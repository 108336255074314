export const PAYERPAYEES_LIST_REQUEST = 'PAYERPAYEES_LIST_REQUEST'
export const PAYERPAYEES_LIST_SUCCESS = 'PAYERPAYEES_LIST_SUCCESS'
export const PAYERPAYEES_LIST_FAIL = 'PAYERPAYEES_LIST_FAIL'


export const PAYERPAYEE_CREATE_REQUEST = 'PAYERPAYEE_CREATE_REQUEST'
export const PAYERPAYEE_CREATE_SUCCESS = 'PAYERPAYEE_CREATE_SUCCESS'
export const PAYERPAYEE_CREATE_FAIL = 'PAYERPAYEE_CREATE_FAIL'


export const PAYERPAYEE_UPDATE_REQUEST = 'PAYERPAYEE_UPDATE_REQUEST'
export const PAYERPAYEE_UPDATE_SUCCESS = 'PAYERPAYEE_UPDATE_SUCCESS'
export const PAYERPAYEE_UPDATE_FAIL = 'PAYERPAYEE_UPDATE_FAIL'

export const PAYERPAYEE_DELETE_REQUEST = 'PAYERPAYEE_DELETE_REQUEST'
export const PAYERPAYEE_DELETE_SUCCESS = 'PAYERPAYEE_DELETE_SUCCESS'
export const PAYERPAYEE_DELETE_FAIL = 'PAYERPAYEE_DELETE_FAIL'

export const VIEW_PAYERPAYEE_REQUEST = 'VIEW_PAYERPAYEE_REQUEST'
export const VIEW_PAYERPAYEE_SUCCESS = 'VIEW_PAYERPAYEE_SUCCESS'
export const VIEW_PAYERPAYEE_FAIL = 'VIEW_PAYERPAYEE_FAIL'