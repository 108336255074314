import React from 'react'
import { Grid, Typography, Stack, Avatar, Box } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { blue, lightBlue, orange, blueGrey } from '@mui/material/colors';
import DetailHeader from '../Applicants/DetailHeader';
import TimelapseIcon from '@mui/icons-material/Timelapse';

const ViewTitleComponent = ({title, picture, profile, icon, iconBrief}) => {
    return (
        <>
            <Grid container justifyContent="flex-start" alignItems="center" sx={{height: '60px', borderRadius: '10px 10px 0 0',background: profile? lightBlue[50] : blue[300] , px: 3, boxShadow: '1px 1px 1px #cfd8da', border: 'none'}}>
                <Grid item >
                    <CircleIcon sx={{color: profile? orange[400] : 'white', fontSize: 12}} fontSize="small"/>
                </Grid>
                <Grid item sx={{ml: 1}}>
                    <Box component="div"  sx={{display: 'flex', justifyContent: "center", alignItems: "center", width: 50, height: 50, background: '', p: 0, m: 0 ,borderRadius: '10px'}}>
                        <Avatar sx={{width: 40, height: 40, background: blueGrey[800]}} variant="rounded" src=''>
                             <Typography variant="h6" sx={{fontWeight: 900}}>{profile? 'P' : 'V'}</Typography>
                        </Avatar>
                    </Box>
                </Grid>
                <Grid item >
                    <Stack direction="row" sx={{ml: 2}}>
                        <Typography variant="h3" sx={{fontSize: '1.3rem',fontFamily: 'Poppins',fontWeight: 800, py: '', px: 0, color: profile? blue[800] : blue[50], mr: 1}}>{title}</Typography>
                        {/* <Typography variant="h3" sx={{fontSize: '1.3rem',fontWeight: 800, py: '', px: 0, color:  blue[200]}}>Mhindu</Typography> */}
                    </Stack>
                </Grid>
            </Grid>
            {picture?
                <Grid container >
                    <DetailHeader/>
                </Grid>
                : '' }
            
            {/* <Grid item>
                <Stack direction="row" justifyContent="flex-end" alignItems="center">
                    {icon === 'Time' ? <TimelapseIcon sx={{ fontSize: 40,color: blue[800], mx: 2}}/> : <TimelapseIcon sx={{ fontSize: 40,color: blue[800], mx: 2}}/> }
                    
                    <Typography variant="h3" sx={{fontSize: 25 ,fontWeight: 800, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blue[300]}}>{iconBrief}</Typography>
                </Stack>
            </Grid> */}
        </>
    )
}

export default ViewTitleComponent
