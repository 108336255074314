export const DIVISIONS_LIST_REQUEST = 'DIVISIONS_LIST_REQUEST'
export const DIVISIONS_LIST_SUCCESS = 'DIVISIONS_LIST_SUCCESS'
export const DIVISIONS_LIST_FAIL = 'DIVISIONS_LIST_FAIL'


export const DIVISIONS_CREATE_REQUEST = 'DIVISIONS_CREATE_REQUEST'
export const DIVISIONS_CREATE_SUCCESS = 'DIVISIONS_CREATE_SUCCESS'
export const DIVISIONS_CREATE_FAIL = 'DIVISIONS_CREATE_FAIL'


export const DIVISIONS_UPDATE_REQUEST = 'DIVISIONS_UPDATE_REQUEST'
export const DIVISIONS_UPDATE_SUCCESS = 'DIVISIONS_UPDATE_SUCCESS'
export const DIVISIONS_UPDATE_FAIL = 'DIVISIONS_UPDATE_FAIL'

export const DIVISIONS_DELETE_REQUEST = 'DIVISIONS_DELETE_REQUEST'
export const DIVISIONS_DELETE_SUCCESS = 'DIVISIONS_DELETE_SUCCESS'
export const DIVISIONS_DELETE_FAIL = 'DIVISIONS_DELETE_FAIL'

export const VIEW_DIVISION_REQUEST = 'VIEW_DIVISION_REQUEST'
export const VIEW_DIVISION_SUCCESS = 'VIEW_DIVISION_SUCCESS'
export const VIEW_DIVISION_FAIL = 'VIEW_DIVISION_FAIL'