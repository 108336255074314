import { WORKINGWEEKSTATUSES_LIST_REQUEST, WORKINGWEEKSTATUSES_LIST_SUCCESS, WORKINGWEEKSTATUSES_LIST_FAIL, VIEW_WORKINGWEEKSTATUS_FAIL, VIEW_WORKINGWEEKSTATUS_REQUEST, VIEW_WORKINGWEEKSTATUS_SUCCESS, WORKINGWEEKSTATUS_CREATE_FAIL, WORKINGWEEKSTATUS_UPDATE_REQUEST, WORKINGWEEKSTATUS_UPDATE_FAIL, WORKINGWEEKSTATUS_DELETE_REQUEST, WORKINGWEEKSTATUS_DELETE_FAIL, WORKINGWEEKSTATUS_CREATE_REQUEST, WORKINGWEEKSTATUS_CREATE_SUCCESS, WORKINGWEEKSTATUS_UPDATE_SUCCESS, WORKINGWEEKSTATUS_DELETE_SUCCESS } from "../Constants/workingWeekStatusConstants";

const workingWeekStatusesListReducer = (state = {workingWeekStatuses: []}, action) => {

    switch (action.type)
    {
        case WORKINGWEEKSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case WORKINGWEEKSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and workingWeekStatuss data is:' + action.payload)
            return {
                loading: false,
                workingWeekStatuses: action.payload
            }
        case WORKINGWEEKSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const workingWeekStatusCreateReducer = (state = {workingWeekStatuses: []}, action) => {

    switch (action.type) {

        case WORKINGWEEKSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case WORKINGWEEKSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                workingWeekStatuses: [...state.workingWeekStatuses, {Applicant_Id: 2, Name: value}]
            };

        case WORKINGWEEKSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const workingWeekStatusViewReducer = (state= {workingWeekStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_WORKINGWEEKSTATUS_REQUEST:
            return {loading: true};
        case VIEW_WORKINGWEEKSTATUS_SUCCESS:
            return {
                    loading: false,
                    workingWeekStatus: action.payload
                }
        case VIEW_WORKINGWEEKSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const workingWeekStatusUpdateReducer = (state = {workingWeekStatuss: {}}, action) => {

    switch (action.type) {

        case WORKINGWEEKSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case WORKINGWEEKSTATUS_UPDATE_SUCCESS:

            return {

            }
        case WORKINGWEEKSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const workingWeekStatusDeleteReducer = (state = {workingWeekStatuss: {}}, action) => {

    switch (action.type) {
        case WORKINGWEEKSTATUS_DELETE_REQUEST:
            return {
                
            }
        case WORKINGWEEKSTATUS_DELETE_SUCCESS:
            return {

            }
        case WORKINGWEEKSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {workingWeekStatusesListReducer, workingWeekStatusCreateReducer, workingWeekStatusViewReducer, workingWeekStatusUpdateReducer, workingWeekStatusDeleteReducer }