import React, {useState} from 'react';
import DeleteWorkerDialog from './DeleteWorkerDialog';
import ViewWorkerDialog from './ViewWorkerDialog';
import AddWorkerDialog from './AddWorkerDialog';

 

const WorkerDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        {
            type === "create"?

            <AddWorkerDialog open={open} onClose={onClose} handleSubmit={handleSubmit}  fullWidth />
            
            : type === "view"?

            <ViewWorkerDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />

            : type === "delete"?

            <DeleteWorkerDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''} 
        
        
    </>
    )

}

export default WorkerDialogs
