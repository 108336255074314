export const DOCUMENTTYPES_LIST_REQUEST = 'DOCUMENTTYPES_LIST_REQUEST'
export const DOCUMENTTYPES_LIST_SUCCESS = 'DOCUMENTTYPES_LIST_SUCCESS'
export const DOCUMENTTYPES_LIST_FAIL = 'DOCUMENTTYPES_LIST_FAIL'


export const DOCUMENTTYPE_CREATE_REQUEST = 'DOCUMENTTYPE_CREATE_REQUEST'
export const DOCUMENTTYPE_CREATE_SUCCESS = 'DOCUMENTTYPE_CREATE_SUCCESS'
export const DOCUMENTTYPE_CREATE_FAIL = 'DOCUMENTTYPE_CREATE_FAIL'


export const DOCUMENTTYPE_UPDATE_REQUEST = 'DOCUMENTTYPE_UPDATE_REQUEST'
export const DOCUMENTTYPE_UPDATE_SUCCESS = 'DOCUMENTTYPE_UPDATE_SUCCESS'
export const DOCUMENTTYPE_UPDATE_FAIL = 'DOCUMENTTYPE_UPDATE_FAIL'

export const DOCUMENTTYPE_DELETE_REQUEST = 'DOCUMENTTYPE_DELETE_REQUEST'
export const DOCUMENTTYPE_DELETE_SUCCESS = 'DOCUMENTTYPE_DELETE_SUCCESS'
export const DOCUMENTTYPE_DELETE_FAIL = 'DOCUMENTTYPE_DELETE_FAIL'

export const VIEW_DOCUMENTTYPE_REQUEST = 'VIEW_DOCUMENTTYPE_REQUEST'
export const VIEW_DOCUMENTTYPE_SUCCESS = 'VIEW_DOCUMENTTYPE_SUCCESS'
export const VIEW_DOCUMENTTYPE_FAIL = 'VIEW_DOCUMENTTYPE_FAIL'