import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { blueGrey } from '@mui/material/colors';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Financial Forecast',
      fontSize: '4rem'
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

 const data = {
  labels,
  datasets: [
    {
      label: 'Actual Inflow',
      data: [100, 200, 300, 400, 500, 600, 700],

      backgroundColor: 'orange',
    },
    {
      label: 'Predicted Inflow',
      data: [10, 150, 270, 180, 400, 500, 300],

      backgroundColor: blueGrey[500],
    },
  ],
};

const Chart = ({chartData}) => {
  return <Bar options={options} data={data} />;
}

export default Chart