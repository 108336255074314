export const TASKSTATUSES_LIST_REQUEST = 'TASKSTATUSES_LIST_REQUEST'
export const TASKSTATUSES_LIST_SUCCESS = 'TASKSTATUSES_LIST_SUCCESS'
export const TASKSTATUSES_LIST_FAIL = 'TASKSTATUSES_LIST_FAIL'


export const TASKSTATUS_CREATE_REQUEST = 'TASKSTATUS_CREATE_REQUEST'
export const TASKSTATUS_CREATE_SUCCESS = 'TASKSTATUS_CREATE_SUCCESS'
export const TASKSTATUS_CREATE_FAIL = 'TASKSTATUS_CREATE_FAIL'


export const TASKSTATUS_UPDATE_REQUEST = 'TASKSTATUS_UPDATE_REQUEST'
export const TASKSTATUS_UPDATE_SUCCESS = 'TASKSTATUS_UPDATE_SUCCESS'
export const TASKSTATUS_UPDATE_FAIL = 'TASKSTATUS_UPDATE_FAIL'

export const TASKSTATUS_DELETE_REQUEST = 'TASKSTATUS_DELETE_REQUEST'
export const TASKSTATUS_DELETE_SUCCESS = 'TASKSTATUS_DELETE_SUCCESS'
export const TASKSTATUS_DELETE_FAIL = 'TASKSTATUS_DELETE_FAIL'

export const VIEW_TASKSTATUS_REQUEST = 'VIEW_TASKSTATUS_REQUEST'
export const VIEW_TASKSTATUS_SUCCESS = 'VIEW_TASKSTATUS_SUCCESS'
export const VIEW_TASKSTATUS_FAIL = 'VIEW_TASKSTATUS_FAIL'