import { CONTACTS_LIST_REQUEST, CONTACTS_LIST_SUCCESS, CONTACTS_LIST_FAIL, VIEW_CONTACT_FAIL, VIEW_CONTACT_REQUEST, VIEW_CONTACT_SUCCESS, CONTACT_CREATE_REQUEST, CONTACT_CREATE_SUCCESS, CONTACT_CREATE_FAIL, CONTACT_UPDATE_FAIL, CONTACT_UPDATE_REQUEST, CONTACT_UPDATE_SUCCESS } from '../Constants/contactConstants';
import api from '../../config/apiConfig';


const getContactsList = () => async(dispatch) => {
    
  
    try {
        dispatch({type: CONTACTS_LIST_REQUEST});
        const {data} = await api.get('/contacts');
        console.log('Contacts data is:' + data[0].Name)
        dispatch({type: CONTACTS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: CONTACTS_LIST_FAIL, payload: error})
    }
} 

const submitContact = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: CONTACT_CREATE_REQUEST});
        
        const {data} = await api.post(`/contacts/`, values);
        console.log('Contact data is:' + data)
        dispatch({type: CONTACT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: CONTACT_CREATE_FAIL, payload: error})
    }
} 

const getContact = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_CONTACT_REQUEST});
        
        const {data} = await api.get(`/contacts/ ${id}`);
        console.log('Contact data is:' + data)
        dispatch({type: VIEW_CONTACT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_CONTACT_FAIL, payload: error})
    }
}

const editContact = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: CONTACT_UPDATE_REQUEST});
        
        const {data} = await api.put(`/contacts/`, values);
        console.log('Contact data is:' + data)
        dispatch({type: CONTACT_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: CONTACT_UPDATE_FAIL, payload: error})
    }
}



export {getContactsList, submitContact, getContact, editContact }