import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, Breadcrumbs, TextField} from '@mui/material';
import { blueGrey, pink, lightBlue, lightGreen } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { getDepartmentsList } from '../../../../../Redux/Actions/DepartmentActions';
import { getBranchesList } from '../../../../../Redux/Actions/BranchActions';
import { getRateCardsList } from '../../../../../Redux/Actions/RateCardActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SummaryBox from '../../../../Utilities/SummaryBox';
import SideCrumbs from '../../../../Utilities/SideCrumbs';
import { submitWorkingWeek } from '../../../../../Redux/Actions/workingWeekActions';


const AddWorkingWeekDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET BRANCHES
    const {workingWeeks, loading} = useSelector(state => state.workingWeeks_List);


    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getJobRolesList());
        dispatch(getDepartmentsList());
        dispatch(getBranchesList());
        dispatch(getRateCardsList());
       
    }, [])
    

    const [values, setValues] = useState({
            start_date: '',
            end_date: '',
            week_number: '',
            status: '',
            
        });

    const [submitValues, setSubmitValues] = useState({
            start_date: '',
            end_date: '',
            week_number: '',
            status: '',
            
        });   
        
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitWorkingWeek(values, submitValues))
        handleSubmit('success',"Successfully saved working week information");
        
    }
    
    const animatedComponents = makeAnimated();
    
    const [inputValue, setInputValue] = useState('');
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
    
    const [date, setDate] = useState({
        start: '01/01/2022',
        end: '01/01/2022',
    });


    const handleDateChange = (newValue, name) => {
        
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate({
            ...date,
            [name]: newValue
        });
        setValues({
            ...values,
            [name]: `${day}/${month+1}/${year}`
        })
        setSubmitValues({
            ...submitValues,
            [name]: `${day}/${month+1}/${year}`
        })
    }


    
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }


    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: lightBlue[700]}}  >
                
                <EngineeringIcon fontSize="large" sx={{color: lightBlue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: lightBlue[200],fontWeight: 700}}> Add Working Week </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    {/* <p>name {submitValues.name}</p>
                    <p>clients {submitValues.clients}</p>
                    <p>types {submitValues.requesttypes}</p>
                    <p>status {submitValues.requeststatuses}</p>
                    <p>division{submitValues.divisions}</p>
                    <p>roles {submitValues.jobroles}</p>
                    <p> number{submitValues.number}</p>
                    <p>notes {submitValues.notes}</p>
                    <p>start {submitValues.start}</p>
                    <p>end {submitValues.end}</p> */}
                    
                    <SideCrumbs/>
                    <Grid item lg={10}>
                        <Form className="">
                        <br/>
                        <Typography id="staff" variant="body2" sx={{display: 'inline',color: 'white',background: pink[400], borderRadius: '5px', px: 2, py: '5px'}}>Specifics</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Week Number</Form.Label>
                                <Form.Control name="week_number" type="number" value={values.week_number} onChange={handleChange} placeholder="E.g 12" className="outline-none border-none" />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="start_date"
                                            label="Week Commence Date"
                                            value={date.start_date}
                                            onChange={(e) => handleDateChange(e, "start_date")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="end_date"
                                            label="Week End Date"
                                            value={date.end_date}
                                            onChange={(e) => handleDateChange(e, "end_date")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography id="staff" variant="body2" sx={{display: 'inline',color: 'white',background: lightGreen[500], borderRadius: '5px', px: 2, py: '5px'}}>Status</Typography>
              
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Week Status</Form.Label>
                                   <AsyncSelect
                                        value={values.status}
                                        name="status"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'workingweekstatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>

                        </Form>
                    </Grid>

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: lightBlue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: lightBlue[800], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Working Week</Button>
        </DialogActions>
    </Dialog>
    ) 
}

export default AddWorkingWeekDialog
