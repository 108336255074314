import { CHECKINSTATUSES_LIST_REQUEST, CHECKINSTATUSES_LIST_SUCCESS, CHECKINSTATUSES_LIST_FAIL, VIEW_CHECKINSTATUS_FAIL, VIEW_CHECKINSTATUS_REQUEST, VIEW_CHECKINSTATUS_SUCCESS, CHECKINSTATUS_CREATE_FAIL, CHECKINSTATUS_UPDATE_REQUEST, CHECKINSTATUS_UPDATE_FAIL, CHECKINSTATUS_DELETE_REQUEST, CHECKINSTATUS_DELETE_FAIL, CHECKINSTATUS_CREATE_REQUEST, CHECKINSTATUS_CREATE_SUCCESS, CHECKINSTATUS_UPDATE_SUCCESS, CHECKINSTATUS_DELETE_SUCCESS } from "../Constants/checkinStatusConstants";

const checkinStatusesListReducer = (state = {checkinStatuses: []}, action) => {

    switch (action.type)
    {
        case CHECKINSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case CHECKINSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and checkinStatuss data is:' + action.payload)
            return {
                loading: false,
                checkinStatuses: action.payload
            }
        case CHECKINSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const checkinStatusCreateReducer = (state = {checkinStatuses: []}, action) => {

    switch (action.type) {

        case CHECKINSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case CHECKINSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                checkinStatuses: [...state.checkinStatuses, {Applicant_Id: 2, Name: value}]
            };

        case CHECKINSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const checkinStatusViewReducer = (state= {checkinStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_CHECKINSTATUS_REQUEST:
            return {loading: true};
        case VIEW_CHECKINSTATUS_SUCCESS:
            return {
                    loading: false,
                    checkinStatus: action.payload
                }
        case VIEW_CHECKINSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const checkinStatusUpdateReducer = (state = {checkinStatuss: {}}, action) => {

    switch (action.type) {

        case CHECKINSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case CHECKINSTATUS_UPDATE_SUCCESS:

            return {

            }
        case CHECKINSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const checkinStatusDeleteReducer = (state = {checkinStatuss: {}}, action) => {

    switch (action.type) {
        case CHECKINSTATUS_DELETE_REQUEST:
            return {
                
            }
        case CHECKINSTATUS_DELETE_SUCCESS:
            return {

            }
        case CHECKINSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {checkinStatusesListReducer, checkinStatusCreateReducer, checkinStatusViewReducer, checkinStatusUpdateReducer, checkinStatusDeleteReducer }