import React, {useState, useEffect} from 'react';
import { Link as BreadcrumbLink, CircularProgress } from '@mui/material';
import { IconButton, Grid, Divider, Stack} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../Hooks/useDialog';
import { blueGrey, blue, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import Crumbs from '../../../Components/Utilities/Crumbs';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import VisitDialogs from '../../../Components/Dialogs/CRM/Management/VisitDialogs/VisitDialogs';
import { getVisitsList } from '../../../Redux/Actions/VisitActions';
import VisitsTbl from '../../../Components/Table/CRM/Management/VisitsTbl';
 
const Visits = () => { 

    //FETCH DATA FROM SERVER
    
    const dispatch = useDispatch();
    const {visits, loading ,error } = useSelector(state => state.visits_List) //receiving state object
    const [list, setList] = useState([])
    
    useEffect(()=> {
        dispatch(getVisitsList());
    }, [])
     
    useEffect(() => {
        if(visits)
        {
            setList([...visits])
        }
        
    }, [visits])
    //TABLE DIALOG FUNCTIONALITY
    
        const handleAddClick = () => {
            console.log('THIS IS WORKING')
            showDialog(true, 'create', '');
        }
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)
    
    //----------------------------------------------------------------------------------------------------------------------------------
     
    return (
        <div>
        
         <ScreenHeader title="Visits" subtitle="Manage all visits from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>
        { dialog.open ?  <VisitDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
        <Grid container justifyContent="space-between" sx={{mb: 2}}>
            <Crumbs root="CRM" middle="Management" end="Visits" main/>
            <Stack direction="row" justifyContent="center" alignItems="center">
                <AddButtonLabel item="Visit"/>
                <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                    <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                </IconButton>
            </Stack>
        </Grid>
        
        <Divider sx={{color: lightBlue[800]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        { loading ?  <HorizontalLoading/> : '' }
        <HorizontalSummaryBox primary="Visits Brief" secondary="Overall Visits count" label1="All Visits" label2="Second Label" label3="Third Label" count={[2,11,6]}/>
        
        <AboveTableHeader title="Visits List"/>
        <Grid container>
            <VisitsTbl visits={list}/>
        </Grid>


     
    </div>
    )
}

export default Visits
