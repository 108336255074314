export const PROFITLOSSSTATUSES_LIST_REQUEST = 'PROFITLOSSSTATUSES_LIST_REQUEST';
export const PROFITLOSSSTATUSES_LIST_SUCCESS = 'PROFITLOSSSTATUSES_LIST_SUCCESS';
export const PROFITLOSSSTATUSES_LIST_FAIL = 'PROFITLOSSSTATUSES_LIST_FAIL'; 

export const PROFITLOSSSTATUS_CREATE_REQUEST = 'PROFITLOSSSTATUS_CREATE_REQUEST'
export const PROFITLOSSSTATUS_CREATE_SUCCESS = 'PROFITLOSSSTATUS_CREATE_SUCCESS'
export const PROFITLOSSSTATUS_CREATE_FAIL = 'PROFITLOSSSTATUS_CREATE_FAIL'


export const PROFITLOSSSTATUS_UPDATE_REQUEST = 'PROFITLOSSSTATUS_UPDATE_REQUEST'
export const PROFITLOSSSTATUS_UPDATE_SUCCESS = 'PROFITLOSSSTATUS_UPDATE_SUCCESS'
export const PROFITLOSSSTATUS_UPDATE_FAIL = 'PROFITLOSSSTATUS_UPDATE_FAIL'

export const PROFITLOSSSTATUS_DELETE_REQUEST = 'PROFITLOSSSTATUS_DELETE_REQUEST'
export const PROFITLOSSSTATUS_DELETE_SUCCESS = 'PROFITLOSSSTATUS_DELETE_SUCCESS'
export const PROFITLOSSSTATUS_DELETE_FAIL = 'PROFITLOSSSTATUS_DELETE_FAIL'

export const VIEW_PROFITLOSSSTATUS_REQUEST = 'VIEW_PROFITLOSSSTATUS_REQUEST'
export const VIEW_PROFITLOSSSTATUS_SUCCESS = 'VIEW_PROFITLOSSSTATUS_SUCCESS'
export const VIEW_PROFITLOSSSTATUS_FAIL = 'VIEW_PROFITLOSSSTATUS_FAIL'