import { VATRETURNS_LIST_REQUEST, VATRETURNS_LIST_SUCCESS, VATRETURNS_LIST_FAIL, VIEW_VATRETURN_FAIL, VIEW_VATRETURN_REQUEST, VIEW_VATRETURN_SUCCESS, VATRETURN_CREATE_REQUEST, VATRETURN_CREATE_SUCCESS, VATRETURN_CREATE_FAIL, VATRETURN_UPDATE_FAIL, VATRETURN_UPDATE_REQUEST, VATRETURN_UPDATE_SUCCESS } from '../Constants/vatReturnConstants';
import api from '../../config/apiConfig';


const getVATReturnsList = () => async(dispatch) => {
    
  
    try {
        dispatch({type: VATRETURNS_LIST_REQUEST});
        const {data} = await api.get('/vatreturns');
        console.log('VATReturns data is:' + data[0].Name)
        dispatch({type: VATRETURNS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: VATRETURNS_LIST_FAIL, payload: error})
    }
} 

const submitVATReturn = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: VATRETURN_CREATE_REQUEST});
        
        const {data} = await api.post(`/vatreturns/`, postValues);
        console.log('VATReturn data is:' + data)
        dispatch({type: VATRETURN_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VATRETURN_CREATE_FAIL, payload: error})
    }
} 

const getVATReturn = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_VATRETURN_REQUEST});
        
        const {data} = await api.get(`/vatreturns/ ${id}`);
        console.log('VATReturn data is:' + data)
        dispatch({type: VIEW_VATRETURN_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_VATRETURN_FAIL, payload: error})
    }
}

const editVATReturn = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: VATRETURN_UPDATE_REQUEST});
        
        const {data} = await api.put(`/vatreturns/`, values);
        console.log('VATReturn data is:' + data)
        dispatch({type: VATRETURN_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: VATRETURN_UPDATE_FAIL, payload: error})
    }
}



export {getVATReturnsList, submitVATReturn, getVATReturn, editVATReturn }