import { TASKS_LIST_REQUEST, TASKS_LIST_SUCCESS, TASKS_LIST_FAIL, VIEW_TASK_FAIL, VIEW_TASK_REQUEST, VIEW_TASK_SUCCESS, TASK_CREATE_FAIL, TASK_UPDATE_REQUEST, TASK_UPDATE_FAIL, TASK_DELETE_REQUEST, TASK_DELETE_FAIL, TASK_CREATE_REQUEST, TASK_CREATE_SUCCESS, TASK_UPDATE_SUCCESS, TASK_DELETE_SUCCESS } from "../Constants/tasksConstants";


const initialState = {
    tasks: [],
    task: {},
    loading: ''
}
const tasksListReducer = (state = initialState, action) => {

    switch (action.type)
    {
        case TASKS_LIST_REQUEST:
            return {loading: true};
 
        case TASKS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and tasks data is:' + action.payload)
            return {
                loading: false,
                tasks: [...action.payload]
            }
        case TASKS_LIST_FAIL:
            return {
                error: action.payload
            } 

            case TASK_CREATE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case TASK_CREATE_SUCCESS: 
            let randomNumber = Math.floor(Math.random() * (Math.random()*43 + 12))
            
            const {name, description, staff, divisions, date, deadline, tasktypes, clients, workers, notes, taskstatuses} = action.payload;
            console.log('MADE IT TO THE TASK CREATE REDUCER:, state values are: ' + description)

            const newTask = {id: randomNumber, Task_Name: name, Description: description, Staff: staff, Type: `${tasktypes}`, Client: clients, Worker: workers, Status: taskstatuses, Date: date, Deadline: deadline};
          
            return {
                ...state,
                loading: false,
                tasks: [ ...state.tasks, newTask ]
            };


        case TASK_CREATE_FAIL:
            return {
                error: action.payload
            }
            
        case TASK_UPDATE_REQUEST:
            return {
                
            }
        case TASK_UPDATE_SUCCESS:

            return {

            }
        case TASK_UPDATE_FAIL:
            return {

            } 
            case TASK_DELETE_REQUEST:
            console.log('I HAVE HIT THE REQUEST IN THE REDUCER')
            return {
                ...state,
                loading: true,
                
            }
        case TASK_DELETE_SUCCESS:
             
            console.log('I HAVE HIT THE SUCCESS IN THE REDUCER with id: ' + action.payload)
            console.log('tasks: ' + state.tasks)
            return {
                ...state,
                loading: false,
                tasks: [...state.tasks.filter(x => x.id !== action.payload)]

            }
        case TASK_DELETE_FAIL:
                console.log('I HAVE HIT THE fail IN THE REDUCER')
            return {
                ...state,
                loading: false,
                error: action.payload,
            }  
        default:
            return state
    }
}
 

const taskViewReducer = (state= initialState, action) => {
    switch(action.type)
    {
        case VIEW_TASK_REQUEST:
                return {
                    ...state,
                    loading: true
                };
        case VIEW_TASK_SUCCESS:
            return {
                    ...state,
                    loading: false,
                    task: action.payload
                }
        case VIEW_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

const taskUpdateReducer = (state = initialState, action) => {

    switch (action.type) {

        case TASK_UPDATE_REQUEST:
            return {
                
            }
        case TASK_UPDATE_SUCCESS:

            return {

            }
        case TASK_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const taskDeleteReducer = (state = initialState, action) => {

    // switch (action.type) {
    //     case TASK_DELETE_REQUEST:
    //         return {
    //             loading: true,
                
    //         }
    //     case TASK_DELETE_SUCCESS:
    //         const newList = state.tasks.filter(x => x.Id != action.payload)
    //         return {
    //             loading: false,
    //             tasks: [...newList]

    //         }
    //     case TASK_DELETE_FAIL:
    //         return state
    
    //     default:
    //        return state;
    // }
}


export {tasksListReducer, taskViewReducer}