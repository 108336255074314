import { TASKTYPES_LIST_REQUEST, TASKTYPES_LIST_SUCCESS, TASKTYPES_LIST_FAIL, VIEW_TASKTYPE_FAIL, VIEW_TASKTYPE_REQUEST, VIEW_TASKTYPE_SUCCESS, TASKTYPE_CREATE_FAIL, TASKTYPE_UPDATE_REQUEST, TASKTYPE_UPDATE_FAIL, TASKTYPE_DELETE_REQUEST, TASKTYPE_DELETE_FAIL, TASKTYPE_CREATE_REQUEST, TASKTYPE_CREATE_SUCCESS, TASKTYPE_UPDATE_SUCCESS, TASKTYPE_DELETE_SUCCESS } from "../Constants/taskTypeConstants";

const taskTypesListReducer = (state = {taskTypes: []}, action) => {

    switch (action.type)
    {
        case TASKTYPES_LIST_REQUEST:
            return {loading: true};
 
        case TASKTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and taskTypes data is:' + action.payload)
            return {
                loading: false,
                taskTypes: action.payload
            }
        case TASKTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const taskTypeCreateReducer = (state = {taskTypes: []}, action) => {

    switch (action.type) {

        case TASKTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case TASKTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                taskTypes: [...state.taskTypes, {Applicant_Id: 2, Name: value}]
            };

        case TASKTYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const taskTypeViewReducer = (state= {taskType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_TASKTYPE_REQUEST:
            return {loading: true};
        case VIEW_TASKTYPE_SUCCESS:
            return {
                    loading: false,
                    taskType: action.payload
                }
        case VIEW_TASKTYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const taskTypeUpdateReducer = (state = {taskTypes: {}}, action) => {

    switch (action.type) {

        case TASKTYPE_UPDATE_REQUEST:
            return {
                
            }
        case TASKTYPE_UPDATE_SUCCESS:

            return {

            }
        case TASKTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const taskTypeDeleteReducer = (state = {taskTypes: {}}, action) => {

    switch (action.type) {
        case TASKTYPE_DELETE_REQUEST:
            return {
                
            }
        case TASKTYPE_DELETE_SUCCESS:
            return {

            }
        case TASKTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {taskTypesListReducer, taskTypeCreateReducer, taskTypeViewReducer, taskTypeUpdateReducer, taskTypeDeleteReducer }