export const SHIFTSTATUSES_LIST_REQUEST = 'SHIFTSTATUSES_LIST_REQUEST'
export const SHIFTSTATUSES_LIST_SUCCESS = 'SHIFTSTATUSES_LIST_SUCCESS'
export const SHIFTSTATUSES_LIST_FAIL = 'SHIFTSTATUSES_LIST_FAIL'


export const SHIFTSTATUS_CREATE_REQUEST = 'SHIFTSTATUS_CREATE_REQUEST'
export const SHIFTSTATUS_CREATE_SUCCESS = 'SHIFTSTATUS_CREATE_SUCCESS'
export const SHIFTSTATUS_CREATE_FAIL = 'SHIFTSTATUS_CREATE_FAIL'


export const SHIFTSTATUS_UPDATE_REQUEST = 'SHIFTSTATUS_UPDATE_REQUEST'
export const SHIFTSTATUS_UPDATE_SUCCESS = 'SHIFTSTATUS_UPDATE_SUCCESS'
export const SHIFTSTATUS_UPDATE_FAIL = 'SHIFTSTATUS_UPDATE_FAIL'

export const SHIFTSTATUS_DELETE_REQUEST = 'SHIFTSTATUS_DELETE_REQUEST'
export const SHIFTSTATUS_DELETE_SUCCESS = 'SHIFTSTATUS_DELETE_SUCCESS'
export const SHIFTSTATUS_DELETE_FAIL = 'SHIFTSTATUS_DELETE_FAIL'

export const VIEW_SHIFTSTATUS_REQUEST = 'VIEW_SHIFTSTATUS_REQUEST'
export const VIEW_SHIFTSTATUS_SUCCESS = 'VIEW_SHIFTSTATUS_SUCCESS'
export const VIEW_SHIFTSTATUS_FAIL = 'VIEW_SHIFTSTATUS_FAIL'