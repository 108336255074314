import React, { useEffect, useState } from 'react';
import DataTable from '../../DataTable';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import StaffDialogs from '../../../Dialogs/CRM/Staff/StaffDialogs/StaffDialogs';

const StaffTbl = ({staff}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const getJRColor = (jr) => {
        return jr === 'Class 1' ? blue[500] : jr === 'Class 2'?  pink[500] : jr === 'Construction'? orange[500] : jr === 'FLT'? lightGreen[500] : blueGrey[500]
    }
    const getStatusColor = (status) => {
        return status === 'New' ? blue[500] : status === 'Active'?  lightGreen[500] : status === 'Multi'? orange[500] : status === 'Leaver'? lightGreen[500] : blueGrey[500]
    }

    const switchToViewStaff = (id) => {
        navigate('/staff/'+ id)
    } 

    const staffQuickView = (id) => {

        showDialog(true, 'view', id)
      }


      const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'fullName', headerName: 'Full Name', renderCell: (params) => <Typography onClick={()=> {staffQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.First_Name} ${params.row.Surname}`}</Typography>},
        { field: 'First_Name', headerName: 'First Name'},
        { field: 'Surname', headerName: 'Surname'},
     

        { field: 'Department', headerName: 'Department', type: 'singleSelect', valueOptions: (['Construction', 'Driving', 'Industrial', 'Aviation'])},

        { field: 'JRole', headerName: 'Job Role', width: 150, type: 'singleSelect',  valueOptions: (['Class 1', 'Driving', 'Industrial', 'Aviation']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getJRColor(params.row.JRole), borderRadius: '30px', px: '10px'}}>{params.row.JRole}</Typography>
        )},

        { field: 'Email', headerName: 'Email'},
        { field: 'Mobile', headerName: 'Mobile'},
        { field: 'NationalNo', headerName: 'National No'},
        { field: 'DOB', headerName: 'DOB'},
        { field: 'Home_Phone', headerName: 'Home Phone'},
        { field: 'Add1', headerName: 'Address 1'},
        { field: 'Add2', headerName: 'Address 2', width: 200},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewStaff(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ];



    return (
        <>
        { dialog.open  ?  <StaffDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }

            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={staff}
                        loading={!staff.length}
                    />
                </div>
            </div>
        </>
       
    )
}

export default StaffTbl
