import React, { useState} from 'react';


  
const useDialog = (showAlert) => {

      const [dialog, setDialog] = useState({
        open: false,
        type: '',
        info: ''
    }) 

   
    const showDialog = (open = false, type = '', info = '') => {
      setDialog({open, type, info})
    }

    
    const closeDialog = () => {
        setDialog({open: false})
    }

    const handleSubmit = (type, message) => {
      closeDialog();
      setTimeout(()=> {
          showAlert(type, message, true)
      }, 2000)
      //showAlert('success', 'Your new applicant has been added to the list', 'info');
  }
    
   


      
    return {dialog, showDialog, closeDialog, handleSubmit}
}

export default useDialog
