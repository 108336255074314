import { SHIFTS_LIST_REQUEST, SHIFTS_LIST_SUCCESS, SHIFTS_LIST_FAIL, VIEW_SHIFT_FAIL, VIEW_SHIFT_REQUEST, VIEW_SHIFT_SUCCESS, SHIFT_CREATE_FAIL, SHIFT_UPDATE_REQUEST, SHIFT_UPDATE_FAIL, SHIFT_DELETE_REQUEST, SHIFT_DELETE_FAIL, SHIFT_CREATE_REQUEST, SHIFT_CREATE_SUCCESS, SHIFT_UPDATE_SUCCESS, SHIFT_DELETE_SUCCESS } from "../Constants/shiftConstants";

const shiftsListReducer = (state = {shifts: []}, action) => {

    switch (action.type)
    {
        case SHIFTS_LIST_REQUEST:
            return {loading: true};
 
        case SHIFTS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and shifts data is:' + action.payload)
            return {
                loading: false,
                shifts: action.payload
            }
        case SHIFTS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const shiftCreateReducer = (state = {shifts: []}, action) => {

    switch (action.type) {

        case SHIFT_CREATE_REQUEST:
            return {
                loading: true
            }

        case SHIFT_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                shifts: [...state.shifts, {Applicant_Id: 2, Name: value}]
            };

        case SHIFT_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const shiftViewReducer = (state= {shift: {}}, action) => {
    switch(action.type)
    {
        case VIEW_SHIFT_REQUEST:
            return {loading: true};
        case VIEW_SHIFT_SUCCESS:
            return {
                    loading: false,
                    shift: action.payload
                }
        case VIEW_SHIFT_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const shiftUpdateReducer = (state = {shifts: {}}, action) => {

    switch (action.type) {

        case SHIFT_UPDATE_REQUEST:
            return {
                
            }
        case SHIFT_UPDATE_SUCCESS:

            return {

            }
        case SHIFT_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const shiftDeleteReducer = (state = {shifts: {}}, action) => {

    switch (action.type) {
        case SHIFT_DELETE_REQUEST:
            return {
                
            }
        case SHIFT_DELETE_SUCCESS:
            return {

            }
        case SHIFT_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {shiftsListReducer, shiftCreateReducer, shiftViewReducer, shiftUpdateReducer, shiftDeleteReducer }