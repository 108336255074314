import { TASKSTATUSES_LIST_REQUEST, TASKSTATUSES_LIST_SUCCESS, TASKSTATUSES_LIST_FAIL, VIEW_TASKSTATUS_FAIL, VIEW_TASKSTATUS_REQUEST, VIEW_TASKSTATUS_SUCCESS, TASKSTATUS_CREATE_REQUEST, TASKSTATUS_CREATE_SUCCESS, TASKSTATUS_CREATE_FAIL, TASKSTATUS_UPDATE_FAIL, TASKSTATUS_UPDATE_REQUEST, TASKSTATUS_UPDATE_SUCCESS } from '../Constants/taskStatusConstants';
import api from '../../config/apiConfig';


const getTaskStatusesList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: TASKSTATUSES_LIST_REQUEST});
        const {data} = await api.get('/taskstatuses');
        // console.log('TaskStatus data is:' + data[0]['TaskStatus_Name'])
        dispatch({type: TASKSTATUSES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: TASKSTATUSES_LIST_FAIL, payload: error})
    }
} 

const submitTaskStatus = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: TASKSTATUS_CREATE_REQUEST});
        
        const {data} = await api.post(`/taskstatuses/`, values);
        console.log('TaskStatus data is:' + data)
        dispatch({type: TASKSTATUS_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: TASKSTATUS_CREATE_FAIL, payload: error})
    }
} 

const getTaskStatus = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_TASKSTATUS_REQUEST});
        
        const {data} = await api.get(`/taskstatuses/ ${id}`);
        console.log('TaskStatus data is:' + data)
        dispatch({type: VIEW_TASKSTATUS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_TASKSTATUS_FAIL, payload: error})
    }
}

const editTaskStatus = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: TASKSTATUS_UPDATE_REQUEST});
        
        const {data} = await api.put(`/taskstatuses/`, values);
        console.log('TaskStatus data is:' + data)
        dispatch({type: TASKSTATUS_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: TASKSTATUS_UPDATE_FAIL, payload: error})
    }
}



export {getTaskStatusesList, submitTaskStatus, getTaskStatus, editTaskStatus }