import React, {useState} from 'react'

const useAlert = () => {

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false,
        })

    const showAlert = (type, message, show) => {
        setAlert({type, message, show})
    }

    
    return {alert, showAlert}
}

export default useAlert
