export const STAFF_LIST_REQUEST = 'STAFF_LIST_REQUEST'
export const STAFF_LIST_SUCCESS = 'STAFF_LIST_SUCCESS'
export const STAFF_LIST_FAIL = 'STAFF_LIST_FAIL'


export const STAFF_CREATE_REQUEST = 'STAFF_CREATE_REQUEST'
export const STAFF_CREATE_SUCCESS = 'STAFF_CREATE_SUCCESS'
export const STAFF_CREATE_FAIL = 'STAFF_CREATE_FAIL'


export const STAFF_UPDATE_REQUEST = 'STAFF_UPDATE_REQUEST'
export const STAFF_UPDATE_SUCCESS = 'STAFF_UPDATE_SUCCESS'
export const STAFF_UPDATE_FAIL = 'STAFF_UPDATE_FAIL'

export const STAFF_DELETE_REQUEST = 'STAFF_DELETE_REQUEST'
export const STAFF_DELETE_SUCCESS = 'STAFF_DELETE_SUCCESS'
export const STAFF_DELETE_FAIL = 'STAFF_DELETE_FAIL'

export const VIEW_STAFF_REQUEST = 'VIEW_STAFF_REQUEST'
export const VIEW_STAFF_SUCCESS = 'VIEW_STAFF_SUCCESS'
export const VIEW_STAFF_FAIL = 'VIEW_STAFF_FAIL'