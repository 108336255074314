import { HOLIDAYS_LIST_REQUEST, HOLIDAYS_LIST_SUCCESS, HOLIDAYS_LIST_FAIL, VIEW_HOLIDAY_FAIL, VIEW_HOLIDAY_REQUEST, VIEW_HOLIDAY_SUCCESS, HOLIDAY_CREATE_REQUEST, HOLIDAY_CREATE_SUCCESS, HOLIDAY_CREATE_FAIL, HOLIDAY_UPDATE_FAIL, HOLIDAY_UPDATE_REQUEST, HOLIDAY_UPDATE_SUCCESS } from '../Constants/holidayConstants';
import api from '../../config/apiConfig';


const getHolidaysList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: HOLIDAYS_LIST_REQUEST});
        const {data} = await api.get('/holidays');
        // console.log('Holidays data is:' + data[0]['Holiday_Name'])
        dispatch({type: HOLIDAYS_LIST_SUCCESS, payload: data})
        
         
    } catch (error) {
        dispatch({type: HOLIDAYS_LIST_FAIL, payload: error})
    }
} 

const submitHoliday = (stateValues, submitValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: HOLIDAY_CREATE_REQUEST});
        
        const {data} = await api.post(`/holidays/`, submitValues);
        console.log('Holiday data is:' + data)
        dispatch({type: HOLIDAY_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: HOLIDAY_CREATE_FAIL, payload: error})
    }
} 

const getHoliday = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_HOLIDAY_REQUEST});
        
        const {data} = await api.get(`/holidays/ ${id}`);
        console.log('Holiday data is:' + data)
        dispatch({type: VIEW_HOLIDAY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_HOLIDAY_FAIL, payload: error})
    }
}

const editHoliday = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: HOLIDAY_UPDATE_REQUEST});
        
        const {data} = await api.put(`/holidays/`, values);
        console.log('Holiday data is:' + data)
        dispatch({type: HOLIDAY_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: HOLIDAY_UPDATE_FAIL, payload: error})
    }
}



export {getHolidaysList, submitHoliday, getHoliday, editHoliday }