import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, CircularProgress} from '@mui/material';
import { blueGrey, orange, blue, red, cyan, deepOrange, green, lightGreen } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useDispatch, useSelector } from 'react-redux';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import useAlert from '../../../../../Hooks/useAlert';
import ComplexAlert from '../../../../ComplexAlert';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated'
import { getWorker, getWorkers } from '../../../../../Redux/Actions/WorkerActions';
import EditInDialog from '../../../../Utilities/EditInDialog';
import DialogNavigation from '../../../../Utilities/DialogNavigation';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { getShiftPreferencesList } from '../../../../../Redux/Actions/ShiftPreferenceActions';
import { getPayrollSetupsList } from '../../../../../Redux/Actions/PayrollSetupActions';
import { getPayrollDocTypesList } from '../../../../../Redux/Actions/PayrollDocTypeActions';
import { getWorkerStatusesList } from '../../../../../Redux/Actions/WorkerStatusActions';
import api from '../../../../../config/apiConfig';



const ViewWorkerDialog = ({open, onClose, type, info, handleSubmit}) => {

    //ACCESS DISPATCH
    const dispatch = useDispatch();
    const id = info;

    console.log('INFOOO' + info)

    //GET WORKER
    const worker_records = useSelector(state => state.worker_View);
    const {loading: loading_worker, worker} = worker_records;

    //GET SHIFT PREFS
    const preferences_ListState = useSelector(state => state.shiftPreferences_List);
    const {shiftPreferences, loading: loading_preferences} = preferences_ListState;
 
    //GET DIVISIONS
    const divisions_ListState = useSelector(state => state.divisions_List);
    const {divisions, loading} = divisions_ListState;

    //GET JOB ROLES
    const roles_List_State = useSelector((state) => state.jobRoles_List);
    const {loading: loading_roles, jobRoles} = roles_List_State;

    //GET PAYROLL SETUP 
    const payrollSetup_ListState = useSelector(state => state.payrollSetups_List);
    const {payrollSetups, loading: loading_setups} = payrollSetup_ListState;

    //GET PAYROLL DOC TYPE
    const docTypes_ListState = useSelector(state => state.payrollDocTypes_List);
    const {payrollDocTypes, loading: loading_docTypes} = docTypes_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;

    //GET WORKERs
    const workers_ListState = useSelector(state => state.workers_List);
    const {workers, loading: loading_workers} = workers_ListState;

    //GET WORKER STATUS
    const statuses_ListState = useSelector(state => state.workerStatuses_List);
    const {workerStatuses, loading: loading_statuses} = statuses_ListState;

    //GET LINKED UMBRELLAS
    const umbrellas_List_State = useSelector((state) => state.linkedUmbrellas_List);
    const {loading: loading_umbrellas, linkedUmbrellas} = umbrellas_List_State;
    
    
       
    //CURRENT DATE
    const d = new Date(), yyyy = d.getFullYear(), mm = d.getMonth(), dd = d.getDay();
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getWorker(id));
        dispatch(getDivisions());
        dispatch(getJobRolesList());
        dispatch(getShiftPreferencesList());
        dispatch(getPayrollSetupsList());
        dispatch(getPayrollDocTypesList());
        dispatch(getWorkerStatusesList());
       
    }, [])
    

    const [values, setValues] = useState({
            fname: loading_worker? '' : worker.First_Name, 
            surname: loading_worker? '' : worker.Surname, 
            dob: loading_worker? '' : worker.DOB, 
            insurance: loading_worker? '' : worker.Insurance_No, 
            email: loading_worker? '' : worker.Email, 
            address1: loading_worker? '' : worker.Address1, 
            address2: loading_worker? '' : worker.Address2, 
            postcode: loading_worker? '' : worker.Postcode, 
            town: loading_worker? '' : worker.Postcode, 
            mobile: loading_worker? '' : worker.Mobile, 
            home: loading_worker? '' : worker.Home_Phone, 
            staff: '' , 
            division: loading? '' : worker.Division, 
        });

        console.log('Fname holds:' + values.fname)

    const [submitValues, setSubmitValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });


    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            
 
        }
    }
//Select input START

const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {

        
        const keeper = [];

        const tableName = name == 'divisions' ? divisions : name == 'jobroles' ? jobRoles : name == 'staff' ? staff : name == 'shiftpreferences' ? shiftPreferences : name == 'payrollsetups' ? payrollSetups : name == 'payrolldoctypes' ? payrollDocTypes : name == 'workerstatuses' ? workerStatuses : name == 'linkedumbrellas' ? linkedUmbrellas :'' ;

        const id_field = name === 'divisions' ? 'Division_Id': name === 'staff' ? 'Staff_Id' : name === 'jobroles'? 'Role_Id' : name === 'shiftpreferences'? 'Preference_Id': name === 'payrollsetups'? 'Setup_Id' : name === 'payrolldoctypes'? 'Type_Id' : name === 'workerstatuses'? 'Status_Id' : name === 'linkedumbrellas'? 'Umbrella_Id': '';
        const name_field = name === 'divisions' ? 'Name': name === 'staff' ? 'First_Name' : name === 'jobroles'? 'Name' : name === 'shiftpreferences'? 'Name': name === 'payrollsetups'? 'Setup_Name': name === 'payrolldoctypes'? 'Type_Name' : name === 'workerstatuses'? 'Status_Name' : name === 'linkedumbrellas'? 'Umbrella_Name':  '';
            
        tableName.forEach(x => {
            keeper.push({value: x[id_field], label: x[name_field]})
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['divisions', 'staff', 'jobroles', 'shiftpreferences', 'payrollsetups', 'payrolldoctypes', 'workerstatuses', 'linkedumbrellas'];
        
        const id_field = name === 'divisions' ? 'Division_Id': name === 'staff' ? 'Staff_Id' : name === 'jobroles'? 'Role_Id' : name === 'shiftpreferences'? 'Preference_Id': name === 'payrollsetups'? 'Setup_Id' : name === 'payrolldoctypes'? 'Type_Id' : name === 'workerstatuses'? 'Status_Id' : name === 'linkedumbrellas'? 'Umbrella_Id': '';
        const name_field = name === 'divisions' ? 'Name': name === 'staff' ? 'FullName' : name === 'jobroles'? 'Name' : name === 'shiftpreferences'? 'Name': name === 'payrollsetups'? 'Setup_Name': name === 'payrolldoctypes'? 'Type_Name' : name === 'workerstatuses'? 'Status_Name' : name === 'linkedumbrellas'? 'Umbrella_Name':  '';
        
        names.forEach(x => x === name)
        {
            
            data.forEach(x => {
                keeper.push({value: x[id_field], label: x[name_field]})
               })
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action) => {
        
        const name = action.name;

        setValues({
            ...values,
            [name]: value
        })

        setSubmitValues({
            ...submitValues,
            [name]: value.value
        })
        
    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }

    const [summary, setSummary] = useState(false)

    const handleSummary = () => {
        setSummary(!summary);
    }


//Select input END

    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        showAlert('info','You can now edit this workers information',true)
        setIsEditing(!isEditing);
    }

    //USE ALERT
    const {alert, showAlert} = useAlert();
    
    const animatedComponents = makeAnimated();


    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >
            
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[800]}} className=""  >
                <Stack direction="row" alignItems="center">
                    <AssessmentIcon sx={{color: blue[300], mr: 2, fontSize: '1.5rem'}}/>
                    <Typography variant="h6" sx={{color: blue[300],fontWeight: 700}}>View Worker</Typography>
                </Stack>
                <Typography variant="body2" sx={{color: "white",fontWeight: 500, background: lightGreen[600], borderRadius: '5px', px: 2, py: '5px',  boxShadow: '.5px .5px 5px blue'}}>{loading? <CircularProgress size={15}  color="primary"/> : worker.Status_Name}</Typography>
            </DialogTitle>

        {alert.show? 
        <ComplexAlert alert={alert} removeAlert={showAlert}/> : ''
        }
        <Divider sx={{mt: 2, color: blue[500]}}/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">
                <Grid item lg={12} justifyContent="space-between"> 
                    <Grid container justifyContent="space-between">
                    <Stack direction="column">
                        <Typography sx={{display: 'inline',fontSize: '.9rem', fontFamily: 'Poppins', fontWeight: '800', color: blueGrey[300] }}>Title</Typography>
                        <Typography sx={{fontSize: '1.4rem', fontFamily: 'Poppins-Medium', fontWeight: '800'}}>{loading? <CircularProgress size={24}  color="primary"/> : `${worker.First_Name} ${worker.Surname}`}</Typography>
                    </Stack>
                    <EditInDialog isEditing={isEditing} handleEditClick={handleEditClick}/>
                    </Grid>
                </Grid>
                <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                    <DialogNavigation/>
                </Grid>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[800], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Worker ID</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled type="text" value={`WRK - ${loading_worker? '' : worker.id}`} className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">First Name</Form.Label>
                                    <Form.Control name="fname" onChange={handleChange}  disabled={isEditing? false : true} type="text" value={loading_worker? '' : worker.First_Name} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Surname</Form.Label>
                                    <Form.Control name="surname" disabled={isEditing? false : true} type="text" value={loading_worker? '' : worker.Surname} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">DOB</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled type="text" value={loading_worker? '' : worker.DOB} className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Insurance No.</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading_worker? '' : worker.Insurance_No} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Email</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading_worker? '' : worker.Email} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Mobile No.</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading_worker? '' : worker.Mobile} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Home Phone No.</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading_worker? '' : worker.Home_Phone} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Address 1</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading_worker? '' : worker.Address1} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Address 2</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading_worker? '' : worker.Address2} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Postcode</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading_worker? '' : worker.Postcode} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Town/City</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading_worker? '' : worker.Town_City} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                        
                        <Divider sx={{my: 2, color: blue[200]}}/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[700], borderRadius: '5px', px: 2, py: '5px',}}>Worker Particulars</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Division</Form.Label>
                                    <br/>
                                    {
                                        !isEditing? 
                                        <AsyncSelect
                                            value={values.division}
                                            defaultValue={'orange'}
                                            className="tes"
                                            name="divisions"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'divisions')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for division ..."
                                            onInputChange={handleInputChange}
                                        />  : ''
                                    // <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : worker.Division}</Typography>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Consultant</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                        <AsyncSelect
                                            value={values.staff}
                                            name="staff"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'staff')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for consultant ..."
                                            onInputChange={handleInputChange}
                                        />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading_worker? '' : worker.Consultant}</Typography>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Status</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                        <AsyncSelect
                                            value={values.workerstatuses}
                                            name="workerstatuses"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'workerstatuses')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for status ..."
                                            onInputChange={handleInputChange}
                                        />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: green[500], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading_worker? '' : worker.Status_Name}</Typography>
                                    }
                                </Form.Group>

                                
                            </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px',}}>Payment Route</Typography>

                            <Row>
                               <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Payroll Setup</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                        <AsyncSelect
                                            value={values.payrollsetups}
                                            name="payrollsetups"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'payrollsetups')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for setups ..."
                                            onInputChange={handleInputChange}
                                        />   :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading_worker? '' : worker.Setup_Name}</Typography>
                                    }
                                </Form.Group>
                               <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Payroll Doc Type</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                        <AsyncSelect
                                            value={values.payrolldoctypes}
                                            name="payrolldoctypes"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'payrolldoctypes')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for type ..."
                                            onInputChange={handleInputChange}
                                        />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading_worker? '' : worker.Type_Name}</Typography>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Umbrella</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                        <AsyncSelect
                                            value={values.linkedumbrellas}
                                            name="linkedumbrellas"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'linkedumbrellas')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for umbrella ..."
                                            onInputChange={handleInputChange}
                                        />   :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading_worker? '' : worker.Umbrella_Name}</Typography>
                                    }
                                </Form.Group>
                            
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">UTR No.</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled type="text" value={loading? '' : worker.UTR_No} className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">CIS No.</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading? '' : worker.CIS_No} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                
                            </Row>

                           
                        <Divider sx={{my: 2, color: blue[200]}}/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[700], borderRadius: '5px', px: 2, py: '5px',}}>Company</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Company</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled type="text" value={loading? '' : worker.Company_Name} className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Company VAT</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading? '' : worker.Company_No} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                
                            </Row>
                           
                        <Divider sx={{my: 2, color: blue[200]}}/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px',}}>Notes</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="mb-0 p-0 view-label">Worker Notes</Form.Label>
                                    <Form.Control 
                                        onChange={handleChange} 
                                        name="subject" 
                                        disabled={isEditing? false : true} 
                                        as="textarea" 
                                        value={'Hard coded notes'} 
                                        className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                
                                
                            </Row>
                        </Form>
                    </Grid>

                    {/* <Grid item lg={2} sx={{mr: 5}}>
                        <Stack sx={{mt: 3}} alignItems="center">
                            <Typography sx={{textAlign: 'center', mb: 3, color: 'gray'}}>Actions</Typography>
                            <Button variant="outlined" size="small" onClick={handleEditClick} endIcon={isEditing? <CloseIcon fontSize="small"/> : <EditIcon fontSize="small"/>} sx={{mb: 1,fontSize: '.7rem', color: isEditing? 'white' : '', border: '1px solid #80deea', background: isEditing? cyan[700] : cyan[50], width: '75%', boxShadow: 'none', px: 0}}>{isEditing? 'Cancel' : 'Edit'}</Button>
                            <Button variant="outlined" size="small" endIcon={<DeleteIcon fontSize="small" sx={{color: 'red[500]'}}/>} sx={{mb: 1, fontSize: '.7rem', color: '', border: '1px solid #80deea', background: red[50], width: '75%', boxShadow: 'none'}}>Delete</Button>

                        </Stack>
                    </Grid> */}
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            {/* <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: 'blue', '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Changes</Button> */}
        </DialogActions>
    </Dialog>
    )
}

export default ViewWorkerDialog
