export const PAYMENTTERMS_LIST_REQUEST = 'PAYMENTTERMS_LIST_REQUEST'
export const PAYMENTTERMS_LIST_SUCCESS = 'PAYMENTTERMS_LIST_SUCCESS'
export const PAYMENTTERMS_LIST_FAIL = 'PAYMENTTERMS_LIST_FAIL'


export const PAYMENTTERM_CREATE_REQUEST = 'PAYMENTTERM_CREATE_REQUEST'
export const PAYMENTTERM_CREATE_SUCCESS = 'PAYMENTTERM_CREATE_SUCCESS'
export const PAYMENTTERM_CREATE_FAIL = 'PAYMENTTERM_CREATE_FAIL'


export const PAYMENTTERM_UPDATE_REQUEST = 'PAYMENTTERM_UPDATE_REQUEST'
export const PAYMENTTERM_UPDATE_SUCCESS = 'PAYMENTTERM_UPDATE_SUCCESS'
export const PAYMENTTERM_UPDATE_FAIL = 'PAYMENTTERM_UPDATE_FAIL'

export const PAYMENTTERM_DELETE_REQUEST = 'PAYMENTTERM_DELETE_REQUEST'
export const PAYMENTTERM_DELETE_SUCCESS = 'PAYMENTTERM_DELETE_SUCCESS'
export const PAYMENTTERM_DELETE_FAIL = 'PAYMENTTERM_DELETE_FAIL'

export const VIEW_PAYMENTTERM_REQUEST = 'VIEW_PAYMENTTERM_REQUEST'
export const VIEW_PAYMENTTERM_SUCCESS = 'VIEW_PAYMENTTERM_SUCCESS'
export const VIEW_PAYMENTTERM_FAIL = 'VIEW_PAYMENTTERM_FAIL'