export const CLIENTSTATUSES_LIST_REQUEST = 'CLIENTSTATUSES_LIST_REQUEST';
export const CLIENTSTATUSES_LIST_SUCCESS = 'CLIENTSTATUSES_LIST_SUCCESS';
export const CLIENTSTATUSES_LIST_FAIL = 'CLIENTSTATUSES_LIST_FAIL'; 

export const CLIENTSTATUS_CREATE_REQUEST = 'CLIENTSTATUS_CREATE_REQUEST'
export const CLIENTSTATUS_CREATE_SUCCESS = 'CLIENTSTATUS_CREATE_SUCCESS'
export const CLIENTSTATUS_CREATE_FAIL = 'CLIENTSTATUS_CREATE_FAIL'


export const CLIENTSTATUS_UPDATE_REQUEST = 'CLIENTSTATUS_UPDATE_REQUEST'
export const CLIENTSTATUS_UPDATE_SUCCESS = 'CLIENTSTATUS_UPDATE_SUCCESS'
export const CLIENTSTATUS_UPDATE_FAIL = 'CLIENTSTATUS_UPDATE_FAIL'

export const CLIENTSTATUS_DELETE_REQUEST = 'CLIENTSTATUS_DELETE_REQUEST'
export const CLIENTSTATUS_DELETE_SUCCESS = 'CLIENTSTATUS_DELETE_SUCCESS'
export const CLIENTSTATUS_DELETE_FAIL = 'CLIENTSTATUS_DELETE_FAIL'

export const VIEW_CLIENTSTATUS_REQUEST = 'VIEW_CLIENTSTATUS_REQUEST'
export const VIEW_CLIENTSTATUS_SUCCESS = 'VIEW_CLIENTSTATUS_SUCCESS'
export const VIEW_CLIENTSTATUS_FAIL = 'VIEW_CLIENTSTATUS_FAIL'