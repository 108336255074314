export const INVOICETYPES_LIST_REQUEST = 'INVOICETYPES_LIST_REQUEST';
export const INVOICETYPES_LIST_SUCCESS = 'INVOICETYPES_LIST_SUCCESS';
export const INVOICETYPES_LIST_FAIL = 'INVOICETYPES_LIST_FAIL'; 

export const INVOICETYPE_CREATE_REQUEST = 'INVOICETYPE_CREATE_REQUEST'
export const INVOICETYPE_CREATE_SUCCESS = 'INVOICETYPE_CREATE_SUCCESS'
export const INVOICETYPE_CREATE_FAIL = 'INVOICETYPE_CREATE_FAIL'


export const INVOICETYPE_UPDATE_REQUEST = 'INVOICETYPE_UPDATE_REQUEST'
export const INVOICETYPE_UPDATE_SUCCESS = 'INVOICETYPE_UPDATE_SUCCESS'
export const INVOICETYPE_UPDATE_FAIL = 'INVOICETYPE_UPDATE_FAIL'

export const INVOICETYPE_DELETE_REQUEST = 'INVOICETYPE_DELETE_REQUEST'
export const INVOICETYPE_DELETE_SUCCESS = 'INVOICETYPE_DELETE_SUCCESS'
export const INVOICETYPE_DELETE_FAIL = 'INVOICETYPE_DELETE_FAIL'

export const VIEW_INVOICETYPE_REQUEST = 'VIEW_INVOICETYPE_REQUEST'
export const VIEW_INVOICETYPE_SUCCESS = 'VIEW_INVOICETYPE_SUCCESS'
export const VIEW_INVOICETYPE_FAIL = 'VIEW_INVOICETYPE_FAIL'