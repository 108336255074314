import { PAYROLLDOCTYPES_LIST_REQUEST, PAYROLLDOCTYPES_LIST_SUCCESS, PAYROLLDOCTYPES_LIST_FAIL} from "../Constants/payrollDocTypeConstants";

const payrollDocTypesListReducer = (state = {payrollDocTypes: []}, action) => {

    switch (action.type)
    {
        case PAYROLLDOCTYPES_LIST_REQUEST:
            return {loading: true};
 
        case PAYROLLDOCTYPES_LIST_SUCCESS:
            console.log('Code is reaching the reducer and payrollDocTypes data is:' + action.payload)
            return {
                loading: false,
                payrollDocTypes: action.payload
            }
        case PAYROLLDOCTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 


export {payrollDocTypesListReducer}