import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import { blueGrey, blue} from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { submitClient, getClientsList } from '../../../../../Redux/Actions/ClientsActions';
import { submitDivision } from '../../../../../Redux/Actions/DivisionActions';


const AddDivisionDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch(); 
    

    const [value, setValue] = useState('');


    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        setValue(value);  
    }

    const handleSave = () => {
        if(value !== '')
        {
            dispatch(submitDivision(value))
            handleSubmit('success', `Successfully added division: "${value}" to the list.`);
        }
        else
        {
            console.log('Error! Field is empty')
        }
    }

    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[600]}}  >
                
                <CheckCircleIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add Division </Typography>
            </DialogTitle>
     
        <Divider/>
        <DialogContent sx={{background: 'white'}}>
        
    
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                            
                        <Grid item lg={12}>
                            <Form className="my-3">
                            <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Info</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Division Name</Form.Label>
                                    <Form.Control name="name" type="email" value={value} onChange={handleChange} placeholder="Enter name ..." className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            
                        </Form>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[600], '&:hover': {color: blue[50]}}} onClick={handleSave}>Save Division</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddDivisionDialog
