import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField} from '@mui/material';
import { blueGrey, blue, purple, deepPurple, red } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import TaskIcon from '@mui/icons-material/Task';
import { useDispatch, useSelector } from 'react-redux';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getClientsList } from '../../../../../Redux/Actions/ClientsActions';
import { getTaskStatusesList } from '../../../../../Redux/Actions/TaskStatusActions';
import { getWorkers } from '../../../../../Redux/Actions/WorkerActions';
import { getTaskTypesList } from '../../../../../Redux/Actions/TaskTypeActions';
import { submitTask } from '../../../../../Redux/Actions/TasksActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SideCrumbs from '../../../../Utilities/SideCrumbs';
import ComplexAlert from '../../../../ComplexAlert';
import useAlert from '../../../../../Hooks/useAlert';



const AddTaskDialog = ({open, onClose, type, info, handleSubmit}) => {

    //ACCESS DISPATCH
    const dispatch = useDispatch();
    //GET TASKS
    const {tasks, loading: loading_tasks} = useSelector(state => state.tasks_List);
    const {divisions, loading} = useSelector(state => state.divisions_List);
    const {alert, showAlert}= useAlert();

   
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getStaffList());
        dispatch(getClientsList());
        dispatch(getTaskStatusesList());
        dispatch(getTaskTypesList());
        dispatch(getWorkers());
       
    }, [])
    
    

    const [values, setValues] = useState({
            name: '', 
            date: '', 
            deadline: '', 
            description: '', 
            staff: '', 
            divisions: '' , 
            tasktypes: '' , 
            clients: '' , 
            workers: '', 
            notes: '', 
            taskstatuses: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            name: '',
            date: '', 
            deadline: '', 
            description: '', 
            staff: '', 
            divisions: '' , 
            tasktypes: '' , 
            clients: '' , 
            workers: '', 
            notes: '', 
            taskstatuses: '' 
        });
    
    const handleSave = () => {
        const errors = []
        const forCheck = Object.values(submitValues)
        forCheck.forEach( item => item == ''? errors.push(item) : item)
        if(errors != '')
        {
            errors.map(x => console.log('x : ' + x))
            showAlert('error', 'All fields should be filled', true)
        }
        else{
            console.log('CHECK THIS IOT: ' + submitValues.date)
            dispatch(submitTask(submitValues,
                                {
                                    ...values,
                                    staff: values.staff.label, 
                                    divisions: values.divisions.label , 
                                    tasktypes: values.tasktypes.label , 
                                    clients: values.clients.label, 
                                    workers: values.workers.label, 
                                    taskstatuses: values.taskstatuses.label , 
                                }))
            handleSubmit('success', 'Successfully saved the task');
        }

    }

    //I WANT TO SEPARATE THE FUNCTIONALITY HERE
    
    
    const [inputValue, setInputValue] = useState('');
    
    const handleInputChange = (newValue) => {
        
        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
    
    const [summary, setSummary] = useState(false)

    const handleSummary = () => {
        setSummary(!summary);
    }
    const [date, setDate] = useState({
        date: '',
        deadline: '',
    });

    //const {date, deadline, handleDateChange} = useDate(values, setValues)

    const handleDateChange = (newValue, name) => {
        
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate({
            ...date,
            [name]: newValue
        });
        setValues({
            ...values,
            [name]: `${day}/${month+1}/${year}`
        })
        setSubmitValues({
            ...submitValues,
            [name]: `${day}/${month+1}/${year}`
        })
    }

   

    const taskTitle = <Typography 
                        sx={{background: '', borderRadius: '3px', height: '38px', width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', pl: 2, border: '', fontSize: '1.1rem',fontWeight: 600}}>
                        {values.description? values.description :  <span style={{color: blue[100], fontStyle: 'italic', fontSize: '.8rem'}}>__</span> } 
                        <span style={{color: blue[500], px: 3}}> | </span> 
                        {submitValues.date? submitValues.date: <span style={{color: blue[100], fontStyle: 'italic', fontSize: '.8rem'}}>__</span> }  
                        <span style={{color: blue[500]}}> | </span>  
                        {/* {values.workers? values.workers.length +  ' worker(s)' : <span style={{color: blue[100], fontStyle: 'italic', fontSize: '.8rem'}}>__</span>}  */}
                        {values.workers? values.workers.label : <span style={{color: blue[100], fontStyle: 'italic', fontSize: '.8rem'}}>__</span>} 
                     </Typography> 
    const animatedComponents = makeAnimated();


    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between',alignItems: 'center',fontSize: '1.3rem', background: blue[800], fontWeight: 900, color: 'white'}}  > 
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    <TaskIcon fontSize="large" sx={{color: blue[500], mr: 2, fontSize: '1.5rem'}}/> 
                    <Typography variant="h6" sx={{color: blue[500],fontWeight: 700, fontFamily: 'Poppins'}}> Add new Task </Typography>
                </Stack>
                {taskTitle}
            </DialogTitle>

        <Divider sx={{color: blue[500]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                
                    <Grid item lg={12} sx={{m: 0, p: 0, background: ''}}>
                        <Stack direction="row" alignItems="center" sx={{ background: 'blue[50]', mb: 0}}>
                            <Typography variant="body1" sx={{color: blue[900],fontWeight: 900, textAlign: 'center'}}>Form Summary</Typography>
                            <Button variant="text" size="small" sx={{fontSize: 12, color: blue[900], textAlign: 'center'}} onClick={handleSummary}>{summary? 'Close' : 'Open'}</Button>
                        </Stack>
                           
                    </Grid>

                    <Grid item lg={12} sx={{position: 'sticky', zIndex: 10}}>
                                
                    <Grid container flexDirection="row" justifyContent="space-between" className={`summary ${summary? 'open' : ''}`} sx={{px: 3, pt: '2px', background: '#f2f7fc', border: '2px solid whitesmoke', borderRadius: 3, boxShadow: 'none', overflowY: 'scroll'}}>
                                {/* <Divider sx={{border: '.5px solid gray'}}/> */}
                                {/* <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Task ID: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`TSK - ${!loading_assessments? assessments.length + 1 : ''}`} </Typography>
                                </Stack> */}
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Task Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.assessment_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                    <Typography variant="body2" sx={{color: values.subject? '' : blue[500], fontWeight: values.subject? 800 : 500, ml: 5}}>{values.subject? values.subject : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                    <Typography variant="body2" sx={{color: values.applicant? '' : blue[500], fontWeight: values.applicant? 800 : 500, ml: 5}}>{values.applicant? values.applicant : 'null' }</Typography>
                                </Stack>
                               
                                
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Task Type: </Typography>
                                    <Typography variant="body2" sx={{color: values.type? '' : blue[500], fontWeight: values.type? 800 : 500, ml: 5}}>{values.type? values.type : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Level: </Typography>
                                    <Typography variant="body2" sx={{color: values.level? '' : blue[500], fontWeight: values.level? 800 : 500, ml: 5}}>{values.level? values.level : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Task Result: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.result? 'white' : blue[500], fontSize: '.75rem', textAlign: 'left', px: values.result? '4px' : '', borderRadius: '3px' ,fontWeight: values.result? 500 : 500, background: values.result? blue[500] : ''}}>{values.result? values.result : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Task Status: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.status? 'white' : blue[500], fontSize: values.status? '.75rem' :'', textAlign: 'left', px: values.status? '4px' : '', borderRadius: '3px'  ,fontWeight: values.status? 500 : 500, background: values.status? blue[500] : ''}}>{values.status? values.status : 'null' }</Typography>
                                </Stack>
                                
                            
                            </Grid>
                    </Grid>
                    <SideCrumbs/>
                    <Grid item lg={9}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Task ID</Form.Label>
                                <Form.Control disabled type="email" value={`TSK-${tasks? tasks.length: '#'}`} placeholder="E.g 123qst1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Task Name</Form.Label>
                                <Form.Control disabled type="email" name="Task Name" value={'Autogenerated'} onChange={handleChange} className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                    
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Task Description</Form.Label>
                                <Form.Control type="text" name="description" value={values.description} onChange={handleChange} placeholder="A short description will do just fine ..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>


                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: deepPurple[500], borderRadius: '5px', px: 2, py: '5px'}}>Staff On Task</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Choose Division</Form.Label>
                                    <AsyncSelect
                                        value={values.divisions}
                                        name="divisions"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'divisions')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for division ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Task Assigned To</Form.Label>
                                    <AsyncSelect
                                        value={values.staff}
                                        name="staff"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'staff')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for staff ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>
                        <br/>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Related Worker</Form.Label>
                                    <AsyncSelect
                                        value={values.workers}
                                        name="workers"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'workers')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for worker ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Client Information</Typography>
                        <Row>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="view-label">Client Name</Form.Label>
                                <AsyncSelect
                                    value={values.clients}
                                    name="clients"
                                    cacheOptions
                                    loadOptions={(value, action) => loadOptions(value, 'clients')}
                                    defaultOptions
                                    onChange= {(value, action) => handleOptionChange(value, action)}
                                    components={animatedComponents}
                                    placeholder="Search for client ..."
                                    onInputChange={handleInputChange}
                                />
                        </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Task Time Allocation</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="date"
                                            label="Task Date"
                                            value={date.date}
                                            onChange={(e) => handleDateChange(e, "date")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="deadline"
                                            label="Task Deadline"
                                            value={date.deadline}
                                            onChange={(e) => handleDateChange(e, "deadline")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>

                            
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Task Items</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Task Type</Form.Label>
                                <AsyncSelect
                                    value={values.tasktypes}
                                    name="tasktypes"
                                    cacheOptions
                                    loadOptions={(value, action) => loadOptions(value, 'tasktypes')}
                                    defaultOptions
                                    onChange= {(value, action) => handleOptionChange(value, action)}
                                    components={animatedComponents}
                                    placeholder="Search for types ..."
                                    onInputChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Task Status</Form.Label>
                                <AsyncSelect
                                    value={values.taskstatuses}
                                    name="taskstatuses"
                                    cacheOptions
                                    loadOptions={(value, action) => loadOptions(value, 'taskstatuses')}
                                    defaultOptions
                                    onChange= {(value, action) => handleOptionChange(value, action)}
                                    components={animatedComponents}
                                    placeholder="Search for status ..."
                                    onInputChange={handleInputChange}
                                />
                            </Form.Group>

                        </Row>
                        <Row>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="view-label">Task Notes</Form.Label>
                            <Form.Control name="notes" value={values.notes} onChange={handleChange} type="text" as="textarea" placeholder="E.g Construction, etc." className="outline-none border-none" />
                        </Form.Group>
                        
                        
                    </Row>
                          
                        </Form>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[700], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Task</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddTaskDialog
