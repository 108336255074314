import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom'
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, LinearProgress} from '@mui/material';
import { blueGrey, blue, pink } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTask } from '../../../../../Redux/Actions/TasksActions';


 
const DeleteProfitLossDialog = ({open, onClose, type, info, handleSubmit, task}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    

    const handleDelete = () => {
        console.log('in save function')
        dispatch(deleteTask(info.Id))
        handleSubmit('error', 'Task successfully deleted');
        navigate('/tasks')

    }
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >

         
            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, background: pink[500], color: 'white'}}  >
                
                <DeleteIcon fontSize="large" sx={{color: pink[50], mr: 2, fontSize: '1.5rem'}}/> 
                
                    <Typography variant="h6" sx={{color: 'white', fontFamily: 'Poppins',fontWeight: 700}}> About to Delete Task </Typography>
            </DialogTitle>
     
        <Divider/>

        
        <DialogContent sx={{background: 'white'}}>
            <Typography sx={{fontSize: '1.4rem', fontFamily: 'Poppins-Medium', fontWeight: '800', color: blue[500]}}>{`${info.Task_Name} | ${info.Date} | ${info.Worker} `}</Typography>
            <Divider sx={{color: blue[400], mt: 2}}/>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" sx={{position: 'relative', mt: 3}}>
                    
                    <Grid item lg={12}>
                        <Typography variant="body2" sx={{display: 'inline',color: blueGrey[200], fontSize: 17, fontFamily: 'Poppins', background: 'blue[700]', borderRadius: '5px', py: '5px',}}>Confirm deletion</Typography>
                        <Typography variant="h6" sx={{ fontFamily: 'Poppins',fontWeight: 800, mt: 1}}>Are you sure you want to delete the task?</Typography>
                    </Grid>

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: pink[500], '&:hover': {color: blueGrey[900]}}} onClick={handleDelete}>Delete Task</Button>
        </DialogActions>
    </Dialog>
    )
}

export default DeleteProfitLossDialog
