import { ASSESSMENTTYPE_LIST_REQUEST, ASSESSMENTTYPE_LIST_FAIL, ASSESSMENTTYPE_LIST_SUCCESS} from '../Constants/assessmentTypesConstants';
import api from '../../config/apiConfig';


const getAssessmentTypesList = () => async(dispatch) => {
    //const data = assessmenttypes;
 
    try {
        dispatch({type: ASSESSMENTTYPE_LIST_REQUEST});
        const {data} = await api.get('/assessmenttypes');
        //console.log('AssessmentTypes data is:' + data[0]['AssessmentTypes_Name'])
        dispatch({type: ASSESSMENTTYPE_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: ASSESSMENTTYPE_LIST_FAIL, payload: error})
    }
} 

export {getAssessmentTypesList }