export const INVOICES_LIST_REQUEST = 'INVOICES_LIST_REQUEST'
export const INVOICES_LIST_SUCCESS = 'INVOICES_LIST_SUCCESS'
export const INVOICES_LIST_FAIL = 'INVOICES_LIST_FAIL'


export const INVOICE_CREATE_REQUEST = 'INVOICE_CREATE_REQUEST'
export const INVOICE_CREATE_SUCCESS = 'INVOICE_CREATE_SUCCESS'
export const INVOICE_CREATE_FAIL = 'INVOICE_CREATE_FAIL'


export const INVOICE_UPDATE_REQUEST = 'INVOICE_UPDATE_REQUEST'
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS'
export const INVOICE_UPDATE_FAIL = 'INVOICE_UPDATE_FAIL'

export const INVOICE_DELETE_REQUEST = 'INVOICE_DELETE_REQUEST'
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS'
export const INVOICE_DELETE_FAIL = 'INVOICE_DELETE_FAIL'

export const VIEW_INVOICE_REQUEST = 'VIEW_INVOICE_REQUEST'
export const VIEW_INVOICE_SUCCESS = 'VIEW_INVOICE_SUCCESS'
export const VIEW_INVOICE_FAIL = 'VIEW_INVOICE_FAIL'