import { PAYERPAYEETYPES_LIST_REQUEST, PAYERPAYEETYPES_LIST_SUCCESS, PAYERPAYEETYPES_LIST_FAIL, VIEW_PAYERPAYEETYPE_FAIL, VIEW_PAYERPAYEETYPE_REQUEST, VIEW_PAYERPAYEETYPE_SUCCESS, PAYERPAYEETYPE_CREATE_FAIL, PAYERPAYEETYPE_UPDATE_REQUEST, PAYERPAYEETYPE_UPDATE_FAIL, PAYERPAYEETYPE_DELETE_REQUEST, PAYERPAYEETYPE_DELETE_FAIL, PAYERPAYEETYPE_CREATE_REQUEST, PAYERPAYEETYPE_CREATE_SUCCESS, PAYERPAYEETYPE_UPDATE_SUCCESS, PAYERPAYEETYPE_DELETE_SUCCESS } from "../Constants/payerPayeeTypeConstants";

const payerPayeeTypesListReducer = (state = {payerPayeeTypes: []}, action) => {

    switch (action.type)
    {
        case PAYERPAYEETYPES_LIST_REQUEST:
            return {loading: true};
 
        case PAYERPAYEETYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and payerPayeeTypes data is:' + action.payload)
            return {
                loading: false,
                payerPayeeTypes: action.payload
            }
        case PAYERPAYEETYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const payerPayeeTypeCreateReducer = (state = {payerPayeeTypes: []}, action) => {

    switch (action.type) {

        case PAYERPAYEETYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case PAYERPAYEETYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                payerPayeeTypes: [...state.payerPayeeTypes, {Applicant_Id: 2, Name: value}]
            };

        case PAYERPAYEETYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const payerPayeeTypeViewReducer = (state= {payerPayeeType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_PAYERPAYEETYPE_REQUEST:
            return {loading: true};
        case VIEW_PAYERPAYEETYPE_SUCCESS:
            return {
                    loading: false,
                    payerPayeeType: action.payload
                }
        case VIEW_PAYERPAYEETYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const payerPayeeTypeUpdateReducer = (state = {payerPayeeTypes: {}}, action) => {

    switch (action.type) {

        case PAYERPAYEETYPE_UPDATE_REQUEST:
            return {
                
            }
        case PAYERPAYEETYPE_UPDATE_SUCCESS:

            return {

            }
        case PAYERPAYEETYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const payerPayeeTypeDeleteReducer = (state = {payerPayeeTypes: {}}, action) => {

    switch (action.type) {
        case PAYERPAYEETYPE_DELETE_REQUEST:
            return {
                
            }
        case PAYERPAYEETYPE_DELETE_SUCCESS:
            return {

            }
        case PAYERPAYEETYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {payerPayeeTypesListReducer, payerPayeeTypeCreateReducer, payerPayeeTypeViewReducer, payerPayeeTypeUpdateReducer, payerPayeeTypeDeleteReducer }