import React, {useState, useEffect} from 'react';
import { Paper, IconButton, Grid, Divider,LinearProgress, Stack, Typography, Breadcrumbs, Link, List, ListItemText, ToggleButtonGroup, ToggleButton, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../Hooks/useDialog';
import { blueGrey, red, yellow, teal, blue, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import { getAssessmentsList } from '../../../Redux/Actions/AssessmentsActions';
import FilterListIcon from '@mui/icons-material/FilterList';
import AssessmentsList from '../../../Components/Assessments/AssessmentsList';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import Crumbs from '../../../Components/Utilities/Crumbs';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import AssessmentDialogs from '../../../Components/Dialogs/CRM/HR/AssessmentDialogs/AssessmentDialogs';
import AssessmentsTbl from '../../../Components/Table/CRM/HR/AssessmentsTbl';

 
 
 
const Assessments = () => { 


    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET APPLICANTS FROM DB
    const assessment_List_State = useSelector((state) => state.assessments_List);
    const {loading, assessments} = assessment_List_State;
    const [list, setList] = useState([])

//----------------------------------------------------------------------------------------------------------------------------------
    
    //ON COMPONENT DID MOUNT
    useEffect(()=> {
        dispatch(getAssessmentsList());
    }, [])

    useEffect(() => {
        if(assessments)
        {
            setList([...assessments])
        }
     }, [assessments])

    


    
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //TABLE DIALOG FUNCTIONALITY
    
        const handleAddClick = () => {
            console.log('THIS IS WORKING')
            showDialog(true, 'create', '');
        }
        const handleEditClick = () => {
            console.log('THIS IS WORKING')
            showDialog(true, 'view', '');
        }


    //---------------------------------------------------------------------------------------------------------------------------------- 
    //TODO: CRUD FUNCTIONS
        
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)

    const [view, setView] = useState('table')
    
    //----------------------------------------------------------------------------------------------------------------------------------
     
    return (
        <>
           
             <ScreenHeader title="Assessments" subtitle="Manage all assessments from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>

             <Grid container justifyContent="space-between" sx={{mb: 2}}>
                <Crumbs root="CRM" middle="Tasks" end="Current Tasks" main/>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <AddButtonLabel item="Assessment"/>
                    <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                        <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                    </IconButton>
                </Stack>
            </Grid>


            { dialog.open ?  <AssessmentDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>: ''}
           

            <Divider sx={{color: lightBlue[800]}}/>
            { alert.show ?  <ComplexAlert alert={alert} removeAlert={showAlert}/> : ''}
            { loading ?  <HorizontalLoading/> : '' }

            <HorizontalSummaryBox primary="Assessments Brief" secondary="Overall Assessments count" label1="All Assessments" label2="Second Label" label3="Third Label" count={[2,11,6]}/>
            
            <Grid container justifyContent="flex-end" alignItems="center">
                <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{p: 0, ml: 0, background: ''}}>
                    <FilterListIcon sx={{ color: blueGrey[600], mr: 0}} />
                    <ToggleButtonGroup value ={view} size="small" color="primary" exclusive>
                        <ToggleButton size="small" value="table" onClick={()=> setView('table')} >Table </ToggleButton>
                        <ToggleButton size="small" value="cards" onClick={()=> setView('cards')} >Cards </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
            </Grid>
            
                <AboveTableHeader title="Assessments List"/>
              {
                  loading ? "" : !loading && view == 'cards'?
              
              <>
                <AssessmentsList assessments={assessments}/>
                
              </>
               :
               <Grid container>
                 <AssessmentsTbl assessments={list} /> 
               </Grid>
          }
            

    </>
    )
}

export default Assessments
