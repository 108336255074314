export const RECONCILES_LIST_REQUEST = 'RECONCILES_LIST_REQUEST'
export const RECONCILES_LIST_SUCCESS = 'RECONCILES_LIST_SUCCESS'
export const RECONCILES_LIST_FAIL = 'RECONCILES_LIST_FAIL'


export const RECONCILE_CREATE_REQUEST = 'RECONCILE_CREATE_REQUEST'
export const RECONCILE_CREATE_SUCCESS = 'RECONCILE_CREATE_SUCCESS'
export const RECONCILE_CREATE_FAIL = 'RECONCILE_CREATE_FAIL'


export const RECONCILE_UPDATE_REQUEST = 'RECONCILE_UPDATE_REQUEST'
export const RECONCILE_UPDATE_SUCCESS = 'RECONCILE_UPDATE_SUCCESS'
export const RECONCILE_UPDATE_FAIL = 'RECONCILE_UPDATE_FAIL'

export const RECONCILE_DELETE_REQUEST = 'RECONCILE_DELETE_REQUEST'
export const RECONCILE_DELETE_SUCCESS = 'RECONCILE_DELETE_SUCCESS'
export const RECONCILE_DELETE_FAIL = 'RECONCILE_DELETE_FAIL'

export const VIEW_RECONCILE_REQUEST = 'VIEW_RECONCILE_REQUEST'
export const VIEW_RECONCILE_SUCCESS = 'VIEW_RECONCILE_SUCCESS'
export const VIEW_RECONCILE_FAIL = 'VIEW_RECONCILE_FAIL'