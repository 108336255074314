export const LIABILITYTYPES_LIST_REQUEST = 'LIABILITYTYPES_LIST_REQUEST'
export const LIABILITYTYPES_LIST_SUCCESS = 'LIABILITYTYPES_LIST_SUCCESS'
export const LIABILITYTYPES_LIST_FAIL = 'LIABILITYTYPES_LIST_FAIL'


export const LIABILITYTYPE_CREATE_REQUEST = 'LIABILITYTYPE_CREATE_REQUEST'
export const LIABILITYTYPE_CREATE_SUCCESS = 'LIABILITYTYPE_CREATE_SUCCESS'
export const LIABILITYTYPE_CREATE_FAIL = 'LIABILITYTYPE_CREATE_FAIL'


export const LIABILITYTYPE_UPDATE_REQUEST = 'LIABILITYTYPE_UPDATE_REQUEST'
export const LIABILITYTYPE_UPDATE_SUCCESS = 'LIABILITYTYPE_UPDATE_SUCCESS'
export const LIABILITYTYPE_UPDATE_FAIL = 'LIABILITYTYPE_UPDATE_FAIL'

export const LIABILITYTYPE_DELETE_REQUEST = 'LIABILITYTYPE_DELETE_REQUEST'
export const LIABILITYTYPE_DELETE_SUCCESS = 'LIABILITYTYPE_DELETE_SUCCESS'
export const LIABILITYTYPE_DELETE_FAIL = 'LIABILITYTYPE_DELETE_FAIL'

export const VIEW_LIABILITYTYPE_REQUEST = 'VIEW_LIABILITYTYPE_REQUEST'
export const VIEW_LIABILITYTYPE_SUCCESS = 'VIEW_LIABILITYTYPE_SUCCESS'
export const VIEW_LIABILITYTYPE_FAIL = 'VIEW_LIABILITYTYPE_FAIL'