export const SITES_LIST_REQUEST = 'SITES_LIST_REQUEST';
export const SITES_LIST_SUCCESS = 'SITES_LIST_SUCCESS';
export const SITES_LIST_FAIL = 'SITES_LIST_FAIL'; 

export const SITE_CREATE_REQUEST = 'SITE_CREATE_REQUEST'
export const SITE_CREATE_SUCCESS = 'SITE_CREATE_SUCCESS'
export const SITE_CREATE_FAIL = 'SITE_CREATE_FAIL'


export const SITE_UPDATE_REQUEST = 'SITE_UPDATE_REQUEST'
export const SITE_UPDATE_SUCCESS = 'SITE_UPDATE_SUCCESS'
export const SITE_UPDATE_FAIL = 'SITE_UPDATE_FAIL'

export const SITE_DELETE_REQUEST = 'SITE_DELETE_REQUEST'
export const SITE_DELETE_SUCCESS = 'SITE_DELETE_SUCCESS'
export const SITE_DELETE_FAIL = 'SITE_DELETE_FAIL'

export const VIEW_SITE_REQUEST = 'VIEW_SITE_REQUEST'
export const VIEW_SITE_SUCCESS = 'VIEW_SITE_SUCCESS'
export const VIEW_SITE_FAIL = 'VIEW_SITE_FAIL'