import React, { useEffect, useState } from 'react';
import DataTable from '../../DataTable';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import ProspectDialogs from '../../../Dialogs/CRM/Sales/ProspectDialogs/ProspectDialogs';

const ProspectsTbl = ({prospects}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const getJRColor = (jr) => {
        return jr === 'Class 1' ? blue[500] : jr === 'Class 2'?  pink[500] : jr === 'Construction'? orange[500] : jr === 'FLT'? lightGreen[500] : blueGrey[500]
    }
    const getStatusColor = (status) => {
        return status === 'New' ? blue[500] : status === 'Active'?  lightGreen[500] : status === 'Multi'? orange[500] : status === 'Leaver'? lightGreen[500] : blueGrey[500]
    }

    const switchToViewProspect = (id) => {
        navigate('/prospect/'+ id)
    } 

    const prospectQuickView = (id) => {

        showDialog(true, 'view', id)
      }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Name', headerName: 'Name', width: 150, renderCell: (params) => <Typography onClick={()=> {prospectQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Name}`}</Typography>},
        { field: 'CompanyNo', headerName: 'CompanyNo', width: 150},
        { field: 'VATNo', headerName: 'VATNo', width: 150},
        { field: 'CreditRating', headerName: 'Credit Rating', width: 150},
        { field: 'CreditLimit', headerName: 'Credit Limit', width: 150},
        { field: 'MainLocation', headerName: 'Main Location', width: 150},
        { field: 'NumberOfBranches', headerName: '# Of Branches', width: 150},
        { field: 'WorkersUsed', headerName: 'Workers Used', width: 150},
        { field: 'Contact', headerName: 'Contact', width: 150},
        { field: 'Client', headerName: 'Client', width: 150},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewProspect(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ];



    return (
        <>
        { dialog.open  ?  <ProspectDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }

            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={prospects}
                        loading={!prospects.length}
                    />
                </div>
            </div>
        </>
       
    )
}

export default ProspectsTbl
