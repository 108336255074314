import { TIMESHEETS_LIST_REQUEST, TIMESHEETS_LIST_SUCCESS, TIMESHEETS_LIST_FAIL, VIEW_TIMESHEET_FAIL, VIEW_TIMESHEET_REQUEST, VIEW_TIMESHEET_SUCCESS, TIMESHEET_CREATE_REQUEST, TIMESHEET_CREATE_SUCCESS, TIMESHEET_CREATE_FAIL, TIMESHEET_UPDATE_FAIL, TIMESHEET_UPDATE_REQUEST, TIMESHEET_UPDATE_SUCCESS } from '../Constants/timesheetConstants';
import api from '../../config/apiConfig';


const getTimesheetsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: TIMESHEETS_LIST_REQUEST});
        const {data} = await api.get('/timesheets');
        console.log('Timesheets data is:' + data)
        dispatch({type: TIMESHEETS_LIST_SUCCESS, payload: data})
         
        
    } catch (error) {
        dispatch({type: TIMESHEETS_LIST_FAIL, payload: error})
    }
} 

const submitTimesheet = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: TIMESHEET_CREATE_REQUEST});
        
        const {data} = await api.post(`/timesheets/`, values);
        console.log('Timesheet data is:' + data)
        dispatch({type: TIMESHEET_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: TIMESHEET_CREATE_FAIL, payload: error})
    }
} 

const getTimesheet = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_TIMESHEET_REQUEST});
        
        const {data} = await api.get(`/timesheets/ ${id}`);
        console.log('Timesheet data is:' + data)
        dispatch({type: VIEW_TIMESHEET_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_TIMESHEET_FAIL, payload: error})
    }
}

const editTimesheet = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: TIMESHEET_UPDATE_REQUEST});
        
        const {data} = await api.put(`/timesheets/`, values);
        console.log('Timesheet data is:' + data)
        dispatch({type: TIMESHEET_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: TIMESHEET_UPDATE_FAIL, payload: error})
    }
}



export {getTimesheetsList, submitTimesheet, getTimesheet, editTimesheet }