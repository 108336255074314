export const DEPARTMENTS_LIST_REQUEST = 'DEPARTMENTS_LIST_REQUEST'
export const DEPARTMENTS_LIST_SUCCESS = 'DEPARTMENTS_LIST_SUCCESS'
export const DEPARTMENTS_LIST_FAIL = 'DEPARTMENTS_LIST_FAIL'


export const DEPARTMENT_CREATE_REQUEST = 'DEPARTMENT_CREATE_REQUEST'
export const DEPARTMENT_CREATE_SUCCESS = 'DEPARTMENT_CREATE_SUCCESS'
export const DEPARTMENT_CREATE_FAIL = 'DEPARTMENT_CREATE_FAIL'


export const DEPARTMENT_UPDATE_REQUEST = 'DEPARTMENT_UPDATE_REQUEST'
export const DEPARTMENT_UPDATE_SUCCESS = 'DEPARTMENT_UPDATE_SUCCESS'
export const DEPARTMENT_UPDATE_FAIL = 'DEPARTMENT_UPDATE_FAIL'

export const DEPARTMENT_DELETE_REQUEST = 'DEPARTMENT_DELETE_REQUEST'
export const DEPARTMENT_DELETE_SUCCESS = 'DEPARTMENT_DELETE_SUCCESS'
export const DEPARTMENT_DELETE_FAIL = 'DEPARTMENT_DELETE_FAIL'

export const VIEW_DEPARTMENT_REQUEST = 'VIEW_DEPARTMENT_REQUEST'
export const VIEW_DEPARTMENT_SUCCESS = 'VIEW_DEPARTMENT_SUCCESS'
export const VIEW_DEPARTMENT_FAIL = 'VIEW_DEPARTMENT_FAIL'