import React, { useEffect, useState } from 'react';
import DataTable from '../../DataTable';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import PaymentDialogs from '../../../Dialogs/CRM/Staff/PaymentDialogs/PaymentDialogs';

const PaymentsTbl = ({payments}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const getJRColor = (jr) => {
        return jr === 'Class 1' ? blue[500] : jr === 'Class 2'?  pink[500] : jr === 'Construction'? orange[500] : jr === 'FLT'? lightGreen[500] : blueGrey[500]
    }
    const getStatusColor = (status) => {
        return status === 'New' ? blue[500] : status === 'Active'?  lightGreen[500] : status === 'Multi'? orange[500] : status === 'Leaver'? lightGreen[500] : blueGrey[500]
    }

    const switchToViewPayment = (id) => {
        navigate('/payment/'+ id)
    } 

    const checkInQuickView = (id) => {

        showDialog(true, 'view', id)
      }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Name', headerName: 'Full Name', width: 250, renderCell: (params) => <Typography onClick={()=> {checkInQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Worker} - ${params.row.Date} - ${params.row.Staff}`}</Typography>},
        { field: 'Worker', headerName: 'Worker'},
        { field: 'Status', headerName: 'Status', type: 'singleSelect', valueOptions: (['New', 'Active', 'Multi', 'Leaver', 'Archived']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        { field: 'WorkerShiftStatus', headerName: 'Worker Status', type: 'singleSelect', valueOptions: (['New', 'Active', 'Multi', 'Leaver', 'Archived']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.WorkerShiftStatus), borderRadius: '30px', px: '10px'}}>{params.row.WorkerShiftStatus}</Typography>
        )},

        { field: 'Division', headerName: 'Division', type: 'singleSelect', valueOptions: (['Construction', 'Driving', 'Industrial', 'Aviation'])},

        { field: 'JRole', headerName: 'Job Role',type: 'singleSelect',  valueOptions: (['Class 1', 'Driving', 'Industrial', 'Aviation']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getJRColor(params.row.JRole), borderRadius: '30px', px: '10px'}}>{params.row.JRole}</Typography>
        )},

        { field: 'Staff', headerName: 'Consultant'},
        { field: 'Rate', headerName: 'Rate'},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewPayment(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ];



    return (
        <>
        { dialog.open  ?  <PaymentDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }

            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={payments}
                        loading={!payments.length}
                    />
                </div>
            </div>
        </>
       
    )
}

export default PaymentsTbl
