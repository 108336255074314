import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import ApplicantDialogs from '../../../Dialogs/CRM/HR/ApplicantDialogs/ApplicantDialogs';

const ApplicantsTbl = ({applicants}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const getJRColor = (jr) => {
        return jr === 'Class 1' ? blue[500] : jr === 'Class 2'?  pink[500] : jr === 'Construction'? orange[500] : jr === 'FLT'? lightGreen[500] : blueGrey[500]
    }
    const getStatusColor = (status) => {
        return status === 'New' ? blue[500] : status === 'Registered'?  lightGreen[500] : status === 'Compliant'? orange[500] : status === 'Approved'? lightGreen[500] : status === 'Not Suitable'? yellow[800] : blueGrey[500]
    }
    const getProspectColor = (status) => {
        return status === 'Hot' ? red[500] : status === 'Warm'?  yellow[800] : status === 'Cold'? lightBlue[500] : status === 'Frozen'? blue[800] : blueGrey[500]
    }

    const switchToViewApplicant = (id) => {
        navigate('/applicant/'+ id)
    } 

    const applicantQuickView = (id) => {

        showDialog(true, 'view', id)
    }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'fullName', headerName: 'Full Name', renderCell: (params) => <Typography onClick={()=> {applicantQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.First_Name} ${params.row.Surname}`}</Typography>},
        { field: 'First_Name', headerName: 'First Name'},
        { field: 'Surname', headerName: 'Surname'},
        { field: 'Status', headerName: 'Status', type: 'singleSelect', valueOptions: (['New', 'Registered', 'Compliant', 'Approved', 'Not Suitable', 'Archived']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},

        { field: 'Division', headerName: 'Division', type: 'singleSelect', valueOptions: (['Construction', 'Driving', 'Industrial', 'Aviation'])},

        { field: 'Job_Role', headerName: 'Job Role',type: 'singleSelect',  valueOptions: (['Class 1', 'Driving', 'Industrial', 'Aviation']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getJRColor(params.row.Job_Role), borderRadius: '30px', px: '10px'}}>{params.row.Job_Role}</Typography>
        )},

        { field: 'Application_Date', headerName: 'Application Date'},
        { field: 'Email', headerName: 'Email'},
        { field: 'Mobile', headerName: 'Mobile'},
        { field: 'Rating', headerName: 'Rating'},
        { field: 'Prospect', headerName: 'Prospect',type: 'singleSelect',  valueOptions: (['Hot', 'Warm', 'Cold', 'Frozen']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getProspectColor(params.row.Prospect), borderRadius: '30px', px: '10px'}}>{params.row.Prospect}</Typography>
        )},
        { field: 'Home_Phone', headerName: 'Home Phone'},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewApplicant(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ];

    return (
        
        <>
            { dialog.open  ?  <ApplicantDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={applicants}
                        loading={!applicants.length}
                    />
                </div>
            </div>
        </>
    )
}

export default ApplicantsTbl
