import React, { useState, useRef } from 'react'
import KanbanAPI from '../../../../api/KanbanAPI';

const Dropzone = () => {

    const [active, setActive] = useState(false);
    let dropzone = useRef(null)

    const handleDragOver = (e) => {
        e.preventDefault();
        e.target.classList.add('newkanban-dropzone-active')
        setActive(true);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        const columnElement = dropzone.closest(".newkanban-column");
        const columnId = Number(columnElement.dataset.id);
        const dropZonesInColumn = Array.from(columnElement.querySelectorAll(".newkanban-dropzone"));
        const droppedIndex = dropZonesInColumn.indexOf(dropzone);
        const itemId = Number(e.dataTransfer.getData("text/plain"));
        const droppedItemElement = document.querySelector(`[data-id="${itemId}"]`);
        const insertAfter = dropzone.parentElement.classList.contains("newkanban-item")? dropzone.parentElement : dropzone;

        // if(droppedItemElement.contains(e.target)){
        //     return;
        // }
        
        insertAfter.after(droppedItemElement)
        KanbanAPI.updateItem(itemId,{columnId,position: droppedIndex});
        setActive(false);

        console.log('Zones in that column:' + insertAfter);
    }
    return (
        <>
            <div 
                onDragOver={handleDragOver}
                onDragLeave={() => setActive(false)}
                onDrop ={handleDrop}
                className={active? "newkanban-dropzone-active" : "newkanban-dropzone"}
                ref={el => dropzone = el}> 
            </div>
                
        </>
    )
}

export default Dropzone
