import React from 'react'
import ContactCard from './ContactCard';
import { Grid } from '@mui/material';

const ContactsList = ({contacts}) => {
    return (
        <>
        <Grid container justifyContent="flex-start" alignItems="center" sx={{background: ''}}>
        {
            contacts.map(x => 
                
                    <ContactCard contact={x}/>
                )
        }
        </Grid>
        </>
    )
}

export default ContactsList
