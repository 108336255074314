import React from 'react'
import { Form, Row, Col } from 'react-bootstrap';
import { Divider, Typography } from '@mui/material';
import { teal, blue } from '@mui/material/colors';
import useForm from '../../Hooks/useForm';
import tasks from '../../Data/Tasks/Tasks';

const TasksDialogContent = () => {

     
    const {handleChange, values} = useForm(tasks)
    const handleSubmit = () => {
       
    }

    return (
        <>
        {/* <Typography variant="h4">Division: {values['Related Division']}</Typography>
        <Typography variant="h4">Id: {values.Id}</Typography>
        <Typography variant="h4">Description: {values['Task Description']}</Typography> */}
            <Form className="">
            <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 1}}>Basic Information</Typography>
                    <Row>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="text-dark">Task ID</Form.Label>
                            <Form.Control disabled type="email" value="TSK-1" placeholder="E.g 123qst1" className="outline-none border-none" />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="text-dark">Task Name</Form.Label>
                            <Form.Control type="email" name="Task Name" value={values['Task Name']} onChange={handleChange} placeholder="E.g Deliver Banners to IBM" className="outline-none border-none" />
                        </Form.Group>
                    </Row>
                    
                    <Row>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="text-dark">Task Description</Form.Label>
                            <Form.Control type="email" name="Task Description" value={values['Task Description']} onChange={handleChange} placeholder="A short description will do just fine ..." className="outline-none border-none" />
                        </Form.Group>
                    </Row>


                    <br/>
                    <Typography variant="body2" sx={{display: 'inline',color: 'white',background: teal[500], borderRadius: '5px', px: 1}}>Staff On Task</Typography>
                    <Row>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="text-dark">Choose Division</Form.Label>
                            <Form.Select defaultValue="Choose Division" value={values['Related Division']} name="Related Division" onChange={handleChange}>
                                <option>Driving</option>
                                <option>Industrial</option>
                                <option>Construction</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="text-dark">Task Assigned To</Form.Label>
                            <Form.Select defaultValue="Choose Division" value={values['Task Assignee']} name="Task Assignee" onChange={handleChange}>
                                <option>Mirriam Jub</option>
                                <option>Indu Caver</option>
                                <option>Giur Harry</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="text-dark">Related Staff</Form.Label>
                            <Form.Select defaultValue="Choose Division" value={values['Related Staff']} name="Related Staff" onChange={handleChange}>
                                <option>Driving</option>
                                <option>Industrial</option>
                                <option>Construction</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row>
                        
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="text-dark">Workers Involved</Form.Label>
                            <Form.Select defaultValue="Choose Division" value={values['Workers Involved']} name="Workers Involved" onChange={handleChange}>
                                <option>Michael Smith</option>
                                <option>Buble Kodak</option>
                                <option>Wendy Gei</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <br/>
                    <Typography variant="body2" sx={{display: 'inline',color: 'white',background: teal[500], borderRadius: '5px', px: 1}}>Client Informati0n</Typography>
                    <Row>
                    <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                        <Form.Label className="text-dark">Client Name</Form.Label>
                        <Form.Select defaultValue="Choose Division" value={values['Client Name']} name="Client Name" onChange={handleChange}>
                            <option>IBM</option>
                            <option>Buble Kodak</option>
                            <option>Wendy Gei</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                        <Form.Label className="text-dark">Client ID</Form.Label>
                        <Form.Control disabled type="email" value={()=>{if(values['Client Name'] === 'IBM'); return 0}} name="Client Involved"  placeholder="This is autogenerated" className="outline-none border-none" />
                    </Form.Group>
                    </Row>

                    <br/>
                    <Typography variant="body2" sx={{display: 'inline',color: 'white',background: teal[500], borderRadius: '5px', px: 1}}>Task Time Allocation</Typography>
                    <Row>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="text-dark">Task Date</Form.Label>
                            <Form.Control type="email" placeholder="E.g Construction, etc." className="outline-none border-none" />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="text-dark">Task Deadline</Form.Label>
                            <Form.Control type="email" placeholder="E.g Jim Jones" className="outline-none border-none" />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                        <Form.Label className="text-dark">Task Status</Form.Label>
                        <Form.Select defaultValue="Choose Status" value={values['Task Status']} name="Task Status" onChange={handleChange}>
                            <option>New task</option>
                            <option>In Progress</option>
                            <option>Completed</option>
                        </Form.Select>
                    </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="text-dark">Task Notes</Form.Label>
                            <Form.Control type="email" as="textarea" placeholder="E.g Construction, etc." className="outline-none border-none" />
                        </Form.Group>
                        
                        
                    </Row>
                </Form>
        </>
    )
}

export default TasksDialogContent
