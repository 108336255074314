import { REQUESTSTATUSES_LIST_REQUEST, REQUESTSTATUSES_LIST_SUCCESS, REQUESTSTATUSES_LIST_FAIL, VIEW_REQUESTSTATUS_FAIL, VIEW_REQUESTSTATUS_REQUEST, VIEW_REQUESTSTATUS_SUCCESS, REQUESTSTATUS_CREATE_FAIL, REQUESTSTATUS_UPDATE_REQUEST, REQUESTSTATUS_UPDATE_FAIL, REQUESTSTATUS_DELETE_REQUEST, REQUESTSTATUS_DELETE_FAIL, REQUESTSTATUS_CREATE_REQUEST, REQUESTSTATUS_CREATE_SUCCESS, REQUESTSTATUS_UPDATE_SUCCESS, REQUESTSTATUS_DELETE_SUCCESS } from "../Constants/requestStatusConstants";

const requestStatusesListReducer = (state = {requestStatuses: []}, action) => {

    switch (action.type)
    {
        case REQUESTSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case REQUESTSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and requestStatuss data is:' + action.payload)
            return {
                loading: false,
                requestStatuses: action.payload
            }
        case REQUESTSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const requestStatusCreateReducer = (state = {requestStatuses: []}, action) => {

    switch (action.type) {

        case REQUESTSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case REQUESTSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                requestStatuses: [...state.requestStatuses, {Applicant_Id: 2, Name: value}]
            };

        case REQUESTSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const requestStatusViewReducer = (state= {requestStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_REQUESTSTATUS_REQUEST:
            return {loading: true};
        case VIEW_REQUESTSTATUS_SUCCESS:
            return {
                    loading: false,
                    requestStatus: action.payload
                }
        case VIEW_REQUESTSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const requestStatusUpdateReducer = (state = {requestStatuss: {}}, action) => {

    switch (action.type) {

        case REQUESTSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case REQUESTSTATUS_UPDATE_SUCCESS:

            return {

            }
        case REQUESTSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const requestStatusDeleteReducer = (state = {requestStatuss: {}}, action) => {

    switch (action.type) {
        case REQUESTSTATUS_DELETE_REQUEST:
            return {
                
            }
        case REQUESTSTATUS_DELETE_SUCCESS:
            return {

            }
        case REQUESTSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {requestStatusesListReducer, requestStatusCreateReducer, requestStatusViewReducer, requestStatusUpdateReducer, requestStatusDeleteReducer }