import { BRANCHES_LIST_REQUEST, BRANCHES_LIST_SUCCESS, BRANCHES_LIST_FAIL, VIEW_BRANCH_FAIL, VIEW_BRANCH_REQUEST, VIEW_BRANCH_SUCCESS, BRANCH_CREATE_REQUEST, BRANCH_CREATE_SUCCESS, BRANCH_CREATE_FAIL, BRANCH_UPDATE_FAIL, BRANCH_UPDATE_REQUEST, BRANCH_UPDATE_SUCCESS } from '../Constants/branchConstants';
import api from '../../config/apiConfig';


const getBranchesList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: BRANCHES_LIST_REQUEST});
        const {data} = await api.get('/branches');
        // console.log('Branch data is:' + data[0]['Branch_Name'])
        dispatch({type: BRANCHES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: BRANCHES_LIST_FAIL, payload: error})
    }
} 

const submitBranch = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: BRANCH_CREATE_REQUEST});
        
        const {data} = await api.post(`/branches/`, values);
        console.log('Branch data is:' + data)
        dispatch({type: BRANCH_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: BRANCH_CREATE_FAIL, payload: error})
    }
} 

const getBranch = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_BRANCH_REQUEST});
        
        const {data} = await api.get(`/branches/ ${id}`);
        console.log('Branch data is:' + data)
        dispatch({type: VIEW_BRANCH_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_BRANCH_FAIL, payload: error})
    }
}

const editBranch = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: BRANCH_UPDATE_REQUEST});
        
        const {data} = await api.put(`/branches/`, values);
        console.log('Branch data is:' + data)
        dispatch({type: BRANCH_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: BRANCH_UPDATE_FAIL, payload: error})
    }
}



export {getBranchesList, submitBranch, getBranch, editBranch }