import { WORKERS_LIST_REQUEST, WORKERS_LIST_SUCCESS, WORKERS_LIST_FAIL, VIEW_WORKER_REQUEST, WORKER_CREATE_FAIL, WORKER_CREATE_SUCCESS, VIEW_WORKER_SUCCESS, VIEW_WORKER_FAIL, WORKER_UPDATE_REQUEST, WORKER_UPDATE_SUCCESS, WORKER_UPDATE_FAIL, WORKER_DELETE_REQUEST, WORKER_DELETE_SUCCESS, WORKER_DELETE_FAIL, WORKER_CREATE_REQUEST } from "../Constants/workerConstants";
import api from '../../config/apiConfig';

const getWorkers = () => async(dispatch) => { 
    

    try {

        dispatch({type: WORKERS_LIST_REQUEST});
        const {data} = await api.get('/workers');
        // const workers_array = data.workers;
        data.forEach(element => {
            console.log('Item is:' + element.Name)
        });
        setTimeout(() => {
            dispatch({type: WORKERS_LIST_SUCCESS, payload: data})
            
        }, 1000);
 
    } catch (error) {
        dispatch({type: WORKERS_LIST_FAIL, payload: error.msg})
    }
}

const submitWorker = (newWorkerObj) => async (dispatch) => {
    try {
    
        dispatch({type: WORKER_CREATE_REQUEST})
        const message = await api.post('/workers/', newWorkerObj);

        dispatch({type: WORKER_CREATE_SUCCESS, payload: message})
        
    } catch (error) {
        dispatch({type: WORKER_CREATE_FAIL, payload: error.message })
    }
}

const getWorker = (id) => async (dispatch) => {
    try {
    
        dispatch({type: VIEW_WORKER_REQUEST})
        const {data} = await api.get(`/workers/ ${id}`);
       
        console.log('Received object in getWorker: ' + data)
        dispatch({type: VIEW_WORKER_SUCCESS, payload: data})

    } catch (error) {
        dispatch({type: VIEW_WORKER_FAIL, payload: error.message })
    }
}

const editWorker = (id, name) => async (dispatch) => {
    try {
    
        dispatch({type: WORKER_UPDATE_REQUEST})
        const {data} = await api.put('/workers/', {id, name});
       
        console.log('Received object: ' + data)
        dispatch({type: WORKER_UPDATE_SUCCESS, payload: data})

    } catch (error) {
        dispatch({type: WORKER_UPDATE_FAIL, payload: error.message })
    }
}

const deleteWorker = (id) => async (dispatch) => {
    try {
    
        dispatch({type: WORKER_DELETE_REQUEST})
        const {data} = await api.delete(`/workers/ ${id}`);
       
        console.log('Received object: ' + data)
        dispatch({type: WORKER_DELETE_SUCCESS, payload: data})

    } catch (error) {
        dispatch({type: WORKER_DELETE_FAIL, payload: error.message })
    }
}

export {getWorkers, submitWorker, getWorker, editWorker, deleteWorker}