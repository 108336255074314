import { PROFILES_LIST_REQUEST, PROFILES_LIST_SUCCESS, PROFILES_LIST_FAIL, PROFILE_CREATE_SUCCESS, PROFILE_CREATE_FAIL, PROFILE_CREATE_REQUEST, VIEW_PROFILE_REQUEST, VIEW_PROFILE_SUCCESS, VIEW_PROFILE_FAIL } from "../Constants/profileConstants";
import api from '../../config/apiConfig';

const getProfiles = () => async(dispatch) => { 
    

    try {

        dispatch({type: PROFILES_LIST_REQUEST});
        const {data} = await api.get('/profiles');
        // const profiles_array = data.profiles;
        data.forEach(element => {
            console.log('Profile Item is:' + element)
        }); 
        setTimeout(() => {
            dispatch({type: PROFILES_LIST_SUCCESS, payload: data})
            
        }, 1000);
 
    } catch (error) {
        dispatch({type: PROFILES_LIST_FAIL, payload: error.msg})
    }
}

const submitProfile = (values) => async (dispatch) => {
    try {
    
        dispatch({type: PROFILE_CREATE_REQUEST});
        console.log('I AM RECEIVING: ' + values)
        const message = await api.post('/profiles/', values);

        dispatch({type: PROFILE_CREATE_SUCCESS, payload: values})
        
    } catch (error) {
        dispatch({type: PROFILE_CREATE_FAIL, payload: error.message })
    }
}

const getProfile = (id) => async (dispatch) => {
    try {
    
        dispatch({type: VIEW_PROFILE_REQUEST})
        const {data} = await api.get(`/profiles/ ${id}`);
       
        console.log('Received object for Profile from REST API: ' + data)
        dispatch({type: VIEW_PROFILE_SUCCESS, payload: data})

    } catch (error) {
        dispatch({type: VIEW_PROFILE_FAIL, payload: error.message })
    }
}

const editProfile = (id, name) => async (dispatch) => {
    try {
    
        dispatch({type: VIEW_PROFILE_REQUEST})
        const {data} = await api.put('/profiles/', {id, name});
       
        console.log('Received object: ' + data)
        dispatch({type: VIEW_PROFILE_SUCCESS, payload: data})

    } catch (error) {
        dispatch({type: VIEW_PROFILE_FAIL, payload: error.message })
    }
}


export {getProfiles, submitProfile, getProfile, editProfile}