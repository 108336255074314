import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, Breadcrumbs, TextField, Box} from '@mui/material';
import { blueGrey, blue, purple, deepPurple, teal, orange, deepOrange, yellow, pink, lightBlue } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async'; 
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { getShiftPreferencesList } from '../../../../../Redux/Actions/ShiftPreferenceActions';
import { getPayrollSetupsList } from '../../../../../Redux/Actions/PayrollSetupActions';
import { getPayrollDocTypesList } from '../../../../../Redux/Actions/PayrollDocTypeActions';
import { getWorkerStatusesList } from '../../../../../Redux/Actions/WorkerStatusActions';
import { getWorkers, submitWorker } from '../../../../../Redux/Actions/WorkerActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import ComplexLogo from '../../../../../assets/ComplexLogo.png'


const AddInvoiceDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET SHIFT PREFS
    const preferences_ListState = useSelector(state => state.shiftPreferences_List);
    const {shiftPreferences, loading} = preferences_ListState;


    
       
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getWorkers()); 
        dispatch(getDivisions());
        dispatch(getJobRolesList());
        dispatch(getShiftPreferencesList());
        dispatch(getPayrollSetupsList());
        dispatch(getPayrollDocTypesList());
        dispatch(getWorkerStatusesList());
       
    }, [])
    

    const [values, setValues] = useState({
            gender: '',
            firstname: '',
            surname: '',
            nationalno: '',
            dob: '',
            mobile: '',
            hphone: '',
            email: '', 
            address1: '',
            address2: '' , 
            postcode: '' , 
            town: '', 
            divisions: '', 
            jobroles: '', 
            utr: '',
            cis: '',
            company: '',
            companyno: '',
            vat: '',
            staff: '',
            shiftpreferences: '', 
            payrollsetups: '', 
            payrolldoctypes: '', 
            workerstatuses: '', 
            linkedumbrellas: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            gender: '',
            firstname: '',
            surname: '',
            nationalno: '',
            dob: '',
            mobile: '',
            hphone: '',
            email: '', 
            address1: '',
            address2: '' , 
            postcode: '' , 
            town: '', 
            divisions: '', 
            jobroles: [], 
            utr: '',
            cis: '',
            company: '',
            companyno: '',
            vat: '',
            staff: '',
            shiftpreferences: '', 
            payrollsetups: '', 
            payrolldoctypes: '', 
            workerstatuses: '', 
            linkedumbrellas: '',  
        });

   
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitWorker(submitValues))
        handleSubmit('success',"Successfully saved worker's information");

    }
    const [date, setDate] = useState({});
    const handleDateChange = (newValue, name) => {
        
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate({
            ...date,
            [name]: newValue
        });
        setValues({
            ...values,
            [name]: `${day}/${month+1}/${year}`
        })
        setSubmitValues({
            ...submitValues,
            [name]: `${day}/${month+1}/${year}`
        })
    }
    
    const animatedComponents = makeAnimated();
    const [inputValue, setInputValue] = useState('');
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)

    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }

    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: lightBlue[500]}}  >
                
                <ReceiptIcon fontSize="large" sx={{color: blue[50], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[50], fontFamily: 'Poppins-Bold',fontWeight: 700}}> Add Invoice </Typography>
            </DialogTitle>
     
        <Divider/>
        {/* <p>fname {submitValues.firstname}</p>
        <p>sname {submitValues.surname}</p>
        <p>pref {submitValues.shiftpreferences}</p>
        <p>div {submitValues.divisions}</p>
        <p>roles{submitValues.jobroles}</p>
        <p>status {submitValues.workerstatuses}</p>
        <p> staff{submitValues.staff}</p>
        <p>doc {submitValues.payrolldoctypes}</p>
        <p>setup {submitValues.payrollsetups}</p>
        <p>umb {submitValues.linkedumbrellas}</p> */}
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>

                    <Grid item lg={11}>
                    <Row>
                         <div className="company-details">
                            <div className="company-logo">
                                {/* <img src={ComplexLogo} alt=""/> */}
                            </div>
                            <Stack flexDirection="column" alignItems="flex-end" justifyContent="center">
                                <h1>INVOICE</h1>
                                <Stack flexDirection="column" alignItems="flex-end" justifyContent="">
                                    <h3>Contact Details</h3>
                                    <div className="address">
                                        <h5>Address:</h5>
                                        <h6> 1XEF Goh Bay, Great Britain</h6>
                                    </div>
                                </Stack>

                            </Stack>
                        </div>
                        </Row>
                        <Divider sx={{mb: 1, color: orange[500]}}/>
                        <Form className="">

                        <Row>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white', fontFamily: 'Poppins',background: lightBlue[300], borderRadius: '5px', px: 2, py: '5px'}}>Client Information</Typography>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Clients</Form.Label>
                                    <AsyncSelect
                                        value={values.clients}
                                        name="clients"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'clients')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for client ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Address</Form.Label>
                                <Form.Control type="text" name="address" value={values.address} onChange={handleChange} placeholder="" className="outline-none border-none" />
                            </Form.Group>
                            </Row>
                    
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Invoice Number</Form.Label>
                                    <Form.Control type="email" name="firstname" value={values.firstname} onChange={handleChange} placeholder="" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <br/>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <ReactPicker
                                                name="invoice_date"
                                                label="Invoice Date"
                                                value={date.invoice_date}
                                                onChange={(e) => handleDateChange(e, "invoice_date")}
                                                renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:'none', color: "white", width: '100%'}} {...params} />}
                                            />
                                        </LocalizationProvider>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <br/>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <ReactPicker
                                                name="due_date"
                                                label="Invoice Due Date"
                                                value={date.due_date}
                                                onChange={(e) => handleDateChange(e, "due_date")}
                                                renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                            />
                                        </LocalizationProvider>
                                </Form.Group>
                            </Row>

                        <Row>
                            
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Invoice Type</Form.Label>
                                    <AsyncSelect
                                        value={values.type}
                                        name="type"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'invoicetypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Payment Terms</Form.Label>
                                    <AsyncSelect
                                        value={values.terms}
                                        name="terms"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'paymentterms')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for terms ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Consultant</Form.Label>
                                    <AsyncSelect
                                        value={values.staff}
                                        name="staff"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'staff')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for staff ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Row>
                            <Typography id="contact" variant="body2" sx={{display: 'inline',color: 'white', fontFamily: 'Poppins-Medium',background: lightBlue[300], borderRadius: '5px', px: 2, py: '5px'}}>Invoice Lines</Typography>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Timesheets</Form.Label>
                                    <AsyncSelect
                                        value={values.timesheets}
                                        name="timesheets"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'timesheets')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Timesheet ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Rate Card</Form.Label>
                                <Form.Control type="email" name="hphone" value={values.hphone} onChange={handleChange} placeholder="" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Hours</Form.Label>
                                <Form.Control type="text" name="hours" value={values.hours} onChange={handleChange} placeholder="" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Total</Form.Label>
                                <Form.Control type="text" name="hours" value={values.hours} onChange={handleChange} placeholder="" className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                        <Stack flexDirection="row" justifyContent="flex-end" sx={{width: '100%', background: 'none', mt: 1, mb: 3}}>
                            <Button sx={{background: orange[300], color: 'white', py: '1px', fontSize: '.7rem', '&:hover': {background: orange[600]}}}>Add Line</Button>
                        </Stack>

                        <Divider sx={{mb: 2, color: blueGrey[500]}}/>

                        <Grid className="invoice-lines">
                            <Typography sx={{color: blueGrey[500],fontFamily: 'Poppins', mb: 1}}>Lines</Typography>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Timesheet</th>
                                        <th>Service Type</th>
                                        <th>Hours</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Perm Worker</td>
                                        <td>25hrs</td>
                                        <td>2,500.00</td>
                                    </tr>

                                </tbody>
                            </table>
                        </Grid>

                        <Divider sx={{mb: 2, color: blueGrey[500]}}/>

                        <Row className="d-flex flex-column align-items-end">
                            <Typography id="contact" variant="body2" sx={{display: 'inline',color: 'white', fontFamily: 'Poppins-Medium',background: lightBlue[300], borderRadius: '5px', px: 2, py: '5px'}}>VAT & Totals</Typography>
                            <Form.Group as={Col} controlId="formGridEmail" className="col-4 rounded p-2">
                                <Form.Label className="view-label">VAT</Form.Label>
                                <Form.Control type="email" name="address1" value={values.address1} onChange={handleChange} placeholder="" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="col-4 rounded p-2">
                                <Form.Label className="view-label">Total</Form.Label>
                                <Form.Control type="email" name="address2" value={values.address2} onChange={handleChange} placeholder="" className="outline-none border-none" />
                            </Form.Group>
                        
                        </Row>
                        <br/>
                        <Typography id="status" variant="body2" sx={{display: 'inline',color: 'white',background: lightBlue[500], borderRadius: '5px', px: 2, py: '5px'}}>Invoice Status</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Invoice Status</Form.Label>
                                    <AsyncSelect
                                        value={values.status}
                                        name="status"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'invoicestatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                        </Form>
                    </Grid>

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: lightBlue[500], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Invoice</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddInvoiceDialog
