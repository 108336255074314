import { PROSPECTS_LIST_REQUEST, PROSPECTS_LIST_SUCCESS, PROSPECTS_LIST_FAIL, VIEW_PROSPECT_FAIL, VIEW_PROSPECT_REQUEST, VIEW_PROSPECT_SUCCESS, PROSPECT_CREATE_FAIL, PROSPECT_UPDATE_REQUEST, PROSPECT_UPDATE_FAIL, PROSPECT_DELETE_REQUEST, PROSPECT_DELETE_FAIL, PROSPECT_CREATE_REQUEST, PROSPECT_CREATE_SUCCESS, PROSPECT_UPDATE_SUCCESS, PROSPECT_DELETE_SUCCESS } from "../Constants/prospectConstants";

const prospectsListReducer = (state = {prospects: []}, action) => {

    switch (action.type)
    {
        case PROSPECTS_LIST_REQUEST:
            return {loading: true};
 
        case PROSPECTS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and prospects data is:' + action.payload)
            return {
                loading: false,
                prospects: action.payload
            }
        case PROSPECTS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const prospectCreateReducer = (state = {prospects: []}, action) => {

    switch (action.type) {

        case PROSPECT_CREATE_REQUEST:
            return {
                loading: true
            }

        case PROSPECT_CREATE_SUCCESS: 
        console.log('Previous divisions state from reducer: ' + state.prospects);
        
        const newClient = action.payload;
        const updatedList = [...state.prospects, newClient];
            return {
                loading: false,
                prospects: updatedList,
            };

        case PROSPECT_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const prospectViewReducer = (state= {prospect: {}}, action) => {
    switch(action.type)
    {
        case VIEW_PROSPECT_REQUEST:
            return {loading: true};
        case VIEW_PROSPECT_SUCCESS:
            return {
                    loading: false,
                    prospect: action.payload
                }
        case VIEW_PROSPECT_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const prospectUpdateReducer = (state = {prospects: {}}, action) => {

    switch (action.type) {

        case PROSPECT_UPDATE_REQUEST:
            return {
                
            }
        case PROSPECT_UPDATE_SUCCESS:

            return {

            }
        case PROSPECT_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const prospectDeleteReducer = (state = {prospects: {}}, action) => {

    switch (action.type) {
        case PROSPECT_DELETE_REQUEST:
            return {
                
            }
        case PROSPECT_DELETE_SUCCESS:
            return {

            }
        case PROSPECT_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {prospectsListReducer, prospectCreateReducer, prospectViewReducer, prospectUpdateReducer, prospectDeleteReducer }