export const PAYROLLSETUPS_LIST_REQUEST = 'PAYROLLSETUPS_LIST_REQUEST'
export const PAYROLLSETUPS_LIST_SUCCESS = 'PAYROLLSETUPS_LIST_SUCCESS'
export const PAYROLLSETUPS_LIST_FAIL = 'PAYROLLSETUPS_LIST_FAIL'


export const PAYROLLSETUP_CREATE_REQUEST = 'PAYROLLSETUP_CREATE_REQUEST'
export const PAYROLLSETUP_CREATE_SUCCESS = 'PAYROLLSETUP_CREATE_SUCCESS'
export const PAYROLLSETUP_CREATE_FAIL = 'PAYROLLSETUP_CREATE_FAIL'


export const PAYROLLSETUP_UPDATE_REQUEST = 'PAYROLLSETUP_UPDATE_REQUEST'
export const PAYROLLSETUP_UPDATE_SUCCESS = 'PAYROLLSETUP_UPDATE_SUCCESS'
export const PAYROLLSETUP_UPDATE_FAIL = 'PAYROLLSETUP_UPDATE_FAIL'

export const PAYROLLSETUP_DELETE_REQUEST = 'PAYROLLSETUP_DELETE_REQUEST'
export const PAYROLLSETUP_DELETE_SUCCESS = 'PAYROLLSETUP_DELETE_SUCCESS'
export const PAYROLLSETUP_DELETE_FAIL = 'PAYROLLSETUP_DELETE_FAIL'

export const VIEW_PAYROLLSETUP_REQUEST = 'VIEW_PAYROLLSETUP_REQUEST'
export const VIEW_PAYROLLSETUP_SUCCESS = 'VIEW_PAYROLLSETUP_SUCCESS'
export const VIEW_PAYROLLSETUP_FAIL = 'VIEW_PAYROLLSETUP_FAIL'