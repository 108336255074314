import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, yellow, indigo, purple } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import TransactionDialogs from '../../../Dialogs/Finance/Reconciliation/TransactionsDialogs/TransactionDialogs';

const TransactionsTbl = ({transactions}) => { 

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    const  getTypeColor = (status) => {
        return status === 'Assign Work' ? purple[500] : status === 'Complete Invoices'?  lightGreen[500] : status === 'Complete Payroll'? lightBlue[500] :  status === 'Compliance'? pink[500] : status === 'Contact Client'? yellow[800]  : status === 'Contact Client'? indigo[400]  : status === 'New'? orange[500] : blueGrey[500]
    }
    const  getStatusColor = (status) => {
        return status === 'New Task' ? pink[500] : status === 'In Progress'?  yellow[800] : status === 'Part Complete'? lightBlue[500] : status === 'Complete'? lightBlue[500] : status === 'Overdue'? blue[800] : status === 'Escalate'? purple[800] :  blueGrey[500]
    } 

    const switchToViewTask = (id) => {
        navigate('/task/'+ id)
    } 

    const taskQuickView = (id) => {

        showDialog(true, 'view', id)
    }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number', minWidth: 100 },
        { field: 'AccountName', headerName: 'Account Name', minWidth: 150 , renderCell: (params) => <Typography onClick={()=> {taskQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.AccountName}`}</Typography>},
        { field: 'AccountCode', headerName: 'Account Code', minWidth: 150 ,},
        { field: 'isTransfer', headerName: 'isTransfer', minWidth: 150 ,},
        { field: 'Description', headerName: 'Description', minWidth: 200 ,},
        { field: 'Type', headerName: 'Type', minWidth: 150 ,},
        { field: 'PP', headerName: 'Payer/Payee', minWidth: 150 ,},
        { field: 'VATRate', headerName: 'VATRate', minWidth: 150 ,},
        { field: 'NettValue', headerName: 'Nett Value', minWidth: 150 ,},
        { field: 'VAT', headerName: 'VAT', minWidth: 150 ,},
        { field: 'TotalAmount', headerName: 'TotalAmount', minWidth: 150 ,},
        { field: 'BSType', headerName: 'Balance Sheet Type', minWidth: 200 ,},
        { field: 'Depreciation', headerName: 'Depreciation', width: 150},
        { field: 'TodaysDate', headerName: 'TodaysDate', width: 150},
        { field: 'CurrentValue', headerName: 'CurrentValue', width: 150},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewTask(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];


    return (
        
        <>
            { dialog.open  ?  <TransactionDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={transactions}
                        loading={!transactions.length}
                    />
                </div>
            </div>
        </>
    )
}

export default TransactionsTbl
