import React from 'react'
import { IconButton, Stack, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { blue, pink } from '@mui/material/colors';

const EditInDialog = ({isEditing, isDeleting, handleEditClick, handleDeleteClick, handleCancel}) => {
    return (
        <>
            <Stack sx={{mt: 1}} direction="row" alignItems="center" justifyContent="center">
                <IconButton aria-label="delete" sx={{background: blue[50]}}>
                    {isEditing? <CloseIcon fontSize="small" onClick={handleCancel} /> : <EditIcon onClick={handleEditClick}  fontSize="small"/>}
                </IconButton>
                <IconButton aria-label="delete"  sx={{ml: 2, background: pink[50]}}>
                    {isDeleting? <CloseIcon fontSize="small" onClick={handleCancel} /> : <DeleteIcon onClick={handleDeleteClick} fontSize="small" sx={{color: 'red[500]'}}/>}
                </IconButton>
            </Stack> 
        </>
    )
}

export default EditInDialog
