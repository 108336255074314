export const CREDITCHECKSTATUSES_LIST_REQUEST = 'CREDITCHECKSTATUSES_LIST_REQUEST';
export const CREDITCHECKSTATUSES_LIST_SUCCESS = 'CREDITCHECKSTATUSES_LIST_SUCCESS';
export const CREDITCHECKSTATUSES_LIST_FAIL = 'CREDITCHECKSTATUSES_LIST_FAIL'; 

export const CREDITCHECKSTATUS_CREATE_REQUEST = 'CREDITCHECKSTATUS_CREATE_REQUEST'
export const CREDITCHECKSTATUS_CREATE_SUCCESS = 'CREDITCHECKSTATUS_CREATE_SUCCESS'
export const CREDITCHECKSTATUS_CREATE_FAIL = 'CREDITCHECKSTATUS_CREATE_FAIL'


export const CREDITCHECKSTATUS_UPDATE_REQUEST = 'CREDITCHECKSTATUS_UPDATE_REQUEST'
export const CREDITCHECKSTATUS_UPDATE_SUCCESS = 'CREDITCHECKSTATUS_UPDATE_SUCCESS'
export const CREDITCHECKSTATUS_UPDATE_FAIL = 'CREDITCHECKSTATUS_UPDATE_FAIL'

export const CREDITCHECKSTATUS_DELETE_REQUEST = 'CREDITCHECKSTATUS_DELETE_REQUEST'
export const CREDITCHECKSTATUS_DELETE_SUCCESS = 'CREDITCHECKSTATUS_DELETE_SUCCESS'
export const CREDITCHECKSTATUS_DELETE_FAIL = 'CREDITCHECKSTATUS_DELETE_FAIL'

export const VIEW_CREDITCHECKSTATUS_REQUEST = 'VIEW_CREDITCHECKSTATUS_REQUEST'
export const VIEW_CREDITCHECKSTATUS_SUCCESS = 'VIEW_CREDITCHECKSTATUS_SUCCESS'
export const VIEW_CREDITCHECKSTATUS_FAIL = 'CREDITCHECKSTATUS_FAIL'