import { RECONCILES_LIST_REQUEST, RECONCILES_LIST_SUCCESS, RECONCILES_LIST_FAIL, VIEW_RECONCILE_FAIL, VIEW_RECONCILE_REQUEST, VIEW_RECONCILE_SUCCESS, RECONCILE_CREATE_REQUEST, RECONCILE_CREATE_SUCCESS, RECONCILE_CREATE_FAIL, RECONCILE_UPDATE_FAIL, RECONCILE_UPDATE_REQUEST, RECONCILE_UPDATE_SUCCESS } from '../Constants/reconcileConstants';
import api from '../../config/apiConfig';


const getReconcilesList = () => async(dispatch) => {
    
  
    try {
        dispatch({type: RECONCILES_LIST_REQUEST});
        const {data} = await api.get('/reconciles');
        console.log('Reconciles data is:' + data[0].Name)
        dispatch({type: RECONCILES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: RECONCILES_LIST_FAIL, payload: error})
    }
} 

const submitReconcile = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: RECONCILE_CREATE_REQUEST});
        
        const {data} = await api.post(`/reconciles/`, postValues);
        console.log('Reconcile data is:' + data)
        dispatch({type: RECONCILE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: RECONCILE_CREATE_FAIL, payload: error})
    }
} 

const getReconcile = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_RECONCILE_REQUEST});
        
        const {data} = await api.get(`/reconciles/ ${id}`);
        console.log('Reconcile data is:' + data)
        dispatch({type: VIEW_RECONCILE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_RECONCILE_FAIL, payload: error})
    }
}

const editReconcile = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: RECONCILE_UPDATE_REQUEST});
        
        const {data} = await api.put(`/reconciles/`, values);
        console.log('Reconcile data is:' + data)
        dispatch({type: RECONCILE_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: RECONCILE_UPDATE_FAIL, payload: error})
    }
}



export {getReconcilesList, submitReconcile, getReconcile, editReconcile }