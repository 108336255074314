import { TASKTYPES_LIST_REQUEST, TASKTYPES_LIST_SUCCESS, TASKTYPES_LIST_FAIL, VIEW_TASKTYPE_FAIL, VIEW_TASKTYPE_REQUEST, VIEW_TASKTYPE_SUCCESS, TASKTYPE_CREATE_REQUEST, TASKTYPE_CREATE_SUCCESS, TASKTYPE_CREATE_FAIL, TASKTYPE_UPDATE_FAIL, TASKTYPE_UPDATE_REQUEST, TASKTYPE_UPDATE_SUCCESS } from '../Constants/taskTypeConstants';
import api from '../../config/apiConfig';


const getTaskTypesList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: TASKTYPES_LIST_REQUEST});
        const {data} = await api.get('/tasktypes');
        // console.log('TaskTypes data is:' + data[0]['TaskType_Name'])
        dispatch({type: TASKTYPES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: TASKTYPES_LIST_FAIL, payload: error})
    }
} 

const submitTaskType = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: TASKTYPE_CREATE_REQUEST});
        
        const {data} = await api.post(`/tasktypes/`, values);
        console.log('TaskType data is:' + data)
        dispatch({type: TASKTYPE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: TASKTYPE_CREATE_FAIL, payload: error})
    }
} 

const getTaskType = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_TASKTYPE_REQUEST});
        
        const {data} = await api.get(`/tasktypes/ ${id}`);
        console.log('TaskType data is:' + data)
        dispatch({type: VIEW_TASKTYPE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_TASKTYPE_FAIL, payload: error})
    }
}

const editTaskType = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: TASKTYPE_UPDATE_REQUEST});
        
        const {data} = await api.put(`/tasktypes/`, values);
        console.log('TaskType data is:' + data)
        dispatch({type: TASKTYPE_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: TASKTYPE_UPDATE_FAIL, payload: error})
    }
}



export {getTaskTypesList, submitTaskType, getTaskType, editTaskType }