import { PROFITLOSS_LIST_REQUEST, PROFITLOSS_LIST_SUCCESS, PROFITLOSS_LIST_FAIL, VIEW_PROFITLOSS_FAIL, VIEW_PROFITLOSS_REQUEST, VIEW_PROFITLOSS_SUCCESS, PROFITLOSS_CREATE_REQUEST, PROFITLOSS_CREATE_SUCCESS, PROFITLOSS_CREATE_FAIL, PROFITLOSS_UPDATE_FAIL, PROFITLOSS_UPDATE_REQUEST, PROFITLOSS_UPDATE_SUCCESS } from '../Constants/profitLossConstants';
import api from '../../config/apiConfig';


const getProfitLossList = () => async(dispatch) => {
    
  
    try {
        dispatch({type: PROFITLOSS_LIST_REQUEST});
        const {data} = await api.get('/profitloss');
        console.log('ProfitLoss data is:' + data[0].Name)
        dispatch({type: PROFITLOSS_LIST_SUCCESS, payload: data})
        
         
    } catch (error) {
        dispatch({type: PROFITLOSS_LIST_FAIL, payload: error})
    }
} 

const submitProfitLoss = (stateValues, submitValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: PROFITLOSS_CREATE_REQUEST});
        
        const {data} = await api.post(`/profitloss/`, submitValues);
        console.log('ProfitLoss data is:' + data)
        dispatch({type: PROFITLOSS_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: PROFITLOSS_CREATE_FAIL, payload: error})
    }
} 

const getProfitLoss = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_PROFITLOSS_REQUEST});
        
        const {data} = await api.get(`/profitloss/ ${id}`);
        console.log('ProfitLoss data is:' + data)
        dispatch({type: VIEW_PROFITLOSS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_PROFITLOSS_FAIL, payload: error})
    }
}

const editProfitLoss = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: PROFITLOSS_UPDATE_REQUEST});
        
        const {data} = await api.put(`/profitloss/`, values);
        console.log('ProfitLoss data is:' + data)
        dispatch({type: PROFITLOSS_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: PROFITLOSS_UPDATE_FAIL, payload: error})
    }
}



export {getProfitLossList, submitProfitLoss, getProfitLoss, editProfitLoss }