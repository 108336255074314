import { ASSESSMENTRESULT_LIST_REQUEST, ASSESSMENTRESULT_LIST_FAIL, ASSESSMENTRESULT_LIST_SUCCESS} from '../Constants/assessmentResultsConstants';
import api from '../../config/apiConfig';


const getAssessmentResultsList = () => async(dispatch) => {
    //const data = assessmenttypes;
 
    try {
        dispatch({type: ASSESSMENTRESULT_LIST_REQUEST});
        const {data} = await api.get('/assessmentresults');
        //console.log('AssessmentResults data is:' + data[0]['AssessmentResults_Name'])
        dispatch({type: ASSESSMENTRESULT_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: ASSESSMENTRESULT_LIST_FAIL, payload: error})
    }
} 

export {getAssessmentResultsList }