import { INFLUENCES_LIST_REQUEST, INFLUENCES_LIST_SUCCESS, INFLUENCES_LIST_FAIL, VIEW_INFLUENCE_FAIL, VIEW_INFLUENCE_REQUEST, VIEW_INFLUENCE_SUCCESS, INFLUENCE_CREATE_REQUEST, INFLUENCE_CREATE_SUCCESS, INFLUENCE_CREATE_FAIL, INFLUENCE_UPDATE_FAIL, INFLUENCE_UPDATE_REQUEST, INFLUENCE_UPDATE_SUCCESS } from '../Constants/influenceConstants';
import api from '../../config/apiConfig';


const getInfluencesList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: INFLUENCES_LIST_REQUEST});
        const {data} = await api.get('/influences');
        // console.log('Influences data is:' + data[0]['Influence_Name'])
        dispatch({type: INFLUENCES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: INFLUENCES_LIST_FAIL, payload: error})
    }
} 

const submitInfluence = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: INFLUENCE_CREATE_REQUEST});
        
        const {data} = await api.post(`/influences/`, values);
        console.log('Influence data is:' + data)
        dispatch({type: INFLUENCE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: INFLUENCE_CREATE_FAIL, payload: error})
    }
} 

const getInfluence = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_INFLUENCE_REQUEST});
        
        const {data} = await api.get(`/influences/ ${id}`);
        console.log('Influence data is:' + data)
        dispatch({type: VIEW_INFLUENCE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_INFLUENCE_FAIL, payload: error})
    }
}

const editInfluence = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: INFLUENCE_UPDATE_REQUEST});
        
        const {data} = await api.put(`/influences/`, values);
        console.log('Influence data is:' + data)
        dispatch({type: INFLUENCE_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: INFLUENCE_UPDATE_FAIL, payload: error})
    }
}



export {getInfluencesList, submitInfluence, getInfluence, editInfluence }