import { ASSETTYPES_LIST_REQUEST, ASSETTYPES_LIST_SUCCESS, ASSETTYPES_LIST_FAIL, VIEW_ASSETTYPE_FAIL, VIEW_ASSETTYPE_REQUEST, VIEW_ASSETTYPE_SUCCESS, ASSETTYPE_CREATE_FAIL, ASSETTYPE_UPDATE_REQUEST, ASSETTYPE_UPDATE_FAIL, ASSETTYPE_DELETE_REQUEST, ASSETTYPE_DELETE_FAIL, ASSETTYPE_CREATE_REQUEST, ASSETTYPE_CREATE_SUCCESS, ASSETTYPE_UPDATE_SUCCESS, ASSETTYPE_DELETE_SUCCESS } from "../Constants/assetTypeConstants";

const assetTypesListReducer = (state = {assetTypes: []}, action) => {

    switch (action.type)
    {
        case ASSETTYPES_LIST_REQUEST:
            return {loading: true};
 
        case ASSETTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and assetTypes data is:' + action.payload)
            return {
                loading: false,
                assetTypes: action.payload
            }
        case ASSETTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const assetTypeCreateReducer = (state = {assetTypes: []}, action) => {

    switch (action.type) {

        case ASSETTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case ASSETTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                assetTypes: [...state.assetTypes, {Applicant_Id: 2, Name: value}]
            };

        case ASSETTYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const assetTypeViewReducer = (state= {assetType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_ASSETTYPE_REQUEST:
            return {loading: true};
        case VIEW_ASSETTYPE_SUCCESS:
            return {
                    loading: false,
                    assetType: action.payload
                }
        case VIEW_ASSETTYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const assetTypeUpdateReducer = (state = {assetTypes: {}}, action) => {

    switch (action.type) {

        case ASSETTYPE_UPDATE_REQUEST:
            return {
                
            }
        case ASSETTYPE_UPDATE_SUCCESS:

            return {

            }
        case ASSETTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const assetTypeDeleteReducer = (state = {assetTypes: {}}, action) => {

    switch (action.type) {
        case ASSETTYPE_DELETE_REQUEST:
            return {
                
            }
        case ASSETTYPE_DELETE_SUCCESS:
            return {

            }
        case ASSETTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {assetTypesListReducer, assetTypeCreateReducer, assetTypeViewReducer, assetTypeUpdateReducer, assetTypeDeleteReducer }