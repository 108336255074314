export const VISITS_LIST_REQUEST = 'VISITS_LIST_REQUEST'
export const VISITS_LIST_SUCCESS = 'VISITS_LIST_SUCCESS'
export const VISITS_LIST_FAIL = 'VISITS_LIST_FAIL'


export const VISIT_CREATE_REQUEST = 'VISIT_CREATE_REQUEST'
export const VISIT_CREATE_SUCCESS = 'VISIT_CREATE_SUCCESS'
export const VISIT_CREATE_FAIL = 'VISIT_CREATE_FAIL'


export const VISIT_UPDATE_REQUEST = 'VISIT_UPDATE_REQUEST'
export const VISIT_UPDATE_SUCCESS = 'VISIT_UPDATE_SUCCESS'
export const VISIT_UPDATE_FAIL = 'VISIT_UPDATE_FAIL'

export const VISIT_DELETE_REQUEST = 'VISIT_DELETE_REQUEST'
export const VISIT_DELETE_SUCCESS = 'VISIT_DELETE_SUCCESS'
export const VISIT_DELETE_FAIL = 'VISIT_DELETE_FAIL'

export const VIEW_VISIT_REQUEST = 'VIEW_VISIT_REQUEST'
export const VIEW_VISIT_SUCCESS = 'VIEW_VISIT_SUCCESS'
export const VIEW_VISIT_FAIL = 'VIEW_VISIT_FAIL'