import React, { useRef } from 'react'
import { Grid, Typography, Button, Box } from '@mui/material';
import { blue, blueGrey, green, lightBlue, lightGreen, yellow, pink, cyan } from '@mui/material/colors';
import api from '../../config/apiConfig'

export  const convertSelectedStatusToStatusId = async (status) => {
    //----- MY PROCEDURE -----------
    //get statuses from storage which is my db
    //returns an array
    //use findById to look for matching status name
    //use the name key of that status object

    const {data} = await api.get('/taskstatuses');
    
    console.log('DATA FROM BACKEND ' + data.length)
    const wantedStatusObject = data.find( x =>  x.Status_Name === status);
    return wantedStatusObject
}

export const convertSelectItemsToIds = async (array_at_hand) => {


    const [data] = await getu(array_at_hand)
    console.log('data in function defined just now: ' + data)
    return data
        

}

const getu = (array_at_hand) => {

    
    return new Promise((resolve, reject) => {
        console.log('one')
        const items = [];
        for (let index = 0; index < array_at_hand.length; index++) {
            getValues(array_at_hand[index])
            .then((data) => {
                console.log('two')
                console.log('iteration :' + data)

                const wantedObject = data.find(x => x[array_at_hand[index].criteria] === array_at_hand[index].value);
        
                const compiled = {
                    name: array_at_hand[index].name,
                    value: array_at_hand[index].value,
                    criteria: array_at_hand[index].criteria,
                    properties: wantedObject
                }
                items.push(compiled)  
            })
            .then(()=> console.log('items has: ' + items.length))
        }
        setTimeout(() => {
            //console.log('items out of loop has: ' + items.length)
            if(items !== [])
            {
                resolve([items])
            }
            else{
                let reason = new Error('items is empty')
                reject(reason)
            }
            
        }, 2000);
    })
}

const getValues = async (array_item) => {

        const {data} = await api.get(`/${array_item.name}`)
        return data
        
}



const ViewStatus = ({status, task, values, setValues, handleSaveChanges}) => {

    const trigger = useRef();
    const statuses = useRef();

    const handleHover = () => {
        statuses.current.style.display = 'block'
        trigger.current.style.backgroundColor = '#b3e5fc'
    }
    const handleHoverLeave = () => {
        statuses.current.style.display = 'none'
        trigger.current.style.backgroundColor = '#f1f8e9'
    }

    const handleChoose = async (e) => {
        const {name, id} = e.target;
        const {Status_Id, Status_Name} = await convertSelectedStatusToStatusId(name)
        console.log('id is heereeeeeeee guys: ' + Status_Id)
        setValues({
            ...values,
            Status: Status_Name
        })
        console.log('status in state: ' + values.Status)
        handleSaveChanges(e, Status_Id)
        console.log('clicked on: ' + id)
    }
    
    
    return (
        <>
        {
            task? 

       
            <Grid item>
                <Grid ref={trigger} onMouseEnter={handleHover} onMouseLeave={handleHoverLeave} container alignItems="center" sx={{background: blue[50], borderRadius: '5px', boxShadow: '.6px .6px 3px whitesmoke', height: '30px'}}>
                    <Typography variant="body2" sx={{px: 2,color: blueGrey[700], fontWeight: 700, fontFamily: 'Poppins'}}>Status</Typography>
                    <Typography   variant="body2" sx={{px: 1,color: 'white', fontSize: '.7rem', fontFamily: 'Poppins', fontWeight: 400, background: status === 'Escalate' ? blue[500] : status === 'New Task' ? pink[500] : status === 'In Progress' ? cyan[500] : status === 'Overdue' ? blueGrey[800]  : lightGreen[700], borderRadius: '5px', mr: 1, py: '3px', boxShadow: '.5px 2px 3px gray'}}>{status}</Typography>
                    <Box ref={statuses} sx={{display: 'none'}}>
                        <Typography component="span" sx={{mx: 1, color: blue[500]}}>|</Typography>
                        {task.map((x, index) => 
                        <Button key={index} name={x.Status_Name} id={x.Status_Id} onClick={handleChoose} size="small" sx={{color: lightBlue[700], fontSize: 12, background: blue[50],  boxShadow: '.5px .5px 2px gray', py: 0, mr: 1, '&:hover': {background: blueGrey[600], color: "white"}}}>{x.Status_Name}</Button>)}
                    </Box>
                </Grid>
            </Grid>
            :
            <Grid item>
                <Grid container alignItems="center" sx={{background: lightGreen[50], borderRadius: '5px', boxShadow: '.6px .6px 3px whitesmoke', height: '30px'}}>
                    <Typography variant="body2" sx={{px: 2,color: blueGrey[500], fontWeight: 700, fontFamily: 'Poppins'}}>Status</Typography>
                    <Typography variant="body2" sx={{px: 1,color: 'white', fontSize: '.7rem', fontFamily: 'Poppins', fontWeight: 400, background: lightGreen[700], borderRadius: '5px', mr: 1, py: '3px'}}>Active</Typography>
                    
                </Grid>
            </Grid>
             }
        </>
    )
}

export default ViewStatus

// import React, { useRef } from 'react'
// import { Grid, Typography, Button, Box } from '@mui/material';
// import { blue, blueGrey, green, lightBlue, lightGreen, yellow, pink, cyan } from '@mui/material/colors';
// import api from '../../config/apiConfig'

// export  const convertSelectedStatusToStatusId = async (status) => {
//     //----- MY PROCEDURE -----------
//     //get statuses from storage which is my db
//     //returns an array
//     //use findById to look for matching status name
//     //use the name key of that status object

//     const {data} = await api.get('/taskstatuses');
    
//     console.log('DATA FROM BACKEND ' + data.length)
//     const wantedStatusObject = data.find( x =>  x.Status_Name === status);
//     return wantedStatusObject
// }

// export const convertSelectItemsToIds = async (array_at_hand) => {


//     const items = await getu(array_at_hand)
//     console.log('vhampion with : '+ items)
        

// }

// const getu = (array_at_hand) => {
    

//     const items = [];

//     array_at_hand.forEach( async (element) => {

        

//         const {data} = await api.get(`/${element.name}`);
//         /// still in scope

//         const wantedObject = data.find(x => x[element.criteria] === element.value);

//         const compiled = {
//             name: element.name,
//             value: element.value,
//             criteria: element.criteria,
//             properties: wantedObject
//         }
        
//         items.push(compiled)
//         //items.ol = compiled;

//     });

//     if(items !== '')
//     {
//         return items
//     }
//     else
//     {
//         return 'no ice'
//     }

//     return items
// }



// const ViewStatus = ({status, task, values, setValues, handleSaveChanges}) => {

//     const trigger = useRef();
//     const statuses = useRef();

//     const handleHover = () => {
//         statuses.current.style.display = 'block'
//         trigger.current.style.backgroundColor = '#b3e5fc'
//     }
//     const handleHoverLeave = () => {
//         statuses.current.style.display = 'none'
//         trigger.current.style.backgroundColor = '#f1f8e9'
//     }

//     const handleChoose = async (e) => {
//         const {name, id} = e.target;
//         const {Status_Id, Status_Name} = await convertSelectedStatusToStatusId(name)
//         console.log('id is heereeeeeeee guys: ' + Status_Id)
//         setValues({
//             ...values,
//             Status: Status_Name
//         })
//         console.log('status in state: ' + values.Status)
//         handleSaveChanges(e, Status_Id)
//         console.log('clicked on: ' + id)
//     }
    
    
//     return (
//         <>
//         {
//             task? 

       
//             <Grid item>
//                 <Grid ref={trigger} onMouseEnter={handleHover} onMouseLeave={handleHoverLeave} container alignItems="center" sx={{background: blue[50], borderRadius: '5px', boxShadow: '.6px .6px 3px whitesmoke', height: '30px'}}>
//                     <Typography variant="body2" sx={{px: 2,color: blueGrey[700], fontWeight: 700, fontFamily: 'Poppins'}}>Status</Typography>
//                     <Typography   variant="body2" sx={{px: 1,color: 'white', fontSize: '.7rem', fontFamily: 'Poppins', fontWeight: 400, background: status === 'Escalate' ? blue[500] : status === 'New Task' ? pink[500] : status === 'In Progress' ? cyan[500] : status === 'Overdue' ? blueGrey[800]  : lightGreen[700], borderRadius: '5px', mr: 1, py: '3px', boxShadow: '.5px 2px 3px gray'}}>{status}</Typography>
//                     <Box ref={statuses} sx={{display: 'none'}}>
//                         <Typography component="span" sx={{mx: 1, color: blue[500]}}>|</Typography>
//                         {task.map((x, index) => 
//                         <Button key={index} name={x.Status_Name} id={x.Status_Id} onClick={handleChoose} size="small" sx={{color: lightBlue[700], fontSize: 12, background: blue[50],  boxShadow: '.5px .5px 2px gray', py: 0, mr: 1, '&:hover': {background: blueGrey[600], color: "white"}}}>{x.Status_Name}</Button>)}
//                     </Box>
//                 </Grid>
//             </Grid>
//             :
//             <Grid item>
//                 <Grid container alignItems="center" sx={{background: lightGreen[50], borderRadius: '5px', boxShadow: '.6px .6px 3px whitesmoke', height: '30px'}}>
//                     <Typography variant="body2" sx={{px: 2,color: blueGrey[500], fontWeight: 700, fontFamily: 'Poppins'}}>Status</Typography>
//                     <Typography variant="body2" sx={{px: 1,color: 'white', fontSize: '.7rem', fontFamily: 'Poppins', fontWeight: 400, background: lightGreen[700], borderRadius: '5px', mr: 1, py: '3px'}}>Active</Typography>
                    
//                 </Grid>
//             </Grid>
//              }
//         </>
//     )
// }

// export default ViewStatus
