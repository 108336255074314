import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import AssessmentDialogs from '../../../Dialogs/CRM/HR/AssessmentDialogs/AssessmentDialogs';

const AssessmentsTbl = ({assessments}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const getDivisionColor = (jr) => {
        return jr === 'Construction' ? lightGreen[500] : jr === 'Driving'?  pink[500] : jr === 'Aviation'? orange[500] : jr === 'Industrial'? lightBlue[500] : blueGrey[500]
    }
    const  getResultColor = (status) => {
        return status === 'Passed-Client' ? blue[500] : status === 'Re-assessed'?  lightGreen[500] : status === 'Failed-Client'? orange[500] : blueGrey[500]
    }
    const  getStatusColor = (status) => {
        return status === 'Send to Client - Pass' ? pink[500] : status === 'Send to Client - Online Pass'?  yellow[800] : status === 'Failed Client Assessment - Not Good'? lightBlue[500] : status === 'Failed Client Assessment - Reassign'? lightBlue[500] : status === 'Can start work - Online Pass'? blue[800] : status === 'Resit Online Assessment - Online Fail'? blue[800] :  blueGrey[500]
    }

    const switchToViewAssessment = (id) => {
        navigate('/assessment/'+ id)
    } 

    const assessmentQuickView = (id) => {

        showDialog(true, 'view', id)
    }



    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'fullName', headerName: 'Full Name', minWidth: 250 , renderCell: (params) => <Typography onClick={()=> {assessmentQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Applicant} - ${params.row.Date} - ${params.row.Date}`}</Typography>},
        { field: 'Subject', headerName: 'Subject', minWidth: 200 ,},
        { field: 'Applicant', headerName: 'Applicant'},
        { field: 'Status', headerName: 'Status', minWidth: 180 , type: 'singleSelect', valueOptions: (['Send to Client - Pass', 'Send to Client - Online Pass', 'Failed Client Assessment - Not Good', 'Failed Client Assessment - Reassign', 'Can start work - Online Pass', 'Resit Online Assessment - Online Fail']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        { field: 'Type', headerName: 'Type', type: 'singleSelect', valueOptions: (['Client', 'Interview'])},

        { field: 'Division', headerName: 'Division', minWidth: 180 , type: 'singleSelect', valueOptions: (['Driving', 'Construction', 'Aviation', 'Industrial', 'Human Resources']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getDivisionColor(params.row.Division), borderRadius: '30px', px: '10px'}}>{params.row.Division}</Typography>
        )},
        

        { field: 'Branch', headerName: 'Branch', type: 'singleSelect', valueOptions: (['Edmonton', 'Chiswick'])},
        { field: 'Date', headerName: 'Application Date', minWidth: 130 ,},
        { field: 'Level', headerName: 'Level'},
        { field: 'Staff', headerName: 'Consultant'},
        { field: 'Result', headerName: 'Result',type: 'singleSelect',  valueOptions: (['Hot', 'Warm', 'Cold', 'Frozen']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getResultColor(params.row.Result), borderRadius: '30px', px: '10px'}}>{params.row.Result}</Typography>
        )},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewAssessment(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ]; 


    return (
        
        <>
            { dialog.open  ?  <AssessmentDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={assessments}
                        loading={!assessments.length}
                    />
                </div>
            </div>
        </>
    )
}

export default AssessmentsTbl
