export const WORKERSTATUSES_LIST_REQUEST = 'WORKERSTATUSES_LIST_REQUEST'
export const WORKERSTATUSES_LIST_SUCCESS = 'WORKERSTATUSES_LIST_SUCCESS'
export const WORKERSTATUSES_LIST_FAIL = 'WORKERSTATUSES_LIST_FAIL'


export const WORKERSTATUS_CREATE_REQUEST = 'WORKERSTATUS_CREATE_REQUEST'
export const WORKERSTATUS_CREATE_SUCCESS = 'WORKERSTATUS_CREATE_SUCCESS'
export const WORKERSTATUS_CREATE_FAIL = 'WORKERSTATUS_CREATE_FAIL'


export const WORKERSTATUS_UPDATE_REQUEST = 'WORKERSTATUS_UPDATE_REQUEST'
export const WORKERSTATUS_UPDATE_SUCCESS = 'WORKERSTATUS_UPDATE_SUCCESS'
export const WORKERSTATUS_UPDATE_FAIL = 'WORKERSTATUS_UPDATE_FAIL'

export const WORKERSTATUS_DELETE_REQUEST = 'WORKERSTATUS_DELETE_REQUEST'
export const WORKERSTATUS_DELETE_SUCCESS = 'WORKERSTATUS_DELETE_SUCCESS'
export const WORKERSTATUS_DELETE_FAIL = 'WORKERSTATUS_DELETE_FAIL'

export const VIEW_WORKERSTATUS_REQUEST = 'VIEW_WORKERSTATUS_REQUEST'
export const VIEW_WORKERSTATUS_SUCCESS = 'VIEW_WORKERSTATUS_SUCCESS'
export const VIEW_WORKERSTATUS_FAIL = 'VIEW_WORKERSTATUS_FAIL'