import { REQUESTS_LIST_REQUEST, REQUESTS_LIST_SUCCESS, REQUESTS_LIST_FAIL, VIEW_REQUEST_FAIL, VIEW_REQUEST_REQUEST, VIEW_REQUEST_SUCCESS, REQUEST_CREATE_REQUEST, REQUEST_CREATE_SUCCESS, REQUEST_CREATE_FAIL, REQUEST_UPDATE_FAIL, REQUEST_UPDATE_REQUEST, REQUEST_UPDATE_SUCCESS } from '../Constants/requestConstants';
import api from '../../config/apiConfig';


const getRequestsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: REQUESTS_LIST_REQUEST});
        const {data} = await api.get('/requests');
        // console.log('Requests data is:' + data[0]['Request_Name'])
        dispatch({type: REQUESTS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: REQUESTS_LIST_FAIL, payload: error})
    }
} 

const submitRequest = (stateValues, postValues) => async (dispatch) => {
    console.log('POST VALUES: ' + postValues.clients + postValues.name)
     
    try {
        dispatch({type: REQUEST_CREATE_REQUEST});
        
        const {data} = await api.post(`/requests/`, postValues);
        console.log('Request data is:' + data)
        dispatch({type: REQUEST_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: REQUEST_CREATE_FAIL, payload: error})
    }
} 

const getRequest = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_REQUEST_REQUEST});
        
        const {data} = await api.get(`/requests/ ${id}`);
        console.log('Request data is:' + data)
        dispatch({type: VIEW_REQUEST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_REQUEST_FAIL, payload: error})
    }
}

const editRequest = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: REQUEST_UPDATE_REQUEST});
        
        const {data} = await api.put(`/requests/`, values);
        console.log('Request data is:' + data)
        dispatch({type: REQUEST_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: REQUEST_UPDATE_FAIL, payload: error})
    }
}



export {getRequestsList, submitRequest, getRequest, editRequest }