import React from 'react';
import { Grid, Typography, Stack, Box, Divider, Button, } from '@mui/material';
import { blue, blueGrey } from '@mui/material/colors';
import useDialog from '../../Hooks/useDialog';
import useAlert from '../../Hooks/useAlert';
import ComplexAlert from '../ComplexAlert';
import AssessmentDialogs from '../Dialogs/CRM/HR/AssessmentDialogs/AssessmentDialogs';

const DisplayCard = ({assessment}) => {

    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)

 
    const handleClick = () => {
        console.log('THIS IS WORKING')
        showDialog(true, 'view', assessment.Id);
    }


    return (
        <>
        { dialog.open ? 
            <AssessmentDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>: ''}
            
        { alert.show ? 
            <ComplexAlert alert={alert} removeAlert={showAlert}/> : ''}


            <Grid item>

           
            <Button variant="contained" onClick={handleClick}  sx={{display: 'block',width: '420px', p: 2, mr: 1 , mb: 1, borderRadius: 2, background: 'white', boxShadow: '1px 2px 5px #64b5f6', '&:hover': {background: blue[200]}}}>
                <Stack direction="row" justifyContent="space-between" sx={{mb: 2}}>
                    <Typography variant="h1" sx={{color: blue[800], fontSize: '.95rem', fontWeight: 900, ml: 1}}>{`ASS-${assessment.Id}`}</Typography>
                    <Typography variant="h1" sx={{color: blue[800], fontSize: '0.95rem', fontWeight: 900}}>Undefined</Typography>
                   
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Box>
                        <Typography sx={{color: blueGrey[800], fontSize: '.75rem', fontWeight: 500}}>Assessment Subject</Typography>
                        <Typography variant="h1" sx={{color: blue[900], fontSize: '0.75rem', fontWeight: 900}}>{assessment.Assessment_Subject}</Typography>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: "column", justifyContent: 'center'}}>
                        <Typography sx={{color: blueGrey[800], fontSize: '.75rem', fontWeight: 500}}>Status</Typography>
                        <Typography variant="h6" sx={{color: blue[900], fontSize: '0.75rem', fontWeight: 900, background: blue[50], px: 1, borderRadius: 2}}>Passed - Client</Typography>
                    </Box>
                    
                    <Box>
                        <Typography sx={{color: blueGrey[800], fontSize: '.75rem', fontWeight: 500}}>Assessment Date</Typography>
                        <Typography variant="h1" sx={{color: blue[900], fontSize: '0.75rem', fontWeight: 900}}>2022-04-06</Typography>
                    </Box>
                </Stack>
                
            </Button >
            </Grid>
        </>
    )
}

export default DisplayCard
