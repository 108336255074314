import { CONTACTS_LIST_REQUEST, CONTACTS_LIST_SUCCESS, CONTACTS_LIST_FAIL, VIEW_CONTACT_FAIL, VIEW_CONTACT_REQUEST, VIEW_CONTACT_SUCCESS, CONTACT_CREATE_FAIL, CONTACT_UPDATE_REQUEST, CONTACT_UPDATE_FAIL, CONTACT_DELETE_REQUEST, CONTACT_DELETE_FAIL, CONTACT_CREATE_REQUEST, CONTACT_CREATE_SUCCESS, CONTACT_UPDATE_SUCCESS, CONTACT_DELETE_SUCCESS } from "../Constants/contactConstants";

const contactsListReducer = (state = {contacts: []}, action) => {

    switch (action.type)
    {
        case CONTACTS_LIST_REQUEST:
            return {loading: true};
 
        case CONTACTS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and contacts data is:' + action.payload)
            return {
                loading: false,
                contacts: action.payload
            }
        case CONTACTS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const contactCreateReducer = (state = {contacts: []}, action) => {

    switch (action.type) {

        case CONTACT_CREATE_REQUEST:
            return {
                loading: true
            }

        case CONTACT_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                contacts: [...state.contacts, {Applicant_Id: 2, Name: value}]
            };

        case CONTACT_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const contactViewReducer = (state= {contact: {}}, action) => {
    switch(action.type)
    {
        case VIEW_CONTACT_REQUEST:
            return {loading: true};
        case VIEW_CONTACT_SUCCESS:
            return {
                    loading: false,
                    contact: action.payload
                }
        case VIEW_CONTACT_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const contactUpdateReducer = (state = {contacts: {}}, action) => {

    switch (action.type) {

        case CONTACT_UPDATE_REQUEST:
            return {
                
            }
        case CONTACT_UPDATE_SUCCESS:

            return {

            }
        case CONTACT_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const contactDeleteReducer = (state = {contacts: {}}, action) => {

    switch (action.type) {
        case CONTACT_DELETE_REQUEST:
            return {
                
            }
        case CONTACT_DELETE_SUCCESS:
            return {

            }
        case CONTACT_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {contactsListReducer, contactCreateReducer, contactViewReducer, contactUpdateReducer, contactDeleteReducer }