import { DOCUMENTS_LIST_REQUEST, DOCUMENTS_LIST_SUCCESS, DOCUMENTS_LIST_FAIL, VIEW_DOCUMENT_FAIL, VIEW_DOCUMENT_REQUEST, VIEW_DOCUMENT_SUCCESS, DOCUMENT_CREATE_REQUEST, DOCUMENT_CREATE_SUCCESS, DOCUMENT_CREATE_FAIL, DOCUMENT_UPDATE_FAIL, DOCUMENT_UPDATE_REQUEST, DOCUMENT_UPDATE_SUCCESS } from '../Constants/documentConstants';
import api from '../../config/apiConfig';


const getDocumentsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: DOCUMENTS_LIST_REQUEST});
        const {data} = await api.get('/documents');
        // console.log('Documents data is:' + data[0]['Document_Name'])
        dispatch({type: DOCUMENTS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: DOCUMENTS_LIST_FAIL, payload: error})
    }
} 

const submitDocument = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: DOCUMENT_CREATE_REQUEST});
        
        const {data} = await api.post(`/documents/`, values); 
        console.log('Document data is:' + Object.getOwnPropertyNames(values.documents[0]))
        console.log('Document data is:' + values.documents[0].expiry)
        dispatch({type: DOCUMENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: DOCUMENT_CREATE_FAIL, payload: error})
    }
} 

const getDocument = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_DOCUMENT_REQUEST});
        
        const {data} = await api.get(`/documents/ ${id}`);
        console.log('Document data is:' + data)
        dispatch({type: VIEW_DOCUMENT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_DOCUMENT_FAIL, payload: error})
    }
}

const editDocument = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: DOCUMENT_UPDATE_REQUEST});
        
        const {data} = await api.put(`/documents/`, values);
        console.log('Document data is:' + data)
        dispatch({type: DOCUMENT_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: DOCUMENT_UPDATE_FAIL, payload: error})
    }
}



export {getDocumentsList, submitDocument, getDocument, editDocument }