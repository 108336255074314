import { AVAILABILITYSTATUSES_LIST_REQUEST, AVAILABILITYSTATUSES_LIST_SUCCESS, AVAILABILITYSTATUSES_LIST_FAIL} from "../Constants/availabilityStatusConstants";

const availabilityStatusesListReducer = (state = {availabilityStatuses: []}, action) => {
 
    switch (action.type)
    {
        case AVAILABILITYSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case AVAILABILITYSTATUSES_LIST_SUCCESS:
            console.log('Code is reaching the reducer and availabilityStatuses data is:' + (action.payload)[0].Statu_esId)
            return {
                loading: false,
                availabilityStatuses: action.payload
            }
        case AVAILABILITYSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 



export {availabilityStatusesListReducer}