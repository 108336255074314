import React from 'react'
import { Typography } from '@mui/material';
import { blueGrey, blue } from '@mui/material/colors';


const FormLabel = ({title, color}) => {

    let selectedColor = '';
    const colors = {
        gray: blueGrey[800],
        blue: blue[700],
    }

    for(const x in colors)
    {
        if(x === color)
        selectedColor = colors[x]

    }
   
    return (
        <>
            {/* <br/>  */}
             <Typography variant="body2" sx={{display: 'inline',color: 'white',background: selectedColor, borderRadius: '5px', px: 2, py: '5px'}}>{title}</Typography>
        </>
    )
}

export default FormLabel
