import { PAYMENTTERMS_LIST_REQUEST, PAYMENTTERMS_LIST_SUCCESS, PAYMENTTERMS_LIST_FAIL, VIEW_PAYMENTTERM_FAIL, VIEW_PAYMENTTERM_REQUEST, VIEW_PAYMENTTERM_SUCCESS, PAYMENTTERM_CREATE_FAIL, PAYMENTTERM_UPDATE_REQUEST, PAYMENTTERM_UPDATE_FAIL, PAYMENTTERM_DELETE_REQUEST, PAYMENTTERM_DELETE_FAIL, PAYMENTTERM_CREATE_REQUEST, PAYMENTTERM_CREATE_SUCCESS, PAYMENTTERM_UPDATE_SUCCESS, PAYMENTTERM_DELETE_SUCCESS } from "../Constants/paymentTermConstants";

const paymentTermsListReducer = (state = {paymentTerms: []}, action) => {

    switch (action.type)
    {
        case PAYMENTTERMS_LIST_REQUEST:
            return {loading: true};
 
        case PAYMENTTERMS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and paymentTerms data is:' + action.payload)
            return {
                loading: false,
                paymentTerms: action.payload
            }
        case PAYMENTTERMS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const paymentTermCreateReducer = (state = {paymentTerms: []}, action) => {

    switch (action.type) {

        case PAYMENTTERM_CREATE_REQUEST:
            return {
                loading: true
            }

        case PAYMENTTERM_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                paymentTerms: [...state.paymentTerms, {Applicant_Id: 2, Name: value}]
            };

        case PAYMENTTERM_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const paymentTermViewReducer = (state= {paymentTerm: {}}, action) => {
    switch(action.type)
    {
        case VIEW_PAYMENTTERM_REQUEST:
            return {loading: true};
        case VIEW_PAYMENTTERM_SUCCESS:
            return {
                    loading: false,
                    paymentTerm: action.payload
                }
        case VIEW_PAYMENTTERM_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const paymentTermUpdateReducer = (state = {paymentTerms: {}}, action) => {

    switch (action.type) {

        case PAYMENTTERM_UPDATE_REQUEST:
            return {
                
            }
        case PAYMENTTERM_UPDATE_SUCCESS:

            return {

            }
        case PAYMENTTERM_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const paymentTermDeleteReducer = (state = {paymentTerms: {}}, action) => {

    switch (action.type) {
        case PAYMENTTERM_DELETE_REQUEST:
            return {
                
            }
        case PAYMENTTERM_DELETE_SUCCESS:
            return {

            }
        case PAYMENTTERM_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {paymentTermsListReducer, paymentTermCreateReducer, paymentTermViewReducer, paymentTermUpdateReducer, paymentTermDeleteReducer }