import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress} from '@mui/material';
import { blueGrey, orange, blue, purple } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicantsList } from '../../../../../Redux/Actions/ApplicantsActions';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getAssessmentTypesList } from '../../../../../Redux/Actions/AssessmentTypesActions';
import { getAssessmentResultsList } from '../../../../../Redux/Actions/AssessmentResultsActions';
import { getAssessmentStatusesList } from '../../../../../Redux/Actions/AssessmentStatusesActions';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { submitAssessment } from '../../../../../Redux/Actions/AssessmentsActions';



const EditAssessmentDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET ASSESSMENTS
    const assessments_ListState = useSelector(state => state.assessments_List);
    const {assessments, loading: loading_assessments} = assessments_ListState;

    //GET DIVISIONS
    const divisions_ListState = useSelector(state => state.divisions_List);
    const {divisions, loading} = divisions_ListState;

    //GET ASSESSMENT TYPE
    const types_ListState = useSelector(state => state.assessmentTypes_List);
    const {assessmentTypes, loading: loading_types} = types_ListState;

    //GET ASSESSMENT RESULT
    const results_ListState = useSelector(state => state.assessmentResults_List);
    const {assessmentResults, loading: loading_results} = results_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;

    //GET ASSESSMENT STATUS
    const statuses_ListState = useSelector(state => state.assessmentStatuses_List);
    const {assessmentStatuses, loading: loading_statuses} = statuses_ListState;

    //GET APPLICANTS
    const applicants_List_State = useSelector((state) => state.applicants_List);
    const {loading: loading_applicants, applicants} = applicants_List_State;
    
       
    //CURRENT DATE
    const d = new Date();
    const yyyy = d.getFullYear()
    const mm = d.getMonth()
    const dd = d.getDay()
    
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getApplicantsList());
        dispatch(getStaffList());
        dispatch(getAssessmentTypesList());
        dispatch(getAssessmentResultsList());
        dispatch(getAssessmentStatusesList());
       
    }, [])
    

    const [values, setValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
            getName(name, value);
            //const the_name = getName(value);
            //setValues({ applicant: the_name })
        }
        else if(name == 'type' || name == 'status' || name == 'result')
        {

            setValues({
                ...values,
                [name]: value
            });
            checkForId(name, value); 
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
 
        }
    }
    const checkForId =  (name, value) => {

        const criteria = ['type', 'status', 'result'];
        criteria.forEach(item => {
            if(item == name)
            {
                const list = name === 'type'? assessmentTypes : name === 'status' ? assessmentStatuses : assessmentResults;
                const name_field = name === 'type'? 'Type_Name' : name === 'status' ? 'Status_Name' : 'Result_Name';
                const id_field = name === 'type'? 'Type_Id' : name === 'status' ? 'Status_Id' : 'Result_Id';

                const object = list.find(type => type[name_field] == value);
                const id = object[id_field];
                console.log(`ID of${value} is: ${id}`)
                
                setSubmitValues({
                    applicant: values.applicant,
                    subject: values.subject,
                    level: values.level,
                    staff: values.staff,
                    ...submitValues,
                    [name]: id
                })
            }
        } )
        // if(criteria === 'type')
        // {
        //     const object = await types_ListState.find(type => type.Type_Name = value);
        //     const id = object.Id;

        //     setValues({
        //         ...values,
        //         [name]: id
        //     })
        // }
        // else ''
        
    }

    const getName = async (name, value) => {

        console.log('Reaching getName function and recieved:' + value)
        if(name === 'applicant')
        {
            const wanted = await applicants.find(x => x.Applicant_Id == value);
            console.log('Wanted object is: ' + wanted)
            setApplicantName(`${wanted.First_Name} ${wanted.Surname}`)
        }
        else
        {
        
            const wanted = await staff.find(x => x.Staff_Id == value);
            console.log('Wanted object is: ' + wanted)
            setStaffName(`${wanted.First_Name} ${wanted.Surname}`)

        }
    }
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitAssessment(submitValues))
        handleSubmit();

    }

    
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >

            {/* <p>status: {values.status}</p>
            <p>subject: {values.subject}</p>
            <p>type: {values.type}</p>
            <p>ofs: {values.staff}</p>
            <h3>submit values</h3>
            <p>status: {submitValues.status}</p>
            <p>subject: {submitValues.subject}</p>
            <p>type: {submitValues.type}</p>
            <p>ofs: {submitValues.staff}</p> */}
            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white'}}  >
                <AssessmentIcon sx={{color: blue[900], mr: 2, fontSize: '1.5rem'}}/>
                
                    <Typography variant="h6" sx={{color: blueGrey[900],fontWeight: 700}}> Edit assessment </Typography>
            </DialogTitle>
    
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={2} alignItems="center" justifyContent="space-evenly">
                    <Grid item lg={7}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 1}}>Basic Information</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Assessment ID</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" disabled type="text" value={`ASS - ${!loading_assessments? assessments.length + 1 : ''}`} className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Assessment Date</Form.Label>
                                    <Form.Control name="assessment_date" disabled type="text" value={values.assessment_date} placeholder="E.g 123qst1" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Assessment Subject</Form.Label>
                                    <Form.Control onChange={handleChange} name="subject" type="email" value={values.subject} placeholder="Your description here" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Related Applicant</Form.Label>
                                    <Form.Select 
                                        name="applicant" 
                                        onChange={handleChange} 
                                        value={values.applicant_id} 
                                        defaultValue="Choose Applicant">
                                        <option>Select Applicant</option>
                                        {  loading_applicants? '' : applicants.map((x) => 
                                                <option key={x.Applicant_Id}>{x.Applicant_Id}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Applicant Name</Form.Label>
                                    <Form.Control 
                                        onChange={handleChange} 
                                        name="applicant_name" 
                                        disabled 
                                        type="text" 
                                        value= {applicantName} 
                                        placeholder="E.g Kelly" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Office Staff</Form.Label>
                                    <Form.Select 
                                        name="staff" 
                                        onChange={handleChange} 
                                        value={values.staff} 
                                        defaultValue="Choose Staff">
                                        <option>Select Consultant</option>
                                        {  loading_staff? '' : staff.map((x) => 
                                                <option key={x.Staff_Id}>{x.Staff_Id}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Staff Name</Form.Label>
                                    <Form.Control 
                                        onChange={handleChange} 
                                        name="staff_name" 
                                        disabled 
                                        type="text" 
                                        value= {staffName} 
                                        placeholder="E.g Kelly" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Row>
                                {/* <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Division</Form.Label>
                                    <Form.Select name="division" onChange={handleChange} value={values.division} defaultValue="Choose Job">
                                        {  loading? '' : divisions.map((x, index) => 

                                        <option key={index}>{x.Name}</option>

                                        )}
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Assessment Type</Form.Label>
                                    <Form.Select name="type" onChange={handleChange} value={values.type} defaultValue="Choose Job">
                                        {  loading_types? '' : assessmentTypes.map((x, index) => 

                                        <option key={index}>{x.Type_Name}</option>

                                        )}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Level</Form.Label>
                                    <Form.Control onChange={handleChange} name="level" type="number" value={values.level} className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: purple[500], borderRadius: '5px', px: 1}}>Applicant Decision</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Assessment Result</Form.Label>
                                    <Form.Select name="result" onChange={handleChange} value={values.result} defaultValue="Construction">
                                        {  loading_results? '' : assessmentResults.map((x, index) => 

                                        <option key={index}>{x.Result_Name}</option>

                                        )}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Assessment Status</Form.Label>
                                    <Form.Select name="status" onChange={handleChange} value={values.status} defaultValue="Choose Job">
                                        {  loading_statuses? '' : assessmentStatuses.map((x, index) => 

                                        <option key={index}>{x.Status_Name}</option>

                                        )}
                                    </Form.Select>
                                </Form.Group>
                                
                            </Row>
                        </Form>
                    </Grid>

                    <Grid item lg={4}>
                        <Paper sx={{p: 3, background: '#f2f7fc', boxShadow: 'none', overflowY: 'scroll'}}>
                            <Typography variant="body1" sx={{color: blue[700],fontWeight: 500, mb: 1, textAlign: 'center'}}>Form Summary</Typography>
                            <Divider sx={{border: '.5px solid gray'}}/>
                            <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{fontWeight: 500,}}>Assessment ID: </Typography>
                                <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`ASS - ${!loading_assessments? assessments.length + 1 : ''}`} </Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{fontWeight: 500,}}>Assessment Date: </Typography>
                                <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.assessment_date}</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                <Typography variant="body2" sx={{color: values.subject? '' : blue[500], fontWeight: values.subject? 800 : 500, ml: 5}}>{values.subject? values.subject : 'null' }</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                <Typography variant="body2" sx={{color: values.applicant? '' : blue[500], fontWeight: values.applicant? 800 : 500, ml: 5}}>{values.applicant? values.applicant : 'null' }</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Name </Typography>
                                <Typography variant="body2" sx={{color: applicantName? '' : blue[500], fontWeight: applicantName? 800 : 500, ml: 5}}>{applicantName? applicantName : 'null' }</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{fontWeight: 500,}}>Staff Name </Typography>
                                <Typography variant="body2" sx={{color: staffName? '' : blue[500], fontWeight: staffName? 800 : 500, ml: 5}}>{staffName? staffName : 'null' }</Typography>
                            </Stack>
                            
                            <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{fontWeight: 500,}}>Assessment Type: </Typography>
                                <Typography variant="body2" sx={{color: values.type? '' : blue[500], fontWeight: values.type? 800 : 500, ml: 5}}>{values.type? values.type : 'null' }</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{fontWeight: 500,}}>Level: </Typography>
                                <Typography variant="body2" sx={{color: values.level? '' : blue[500], fontWeight: values.level? 800 : 500, ml: 5}}>{values.level? values.level : 'null' }</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{fontWeight: 500,}}>Assessment Result: </Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '100%'}}>
                                <Typography variant="body2" sx={{color: values.result? 'white' : blue[500], fontSize: '.75rem', textAlign: 'left', px: values.result? '4px' : '', borderRadius: '3px' ,fontWeight: values.result? 500 : 500, background: values.result? blue[500] : ''}}>{values.result? values.result : 'null' }</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{fontWeight: 500,}}>Assessment Status: </Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '100%'}}>
                                <Typography variant="body2" sx={{color: values.status? 'white' : blue[500], fontSize: values.status? '.75rem' :'', textAlign: 'left', px: values.status? '4px' : '', borderRadius: '3px'  ,fontWeight: values.status? 500 : 500, background: values.status? blue[500] : ''}}>{values.status? values.status : 'null' }</Typography>
                            </Stack>
                            
                           
                        </Paper>
                    </Grid>
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blueGrey[800], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Assessment</Button>
        </DialogActions>
    </Dialog>
    )
}

export default EditAssessmentDialog
