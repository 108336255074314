import React from 'react'
import { Grid, Box, LinearProgress } from '@mui/material';

const HorizontalLoading = () => {
    return (
        <>
           <Grid container sx={{my: 2, background: ''}}>
                
                <Box component="div" sx={{width: '100%'}}>
                    <LinearProgress color="inherit" sx={{ color: 'orange'}} />
                </Box> 
    
           </Grid>  
        </>
    )
}

export default HorizontalLoading
