import { VATRATES_LIST_REQUEST, VATRATES_LIST_SUCCESS, VATRATES_LIST_FAIL, VIEW_VATRATE_FAIL, VIEW_VATRATE_REQUEST, VIEW_VATRATE_SUCCESS, VATRATE_CREATE_FAIL, VATRATE_UPDATE_REQUEST, VATRATE_UPDATE_FAIL, VATRATE_DELETE_REQUEST, VATRATE_DELETE_FAIL, VATRATE_CREATE_REQUEST, VATRATE_CREATE_SUCCESS, VATRATE_UPDATE_SUCCESS, VATRATE_DELETE_SUCCESS } from "../Constants/vatRateConstants";

const vatRatesListReducer = (state = {vatRates: []}, action) => {

    switch (action.type)
    {
        case VATRATES_LIST_REQUEST:
            return {loading: true};
 
        case VATRATES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and vatRates data is:' + action.payload)
            return {
                loading: false,
                vatRates: action.payload
            }
        case VATRATES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const vatRateCreateReducer = (state = {vatRates: []}, action) => {

    switch (action.type) {

        case VATRATE_CREATE_REQUEST:
            return {
                loading: true
            }

        case VATRATE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                vatRates: [...state.vatRates, {Applicant_Id: 2, Name: value}]
            };

        case VATRATE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const vatRateViewReducer = (state= {vatRate: {}}, action) => {
    switch(action.type)
    {
        case VIEW_VATRATE_REQUEST:
            return {loading: true};
        case VIEW_VATRATE_SUCCESS:
            return {
                    loading: false,
                    vatRate: action.payload
                }
        case VIEW_VATRATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const vatRateUpdateReducer = (state = {vatRates: {}}, action) => {

    switch (action.type) {

        case VATRATE_UPDATE_REQUEST:
            return {
                
            }
        case VATRATE_UPDATE_SUCCESS:

            return {

            }
        case VATRATE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const vatRateDeleteReducer = (state = {vatRates: {}}, action) => {

    switch (action.type) {
        case VATRATE_DELETE_REQUEST:
            return {
                
            }
        case VATRATE_DELETE_SUCCESS:
            return {

            }
        case VATRATE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {vatRatesListReducer, vatRateCreateReducer, vatRateViewReducer, vatRateUpdateReducer, vatRateDeleteReducer }