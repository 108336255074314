import { VATRETURNS_LIST_REQUEST, VATRETURNS_LIST_SUCCESS, VATRETURNS_LIST_FAIL, VIEW_VATRETURN_FAIL, VIEW_VATRETURN_REQUEST, VIEW_VATRETURN_SUCCESS, VATRETURN_CREATE_FAIL, VATRETURN_UPDATE_REQUEST, VATRETURN_UPDATE_FAIL, VATRETURN_DELETE_REQUEST, VATRETURN_DELETE_FAIL, VATRETURN_CREATE_REQUEST, VATRETURN_CREATE_SUCCESS, VATRETURN_UPDATE_SUCCESS, VATRETURN_DELETE_SUCCESS } from "../Constants/vatReturnConstants";

const vatReturnsListReducer = (state = {vatReturns: []}, action) => {

    switch (action.type)
    {
        case VATRETURNS_LIST_REQUEST:
            return {loading: true};
 
        case VATRETURNS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and vatReturns data is:' + action.payload)
            return {
                loading: false,
                vatReturns: action.payload
            }
        case VATRETURNS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const vatReturnCreateReducer = (state = {vatReturns: []}, action) => {

    switch (action.type) {

        case VATRETURN_CREATE_REQUEST:
            return {
                loading: true
            }

        case VATRETURN_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                vatReturns: [...state.vatReturns, {Applicant_Id: 2, Name: value}]
            };

        case VATRETURN_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const vatReturnViewReducer = (state= {vatReturn: {}}, action) => {
    switch(action.type)
    {
        case VIEW_VATRETURN_REQUEST:
            return {loading: true};
        case VIEW_VATRETURN_SUCCESS:
            return {
                    loading: false,
                    vatReturn: action.payload
                }
        case VIEW_VATRETURN_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const vatReturnUpdateReducer = (state = {vatReturns: {}}, action) => {

    switch (action.type) {

        case VATRETURN_UPDATE_REQUEST:
            return {
                
            }
        case VATRETURN_UPDATE_SUCCESS:

            return {

            }
        case VATRETURN_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const vatReturnDeleteReducer = (state = {vatReturns: {}}, action) => {

    switch (action.type) {
        case VATRETURN_DELETE_REQUEST:
            return {
                
            }
        case VATRETURN_DELETE_SUCCESS:
            return {

            }
        case VATRETURN_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {vatReturnsListReducer, vatReturnCreateReducer, vatReturnViewReducer, vatReturnUpdateReducer, vatReturnDeleteReducer }