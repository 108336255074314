export const OPPORTUNITIES_LIST_REQUEST = 'OPPORTUNITIES_LIST_REQUEST'
export const OPPORTUNITIES_LIST_SUCCESS = 'OPPORTUNITIES_LIST_SUCCESS'
export const OPPORTUNITIES_LIST_FAIL = 'OPPORTUNITIES_LIST_FAIL'


export const OPPORTUNITY_CREATE_REQUEST = 'OPPORTUNITY_CREATE_REQUEST'
export const OPPORTUNITY_CREATE_SUCCESS = 'OPPORTUNITY_CREATE_SUCCESS'
export const OPPORTUNITY_CREATE_FAIL = 'OPPORTUNITY_CREATE_FAIL'


export const OPPORTUNITY_UPDATE_REQUEST = 'OPPORTUNITY_UPDATE_REQUEST'
export const OPPORTUNITY_UPDATE_SUCCESS = 'OPPORTUNITY_UPDATE_SUCCESS'
export const OPPORTUNITY_UPDATE_FAIL = 'OPPORTUNITY_UPDATE_FAIL'

export const OPPORTUNITY_DELETE_REQUEST = 'OPPORTUNITY_DELETE_REQUEST'
export const OPPORTUNITY_DELETE_SUCCESS = 'OPPORTUNITY_DELETE_SUCCESS'
export const OPPORTUNITY_DELETE_FAIL = 'OPPORTUNITY_DELETE_FAIL'

export const VIEW_OPPORTUNITY_REQUEST = 'VIEW_OPPORTUNITY_REQUEST'
export const VIEW_OPPORTUNITY_SUCCESS = 'VIEW_OPPORTUNITY_SUCCESS'
export const VIEW_OPPORTUNITY_FAIL = 'VIEW_OPPORTUNITY_FAIL'