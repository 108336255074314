import { RATES_LIST_REQUEST, RATES_LIST_SUCCESS, RATES_LIST_FAIL, VIEW_RATE_FAIL, VIEW_RATE_REQUEST, VIEW_RATE_SUCCESS, RATE_CREATE_FAIL, RATE_UPDATE_REQUEST, RATE_UPDATE_FAIL, RATE_DELETE_REQUEST, RATE_DELETE_FAIL, RATE_CREATE_REQUEST, RATE_CREATE_SUCCESS, RATE_UPDATE_SUCCESS, RATE_DELETE_SUCCESS } from "../Constants/rateConstants";

const ratesListReducer = (state = {rates: []}, action) => {

    switch (action.type)
    {
        case RATES_LIST_REQUEST:
            return {loading: true};
 
        case RATES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and rates data is:' + action.payload)
            return {
                loading: false,
                rates: action.payload
            }
        case RATES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const rateCreateReducer = (state = {rates: []}, action) => {

    switch (action.type) {

        case RATE_CREATE_REQUEST:
            return {
                loading: true
            }

        case RATE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                rates: [...state.rates, {Applicant_Id: 2, Name: value}]
            };

        case RATE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const rateViewReducer = (state= {rate: {}}, action) => {
    switch(action.type)
    {
        case VIEW_RATE_REQUEST:
            return {loading: true};
        case VIEW_RATE_SUCCESS:
            return {
                    loading: false,
                    rate: action.payload
                }
        case VIEW_RATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const rateUpdateReducer = (state = {rates: {}}, action) => {

    switch (action.type) {

        case RATE_UPDATE_REQUEST:
            return {
                
            }
        case RATE_UPDATE_SUCCESS:

            return {

            }
        case RATE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const rateDeleteReducer = (state = {rates: {}}, action) => {

    switch (action.type) {
        case RATE_DELETE_REQUEST:
            return {
                
            }
        case RATE_DELETE_SUCCESS:
            return {

            }
        case RATE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {ratesListReducer, rateCreateReducer, rateViewReducer, rateUpdateReducer, rateDeleteReducer }