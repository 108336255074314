import { WOMSTATUSES_LIST_REQUEST, WOMSTATUSES_LIST_SUCCESS, WOMSTATUSES_LIST_FAIL, VIEW_WOMSTATUS_FAIL, VIEW_WOMSTATUS_REQUEST, VIEW_WOMSTATUS_SUCCESS, WOMSTATUS_CREATE_FAIL, WOMSTATUS_UPDATE_REQUEST, WOMSTATUS_UPDATE_FAIL, WOMSTATUS_DELETE_REQUEST, WOMSTATUS_DELETE_FAIL, WOMSTATUS_CREATE_REQUEST, WOMSTATUS_CREATE_SUCCESS, WOMSTATUS_UPDATE_SUCCESS, WOMSTATUS_DELETE_SUCCESS } from "../Constants/womStatusConstants";

const womStatusesListReducer = (state = {womStatuses: []}, action) => {

    switch (action.type)
    {
        case WOMSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case WOMSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and womStatuss data is:' + action.payload)
            return {
                loading: false,
                womStatuses: action.payload
            }
        case WOMSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const womStatusCreateReducer = (state = {womStatuses: []}, action) => {

    switch (action.type) {

        case WOMSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case WOMSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                womStatuses: [...state.womStatuses, {Applicant_Id: 2, Name: value}]
            };

        case WOMSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const womStatusViewReducer = (state= {womStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_WOMSTATUS_REQUEST:
            return {loading: true};
        case VIEW_WOMSTATUS_SUCCESS:
            return {
                    loading: false,
                    womStatus: action.payload
                }
        case VIEW_WOMSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const womStatusUpdateReducer = (state = {womStatuss: {}}, action) => {

    switch (action.type) {

        case WOMSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case WOMSTATUS_UPDATE_SUCCESS:

            return {

            }
        case WOMSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const womStatusDeleteReducer = (state = {womStatuss: {}}, action) => {

    switch (action.type) {
        case WOMSTATUS_DELETE_REQUEST:
            return {
                
            }
        case WOMSTATUS_DELETE_SUCCESS:
            return {

            }
        case WOMSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {womStatusesListReducer, womStatusCreateReducer, womStatusViewReducer, womStatusUpdateReducer, womStatusDeleteReducer }