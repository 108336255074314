export const WORKERS_LIST_REQUEST = 'WORKERS_LIST_REQUEST'
export const WORKERS_LIST_SUCCESS = 'WORKERS_LIST_SUCCESS'
export const WORKERS_LIST_FAIL = 'WORKERS_LIST_FAIL'


export const WORKER_CREATE_REQUEST = 'WORKER_CREATE_REQUEST'
export const WORKER_CREATE_SUCCESS = 'WORKER_CREATE_SUCCESS'
export const WORKER_CREATE_FAIL = 'WORKER_CREATE_FAIL'


export const WORKER_UPDATE_REQUEST = 'WORKER_UPDATE_REQUEST'
export const WORKER_UPDATE_SUCCESS = 'WORKER_UPDATE_SUCCESS'
export const WORKER_UPDATE_FAIL = 'WORKER_UPDATE_FAIL'

export const WORKER_DELETE_REQUEST = 'WORKER_DELETE_REQUEST'
export const WORKER_DELETE_SUCCESS = 'WORKER_DELETE_SUCCESS'
export const WORKER_DELETE_FAIL = 'WORKER_DELETE_FAIL'

export const VIEW_WORKER_REQUEST = 'VIEW_WORKER_REQUEST'
export const VIEW_WORKER_SUCCESS = 'VIEW_WORKER_SUCCESS'
export const VIEW_WORKER_FAIL = 'VIEW_WORKER_FAIL'