import React, { useState, useEffect } from 'react'
import KanbanAPI from '../../../../api/KanbanAPI';
import Item from './Item';
import Dropzone from './Dropzone';
import { Button, Divider, Typography, Stack, Box } from '@mui/material';
import { blueGrey, blue } from '@mui/material/colors';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Column = ({id, title}) => {

    const [items, setItems] = useState([]);
  
    useEffect(()=>{
        console.log('Data has been fetched')
        setItems([...KanbanAPI.getItems(id)]);
    }, [])

    const handleClick = () => {
        KanbanAPI.insertItem(id, 'I am brand')
        //setItems(KanbanAPI.getItems(id));
        console.log('Items' + items)
    }
    return (
        <Box sx={{mr: 2}}> 
        
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center" className="newkanban-column-title py-2">
                    {id == 1 ? <FormatListNumberedIcon fontSize="small" sx={{color: blue[500]}}/>: id == 2 ?  <TimelapseIcon fontSize="small" sx={{color: blue[500]}}/> : <CheckCircleOutlineIcon fontSize="small" sx={{color: blue[500]}}/> }
                    <Typography variant="h6" sx={{fontSize: '.9rem',fontWeight: 600,color: blueGrey[50]}} >{title}</Typography>
                    <Typography variant="h6" sx={{fontSize: '.9rem',fontWeight: 500,color: blue[200]}} >(Num)</Typography>
                </Stack>


                <div data-id={id} className="newkanban-column" style={{border: '3px solid whitesmoke',borderRadius: '5px',background: blue[50], padding: '5px 5px'}}>
                
                    {/* <Divider/> */}
                    <Typography variant="body2" sx={{color: blueGrey[200], textAlign: 'center', my: 1}}>Showing Items</Typography>
                    <Dropzone/>
                    <div className="newkanban-items">
                        {items.map(item => <Item key={item.id} {...item}/>)}
                    </div>
                    <Button onClick={handleClick} variant="contained" sx={{width: '100%', background: blue[600]}} className="newkanban-button"> Add item </Button>

                </div> 
        </Box>
    )
}

export default Column
