import React, { useState } from 'react';
import { ListItemText, ListItemButton, ListItemIcon, List, Collapse } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { setL2Link_ToActive, setL3Link_ToActive, setL1Link_ToActive } from '../../Redux/Actions/SitemapActions';
import { useDispatch, useSelector } from 'react-redux';
import { blueGrey, blue } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import CircleIcon from '@mui/icons-material/Circle';

const DropdownItem = ({item, drawerStatus}) => {

    //initialising 
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [list, setList] = useState([]);
 
    const dispatch = useDispatch();
    const secondLevelNavActiveState = useSelector(state => state.secondLevelNavActiveState);
    const {active_link} = secondLevelNavActiveState;
    
    const handleClick = (item) => { 
         
        //dispatch(setL2Link_ToActive(item.id))
        //dispatch(setL3Link_ToActive(''))
       
        if(item.secondSubMenu)
        {
             
            setOpen(!open);
            setList([...item.secondSubMenu]);
        }
        else{

        const created_array = String(item.id).split("").map(num => Number(num))
        dispatch(setL1Link_ToActive(created_array[0]))
        dispatch(setL2Link_ToActive(item.id));
        navigate(item.path)
        }
    }
    
    const thirdLevelNavActiveState = useSelector(state => state.thirdLevelNavActiveState);
    const {active_link: L3_active_link} = thirdLevelNavActiveState;

    const handleClickL3Link = (item) => {

        const created_array = String(item.id).split("").map(num => Number(num))
        dispatch(setL1Link_ToActive(created_array[0]))
        const mo = item.id;
        let myFunc = num => Number(num);
        let array_numbers = Array.from(String(mo), myFunc);
        const number = array_numbers[0] + array_numbers[1];
        console.log('NUMBER is:' + array_numbers)
        dispatch(setL3Link_ToActive(item.id));
        navigate(item.path)
        if(item.id < 240)
        {

            dispatch(setL2Link_ToActive(item.id));
        } 
        else if(item.id > 240 && item.id < 250)
        {

            dispatch(setL2Link_ToActive(24))
        }
        else if(item.id > 250 && item.id < 260)
        {

            dispatch(setL2Link_ToActive(25))
        }
        else if(item.id > 260 && item.id < 270)
        {

            dispatch(setL2Link_ToActive(26))
        }
        else if(item.id > 270 && item.id < 280)
        {

            dispatch(setL2Link_ToActive(27))
        }
        else if(item.id > 280 && item.id < 290)
        {

            dispatch(setL2Link_ToActive(28))
        }
        else if(item.id > 290 && item.id < 300)
        {

            dispatch(setL2Link_ToActive(29))
        }
        

    }


    return <>
                <ListItemButton
                    selected = {item.id === active_link} 
                    onClick={()=> {handleClick(item)}}
                    className={item.id === active_link? 'L2-active': ''}
                    sx={{ pl: 2 }}
                    disabled={item.title === 'Calendars'? true : false}
                >
                    <ListItemIcon sx={{color: item.id === active_link? blue[50] : blueGrey[800] }}>{item.icon}</ListItemIcon>
                    <ListItemText  primary={item.title} className={item.id === active_link? 'L2-text-active': ''} primaryTypographyProps={{color: item.id === active_link? blue[50] : blueGrey[700], fontSize: 14, fontFamily: 'Poppins'}}/>
                    {/* <ListItemText  primary={item.title} className={item.id === active_link? 'L2-text-active': ''} primaryTypographyProps={{color: item.id === active_link? blue[500] : blueGrey[700]}}/> */}
                    {/* {
                        item.secondSubMenu && open?
                        <ExpandMore sx={{ color: item.id === active_link? blue[600] : blueGrey[900]}}/>
                        : item.secondSubMenu?
                        <ExpandLess sx={{color: item.id === active_link? blue[600] : blueGrey[900]}}/>
                        : null
                    } */}
                </ListItemButton> 
                {
                    list && open?
                    <List sx={{background: 'rgba(227, 242, 253, .5)', width: '120%', ml: '-20px', pl: '20px'}} >
                    {/* <List sx={{background: 'rgba(242, 247, 252, .9)', width: '120%', ml: '-20px', pl: '20px'}} > */}
                        <Collapse in={open} timeout="auto" unmountOnExit>
                        {
                            list.map(item =>
                                <ListItemButton 
                                    selected ={item.id === L3_active_link}
                                    className="L3-active"
                                    onClick={()=> {handleClickL3Link(item)}}
                                    //sx={{borderLeft: item.id === L3_active_link? '2px solid black' : ''}}
                                    sx={{ml: '2px',  '&:hover': {background: 'none', color: 'pink'}}}
                                >
                                    <CircleIcon fontSize="small" sx={{m: 0, p: 0, color: item.id === L3_active_link? blue[600] : blueGrey[800],fontSize: '.4rem', mr: '20px'}}/>
                                    {/* <ListItemIcon sx={{background: 'pink', maxWidth: '10px', color: item.id === active_link? blueGrey[700] : blueGrey[800] }}><CircleIcon fontSize="small" sx={{m: 0, p: 0, color: item.id === L3_active_link? blue[800] : blueGrey[800],fontSize: '.6rem'}}/></ListItemIcon> */}
                                    <ListItemText className={item.id === L3_active_link? 'L3-text-active' : 'L3-text'} primary={item.title} primaryTypographyProps ={{ display: drawerStatus ? '' : 'none',p: 0, m: 0, fontSize: 14, fontFamily: 'Poppins', color: item.id === L3_active_link? blue[600] : blueGrey[400], fontWeight: item.id === L3_active_link? 500 : 500 , '&:hover': {background: 'none', color: 'pink'}}}/>
                                </ListItemButton>
                            )
                        }
                        </Collapse>
                    </List>
                        : ''
                }
          </>
}

export default DropdownItem
