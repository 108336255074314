export const CLIENTS_LIST_REQUEST = 'CLIENTS_LIST_REQUEST'
export const CLIENTS_LIST_SUCCESS = 'CLIENTS_LIST_SUCCESS'
export const CLIENTS_LIST_FAIL = 'CLIENTS_LIST_FAIL'


export const CLIENT_CREATE_REQUEST = 'CLIENT_CREATE_REQUEST'
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS'
export const CLIENT_CREATE_FAIL = 'CLIENT_CREATE_FAIL'


export const CLIENT_UPDATE_REQUEST = 'CLIENT_UPDATE_REQUEST'
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS'
export const CLIENT_UPDATE_FAIL = 'CLIENT_UPDATE_FAIL'

export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST'
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS'
export const CLIENT_DELETE_FAIL = 'CLIENT_DELETE_FAIL'

export const VIEW_CLIENT_REQUEST = 'VIEW_CLIENT_REQUEST'
export const VIEW_CLIENT_SUCCESS = 'VIEW_CLIENT_SUCCESS'
export const VIEW_CLIENT_FAIL = 'VIEW_CLIENT_FAIL'