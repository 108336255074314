export const HOLIDAYSTATUSES_LIST_REQUEST = 'HOLIDAYSTATUSES_LIST_REQUEST';
export const HOLIDAYSTATUSES_LIST_SUCCESS = 'HOLIDAYSTATUSES_LIST_SUCCESS';
export const HOLIDAYSTATUSES_LIST_FAIL = 'HOLIDAYSTATUSES_LIST_FAIL'; 

export const HOLIDAYSTATUS_CREATE_REQUEST = 'HOLIDAYSTATUS_CREATE_REQUEST'
export const HOLIDAYSTATUS_CREATE_SUCCESS = 'HOLIDAYSTATUS_CREATE_SUCCESS'
export const HOLIDAYSTATUS_CREATE_FAIL = 'HOLIDAYSTATUS_CREATE_FAIL'


export const HOLIDAYSTATUS_UPDATE_REQUEST = 'HOLIDAYSTATUS_UPDATE_REQUEST'
export const HOLIDAYSTATUS_UPDATE_SUCCESS = 'HOLIDAYSTATUS_UPDATE_SUCCESS'
export const HOLIDAYSTATUS_UPDATE_FAIL = 'HOLIDAYSTATUS_UPDATE_FAIL'

export const HOLIDAYSTATUS_DELETE_REQUEST = 'HOLIDAYSTATUS_DELETE_REQUEST'
export const HOLIDAYSTATUS_DELETE_SUCCESS = 'HOLIDAYSTATUS_DELETE_SUCCESS'
export const HOLIDAYSTATUS_DELETE_FAIL = 'HOLIDAYSTATUS_DELETE_FAIL'

export const VIEW_HOLIDAYSTATUS_REQUEST = 'VIEW_HOLIDAYSTATUS_REQUEST'
export const VIEW_HOLIDAYSTATUS_SUCCESS = 'VIEW_HOLIDAYSTATUS_SUCCESS'
export const VIEW_HOLIDAYSTATUS_FAIL = 'HOLIDAYSTATUS_FAIL'