import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, Breadcrumbs, TextField} from '@mui/material';
import { blueGrey, pink, lightBlue, lightGreen } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useDispatch, useSelector } from 'react-redux';
import {  submitRequest } from '../../../../../Redux/Actions/RequestActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { Link as BreadcrumbLink } from '@mui/material';
import { getDepartmentsList } from '../../../../../Redux/Actions/DepartmentActions';
import { getBranchesList } from '../../../../../Redux/Actions/BranchActions';
import { getRateCardsList } from '../../../../../Redux/Actions/RateCardActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SummaryBox from '../../../../Utilities/SummaryBox';
import SideCrumbs from '../../../../Utilities/SideCrumbs';
import useDate from '../../../../../Hooks/useDate';
import { submitVisit } from '../../../../../Redux/Actions/VisitActions';


const AddVisitDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET BRANCHES
    const {branches, loading: loading_branches} = useSelector(state => state.branches_List);
    //GET DEPARTMENTS
    const {departments, loading} = useSelector(state => state.departments_List);
    //GET DEPARTMENTS
    const {rateCards, loading: loading_cards} = useSelector(state => state.rateCards_List);
    //GET JOB ROLES
    const {loading: loading_roles, jobRoles}  = useSelector((state) => state.jobRoles_List);
    //GET OFFICE STAFF AS CONSULTANT
    const {staff, loading: loading_staff} = useSelector(state => state.staff_List);


    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getJobRolesList());
        dispatch(getDepartmentsList());
        dispatch(getBranchesList());
        dispatch(getRateCardsList());
       
    }, [])
    

    const [values, setValues] = useState({
            name: '',
            visittypes: '',
            date: '',
        });

    const [submitValues, setSubmitValues] = useState({
            name: '',
            visittypes: '',
            date: '',
        });   
        
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitVisit(values, submitValues))
        handleSubmit('success',"Successfully saved visit information");
        
    } 
    
    const animatedComponents = makeAnimated();
    
    const [inputValue, setInputValue] = useState('');
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
    
    const [date, setDate] = useState({
        date: '',
    });


    const handleDateChange = (newValue, name) => {
        
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate({
            ...date,
            [name]: newValue
        });
        setValues({
            ...values,
            [name]: `${day}/${month+1}/${year}`
        })
        setSubmitValues({
            ...submitValues,
            [name]: `${day}/${month+1}/${year}`
        })
    }


    
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }


    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: lightBlue[500]}}  >
                
                <EngineeringIcon fontSize="large" sx={{color: lightBlue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: lightBlue[200],fontWeight: 700}}> Add Visit </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    
                    <SideCrumbs/>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Visit ID</Form.Label>
                                <Form.Control disabled type="text" value="LSV-3" placeholder="E.g 123qst1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Name</Form.Label>
                                <Form.Control type="text" name="name" value={values.name} onChange={handleChange} placeholder="Enter name" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Visit Type</Form.Label>
                                    <AsyncSelect
                                        value={values.visittypes}
                                        name="visittypes"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'visittypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                        
                        <br/>
                        <Typography id="staff" variant="body2" sx={{display: 'inline',color: 'white',background: pink[400], borderRadius: '5px', px: 2, py: '5px'}}>Date and Time</Typography>
                        <Row>

                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="date"
                                            label="Date"
                                            value={date.date}
                                            onChange={(e) => handleDateChange(e, "date")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>
                        </Row>

                        </Form>
                    </Grid>

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: lightBlue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: lightBlue[800], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Visit</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddVisitDialog
