import { RATESTATUSES_LIST_REQUEST, RATESTATUSES_LIST_SUCCESS, RATESTATUSES_LIST_FAIL, VIEW_RATESTATUS_FAIL, VIEW_RATESTATUS_REQUEST, VIEW_RATESTATUS_SUCCESS, RATESTATUS_CREATE_FAIL, RATESTATUS_UPDATE_REQUEST, RATESTATUS_UPDATE_FAIL, RATESTATUS_DELETE_REQUEST, RATESTATUS_DELETE_FAIL, RATESTATUS_CREATE_REQUEST, RATESTATUS_CREATE_SUCCESS, RATESTATUS_UPDATE_SUCCESS, RATESTATUS_DELETE_SUCCESS } from "../Constants/rateStatusConstants";

const rateStatusesListReducer = (state = {rateStatuses: []}, action) => {

    switch (action.type)
    {
        case RATESTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case RATESTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and rateStatuss data is:' + action.payload)
            return {
                loading: false,
                rateStatuses: action.payload
            }
        case RATESTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const rateStatusCreateReducer = (state = {rateStatuses: []}, action) => {

    switch (action.type) {

        case RATESTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case RATESTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                rateStatuses: [...state.rateStatuses, {Applicant_Id: 2, Name: value}]
            };

        case RATESTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const rateStatusViewReducer = (state= {rateStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_RATESTATUS_REQUEST:
            return {loading: true};
        case VIEW_RATESTATUS_SUCCESS:
            return {
                    loading: false,
                    rateStatus: action.payload
                }
        case VIEW_RATESTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const rateStatusUpdateReducer = (state = {rateStatuss: {}}, action) => {

    switch (action.type) {

        case RATESTATUS_UPDATE_REQUEST:
            return {
                
            }
        case RATESTATUS_UPDATE_SUCCESS:

            return {

            }
        case RATESTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const rateStatusDeleteReducer = (state = {rateStatuss: {}}, action) => {

    switch (action.type) {
        case RATESTATUS_DELETE_REQUEST:
            return {
                
            }
        case RATESTATUS_DELETE_SUCCESS:
            return {

            }
        case RATESTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {rateStatusesListReducer, rateStatusCreateReducer, rateStatusViewReducer, rateStatusUpdateReducer, rateStatusDeleteReducer }