export const CREDITCHECKS_LIST_REQUEST = 'CREDITCHECKS_LIST_REQUEST';
export const CREDITCHECKS_LIST_SUCCESS = 'CREDITCHECKS_LIST_SUCCESS';
export const CREDITCHECKS_LIST_FAIL = 'CREDITCHECKS_LIST_FAIL'; 

export const CREDITCHECK_CREATE_REQUEST = 'CREDITCHECK_CREATE_REQUEST'
export const CREDITCHECK_CREATE_SUCCESS = 'CREDITCHECK_CREATE_SUCCESS'
export const CREDITCHECK_CREATE_FAIL = 'CREDITCHECK_CREATE_FAIL'


export const CREDITCHECK_UPDATE_REQUEST = 'CREDITCHECK_UPDATE_REQUEST'
export const CREDITCHECK_UPDATE_SUCCESS = 'CREDITCHECK_UPDATE_SUCCESS'
export const CREDITCHECK_UPDATE_FAIL = 'CREDITCHECK_UPDATE_FAIL'

export const CREDITCHECK_DELETE_REQUEST = 'CREDITCHECK_DELETE_REQUEST'
export const CREDITCHECK_DELETE_SUCCESS = 'CREDITCHECK_DELETE_SUCCESS'
export const CREDITCHECK_DELETE_FAIL = 'CREDITCHECK_DELETE_FAIL'

export const VIEW_CREDITCHECK_REQUEST = 'VIEW_CREDITCHECK_REQUEST'
export const VIEW_CREDITCHECK_SUCCESS = 'VIEW_CREDITCHECK_SUCCESS'
export const VIEW_CREDITCHECK_FAIL = 'VIEW_CREDITCHECK_FAIL'