import { ACCSANDINCIDENTS_LIST_REQUEST, ACCSANDINCIDENTS_LIST_SUCCESS, ACCSANDINCIDENTS_LIST_FAIL, VIEW_ACCSANDINCIDENT_FAIL, VIEW_ACCSANDINCIDENT_REQUEST, VIEW_ACCSANDINCIDENT_SUCCESS, ACCSANDINCIDENT_CREATE_REQUEST, ACCSANDINCIDENT_CREATE_SUCCESS, ACCSANDINCIDENT_CREATE_FAIL, ACCSANDINCIDENT_UPDATE_FAIL, ACCSANDINCIDENT_UPDATE_REQUEST, ACCSANDINCIDENT_UPDATE_SUCCESS } from '../Constants/accsAndIncidentConstants';
import api from '../../config/apiConfig';


const getAccsAndIncidentsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: ACCSANDINCIDENTS_LIST_REQUEST});
        const {data} = await api.get('/accidentsandincidents');
        // console.log('AccsAndIncidents data is:' + data[0]['AccsAndIncident_Name'])
        dispatch({type: ACCSANDINCIDENTS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: ACCSANDINCIDENTS_LIST_FAIL, payload: error})
    }
} 

const submitAccsAndIncident = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: ACCSANDINCIDENT_CREATE_REQUEST});
        
        const {data} = await api.post(`/accidentsandincidents/`, postValues);
        console.log('AccsAndIncident data is:' + data)
        dispatch({type: ACCSANDINCIDENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ACCSANDINCIDENT_CREATE_FAIL, payload: error})
    }
} 

const getAccsAndIncident = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_ACCSANDINCIDENT_REQUEST});
        
        const {data} = await api.get(`/accidentsandincidents/ ${id}`);
        console.log('AccsAndIncident data is:' + data)
        dispatch({type: VIEW_ACCSANDINCIDENT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_ACCSANDINCIDENT_FAIL, payload: error})
    }
}

const editAccsAndIncident = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: ACCSANDINCIDENT_UPDATE_REQUEST});
        
        const {data} = await api.put(`/accidentsandincidents/`, values);
        console.log('AccsAndIncident data is:' + data)
        dispatch({type: ACCSANDINCIDENT_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: ACCSANDINCIDENT_UPDATE_FAIL, payload: error})
    }
}



export {getAccsAndIncidentsList, submitAccsAndIncident, getAccsAndIncident, editAccsAndIncident }