import { HOLIDAYS_LIST_REQUEST, HOLIDAYS_LIST_SUCCESS, HOLIDAYS_LIST_FAIL, VIEW_HOLIDAY_FAIL, VIEW_HOLIDAY_REQUEST, VIEW_HOLIDAY_SUCCESS, HOLIDAY_CREATE_FAIL, HOLIDAY_UPDATE_REQUEST, HOLIDAY_UPDATE_FAIL, HOLIDAY_DELETE_REQUEST, HOLIDAY_DELETE_FAIL, HOLIDAY_CREATE_REQUEST, HOLIDAY_CREATE_SUCCESS, HOLIDAY_UPDATE_SUCCESS, HOLIDAY_DELETE_SUCCESS } from "../Constants/holidayConstants";

const holidaysListReducer = (state = {holidays: []}, action) => {

    switch (action.type)
    {
        case HOLIDAYS_LIST_REQUEST:
            return {loading: true};
 
        case HOLIDAYS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and holidays data is:' + action.payload)
            return {
                loading: false,
                holidays: action.payload
            }
        case HOLIDAYS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const holidayCreateReducer = (state = {holidays: []}, action) => {

    switch (action.type) {

        case HOLIDAY_CREATE_REQUEST:
            return {
                loading: true
            }

        case HOLIDAY_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                holidays: [...state.holidays, {Applicant_Id: 2, Name: value}]
            };

        case HOLIDAY_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const holidayViewReducer = (state= {holiday: {}}, action) => {
    switch(action.type)
    {
        case VIEW_HOLIDAY_REQUEST:
            return {loading: true};
        case VIEW_HOLIDAY_SUCCESS:
            return {
                    loading: false,
                    holiday: action.payload
                }
        case VIEW_HOLIDAY_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const holidayUpdateReducer = (state = {holidays: {}}, action) => {

    switch (action.type) {

        case HOLIDAY_UPDATE_REQUEST:
            return {
                
            }
        case HOLIDAY_UPDATE_SUCCESS:

            return {

            }
        case HOLIDAY_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const holidayDeleteReducer = (state = {holidays: {}}, action) => {

    switch (action.type) {
        case HOLIDAY_DELETE_REQUEST:
            return {
                
            }
        case HOLIDAY_DELETE_SUCCESS:
            return {

            }
        case HOLIDAY_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {holidaysListReducer, holidayCreateReducer, holidayViewReducer, holidayUpdateReducer, holidayDeleteReducer }