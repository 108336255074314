import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress} from '@mui/material';
import { blueGrey, orange, blue, purple, red, deepPurple, deepOrange, green } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useDispatch, useSelector } from 'react-redux';
import useAlert from '../../../../../Hooks/useAlert';
import ComplexAlert from '../../../../ComplexAlert';
import AlarmOnRoundedIcon from '@mui/icons-material/AlarmOnRounded';
import useDialog from '../../../../../Hooks/useDialog';
import { getShift } from '../../../../../Redux/Actions/ShiftActions';



const ViewProspectDialog = ({open, onClose, info, type, handleSubmit}) => {


    const {dialog} = useDialog();
    console.log('DIALOG INFO:' + info)

    const dispatch = useDispatch();

    //GET SHIFT
    const shiftDetails = useSelector(state => state.shift_View) //receiving state object
    const {loading, shift } = shiftDetails;

    
    //CURRENT DATE
    const d = new Date(), yyyy = d.getFullYear(), mm = d.getMonth(), dd = d.getDay()
    
    //USE ALERT
    const {alert, showAlert} = useAlert();
    const [view, setView] = useState('details');

    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getShift(info));
       
    }, [view])
    
    

    const changeView = (type) => {
        if(type === 'details')
        {

            setView('details');
        }
        else{
            setView('clockin');
        }
    }

    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >
            {/* <h3>I am receiving info: {info.Assessment_Subject}</h3> */}
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: deepPurple[800]}} className=""  >
                <Stack direction="row" alignItems="center">
                    <AssessmentIcon sx={{color: blue[50], mr: 2, fontSize: '1.5rem'}}/>
                    <Typography variant="h6" sx={{color: 'white',fontWeight: 700}}>View Shift</Typography>
                </Stack>
                <Stack direction="row">
                    <Button onClick={()=> changeView('details')} size="small" sx={{background: deepPurple[900], color: 'white', px: 2, py: '2px', mr: 1}}>
                        Details
                    </Button>
                    <Button onClick={()=> changeView('clockin')} size="small" sx={{background: deepPurple[700], color: 'white', px: 2, py: '2px'}}>
                        Clock In <AlarmOnRoundedIcon fontSize="small" sx={{ml: 1}}/>
                    </Button>
                    {/* <Typography variant="body2" sx={{color: "white",fontWeight: 500, background: deepPurple[700], borderRadius: 3,  px: 2, py: '5px'}}>in Progress</Typography> */}

                </Stack>
            </DialogTitle>

        {alert.show? 
        <ComplexAlert alert={alert} removeAlert={showAlert}/> : ''
        }
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        {
            view === 'details'? 
        <>
            <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">
                    <Grid item lg={12}>
                        <Form className="">
                            
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Shift Status</Form.Label>
                                    <Typography variant="body2" sx={{display: 'inline',color: 'white',background: green[500], borderRadius: '5px',  px: 2, py: '5px', ml: 2}}>{`${loading? '' : shift.Shift_Status}`}</Typography>
                                </Form.Group>
                                
                                
                            </Row>
                            
                            <br/>
                            <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[800], mb: '5px', borderRadius: '5px',  px: 2, py: '5px'}}>Basic Information</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Shift ID</Form.Label>
                                    <Form.Control  name="id" autoFocus="true" disabled type="text" value={`SHT - ${loading? '' : shift.Shift_Id}`} className="border-0" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Shift Name</Form.Label>
                                    <Form.Control name="assessment_date" disabled type="text" value={loading? '': `${shift.First_Name} ${shift.Surname} | ${shift.Date} | ${shift.Client}`} placeholder="E.g 123qst1" className="border-0" />
                                </Form.Group>
                                
                            </Row>

                            <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Shift Date</Form.Label>
                                <Form.Control name="assessment_date" disabled type="text" value={loading? '' : shift.Date} placeholder="E.g 123qst1" className="border-0" />
                            </Form.Group>
                            </Row>
                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: deepPurple[500], borderRadius: '5px',  px: 2, py: '5px'}}>People on Shift</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Worker</Form.Label>
                                    <Form.Control name="assessment_date" disabled type="text" value={loading? '': `${shift.First_Name} ${shift.Surname}`} placeholder="E.g 123qst1" className="border-0" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Staff</Form.Label>
                                    <Form.Control name="assessment_date" disabled type="text" value={loading? '': `${shift.Staff_FName} ${shift.Staff_LName} `} placeholder="E.g 123qst1" className="border-0" />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Client</Form.Label>
                                    <Form.Control name="assessment_date" disabled type="text" value={loading? '': `${shift.Client} `} placeholder="E.g 123qst1" className="border-0" />
                                </Form.Group>
                            </Row>

                            <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[800], borderRadius: '5px',  px: 2, py: '5px',}}>Notes</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="mb-0 p-0 view-label">Shift Notes</Form.Label>
                                    <Form.Control 
                                         
                                        name="subject" 
                                        disabled 
                                        as="textarea" 
                                        value={'Hard coded notes'} 
                                        className="border-0" />
                                </Form.Group>
                                
                                
                            </Row>
                        </Form>
                    </Grid>

                  
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
        
        </DialogActions>
        </>
        : 

        ''
    }
    </Dialog>
    )
}

export default ViewProspectDialog
