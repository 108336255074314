import React, {useState, useRef, useEffect} from 'react'
import PageHeader from '../../../Components/PageHeader';
import { Typography, Paper, Grid, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Button, Card, ToggleButtonGroup, ToggleButton, ClickAwayListener, MenuItem, MenuList, Popper, Grow, Divider, IconButton, TextField, Alert, List, ListItem, LinearProgress, Box, Tabs, Tab, Stack, ListItemText, ListItemAvatar, Avatar, ListItemIcon, Breadcrumbs } from '@mui/material';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router'; 
import {matchPath, useParams} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home'; 
import CircleIcon from '@mui/icons-material/Circle';
import { blueGrey, green, blue, deepOrange, red, deepPurple } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import DetailHeader from '../../../Components/Applicants/DetailHeader';
import Select from 'react-select';
import makeAnimated from 'react-select/animated'
import { getWorker } from '../../../Redux/Actions/WorkerActions';

const ViewStaff = () => {
 
    const {id} = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    //GET WORKER
    const worker_records = useSelector(state => state.worker_View);
    const {loading, worker} = worker_records;

      //ON COMPONENT DID MOUNT
      useEffect(()=> {
        dispatch(getWorker(id));
    
        
    }, [])

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 0 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
    
    const [tabValue, setTabValue] = React.useState(0);
    
      const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };

      const [isEditing, setIsEditing] = useState(false);

      const animatedComponents = makeAnimated();

        const workers = [
            {value: 'Emily Bone', label: 'Emily Bone'},
            {value: 'John Cusa', label: 'John Cusa'},
            {value: 'Mah Jani', label: 'Mah Jani'},
        ]

    return (
        <>
            {/* <PageHeader page="Worker Details" previous  /> */}
            <Grid container justifyContent="space-between">
                <Breadcrumbs>
                    <Link><HomeIcon fontSize="small" sx={{color: blueGrey[300]}}/></Link>
                    <Link underline="none" sx={{color: blueGrey[300]}} href="#">CRM</Link>
                    <Link underline="none" sx={{color: blueGrey[300]}} onClick={()=> navigate('/staff')} href="#">Staff</Link>
                    <Link underline="none" sx={{color: deepPurple[400]}}>Details</Link>
                </Breadcrumbs>
             </Grid>

            <Grid container  alignItems="" justifyContent="space-between" sx={{mt: 3, mb: 0, background: ''}}>
                
                <Grid item lg={12} sx={{mb: 0, pb: 0}}>
                    
                    <DetailHeader headerFor="worker-details" values={loading? '' : `${worker.First_Name} ${worker.Surname}`}/>

                </Grid>
                <Grid item lg={12} sx={{background: '', p: 2, mt: 2, borderRadius: '5px 5px 0 0'}}>
                    
                    <Grid container justifyContent="flex-start" alignItems="center" sx={{background: 'white', p: 0, mt: 0, borderRadius: '5px 5px 0 0'}}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" sx={{p: 0}}>
                                <Tab label="Information"/>
                                <Tab label={`Current Tasks`}  />
                                <Tab label="Timesheets"  />
                                <Tab label="Other"  />
                            </Tabs>
                    </Grid>

                    <Grid container sx={{mt: 2}}>

                    <Grid item lg={9} sx={{mt: 1}}>
                        <Stack direction="row" alignItems="center"  sx={{mb: 2, mt: 0}}>
                            <Typography variant="body1" sx={{fontWeight: 900}}>Basic Details</Typography>
                            <Typography variant="body2" sx={{fontSize: '0.7rem', fontWeight: 500, px: 1, py: 0,ml: 1,color: 'white', background: deepPurple[700], borderRadius: '5px'}}>Info</Typography>
                        </Stack>
                            <Paper sx={{mt: 0,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                
                                <Form className="">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridEmail" >
                                            <Form.Label className="view-label mb-3">Worker ID</Form.Label>
                                            <Form.Control disabled type="email" value={`WRK-${id}`} placeholder="E.g 123qst1" className={`${isEditing? '' : 'border-0  '}`} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label className="view-label mb-3 ">Worker Name</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        isMulti
                                                        components={animatedComponents}
                                                        placeholder="Select applicants ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${worker.First_Name} ${worker.Surname}`}</Typography>
                                            }
                                        </Form.Group>
                                        
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} controlId="formGridEmail" >
                                            <Form.Label className="view-label mb-3">Email</Form.Label>
                                            <Form.Control autoFocus={isEditing? true : false} disabled type="email" value={loading? '' : worker.Email} placeholder="E.g John" className={`${isEditing? '' : 'border-0 '}`} />
                                        </Form.Group>
                                    </Row>
                                </Form>
                            </Paper>

                            <Stack direction="row" alignItems="center"  sx={{mb: 2, mt: 3}}>
                                <Typography variant="body1" sx={{fontWeight: 900}}>Worker Specifics</Typography>
                                <CircleIcon fontSize="small" sx={{color: deepPurple[800], ml: 1}}/>
                            </Stack>
                            <Paper sx={{mt: 0,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                           
                                <Form>
                                   
                                    <Row className="mt-3">
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="view-label mb-3">Division</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        isMulti
                                                        components={animatedComponents}
                                                        placeholder="Select division ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Construction</Typography>
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="view-label mb-3">Consultant</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        isMulti
                                                        components={animatedComponents}
                                                        placeholder="Select staff ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${worker.Staff_FName} ${worker.Staff_SName}`}</Typography>
                                            }
                                        </Form.Group>
                                       

                                    </Row>
                                    <Row className="mt-3">
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="view-label mb-3">Worker Type</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        isMulti
                                                        components={animatedComponents}
                                                        placeholder="Select type ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: deepOrange[400], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : worker.Type_Name}</Typography>
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="view-label mb-3">Worker Status</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        isMulti
                                                        components={animatedComponents}
                                                        placeholder="Select status ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : worker.Status_Name}</Typography>
                                            }
                                        </Form.Group>
                                    </Row>
                                </Form>
                            </Paper>

                            <Stack direction="row" alignItems="center"  sx={{mb: 2, mt: 3}}>
                                <Typography variant="body1" sx={{fontWeight: 900}}>Result</Typography>
                            </Stack>
                                <Paper sx={{mt: 0,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                    <Form>
                                    <Row className="mt-3">
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="view-label mb-3">Worker Result</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        components={animatedComponents}
                                                        placeholder="Select result ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : worker.Result_Name}</Typography>
                                            }
                                        </Form.Group>
                                    </Row>
                                    
                                </Form>
                            </Paper>

                           
                    
                    </Grid>
                    </Grid>
                </Grid>

               

            </Grid>

            <Grid container spacing={1} sx={{mt: 3, mb: 1, background: ''}}>

        <Grid item lg={12} sx={{mb: 0, pb: 0}}>


        </Grid>

        {/* <Grid item lg={9} sx={{mt: 1}}>
                <Paper sx={{mt: 0,p: 4, background: 'white', boxShadow: 'none'}}> 
                    <Stack direction="row" alignItems="center" sx={{mb: 3}}>
                        <Typography variant="h5" sx={{fontWeight: 900}}>Details</Typography>
                        <Typography variant="" sx={{fontSize: '1rem', fontWeight: 700, px: 1, py: 0,ml: 1,color: 'white', background: blue[200], borderRadius: '5px'}}>Info</Typography>

                    </Stack>
                    <Form className="">
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="border border-1 border-light rounded p-1">
                                <Form.Label className="">Applicant ID</Form.Label>
                                <Form.Control disabled type="email" value={values.id} placeholder="E.g 123qst1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="border border-1 border-light rounded p-1">
                                <Form.Label>Applicant Name</Form.Label>
                                <Form.Control  value={values.name} disabled={isEditing? false : true} type="email" placeholder="Enter applicant's name" />
                            </Form.Group>
                            
                        </Row>
                        <Row className="mt-1">
                        
                            <Form.Group as={Col} controlId="formGridEmail" className="border border-1 border-light rounded p-1">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" value={values.fname} placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="border border-2 border-light rounded p-1">
                                <Form.Label>Surname</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" value={values.lname} placeholder="Enter email" />
                            </Form.Group>
                        </Row>

                        <Row className="mt-1">
                        
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Home Phone</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                        </Row>
                    </Form>
                </Paper>

                <Paper sx={{mt: 1,p: 4, background: 'white', boxShadow: 'none'}}> 
                <Typography variant="h5" sx={{fontWeight: 900, mb: 3}}>Job Specifics</Typography>
                    <Form>
                    
                        <Row className="mt-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Division</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Job</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>

                        </Row>
                        <Row className="mt-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Applicant Rating</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Applicant Prospect</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                        </Row>
                    </Form>
                </Paper>

                <Paper sx={{mt: 1,p: 4, background: 'white', boxShadow: 'none'}}> 
                <Typography variant="h5" sx={{fontWeight: 900, mb: 3}}>Applicant Specifics</Typography>
                    <Form>
                    
                        <Row className="mt-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Applicant Status</Form.Label>
                                <Form.Control disabled type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Worker</Form.Label>
                                <Form.Control disabled type="email" placeholder="Enter email" />
                            </Form.Group>

                        </Row>
                    
                    </Form>
                </Paper>

        </Grid>
        <Grid item lg={3} sx={{mt: 1}}>
            <Paper sx={{mt: 0,py: 2, background: 'white', boxShadow: 'none', textAlign: 'center'}}>
                <Typography variant="h6" sx={{fontWeight: 700, py: 1, px: 0,color: blueGrey[700], background: 'blueGrey[200]'}}>Applicant History</Typography>
                <Divider/>
                <br/>
                <Typography variant="p" sx={{fontSize: '.8rem',my: 3, fontWeight: 700, py: 1, px: 1,color: blueGrey[50], background: blueGrey[500], borderRadius: '5px'}}>No Previous History</Typography>

            </Paper>

            <Paper sx={{mt: 2,py: 1, background: 'white', boxShadow: 'none', textAlign: 'center'}}>
                <Typography variant="h6" sx={{fontWeight: 700, py: 1, px: 0,color: blueGrey[900], background: 'blueGrey[200]'}}> Other I.T Vacancies</Typography>
                <Divider/>
                <br/>
                <List sx={{ textAlign: 'left', p: 2}}>
                    <ListItem>
                        <ListItemIcon>
                                <InfoIcon/>
                        </ListItemIcon>
                        <ListItemText primary="React Developer" sx={{m: 0, p: 0}}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                                <InfoIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Javascript Developer" sx={{m: 0, p: 0}}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                                <InfoIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Senior React Frontend Developer" sx={{m: 0, p: 0}}/>
                    </ListItem>
                </List>

            </Paper>
        </Grid>
 */}

        </Grid>
        </>
    )
}

export default ViewStaff

{/* <Grid item sx={{pr: 4, borderRight: '1px solid #eceff1'}}>
<Stack sx={{ml: 2}}>
    <Typography variant="h1" sx={{fontSize: '1.7rem',fontWeight: 800, py: '', px: 0,color: 'blueGrey[800]', background: 'blueGrey[200]'}}>{values ? values.name : 'Employee Name'}</Typography>
        <Divider orientation="vertical" variant="middle" />
    <Stack direction="row" justifyContent="space-between" sx={{mt: '7px'}}>
            <Box sx={{px:0, py: 0, display: 'flex', justifyContent: "space-around", alignItems: "center", background: '', borderRadius: '10px'}}>
                <Typography variant="" sx={{fontSize: '', fontWeight: 500, py: 0, pl: 0,color: blueGrey[700], background: 'blueGrey[200]'}}>{headerFor == 'worker-details' ? 'Department:' : 'Tag:'}</Typography>
                <Typography variant="" sx={{fontSize: '.7rem', fontWeight: 500, px: 1, py: 0,ml: 1,color: blueGrey[50], background: 'dodgerblue', borderRadius: '10px'}}>{headerFor == 'worker-details' ? 'Employee Department' : 'Immediate Hire'}</Typography>
            </Box>
    </Stack>

    
</Stack>
</Grid> */}