import { DOCUMENTTYPES_LIST_REQUEST, DOCUMENTTYPES_LIST_SUCCESS, DOCUMENTTYPES_LIST_FAIL, VIEW_DOCUMENTTYPE_FAIL, VIEW_DOCUMENTTYPE_REQUEST, VIEW_DOCUMENTTYPE_SUCCESS, DOCUMENTTYPE_CREATE_REQUEST, DOCUMENTTYPE_CREATE_SUCCESS, DOCUMENTTYPE_CREATE_FAIL, DOCUMENTTYPE_UPDATE_FAIL, DOCUMENTTYPE_UPDATE_REQUEST, DOCUMENTTYPE_UPDATE_SUCCESS } from '../Constants/documentTypeConstants';
import api from '../../config/apiConfig';


const getDocumentTypesList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: DOCUMENTTYPES_LIST_REQUEST});
        const {data} = await api.get('/documenttypes');
        // console.log('DocumentTypes data is:' + data[0]['DocumentType_Name'])
        dispatch({type: DOCUMENTTYPES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: DOCUMENTTYPES_LIST_FAIL, payload: error})
    }
} 

const submitDocumentType = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: DOCUMENTTYPE_CREATE_REQUEST});
        
        const {data} = await api.post(`/documenttypes/`, values);
        console.log('DocumentType data is:' + data)
        dispatch({type: DOCUMENTTYPE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: DOCUMENTTYPE_CREATE_FAIL, payload: error})
    }
} 

const getDocumentType = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_DOCUMENTTYPE_REQUEST});
        
        const {data} = await api.get(`/documenttypes/ ${id}`);
        console.log('DocumentType data is:' + data)
        dispatch({type: VIEW_DOCUMENTTYPE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_DOCUMENTTYPE_FAIL, payload: error})
    }
}

const editDocumentType = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: DOCUMENTTYPE_UPDATE_REQUEST});
        
        const {data} = await api.put(`/documenttypes/`, values);
        console.log('DocumentType data is:' + data)
        dispatch({type: DOCUMENTTYPE_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: DOCUMENTTYPE_UPDATE_FAIL, payload: error})
    }
}



export {getDocumentTypesList, submitDocumentType, getDocumentType, editDocumentType }