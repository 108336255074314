import React from 'react'
import Column from '../../Screens/CRM/Tasks/NewKanbanComponents/Column';

const KanbanView = ({columns}) => {
    return (
        <>
            <div className="newkanban">
                {columns.map(column => <Column key={column.id} {...column} />)}
            </div>
        </>
    )
}

export default KanbanView
