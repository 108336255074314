import { PROSPECTS_LIST_REQUEST, PROSPECTS_LIST_SUCCESS, PROSPECTS_LIST_FAIL, VIEW_PROSPECT_FAIL, VIEW_PROSPECT_REQUEST, VIEW_PROSPECT_SUCCESS, PROSPECT_CREATE_REQUEST, PROSPECT_CREATE_SUCCESS, PROSPECT_CREATE_FAIL, PROSPECT_UPDATE_FAIL, PROSPECT_UPDATE_REQUEST, PROSPECT_UPDATE_SUCCESS } from '../Constants/prospectConstants';
import api from '../../config/apiConfig';


const getProspectsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: PROSPECTS_LIST_REQUEST});
        const {data} = await api.get('/prospects');
        //console.log('Prospects data is:' + data[0].Name)
        dispatch({type: PROSPECTS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: PROSPECTS_LIST_FAIL, payload: error})
    }
} 

const submitProspect = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: PROSPECT_CREATE_REQUEST});
        
        const {data} = await api.post(`/prospects/`, postValues);
        console.log('Prospect data is:' + data)
        dispatch({type: PROSPECT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: PROSPECT_CREATE_FAIL, payload: error})
    }
} 

const getProspect = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_PROSPECT_REQUEST});
        
        const {data} = await api.get(`/prospects/ ${id}`);
        console.log('Prospect data is:' + data)
        dispatch({type: VIEW_PROSPECT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_PROSPECT_FAIL, payload: error})
    }
}

const editProspect = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: PROSPECT_UPDATE_REQUEST});
        
        const {data} = await api.put(`/prospects/`, values);
        console.log('Prospect data is:' + data)
        dispatch({type: PROSPECT_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: PROSPECT_UPDATE_FAIL, payload: error})
    }
}



export {getProspectsList, submitProspect, getProspect, editProspect }