import { ASSESSMENTSTATUS_LIST_REQUEST, ASSESSMENTSTATUS_LIST_FAIL, ASSESSMENTSTATUS_LIST_SUCCESS} from '../Constants/assessmentStatusesConstants';
import api from '../../config/apiConfig';


const getAssessmentStatusesList = () => async(dispatch) => {
    //const data = assessmenttypes;
 
    try {
        dispatch({type: ASSESSMENTSTATUS_LIST_REQUEST});
        const {data} = await api.get('/assessmentstatuses');
        //console.log('AssessmentStatuses data is:' + data[0]['AssessmentStatuses_Name'])
        dispatch({type: ASSESSMENTSTATUS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: ASSESSMENTSTATUS_LIST_FAIL, payload: error})
    }
} 

export {getAssessmentStatusesList }