import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import { blueGrey, cyan, blue, purple, deepPurple, teal, orange, deepOrange, green } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getWorkers } from '../../../../../Redux/Actions/WorkerActions';
import { getAvailabilityStatusesList } from '../../../../../Redux/Actions/AvailabilityStatusActions';
import { submitAvailability } from '../../../../../Redux/Actions/AvailabilityActions';
import api from '../../../../../config/apiConfig';


const AddTimesheetDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch(); 

    //GET ASSESSMENTS
    const statuses_ListState = useSelector(state => state.availabilityStatuses_List);
    const {statuses, loading: loading_statuses} = statuses_ListState;

    //GET AVAILABILITIES
    const availabilities_ListState = useSelector(state => state.availability_List);
    const {availabilities, loading: loading_availabilities} = availabilities_ListState;

    //GET WORKERS
    const workers_ListState = useSelector(state => state.workers_List);
    const {workers, loading} = workers_ListState;
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getAvailabilityStatusesList());
        dispatch(getWorkers());
       
    }, [])
    

    const [values, setValues] = useState({
            
            workers: '',
            monday: '' , 
            tuesday: '' , 
            wednesday: '' , 
            thursday: '' , 
            friday: '' , 
            saturday: '' , 
            sunday: '' , 
            availabilitystatuses: '',
        });

    const [submitValues, setSubmitValues] = useState({
            
            workers: '',
            monday: '' , 
            tuesday: '' , 
            wednesday: '' , 
            thursday: '' , 
            friday: '' , 
            saturday: '' , 
            sunday: '' , 
            availabilitystatuses: '', 
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
          
        }
        else if(name == 'type' || name == 'status' || name == 'result')
        {

            setValues({
                ...values,
                [name]: value
            });
           
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
 
        }
    }

    const handleSave = () => {
        console.log('in save function')
        dispatch(submitAvailability({...submitValues, date}));
        handleSubmit();

    }

    const animatedComponents = makeAnimated();


    const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {

        
        const keeper = [];

        const tableName = name == 'workers' ? workers : name == 'availabilitystatuses' ? statuses : '';

        const id_field = name === 'availabilitystatuses' ? 'Status_Id' : name === 'workers'? 'Worker_Id' : name === 'clients'? 'Client_Id': name === 'shiftstatuses'? 'Id' : name === 'jobroles'? 'Role_Id': name === 'ratecards'? 'Id' : name === 'contacts'? 'Id' : '';
        const name_field = name === 'availabilitystatuses' ? 'Status_Name' : name === 'workers'? 'First_Name' : name === 'clients'? 'Name': name === 'shiftstatuses'? 'Name' : name === 'jobroles'? 'Name' : name === 'ratecards'? 'Name' : name === 'contacts'? 'First_Name' :'';
            
        tableName.forEach(x => {
            if(name === 'workers' || name === 'contacts')
            {
                keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
            }
            else{
                keeper.push({value: x[id_field], label: x[name_field]})

            }
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['divisions', 'staff', 'workers', 'clients', 'shiftstatuses', 'jobroles', 'ratecards', 'contacts', 'availabilitystatuses'];
        
        const id_field = name === 'availabilitystatuses' ? 'Status_Id' : name === 'workers'? 'Worker_Id' : name === 'clients'? 'Client_Id': name === 'shiftstatuses'? 'Id' : name === 'jobroles'? 'Role_Id': name === 'ratecards'? 'Id' : name === 'contacts'? 'Id' : '';
        const name_field = name === 'availabilitystatuses' ? 'Status_Name' : name === 'workers'? 'First_Name' : name === 'clients'? 'Name': name === 'shiftstatuses'? 'Name' : name === 'jobroles'? 'Name' : name === 'ratecards'? 'Name' : name === 'contacts'? 'First_Name' :'';
        
        names.forEach(x => x === name)
        {
            
            data.forEach(x => {
                if(name === 'workers' || name === 'contacts' )
                {
                    keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
                }
                else{

                    keeper.push({value: x[id_field], label: x[name_field]})
                }
                })
               
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action) => {
        
        const name = action.name;

        if(name === 'jobroles')
        {
            const container = [];
            value.forEach(x => { container.push(x.value) })
            setValues({
                ...values,
                jobroles: [...value]
            })
            setSubmitValues({
                ...submitValues,
                jobroles: container
            })
        }
        else{
            setValues({
                ...values,
                [name]: value
            })
            setSubmitValues({
                ...submitValues,
                [name]: value.value
            })

        }
    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
 
    
    const [date, setDate] = useState(`${1}/${1}/${2022}`);
    const [displayDates, setDisplayDates] = useState({
        monday: ''
    })

    const handleDateChange = (newValue) => {
        const dayOfWeek = newValue.getDay();
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate(`${day}/${month+1}/${year}`);
        console.log('DATE SELECTED, DAY OF WEEK IS:' + dayOfWeek)

        if(dayOfWeek > 2)
        {
            setDisplayDates({
                monday: `${day}/${month+1}/${year}`,
                tuesday: `${day}/${month+1}/${year}`,
                wednesday: `${day}/${month+1}/${year}`,
                thursday: `${day}/${month+1}/${year}`,
                friday: `${day}/${month+1}/${year}`,
                saturday: `${day}/${month+1}/${year}`,
                sunday: `${day}/${month+1}/${year}`,
            })
        }
        //setDate()
        //new Date().get
    }
    
    const [summary, setSummary] = useState(false)

    const handleSummary = () => {
        setSummary(!summary);
    }
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: deepPurple[900]}}  >
                
                <CheckCircleIcon fontSize="large" sx={{color: deepPurple[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: deepPurple[200],fontWeight: 700}}> Add Availability </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
        
    
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <Grid item lg={12} sx={{position: 'sticky', zIndex: 10}}>
                        <Grid item lg={12} sx={{m: 0, p: 0, background: ''}}>
                            <Stack direction="row" alignItems="center" sx={{ background: 'blue[50]', mb: 0}}>
                                <Typography variant="body1" sx={{color: deepPurple[800],fontWeight: 900, textAlign: 'center'}}>Form Summary</Typography>
                                <Button variant="text" size="small" sx={{fontSize: 12, color: deepPurple[900], textAlign: 'center'}} onClick={handleSummary}>{summary? 'Close' : 'Open'}</Button>
                            </Stack>
                        </Grid>
                                
                        <Grid container flexDirection="row" justifyContent="space-between" className={`summary ${summary? 'open' : ''}`} sx={{px: 3, pt: '2px', background: '#f2f7fc', border: '2px solid whitesmoke', borderRadius: 3, boxShadow: 'none', overflowY: 'scroll'}}>
                                {/* <Divider sx={{border: '.5px solid gray'}}/> */}
                                <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability ID: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`AVL - ${!loading? 2 + 1 : ''}`} </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.assessment_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                    <Typography variant="body2" sx={{color: values.subject? '' : blue[500], fontWeight: values.subject? 800 : 500, ml: 5}}>{values.subject? values.subject : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                    <Typography variant="body2" sx={{color: values.applicant? '' : blue[500], fontWeight: values.applicant? 800 : 500, ml: 5}}>{values.applicant? values.applicant : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Name </Typography>
                                    <Typography variant="body2" sx={{color: applicantName? '' : blue[500], fontWeight: applicantName? 800 : 500, ml: 5}}>{applicantName? applicantName : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff Name </Typography>
                                    <Typography variant="body2" sx={{color: staffName? '' : blue[500], fontWeight: staffName? 800 : 500, ml: 5}}>{staffName? staffName : 'null' }</Typography>
                                </Stack>
                                
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Type: </Typography>
                                    <Typography variant="body2" sx={{color: values.type? '' : blue[500], fontWeight: values.type? 800 : 500, ml: 5}}>{values.type? values.type : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Level: </Typography>
                                    <Typography variant="body2" sx={{color: values.level? '' : blue[500], fontWeight: values.level? 800 : 500, ml: 5}}>{values.level? values.level : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Result: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.result? 'white' : blue[500], fontSize: '.75rem', textAlign: 'left', px: values.result? '4px' : '', borderRadius: '3px' ,fontWeight: values.result? 500 : 500, background: values.result? blue[500] : ''}}>{values.result? values.result : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Status: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.status? 'white' : blue[500], fontSize: values.status? '.75rem' :'', textAlign: 'left', px: values.status? '4px' : '', borderRadius: '3px'  ,fontWeight: values.status? 500 : 500, background: values.status? blue[500] : ''}}>{values.status? values.status : 'null' }</Typography>
                                </Stack>
                                
                            
                            </Grid>
                        </Grid>
                        {/* <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                            <Stack sx={{mb: 3, background: '', height: '300px', borderRadius: '5px', width: '80%', pl: 3, pt: 3}}>
                                <BreadcrumbLink underline="none" sx={{color: blueGrey[300]}} fontSize="small" href="#basic">Basic Info</BreadcrumbLink>
                                <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#nature">Job Nature</BreadcrumbLink>
                                <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#staff">Staff of Shift</BreadcrumbLink>
                                <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#client">Client Information</BreadcrumbLink>
                                <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#notes">Notes</BreadcrumbLink>
                            </Stack>
                        </Grid> */}
                             
                             
                            {/* <p>status: {submitValues.availabilitystatuses}</p>
                            <p>date: {date}</p>
                            <p>mon: {submitValues.monday}</p>
                            <p>tue: {submitValues.tuesday}</p>
                            <p>wed: {submitValues.wednesday}</p>
                            <p>thursday: {submitValues.thursday}</p>
                            <p>fri: {submitValues.friday}</p>
                            <p>sat: {submitValues.saturday}</p>
                            <p>sunday: {submitValues.sunday}</p>
                            <p>workers: {submitValues.workers}</p> */}
                        <Grid item lg={12}>
                            <Form className="my-3">
                            <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Info</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2 mt-2">
                                    <Form.Label className="view-label">Availability ID</Form.Label>
                                    <Form.Control disabled type="email" value="AVL-3" placeholder="E.g 123qst1" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Availability Name</Form.Label>
                                    <Form.Control type="email" value="Autogenerated" disabled placeholder="Enter name ..." className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Related Worker</Form.Label>
                                    <AsyncSelect
                                        value={values.workers}
                                        name="workers"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'workers')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for worker ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Availability Status</Form.Label>
                                    <AsyncSelect
                                        value={values.availabilitystatuses}
                                        name="availabilitystatuses"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'availabilitystatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Week Commencing</Form.Label>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            label=""
                                            value={date}
                                            //maxDate="any"
                                            onChange={handleDateChange}
                                            // onChange={(newValue) => { setDate(`${newValue.getDay()} / ${newValue.getMonth()} / ${newValue.getFullYear()}`)}}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' deepPurple[50]', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Form.Group>
                                
                            </Row>
                        </Form>
                        
                    <Grid container justifyContent="space-between">
                    
                    <Grid item lg={5}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Your dates</Typography>

                 
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded p-2 mt-3">
                                <Form.Label column sm={3} className="bold" style={{color: deepPurple[700]}}>Monday</Form.Label>
                                <Col sm={9}>
                                    <Form.Control type="email" name="Availability Description" value={displayDates.monday?displayDates.monday : ''} onChange={handleChange} placeholder="Date will come here" disabled className="outline-none border-none" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label column sm={3} className="bold" style={{color: deepPurple[700]}}>Tuesday</Form.Label>
                                <Col sm={9}>
                                    <Form.Control type="email" name="Availability Description" value={displayDates.tuesday?displayDates.tuesday : ''} onChange={handleChange} placeholder="Date will come here" disabled className="outline-none border-none" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label column sm={3} className="bold" style={{color: deepPurple[700]}}>Wednesday</Form.Label>
                                <Col sm={9}>
                                    <Form.Control type="email" name="Availability Description" value={displayDates.wednesday?displayDates.wednesday : ''} onChange={handleChange} placeholder="Date will come here" disabled className="outline-none border-none" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label column sm={3} className="bold" style={{color: deepPurple[700]}}>Thursday</Form.Label>
                                <Col sm={9}>
                                    <Form.Control type="email" name="Availability Description" value={displayDates.thursday?displayDates.thursday : ''} onChange={handleChange} placeholder="Date will come here" disabled className="outline-none border-none" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label column sm={3} className="bold" style={{color: deepPurple[700]}}>Friday</Form.Label>
                                <Col sm={9}>
                                    <Form.Control type="email" name="Availability Description" value={displayDates.friday?displayDates.friday : ''} onChange={handleChange} placeholder="Date will come here" disabled className="outline-none border-none" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label column sm={3} className="bold" style={{color: deepPurple[700]}}>Saturday</Form.Label>
                                <Col sm={9}>
                                    <Form.Control type="email" name="Availability Description" value={displayDates.saturday?displayDates.saturday : ''} onChange={handleChange} placeholder="Date will come here" disabled className="outline-none border-none" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label column sm={3} className="bold" style={{color: deepPurple[700]}}>Sunday</Form.Label>
                                <Col sm={9}>
                                    <Form.Control type="email" name="Availability Description" value={displayDates.sunday?displayDates.sunday : ''} onChange={handleChange} placeholder="Date will come here" disabled className="outline-none border-none" />
                                </Col>
                            </Form.Group>
                       
                        
                        </Form>
                    </Grid>

                    <Grid item lg={6}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: deepPurple[700], borderRadius: '5px', px: 2, py: '5px'}}>Confirm Availability</Typography>

                        {/* <h4>Monday: {values.monday}</h4> */}
                 
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded mt-4">
                                <Form.Label column sm={2} className="bold" style={{color: deepPurple[700]}}>Monday</Form.Label>
                                <Col sm={8}>
                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="female"  value={values.monday} name="monday" onChange={handleChange}>
                                        <FormControlLabel value="Available" control={<Radio size="small" />} label="Available" />
                                        <FormControlLabel value="Can't Work" control={<Radio size="small" />} label="Can't Work" />
                                        <FormControlLabel value="Backup" control={<Radio size="small" />} label="Backup" />
                                    </RadioGroup>   
                                </Col>
                                <Col sm={2}>
                                    {values.monday? <DoneIcon fontSize="small" sx={{color: deepPurple[700]}}/> : <Typography component="h6" sx={{width: '100%', fontSize: '.8rem', mt: 1, color: deepPurple[700]}}>confirm day</Typography>}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded mt-3">
                                <Form.Label column sm={2} className="bold" style={{color: deepPurple[700]}}>Tuesday</Form.Label>
                                <Col sm={8}>
                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" value={values.tuesday} name="tuesday" onChange={handleChange}>
                                        <FormControlLabel value="Available" control={<Radio size="small" />} label="Available" />
                                        <FormControlLabel value="Can't Work" control={<Radio size="small" />} label="Can't Work" />
                                        <FormControlLabel value="Backup" control={<Radio size="small" />} label="Backup" />
                                    </RadioGroup>   
                                </Col>
                                <Col sm={2}>
                                    {values.tuesday? <DoneIcon fontSize="small" sx={{color: deepPurple[700]}}/> : <Typography component="h6" sx={{width: '100%', fontSize: '.8rem', mt: 1, color: deepPurple[700]}}>confirm day</Typography>}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded mt-3">
                                <Form.Label column sm={2} className="bold" style={{color: deepPurple[700]}}>Wednesday</Form.Label>
                                <Col sm={8}>
                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" value={values.wednesday} name="wednesday" onChange={handleChange}>
                                        <FormControlLabel value="Available" control={<Radio size="small" />} label="Available" />
                                        <FormControlLabel value="Can't Work" control={<Radio size="small" />} label="Can't Work" />
                                        <FormControlLabel value="Backup" control={<Radio size="small" />} label="Backup" />
                                    </RadioGroup>   
                                </Col>
                                 <Col sm={2}>
                                    {values.wednesday? <DoneIcon fontSize="small" sx={{color: deepPurple[700]}}/> : <Typography component="h6" sx={{width: '100%', fontSize: '.8rem', mt: 1, color: deepPurple[700]}}>confirm day</Typography>}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded mt-3">
                                <Form.Label column sm={2} className="bold" style={{color: deepPurple[700]}}>Thursday</Form.Label>
                                <Col sm={8}>
                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" value={values.thursday} name="thursday" onChange={handleChange}>
                                        <FormControlLabel value="Available" control={<Radio size="small" />} label="Available" />
                                        <FormControlLabel value="Can't Work" control={<Radio size="small" />} label="Can't Work" />
                                        <FormControlLabel value="Backup" control={<Radio size="small" />} label="Backup" />
                                    </RadioGroup>   
                                </Col>
                                <Col sm={2}>
                                    {values.thursday? <DoneIcon fontSize="small" sx={{color: deepPurple[700]}}/> : <Typography component="h6" sx={{width: '100%', fontSize: '.8rem', mt: 1, color: deepPurple[700]}}>confirm day</Typography>}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded mt-3">
                                <Form.Label column sm={2} className="bold" style={{color: deepPurple[700]}}>Friday</Form.Label>
                                <Col sm={8}>
                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" value={values.friday} name="friday" onChange={handleChange}>
                                        <FormControlLabel value="Available" control={<Radio size="small" />} label="Available" />
                                        <FormControlLabel value="Can't Work" control={<Radio size="small" />} label="Can't Work" />
                                        <FormControlLabel value="Backup" control={<Radio size="small" />} label="Backup" />
                                    </RadioGroup>   
                                </Col>
                                <Col sm={2}>
                                    {values.friday? <DoneIcon fontSize="small" sx={{color: deepPurple[700]}}/> : <Typography component="h6" sx={{width: '100%', fontSize: '.8rem', mt: 1, color: deepPurple[700]}}>confirm day</Typography>}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded mt-3">
                                <Form.Label column sm={2} className="bold" style={{color: deepPurple[700]}}>Saturday</Form.Label>
                                <Col sm={8}>
                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" value={values.saturday} name="saturday" onChange={handleChange}>
                                        <FormControlLabel value="Available" control={<Radio size="small" />} label="Available" />
                                        <FormControlLabel value="Can't Work" control={<Radio size="small" />} label="Can't Work" />
                                        <FormControlLabel value="Backup" control={<Radio size="small" />} label="Backup" />
                                    </RadioGroup>   
                                </Col>
                                <Col sm={2}>
                                    {values.saturday? <DoneIcon fontSize="small" sx={{color: deepPurple[700]}}/> : <Typography component="h6" sx={{width: '100%', fontSize: '.8rem', mt: 1, color: deepPurple[700]}}>confirm day</Typography>}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridEmail" className="rounded mt-3">
                                <Form.Label column sm={2} className="bold" style={{color: deepPurple[700]}}>Sunday</Form.Label>
                                <Col sm={8}>
                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" value={values.sunday} name="sunday" onChange={handleChange}>
                                        <FormControlLabel value="Available" control={<Radio size="small" />} label="Available" />
                                        <FormControlLabel value="Can't Work" control={<Radio size="small" />} label="Can't Work" />
                                        <FormControlLabel value="Backup" control={<Radio size="small" />} label="Backup" />
                                    </RadioGroup>   
                                </Col>
                                <Col sm={2}>
                                    {values.sunday? <DoneIcon fontSize="small" sx={{color: deepPurple[700]}}/> : <Typography component="h6" sx={{width: '100%', fontSize: '.8rem', mt: 1, color: deepPurple[700]}}>confirm day</Typography>}
                                </Col>
                            </Form.Group>

             

                        

                        
                      

                       
                       

                       
                        
                        
                        </Form>
                    </Grid>
                   
                    </Grid>

                    <br/>
                        <Form className="my-3">
                             <Typography variant="body2" sx={{display: 'inline',color: 'white',background: deepPurple[700], borderRadius: '5px', px: 2, py: '5px'}}>Days Available To Work</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2 mt-2">
                                    <Form.Label className="view-label">Days</Form.Label>
                                    <Form.Control disabled type="email" value="days will come here" placeholder="E.g 123qst1" className="outline-none border-none" />
                                </Form.Group>
                                
                            </Row>
                        </Form>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: deepPurple[800], '&:hover': {color: deepPurple[900]}}} onClick={handleSave}>Save Availability</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddTimesheetDialog
