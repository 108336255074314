import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControlLabel, Checkbox} from '@mui/material';
import { blueGrey, blue, purple, deepPurple, red } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import TaskIcon from '@mui/icons-material/Task';
import { useDispatch, useSelector } from 'react-redux';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getClientsList } from '../../../../../Redux/Actions/ClientsActions';
import { getTaskStatusesList } from '../../../../../Redux/Actions/TaskStatusActions';
import { getWorkers } from '../../../../../Redux/Actions/WorkerActions';
import { getTaskTypesList } from '../../../../../Redux/Actions/TaskTypeActions';
import { submitTask } from '../../../../../Redux/Actions/TasksActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SideCrumbs from '../../../../Utilities/SideCrumbs';
import ComplexAlert from '../../../../ComplexAlert';
import useAlert from '../../../../../Hooks/useAlert';
import SummaryBox from '../../../../Utilities/SummaryBox';
import { submitCOA } from '../../../../../Redux/Actions/COAActions';



const AddCOADialog = ({open, onClose, type, info, handleSubmit}) => {

    //ACCESS DISPATCH
    const dispatch = useDispatch();
    //GET TASKS
    const {tasks, loading: loading_tasks} = useSelector(state => state.tasks_List);
    const {divisions, loading} = useSelector(state => state.divisions_List);
    const {alert, showAlert}= useAlert();

   
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getStaffList());
        dispatch(getClientsList());
        dispatch(getTaskStatusesList());
        dispatch(getTaskTypesList());
        dispatch(getWorkers());
       
    }, [])
    
    

    const [values, setValues] = useState({
            code: '', 
            name: '', 
            description: '', 
            pl_group: '', 
            balancesheetType: '' , 
            vatRate: '' , 
            incomeType: '' , 
            expenseType: '', 
            assetType: '', 
            liabilityType: '', 
            equityType: '', 
            currentBalance: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            code: '', 
            name: '', 
            description: '', 
            pl_group: '', 
            balancesheetType: '' , 
            vatRate: '' , 
            incomeType: '' , 
            expenseType: '', 
            assetType: '', 
            liabilityType: '', 
            equityType: '', 
            currentBalance: '',  
        });
    
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitCOA(values, submitValues))
        handleSubmit('success',"Successfully saved COA information");

    }

    
    
    const [inputValue, setInputValue] = useState('');
    
    const handleInputChange = (newValue) => {
        
        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
 
  
   


    const animatedComponents = makeAnimated();


    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between',alignItems: 'center',fontSize: '1.3rem', background: blue[800], fontWeight: 900, color: 'white'}}  > 
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    <TaskIcon fontSize="large" sx={{color: blue[500], mr: 2, fontSize: '1.5rem'}}/> 
                    <Typography variant="h6" sx={{color: blue[500],fontWeight: 700, fontFamily: 'Poppins'}}> Add COA </Typography>
                </Stack>
            </DialogTitle>

        <Divider sx={{color: blue[500]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    {/* <p>name {submitValues.name}</p>
                    <p>group {submitValues.group}</p>
                    <p>worker {submitValues.worker}</p>
                    <p>staff {submitValues.staff}</p>
                    <p>startdate{submitValues.start}</p>
                    <p>endate {submitValues.end}</p>
                    <p>days_taken{submitValues.days_taken}</p>
                    <p>days_left {submitValues.days_left}</p>
                    <p>approved {submitValues.approved_by}</p>
                    <p>status {submitValues.status}</p> */}
                    <SideCrumbs/>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Account Code</Form.Label>
                                <Form.Control type="text" name="code" value={values.code} onChange={handleChange} className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Account Name</Form.Label>
                                <Form.Control type="text" name="name" value={values.name} onChange={handleChange} className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                    
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Description</Form.Label>
                                <Form.Control type="text" as="textarea" name="description" value={values.description} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>


                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: deepPurple[500], borderRadius: '5px', px: 2, py: '5px'}}>Staff On Task</Typography>
                        <Divider sx={{my: 2}}/>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label w-100">Profit & Loss Group</Form.Label>
                                <FormControlLabel control={<Checkbox name="pl_group" value="Income" checked={values.pl_group === 'Income'? true : null} onChange={handleChange} />} label="Income" />
                                <FormControlLabel control={<Checkbox name="pl_group" value="Expense" checked={values.pl_group === 'Expense'? true : null} onChange={handleChange} />} label="Expense" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Balance Sheet Type</Form.Label>
                                    <AsyncSelect
                                        value={values.balancesheetType}
                                        name="balancesheetType"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'balancesheettypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">VAT Rates</Form.Label>
                                    <AsyncSelect
                                        value={values.vatRate}
                                        name="vatRate"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'vatrates')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for rate ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>
                        <br/>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Income Type</Form.Label>
                                    <AsyncSelect
                                        value={values.incomeType}
                                        name="incomeType"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'incometypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Expense Type</Form.Label>
                                    <AsyncSelect
                                        value={values.expenseType}
                                        name="expenseType"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'expensetypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Asset Type</Form.Label>
                                    <AsyncSelect
                                        value={values.assetType}
                                        name="assetType"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'assettypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Client Information</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Liability Type</Form.Label>
                                    <AsyncSelect
                                        value={values.liabilityType}
                                        name="liabilityType"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'liabilitytypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label w-100">Equity Type</Form.Label>
                                <FormControlLabel control={<Checkbox name="equityType" value="Share Capital" checked={values.equityType === 'Share Capital'? true : null} onChange={handleChange} />} label="Share Capital" />
                                <FormControlLabel control={<Checkbox name="equityType" value="Retained Earnings" checked={values.equityType === 'Retained Earnings'? true : null} onChange={handleChange} />} label="Retained Earnings" />
                            </Form.Group>
                        </Row>

                       
                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Task Items</Typography>
                    
                        <Row>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="view-label">Current Balance</Form.Label>
                            <Form.Control name="currentBalance" value={values.currentBalance} onChange={handleChange} type="text" placeholder="" className="outline-none border-none" />
                        </Form.Group>
                        
                        
                    </Row>
                          
                        </Form>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[700], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save COA</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddCOADialog
