import { BALANCESHEETS_LIST_REQUEST, BALANCESHEETS_LIST_SUCCESS, BALANCESHEETS_LIST_FAIL, VIEW_BALANCESHEET_FAIL, VIEW_BALANCESHEET_REQUEST, VIEW_BALANCESHEET_SUCCESS, BALANCESHEET_CREATE_REQUEST, BALANCESHEET_CREATE_SUCCESS, BALANCESHEET_CREATE_FAIL, BALANCESHEET_UPDATE_FAIL, BALANCESHEET_UPDATE_REQUEST, BALANCESHEET_UPDATE_SUCCESS } from '../Constants/balanceSheetConstants';
import api from '../../config/apiConfig';


const getBalanceSheetsList = () => async(dispatch) => {
    
  
    try {
        dispatch({type: BALANCESHEETS_LIST_REQUEST});
        const {data} = await api.get('/balancesheets');
        console.log('BalanceSheets data is:' + data[0].Name)
        dispatch({type: BALANCESHEETS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: BALANCESHEETS_LIST_FAIL, payload: error})
    }
} 

const submitBalanceSheet = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: BALANCESHEET_CREATE_REQUEST});
        
        const {data} = await api.post(`/balancesheets/`, postValues);
        console.log('BalanceSheet data is:' + data)
        dispatch({type: BALANCESHEET_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: BALANCESHEET_CREATE_FAIL, payload: error})
    }
} 

const getBalanceSheet = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_BALANCESHEET_REQUEST});
        
        const {data} = await api.get(`/balancesheets/ ${id}`);
        console.log('BalanceSheet data is:' + data)
        dispatch({type: VIEW_BALANCESHEET_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_BALANCESHEET_FAIL, payload: error})
    }
}

const editBalanceSheet = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: BALANCESHEET_UPDATE_REQUEST});
        
        const {data} = await api.put(`/balancesheets/`, values);
        console.log('BalanceSheet data is:' + data)
        dispatch({type: BALANCESHEET_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: BALANCESHEET_UPDATE_FAIL, payload: error})
    }
}



export {getBalanceSheetsList, submitBalanceSheet, getBalanceSheet, editBalanceSheet }