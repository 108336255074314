import { CREDITCHECKSTATUSES_LIST_REQUEST, CREDITCHECKSTATUSES_LIST_SUCCESS, CREDITCHECKSTATUSES_LIST_FAIL, VIEW_CREDITCHECKSTATUS_FAIL, VIEW_CREDITCHECKSTATUS_REQUEST, VIEW_CREDITCHECKSTATUS_SUCCESS, CREDITCHECKSTATUS_CREATE_FAIL, CREDITCHECKSTATUS_UPDATE_REQUEST, CREDITCHECKSTATUS_UPDATE_FAIL, CREDITCHECKSTATUS_DELETE_REQUEST, CREDITCHECKSTATUS_DELETE_FAIL, CREDITCHECKSTATUS_CREATE_REQUEST, CREDITCHECKSTATUS_CREATE_SUCCESS, CREDITCHECKSTATUS_UPDATE_SUCCESS, CREDITCHECKSTATUS_DELETE_SUCCESS } from "../Constants/creditCheckStatusConstants";

const creditCheckStatusesListReducer = (state = {creditCheckStatuses: []}, action) => {

    switch (action.type)
    {
        case CREDITCHECKSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case CREDITCHECKSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and creditCheckStatuss data is:' + action.payload)
            return {
                loading: false,
                creditCheckStatuses: action.payload
            }
        case CREDITCHECKSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const creditCheckStatusCreateReducer = (state = {creditCheckStatuses: []}, action) => {

    switch (action.type) {

        case CREDITCHECKSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case CREDITCHECKSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                creditCheckStatuses: [...state.creditCheckStatuses, {Applicant_Id: 2, Name: value}]
            };

        case CREDITCHECKSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const creditCheckStatusViewReducer = (state= {creditCheckStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_CREDITCHECKSTATUS_REQUEST:
            return {loading: true};
        case VIEW_CREDITCHECKSTATUS_SUCCESS:
            return {
                    loading: false,
                    creditCheckStatus: action.payload
                }
        case VIEW_CREDITCHECKSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const creditCheckStatusUpdateReducer = (state = {creditCheckStatuss: {}}, action) => {

    switch (action.type) {

        case CREDITCHECKSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case CREDITCHECKSTATUS_UPDATE_SUCCESS:

            return {

            }
        case CREDITCHECKSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const creditCheckStatusDeleteReducer = (state = {creditCheckStatuss: {}}, action) => {

    switch (action.type) {
        case CREDITCHECKSTATUS_DELETE_REQUEST:
            return {
                
            }
        case CREDITCHECKSTATUS_DELETE_SUCCESS:
            return {

            }
        case CREDITCHECKSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {creditCheckStatusesListReducer, creditCheckStatusCreateReducer, creditCheckStatusViewReducer, creditCheckStatusUpdateReducer, creditCheckStatusDeleteReducer }