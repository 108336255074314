import { PAYTYPES_LIST_REQUEST, PAYTYPES_LIST_SUCCESS, PAYTYPES_LIST_FAIL, VIEW_PAYTYPE_FAIL, VIEW_PAYTYPE_REQUEST, VIEW_PAYTYPE_SUCCESS, PAYTYPE_CREATE_FAIL, PAYTYPE_UPDATE_REQUEST, PAYTYPE_UPDATE_FAIL, PAYTYPE_DELETE_REQUEST, PAYTYPE_DELETE_FAIL, PAYTYPE_CREATE_REQUEST, PAYTYPE_CREATE_SUCCESS, PAYTYPE_UPDATE_SUCCESS, PAYTYPE_DELETE_SUCCESS } from "../Constants/payTypeConstants";

const payTypesListReducer = (state = {payTypes: []}, action) => {

    switch (action.type)
    {
        case PAYTYPES_LIST_REQUEST:
            return {loading: true};
 
        case PAYTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and payTypes data is:' + action.payload)
            return {
                loading: false,
                payTypes: action.payload
            }
        case PAYTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const payTypeCreateReducer = (state = {payTypes: []}, action) => {

    switch (action.type) {

        case PAYTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case PAYTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                payTypes: [...state.payTypes, {Applicant_Id: 2, Name: value}]
            };

        case PAYTYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const payTypeViewReducer = (state= {payType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_PAYTYPE_REQUEST:
            return {loading: true};
        case VIEW_PAYTYPE_SUCCESS:
            return {
                    loading: false,
                    payType: action.payload
                }
        case VIEW_PAYTYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const payTypeUpdateReducer = (state = {payTypes: {}}, action) => {

    switch (action.type) {

        case PAYTYPE_UPDATE_REQUEST:
            return {
                
            }
        case PAYTYPE_UPDATE_SUCCESS:

            return {

            }
        case PAYTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const payTypeDeleteReducer = (state = {payTypes: {}}, action) => {

    switch (action.type) {
        case PAYTYPE_DELETE_REQUEST:
            return {
                
            }
        case PAYTYPE_DELETE_SUCCESS:
            return {

            }
        case PAYTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {payTypesListReducer, payTypeCreateReducer, payTypeViewReducer, payTypeUpdateReducer, payTypeDeleteReducer }