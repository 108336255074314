import React, { useEffect, useState } from 'react';
import DataTable from '../../DataTable';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import ContactDialogs from '../../../Dialogs/CRM/Sales/ContactDialogs/ContactDialogs';

const ContactsTbl = ({contacts}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const getTypeColor = (jr) => {
        return jr === 'Prospect' ? blue[500] : jr === 'Client'?  pink[500] : jr === 'Supplier'? orange[500] : jr === 'Next of Kin'? lightGreen[500] : blueGrey[500]
    }
    const getStatusColor = (status) => {
        return status === 'New' ? blue[500] : status === 'Active'?  lightGreen[500] : status === 'Multi'? orange[500] : status === 'Leaver'? lightGreen[500] : blueGrey[500]
    }

    const switchToViewContact = (id) => {
        navigate('/contact/'+ id)
    } 

    const contactQuickView = (id) => {

        showDialog(true, 'view', id)
      }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Name', headerName: 'Name', width: 150, renderCell: (params) => <Typography onClick={()=> {contactQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Name}`}</Typography>},
        { field: 'Type', headerName: 'Type',type: 'singleSelect',  valueOptions: (['Prospect', 'Client', 'Supplier', 'Next of Kin']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getTypeColor(params.row.Type), borderRadius: '30px', px: '10px'}}>{params.row.Type}</Typography>
        )},
        { field: 'isPrimary', headerName: 'isPrimary', width: 150},
        { field: 'Email', headerName: 'Email', width: 150},
        { field: 'Mobile', headerName: 'Mobile', width: 150},
        { field: 'Office_Phone', headerName: 'Office Phone', width: 150},
        { field: 'Department', headerName: 'Department', width: 150},
        { field: 'Role', headerName: 'Role', width: 150},
        { field: 'Client', headerName: 'Client', width: 150},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewContact(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ];



    return (
        <>
        { dialog.open  ?  <ContactDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }

            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={contacts}
                        loading={!contacts.length}
                    />
                </div>
            </div>
        </>
       
    )
}

export default ContactsTbl
