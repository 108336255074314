import React, {useState} from 'react'
import {styled} from '@mui/material/styles'
import PageHeader from '../../Components/PageHeader';
import { Card, Paper, Button, Grid, CardHeader, CardActions, Avatar, Divider, IconButton, Box, AppBar, Toolbar, ToggleButtonGroup, ToggleButton, ClickAwayListener, MenuItem, MenuList, Popper, Grow, ButtonGroup, Slider, Typography, ListItem, List, ListSubheader, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AddIcon from '@mui/icons-material/Add';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { deepOrange, deepPurple } from '@mui/material/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KanbanColumn from '../../Components/KanbanColumn';


const Sketch = () => {
    
    
    const CustomSlider = styled(Slider)(({theme}) => `
        color: yellow !important;
        & .MuiSlider-thumb{
            color: ${theme.palette.error.light};
        }`)

        const [show, setShow] = useState(false)
    
     const handleClick = () => {
         setShow(!show)
     }
 
 
    return (

        <div>
            <Typography variant="h1"> My sketch area</Typography>

            {/* 
                • List item takes in children 
                • State can be managed at parent level or at component level
            */}


            <List 
                component="nav"
                sx={{maxWidth: 250, width: '100%'}}
                subheader={<ListSubheader>Subheader here</ListSubheader>}
            >
                {/* {
                    NavData.map(item => <NavItem item={item}/>)
                } */}
            
                
            </List>
            {/* <Slider
                defaultValue={30}
                // sx={{
                //     width: 500,
                //     color: 'lavender',
                //     '& .MuiSlider-thumb': {
                //         color: 'violet'
                //     }
                // }}
                className="dottie"
                componentsProps={{thumb: {className: 'finger'}}}
            />
            <CustomSlider
                defaultValue={50}
            /> */}
        </div>
    )
}

export default Sketch
