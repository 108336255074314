import React, {useState, useRef, useEffect} from 'react'
import PageHeader from '../../../Components/PageHeader';
import { Typography, Paper, Grid, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Button, Card, ToggleButtonGroup, ToggleButton, ClickAwayListener, MenuItem, MenuList, Popper, Grow, Divider, IconButton, TextField, Alert, List, ListItem, LinearProgress, Box, Tabs, Tab, Stack, ListItemText, ListItemAvatar, Avatar, ListItemIcon, Breadcrumbs } from '@mui/material';
import useForm from '../../../Hooks/useForm';
import { styled } from '@mui/material/styles';
import {useNavigate, matchPath, useParams} from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ComplexAlert from '../../../Components/ComplexAlert';
import { blueGrey, green, blue, deepPurple, orange } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import { getApplicant } from '../../../Redux/Actions/ApplicantsActions';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated'
import Crumbs from '../../../Components/Utilities/Crumbs';
import useCRUD from '../../../Hooks/useCRUD';
import CRUD from '../../../Components/Utilities/CRUD';
import ViewTitleComponent from '../../../Components/Utilities/ViewTitleComponent';
import FormHeading from '../../../Components/Utilities/FormHeading';
import ViewStatus from '../../../Components/Utilities/ViewStatus';
import ApplicantDialogs from '../../../Components/Dialogs/CRM/HR/ApplicantDialogs/ApplicantDialogs';
  
 

//Custom Button extended from MUI Button

const ReturnButton = styled(Button)(({theme})=> ({

     backgroundColor: 'red',
     '&:hover': { 
        backgroundColor: blueGrey[400],
     }
    })
)


const ViewApplicant = () => {

    //GET ID FROM ROUTE
    const {id} = useParams();
    const dispatch = useDispatch();
    const {isEditing, handleCreate, handleEdit, handleSave, handleDelete, setIsEditing, handlePrint, alert, showAlert, dialog, showDialog, handleSubmit, closeDialog } = useCRUD();

    //GET APPLICANT
    const applicant_records = useSelector(state => state.applicant_View);
    const {loading, applicant} = applicant_records;

//----------------------------------------------------------------------------------------------------------------------------------
    
    //ON COMPONENT DID MOUNT
    useEffect(()=> {
        dispatch(getApplicant(id));
    }, [])
   
    // handling form inputs

    const {values, handleChange} = useForm(id);

//----------------------------------------------------------------------------------------------------------------------------------

//DEALING WITH THE TABS
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


//----------------------------------------------------------------------------------------------------------------------------------
const animatedComponents = makeAnimated();

const workers = [
    {value: 'Emily Bone', label: 'Emily Bone'},
    {value: 'John Cusa', label: 'John Cusa'},
    {value: 'Mah Jani', label: 'Mah Jani'},
]
   
  

    return (
        <div> 
            <PageHeader page="Applicant Details" previous applicants task={values.name} />
            <Crumbs root="CRM" middle="applicants" end="Details"/>
            <Grid container justifyContent="space-between" alignItems= "center"  sx={{mt: 2, mb: 2, px:0, borderRadius: '20px', color: 'gray', background: ''}}>
                <ViewStatus/>
                <CRUD isEditing={isEditing} handleCreate={handleCreate} handleEdit={handleEdit} handleDelete={handleDelete} setIsEditing={setIsEditing} showAlert ={showAlert } handlePrint/>
            </Grid>
            {/* <ViewTitle title={loading? '' : applicant.Applicant}/> */}
            
            
            {alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/>: ''}
            {dialog.open? <ApplicantDialogs open={dialog.open} onClose={closeDialog} {...dialog}/>: ''}
            {loading ? <Box sx={{mt: 3,  color: 'orange', width: '100%' }}><LinearProgress sx={{ color: 'orange'}} /></Box> 
            : 
            
            
                
                <Grid container sx={{mt: 3, mb: 1, background: ''}}>

                    <Grid item lg={12} sx={{mt: 1, background: 'white', p: 0}}>
                        <ViewTitleComponent title={applicant.Applicant} picture/>
                        <Grid container justifyContent="flex-start" alignItems="center" sx={{background: blue[50], px: 2, mt: 3, borderRadius: '0'}}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" sx={{p: 0}}>
                                <Tab label="Information"/>
                                <Tab label={`Documents`}  />
                                <Tab label="Other"  />
                            </Tabs>
                        </Grid>

                        <TabPanel value={tabValue} index={0}>
                            <Grid container sx={{background: '', p: 2, mt: 2, borderRadius: '5px 5px 0 0', background: ''}}>
                                <Grid item lg={12}>
                                    <FormHeading title="Basic Details" badge="Info" icon=""/>
                                    <Paper sx={{mt: 2,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                        <Form className=""> 
                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridEmail" >
                                                    <Form.Label className="view-label mb-3">ID</Form.Label>
                                                    <Form.Control disabled type="email" value={`APP-${applicant.Id}`} placeholder="E.g 123qst1" className={`${isEditing? '' : 'border-0  '}`} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail" >
                                                    <Form.Label className="view-label mb-3"> Name</Form.Label>
                                                    <Form.Control  value={loading? '' : applicant.Applicant} disabled={true} type="email" placeholder="Enter applicant's name" className={`${isEditing? '' : 'border-0 '}`} />
                                                </Form.Group>
                                                
                                            </Row>
                                            <Row className="mb-3">
                                            
                                                <Form.Group as={Col} controlId="formGridEmail" >
                                                    <Form.Label className="view-label mb-3">First Name</Form.Label>
                                                    <Form.Control autoFocus={isEditing? true : false} disabled={isEditing? false : true} type="email" value={applicant.First_Name} placeholder="E.g John" className={`${isEditing? '' : 'border-0 '}`} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-1">
                                                    <Form.Label className="view-label mb-3">Surname</Form.Label>
                                                    <Form.Control disabled={isEditing? false : true} type="email" value={applicant.Surname} placeholder="Enter email" className={`${isEditing? '' : 'border-0 '}`} />
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-1">
                                            
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label className="view-label mb-3">Email</Form.Label>
                                                    <Form.Control disabled={isEditing? false : true} type="email" value={applicant.Email} placeholder="Enter email" className={`${isEditing? '' : 'border-0 '}`}  />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label className="view-label mb-3">Mobile</Form.Label>
                                                    <Form.Control disabled={isEditing? false : true} type="email" value={applicant.Mobile} placeholder="Enter email" className={`${isEditing? '' : 'border-0 '}`} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label className="view-label mb-3">Home Phone</Form.Label>
                                                    <Form.Control disabled={isEditing? false : true} type="email" value={applicant.Home_Phone} placeholder="Enter email" className={`${isEditing? '' : 'border-0 '}`} />
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    </Paper>

                                    
                                    <FormHeading title="Job Specifics" badge="" icon="circle"/>
                                    <Paper sx={{mt: 0,p: 4, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                        <Form>
                                        
                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                    <Form.Label className="view-label mb-3">Division</Form.Label>
                                                    <br/>
                                                    {
                                                        isEditing? 
                                                            <Select 
                                                                value={workers[0]}
                                                                options={workers}
                                                                closeMenuOnSelect ={false}
                                                                isMulti
                                                                components={animatedComponents}
                                                                placeholder="Select staff ..."
                                                            />  :
                                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Construction</Typography>
                                                    }
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                    <Form.Label className="view-label mb-3">Job Role</Form.Label>
                                                    <br/>
                                                    {
                                                        isEditing? 
                                                            <Select 
                                                                value={workers[0]}
                                                                options={workers}
                                                                closeMenuOnSelect ={false}
                                                                isMulti
                                                                components={animatedComponents}
                                                                placeholder="Select staff ..."
                                                            />  :
                                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Architect</Typography>
                                                    }
                                                </Form.Group>

                                            </Row>
                                            <Row className="mt-3">
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label className="view-label mb-3">Applicant Rating</Form.Label>
                                                    <Form.Control 
                                                        disabled={isEditing? false : true} 
                                                        type="email" 
                                                        value={applicant.Applicant_Rating} 
                                                        className={`${isEditing? '' : 'border-0 '}`} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                    <Form.Label className="view-label mb-3">Applicant Prospect</Form.Label>
                                                    <br/>
                                                    {
                                                        isEditing? 
                                                            <Select 
                                                                value={workers[0]}
                                                                options={workers}
                                                                closeMenuOnSelect ={false}
                                                                isMulti
                                                                components={animatedComponents}
                                                                placeholder="Select prospect ..."
                                                            />  :
                                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: green[600], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Hot</Typography>
                                                    }
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    </Paper>

                                    
                                    <FormHeading title="Conclusion" badge="" icon="circle"/>
                                    <Paper sx={{mt: 0,p: 4, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                        <Form>
                                        
                                            <Row className="">
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label mb-3">Applicant Status</Form.Label>
                                                <br/>
                                                {
                                                    isEditing? 
                                                        <Select 
                                                            value={workers[0]}
                                                            options={workers}
                                                            closeMenuOnSelect ={false}
                                                            isMulti
                                                            components={animatedComponents}
                                                            placeholder="Select staff ..."
                                                        />  :
                                                <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Billy Baker</Typography>
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label mb-3">Related Assessments</Form.Label>
                                                <br/>
                                                {
                                                    isEditing? 
                                                        <Select 
                                                            value={workers[0]}
                                                            options={workers}
                                                            closeMenuOnSelect ={false}
                                                            isMulti
                                                            components={animatedComponents}
                                                            placeholder="Select staff ..."
                                                        />  :
                                                <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>ASS-1</Typography>
                                                }
                                            </Form.Group>

                                            </Row>
                                        
                                        </Form>
                                    </Paper>

                                    
                                    <FormHeading title="Other" badge="" icon="circle"/>
                                    <Paper sx={{mt: 0,p: 4, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                    <Form>
                                    
                                        <Row className="">
                                        
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="view-label mb-3">Related Profile</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        isMulti
                                                        components={animatedComponents}
                                                        placeholder="Select profile ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>PROF-12</Typography>
                                            }
                                        </Form.Group>

                                        </Row>
                                    
                                    </Form>
                                </Paper>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={tabValue} index={1}>
                            <Grid container sx={{background: '', p: 2, mt: 0, borderRadius: '5px 5px 0 0', background: ''}}>
                                <Grid item lg={12}>
                                <FormHeading title="Documents" badge="" icon="circle"/>
                                    <Paper sx={{mt: 2,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                        <Form className=""> 
                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridEmail" >
                                                    <Form.Label className="view-label ">ID</Form.Label>
                                                    <Form.Control disabled type="email" value={`APP-${applicant.Id}`} placeholder="E.g 123qst1" className={`${isEditing? '' : 'border-0  '}`} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail" >
                                                    <Form.Label className="view-label "> Name</Form.Label>
                                                    <Form.Control  value={loading? '' : applicant.Applicant} disabled={true} type="email" placeholder="Enter applicant's name" className={`${isEditing? '' : 'border-0 '}`} />
                                                </Form.Group>
                                                
                                            </Row>
                                            <Row className="">
                                            
                                                <Form.Group as={Col} controlId="formGridEmail" >
                                                    <Form.Label className="view-label ">First Name</Form.Label>
                                                    <Form.Control autoFocus={isEditing? true : false} disabled={isEditing? false : true} type="email" value={applicant.First_Name} placeholder="E.g John" className={`${isEditing? '' : 'border-0 '}`} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-1">
                                                    <Form.Label className="view-label ">Surname</Form.Label>
                                                    <Form.Control disabled={isEditing? false : true} type="email" value={applicant.Surname} placeholder="Enter email" className={`${isEditing? '' : 'border-0 '}`} />
                                                </Form.Group>
                                            </Row>

                                        </Form>
                                    </Paper>
                                </Grid>
                            </Grid>

                        </TabPanel>
                        
                    </Grid>
                    
                      
                    <Grid item lg={3} sx={{mt: 2, background: '', height: '600px', overflowY: 'hidden', overflow: 'scroll'}} className={`edit-summary ${isEditing? 'slide' : ''}`}>
                        <Stack direction="row" alignItems="center" justifyContent="center">
                            <Typography sx={{color: blue[600], fontSize: '', fontWeight: 700, textAlign: 'center', mt: 1, mr: 1}}>Edit Summary</Typography>
                            <IconButton size="small" sx={{background: 'white'}}>
                                <MoreHorizIcon fontSize="inherit"  />
                            </IconButton>
                        </Stack>
                            <Typography sx={{color: 'gray', fontSize: '.75rem', fontWeight: 500, textAlign: 'center', my: 2}}>No changes made yet.</Typography>
                            <Box sx={{pl: 5}}>
                            <Divider sx={{border: '.5px solid gray'}}/>
                            <Stack direction="column" alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray', fontWeight: 500,}}>Applicant ID: </Typography>
                                <Typography variant="body2" sx={{color: values.id? '' : blue[900], fontWeight: applicant.Applicant_Id? 800 : 500, ml: 0}}>{applicant.Applicant_Id? 'APP-' +applicant.Applicant_Id : 'null' }</Typography>
                            </Stack>
                            <Stack direction="column"  alignItems="flex-start" sx={{mt: 1, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray', fontWeight: 500,}}>Applicant Date: </Typography>
                                <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.application_date}</Typography>
                            </Stack>
                            <Stack direction="column"  alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray', fontWeight: 500,}}>First name: </Typography>
                                <Typography variant="body2" sx={{color: values.first_name? '' : blue[900], fontWeight: applicant.First_Name? 800 : 500, ml: 0}}>{applicant.First_Name? applicant.First_Name : 'null' }</Typography>
                            </Stack>
                            <Stack direction="column"  alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray', fontWeight: 500,}}>Surname: </Typography>
                                <Typography variant="body2" sx={{color: values.surname? '' : blue[900], fontWeight: applicant.Surname? 800 : 500, ml: 0}}>{applicant.Surname? applicant.Surname : 'null' }</Typography>
                            </Stack>
                            <Stack direction="column"  alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray', fontWeight: 500,}}>Email: </Typography>
                                <Typography variant="body2" sx={{color: values.email? '' : blue[900], fontWeight: applicant.Email? 800 : 500, ml: 0}}>{applicant.Email? applicant.Email : 'null' }</Typography>
                            </Stack>
                            <Stack direction="column"  alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray',fontWeight: 500,}}>Mobile: </Typography>
                                <Typography variant="body2" sx={{color: applicant.mobile? '' : blue[900], fontWeight: applicant.Mobile? 800 : 500, ml: 0}}>{applicant.Mobile? applicant.Mobile : 'null' }</Typography>
                            </Stack>
                            <Stack direction="column"  alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray',fontWeight: 500,}}>Home Phone: </Typography>
                                <Typography variant="body2" sx={{color: applicant.home_phone? '' : blue[900], fontWeight: applicant.Home_Phone? 800 : 500, ml: 0}}>{applicant.Home_Phone? applicant.Home_Phone : 'null' }</Typography>
                            </Stack>
                            <Stack direction="column"  alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray',fontWeight: 500,}}>Division: </Typography>
                                <Typography variant="body2" sx={{color: applicant.division? '' : blue[900], fontWeight: applicant.Division_Id? 800 : 500, ml: 0}}>{applicant.Division_Id? applicant.Division_Id : 'null' }</Typography>
                            </Stack>
                            <Stack direction="column"  alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray',fontWeight: 500,}}>Job Role: </Typography>
                                <Typography variant="body2" sx={{color: applicant.role? '' : blue[900], fontWeight: applicant.Job_Role_Id? 800 : 500, ml: 0}}>{applicant.Job_Role_Id? applicant.Job_Role_Id : 'null' }</Typography>
                            </Stack>
                            <Stack direction="column"  alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray',fontWeight: 500,}}>Rating: </Typography>
                                <Typography variant="body2" sx={{color: applicant.rating? '' : blue[900], fontWeight: applicant.Applicant_Rating? 800 : 500, ml: 0}}>{applicant.Applicant_Rating? applicant.Applicant_Rating : 'null' }</Typography>
                            </Stack>
                            <Stack direction="column"  alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray',fontWeight: 500,}}>Prospect: </Typography>
                                <Typography variant="body2" sx={{color: applicant.prospect? '' : blue[900], fontWeight: applicant.Applicant_Prospect_Id? 800 : 500, ml: 0}}>{applicant.Applicant_Prospect_Id? applicant.Applicant_Prospect_Id : 'null' }</Typography>
                            </Stack>
                            <Stack direction="column"  alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray',fontWeight: 500,}}>Status: </Typography>
                                <Typography variant="body2" sx={{color: applicant.status? '' : blue[900], fontWeight: applicant.Applicant_Status_Id? 800 : 500, ml: 0}}>{applicant.Applicant_Status_Id? applicant.Applicant_Status_Id : 'null' }</Typography>
                            </Stack>
                            </Box>
                        
                    </Grid>
                    <Grid item lg={3} sx={{mt: 5}}>
                        <Paper sx={{mt: 4,py: 2, background: 'rgb(242, 247, 252)', boxShadow: 'none', textAlign: 'center'}}>
                            
                            <Stack direction="row" alignItems="center" justifyContent="center"  sx={{my: 3}}>
                                <Typography variant="body1" sx={{fontWeight: 900, mr: 1, textAlign: 'center'}}>Sections</Typography>
                                <Typography variant="body2" sx={{fontSize: '0.7rem', fontWeight: 500, px: 1, py: 0,ml: 1,color: 'white', background: blue[700], borderRadius: '5px'}}>Navigation</Typography>
                            </Stack>
                            <Divider variant="middle" />
                            <br/>
                           
                            <Stack direction="column" alignItems="center">
                                <Button size="small" variant="contained" sx={{background: blueGrey[800], color: blue[50], fontSize: '.7rem', fontWeight: 700, boxShadow: 'none', mb: 1, width: '50%'}}>Details</Button>
                                <Button size="small" variant="contained" sx={{background: blueGrey[50], color: blueGrey[600], fontSize: '.7rem', fontWeight: 700, boxShadow: 'none', mb: 1, width: '50%'}}>Job Specifics</Button>
                                <Button size="small" variant="contained" sx={{background: blueGrey[50], color: blueGrey[600], fontSize: '.7rem', fontWeight: 700, boxShadow: 'none', mb: 1, width: '50%'}}>Applicant Specifics</Button>

                            </Stack>
                        </Paper>

                        
                    </Grid>
                    
                    
                </Grid>
        }

           
           
        </div>
    )
}

export default ViewApplicant
 