import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, Breadcrumbs, TextField} from '@mui/material';
import { blueGrey, pink, lightBlue, lightGreen, blue } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { getDepartmentsList } from '../../../../../Redux/Actions/DepartmentActions';
import { getBranchesList } from '../../../../../Redux/Actions/BranchActions';
import { getRateCardsList } from '../../../../../Redux/Actions/RateCardActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SummaryBox from '../../../../Utilities/SummaryBox';
import SideCrumbs from '../../../../Utilities/SideCrumbs'; 
import useDate from '../../../../../Hooks/useDate';
import { submitSite } from '../../../../../Redux/Actions/SiteActions';


const AddClientSiteDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET BRANCHES
    const {branches, loading: loading_branches} = useSelector(state => state.branches_List);
    //GET DEPARTMENTS
    const {departments, loading} = useSelector(state => state.departments_List);


    
    //ON COMPONENT DID MOUNT
    useEffect(() => { 
        
        dispatch(getDivisions());
        dispatch(getJobRolesList());
        dispatch(getDepartmentsList());
        dispatch(getBranchesList());
        dispatch(getRateCardsList());
       
    }, [])
    

    const [values, setValues] = useState({
            name: '',
            clients: '',
            main_contact: '',
            other_contact: '',
            location: '',
            description: '',
            add1: '',
            add2: '',
            postcode: '',
            town_city: '',
            status: '',
        });

    const [submitValues, setSubmitValues] = useState({
            name: '',
            clients: '',
            main_contact: '',
            other_contact: '',
            location: '',
            description: '',
            add1: '',
            add2: '',
            postcode: '',
            town_city: '',
            status: '',
        });   
        
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitSite(values, submitValues))
        handleSubmit('success',"Successfully saved client site information");
        
    }
    
    const animatedComponents = makeAnimated();
    
    const [inputValue, setInputValue] = useState('');
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
    
    
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }


    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[800]}}  >
                
                <EngineeringIcon fontSize="large" sx={{color: blue[300], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[300],fontWeight: 700}}> Add Client Site </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    
                    <SideCrumbs/>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Client Site ID</Form.Label>
                                <Form.Control disabled type="email" value="CLS-1" placeholder="E.g 123qst1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Client</Form.Label>
                                    <AsyncSelect
                                        value={values.clients}
                                        name="clients"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'clients')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for client ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: blue[400], fontSize: 17, fontFamily: 'Poppins', background: 'blue[700]', borderRadius: '5px', py: '5px',}}>Contacts</Typography>
                        <Divider sx={{color: blue[400], mt: 1, mb: 1}}/>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Main Site Contact</Form.Label>
                                    <AsyncSelect
                                        value={values.main_contact}
                                        name="main_contact"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'contacts')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for contact ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Other Site Contact</Form.Label>
                                    <AsyncSelect
                                        value={values.other_contact}
                                        name="other_contact"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'contacts')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for contact ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: blue[400], fontSize: 17, fontFamily: 'Poppins', background: 'blue[700]', borderRadius: '5px', py: '5px',}}>Location</Typography>
                        <Divider sx={{color: blue[400], mt: 1, mb: 1}}/>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Site Location</Form.Label>
                                <Form.Control type="text" name="location" value={values.location} onChange={handleChange} placeholder="Beitbridge, Zimbabwe" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Address 1</Form.Label>
                                <Form.Control type="textarea" name="add1" value={values.add1} onChange={handleChange} placeholder="196 Cornae Way" className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Address 2</Form.Label>
                                <Form.Control type="textarea" name="add2" value={values.add2} onChange={handleChange} placeholder="Beitbridge, Zimbabwe" className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Postcode</Form.Label>
                                <Form.Control type="textarea" name="postcode" value={values.postcode} onChange={handleChange} placeholder="1XFI000" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Town/City</Form.Label>
                                <Form.Control type="textarea" name="town_city" value={values.town_city} onChange={handleChange} placeholder="Beitbridge" className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: blue[400], fontSize: 17, fontFamily: 'Poppins', background: 'blue[700]', borderRadius: '5px', py: '5px',}}>Description</Typography>
                        <Divider sx={{color: blue[400], mt: 1, mb: 1}}/>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Site Description</Form.Label>
                                <Form.Control as="textarea" name="description" value={values.description} onChange={handleChange} placeholder="Site is new and there are no parking spaces available ..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: blue[400], fontSize: 17, fontFamily: 'Poppins', background: 'blue[700]', borderRadius: '5px', py: '5px',}}>Status</Typography>
                        <Divider sx={{color: blue[400], mt: 1, mb: 1}}/>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Site Status</Form.Label>
                                    <AsyncSelect
                                        value={values.status}
                                        name="status"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'sitestatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                        
                        </Form>
                    </Grid>

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: lightBlue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: lightBlue[800], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Site</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddClientSiteDialog
