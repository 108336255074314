export const VATRETURNS_LIST_REQUEST = 'VATRETURNS_LIST_REQUEST'
export const VATRETURNS_LIST_SUCCESS = 'VATRETURNS_LIST_SUCCESS'
export const VATRETURNS_LIST_FAIL = 'VATRETURNS_LIST_FAIL'


export const VATRETURN_CREATE_REQUEST = 'VATRETURN_CREATE_REQUEST'
export const VATRETURN_CREATE_SUCCESS = 'VATRETURN_CREATE_SUCCESS'
export const VATRETURN_CREATE_FAIL = 'VATRETURN_CREATE_FAIL'


export const VATRETURN_UPDATE_REQUEST = 'VATRETURN_UPDATE_REQUEST'
export const VATRETURN_UPDATE_SUCCESS = 'VATRETURN_UPDATE_SUCCESS'
export const VATRETURN_UPDATE_FAIL = 'VATRETURN_UPDATE_FAIL'

export const VATRETURN_DELETE_REQUEST = 'VATRETURN_DELETE_REQUEST'
export const VATRETURN_DELETE_SUCCESS = 'VATRETURN_DELETE_SUCCESS'
export const VATRETURN_DELETE_FAIL = 'VATRETURN_DELETE_FAIL'

export const VIEW_VATRETURN_REQUEST = 'VIEW_VATRETURN_REQUEST'
export const VIEW_VATRETURN_SUCCESS = 'VIEW_VATRETURN_SUCCESS'
export const VIEW_VATRETURN_FAIL = 'VIEW_VATRETURN_FAIL'