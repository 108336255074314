export const AVAILABILITY_LIST_REQUEST = 'AVAILABILITY_LIST_REQUEST'
export const AVAILABILITY_LIST_SUCCESS = 'AVAILABILITY_LIST_SUCCESS'
export const AVAILABILITY_LIST_FAIL = 'AVAILABILITY_LIST_FAIL'


export const AVAILABILITY_CREATE_REQUEST = 'AVAILABILITY_CREATE_REQUEST'
export const AVAILABILITY_CREATE_SUCCESS = 'AVAILABILITY_CREATE_SUCCESS'
export const AVAILABILITY_CREATE_FAIL = 'AVAILABILITY_CREATE_FAIL'


export const AVAILABILITY_UPDATE_REQUEST = 'AVAILABILITY_UPDATE_REQUEST'
export const AVAILABILITY_UPDATE_SUCCESS = 'AVAILABILITY_UPDATE_SUCCESS'
export const AVAILABILITY_UPDATE_FAIL = 'AVAILABILITY_UPDATE_FAIL'

export const AVAILABILITY_DELETE_REQUEST = 'AVAILABILITY_DELETE_REQUEST'
export const AVAILABILITY_DELETE_SUCCESS = 'AVAILABILITY_DELETE_SUCCESS'
export const AVAILABILITY_DELETE_FAIL = 'AVAILABILITY_DELETE_FAIL'

export const VIEW_AVAILABILITY_REQUEST = 'VIEW_AVAILABILITY_REQUEST'
export const VIEW_AVAILABILITY_SUCCESS = 'VIEW_AVAILABILITY_SUCCESS'
export const VIEW_AVAILABILITY_FAIL = 'VIEW_AVAILABILITY_FAIL'