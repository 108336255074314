import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import { blueGrey, blue} from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { submitClient, getClientsList } from '../../../../../Redux/Actions/ClientsActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SummaryBox from '../../../../Utilities/SummaryBox';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import SideCrumbs from '../../../../Utilities/SideCrumbs';

 
const AddClientDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch(); 

    //GET CLIENTS
    const clients_ListState = useSelector(state => state.clients_List);
    const {clients, loading} = clients_ListState;

    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getClientsList());
       
    }, [])
    

    const [values, setValues] = useState({
            name: '',
            add1: '' , 
            add2: '' , 
            postcode: '' , 
            town_city: '' , 
            company_number: '' , 
            vat_number: '' , 
            setup: '' , 
            industry: '' , 
            status: '' , 
    });
    const [submitValues, setSubmitValues] = useState({
            name: '',
            add1: '' , 
            add2: '' , 
            postcode: '' , 
            town_city: '' , 
            company_number: '' , 
            vat_number: '' , 
            setup: '' , 
            industry: '' , 
            status: '' , 
    });



    const handleSave = () => {
        console.log('in save function')
        dispatch(submitClient(values, submitValues));
        handleSubmit('success','successfully saved client information');

    }

    const animatedComponents = makeAnimated();
    
    const [inputValue, setInputValue] = useState('');
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
    
    
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[800]}}  >
                
                <CheckCircleIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add Client </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
        
    
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                        <SummaryBox values={values}/>
                        <SideCrumbs/>
                            
                        <Grid item lg={10}>
                            <Form className="my-3">
                            <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Info</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2 mt-2">
                                    <Form.Label className="view-label">Client ID</Form.Label>
                                    <Form.Control disabled type="text" value="CLT-1" placeholder="E.g 123qst1" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Client Name</Form.Label>
                                    <Form.Control name="name" type="text" value={values.name} onChange={handleChange} placeholder="HUI Enterprise" className="outline-none border-none" />
                                </Form.Group>
                            </Row>

                            <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: blue[400], fontSize: 17, fontFamily: 'Poppins', background: 'blue[700]', borderRadius: '5px', py: '5px',}}>Address</Typography>
                        <Divider sx={{color: blue[400], mt: 1, mb: 1}}/>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Address 1</Form.Label>
                                    <Form.Control name="add1" type="text" value={values.add1} onChange={handleChange} placeholder="Address 1" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Address 2</Form.Label>
                                    <Form.Control name="add2" type="text" value={values.add2} onChange={handleChange} placeholder="Address 2" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Postcode</Form.Label>
                                    <Form.Control name="postcode" type="text" value={values.postcode} onChange={handleChange} placeholder="KIL0123" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Town/City</Form.Label>
                                    <Form.Control name="town_city" type="text" value={values.town_city} onChange={handleChange} placeholder="Town/City" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Row>
                            <br/>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[400], fontSize: 17, fontFamily: 'Poppins', background: 'blue[700]', borderRadius: '5px', py: '5px',}}>Company Details</Typography>
                            <Divider sx={{color: blue[400], mt: 1, mb: 1}}/>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Company Number</Form.Label>
                                    <Form.Control name="company_number" type="text" value={values.company_number} onChange={handleChange} placeholder="Number" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">VAT Number</Form.Label>
                                    <Form.Control name="vat_number" type="number" value={values.vat_number} onChange={handleChange} placeholder="VAT Number" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: blue[400], fontSize: 17, fontFamily: 'Poppins', background: 'blue[700]', borderRadius: '5px', py: '5px',}}>Other details</Typography>
                        <Divider sx={{color: blue[400], mt: 1, mb: 1}}/>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Setup Type</Form.Label>
                                    <AsyncSelect
                                        value={values.setup}
                                        name="setup"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'setuptypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Business Industry</Form.Label>
                                    <AsyncSelect
                                        value={values.industry}
                                        name="industry"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'industries')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for industry ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: blue[400], fontSize: 17, fontFamily: 'Poppins', background: 'blue[700]', borderRadius: '5px', py: '5px',}}>Status</Typography>
                        <Divider sx={{color: blue[400], mt: 1, mb: 1}}/>

                        <Row>
                            
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Client Status</Form.Label>
                                    <AsyncSelect
                                        value={values.status}
                                        name="status"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'clientstatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                            
                        </Form>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[600], '&:hover': {color: blue[50]}}} onClick={handleSave}>Save Client</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddClientDialog
