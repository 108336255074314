import React from 'react'
import { Avatar, Typography, Stack, Grid } from '@mui/material';
import ME from '../../assets/ME.jpg';
import { blueGrey, blue } from '@mui/material/colors';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import useDialog from '../../Hooks/useDialog';
import useAlert from '../../Hooks/useAlert';
import ComplexAlert from '../ComplexAlert';
import ContactDialogs from '../Dialogs/CRM/Sales/ContactDialogs/ContactDialogs';

const ContactCard = ({contact}) => {

    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)

 
    const handleClick = () => {
        console.log('THIS IS WORKING')
        showDialog(true, 'view', contact.Id);
    }


    return ( 
        <>
        { dialog.open ? 
            <ContactDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>: ''}
            
        { alert.show ? 
            <ComplexAlert alert={alert} removeAlert={showAlert}/> : ''}


            <Grid item lg={4} >
                <Grid container justifyContent="space-between" alignItems="flex-start" onClick={handleClick} sx={{boxShadow: '1px 1px 5px #bbdefb',background: 'white', width: '95%', borderRadius: '5px', my: 2, '&:hover': {background: 'whitesmoke', cursor: 'pointer'}}}>
                    
                    <Avatar sx={{width: 100, height: 100, background: blueGrey[500], border: '7px solid whitesmoke'}} variant="circle" >
                        <PersonPinIcon fontSize="large"/>
                    </Avatar>
                    
                    <Grid item lg={8}>
                        <Stack sx={{mt: 1, mr: 2}}>
                            <Typography sx={{fontSize: 20, fontFamily: 'Poppins-Bold', color: blueGrey[800]}}>{contact.Name}</Typography>
                            <Stack direction="row" sx={{background: blue[200], borderRadius: '5px', px: 1}}>
                                <Typography sx={{fontSize: 13, fontFamily: 'Poppins', color: blue[600], mr: 1}}>D:</Typography>
                                <Typography sx={{fontSize: 13, fontFamily: 'Poppins-Medium', color: 'white', mr: 2}}>{contact.Department}</Typography>
                                <Typography sx={{fontSize: 13, fontFamily: 'Poppins', color: blue[600], mr: 1}}>P:</Typography>
                                <Typography sx={{fontSize: 13, fontFamily: 'Poppins-Medium', color: 'white'}}>{contact.Department}</Typography>
                            </Stack>
                        </Stack> 
                        <Stack direction="row" sx={{mt: 1, mr: 2}}>
                            <Typography sx={{fontSize: 13, fontFamily: 'Poppins', color: blueGrey[800]}}>E: {contact.Email}</Typography>
                            <Typography sx={{fontSize: 13, fontFamily: 'Poppins', color: blueGrey[800], ml: 1}}>M: {contact.Mobile}</Typography>
                            
                        </Stack> 
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactCard
