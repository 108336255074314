import React from 'react'
import { Paper, Grid, Typography } from '@mui/material';
import { blue, orange } from '@mui/material/colors';

const TimesheetListHeadings = () => {
    return (
        <>
            <Paper sx={{mt: 2,px: 1, py: 2, background: 'whitesmoke', boxShadow: 'none', border: '1px solid whitesmoke'}}>
                <Grid container justifyContent="flex-start">
                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', ml: 1, color:  blue[500], width: '50px', background: '', textAlign: 'left'}}>No.</Typography>
                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', ml: 2, color:  blue[500], minWidth: '300px', maxWidth: '300px'}}>Shift</Typography>
                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', ml: 1, color:  blue[500], width: '150px'}}>Division</Typography>
                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', ml: 1, color:  blue[500], width: '150px'}}>Role</Typography>
                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', ml: 1, color:  blue[500], minWidth: '150px'}}>Status</Typography>
                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', ml: 1, color:  blue[500], width: '150px'}}>Total Hours</Typography>
                </Grid>
            </Paper>
        </>
    )
}

export default TimesheetListHeadings
