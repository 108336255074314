import React, {useState} from 'react';
import AddReconcileDialog from './AddReconcileDialog';
import ViewReconcileDialog from './ViewReconcileDialog';
import DeleteReconcileDialog from './DeleteReconcileDialog';



const ReconcileDialogs
 = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <> 
        {
            type === "create"?

            <AddReconcileDialog open={open} onClose={onClose} handleSubmit={handleSubmit}  fullWidth />
            
            : type === "view"?

            <ViewReconcileDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />

            : type === "delete"?

            <DeleteReconcileDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />
            // <ViewReconcileDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default ReconcileDialogs

