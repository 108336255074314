import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDivisions, submitDivision } from '../../../Redux/Actions/DivisionActions';
import { Typography, Box, LinearProgress , Button, Grid, CircularProgress, IconButton, Stack, Divider} from '@mui/material';
import { blue, blueGrey, lightBlue } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import Crumbs from '../../../Components/Utilities/Crumbs';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import useDialog from '../../../Hooks/useDialog';
import useAlert from '../../../Hooks/useAlert';
import ComplexAlert from '../../../Components/ComplexAlert';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import DivisionDialogs from '../../../Components/Dialogs/CRM/Operations/DivisionDialogs/DivisionDialogs';
import DivisionsTbl from '../../../Components/Table/CRM/Operations/DivisionsTbl';


const Divisions = () => {

    const dispatch = useDispatch();
    //GET DIVISIONS
    const {divisions, loading} = useSelector(state => state.divisions_List);

    const [value, setValue] = useState('');
    const [items, setItems] = useState([])
 
    useEffect(()=> {
     
        dispatch(getDivisions());
        
    }, [])

    useEffect(()=> {
     
        if(divisions)
        {
            setItems([...divisions])
        }
        
    }, [divisions])


    const handleChange = (e) => {
        const {value} = e.target;
        //console.log('Value is:' + value)
        setValue(value);
    }
 
   

    const navigate = useNavigate();

    const handleClick = (id) => {
        navigate('/division/' + id)
    }

    const handleAddClick = () => {
        console.log('THIS IS WORKING')
        showDialog(true, 'create', '');
    }
    const {alert, showAlert} = useAlert();
    const {closeDialog, dialog, handleSubmit, showDialog} = useDialog(showAlert);

    return (
        <>
            <ScreenHeader title="Divisions" subtitle="Manage all divisions from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${items.length}`}/>
            <Grid container justifyContent="space-between" sx={{mb: 2}}>
                <Crumbs root="CRM" middle="Operations" end="Divisions" main/>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <AddButtonLabel item="Division"/>
                    <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                        <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                    </IconButton>
                </Stack>
            </Grid>
            <Divider sx={{color: lightBlue[800]}}/>
            {dialog.open? <DivisionDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : ''  }
            {alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
            { loading ?  <HorizontalLoading/> : '' }
            
            {/* <br/>
            <h1>DDd { !loading? divisions.Name : null}</h1>  
            { !loading? divisions.map((element, index) => 
                <Typography key={index} onClick={()=> handleClick(element.Division_Id)} variant="body1" sx={{color: blue[800],fontWeight: 700, cursor: 'pointer'}}>{element.Name}</Typography>
            ): 'Nothing'} */}

            <Grid container>
                <DivisionsTbl divisions={items}/>
            </Grid>

            <hr/>
            
            <h4>{`You have entered ${value} division`}</h4>
            <br/>
            {/* {
                save_loading? 'loading save' : 'done loading save'
            } */}
            <Form>
                <Form.Group as={Row}>
                    <Form.Label column className="lg-3">Input department name</Form.Label>
                    <Col lg={9}>
                        <Form.Control onChange={handleChange} placeholder="Enter Division" value={value}/>
                    </Col>
                </Form.Group>
                <br/>
                <Button onClick={handleSubmit} variant="contained">Add Division</Button>
            </Form>

            
        </>
    )
}

export default Divisions
