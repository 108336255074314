export const PROFILES_LIST_REQUEST = 'PROFILES_LIST_REQUEST'
export const PROFILES_LIST_SUCCESS = 'PROFILES_LIST_SUCCESS'
export const PROFILES_LIST_FAIL = 'PROFILES_LIST_FAIL'



export const PROFILE_CREATE_REQUEST = 'PROFILE_CREATE_REQUEST'
export const PROFILE_CREATE_SUCCESS = 'PROFILE_CREATE_SUCCESS'
export const PROFILE_CREATE_FAIL = 'PROFILE_CREATE_FAIL'


export const VIEW_PROFILE_REQUEST = 'VIEW_PROFILE_REQUEST'
export const VIEW_PROFILE_SUCCESS = 'VIEW_PROFILE_SUCCESS'
export const VIEW_PROFILE_FAIL = 'VIEW_PROFILE_FAIL'


export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL'


export const PROFILE_DELETE_REQUEST = 'PROFILE_DELETE_REQUEST'
export const PROFILE_DELETE_SUCCESS = 'PROFILE_DELETE_SUCCESS'
export const PROFILE_DELETE_FAIL = 'PROFILE_DELETE_FAIL'



