import React from 'react';
import { Box, Grid, Tabs, Tab, Typography } from '@mui/material';
import SignIn from './SignIn';
import SignUp from './SignUp';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


  
  const LoginScreen = ({login, setLogin}) => {

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    return (
        <div className="login-page">
          
        <Grid container sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', background: ''}}>
            <Grid item lg={12} sx={{background: '', mt: 5}}>
                 <h1>Welcome to the Complex Systems Company portal. This is a private portal for employees, clients or agencies</h1>
            </Grid>
            <Box component="div" sx={{position: 'absolute',top: '14%',background: 'red', textAlign: 'center', borderRadius: '5px', width: '400px', display: 'flex', justifyContent: 'center'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', ml:2 }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Sign In" sx={{color: 'white'}}/>
                        <Tab label="Register" sx={{color: 'white'}} />
                    </Tabs>
                </Box>
            </Box>
        </Grid>
        <Box component="div" sx={{position: 'absolute',top: '20%', left: '50%', transform: 'translate(-50%,0%)', background: '', textAlign: 'center', borderRadius: '5px'}}>

            <TabPanel value={tabValue} index={0}>
                <Grid container justifyContent="center" sx={{background: ''}}>
                        <SignIn login={login} setLogin={setLogin}/>
                   
                </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
               
                    <Grid container justifyContent="center"  sx={{background: ''}}>
                        <SignUp login={login} setLogin={setLogin}/>
                    </Grid>
             
            </TabPanel>
        </Box>
      
            
            {/* <SignUp/> */}
   

</div>
    )
}

export default LoginScreen


 {/* <Form>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridEmail" >
                            <Form.Label className="view-label mb-3">Assessment ID</Form.Label>
                            <Form.Control type="email" value={'Nothing'} placeholder="E.g 123qst1" />
                        </Form.Group>
                    </Row>
                </Form> */}