import { CONTACTTYPES_LIST_REQUEST, CONTACTTYPES_LIST_SUCCESS, CONTACTTYPES_LIST_FAIL, VIEW_CONTACTTYPE_FAIL, VIEW_CONTACTTYPE_REQUEST, VIEW_CONTACTTYPE_SUCCESS, CONTACTTYPE_CREATE_REQUEST, CONTACTTYPE_CREATE_SUCCESS, CONTACTTYPE_CREATE_FAIL, CONTACTTYPE_UPDATE_FAIL, CONTACTTYPE_UPDATE_REQUEST, CONTACTTYPE_UPDATE_SUCCESS } from '../Constants/contactTypeConstants';
import api from '../../config/apiConfig';


const getContactTypesList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: CONTACTTYPES_LIST_REQUEST});
        const {data} = await api.get('/contacttypes');
        // console.log('ContactTypes data is:' + data[0]['ContactType_Name'])
        dispatch({type: CONTACTTYPES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: CONTACTTYPES_LIST_FAIL, payload: error})
    }
} 

const submitContactType = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: CONTACTTYPE_CREATE_REQUEST});
        
        const {data} = await api.post(`/contacttypes/`, values);
        console.log('ContactType data is:' + data)
        dispatch({type: CONTACTTYPE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: CONTACTTYPE_CREATE_FAIL, payload: error})
    }
} 

const getContactType = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_CONTACTTYPE_REQUEST});
        
        const {data} = await api.get(`/contacttypes/ ${id}`);
        console.log('ContactType data is:' + data)
        dispatch({type: VIEW_CONTACTTYPE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_CONTACTTYPE_FAIL, payload: error})
    }
}

const editContactType = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: CONTACTTYPE_UPDATE_REQUEST});
        
        const {data} = await api.put(`/contacttypes/`, values);
        console.log('ContactType data is:' + data)
        dispatch({type: CONTACTTYPE_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: CONTACTTYPE_UPDATE_FAIL, payload: error})
    }
}



export {getContactTypesList, submitContactType, getContactType, editContactType }