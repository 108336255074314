export const OPPORTUNITYSTATUSES_LIST_REQUEST = 'OPPORTUNITYSTATUSES_LIST_REQUEST';
export const OPPORTUNITYSTATUSES_LIST_SUCCESS = 'OPPORTUNITYSTATUSES_LIST_SUCCESS';
export const OPPORTUNITYSTATUSES_LIST_FAIL = 'OPPORTUNITYSTATUSES_LIST_FAIL'; 

export const OPPORTUNITYSTATUS_CREATE_REQUEST = 'OPPORTUNITYSTATUS_CREATE_REQUEST'
export const OPPORTUNITYSTATUS_CREATE_SUCCESS = 'OPPORTUNITYSTATUS_CREATE_SUCCESS'
export const OPPORTUNITYSTATUS_CREATE_FAIL = 'OPPORTUNITYSTATUS_CREATE_FAIL'


export const OPPORTUNITYSTATUS_UPDATE_REQUEST = 'OPPORTUNITYSTATUS_UPDATE_REQUEST'
export const OPPORTUNITYSTATUS_UPDATE_SUCCESS = 'OPPORTUNITYSTATUS_UPDATE_SUCCESS'
export const OPPORTUNITYSTATUS_UPDATE_FAIL = 'OPPORTUNITYSTATUS_UPDATE_FAIL'

export const OPPORTUNITYSTATUS_DELETE_REQUEST = 'OPPORTUNITYSTATUS_DELETE_REQUEST'
export const OPPORTUNITYSTATUS_DELETE_SUCCESS = 'OPPORTUNITYSTATUS_DELETE_SUCCESS'
export const OPPORTUNITYSTATUS_DELETE_FAIL = 'OPPORTUNITYSTATUS_DELETE_FAIL'

export const VIEW_OPPORTUNITYSTATUS_REQUEST = 'VIEW_OPPORTUNITYSTATUS_REQUEST'
export const VIEW_OPPORTUNITYSTATUS_SUCCESS = 'VIEW_OPPORTUNITYSTATUS_SUCCESS'
export const VIEW_OPPORTUNITYSTATUS_FAIL = 'VIEW_OPPORTUNITYSTATUS_FAIL'