import React, {useState, useEffect} from 'react';
import { Box } from '@mui/system';
import { Link as BreadcrumbLink, Avatar, CircularProgress, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { IconButton, Grid, Divider, LinearProgress, Stack, Typography, Breadcrumbs } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import useDialog from '../../../Hooks/useDialog';
import { blueGrey , blue, purple, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import { getContactsList } from '../../../Redux/Actions/ContactActions';
import ContactsList from '../../../Components/Utilities/ContactsList';
import SearchTableComponent from '../../../Components/Table/SearchTableComponent';
import useFilter from '../../../Hooks/useFilter';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import Crumbs from '../../../Components/Utilities/Crumbs';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import ContactDialogs from '../../../Components/Dialogs/CRM/Sales/ContactDialogs/ContactDialogs';
import ContactsTbl from '../../../Components/Table/CRM/Sales/ContactsTbl';
 
const Contacts = () => { 

    //FETCH DATA FROM SERVER
    const {loading, contacts, error } = useSelector(state => state.contacts_List) //receiving state object
    
    const [list, setList] = useState([])
    
    const dispatch = useDispatch();
    
    useEffect(()=> {
        dispatch(getContactsList());
    }, [])

    useEffect(()=> {
        if(contacts)
        {
            setList([...contacts])
        }

    }, [contacts])
     
    //TABLE DIALOG FUNCTIONALITY
    
        const handleAddClick = () => {
            console.log('THIS IS WORKING')
            showDialog(true, 'create', '');
        }

        
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)
    const {handleSearch} = useFilter();

    const [view, setView] = useState('table');
    
    //----------------------------------------------------------------------------------------------------------------------------------
     
    return (
        <>
            
        <ScreenHeader title="Contacts" subtitle="Manage contact information from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>
        {dialog.open ? <ContactDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : ''}
        


        <Grid container justifyContent="space-between" sx={{mb: 2}}>
            <Crumbs root="CRM" middle="Sales" end="Contacts" main/>
            <Stack direction="row" justifyContent="center" alignItems="center">
                <AddButtonLabel item="Contact"/>
                <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                    <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                </IconButton>
            </Stack>
        </Grid>

 
        <Divider sx={{color: lightBlue[800]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        { loading ?  <HorizontalLoading/> : '' }


        <HorizontalSummaryBox primary="Contacts Brief" secondary="Overall Contacts count" label1="All Contacts" label2="Second Label" label3="Third Label" count={[2,11,6]}/>
        <Grid container justifyContent="flex-end">
            <ToggleButtonGroup value ={view} size="small" color="primary" >
                <ToggleButton value="table" onClick={()=> setView('table')} sx={{background: view == 'table'? '' : '' }}>Table <CircleIcon fontSize="small" sx={{ pl: 1, color: blue[700]}}/> </ToggleButton>
                <ToggleButton value="kanban" onClick={()=> setView('kanban')} sx={{background: view == 'cards'? '' : '' }}>Cards <CircleIcon fontSize="small" sx={{ pl: 1, color: blue[700]}}/></ToggleButton>
            </ToggleButtonGroup>
        </Grid>

        <AboveTableHeader title="Contacts List"/>

        {
                view === 'table'?

                <Grid container>
                    <ContactsTbl contacts={list}/>
                </Grid>
                        :
                <Grid container sx={{my: 3, mr: 3, background: ''}}> 
                    <Box component="div" sx={{background: 'white', px: 1, py: 1, mt: 1, width: '30%', mb: 2, borderRadius: 2, boxShadow: '1px 1px 5px #cfd8dc'}}>
                        <SearchTableComponent handleSearch ={handleSearch} other forTable={'Search contacts'}/>
                    </Box>
                    
                    
                        {
                            loading? ''
                            : 
                            <ContactsList contacts={contacts}/>
                        }
                </Grid>
        }

        
   


     
    </>
    )
}

export default Contacts
