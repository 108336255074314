import { DIVISIONS_LIST_FAIL, DIVISIONS_LIST_SUCCESS, DIVISIONS_LIST_REQUEST,
         DIVISIONS_CREATE_REQUEST, DIVISIONS_CREATE_SUCCESS, DIVISIONS_CREATE_FAIL,
         DIVISIONS_UPDATE_REQUEST, DIVISIONS_UPDATE_SUCCESS, DIVISIONS_UPDATE_FAIL,
         DIVISIONS_DELETE_REQUEST, DIVISIONS_DELETE_SUCCESS, DIVISIONS_DELETE_FAIL, VIEW_DIVISION_REQUEST, VIEW_DIVISION_SUCCESS, VIEW_DIVISION_FAIL } from "../Constants/divisionConstants";

const initialState = {

        divisions: [],
        division: {},
        loading: true,
    }



const divisionsListReducer = (state = initialState, action) => {

    switch(action.type)
    {
        case DIVISIONS_LIST_REQUEST: 
            return {
                    loading: true
                }

        case DIVISIONS_LIST_SUCCESS: 
           
            return {
                    loading: false, 
                    divisions: [...action.payload]
                }

        case DIVISIONS_LIST_FAIL: 
            return {
                error: action.payload
            }

        case DIVISIONS_CREATE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DIVISIONS_CREATE_SUCCESS: 
         
            let randomNumber = Math.floor(Math.random() * (Math.random()*43 + 12))
            const neww = {id: randomNumber, Name: action.payload};
            return {
                ...state,
                loading: false,
                divisions: [...state.divisions, neww]
                
            };

        case DIVISIONS_CREATE_FAIL:
        return {
            error: action.payload
        }
        
        
        default:
            return state

    }

}

const divisionCreateReducer = (state = { divisions: []}, action) => {
 
    switch (action.type) {


        case DIVISIONS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const divisionViewReducer = (state = initialState, action) => {

    switch (action.type) {

        case VIEW_DIVISION_REQUEST:
            return {
                loading: true,
            }

        case VIEW_DIVISION_SUCCESS: 
            console.log('In reducer, have received: ' + action.payload)
            return {
                loading: false,
                division: action.payload
            };

        case VIEW_DIVISION_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const divisionUpdateReducer = (state = initialState, action) => {

    switch (action.type) {

        case DIVISIONS_UPDATE_REQUEST:

            return {
                loading: true
            }
        case DIVISIONS_UPDATE_SUCCESS:
            return {
                loading: false,
                division: action.payload
            }
        case DIVISIONS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const divisionDeleteReducer = (state = initialState, action) => {

    switch (action.type) {
        case DIVISIONS_DELETE_REQUEST:
            return {
                
            }
        case DIVISIONS_DELETE_SUCCESS:
            return {

            }
        case DIVISIONS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}




export {divisionsListReducer, divisionCreateReducer, divisionViewReducer, divisionUpdateReducer, divisionDeleteReducer}