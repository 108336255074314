import { RECONCILES_LIST_REQUEST, RECONCILES_LIST_SUCCESS, RECONCILES_LIST_FAIL, VIEW_RECONCILE_FAIL, VIEW_RECONCILE_REQUEST, VIEW_RECONCILE_SUCCESS, RECONCILE_CREATE_FAIL, RECONCILE_UPDATE_REQUEST, RECONCILE_UPDATE_FAIL, RECONCILE_DELETE_REQUEST, RECONCILE_DELETE_FAIL, RECONCILE_CREATE_REQUEST, RECONCILE_CREATE_SUCCESS, RECONCILE_UPDATE_SUCCESS, RECONCILE_DELETE_SUCCESS } from "../Constants/reconcileConstants";

const reconcilesListReducer = (state = {reconciles: []}, action) => {

    switch (action.type)
    {
        case RECONCILES_LIST_REQUEST:
            return {loading: true};
 
        case RECONCILES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and reconciles data is:' + action.payload)
            return {
                loading: false,
                reconciles: action.payload
            }
        case RECONCILES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const reconcileCreateReducer = (state = {reconciles: []}, action) => {

    switch (action.type) {

        case RECONCILE_CREATE_REQUEST:
            return {
                loading: true
            }

        case RECONCILE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                reconciles: [...state.reconciles, {Applicant_Id: 2, Name: value}]
            };

        case RECONCILE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const reconcileViewReducer = (state= {reconcile: {}}, action) => {
    switch(action.type)
    {
        case VIEW_RECONCILE_REQUEST:
            return {loading: true};
        case VIEW_RECONCILE_SUCCESS:
            return {
                    loading: false,
                    reconcile: action.payload
                }
        case VIEW_RECONCILE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const reconcileUpdateReducer = (state = {reconciles: {}}, action) => {

    switch (action.type) {

        case RECONCILE_UPDATE_REQUEST:
            return {
                
            }
        case RECONCILE_UPDATE_SUCCESS:

            return {

            }
        case RECONCILE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const reconcileDeleteReducer = (state = {reconciles: {}}, action) => {

    switch (action.type) {
        case RECONCILE_DELETE_REQUEST:
            return {
                
            }
        case RECONCILE_DELETE_SUCCESS:
            return {

            }
        case RECONCILE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {reconcilesListReducer, reconcileCreateReducer, reconcileViewReducer, reconcileUpdateReducer, reconcileDeleteReducer }