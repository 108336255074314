import React, { useState } from 'react'
import { Card, Grid, Box, Divider, Button, TextField, FormControl, MenuItem, Stack, Typography, IconButton } from '@mui/material';
import {Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText  } from '@mui/material';
import Chart from '../../../Components/Chart';
import {Bar} from 'react-chartjs-2';
import PageHeader from '../../../Components/PageHeader';
import AddIcon from '@mui/icons-material/Add';
import PeopleIcon from '@mui/icons-material/People';
import StoreIcon from '@mui/icons-material/Store';
import EngineeringIcon from '@mui/icons-material/Engineering';
import useDialog from '../../../Hooks/useDialog';
import Slide from '@mui/material/Slide';
import { blueGrey, blue, deepPurple, deepOrange, green } from '@mui/material/colors';
import AttachmentIcon from '@mui/icons-material/Attachment';
import BadgeIcon from '@mui/icons-material/Badge';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import SearchTableComponent from '../../../Components/Table/SearchTableComponent';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const DashboardScreen = () => {

    //Dialog hook stuff
    const {handleClose, handleClickOpen, open} = useDialog();
    
    //Input field stuff
    const dashboard_options = [
        { 
            label: 'Type 1',
            value: 'Value 1',
        },
        {
            label: 'Type 2',
            value: 'Value 2',
        },
         
    ];
    const [menuValue, setMenuValue] = useState('Top-level')
    const handleChange = (event) => {
        const {value} = event.target;
        setMenuValue(value) ;
    }
 
    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography sx={{fontSize: 30, display: 'inline-block', color: blueGrey[900], fontFamily: 'Poppins', fontWeight: 900, marginBottom: 0, mr: 1}} >{`Complex Recruitment `} <Typography variant="" component="span" sx={{color: blueGrey[700],fontSize: '1.8rem'}}></Typography></Typography>
                    <Typography sx={{fontSize: 30, display: 'inline-block', color: blueGrey[900], fontFamily: 'Poppins', fontWeight: 300, marginBottom: 0}} >{` Dashboard`} <Typography variant="" component="span" sx={{color: blueGrey[700],fontSize: '1.8rem'}}></Typography></Typography>
                    <br/>
                    <Typography variant="" sx={{display: 'inline-block', borderRadius: '5px', px: '7px' , color: 'white',fontFamily: 'Poppins', fontWeight: 500, background: blueGrey[900] , mt: 1 , boxShadow: '1px 1px 8px #90a4ae'}}>Have a view of the company here.</Typography>
                    <Typography variant="" sx={{display: 'block', borderRadius: '5px', px: '7px' , color: '', fontSize: 14,fontFamily: 'Poppins', fontWeight: 500, mt: 2 , boxShadow: ''}}>Up-to-date</Typography>

                </Grid>
                <Grid item>
                    <AccessTimeIcon sx={{mr: 1, color: blue[800]}}/>
                    <Typography variant="" sx={{display: 'inline-block', borderRadius: '5px', px: '7px' , color: 'white',fontWeight: 500, background: blueGrey[900], textAlign: 'right' }}>13:00 A.M</Typography>
                    <br/>
                    <Typography variant="" sx={{display: 'inline-block', borderRadius: '5px', color: blue[800], fontSize: 14,fontWeight: 500, mt: 1 , boxShadow: ''}}>Harare, Zimbabwe</Typography>
            
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ mt:0, mb: 3, background: '' }}>     
        
                <Box component="div" sx={{background: 'white', px: 1, py: 1, width: '30%', mt: 2, borderRadius: 2}}>
                    <SearchTableComponent handleSearch ={'handleSearch'} other forTable={'Search for section'}/>
                </Box>

                <IconButton variant="contained" onClick={handleClickOpen} sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray'}}>
                    <AddIcon sx={{color: 'white'}} />
                </IconButton>
            </Grid>


       
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{color: blueGrey[700], fontWeight: 800}}>Create new dashboard</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Box 
                        component="form"
                        sx={{'& .MuiTextField-root': { m: 1, width: '50ch' },}}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl>
                            <TextField
                                disabled
                                margin="dense"
                                id="name"
                                label="Dashboard ID"
                                type="email"
                                fullWidth
                                variant="standard"
                                sx={{mb: 3}}
                            />
                            <br/>
                            <TextField
                                id=""
                                select
                                label="Select dashboard type"
                                value={menuValue}
                                handleChange={handleChange}
                             
                            >
                                {dashboard_options.map(option => (
                                    <MenuItem value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}

                            </TextField>
                            <TextField
                                id=""
                                select
                                label="Select another field here"
                                value={menuValue}
                                handleChange={handleChange}
                                helperText="Please select the type of dashboard"
                            >
                                {dashboard_options.map(option => (
                                    <MenuItem value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}

                            </TextField>
                        </FormControl>
                    </Box>
                    
                </DialogContent>
                <Divider/>
                <DialogActions>
                <Button color="error" onClick={handleClose}>Cancel</Button>
                <Button color="info" onClick={handleClose}>Create Dashboard</Button>
                </DialogActions>
            </Dialog>

          
            <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 500 , color: blueGrey[100], my: 2}}>Heading 1</Typography>

            <Grid container justifyContent="space-evenly" alignContent="" sx={{mb: 3, }}>
                <Grid item sx={{width: '32%',py: 1, mr: 1, textAlign: 'center', background: 'rgba(255, 255, 255,.7)', borderRadius: '10px', border: '2px solid rgba(242, 247, 252, .1)', boxShadow: ''}}>
                    <Grid container flexDirection="row" justifyContent="space-around" alignItems="center">
                        <PeopleIcon sx={{color: blueGrey[500], fontSize: 30}}/>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 700 , color: blueGrey[900], mr: 2}}>Office Staff</Typography>
                            <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[500], lineHeight: '20px', fontWeight: 600}}>120</Typography>
                        </Stack>
                        
                    </Grid>
                </Grid>
                <Grid item sx={{width: '32%',py: 1, mr: 1, textAlign: 'center', background: 'rgba(255, 255, 255,.7)', borderRadius: '10px', border: '2px solid rgba(242, 247, 252, .1)', boxShadow: ''}}>
                    <Grid container flexDirection="row" justifyContent="space-around" alignItems="center">
                        <EngineeringIcon sx={{color: blueGrey[500], fontSize: 30}}/>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 700 , color: blueGrey[900], mr: 2}}>Workers</Typography>
                            <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[500], lineHeight: '20px', fontWeight: 600}}>120</Typography>
                        </Stack>
                        
                    </Grid>
                </Grid>
                <Grid item sx={{width: '32%',py: 1, mr: 1, textAlign: 'center', background: 'rgba(255, 255, 255,.7)', borderRadius: '10px', border: '2px solid rgba(242, 247, 252, .1)', boxShadow: ''}}>
                    <Grid container flexDirection="row" justifyContent="space-around" alignItems="center">
                        <StoreIcon si sx={{color: blueGrey[500], fontSize: 30}}/>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 700 , color: blueGrey[900], mr: 2}}>Clients</Typography>
                            <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[500], lineHeight: '20px', fontWeight: 600}}>620</Typography>
                        </Stack>
                        
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-start" alignContent="" sx={{background: '', }}>
            
                <Grid item lg={3} sx={{py: 1, mr: 0, textAlign: 'center', background: blueGrey[900], borderRadius: '10px', border: '2px solid #b0bec5', boxShadow: '2px 2px 10px  rgb(182, 182, 182)'}}>
                    <Grid container flexDirection="row" justifyContent="space-around" alignItems="center">
                        <CallMissedOutgoingIcon sx={{color: blueGrey[50], fontSize: 70}}/>
                        <Stack direction="column" alignItems="flex-end">
                            <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 700 , color: 'white'}}>Sales Growth</Typography>
                            <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[50], lineHeight: '20px', fontWeight: 600}}>120,000</Typography>
                        </Stack>
                        <Stack justifyContent="center" direction="row" sx={{mb:1, width: '50%',background: ''}}>
                            <Typography variant="" sx={{fontSize: 17, fontWeight: 900 ,color: blueGrey[200], pl: 1}}>Position: </Typography>
                            <ArrowCircleUpIcon sx={{ml:1, color: 'green', fontWeight: 900}}/>
                            <Typography variant="" sx={{fontSize: 17, fontWeight: 900 , color: blueGrey[200], pl: 1}}>+33.12%</Typography>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid item lg={3} sx={{py: 1, mr: 0, textAlign: 'center', background: blue[900], borderRadius: '10px', border: '2px solid #b0bec5', boxShadow: '2px 2px 10px  rgb(182, 182, 182)'}}>
                    <Grid container flexDirection="row" justifyContent="space-around" alignItems="center">
                        <CallMissedOutgoingIcon sx={{color: blueGrey[50], fontSize: 70}}/>
                        <Stack direction="column" alignItems="flex-end">
                            <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 700 , color: 'white'}}>Heading 2</Typography>
                            <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[50], lineHeight: '20px', fontWeight: 600}}>120,000</Typography>
                        </Stack>
                        <Stack justifyContent="center" direction="row" sx={{mb:1, width: '50%',background: ''}}>
                            <Typography variant="" sx={{fontSize: 17, fontWeight: 900 ,color: blueGrey[200], pl: 1}}>Position: </Typography>
                            <ArrowCircleUpIcon sx={{ml:1, color: 'green', fontWeight: 900}}/>
                            <Typography variant="" sx={{fontSize: 17, fontWeight: 900 , color: blueGrey[200], pl: 1}}>+33.12%</Typography>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid item lg={3} sx={{py: 1, mr: 0, textAlign: 'center', background: deepPurple[900], borderRadius: '10px', border: '2px solid #b0bec5', boxShadow: '2px 2px 10px  rgb(182, 182, 182)'}}>
                    <Grid container flexDirection="row" justifyContent="space-around" alignItems="center">
                        <CallMissedOutgoingIcon sx={{color: blueGrey[50], fontSize: 70}}/>
                        <Stack direction="column" alignItems="flex-end">
                            <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 700 , color: 'white'}}>Heading 3</Typography>
                            <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[50], lineHeight: '20px', fontWeight: 600}}>120,000</Typography>
                        </Stack>
                        <Stack justifyContent="center" direction="row" sx={{mb:1, width: '50%',background: ''}}>
                            <Typography variant="" sx={{fontSize: 17, fontWeight: 900 ,color: blueGrey[200], pl: 1}}>Position: </Typography>
                            <ArrowCircleUpIcon sx={{ml:1, color: 'green', fontWeight: 900}}/>
                            <Typography variant="" sx={{fontSize: 17, fontWeight: 900 , color: blueGrey[200], pl: 1}}>+33.12%</Typography>
                        </Stack>
                    </Grid>
                </Grid>

                
                <Grid item lg={3} sx={{py: 1, mr: 0, textAlign: 'center', background: deepOrange[700], borderRadius: '10px', border: '2px solid #b0bec5', boxShadow: '2px 2px 10px  rgb(182, 182, 182)'}}>
                    <Grid container flexDirection="row" justifyContent="space-around" alignItems="center">
                        <CallMissedOutgoingIcon sx={{color: blueGrey[50], fontSize: 70}}/>
                        <Stack direction="column" alignItems="flex-end">
                            <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 700 , color: 'white'}}>Heading 4</Typography>
                            <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[50], lineHeight: '20px', fontWeight: 600}}>120,000</Typography>
                        </Stack>
                        <Stack justifyContent="center" direction="row" sx={{mb:1, width: '50%',background: ''}}>
                            <Typography variant="" sx={{fontSize: 17, fontWeight: 900 ,color: blueGrey[200], pl: 1}}>Position: </Typography>
                            <ArrowCircleUpIcon sx={{ml:1, color: green[500], fontWeight: 900}}/>
                            <Typography variant="" sx={{fontSize: 17, fontWeight: 900 , color: blueGrey[200], pl: 1}}>+33.12%</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        
            <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 500 , color: blueGrey[100], my: 2}}>Heading 2</Typography>

            <Grid container justifyContent="space-evenly" alignContent="" sx={{mb: 3, }}>
                <Grid item lg={7} sx={{background: ''}}>
                    <Grid container>
                        <Grid item lg={5} sx={{width: '',py: 1, mr: 2, textAlign: 'center', background: 'rgba(255, 255, 255,.7)', borderRadius: '10px', border: '2px solid rgba(242, 247, 252, .1)', boxShadow: ''}}>
                            <Grid container flexDirection="row" justifyContent="space-around" alignItems="center">
                                <PeopleIcon sx={{color: blueGrey[500], fontSize: 30}}/>
                                <Stack direction="row" alignItems="center">
                                    <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 700 , color: blueGrey[900], mr: 2}}>Applicants</Typography>
                                    <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blueGrey[500], lineHeight: '20px', fontWeight: 600}}>20</Typography>
                                </Stack>
                                
                            </Grid>
                        </Grid>
                
                        <Grid item lg={5} sx={{width: '',py: 1, mr: 1, textAlign: 'center', background: 'rgba(255, 255, 255,.7)', borderRadius: '10px', border: '2px solid rgba(242, 247, 252, .1)', boxShadow: ''}}>
                            <Grid container flexDirection="row" justifyContent="space-around" alignItems="center">
                                <PeopleIcon sx={{color: blueGrey[500], fontSize: 30}}/>
                                <Stack direction="row" alignItems="center">
                                    <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 700 , color: blueGrey[900], mr: 2}}>Registered</Typography>
                                    <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[500], lineHeight: '20px', fontWeight: 600}}>120</Typography>
                                </Stack>
                                
                            </Grid>
                        </Grid>
                        <Grid item lg={12} sx={{width: '',py: 1,mt: 2,  mr: 1, textAlign: 'center', background: blueGrey[800], borderRadius: '10px', border: '2px solid rgba(242, 247, 252, .1)', boxShadow: ''}}>
                            <Grid container flexDirection="row" justifyContent="space-around" alignItems="center">
                                <Typography variant="h6" component="p" sx={{fontSize: 17, fontWeight: 500 , color: blue[500], mr: 2}}>Tasks</Typography>
                                <Stack direction="row" alignItems="center">
                                    <Typography variant="h6" component="p" sx={{fontSize: 16, fontWeight: 700 , color: blueGrey[50], mr: 2}}> Ongoing</Typography>
                                    <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[500], lineHeight: '20px', fontWeight: 600, mr: 3}}>120</Typography>

                                    <Typography variant="h6" component="p" sx={{fontSize: 16, fontWeight: 700 , color: blueGrey[50], mr: 2}}> Completed</Typography>
                                    <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[500], lineHeight: '20px', fontWeight: 600}}>120</Typography>
                                </Stack>
                                
                            </Grid>
                        </Grid>
                        <Grid item lg={12} sx={{width: '',py: 1,mt: 2,  mr: 1, textAlign: 'center', background: blueGrey[900], borderRadius: '10px', border: '2px solid rgba(242, 247, 252, .1)', boxShadow: ''}}>
                            <Grid container flexDirection="row" justifyContent="space-around" alignItems="center">
                                <Typography variant="h6" component="p" sx={{fontSize: 17, fontWeight: 500 , color: blue[500], mr: 2}}>Assessments</Typography>
                                <Stack direction="row" alignItems="center">
                                    <Typography variant="h6" component="p" sx={{fontSize: 16, fontWeight: 700 , color: blueGrey[50], mr: 2}}>Ongoing</Typography>
                                    <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[500], lineHeight: '20px', fontWeight: 600, mr: 3}}>120</Typography>

                                    <Typography variant="h6" component="p" sx={{fontSize: 16, fontWeight: 700 , color: blueGrey[50], mr: 2}}>Completed</Typography>
                                    <Typography variant="" sx={{fontSize: 15, fontWeight: 900 ,color: blue[500], lineHeight: '20px', fontWeight: 600}}>120</Typography>
                                </Stack>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={5} sx={{background: ''}}>
                    <Grid container>
                        <Grid item lg={12} sx={{width: '',py: 1, mr: 1, textAlign: 'center', background: 'rgba(255, 255, 255,.7)', borderRadius: '10px', border: '2px solid rgba(242, 247, 252, .1)', boxShadow: ''}}>
                            <Grid container flexDirection="row" justifyContent="space-around" alignItems="center" sx={{height: '200px'}}>
                               
                                <Stack direction="row" alignItems="center">
                                    <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 700 , color: blueGrey[900], mr: 2}}>SALARIES</Typography>
                                    <Typography variant="" sx={{fontSize: 15, fontWeight: 400 ,color: blue[500], lineHeight: '20px'}}>Salary Information Here?</Typography>
                                </Stack>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 500 , color: blueGrey[100], my: 2}}>Finance </Typography>
            <Grid container spacing={2} sx={{my: 2}}>
                    <Grid item lg={6} >
                        <Card variant="outlined" sx={{background: blueGrey[800], padding: '30px', border: '2px solid #cfd8dc', borderRadius: '10px'}}>
                            <Chart/>
                        </Card>
                    </Grid>

                    <Grid item lg={6} >
                        <Grid container  sx={{ background: blueGrey[600], border: '3px solid #eceff1', borderRadius: '10px'}}>

                            <Grid item lg={12} sx={{mb: 1}}>
                                <Grid container justifyContent="flex-start" alignItems="center" sx={{pl: 3, py: 2}}>
                                    <FormatListNumberedIcon sx={{color: 'orange'}}/>
                                    <Typography variant="h5" style={{fontWeight: 700, fontSize: '1rem',color: blueGrey[100], padding: '0 30px', lineHeight: '10px'}}>Financial Statistics</Typography>
                                
                                </Grid>
                            <Divider/>

                            </Grid>
                            <Grid item lg={12} sx={{height: '100%'}}>
                            <Grid container justifyContent="space-around" sx={{background: ''}}>

                                <Grid item lg={4} >
                                    <Grid container direction="column" alignItems="center" justifyContent="center">
                                        <Typography style={{color: blueGrey[100], fontWeight: 700, }}>In progress</Typography>
                                        <Typography style={{color: 'black', fontSize: '1.2rem', fontWeight: 800, }}>12</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item lg={4}>
                                    <Grid container direction="column" alignItems="center" justifyContent="center">
                                        <Typography style={{color: blueGrey[100], fontWeight: 700, }}>Postponed</Typography>
                                        <Typography style={{color: 'black', fontSize: '1.2rem', fontWeight: 800, }}>3</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={4}>
                                    <Grid container direction="column" alignItems="center" justifyContent="center">
                                        <Typography style={{color: blueGrey[100], fontWeight: 700, }}>Completed</Typography>
                                        <Typography style={{color: 'black', fontSize: '1.2rem', fontWeight: 800, }}>3</Typography>
                                    </Grid>
                                </Grid>

                            
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{borderBottom: '2px solid red'}}/>
            </Grid>

            <Typography variant="h6" component="p" sx={{fontSize: 20, fontWeight: 500 , color: blueGrey[100], my: 2}}>Sales Growth</Typography>

            <Grid container justifyContent="space-around" alignContent="" sx={{background: ''}}>
                <Grid item lg={3} sx={{py: 2, textAlign: 'center', border: '', borderRadius: '10px'}}>
                    <Grid container flexDirection="column" justifyContent="center" alignContent="center">
                        <Typography variant="h6" component="p" sx={{color: 'black'}}>Sales Growth</Typography>
                        <Typography variant=""  sx={{color: blueGrey[900], lineHeight: '20px',fontSize: 30, fontWeight: 700 , }}>120,000</Typography>
                        <Stack justifyContent="center" direction="row" sx={{mt:1, width: '50%',background: ''}}>
                            <Typography variant="" component="p" sx={{pl: 1}}>Position: </Typography>
                            <ArrowCircleUpIcon sx={{ml:1, color: 'green', fontWeight: 900}}/>
                            <Typography variant="" component="h4" sx={{pl: 1}}>+33.12%</Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Divider orientation="vertical"/>
                <Grid item lg={3} sx={{py: 2, textAlign: 'center'}}>
                    <Grid container flexDirection="column" justifyContent="center" alignContent="center">
                        <Typography variant="h6" component="p" sx={{color: 'black'}}>Current Leads</Typography>
                        <Typography variant="" component="h1" sx={{color: blueGrey[900], lineHeight: '20px', fontSize: 30, fontWeight: 700 , }}>100 new</Typography>
                        <Stack justifyContent="center" direction="row" sx={{mt:1, width: '50%',background: ''}}>
                            <ArrowCircleDownIcon sx={{color: 'red', fontWeight: 900}}/>
                            <Typography variant="" component="h4" sx={{pl: 1}}>-3.2%</Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem className="dash-top"/>
                <Grid item lg={3} sx={{py: 2, textAlign: 'center'}}>
                    <Grid container flexDirection="column" justifyContent="center" alignContent="center">
                        <Typography variant="h6" component="p" sx={{color: 'black'}}>Sales Forecast</Typography>
                        <Typography variant="" component="h1" sx={{color: blueGrey[900], lineHeight: '20px', fontSize: 30, fontWeight: 700 , }}>80,000</Typography>
                        <Stack justifyContent="center" direction="row" sx={{mt:1, width: '50%',background: ''}}>
                            <ArrowCircleUpIcon sx={{color: 'moccasins', fontWeight: 900}}/>
                            <Typography variant="" component="h4" sx={{pl: 1}}>+33.12%</Typography>
                        </Stack>
                    </Grid>
                </Grid>
                
                
            </Grid>

            {/* <Typography sx={{mt: 2, color: blueGrey[900]}}>Heading here</Typography> */}
            <Grid container sx={{my: 3}} spacing={1} >
                    
                    <Grid item lg={4} >
       
                        <Grid container  sx={{ background: blueGrey[300], border: '3px solid white', borderRadius: '10px'}}>

                            <Grid item lg={12} sx={{mb: 1}}>
                                <Grid container justifyContent="flex-start" alignItems="center" sx={{pl:3, py: 2}}>
                                    <BadgeIcon sx={{color: 'orange'}}/>
                                    <Typography variant="h5" style={{fontWeight: 700, fontSize: '2rem',color: blueGrey[100], padding: '0 30px', lineHeight: '10px'}}>Staff</Typography>
                                  
                                </Grid>
                            <Divider/>

                            </Grid>
                            <Grid item lg={12} sx={{height: '100%'}}>
                                <Grid container justifyContent="flex-start" sx={{background: ''}}>

                                    <Grid item lg={4} >
                                        <Grid container direction="column" alignItems="center" justifyContent="center">
                                            <Typography style={{color: blueGrey[700], fontWeight: 700, }}>All</Typography>
                                            <Typography style={{color: 'black', fontSize: '3rem', fontWeight: 800, }}>54</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item lg={4}>
                                        <Grid container direction="column" alignItems="center" justifyContent="center">
                                            <Typography style={{color: blueGrey[700], fontWeight: 700, }}>Management</Typography>
                                            <Typography style={{color: 'black', fontSize: '3rem', fontWeight: 800, }}>11</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item lg={4}>
                                        <Grid container direction="column" alignItems="center" justifyContent="center">
                                            <Typography style={{color: blueGrey[700], fontWeight: 700, }}>Remote</Typography>
                                            <Typography style={{color: 'black', fontSize: '3rem', fontWeight: 800, }}>7</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
       
                    </Grid>

                    <Grid item lg={4} >
                        <Grid container sx={{ background: blueGrey[400], border: '3px solid #eceff1', borderRadius: '10px'}}>

                            <Grid item lg={12} sx={{mb: 1}}>
                                <Grid container justifyContent="flex-start" alignItems="center" sx={{pl:3, py: 2}}>
                                    <BadgeIcon sx={{color: 'orange'}}/>
                                    <Typography variant="h5" style={{fontWeight: 700, fontSize: '2rem',color: blueGrey[100], padding: '0 30px', lineHeight: '10px'}}>New applicants</Typography>
                                
                                </Grid>
                            <Divider/>

                            </Grid>
                            <Grid item lg={12} sx={{height: '100%'}}>
                            <Grid container justifyContent="space-around" sx={{background: ''}}>

                                <Grid item lg={4} >
                                    <Grid container direction="column" alignItems="center" justifyContent="center">
                                        <Typography style={{color: blueGrey[700], fontWeight: 700, }}>Applicants</Typography>
                                        <Typography style={{color: 'black', fontSize: '3rem', fontWeight: 800, }}>1</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item lg={4}>
                                    <Grid container direction="column" alignItems="center" justifyContent="center">
                                        <Typography style={{color: blueGrey[700], fontWeight: 700, }}>Departments</Typography>
                                        <Typography style={{color: 'black', fontSize: '3rem', fontWeight: 800, }}>1</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item lg={4}>
                                    <Grid container direction="column" alignItems="center" justifyContent="center">
                                        <Typography style={{color: blueGrey[700], fontWeight: 700, }}>Roles</Typography>
                                        <Typography style={{color: 'black', fontSize: '3rem', fontWeight: 800, }}>7</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} >
                        <Grid container  sx={{ background: blueGrey[500], border: '3px solid #eceff1', borderRadius: '10px'}}>

                            <Grid item lg={12} sx={{mb: 1}}>
                                <Grid container justifyContent="flex-start" alignItems="center" sx={{pl: 3, py: 2}}>
                                    <FormatListNumberedIcon sx={{color: 'orange'}}/>
                                    <Typography variant="h5" style={{fontWeight: 700, fontSize: '2rem',color: blueGrey[200], padding: '0 30px', lineHeight: '10px'}}>Tasks running</Typography>
                                
                                </Grid>
                            <Divider/>

                            </Grid>
                            <Grid item lg={12} sx={{height: '100%'}}>
                            <Grid container justifyContent="space-around" sx={{background: ''}}>

                                <Grid item lg={4} >
                                    <Grid container direction="column" alignItems="center" justifyContent="center">
                                        <Typography style={{color: blueGrey[700], fontWeight: 700, }}>In progress</Typography>
                                        <Typography style={{color: 'black', fontSize: '3rem', fontWeight: 800, }}>12</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item lg={4}>
                                    <Grid container direction="column" alignItems="center" justifyContent="center">
                                        <Typography style={{color: blueGrey[700], fontWeight: 700, }}>Postponed</Typography>
                                        <Typography style={{color: 'black', fontSize: '3rem', fontWeight: 800, }}>3</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={4}>
                                    <Grid container direction="column" alignItems="center" justifyContent="center">
                                        <Typography style={{color: blueGrey[700], fontWeight: 700, }}>Completed</Typography>
                                        <Typography style={{color: 'black', fontSize: '3rem', fontWeight: 800, }}>3</Typography>
                                    </Grid>
                                </Grid>

                            
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>

            {/* ------------------------------- ------------- New applicants --------------------- ----------------------------- ---------------------*/}
            </Grid>

            

            
        </div>
    )
}

export default DashboardScreen
