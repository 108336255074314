import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo, purple } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import DivisionDialogs from '../../../Dialogs/CRM/Operations/DivisionDialogs/DivisionDialogs';

const DivisionsTbl = ({divisions}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    const getDivisionColor = (jr) => {
        return jr === 'Construction' ? lightGreen[500] : jr === 'Driving'?  pink[500] : jr === 'Aviation'? orange[500] : jr === 'Industrial'? lightBlue[500] : blueGrey[500]
    }

    const switchToViewDivision = (id) => {
        navigate('/division/'+ id)
    } 

    const divisionQuickView = (id) => {

        showDialog(true, 'view', id)
    }


 
    const columns = [
        { field: 'id', headerName: 'Id', minWidth: 100,  renderCell: (params) => <Typography onClick={()=> {divisionQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`DIV-${params.row.id}`}</Typography>},
        { field: 'Name', headerName: 'Division', minWidth: 180 , type: 'singleSelect', valueOptions: (['Driving', 'Construction', 'Aviation', 'Industrial', 'Human Resources']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getDivisionColor(params.row.Name), borderRadius: '30px', px: '10px'}}>{params.row.Name}</Typography>
        )},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewDivision(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];

    return (
        
        <>
            { dialog.open  ?  <DivisionDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={divisions}
                        loading={!divisions.length}
                    />
                </div>
            </div>
        </>
    )
}

export default DivisionsTbl
