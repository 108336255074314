import { WORKERSTATUSES_LIST_REQUEST, WORKERSTATUSES_LIST_FAIL, WORKERSTATUSES_LIST_SUCCESS} from '../Constants/workerStatusConstants';
import api from '../../config/apiConfig';


const getWorkerStatusesList = () => async(dispatch) => {
    //const data = assessmenttypes;
 
    try {
        dispatch({type: WORKERSTATUSES_LIST_REQUEST});
        const {data} = await api.get('/workerstatuses');
        //console.log('WorkerStatuses data is:' + data[0]['WorkerStatuses_Name'])
        dispatch({type: WORKERSTATUSES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: WORKERSTATUSES_LIST_FAIL, payload: error})
    }
} 

export {getWorkerStatusesList }