import { PAYMENTS_LIST_REQUEST, PAYMENTS_LIST_SUCCESS, PAYMENTS_LIST_FAIL, VIEW_PAYMENT_FAIL, VIEW_PAYMENT_REQUEST, VIEW_PAYMENT_SUCCESS, PAYMENT_CREATE_FAIL, PAYMENT_UPDATE_REQUEST, PAYMENT_UPDATE_FAIL, PAYMENT_DELETE_REQUEST, PAYMENT_DELETE_FAIL, PAYMENT_CREATE_REQUEST, PAYMENT_CREATE_SUCCESS, PAYMENT_UPDATE_SUCCESS, PAYMENT_DELETE_SUCCESS } from "../Constants/paymentConstants";

const paymentsListReducer = (state = {payments: []}, action) => {

    switch (action.type)
    {
        case PAYMENTS_LIST_REQUEST:
            return {loading: true};
 
        case PAYMENTS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and payments data is:' + action.payload)
            return {
                loading: false,
                payments: action.payload
            }
        case PAYMENTS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const paymentCreateReducer = (state = {payments: []}, action) => {

    switch (action.type) {

        case PAYMENT_CREATE_REQUEST:
            return {
                loading: true
            }

        case PAYMENT_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                payments: [...state.payments, {Applicant_Id: 2, Name: value}]
            };

        case PAYMENT_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const paymentViewReducer = (state= {payment: {}}, action) => {
    switch(action.type)
    {
        case VIEW_PAYMENT_REQUEST:
            return {loading: true};
        case VIEW_PAYMENT_SUCCESS:
            return {
                    loading: false,
                    payment: action.payload
                }
        case VIEW_PAYMENT_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const paymentUpdateReducer = (state = {payments: {}}, action) => {

    switch (action.type) {

        case PAYMENT_UPDATE_REQUEST:
            return {
                
            }
        case PAYMENT_UPDATE_SUCCESS:

            return {

            }
        case PAYMENT_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const paymentDeleteReducer = (state = {payments: {}}, action) => {

    switch (action.type) {
        case PAYMENT_DELETE_REQUEST:
            return {
                
            }
        case PAYMENT_DELETE_SUCCESS:
            return {

            }
        case PAYMENT_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {paymentsListReducer, paymentCreateReducer, paymentViewReducer, paymentUpdateReducer, paymentDeleteReducer }