import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControlLabel, Checkbox} from '@mui/material';
import { blueGrey, blue, purple, deepPurple, red } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import TaskIcon from '@mui/icons-material/Task';
import { useDispatch, useSelector } from 'react-redux';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getClientsList } from '../../../../../Redux/Actions/ClientsActions';
import { getTaskStatusesList } from '../../../../../Redux/Actions/TaskStatusActions';
import { getWorkers } from '../../../../../Redux/Actions/WorkerActions';
import { getTaskTypesList } from '../../../../../Redux/Actions/TaskTypeActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SideCrumbs from '../../../../Utilities/SideCrumbs';
import ComplexAlert from '../../../../ComplexAlert';
import useAlert from '../../../../../Hooks/useAlert';
import SummaryBox from '../../../../Utilities/SummaryBox';
import { submitTransaction } from '../../../../../Redux/Actions/TransactionActions';



const AddTransactionDialog = ({open, onClose, type, info, handleSubmit}) => {

    //ACCESS DISPATCH
    const dispatch = useDispatch();
    //GET TASKS
    const {tasks, loading: loading_tasks} = useSelector(state => state.tasks_List);
    const {divisions, loading} = useSelector(state => state.divisions_List);
    const {alert, showAlert}= useAlert();

   
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getStaffList());
        dispatch(getClientsList());
        dispatch(getTaskStatusesList());
        dispatch(getTaskTypesList());
        dispatch(getWorkers());
       
    }, [])
    
    

    const [values, setValues] = useState({
            isTransfer: '', 
            transaction_date: '', 
            account_name: '', 
            account_code: '', 
            transaction_type: '', 
            payer_payee: '', 
            description: '' , 
            vatRate: '' , 
            nettValue: '' , 
            vat: '', 
            total_amount: '', 
            bs_type: '', 
            depreciation: '', 
            todays_date: '', 
            currentValue: '', 
            transaction_status: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            isTransfer: '', 
            transaction_date: '', 
            account_name: '', 
            account_code: '', 
            transaction_type: '', 
            payer_payee: '', 
            description: '' , 
            vatRate: '' , 
            nettValue: '' , 
            vat: '', 
            total_amount: '', 
            bs_type: '', 
            depreciation: '', 
            todays_date: '', 
            currentValue: '', 
            transaction_status: '', 
        });
    
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitTransaction(values, submitValues))
        handleSubmit('success',"Successfully saved transaction information");

    }
    const [date, setDate] = useState({});
    const handleDateChange = (newValue, name) => {
        
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate({
            ...date,
            [name]: newValue
        });
        setValues({
            ...values,
            [name]: `${day}/${month+1}/${year}`
        })
        setSubmitValues({
            ...submitValues,
            [name]: `${day}/${month+1}/${year}`
        })
    }
    
    
    const [inputValue, setInputValue] = useState('');
    
    const handleInputChange = (newValue) => {
        
        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
 
    

   


    const animatedComponents = makeAnimated();


    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between',alignItems: 'center',fontSize: '1.3rem', background: blue[800], fontWeight: 900, color: 'white'}}  > 
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    <TaskIcon fontSize="large" sx={{color: blue[500], mr: 2, fontSize: '1.5rem'}}/> 
                    <Typography variant="h6" sx={{color: blue[500],fontWeight: 700, fontFamily: 'Poppins'}}> Add Transaction </Typography>
                </Stack>
            </DialogTitle>

        <Divider sx={{color: blue[500]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    {/* <p>name {submitValues.name}</p>
                    <p>group {submitValues.group}</p>
                    <p>worker {submitValues.worker}</p>
                    <p>staff {submitValues.staff}</p>
                    <p>startdate{submitValues.start}</p>
                    <p>endate {submitValues.end}</p>
                    <p>days_taken{submitValues.days_taken}</p>
                    <p>days_left {submitValues.days_left}</p>
                    <p>approved {submitValues.approved_by}</p>
                    <p>transaction_status {submitValues.transaction_status}</p> */}
                    <SideCrumbs/>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label w-100">is Transfer?</Form.Label>
                                <FormControlLabel control={<Checkbox name="isTransfer" value="Yes" checked={values.isTransfer === 'Yes'? true : null} onChange={handleChange} />} label="Yes" />
                                <FormControlLabel control={<Checkbox name="isTransfer" value="No" checked={values.isTransfer === 'No'? true : null} onChange={handleChange} />} label="No" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="transaction_date"
                                            label="Transaction Date"
                                            value={date.transaction_date}
                                            onChange={(e) => handleDateChange(e, "transaction_date")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="todays_date"
                                            label="Today's Date"
                                            value={date.todays_date}
                                            onChange={(e) => handleDateChange(e, "todays_date")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>
                        </Row>
                    
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Description</Form.Label>
                                <Form.Control type="text" as="textarea" name="description" value={values.description} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Account Name</Form.Label>
                                <Form.Control type="text" name="account_name" value={values.account_name} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Account Code</Form.Label>
                                <Form.Control type="text" name="account_code" value={values.account_code} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>


                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: deepPurple[500], borderRadius: '5px', px: 2, py: '5px'}}>Items</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Transaction Type</Form.Label>
                                    <AsyncSelect
                                        value={values.transaction_type}
                                        name="transaction_type"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'transactiontypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Balance Sheet Type</Form.Label>
                                    <AsyncSelect
                                        value={values.bs_type}
                                        name="bs_type"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'balancesheettypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>
                        <Row>
                           
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Payer/Payee</Form.Label>
                                    <AsyncSelect
                                        value={values.payer_payee}
                                        name="payer_payee"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'payerpayees')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for payer/payee ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">VAT Rate</Form.Label>
                                    <AsyncSelect
                                        value={values.vatRate}
                                        name="vatRate"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'vatrates')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for rate ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Nett Value</Form.Label>
                                <Form.Control type="text" name="nettValue" value={values.nettValue} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">VAT</Form.Label>
                                <Form.Control type="text" name="vat" value={values.vat} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Total Amount</Form.Label>
                                <Form.Control type="text" name="total_amount" value={values.total_amount} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Depreciation</Form.Label>
                                <Form.Control type="text" name="depreciation" value={values.depreciation} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Current Value</Form.Label>
                                <Form.Control type="text" name="currentValue" value={values.currentValue} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>



                      
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label w-100">Status</Form.Label>
                                <FormControlLabel control={<Checkbox name="transaction_status" value="Reconciled" checked={values.transaction_status === 'Reconciled'? true : null} onChange={handleChange} />} label="Reconciled" />
                                <FormControlLabel control={<Checkbox name="transaction_status" value="Make Changes" checked={values.transaction_status === 'Make Changes'? true : null} onChange={handleChange} />} label="Make Changes" />
                            </Form.Group>
                        </Row>
                          
                        </Form>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[700], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Transaction</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddTransactionDialog
