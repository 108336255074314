import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControlLabel, Checkbox} from '@mui/material';
import { blueGrey, blue, purple, deepPurple, red } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import TaskIcon from '@mui/icons-material/Task';
import { useDispatch, useSelector } from 'react-redux';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getClientsList } from '../../../../../Redux/Actions/ClientsActions';
import { getTaskStatusesList } from '../../../../../Redux/Actions/TaskStatusActions';
import { getWorkers } from '../../../../../Redux/Actions/WorkerActions';
import { getTaskTypesList } from '../../../../../Redux/Actions/TaskTypeActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SideCrumbs from '../../../../Utilities/SideCrumbs';
import ComplexAlert from '../../../../ComplexAlert';
import useAlert from '../../../../../Hooks/useAlert';
import SummaryBox from '../../../../Utilities/SummaryBox';
import { submitVATReturn } from '../../../../../Redux/Actions/VATReturnActions';
import { submitPayerPayee } from '../../../../../Redux/Actions/PayerPayeeActions';



const AddPayerPayeeDialog = ({open, onClose, type, info, handleSubmit}) => {

    //ACCESS DISPATCH
    const dispatch = useDispatch();
    //GET TASKS
    const {tasks, loading: loading_tasks} = useSelector(state => state.tasks_List);
    const {divisions, loading} = useSelector(state => state.divisions_List);
    const {alert, showAlert}= useAlert();

   
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getStaffList());
        dispatch(getClientsList());
        dispatch(getTaskStatusesList());
        dispatch(getTaskTypesList());
        dispatch(getWorkers());
       
    }, [])
    
    

    const [values, setValues] = useState({
            pp_type: '', 
            fname: '', 
            lname: '', 
            email: '', 
            phone: '', 
            pp_status: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            pp_type: '', 
            fname: '', 
            lname: '', 
            email: '', 
            phone: '', 
            pp_status: '', 
        });
    
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitPayerPayee(values, submitValues))
        handleSubmit('success',"Successfully saved Payer/Payee information");

    }

    const [inputValue, setInputValue] = useState('');
    
    const handleInputChange = (newValue) => {
        
        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)

    const animatedComponents = makeAnimated();


    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between',alignItems: 'center',fontSize: '1.3rem', background: blue[800], fontWeight: 900, color: 'white'}}  > 
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    <TaskIcon fontSize="large" sx={{color: blue[500], mr: 2, fontSize: '1.5rem'}}/> 
                    <Typography variant="h6" sx={{color: blue[500],fontWeight: 700, fontFamily: 'Poppins'}}> Add Payer/Payee </Typography>
                </Stack>
            </DialogTitle>

        <Divider sx={{color: blue[500]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    {/* <p>pp_status {values.pp_status}</p> */}
                    <SideCrumbs/>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                       
                    
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Payer/Payee Type</Form.Label>
                                    <AsyncSelect
                                        value={values.pp_type}
                                        name="pp_type"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'payerpayeetypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">First Name</Form.Label>
                                <Form.Control type="text"  name="fname" value={values.fname} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Surname</Form.Label>
                                <Form.Control type="text"  name="lname" value={values.lname} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <Divider sx={{my: 2, color: blue[500]}}/>
                        
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Email</Form.Label>
                                <Form.Control type="text" name="email" value={values.email} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Phone</Form.Label>
                                <Form.Control type="number" name="phone" value={values.phone} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: deepPurple[500], borderRadius: '5px', px: 2, py: '5px'}}>Items</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label w-100">Payer/Payee Status</Form.Label>
                                <FormControlLabel control={<Checkbox name="pp_status" value="Active" checked={values.pp_status === 'Active'? true : null} onChange={handleChange} />} label="Active" />
                                <FormControlLabel control={<Checkbox name="pp_status" value="Inactive" checked={values.pp_status === 'Inactive'? true : null} onChange={handleChange} />} label="Inactive" />
                            </Form.Group>
                        </Row>
                          
                        </Form>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[700], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Payer/Payee</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddPayerPayeeDialog
