import { WORKERSHIFTSTATUSES_LIST_REQUEST, WORKERSHIFTSTATUSES_LIST_SUCCESS, WORKERSHIFTSTATUSES_LIST_FAIL, VIEW_WORKERSHIFTSTATUS_FAIL, VIEW_WORKERSHIFTSTATUS_REQUEST, VIEW_WORKERSHIFTSTATUS_SUCCESS, WORKERSHIFTSTATUS_CREATE_FAIL, WORKERSHIFTSTATUS_UPDATE_REQUEST, WORKERSHIFTSTATUS_UPDATE_FAIL, WORKERSHIFTSTATUS_DELETE_REQUEST, WORKERSHIFTSTATUS_DELETE_FAIL, WORKERSHIFTSTATUS_CREATE_REQUEST, WORKERSHIFTSTATUS_CREATE_SUCCESS, WORKERSHIFTSTATUS_UPDATE_SUCCESS, WORKERSHIFTSTATUS_DELETE_SUCCESS } from "../Constants/workerShiftStatusConstants";

const workerShiftStatusesListReducer = (state = {workerShiftStatuses: []}, action) => {

    switch (action.type)
    {
        case WORKERSHIFTSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case WORKERSHIFTSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and workerShiftStatuss data is:' + action.payload)
            return {
                loading: false,
                workerShiftStatuses: action.payload
            }
        case WORKERSHIFTSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const workerShiftStatusCreateReducer = (state = {workerShiftStatuses: []}, action) => {

    switch (action.type) {

        case WORKERSHIFTSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case WORKERSHIFTSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                workerShiftStatuses: [...state.workerShiftStatuses, {Applicant_Id: 2, Name: value}]
            };

        case WORKERSHIFTSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const workerShiftStatusViewReducer = (state= {workerShiftStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_WORKERSHIFTSTATUS_REQUEST:
            return {loading: true};
        case VIEW_WORKERSHIFTSTATUS_SUCCESS:
            return {
                    loading: false,
                    workerShiftStatus: action.payload
                }
        case VIEW_WORKERSHIFTSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const workerShiftStatusUpdateReducer = (state = {workerShiftStatuss: {}}, action) => {

    switch (action.type) {

        case WORKERSHIFTSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case WORKERSHIFTSTATUS_UPDATE_SUCCESS:

            return {

            }
        case WORKERSHIFTSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const workerShiftStatusDeleteReducer = (state = {workerShiftStatuss: {}}, action) => {

    switch (action.type) {
        case WORKERSHIFTSTATUS_DELETE_REQUEST:
            return {
                
            }
        case WORKERSHIFTSTATUS_DELETE_SUCCESS:
            return {

            }
        case WORKERSHIFTSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {workerShiftStatusesListReducer, workerShiftStatusCreateReducer, workerShiftStatusViewReducer, workerShiftStatusUpdateReducer, workerShiftStatusDeleteReducer }