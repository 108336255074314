import React from 'react'
import { Paper, Grid, Typography } from '@mui/material';
import { blue, blueGrey, orange } from '@mui/material/colors';

const TimesheetListItem = ({no, shift, date, division, status, role, sum, other}) => {
    return (
        <>
           <Paper sx={{mt: 1,px: 0, py: 0, background: 'rgba(227, 242, 253, .3)', boxShadow: 'none', border: '1px solid whitesmoke', '&:hover': {background: orange[50]}}}> 
                <Grid container justifyContent="flex-start" sx={{my: 1}} >
                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', width: '50px',  px: 0, mr: 2, height: '120%', width: '50px', textAlign: 'center' ,background: 'blue[500]', color:  blue[600]}}>{no}</Typography>
                    <Typography sx={{fontSize: 13 ,fontWeight: 600, fontFamily: 'Poppins-Regular', width: '300px', px: 2, mr: 2, color:  blueGrey[600], borderRight: '1px solid #bbdefb'}}>{shift}</Typography>
                    <Typography sx={{fontSize: 13 ,fontWeight: 600, fontFamily: 'Poppins-Regular', width: '150px', pl: 1, mr: 2, color:  blueGrey[600], borderRight: '1px solid #bbdefb'}}>{division}</Typography>
                    <Typography sx={{fontSize: 13 ,fontWeight: 600, fontFamily: 'Poppins-Regular', width: '150px', pl: 1, mr: 2, color:  blueGrey[600], borderRight: '1px solid #bbdefb'}}>{role}</Typography>
                    <Typography sx={{fontSize: 13 ,fontWeight: 600, fontFamily: 'Poppins-Regular', width: '150px', pl: 1, mr: 2, color:  orange[500],   borderRight: '1px solid #bbdefb'}}>{status}</Typography>
                    <Typography sx={{fontSize: 13 ,fontWeight: 600, fontFamily: 'Poppins-Regular', width: '150px', pl: 1, mr: 2, color:  blueGrey[600], borderRight: '1px solid #bbdefb'}}>{120}</Typography>
                </Grid>
            </Paper> 
        </>
    )
}

export default TimesheetListItem
