export const PROFITLOSS_LIST_REQUEST = 'PROFITLOSS_LIST_REQUEST'
export const PROFITLOSS_LIST_SUCCESS = 'PROFITLOSS_LIST_SUCCESS'
export const PROFITLOSS_LIST_FAIL = 'PROFITLOSS_LIST_FAIL'


export const PROFITLOSS_CREATE_REQUEST = 'PROFITLOSS_CREATE_REQUEST'
export const PROFITLOSS_CREATE_SUCCESS = 'PROFITLOSS_CREATE_SUCCESS'
export const PROFITLOSS_CREATE_FAIL = 'PROFITLOSS_CREATE_FAIL'


export const PROFITLOSS_UPDATE_REQUEST = 'PROFITLOSS_UPDATE_REQUEST'
export const PROFITLOSS_UPDATE_SUCCESS = 'PROFITLOSS_UPDATE_SUCCESS'
export const PROFITLOSS_UPDATE_FAIL = 'PROFITLOSS_UPDATE_FAIL'

export const PROFITLOSS_DELETE_REQUEST = 'PROFITLOSS_DELETE_REQUEST'
export const PROFITLOSS_DELETE_SUCCESS = 'PROFITLOSS_DELETE_SUCCESS'
export const PROFITLOSS_DELETE_FAIL = 'PROFITLOSS_DELETE_FAIL'

export const VIEW_PROFITLOSS_REQUEST = 'VIEW_PROFITLOSS_REQUEST'
export const VIEW_PROFITLOSS_SUCCESS = 'VIEW_PROFITLOSS_SUCCESS'
export const VIEW_PROFITLOSS_FAIL = 'VIEW_PROFITLOSS_FAIL'