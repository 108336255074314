export const LINKEDUMBRELLAS_LIST_REQUEST = 'LINKEDUMBRELLAS_LIST_REQUEST'
export const LINKEDUMBRELLAS_LIST_SUCCESS = 'LINKEDUMBRELLAS_LIST_SUCCESS'
export const LINKEDUMBRELLAS_LIST_FAIL = 'LINKEDUMBRELLAS_LIST_FAIL'

export const LINKEDUMBRELLA_CREATE_REQUEST = 'LINKEDUMBRELLA_CREATE_REQUEST'
export const LINKEDUMBRELLA_CREATE_SUCCESS = 'LINKEDUMBRELLA_CREATE_SUCCESS'
export const LINKEDUMBRELLA_CREATE_FAIL = 'LINKEDUMBRELLA_CREATE_FAIL'

export const LINKEDUMBRELLA_UPDATE_REQUEST = 'LINKEDUMBRELLA_UPDATE_REQUEST'
export const LINKEDUMBRELLA_UPDATE_SUCCESS = 'LINKEDUMBRELLA_UPDATE_SUCCESS'
export const LINKEDUMBRELLA_UPDATE_FAIL = 'LINKEDUMBRELLA_UPDATE_FAIL'

export const LINKEDUMBRELLA_DELETE_REQUEST = 'LINKEDUMBRELLA_DELETE_REQUEST'
export const LINKEDUMBRELLA_DELETE_SUCCESS = 'LINKEDUMBRELLA_DELETE_SUCCESS'
export const LINKEDUMBRELLA_DELETE_FAIL = 'LINKEDUMBRELLA_DELETE_FAIL'

export const VIEW_LINKEDUMBRELLA_REQUEST = 'VIEW_LINKEDUMBRELLA_REQUEST'
export const VIEW_LINKEDUMBRELLA_SUCCESS = 'VIEW_LINKEDUMBRELLA_SUCCESS'
export const VIEW_LINKEDUMBRELLA_FAIL = 'VIEW_LINKEDUMBRELLA_FAIL'