import { OPPORTUNITYTYPES_LIST_REQUEST, OPPORTUNITYTYPES_LIST_SUCCESS, OPPORTUNITYTYPES_LIST_FAIL, VIEW_OPPORTUNITYTYPE_FAIL, VIEW_OPPORTUNITYTYPE_REQUEST, VIEW_OPPORTUNITYTYPE_SUCCESS, OPPORTUNITYTYPE_CREATE_FAIL, OPPORTUNITYTYPE_UPDATE_REQUEST, OPPORTUNITYTYPE_UPDATE_FAIL, OPPORTUNITYTYPE_DELETE_REQUEST, OPPORTUNITYTYPE_DELETE_FAIL, OPPORTUNITYTYPE_CREATE_REQUEST, OPPORTUNITYTYPE_CREATE_SUCCESS, OPPORTUNITYTYPE_UPDATE_SUCCESS, OPPORTUNITYTYPE_DELETE_SUCCESS } from "../Constants/opportunityTypeConstants";

const opportunityTypesListReducer = (state = {opportunityTypes: []}, action) => {

    switch (action.type)
    {
        case OPPORTUNITYTYPES_LIST_REQUEST:
            return {loading: true};
 
        case OPPORTUNITYTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and opportunityTypes data is:' + action.payload)
            return {
                loading: false,
                opportunityTypes: action.payload
            }
        case OPPORTUNITYTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const opportunityTypeCreateReducer = (state = {opportunityTypes: []}, action) => {

    switch (action.type) {

        case OPPORTUNITYTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case OPPORTUNITYTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                opportunityTypes: [...state.opportunityTypes, {Applicant_Id: 2, Name: value}]
            };

        case OPPORTUNITYTYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const opportunityTypeViewReducer = (state= {opportunityType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_OPPORTUNITYTYPE_REQUEST:
            return {loading: true};
        case VIEW_OPPORTUNITYTYPE_SUCCESS:
            return {
                    loading: false,
                    opportunityType: action.payload
                }
        case VIEW_OPPORTUNITYTYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const opportunityTypeUpdateReducer = (state = {opportunityTypes: {}}, action) => {

    switch (action.type) {

        case OPPORTUNITYTYPE_UPDATE_REQUEST:
            return {
                
            }
        case OPPORTUNITYTYPE_UPDATE_SUCCESS:

            return {

            }
        case OPPORTUNITYTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const opportunityTypeDeleteReducer = (state = {opportunityTypes: {}}, action) => {

    switch (action.type) {
        case OPPORTUNITYTYPE_DELETE_REQUEST:
            return {
                
            }
        case OPPORTUNITYTYPE_DELETE_SUCCESS:
            return {

            }
        case OPPORTUNITYTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {opportunityTypesListReducer, opportunityTypeCreateReducer, opportunityTypeViewReducer, opportunityTypeUpdateReducer, opportunityTypeDeleteReducer }