import React, {useState} from 'react';
import AddApplicantDialog from './AddApplicantDialog';
import ViewApplicantDialog from './ViewApplicantDialog';
import EditApplicantDialog from './EditApplicantDialog';
import DeleteApplicantDialog from './DeleteApplicantDialog';



const ApplicantDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return ( 
        <> 
        { 
            type === "create"?

            <AddApplicantDialog open={open} onClose={onClose} handleSubmit={handleSubmit} type={type} info={info} fullWidth/>
            
            : type === "view"?

            <ViewApplicantDialog open={open} onClose={onClose} type={type} info={info} fullWidth/>
            
            : type === "edit"?

            <EditApplicantDialog open={open} onClose={onClose} type={type} info={info} fullWidth/>
            
            : type === "delete"?

            <DeleteApplicantDialog open={open} onClose={onClose} type={type} info={info} fullWidth/>
            // <ViewAssessmentDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default ApplicantDialogs
