import React from 'react';
import { Link as BreadcrumbLink, Stack } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const DialogNavigation = () => {
    return (
        <>
            <Stack sx={{mb: 3, background: '', height: '300px', borderRadius: '5px', width: '80%', pl: 3, pt: 3}}>
                <BreadcrumbLink underline="none" sx={{color: blueGrey[300]}} fontSize="small" href="#basic">Basic Info</BreadcrumbLink>
                <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#specifics">Applicant Specifics</BreadcrumbLink>
                <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#status">Applicant Status</BreadcrumbLink>
            </Stack>
        </>
    )
}

export default DialogNavigation
