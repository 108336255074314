export const PAYROLLDOCTYPES_LIST_REQUEST = 'PAYROLLDOCTYPES_LIST_REQUEST'
export const PAYROLLDOCTYPES_LIST_SUCCESS = 'PAYROLLDOCTYPES_LIST_SUCCESS'
export const PAYROLLDOCTYPES_LIST_FAIL = 'PAYROLLDOCTYPES_LIST_FAIL'


export const PAYROLLDOCTYPE_CREATE_REQUEST = 'PAYROLLDOCTYPE_CREATE_REQUEST'
export const PAYROLLDOCTYPE_CREATE_SUCCESS = 'PAYROLLDOCTYPE_CREATE_SUCCESS'
export const PAYROLLDOCTYPE_CREATE_FAIL = 'PAYROLLDOCTYPE_CREATE_FAIL'


export const PAYROLLDOCTYPE_UPDATE_REQUEST = 'PAYROLLDOCTYPE_UPDATE_REQUEST'
export const PAYROLLDOCTYPE_UPDATE_SUCCESS = 'PAYROLLDOCTYPE_UPDATE_SUCCESS'
export const PAYROLLDOCTYPE_UPDATE_FAIL = 'PAYROLLDOCTYPE_UPDATE_FAIL'

export const PAYROLLDOCTYPE_DELETE_REQUEST = 'PAYROLLDOCTYPE_DELETE_REQUEST'
export const PAYROLLDOCTYPE_DELETE_SUCCESS = 'PAYROLLDOCTYPE_DELETE_SUCCESS'
export const PAYROLLDOCTYPE_DELETE_FAIL = 'PAYROLLDOCTYPE_DELETE_FAIL'

export const VIEW_PAYROLLDOCTYPE_REQUEST = 'VIEW_PAYROLLDOCTYPE_REQUEST'
export const VIEW_PAYROLLDOCTYPE_SUCCESS = 'VIEW_PAYROLLDOCTYPE_SUCCESS'
export const VIEW_PAYROLLDOCTYPE_FAIL = 'VIEW_PAYROLLDOCTYPE_FAIL'