import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, Breadcrumbs, TextField} from '@mui/material';
import { blueGrey, pink, lightBlue, lightGreen } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useDispatch, useSelector } from 'react-redux';
import {  submitRequest } from '../../../../../Redux/Actions/RequestActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { Link as BreadcrumbLink } from '@mui/material';
import { getDepartmentsList } from '../../../../../Redux/Actions/DepartmentActions';
import { getBranchesList } from '../../../../../Redux/Actions/BranchActions';
import { getRateCardsList } from '../../../../../Redux/Actions/RateCardActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SummaryBox from '../../../../Utilities/SummaryBox';
import SideCrumbs from '../../../../Utilities/SideCrumbs';
import useDate from '../../../../../Hooks/useDate';


const AddRequestDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET BRANCHES
    const {branches, loading: loading_branches} = useSelector(state => state.branches_List);
    //GET DEPARTMENTS
    const {departments, loading} = useSelector(state => state.departments_List);
    //GET DEPARTMENTS
    const {rateCards, loading: loading_cards} = useSelector(state => state.rateCards_List);
    //GET JOB ROLES
    const {loading: loading_roles, jobRoles}  = useSelector((state) => state.jobRoles_List);
    //GET OFFICE STAFF AS CONSULTANT
    const {staff, loading: loading_staff} = useSelector(state => state.staff_List);


    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getJobRolesList());
        dispatch(getDepartmentsList());
        dispatch(getBranchesList());
        dispatch(getRateCardsList());
       
    }, [])
    

    const [values, setValues] = useState({
            name: '',
            clients: '',
            requesttypes: '',
            requeststatuses: '',
            divisions: '',
            jobroles: '',
            number: '',
            notes: '',
            start: '',
            end: '',
        });

    const [submitValues, setSubmitValues] = useState({
            name: '',
            clients: '',
            requesttypes: '',
            requeststatuses: '',
            divisions: '',
            jobroles: '',
            number: '',
            notes: '',
            start: '',
            end: '',
        });   
        
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitRequest(values, submitValues))
        handleSubmit('success',"Successfully saved request information");
        
    }
    
    const animatedComponents = makeAnimated();
    
    const [inputValue, setInputValue] = useState('');
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
    
    const [date, setDate] = useState({
        start: '',
        end: '',
    });


    const handleDateChange = (newValue, name) => {
        
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate({
            ...date,
            [name]: newValue
        });
        setValues({
            ...values,
            [name]: `${day}/${month+1}/${year}`
        })
        setSubmitValues({
            ...submitValues,
            [name]: `${day}/${month+1}/${year}`
        })
    }


    
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }


    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: lightBlue[500]}}  >
                
                <EngineeringIcon fontSize="large" sx={{color: lightBlue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: lightBlue[200],fontWeight: 700}}> Add Request </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    {/* <p>name {submitValues.name}</p>
                    <p>clients {submitValues.clients}</p>
                    <p>types {submitValues.requesttypes}</p>
                    <p>status {submitValues.requeststatuses}</p>
                    <p>division{submitValues.divisions}</p>
                    <p>roles {submitValues.jobroles}</p>
                    <p> number{submitValues.number}</p>
                    <p>notes {submitValues.notes}</p>
                    <p>start {submitValues.start}</p>
                    <p>end {submitValues.end}</p> */}
                    
                    <SideCrumbs/>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Request ID</Form.Label>
                                <Form.Control disabled type="email" value="RQT-3" placeholder="E.g 123qst1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Name</Form.Label>
                                <Form.Control type="email" name="name" value={values.firstname} onChange={handleChange} placeholder="Enter name" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Request Type</Form.Label>
                                    <AsyncSelect
                                        value={values.requesttypes}
                                        name="requesttypes"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'requesttypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                        <br/>
                        <Typography id="specifics" variant="body2" sx={{display: 'inline',color: 'white',background: lightBlue[500], borderRadius: '5px', px: 2, py: '5px'}}>Some Specifics</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Client</Form.Label>
                                    <AsyncSelect
                                        value={values.clients}
                                        name="clients"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'clients')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for client ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Division</Form.Label>
                                    <AsyncSelect
                                        value={values.divisions}
                                        name="divisions"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'divisions')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for division ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Position Required</Form.Label>
                                        <AsyncSelect
                                            value={values.jobroles}
                                            name="jobroles"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'jobroles')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for position ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>
                        </Row>
                        
                        <br/>
                        <Typography id="staff" variant="body2" sx={{display: 'inline',color: 'white',background: pink[400], borderRadius: '5px', px: 2, py: '5px'}}>Specifics</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label"># of workers needed</Form.Label>
                                <Form.Control name="number" type="number" value={values.number} onChange={handleChange} placeholder="E.g 12" className="outline-none border-none" />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="start"
                                            label="Worker's Start Date"
                                            value={date.start}
                                            onChange={(e) => handleDateChange(e, "start")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="end"
                                            label="Worker's End Date"
                                            value={date.end}
                                            onChange={(e) => handleDateChange(e, "end")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>
                        </Row>


                        <br/>
                        <Typography id="staff" variant="body2" sx={{display: 'inline',color: 'white',background: lightGreen[500], borderRadius: '5px', px: 2, py: '5px'}}>Consultant</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Request Status</Form.Label>
                                   <AsyncSelect
                                        value={values.requeststatuses}
                                        name="requeststatuses"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'requeststatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Notes</Form.Label>
                                <Form.Control name="notes" type="text" onChange={handleChange} as="textarea" value={values.notes} placeholder="Notes ..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        </Form>
                    </Grid>

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: lightBlue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: lightBlue[800], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Request</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddRequestDialog
