import React from 'react'
import PageHeader from '../../../Components/PageHeader';

const AdminScreen = () => {
    return (
        <div>
            <PageHeader page="My Admin Page"/>
        </div>
    )
}

export default AdminScreen
