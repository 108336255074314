export const AVAILABILITYSTATUSES_LIST_REQUEST = 'AVAILABILITYSTATUSES_LIST_REQUEST'
export const AVAILABILITYSTATUSES_LIST_SUCCESS = 'AVAILABILITYSTATUSES_LIST_SUCCESS'
export const AVAILABILITYSTATUSES_LIST_FAIL = 'AVAILABILITYSTATUSES_LIST_FAIL'


export const AVAILABILITYSTATUS_CREATE_REQUEST = 'AVAILABILITYSTATUS_CREATE_REQUEST'
export const AVAILABILITYSTATUS_CREATE_SUCCESS = 'AVAILABILITYSTATUS_CREATE_SUCCESS'
export const AVAILABILITYSTATUS_CREATE_FAIL = 'AVAILABILITYSTATUS_CREATE_FAIL'


export const AVAILABILITYSTATUS_UPDATE_REQUEST = 'AVAILABILITYSTATUS_UPDATE_REQUEST'
export const AVAILABILITYSTATUS_UPDATE_SUCCESS = 'AVAILABILITYSTATUS_UPDATE_SUCCESS'
export const AVAILABILITYSTATUS_UPDATE_FAIL = 'AVAILABILITYSTATUS_UPDATE_FAIL'

export const AVAILABILITYSTATUS_DELETE_REQUEST = 'AVAILABILITYSTATUS_DELETE_REQUEST'
export const AVAILABILITYSTATUS_DELETE_SUCCESS = 'AVAILABILITYSTATUS_DELETE_SUCCESS'
export const AVAILABILITYSTATUS_DELETE_FAIL = 'AVAILABILITYSTATUS_DELETE_FAIL'

export const VIEW_AVAILABILITYSTATUS_REQUEST = 'VIEW_AVAILABILITYSTATUS_REQUEST'
export const VIEW_AVAILABILITYSTATUS_SUCCESS = 'VIEW_AVAILABILITYSTATUS_SUCCESS'
export const VIEW_AVAILABILITYSTATUS_FAIL = 'VIEW_AVAILABILITYSTATUS_FAIL'