export const RECURRINGS_LIST_REQUEST = 'RECURRINGS_LIST_REQUEST'
export const RECURRINGS_LIST_SUCCESS = 'RECURRINGS_LIST_SUCCESS'
export const RECURRINGS_LIST_FAIL = 'RECURRINGS_LIST_FAIL'


export const RECURRING_CREATE_REQUEST = 'RECURRING_CREATE_REQUEST'
export const RECURRING_CREATE_SUCCESS = 'RECURRING_CREATE_SUCCESS'
export const RECURRING_CREATE_FAIL = 'RECURRING_CREATE_FAIL'


export const RECURRING_UPDATE_REQUEST = 'RECURRING_UPDATE_REQUEST'
export const RECURRING_UPDATE_SUCCESS = 'RECURRING_UPDATE_SUCCESS'
export const RECURRING_UPDATE_FAIL = 'RECURRING_UPDATE_FAIL'

export const RECURRING_DELETE_REQUEST = 'RECURRING_DELETE_REQUEST'
export const RECURRING_DELETE_SUCCESS = 'RECURRING_DELETE_SUCCESS'
export const RECURRING_DELETE_FAIL = 'RECURRING_DELETE_FAIL'

export const VIEW_RECURRING_REQUEST = 'VIEW_RECURRING_REQUEST'
export const VIEW_RECURRING_SUCCESS = 'VIEW_RECURRING_SUCCESS'
export const VIEW_RECURRING_FAIL = 'VIEW_RECURRING_FAIL'