import { APPLICANTSTATUS_LIST_REQUEST, APPLICANTSTATUS_LIST_SUCCESS, APPLICANTSTATUS_LIST_FAIL} from "../Constants/applicantStatusesConstants";

const applicantStatusesListReducer = (state = {applicantStatuses: []}, action) => {
 
    switch (action.type)
    {
        case APPLICANTSTATUS_LIST_REQUEST:
            return {loading: true};
 
        case APPLICANTSTATUS_LIST_SUCCESS:
            console.log('Code is reaching the reducer and applicantStatuses data is:' + (action.payload)[0].Statu_esId)
            return {
                loading: false,
                applicantStatuses: action.payload
            }
        case APPLICANTSTATUS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 



export {applicantStatusesListReducer}