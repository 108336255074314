import React, {useState} from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
import {Route, Routes, Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ComplexLogo from '../../assets/ComplexLogo.png'
import AccountMenu from '../AccountMenu';
import NavData from '../../Data/NavData';
import AppRoutes from '../../Routes/AppRoutes';
import { blueGrey } from '@material-ui/core/colors';
import { Typography } from '@mui/material';
import NavItem from '../Sitemap/NavItem';
import DashboardScreen from '../../Screens/CRM/Dashboard/DashboardScreen';

 
 
 
const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: `1px solid #cfd8dc`,
  background: 'white',
  // background: 'rgb(246, 247, 248)',
  //background: '#eceff1',
  //boxShadow: '2px 0 15px #cfd8dc',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({

  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 0),
  // width: '310px',
  // position: 'absolute',
  // zIndex: 20,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  //background: 'rgb(246, 247, 248)',
  backgroundColor: blueGrey[900],
  height: '63px',
  
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    // backgroundColor: 'orange !important',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



export default function MiniDrawer({screens}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [showHeading, setShowHeading] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
    setShowHeading(!showHeading);
  }

  const screens_container = <><Route path="/dashboard" component={DashboardScreen}/></>

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    background: 'pink',
    // backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'white',
    // color: blueGrey[900],
    background: blueGrey[900],
    // background: 'rgb(246, 247, 248)',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));
  
  return (
    <>
    <Box sx={{ display: 'flex'}}>
      <CssBaseline />

      <AppBar position="fixed" open={open} sx={{
        boxShadow: '0.6px 0.6px 5px black', 
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
        //borderRight: '7px solid red',
        // boxShadow: '0 2px 8px rgb(196, 196, 196)',
        //borderRadius: '0 0 0 20px'
        }}>
       
       <Box sx={{
      
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ml: 4,
        
        }}
       >
          <IconButton
                aria-label="open drawer"
                // onClick={handleDrawerOpen}
                onClick={handleDrawerToggle}
                sx={{ color: blueGrey[200], background: blueGrey[800], marginLeft: '',...(open && { display: '',}),}}
                // sx={{ color: blueGrey[700], background: blueGrey[50], marginLeft: '',...(open && { display: '',}),}}
              >
                <MenuIcon />

          </IconButton>

          <Search>
            <SearchIconWrapper>
              <SearchIcon sx={{position: 'relative', zIndex: '100', color: blueGrey[50]}} />
              {/* <SearchIcon sx={{position: 'relative', zIndex: '100', color: blueGrey[900]}} /> */}
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search', color: 'white' }}
            />
          </Search>
        </Box>


        <AccountMenu/>

      </AppBar>

      <Drawer variant="permanent" open={open} sx={{
        borderRight: 0,

      }}>

        <Box component="div" style={{height: '70px', minHeight: '50px',maxHeight: '63px', backgroundColor: blueGrey[900], m: 0 , p: 0, position: 'fixed', zIndex: 50, boxShadow: '0.6px 0.6px 5px black', }}>
          <div className="company-logo">
              <img src={ComplexLogo} alt="Complex Recruitment" />
          </div>
   
        </Box>


 
        {/* <Divider /> */}


        <List 
                component="nav"
                sx={{background: '',display: 'flex',alignItems:'center',justifyContent: 'flex-start',flexDirection:'column',minWidth:230,maxWidth:230,width:230,ml: 3, p: 0, m: 0, mt: '100px', textAlign: 'center'}}
                //subheader={<ListSubheader>Subheader here</ListSubheader>}
            >
                {
                    NavData.map(item => <NavItem key={item.id} item={item} drawerStatus={open}/>)
                }
            
                
            </List>

      </Drawer> 


      <Box component="main" sx={{ flexGrow: 1, p: 3, borderRadius: '10px',border: 0, margin: '4% 0px', background: 'rgba(242, 247, 252, .5)'}} className="bbb">
        {/* <DrawerHeader /> */}
       
       {/* Box shadow was 1 1 8 gray rgba(207, 216, 220, .1) */}

        <Box sx={{ boxShadow: '0px 0px 0px gray', flexGrow: 1, py: 3, px: 4, border: '3px solid none', borderRadius: '10px', height: '100%', background: ''}}>
          
          <AppRoutes/>
          
         
        </Box>
      </Box>
    </Box>
      <footer>
        <Typography variant="body1" component="p" sx={{color: 'white', textAlign: 'center', mr: 0, pt: 2}}>Seamless Systems (Pvt) Ltd.</Typography>
      </footer>
    </>
  );
}