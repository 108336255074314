export const WOMS_LIST_REQUEST = 'WOMS_LIST_REQUEST'
export const WOMS_LIST_SUCCESS = 'WOMS_LIST_SUCCESS'
export const WOMS_LIST_FAIL = 'WOMS_LIST_FAIL'


export const WOM_CREATE_REQUEST = 'WOM_CREATE_REQUEST'
export const WOM_CREATE_SUCCESS = 'WOM_CREATE_SUCCESS'
export const WOM_CREATE_FAIL = 'WOM_CREATE_FAIL'


export const WOM_UPDATE_REQUEST = 'WOM_UPDATE_REQUEST'
export const WOM_UPDATE_SUCCESS = 'WOM_UPDATE_SUCCESS'
export const WOM_UPDATE_FAIL = 'WOM_UPDATE_FAIL'

export const WOM_DELETE_REQUEST = 'WOM_DELETE_REQUEST'
export const WOM_DELETE_SUCCESS = 'WOM_DELETE_SUCCESS'
export const WOM_DELETE_FAIL = 'WOM_DELETE_FAIL'

export const VIEW_WOM_REQUEST = 'VIEW_WOM_REQUEST'
export const VIEW_WOM_SUCCESS = 'VIEW_WOM_SUCCESS'
export const VIEW_WOM_FAIL = 'VIEW_WOM_FAIL'