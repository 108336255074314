import React, {useState, useEffect} from 'react';
import { Link as BreadcrumbLink, CircularProgress } from '@mui/material';
import { IconButton, Grid, Divider, Stack} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../Hooks/useDialog';
import { blueGrey, blue, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import Crumbs from '../../../Components/Utilities/Crumbs';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import HolidayDialogs from '../../../Components/Dialogs/CRM/System/HolidayDialogs/HolidayDialogs';
import HolidayTbl from '../../../Components/Table/CRM/System/HolidayTbl';
import { getHolidaysList } from '../../../Redux/Actions/HolidayActions';
 
const UploadFiles = () => { 

    //FETCH DATA FROM SERVER
    
    const dispatch = useDispatch();
    const {holidays, loading ,error } = useSelector(state => state.holidays_List) //receiving state object
    const [list, setList] = useState([])
    
    useEffect(()=> {
        dispatch(getHolidaysList());
    }, [])
     
    useEffect(() => {
        if(holidays)
        {
            setList([...holidays])
        }
        
    }, [holidays])
    //TABLE DIALOG FUNCTIONALITY
    
        const handleAddClick = () => {
            console.log('THIS IS WORKING')
            showDialog(true, 'create', '');
        }
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)
    
    //----------------------------------------------------------------------------------------------------------------------------------
     
    return (
        <div>
        
         <ScreenHeader title="Upload Files" subtitle="Manage all files from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>
        { dialog.open ?  <HolidayDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
        <Grid container justifyContent="space-between" sx={{mb: 2}}>
            <Crumbs root="CRM" middle="System" end="Holidays" main/>
            <Stack direction="row" justifyContent="center" alignItems="center">
                <AddButtonLabel item="Holiday"/>
                <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                    <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                </IconButton>
            </Stack>
        </Grid>
        
        <Divider sx={{color: lightBlue[800]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        { loading ?  <HorizontalLoading/> : '' }
        <HorizontalSummaryBox primary="Holidays Brief" secondary="Overall Holidays count" label1="All Holidays" label2="Second Label" label3="Third Label" count={[2,11,6]}/>
        
        <AboveTableHeader title="Holidays List"/>
        <Grid container>
            <HolidayTbl holidays={list}/>
        </Grid>


     
    </div>
    )
}

export default UploadFiles
