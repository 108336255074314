import React from 'react'
import PageHeader from '../../Components/PageHeader';

const FinanceScreen = () => {
    return (
        <div>
            <PageHeader page="Finances"/>
        </div>
    )
}

export default FinanceScreen
