import { COAS_LIST_REQUEST, COAS_LIST_SUCCESS, COAS_LIST_FAIL, VIEW_COA_FAIL, VIEW_COA_REQUEST, VIEW_COA_SUCCESS, COA_CREATE_REQUEST, COA_CREATE_SUCCESS, COA_CREATE_FAIL, COA_UPDATE_FAIL, COA_UPDATE_REQUEST, COA_UPDATE_SUCCESS } from '../Constants/chartOfAccountConstants';
import api from '../../config/apiConfig';


const getCOAsList = () => async(dispatch) => {
    
  
    try {
        dispatch({type: COAS_LIST_REQUEST});
        const {data} = await api.get('/chartofaccounts');
        console.log('COAs data is:' + data[0].Name)
        dispatch({type: COAS_LIST_SUCCESS, payload: data})
         
        
    } catch (error) {
        dispatch({type: COAS_LIST_FAIL, payload: error})
    }
} 

const submitCOA = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: COA_CREATE_REQUEST});
        
        const {data} = await api.post(`/chartofaccounts/`, postValues);
        console.log('COA data is:' + data)
        dispatch({type: COA_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: COA_CREATE_FAIL, payload: error})
    }
} 

const getCOA = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_COA_REQUEST});
        
        const {data} = await api.get(`/chartofaccounts/ ${id}`);
        console.log('COA data is:' + data)
        dispatch({type: VIEW_COA_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_COA_FAIL, payload: error})
    }
}

const editCOA = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: COA_UPDATE_REQUEST});
        
        const {data} = await api.put(`/chartofaccounts/`, values);
        console.log('COA data is:' + data)
        dispatch({type: COA_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: COA_UPDATE_FAIL, payload: error})
    }
}



export {getCOAsList, submitCOA, getCOA, editCOA }