export const applicants_list = [
    {
        id: 1,
        'Applicant Name': 'Jim Jones',
        'First Name' : 'Jim',
        Surname: 'Jones',
        Gender: 'male',
        Email: 'emailname@server.com',
        Mobile: '+263 123456',
        'Home Phone': 'Address Field',
        Division: 'I.T',
        Job: 'Web Developer',
        'Applicant Rating': 'Not rated',
        'Applicant Prospect': false,
        'Applicant Status': 'Not Assessed',
        Assessment: false

},
    {
        id: 2,
        'Applicant Name': 'Olin Base',
        'First Name' : 'Olin',
        Surname: 'Base',
        Gender: 'male',
        Email: 'emailname@server.com',
        Mobile: '+263 123456',
        'Home Phone': 'Address Field',
        Division: 'I.T',
        Job: 'Web Developer',
        'Applicant Rating': 'Not rated',
        'Applicant Prospect': true,
        'Applicant Status': 'Not Assessed',
        Assessment: true

},
    {
        id: 3,
        'Applicant Name': 'Giani Rulpie ',
        'First Name' : 'Giani',
        Surname: 'Rulpie',
        Gender: 'male',
        Email: 'emailname@server.com',
        Mobile: '+263 123456',
        'Home Phone': 'Address Field',
        Division: 'I.T',
        Job: 'Web Developer',
        'Applicant Rating': 'Not rated',
        'Applicant Prospect': true,
        'Applicant Status': 'Not Assessed',
        Assessment: false

},
    {
        id: 4,
        'Applicant Name': 'Gloe Baker',
        'First Name' : 'Gloe',
        Surname: 'Baker',
        Gender: 'male',
        Email: 'emailname@server.com',
        Mobile: '+263 123456',
        'Home Phone': 'Address Field',
        Division: 'I.T',
        Job: 'Web Developer',
        'Applicant Rating': 'Not rated',
        'Applicant Prospect': true,
        'Applicant Status': 'Not Assessed',
        Assessment: false

},
    {
        id: 5,
        'Applicant Name': 'Chris War',
        'First Name' : 'Chris',
        Surname: 'War',
        Gender: 'male',
        Email: 'emailname@server.com',
        Mobile: '+263 123456',
        'Home Phone': 'Address Field',
        Division: 'I.T',
        Job: 'Web Developer',
        'Applicant Rating': 'Not rated',
        'Applicant Prospect': false,
        'Applicant Status': 'Not Assessed',
        Assessment: false

},
    {
        id: 6,
        'Applicant Name': 'Alex Founder',
        'First Name' : 'Alex',
        Surname: 'Founder',
        Gender: 'male',
        Email: 'emailname@server.com',
        Mobile: '+263 123456',
        'Home Phone': 'Address Field',
        Division: 'I.T',
        Job: 'Web Developer',
        'Applicant Rating': 'Not rated',
        'Applicant Prospect': true,
        'Applicant Status': 'Not Assessed',
        Assessment: false

},

]

export default applicants_list 