import React from 'react';
import CallIcon from '@mui/icons-material/Call';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Typography, Paper, Grid, Box, Tabs, Tab, Stack, Avatar, Chip } from '@mui/material';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import { deepOrange, deepPurple, blueGrey, orange, blue, indigo, cyan, green, purple, pink } from '@mui/material/colors';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import ME from '../../assets/ME.jpg';


const DetailHeader = ({values, headerFor, applicant}) => {
    return (
        <>
            <Grid container justifyContent="flex-start" alignItems="center" sx={{height: '75px', my: 1, py: 1, background: 'whitemsoke', boxShadow: 'none', border: 'none'}}>
                <Grid item lg={1} sx={{ml: 3, background: ''}}>
                    <Avatar sx={{width: 70, height: 70}} variant="rounded" src={ ME }/>
                </Grid>

                <Grid item lg={9} sx={{mx: 3, background: ''}}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{mt: '0'}}>
                        <Box sx={{px:0, py: 0, display: 'flex', justifyContent: "space-around", alignItems: "center", background: '', borderRadius: '10px'}}>
                            <Stack >
                                <Typography sx={{fontSize: '.8rem', mb: 1, fontFamily: 'Poppins-Light', fontWeight: 600, textAlign: 'left', color: blue[500], background: 'white', borderRadius: '10px'}}>Mobile</Typography>
                                <Chip size="small" variant="outlined" sx={{outlineColor: blue[400]}} avatar={<CallIcon fontSize="small" />} label="+263 787 582 628" />
                            </Stack>

                            <Stack  sx={{ml: 4}}>
                                <Typography sx={{fontSize: '.8rem', mb: 1, fontFamily: 'Poppins-Light', fontWeight: 600, textAlign: 'left', color: blue[500], background: 'white', borderRadius: '10px'}}>Socials</Typography>
                                <Chip size="small" variant="outlined"  avatar={<LinkedInIcon  fontSize="small"/>} label="linkedin.serr.lg" />
                                
                            </Stack>
                        </Box> 
                    <Typography sx={{fontSize: '.9rem', fontFamily: 'Poppins-Light', fontWeight: 600, textAlign: 'right', color: blueGrey[700], background: 'white', borderRadius: '10px'}}>Male</Typography>
                    </Stack>
                </Grid>

            </Grid>
            
        </>
    )
}

export default DetailHeader


// import React from 'react';
// import CallIcon from '@mui/icons-material/Call';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import { Typography, Paper, Grid, Box, Tabs, Tab, Stack, Avatar, Chip } from '@mui/material';
// import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
// import { deepOrange, deepPurple, blueGrey, orange, blue, indigo, cyan, green, purple, pink } from '@mui/material/colors';
// import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
// import ME from '../../assets/ME.jpg';


// const DetailHeader = ({values, headerFor, applicant}) => {
//     return (
//         <>
//             <Grid container justifyContent="flex-start" alignItems="center" sx={{height: '75px',background: headerFor == 'availability-details' ? deepPurple[800]: headerFor == 'checkin-details' ? green[800] : headerFor == 'worker-details' ? 'blue[50]':  headerFor == 'payment-details' ? pink[600] : '#e3f2fd', px: 3, borderRadius: headerFor == 'worker-details' ? '' :'10px 10px 0 0', boxShadow: 'none', border: '1px solid #e3f2fd'}}>
//             {/* <Grid container justifyContent="flex-start" alignItems="center" sx={{height: '75px',background: headerFor == 'availability-details' ? deepPurple[800]: headerFor == 'checkin-details' ? green[800] : headerFor == 'worker-details' ? 'blue[50]':  headerFor == 'payment-details' ? pink[600] : '#e3f2fd', px: 3, borderRadius: headerFor == 'worker-details' ? '' :'10px 10px 0 0', boxShadow: '1px 1px 1px #cfd8da', border: '1px solid #e3f2fd'}}> */}
//                 <Grid item >
//                     <Box component="div"  sx={{display: 'flex', justifyContent: "center", alignItems: "center", width: 50, height: 50, background: '',p: 0, m: 0 ,borderRadius: '10px'}}>
//                         <Avatar sx={{width: 60, height: 60, background: headerFor == 'worker-details' ? '': headerFor == 'payment-details' ? 'none' : headerFor == 'availability-details' ? 'none' : 'orange'}} variant="rounded" src={headerFor == "worker-details" ? ME : ''}>
//                             {headerFor == 'worker-details' ? '': <Typography variant="h6" sx={{fontWeight: 900}}>{headerFor == 'availability-details' ? <EventAvailableRoundedIcon sx={{fontSize: 40}}/> : headerFor == 'payment-details' ? <CurrencyPoundIcon sx={{fontSize: 40}}/>: 'JJ'}</Typography>}
//                         </Avatar>
//                     </Box>
//                 </Grid>
//                 <Grid item >
//                     <Stack sx={{ml: 2}}>
//                         {applicant?
//                             <Typography variant="h3" sx={{fontSize: '1.3rem',fontWeight: 800, py: '', px: 0, color: headerFor == 'payment-details' ? 'white' : headerFor == 'availability-details' ? 'white' : blueGrey[900], background: 'purple[200]'}}>{values.First_Name} {values.Surname}</Typography>
//                             : ''
//                         }

//                         <Stack direction="row" justifyContent="space-between" sx={{mt: '0'}}>
//                                 {headerFor == 'applicant-details'? 
//                                 <Box sx={{px:0, py: 0, display: 'flex', justifyContent: "space-around", alignItems: "center", background: '', borderRadius: '10px'}}>
//                                     <Chip size="small" variant="outlined" sx={{outlineColor: blue[400]}} avatar={<CallIcon fontSize="small" />} label="+263 787 582 628" />
//                                     <Chip size="small" variant="outlined" avatar={<LinkedInIcon fontSize="small"/>} label="+263 787 582 628"  sx={{ml: 2}}/>
//                                 </Box> : ''}

//                                 {headerFor == 'worker-details'? 
//                                 <Box sx={{px:0, py: 0, display: 'flex', justifyContent: "space-around", alignItems: "center", background: '', borderRadius: '10px'}}>
//                                     <Typography variant="" sx={{fontSize: '', fontWeight: 500, py: 0, pl: 0,color: blueGrey[700], background: 'blueGrey[200]'}}>Gender:</Typography>
//                                     <Typography variant="" sx={{fontSize: '', fontWeight: 500, px: 1, py: 0,ml: 1,color: blueGrey[700], background: '', borderRadius: '10px'}}>Male</Typography>
//                                 </Box>: ''}
//                         </Stack>
//                     </Stack>
//                 </Grid>
                
//                 <Grid item sx={{bgcolor: '', width: '60%', textAlign: 'right'}}>
                    
                        
//                         <Typography variant="" sx={{fontSize: '.8rem', fontWeight: 600, px: 2, py: '2px',ml: 1,color: blueGrey[700], background: 'white', borderRadius: '10px'}}>{headerFor == 'worker-details' ? 'Employee Department' : 'Immediate Hire'}</Typography>
                   
//                 </Grid>
//             </Grid>
            
//         </>
//     )
// }

// export default DetailHeader
