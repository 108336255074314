import { PAYROLLSETUPS_LIST_REQUEST, PAYROLLSETUPS_LIST_FAIL, PAYROLLSETUPS_LIST_SUCCESS} from '../Constants/payrollSetupConstants';
import api from '../../config/apiConfig';


const getPayrollSetupsList = () => async(dispatch) => {
    //const data = assessmenttypes;
 
    try {
        dispatch({type: PAYROLLSETUPS_LIST_REQUEST});
        const {data} = await api.get('/payrollsetups');
        //console.log('PayrollSetups data is:' + data[0]['PayrollSetups_Name'])
        dispatch({type: PAYROLLSETUPS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: PAYROLLSETUPS_LIST_FAIL, payload: error})
    }
} 

export {getPayrollSetupsList }