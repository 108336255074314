import { SHIFTPREFERENCES_LIST_REQUEST, SHIFTPREFERENCES_LIST_FAIL, SHIFTPREFERENCES_LIST_SUCCESS} from '../Constants/shiftPreferenceConstants';
import api from '../../config/apiConfig';


const getShiftPreferencesList = () => async(dispatch) => {
    //const data = assessmenttypes;
 
    try {
        dispatch({type: SHIFTPREFERENCES_LIST_REQUEST});
        const {data} = await api.get('/shiftpreferences');
        //console.log('ShiftPreferences data is:' + data[0]['ShiftPreferences_Name'])
        dispatch({type: SHIFTPREFERENCES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: SHIFTPREFERENCES_LIST_FAIL, payload: error})
    }
} 

export {getShiftPreferencesList }