import { STAFF_LIST_REQUEST, STAFF_LIST_SUCCESS, STAFF_LIST_FAIL, VIEW_STAFF_FAIL, VIEW_STAFF_REQUEST, VIEW_STAFF_SUCCESS, STAFF_CREATE_REQUEST, STAFF_CREATE_SUCCESS, STAFF_CREATE_FAIL, STAFF_UPDATE_FAIL, STAFF_UPDATE_REQUEST, STAFF_UPDATE_SUCCESS } from '../Constants/staffConstants';
import api from '../../config/apiConfig';


const getStaffList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: STAFF_LIST_REQUEST});
        const {data} = await api.get('/staff');
        // console.log('Staff data is:' + data[0]['Staff_Name'])
        dispatch({type: STAFF_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: STAFF_LIST_FAIL, payload: error})
    }
} 

const submitStaff = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: STAFF_CREATE_REQUEST});
        
        const {data} = await api.post(`/staff/`, values);
        console.log('Staff data is:' + data)
        dispatch({type: STAFF_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: STAFF_CREATE_FAIL, payload: error})
    }
} 

const getStaff = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_STAFF_REQUEST});
        
        const {data} = await api.get(`/staff/ ${id}`);
        console.log('Staff data is:' + data.Staff_FName)
        dispatch({type: VIEW_STAFF_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_STAFF_FAIL, payload: error})
    }
}

const editStaff = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: STAFF_UPDATE_REQUEST});
        
        const {data} = await api.put(`/staff/`, values);
        console.log('Staff data is:' + data)
        dispatch({type: STAFF_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: STAFF_UPDATE_FAIL, payload: error})
    }
}



export {getStaffList, submitStaff, getStaff, editStaff }