import { PAYROLLSETUPS_LIST_REQUEST, PAYROLLSETUPS_LIST_SUCCESS, PAYROLLSETUPS_LIST_FAIL} from "../Constants/payrollSetupConstants";

const payrollSetupsListReducer = (state = {payrollSetups: []}, action) => {

    switch (action.type)
    {
        case PAYROLLSETUPS_LIST_REQUEST:
            return {loading: true};
 
        case PAYROLLSETUPS_LIST_SUCCESS:
            console.log('Code is reaching the reducer and payrollSetups data is:' + action.payload)
            return {
                loading: false,
                payrollSetups: action.payload
            }
        case PAYROLLSETUPS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 


export {payrollSetupsListReducer}