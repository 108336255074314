import React from 'react'
import { Stack, Link } from '@mui/material';
import { blue } from '@mui/material/colors';

const FormNavigation = () => {
    return (
        <>
            <Stack direction="row" alignItems="center" sx={{mr: 5, position: '', right: '20%', display: 'block'}} >
                <Link sx={{mr: 1, color: blue[700], fontFamily: 'Poppins', fontSize: 13, textDecoration: 'none', cursor: 'pointer'}}>Info</Link>
                <Link sx={{mr: 1, color: blue[700], fontFamily: 'Poppins', fontSize: 13, textDecoration: 'none', cursor: 'pointer'}} href="#staff">Staff</Link>
                <Link sx={{mr: 1, color: blue[700], fontFamily: 'Poppins', fontSize: 13, textDecoration: 'none', cursor: 'pointer'}}>Specifics</Link>
                <Link sx={{mr: 1, color: blue[700], fontFamily: 'Poppins', fontSize: 13, textDecoration: 'none', cursor: 'pointer'}}>Notes</Link>
            </Stack>
            
        </>
    )
}

export default FormNavigation
