import { APPLICANTPROSPECT_LIST_REQUEST, APPLICANTPROSPECT_LIST_FAIL, APPLICANTPROSPECT_LIST_SUCCESS} from '../Constants/applicantProspectConstants';
import api from '../../config/apiConfig';


const getApplicantProspectsList = () => async(dispatch) => {
    //const data = assessmenttypes;
 
    try {
        dispatch({type: APPLICANTPROSPECT_LIST_REQUEST});
        const {data} = await api.get('/applicantprospects');
        //console.log('ApplicantProspects data is:' + data[0]['ApplicantProspects_Name'])
        dispatch({type: APPLICANTPROSPECT_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: APPLICANTPROSPECT_LIST_FAIL, payload: error})
    }
} 

export {getApplicantProspectsList }