import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import DoneIcon from '@mui/icons-material/Done';
import AvailabilityDialogs from '../../../Dialogs/CRM/Staff/AvailabilityDialogs/AvailabilityDialogs';

const AvailabilityTbl = ({availabilities}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const  getStatusColor = (status) => {
        return status === 'Send to Client - Pass' ? pink[500] : status === 'Send to Client - Online Pass'?  yellow[800] : status === 'Failed Client Availability - Not Good'? lightBlue[500] : status === 'Failed Client Availability - Reassign'? lightBlue[500] : status === 'Can start work - Online Pass'? blue[800] : status === 'Resit Online Availability - Online Fail'? blue[800] :  blueGrey[500]
    }

    const switchToViewAvailability = (id) => {
        navigate('/availability/'+ id)
    } 

    const availabilityQuickView = (id) => {

        showDialog(true, 'view', id)
    }




    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'AvailabilityName', headerName: 'Full Name', minWidth: 210 , renderCell: (params) => <Typography onClick={()=> {availabilityQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.AvailabilityName}`}</Typography>},
        { field: 'Worker', headerName: 'Worker', minWidth: 120 ,},
        { field: 'Monday', headerName: 'Monday', minWidth: 50 , renderCell: (params) => ( params.row.Monday === 'Available'? <DoneIcon fontSize="small" sx={{color: lightGreen[600]}}/> : params.row.Monday)},
        { field: 'Tuesday', headerName: 'Tuesday', minWidth: 50 , renderCell: (params) => ( params.row.Tuesday === 'Available'? <DoneIcon fontSize="small" sx={{color: lightGreen[600]}}/> : params.row.Tuesday)},
        { field: 'Wednesday', headerName: 'Wednesday', minWidth: 50 , renderCell: (params) => ( params.row.Wednesday === 'Available'? <DoneIcon fontSize="small" sx={{color: lightGreen[600]}}/> : params.row.Wednesday)},
        { field: 'Thursday', headerName: 'Thursday', minWidth: 50 , renderCell: (params) => ( params.row.Thursday === 'Available'? <DoneIcon fontSize="small" sx={{color: lightGreen[600]}}/> : params.row.Thursday)},
        { field: 'Friday', headerName: 'Friday', minWidth: 50 , renderCell: (params) => ( params.row.Friday === 'Available'? <DoneIcon fontSize="small" sx={{color: lightGreen[600]}}/> : params.row.Friday)},
        { field: 'Saturday', headerName: 'Saturday', minWidth: 50 , renderCell: (params) => ( params.row.Saturday === 'Available'? <DoneIcon fontSize="small" sx={{color: lightGreen[600]}}/> : params.row.Saturday)},
        { field: 'Sunday', headerName: 'Sunday', minWidth: 50 , renderCell: (params) => ( params.row.Sunday === 'Available'? <DoneIcon fontSize="small" sx={{color: lightGreen[600]}}/> : params.row.Sunday)},
        { field: 'WeekCommencing', headerName: 'Week Commencing'},
        { field: 'Status', headerName: 'Status', minWidth: 180 , type: 'singleSelect', valueOptions: (['Send to Client - Pass', 'Send to Client - Online Pass', 'Failed Client Availability - Not Good', 'Failed Client Availability - Reassign', 'Can start work - Online Pass', 'Resit Online Availability - Online Fail']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewAvailability(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ];


    return (
        
        <>
            { dialog.open  ?  <AvailabilityDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={availabilities}
                        loading={!availabilities.length}
                    />
                </div>
            </div>
        </>
    )
}

export default AvailabilityTbl
