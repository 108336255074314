import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, yellow, indigo, purple } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import VATReturnDialogs from '../../../Dialogs/Finance/Reconciliation/VATReturnsDialogs/VATReturnDialogs';

const VATReturnsTbl = ({vatReturns}) => { 

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    const  getTypeColor = (status) => {
        return status === 'Assign Work' ? purple[500] : status === 'Complete Invoices'?  lightGreen[500] : status === 'Complete Payroll'? lightBlue[500] :  status === 'Compliance'? pink[500] : status === 'Contact Client'? yellow[800]  : status === 'Contact Client'? indigo[400]  : status === 'New'? orange[500] : blueGrey[500]
    }
    const  getStatusColor = (status) => {
        return status === 'New Task' ? pink[500] : status === 'In Progress'?  yellow[800] : status === 'Part Complete'? lightBlue[500] : status === 'Complete'? lightBlue[500] : status === 'Overdue'? blue[800] : status === 'Escalate'? purple[800] :  blueGrey[500]
    } 

    const switchToViewTask = (id) => {
        navigate('/task/'+ id)
    } 

    const taskQuickView = (id) => {

        showDialog(true, 'view', id)
    }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number', minWidth: 100 },
        { field: 'VATName', headerName: 'Account Name', minWidth: 150 , renderCell: (params) => <Typography onClick={()=> {taskQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.VATName}`}</Typography>},
        { field: 'Frequency', headerName: 'Frequency', minWidth: 150},
        { field: 'StartDate', headerName: 'Start Date', minWidth: 150 ,},
        { field: 'EndDate', headerName: 'End Date', minWidth: 150 ,},
        { field: 'Box1', headerName: 'Box1', minWidth: 120},
        { field: 'Box2', headerName: 'Box2', minWidth: 120},
        { field: 'Box3', headerName: 'Box3', minWidth: 120},
        { field: 'Box4', headerName: 'Box4', minWidth: 120},
        { field: 'Box5', headerName: 'Box5', minWidth: 120},
        { field: 'Box6', headerName: 'Box6', minWidth: 120},
        { field: 'Box7', headerName: 'Box7', minWidth: 120},
        { field: 'Box8', headerName: 'Box8', minWidth: 120},
        { field: 'Status', headerName: 'Status', minWidth: 180 , type: 'singleSelect', valueOptions: (['Send to Client - Pass', 'Send to Client - Online Pass', 'Failed Client Assessment - Not Good', 'Failed Client Assessment - Reassign', 'Can start work - Online Pass', 'Resit Online Assessment - Online Fail']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewTask(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];


    return (
        
        <>
            { dialog.open  ?  <VATReturnDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={vatReturns}
                        loading={!vatReturns.length}
                    />
                </div>
            </div>
        </>
    )
}

export default VATReturnsTbl
