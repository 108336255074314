import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, TextField, Rating} from '@mui/material';
import { blueGrey, orange, blue, purple, deepOrange, deepPurple } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useDispatch, useSelector } from 'react-redux';
import EventIcon from '@mui/icons-material/Event';
import { getApplicantsList } from '../../../../../Redux/Actions/ApplicantsActions';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import { getAssessmentTypesList } from '../../../../../Redux/Actions/AssessmentTypesActions';
import { getAssessmentResultsList } from '../../../../../Redux/Actions/AssessmentResultsActions';
import { getAssessmentStatusesList } from '../../../../../Redux/Actions/AssessmentStatusesActions';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { submitAssessment } from '../../../../../Redux/Actions/AssessmentsActions';
import AsyncSelect from 'react-select/async';
import { Link as BreadcrumbLink } from '@mui/material';
import makeAnimated from 'react-select/animated';
import api from '../../../../../config/apiConfig';


const AddAssessmentDialog = ({open, onClose, type, info, handleSubmit}) => {
 


    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET ASSESSMENTS
    const assessments_ListState = useSelector(state => state.assessments_List);
    const {assessments, loading: loading_assessments} = assessments_ListState;

    //GET DIVISIONS
    const divisions_ListState = useSelector(state => state.divisions_List);
    const {divisions, loading} = divisions_ListState;

    //GET ASSESSMENT TYPE
    const types_ListState = useSelector(state => state.assessmentTypes_List);
    const {assessmentTypes, loading: loading_types} = types_ListState;

    //GET ASSESSMENT RESULT
    const results_ListState = useSelector(state => state.assessmentResults_List);
    const {assessmentResults, loading: loading_results} = results_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;

    //GET ASSESSMENT STATUS
    const statuses_ListState = useSelector(state => state.assessmentStatuses_List);
    const {assessmentStatuses, loading: loading_statuses} = statuses_ListState;

    //GET APPLICANTS
    const applicants_List_State = useSelector((state) => state.applicants_List);
    const {loading: loading_applicants, applicants} = applicants_List_State;
    
       
    //CURRENT DATE
    const d = new Date();
    const yyyy = d.getFullYear()
    const mm = d.getMonth()
    const dd = d.getDay()
    
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getApplicantsList());
        dispatch(getStaffList());
        dispatch(getAssessmentTypesList());
        dispatch(getAssessmentResultsList());
        dispatch(getAssessmentStatusesList());
       
    }, [])
    

    const [values, setValues] = useState({
            assessment_date: '', 
            assessment_time: '', 
            subject: '',
            applicants: '' , 
            staff: '' , 
            division: '', 
            assessmenttypes: '', 
            level: '', 
            assessmentresults: '', 
            assessmentstatuses: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            assessment_date: '', 
            assessment_time: '', 
            subject: '',
            applicants: '' , 
            staff: '' , 
            division: '', 
            assessmenttypes: '', 
            level: '', 
            assessmentresults: '', 
            assessmentstatuses: '',
            });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
           
            //const the_name = getName(value);
            //setValues({ applicant: the_name })
        }
        else if(name == 'type' || name == 'status' || name == 'result')
        {

            setValues({
                ...values,
                [name]: value
            });
            
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
 
        }
    }
    
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitAssessment({...submitValues, date, time}))
        handleSubmit('success',`Successfully added the assessment for the applicant: ${values.applicants.label}`);

    }

    const animatedComponents = makeAnimated();

    const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {
        
        const keeper = [];

        const tableName = name == 'applicants' ? applicants : name == 'staff' ? staff : name == 'assessmentresults' ? assessmentResults : name == 'assessmenttypes' ? assessmentTypes : name == 'assessmentstatuses' ? assessmentStatuses :'';

        const id_field = name === 'applicants' ? 'Applicant_Id': name === 'staff' ? 'Staff_Id' : name === 'assessmentresults'? 'Result_Id' : name === 'assessmenttypes'? 'Type_Id': name === 'assessmentstatuses'? 'Status_Id' : '';
        const name_field = name === 'applicants' ? 'First_Name': name === 'staff' ? 'First_Name' : name === 'assessmentresults'? 'Result_Name' : name === 'assessmenttypes'? 'Type_Name': name === 'assessmentstatuses'? 'Status_Name' : '';
            
        tableName.forEach(x => {
            
            if(name === 'applicants')
            {
                keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
            }
            else{
                keeper.push({value: x[id_field], label: x[name_field]})

            }

            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['applicants', 'staff', 'assessmentresults', 'assessmenttypes', 'assessmentstatuses'];
        
        const id_field = name === 'applicants' ? 'Applicant_Id': name === 'staff' ? 'Staff_Id' : name === 'assessmentresults'? 'Result_Id' : name === 'assessmenttypes'? 'Type_Id': name === 'assessmentstatuses'? 'Status_Id' : '';
        const name_field = name === 'applicants' ? 'First_Name': name === 'staff' ? 'First_Name' : name === 'assessmentresults'? 'Result_Name' : name === 'assessmenttypes'? 'Type_Name': name === 'assessmentstatuses'? 'Status_Name' : '';
        
        names.forEach(x => x === name)
        {
          
            data.forEach(x => {
                if(name === 'applicants')
                {
                    keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
                }
                else{

                    keeper.push({value: x[id_field], label: x[name_field]})
                }
                })
            
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action) => {
        
        const name = action.name;

            setValues({
                ...values,
                [name]: value
            })
            setSubmitValues({
                ...submitValues,
                [name]: value.value
            })

            if(name === 'applicants')
            {
                setValues({
                    ...values,
                    [name]: value
                })
                setSubmitValues({
                    ...submitValues,
                    [name]: value.value
                })
            
            //SET BRANCH NAME BASED ON STAFF SELECTED
            setDivision((prevState)=> {
                if(name === 'applicants')
                {
                    const applicant = applicants.find(x => `${x['First_Name']}   ${x['Surname']}` === value.label);
                    const targetDivisionId = applicant.Division_Id;
                    const division = divisions.find(x => x.Division_Id === targetDivisionId);

                    return division.Name;
                    
                    
                }
            })
            }
        

    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }

    const [summary, setSummary] = useState(false)

    const handleSummary = () => {
        setSummary(!summary);
    }
    

    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [division, setDivision] = useState('')

    const assessmentName = <Typography 
                        sx={{background: '', borderRadius: '3px', height: '38px', width: '300px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', pl: 2, border: '', fontSize: '1.1rem',fontWeight: 600}}>
                        {values.applicants.label? values.applicants.label :  <span style={{color: blue[100], fontStyle: 'italic', fontSize: '.8rem'}}>__</span> } 
                        <span style={{color: blue[500], px: 3}}> | </span> 
                        {date? date : <span style={{color: blue[100], fontStyle: 'italic', fontSize: '.8rem'}}>__</span> }  
                        <span style={{color: blue[500]}}> | </span>  
                        {time? time : <span style={{color: blue[100], fontStyle: 'italic', fontSize: '.8rem'}}>__</span>} 
                     </Typography> 

     
    
    return (
        
        <Dialog open={open} onClose={onClose} handleSubmit fullWidth maxWidth="lg" >

           
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, background: blue[700] , color: 'white'}}  >
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    <AssessmentIcon sx={{color: blue[300], mr: 2, fontSize: '1.5rem'}}/>
                    <Typography variant="h6" sx={{color: blue[300],fontWeight: 700}}> Add new assessment </Typography>
                </Stack>
                
                {assessmentName}
            </DialogTitle>
    
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly">

                <Grid item lg={12} sx={{m: 0, p: 0, background: ''}}>
                    <Stack direction="row" alignItems="center" sx={{ background: 'blue[50]', mb: 0}}>
                        <Typography variant="body1" sx={{color: blue[900],fontWeight: 900, textAlign: 'center'}}>Form Summary</Typography>
                        <Button variant="text" size="small" sx={{fontSize: 12, color: blue[900], textAlign: 'center'}} onClick={handleSummary}>{summary? 'Close' : 'Open'}</Button>
                    </Stack>
                    {/* <p>ID: </p>
                    <p>date: {date}</p>
                    <p>time: {time}</p>
                    <p>subject: {submitValues.subject}</p>
                    <p>applicant: {submitValues.applicants}</p>
                    <p>staff: {submitValues.staff}</p>
                    <p>type: {submitValues.assessmenttypes}</p>
                    <p>level: {submitValues.level}</p>
                    <p>result: {submitValues.assessmentresults}</p>
                    <p>status: {submitValues.assessmentstatuses}</p> */}
                </Grid>

                <Grid item lg={12}> 
                    <Grid container flexDirection="row" justifyContent="space-between" className={`summary ${summary? 'open' : ''}`} sx={{px: 3, pt: '2px', background: '#f2f7fc', border: '2px solid whitesmoke', borderRadius: 3, boxShadow: 'none', overflowY: 'scroll'}}>
                                <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Assessment ID: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`ASS - ${!loading_assessments? assessments.length + 1 : ''}`} </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Assessment Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.assessment_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                    <Typography variant="body2" sx={{color: values.subject? '' : blue[500], fontWeight: values.subject? 800 : 500, ml: 5}}>{values.subject? values.subject : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                    <Typography variant="body2" sx={{color: values.applicant? '' : blue[500], fontWeight: values.applicant? 800 : 500, ml: 5}}>{values.applicant? values.applicant : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Name </Typography>
                                    <Typography variant="body2" sx={{color: applicantName? '' : blue[500], fontWeight: applicantName? 800 : 500, ml: 5}}>{applicantName? applicantName : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff Name </Typography>
                                    <Typography variant="body2" sx={{color: staffName? '' : blue[500], fontWeight: staffName? 800 : 500, ml: 5}}>{staffName? staffName : 'null' }</Typography>
                                </Stack>
                                
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Assessment Type: </Typography>
                                    <Typography variant="body2" sx={{color: values.type? '' : blue[500], fontWeight: values.type? 800 : 500, ml: 5}}>{values.type? values.type : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Level: </Typography>
                                    <Typography variant="body2" sx={{color: values.level? '' : blue[500], fontWeight: values.level? 800 : 500, ml: 5}}>{values.level? values.level : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Assessment Result: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.result? 'white' : blue[500], fontSize: '.75rem', textAlign: 'left', px: values.result? '4px' : '', borderRadius: '3px' ,fontWeight: values.result? 500 : 500, background: values.result? blue[500] : ''}}>{values.result? values.result : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Assessment Status: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.status? 'white' : blue[500], fontSize: values.status? '.75rem' :'', textAlign: 'left', px: values.status? '4px' : '', borderRadius: '3px'  ,fontWeight: values.status? 500 : 500, background: values.status? blue[500] : ''}}>{values.status? values.status : 'null' }</Typography>
                                </Stack>
                            
                           </Grid>
                </Grid>
                <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                    <Stack sx={{mb: 3, background: '', height: '300px', borderRadius: '5px', width: '80%', pl: 3, pt: 3}}>
                        <BreadcrumbLink underline="none" sx={{color: blueGrey[300]}} fontSize="small" href="#basic">Basic Info</BreadcrumbLink>
                        <BreadcrumbLink underline="none" sx={{color: blueGrey[300] , mt: 4}} fontSize="small" href="#basic">Date & Time</BreadcrumbLink>
                        <BreadcrumbLink underline="none" sx={{color: blueGrey[300] , mt: 4}} fontSize="small" href="#basic">Applicant</BreadcrumbLink>
                        <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#specifics">Staff</BreadcrumbLink>
                        <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#status">Decision</BreadcrumbLink>
                    </Stack>
                </Grid>
                    <Grid item lg={10} sx={{mt: 2}}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[700], borderRadius: '5px',  px: 2, py: '5px'}}>Basic Information</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Assessment ID</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" disabled type="text" value={`ASS - ${!loading_assessments? assessments.length + 1 : ''}`} className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Assessment Date</Form.Label>
                                    <Form.Control name="assessment_date" disabled type="text" value={date? date : ''} placeholder="No date set yet" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Assessment Subject</Form.Label>
                                    <Form.Control onChange={handleChange} name="subject" type="email" value={values.subject} placeholder="Your description here" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[700], borderRadius: '5px',  px: 2, py: '5px'}}>Applicant</Typography>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Related Applicant</Form.Label>
                                        <AsyncSelect
                                            value={values.applicants}
                                            name="applicants"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'applicants')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for applicant ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Division</Form.Label>
                                    <Form.Control type="email" value={division} disabled placeholder="Choose Applicant" className="outline-none border-none" /> 
                                </Form.Group>

                            </Row>
                            {/* <EventIcon fontSize="medium" sx={{color: blueGrey[900]}}/> */}
                            <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px',  px: 2, py: '5px'}}>Date & Time</Typography>
                            <Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Assessment Date</Form.Label>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            label=""
                                            value={date}
                                            name="assessment_date"
                                            onChange={(newValue) => { setDate(`${newValue.getDay()} / ${newValue.getMonth()} / ${newValue.getFullYear()}`)}}
                                            sx={{color: blue[500]}}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background: 'blue[50]', color: blue[700], width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Assessment Start Time</Form.Label>
                                    <TextField
                                        id="time"
                                        label=""
                                        name="assessment_time"
                                        type="time"
                                        size="small"
                                        value={time}
                                        onChange={(e)=> setTime(e.target.value)}
                                        defaultValue="07:30"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 300, // 5 min
                                        }}
                                        sx={{ width: "100%", background: '', color: blue[700] }}
                                    />
                            </Form.Group>
                                
                            </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[700], borderRadius: '5px',  px: 2, py: '5px'}}>Staff on assessment</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Office Staff</Form.Label>
                                    <AsyncSelect
                                        value={values.staff}
                                        name="staff"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'staff')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for staff ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                
                            </Row>

                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Assessment Type</Form.Label>
                                        <AsyncSelect
                                            value={values.assessmenttypes}
                                            name="assessmenttypes"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'assessmenttypes')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for type ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Level</Form.Label>
                                    <br/>
                                    <Rating name="level" defaultValue={2.5} value={values.level} onChange={handleChange} precision={0.5} sx={{mt: 1}}/>
                                </Form.Group>
                            </Row>
                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: purple[700], borderRadius: '5px',  px: 2, py: '5px'}}>Applicant Decision</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Assessment Result</Form.Label>
                                        <AsyncSelect
                                            value={values.assessmentresults}
                                            name="assessmentresults"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'assessmentresults')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for result ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Assessment Status</Form.Label>
                                        <AsyncSelect
                                            value={values.assessmentstatuses}
                                            name="assessmentstatuses"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'assessmentstatuses')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for status ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>
                                
                            </Row>
                        </Form>
                    </Grid>
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[800], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Assessment</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddAssessmentDialog
