export const SITESTATUSES_LIST_REQUEST = 'SITESTATUSES_LIST_REQUEST';
export const SITESTATUSES_LIST_SUCCESS = 'SITESTATUSES_LIST_SUCCESS';
export const SITESTATUSES_LIST_FAIL = 'SITESTATUSES_LIST_FAIL'; 

export const SITESTATUS_CREATE_REQUEST = 'SITESTATUS_CREATE_REQUEST'
export const SITESTATUS_CREATE_SUCCESS = 'SITESTATUS_CREATE_SUCCESS'
export const SITESTATUS_CREATE_FAIL = 'SITESTATUS_CREATE_FAIL'


export const SITESTATUS_UPDATE_REQUEST = 'SITESTATUS_UPDATE_REQUEST'
export const SITESTATUS_UPDATE_SUCCESS = 'SITESTATUS_UPDATE_SUCCESS'
export const SITESTATUS_UPDATE_FAIL = 'SITESTATUS_UPDATE_FAIL'

export const SITESTATUS_DELETE_REQUEST = 'SITESTATUS_DELETE_REQUEST'
export const SITESTATUS_DELETE_SUCCESS = 'SITESTATUS_DELETE_SUCCESS'
export const SITESTATUS_DELETE_FAIL = 'SITESTATUS_DELETE_FAIL'

export const VIEW_SITESTATUS_REQUEST = 'VIEW_SITESTATUS_REQUEST'
export const VIEW_SITESTATUS_SUCCESS = 'VIEW_SITESTATUS_SUCCESS'
export const VIEW_SITESTATUS_FAIL = 'SITESTATUS_FAIL'