export const INCIDENTSTATUSES_LIST_REQUEST = 'INCIDENTSTATUSES_LIST_REQUEST';
export const INCIDENTSTATUSES_LIST_SUCCESS = 'INCIDENTSTATUSES_LIST_SUCCESS';
export const INCIDENTSTATUSES_LIST_FAIL = 'INCIDENTSTATUSES_LIST_FAIL'; 

export const INCIDENTSTATUS_CREATE_REQUEST = 'INCIDENTSTATUS_CREATE_REQUEST'
export const INCIDENTSTATUS_CREATE_SUCCESS = 'INCIDENTSTATUS_CREATE_SUCCESS'
export const INCIDENTSTATUS_CREATE_FAIL = 'INCIDENTSTATUS_CREATE_FAIL'


export const INCIDENTSTATUS_UPDATE_REQUEST = 'INCIDENTSTATUS_UPDATE_REQUEST'
export const INCIDENTSTATUS_UPDATE_SUCCESS = 'INCIDENTSTATUS_UPDATE_SUCCESS'
export const INCIDENTSTATUS_UPDATE_FAIL = 'INCIDENTSTATUS_UPDATE_FAIL'

export const INCIDENTSTATUS_DELETE_REQUEST = 'INCIDENTSTATUS_DELETE_REQUEST'
export const INCIDENTSTATUS_DELETE_SUCCESS = 'INCIDENTSTATUS_DELETE_SUCCESS'
export const INCIDENTSTATUS_DELETE_FAIL = 'INCIDENTSTATUS_DELETE_FAIL'

export const VIEW_INCIDENTSTATUS_REQUEST = 'VIEW_INCIDENTSTATUS_REQUEST'
export const VIEW_INCIDENTSTATUS_SUCCESS = 'VIEW_INCIDENTSTATUS_SUCCESS'
export const VIEW_INCIDENTSTATUS_FAIL = 'VIEW_INCIDENTSTATUS_FAIL'