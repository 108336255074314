import { ASSESSMENTSTATUS_LIST_REQUEST, ASSESSMENTSTATUS_LIST_SUCCESS, ASSESSMENTSTATUS_LIST_FAIL} from "../Constants/assessmentStatusesConstants";

const assessmentStatusesListReducer = (state = {assessmentStatuses: []}, action) => {
 
    switch (action.type)
    {
        case ASSESSMENTSTATUS_LIST_REQUEST:
            return {loading: true};
 
        case ASSESSMENTSTATUS_LIST_SUCCESS:
            console.log('Code is reaching the reducer and assessmentStatuses data is:' + (action.payload)[0].Statu_esId)
            return {
                loading: false,
                assessmentStatuses: action.payload
            }
        case ASSESSMENTSTATUS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 



export {assessmentStatusesListReducer}