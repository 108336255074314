import React, { useEffect, useState } from 'react';
import DataTable from '../../DataTable';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import ShiftDialogs from '../../../Dialogs/CRM/Staff/ShiftDialogs/ShiftDialogs';

const ShiftsTbl = ({shifts}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const getJRColor = (jr) => {
        return jr === 'Class 1' ? blue[500] : jr === 'Class 2'?  pink[500] : jr === 'Construction'? orange[500] : jr === 'FLT'? lightGreen[500] : blueGrey[500]
    }
    const getStatusColor = (status) => {
        return status === 'New' ? blue[500] : status === 'Active'?  lightGreen[500] : status === 'Multi'? orange[500] : status === 'Leaver'? lightGreen[500] : blueGrey[500]
    }

    const switchToViewShift = (id) => {
        navigate('/shift/'+ id)
    } 

    const shiftQuickView = (id) => {

        showDialog(true, 'view', id)
      }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Name', headerName: 'Name', width: 250,  renderCell: (params) => <Typography onClick={()=> {shiftQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Worker} | ${params.row.Date} | ${params.row.Client}`}</Typography>},
        { field: 'Worker', headerName: 'Worker'},
        
        { field: 'Division', headerName: 'Division', type: 'singleSelect', valueOptions: (['Construction', 'Driving', 'Industrial', 'Aviation'])},
        
        { field: 'Job_Role', headerName: 'Job Role',type: 'singleSelect',  valueOptions: (['Class 1', 'Driving', 'Industrial', 'Aviation']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getJRColor(params.row.Job_Role), borderRadius: '30px', px: '10px'}}>{params.row.Job_Role}</Typography>
            )},
            
        { field: 'Date', headerName: 'Date'},
        { field: 'Start_Time', headerName: 'Start Time'},
        { field: 'End_Time', headerName: 'End Time'},
        { field: 'Duration', headerName: 'Duration'},
        { field: 'Client', headerName: 'Client'},
        { field: 'Client', headerName: 'Client'},
        { field: 'Staff', headerName: 'Consultant'},
        { field: 'Shift_Status', headerName: 'Status', type: 'singleSelect', valueOptions: (['New', 'Active', 'Multi', 'Leaver', 'Archived']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Shift_Status), borderRadius: '30px', px: '10px'}}>{params.row.Shift_Status}</Typography>
        )},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewShift(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ];



    return (
        <>
        { dialog.open  ?  <ShiftDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }

            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={shifts}
                        loading={!shifts.length}
                    />
                </div>
            </div>
        </>
       
    )
}

export default ShiftsTbl
