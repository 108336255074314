import React, {useState} from 'react';
import AddCOADialog from './AddCOADialog';
import ViewCOADialog from './ViewCOADialog';
import DeleteCOADialog from './DeleteCOADialog';



const COADialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <> 
        {
            type === "create"?

            <AddCOADialog open={open} onClose={onClose} handleSubmit={handleSubmit}  fullWidth />
            
            : type === "view"?

            <ViewCOADialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />

            : type === "delete"?

            <DeleteCOADialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />
            // <ViewCOADialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default COADialogs
