import React, {useState, useRef, useEffect} from 'react'
import { Typography, Paper, Grid, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Button, Card, ToggleButtonGroup, ToggleButton, ClickAwayListener, MenuItem, MenuList, Popper, Grow, Divider, IconButton, TextField, Alert, List, ListItem, LinearProgress, Box, Tabs, Tab, Stack, ListItemText, ListItemAvatar, Avatar, ListItemIcon, Breadcrumbs, Chip } from '@mui/material';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router'; 
import {matchPath, useParams} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import CircleIcon from '@mui/icons-material/Circle';
import { blueGrey, green, blue, deepOrange, red, deepPurple, pink, indigo, orange } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated'
import PageHeader from '../../../Components/PageHeader';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import useDialog from '../../../Hooks/useDialog';
import useAlert from '../../../Hooks/useAlert';
import useToPDF from '../../../Hooks/useToPDF';
import { getShift } from '../../../Redux/Actions/ShiftActions';

const ViewShift = () => {
 
    const {id} = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const shiftDetails = useSelector(state => state.shift_View) //receiving state object
    const {loading, shift } = shiftDetails;

      //ON COMPONENT DID MOUNT
      useEffect(()=> {
        dispatch(getShift(id));
    
        
    }, [])

    const {handlePrint, componentRef} = useToPDF();
    
      const handleClickEdit = () => {
        
        showAlert('info', 'You can now edit this applicants information', true)
        setIsEditing(true)
    }

    const handleCancel = () => {
        setIsEditing(false)
    }


//----------------------------------------------------------------------------------------------------------------------------------
//SAVING EDIT CHANGES

    const handleSave = () => {
        setIsEditing(false)
        showAlert('success', 'Your information has been successfully saved', true)
    }
//----------------------------------------------------------------------------------------------------------------------------------

    const handleClickAdd = () => {
        //setDialog({...dialog, open: true, type: 'add-new-applicant'})
        showDialog(true, 'create', 'holder');
        
    }
    const handleClickDelete = () => {
        //setDialog({...dialog, open: true, type: 'add-new-applicant'})
        showDialog(true, 'delete', 'holder');
        
    }
    const {alert, showAlert} = useAlert();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert);

      const [isEditing, setIsEditing] = useState(false);

      const animatedComponents = makeAnimated();

        const workers = [
            {value: 'Emily Bone', label: 'Emily Bone'},
            {value: 'John Cusa', label: 'John Cusa'},
            {value: 'Mah Jani', label: 'Mah Jani'},
        ]

        const dash = <span style={{color: deepPurple[200], marginRight: '15px'}}>|</span>

    return (
        <>
            <PageHeader page="Shift Details" previous shifts />
            <Grid container justifyContent="space-between" sx={{mt: 2}}>
                <Breadcrumbs>
                    <Link><HomeIcon fontSize="small" sx={{color: blueGrey[300]}}/></Link>
                    <Link underline="none" sx={{color: blueGrey[300]}} fontSize="small" href="#">CRM</Link>
                    <Link underline="none" sx={{color: blueGrey[300]}} fontSize="small" onClick={()=> navigate('/shifts')} href="#">Shifts</Link>
                    <Link underline="none" sx={{color: deepPurple[400]}} fontSize="small">Details</Link>
                </Breadcrumbs>
             </Grid>
             <Grid container justifyContent="space-between" alignItems= "center"  sx={{mt: 2, mb: 2, px:0, borderRadius: '20px', color: 'gray', background: ''}}>
                <Grid item>
                    <Grid container alignItems="center" sx={{background: 'whitesmoke', borderRadius: '5px', boxShadow: '.6px .6px 3px whitesmoke', height: '30px'}}>
                        <Typography variant="body2" sx={{px: 2,color: blueGrey[900], fontWeight: 700}}>Status</Typography>
                        <Typography variant="body2" sx={{px: 1,color: 'white', fontSize: '.7rem', fontWeight: 400, background: blueGrey[800], borderRadius: '5px', mr: 1, py: '3px'}}>{loading? '' : shift.Shift_Status}</Typography>
                        <Button size="small" >
                            <Typography variant="body2" sx={{px: 1,color: blue[700], fontSize: '.75rem', fontWeight: 500, background: '', borderRadius: '5px', mr: 1, py: '3px'}}>Update</Typography>
                        </Button>
                    </Grid>
                    
                        <Stack direction="row" sx={{mt: 1, display: 'none'}} className="nothing">
                            <Button size="small"  sx={{color: blue[700], fontWeight: 600, fontSize: '.75rem'}}>Passed</Button>
                            <Button size="small"  sx={{color: orange[700], fontWeight: 600, fontSize: '.75rem'}}>Re-assess</Button>
                            <Button size="small"  sx={{color: red[700], fontWeight: 600, fontSize: '.75rem'}}>Failed</Button>
                            
                        </Stack>
                </Grid>

                <Grid item lg={''}>
                    <Grid container justifyContent="flex-end" alignItems="center" sx={{background: ''}}>
                    {isEditing?
                        <>
                        <Button onClick={handleCancel} sx={{fontSize: '.7rem',height: 1,fontWeight: 600, background: 'whitesmoke', mr: 1, boxShadow: '.5px .5px 2px #90a4ae'}} size="small">
                            Cancel
                        </Button>
                        <Button onClick={handleSave} sx={{fontSize: '.7rem',height: 1, color: 'white',fontWeight: 600, background: green[600], mr: 4, my: 0, boxShadow: '.5px .5px 2px #90a4ae'}} size="small">
                            UPDATE
                        </Button>
                        </>
                        : 
                        <IconButton
                            size="small" 
                            aria-label="delete" 
                            onClick={handleClickEdit}
                            sx={{background: 'whitesmoke', mr: 2, boxShadow: '.5px .5px 2px #90a4ae'}}>
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    }
                    <IconButton
                        size="small" 
                        aria-label="delete" 
                        onClick={handleClickDelete}
                        sx={{background: 'whitesmoke', mr: 2, boxShadow: '.5px .5px 2px #90a4ae'}}>
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                        size="small" 
                        aria-label="delete" 
                        onClick={handlePrint}
                        sx={{background: 'whitesmoke', mr: 2, boxShadow: '.5px .5px 2px #90a4ae'}}>
                        <PrintIcon fontSize="inherit" />
                    </IconButton>
                    
                        <IconButton aria-label="add" sx={{color: 'white',background: blueGrey[900], boxShadow: '.5px .5px 2px #90a4ae'}} onClick={handleClickAdd}>
                            <AddIcon />
                        </IconButton>
                        {/* <Typography body2="h6" component="p" sx={{color: blueGrey[200]}}>Add new applicant</Typography> */}
                    
                    </Grid>
                </Grid>

            </Grid>


            <Grid container  alignItems="" justifyContent="space-between" sx={{mt: 3, mb: 0, background: ''}}>
                
                {/* Start of sheet */}
                <Grid item lg={12} sx={{mb: 0, pb: 0, background: 'white', borderRadius: '10px 10px 0 0'}}>
                    
                    {/* Header */}
                    <Grid container justifyContent="flex-start" alignItems="center" sx={{height: '75px', borderRadius: '10px 10px 0 0',background:  deepPurple[800], px: 3, boxShadow: '1px 1px 1px #cfd8da', border: '1px solid #e3f2fd'}}>
                        <Grid item >
                            <CircleIcon sx={{color: 'white'}}/>
                        </Grid>
                        <Grid item >
                            <Stack direction="row" sx={{ml: 2}}>
                                <Typography variant="h3" sx={{fontSize: '1.3rem',fontWeight: 800, py: '', px: 0, color:  'white', mr: 1}}>{loading? '': `${shift.First_Name} ${shift.Surname}`}</Typography>
                                {dash}
                                <Typography variant="h3" sx={{fontSize: '1.3rem',fontWeight: 800, py: '', px: 0, color:  'white', mr: 1}}>{loading? '': ` ${shift.Date}`}</Typography>
                                {dash}
                                <Typography variant="h3" sx={{fontSize: '1.3rem',fontWeight: 800, py: '', px: 0, color:  'white', mr: 1}}>{loading? '': `${shift.Client}`}</Typography>
                                {/* <Typography variant="h3" sx={{fontSize: '1.3rem',fontWeight: 800, py: '', px: 0, color:  deepPurple[200]}}>Mhindu</Typography> */}

                            </Stack>
                        </Grid>
                    </Grid>
                    
                    {loading? 'loading' : 

                    <>
                        <Grid container justifyContent="space-between" alignItems="center" sx={{mt: 1, pr: 2, background: ''}}>
                            <Grid item>
                                <Stack direction="row" justifyContent="flex-end" alignItems="center">
                                    <WorkIcon sx={{ fontSize: 40,color: deepPurple[800], mx: 2}}/>
                                    <Typography variant="h3" sx={{fontSize: 25 ,fontWeight: 800, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  deepPurple[300]}}>Shift</Typography>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
                                    <Typography variant="h3" sx={{fontSize: 40 ,fontWeight: 800, fontFamily: 'Poppins-Medium', py: '', px: 0, color:  deepPurple[800], mr: 1}}>{shift.Start_Time}am</Typography>
                                </Stack>
                                <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  deepPurple[300]}}>12/02/2022 - 12/03/2022</Typography>

                            </Grid>
                        </Grid>

                        <Grid item lg={12} sx={{background: '', p: 2, mt: 2, borderRadius: '5px 5px 0 0'}}>
                        
                            <Grid container sx={{mt: 1}}>

                                <Grid item lg={12} sx={{mt: 1}}>
                                    <Stack direction="row" alignItems="center"  sx={{mb: 2, mt: 0}}>
                                        <Typography variant="body1" sx={{fontWeight: 900}}>Key Points</Typography>
                                        <Typography variant="body2" sx={{fontSize: '0.7rem', fontWeight: 500, px: 1, py: 0,ml: 1,color: 'white', background: deepPurple[700], borderRadius: '5px'}}>Info</Typography>
                                    </Stack>
                                        <Paper sx={{mt: 0,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                            <Grid container justifyContent="space-around">
                                                <Stack sx={{mt: 0}}>
                                                    <Typography sx={{fontSize: 15 ,fontWeight: 500, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  deepPurple[500]}}>Date</Typography>
                                                    <Typography sx={{fontSize: 17 ,fontWeight: 600, fontFamily: 'Poppins-Bold', py: '', px: 0, color:  blueGrey[900], mt: 1}}>{shift.Date}</Typography>
                                                </Stack>
                                                <Stack sx={{mt: 0}}>
                                                    <Typography sx={{fontSize: 15 ,fontWeight: 500, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  deepPurple[500]}}>Start Time</Typography>
                                                    <Typography sx={{fontSize: 17 ,fontWeight: 600, fontFamily: 'Poppins-Bold', py: '', px: 0, color:  blueGrey[900], mt: 1}}>{shift.Start_Time}</Typography>
                                                </Stack>
                                                <Stack sx={{mt: 0}}>
                                                    <Typography sx={{fontSize: 15 ,fontWeight: 500, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  deepPurple[500]}}>End Time</Typography>
                                                    <Typography sx={{fontSize: 17 ,fontWeight: 600, fontFamily: 'Poppins-Bold', py: '', px: 0, color:  blueGrey[900], mt: 1}}>{shift.End_Time}</Typography>
                                                </Stack>
                                                <Stack sx={{mt: 0}}>
                                                    <Typography sx={{fontSize: 15 ,fontWeight: 500, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  deepPurple[500]}}>Duration</Typography>
                                                    <Typography sx={{fontSize: 17 ,fontWeight: 600, fontFamily: 'Poppins-Bold', py: '', px: 0, color:  blueGrey[900], mt: 1}}>{'2hrs'}</Typography>
                                                </Stack>
                                                <Stack sx={{mt: 0}}>
                                                    <Typography sx={{fontSize: 15 ,fontWeight: 500, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  deepPurple[500]}}>Break Deducted</Typography>
                                                    <Typography sx={{fontSize: 17 ,fontWeight: 600, fontFamily: 'Poppins-Bold', py: '', px: 0, color:  blueGrey[900], mt: 1}}>{'30mins'}</Typography>
                                                </Stack>
                                            </Grid>
                                        </Paper>
                                        
                                </Grid>
                            <Grid item lg={12} sx={{mt: 1}}>

                                    <Stack direction="row" alignItems="center"  sx={{mb: 2, mt: 3}}>
                                        <Typography variant="body1" sx={{fontWeight: 900}}>Shift Details</Typography>
                                        <CircleIcon fontSize="small" sx={{color: deepPurple[800], ml: 1}}/>
                                    </Stack>
                                    <Paper sx={{mt: 0,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                
                                        <Form>
                                        
                                            <Row className="mt-3">
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                    <Form.Label className="view-label mb-3">Division</Form.Label>
                                                    <br/>
                                                    {
                                                        isEditing? 
                                                            <Select 
                                                                value={workers[0]}
                                                                options={workers}
                                                                closeMenuOnSelect ={false}
                                                                isMulti
                                                                components={animatedComponents}
                                                                placeholder="Select division ..."
                                                            />  :
                                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Construction</Typography>
                                                    }
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                    <Form.Label className="view-label mb-3">Job Role</Form.Label>
                                                    <br/>
                                                    {
                                                        isEditing? 
                                                            <Select 
                                                                value={workers[0]}
                                                                options={workers}
                                                                closeMenuOnSelect ={false}
                                                                isMulti
                                                                components={animatedComponents}
                                                                placeholder="Select division ..."
                                                            />  :
                                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Class 1</Typography>
                                                    }
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                    <Form.Label className="view-label mb-3">Consultant</Form.Label>
                                                    <br/>
                                                    {
                                                        isEditing? 
                                                            <Select 
                                                                value={workers[0]}
                                                                options={workers}
                                                                closeMenuOnSelect ={false}
                                                                isMulti
                                                                components={animatedComponents}
                                                                placeholder="Select staff ..."
                                                            />  :
                                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${shift.Staff_FName} ${shift.Staff_SName}`}</Typography>
                                                    }
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                    <Form.Label className="view-label mb-3">Shift Status</Form.Label>
                                                    <br/>
                                                    {
                                                        isEditing? 
                                                            <Select 
                                                                value={workers[0]}
                                                                options={workers}
                                                                closeMenuOnSelect ={false}
                                                                isMulti
                                                                components={animatedComponents}
                                                                placeholder="Select type ..."
                                                            />  :
                                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: deepOrange[400], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : shift.Shift_Status}</Typography>
                                                    }
                                                </Form.Group>
                                            

                                            </Row>
                                            <Row className="mt-3">
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                    <Form.Label className="view-label mb-3">Shift Status</Form.Label>
                                                    <br/>
                                                    {
                                                        isEditing? 
                                                            <Select 
                                                                value={workers[0]}
                                                                options={workers}
                                                                closeMenuOnSelect ={false}
                                                                isMulti
                                                                components={animatedComponents}
                                                                placeholder="Select type ..."
                                                            />  :
                                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: deepOrange[400], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : shift.Shift_Status}</Typography>
                                                    }
                                                </Form.Group>
                                                
                                            </Row>
                                        </Form>
                                    </Paper>

                                

                                
                            
                            </Grid>
                            </Grid>
                        </Grid>
                    </>
                    } 

                </Grid>
                {/* End of Sheet */}

               

            </Grid>

            <Grid container spacing={1} sx={{mt: 3, mb: 1, background: ''}}>

        <Grid item lg={12} sx={{mb: 0, pb: 0}}>


        </Grid>

       

        </Grid>
        </>
    )
}

export default ViewShift

{/* <Grid item sx={{pr: 4, borderRight: '1px solid #eceff1'}}>
<Stack sx={{ml: 2}}>
    <Typography variant="h1" sx={{fontSize: '1.7rem',fontWeight: 800, py: '', px: 0,color: 'blueGrey[800]', background: 'blueGrey[200]'}}>{values ? values.name : 'Employee Name'}</Typography>
        <Divider orientation="vertical" variant="middle" />
    <Stack direction="row" justifyContent="space-between" sx={{mt: '7px'}}>
            <Box sx={{px:0, py: 0, display: 'flex', justifyContent: "space-around", alignItems: "center", background: '', borderRadius: '10px'}}>
                <Typography variant="" sx={{fontSize: '', fontWeight: 500, py: 0, pl: 0,color: blueGrey[700], background: 'blueGrey[200]'}}>{headerFor == 'worker-details' ? 'Department:' : 'Tag:'}</Typography>
                <Typography variant="" sx={{fontSize: '.7rem', fontWeight: 500, px: 1, py: 0,ml: 1,color: blueGrey[50], background: 'dodgerblue', borderRadius: '10px'}}>{headerFor == 'worker-details' ? 'Employee Department' : 'Immediate Hire'}</Typography>
            </Box>
    </Stack>

    
</Stack>
</Grid> */}