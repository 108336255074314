import { STAFF_LIST_REQUEST, STAFF_LIST_SUCCESS, STAFF_LIST_FAIL} from "../Constants/staffConstants";

const staffListReducer = (state = {staff: []}, action) => {

    switch (action.type)
    {
        case STAFF_LIST_REQUEST:
            return {loading: true};
 
        case STAFF_LIST_SUCCESS:
            console.log('Code is reaching the reducer and staff data is:' + action.payload)
            return {
                loading: false,
                staff: action.payload
            }
        case STAFF_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 


export {staffListReducer}