import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress} from '@mui/material';
import { blueGrey, orange, blue, purple, red, cyan, deepOrange, green, indigo, pink } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicantsList } from '../../../../../Redux/Actions/ApplicantsActions';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getAssessmentTypesList } from '../../../../../Redux/Actions/AssessmentTypesActions';
import { getAssessmentResultsList } from '../../../../../Redux/Actions/AssessmentResultsActions';
import { getAssessmentStatusesList } from '../../../../../Redux/Actions/AssessmentStatusesActions';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import useAlert from '../../../../../Hooks/useAlert';
import ComplexAlert from '../../../../ComplexAlert';
import Select from 'react-select';
import makeAnimated from 'react-select/animated'
import { getWorker } from '../../../../../Redux/Actions/WorkerActions';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';



const ViewAccAndIncidentDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();
    const id = info;


    //GET WORKER
    const worker_records = useSelector(state => state.worker_View);
    const {loading: loading_worker, worker} = worker_records;

    //GET DIVISIONS
    const divisions_ListState = useSelector(state => state.divisions_List);
    const {divisions, loading} = divisions_ListState;

    //GET ASSESSMENT TYPE
    const types_ListState = useSelector(state => state.assessmentTypes_List);
    const {assessmentTypes, loading: loading_types} = types_ListState;

    //GET ASSESSMENT RESULT
    const results_ListState = useSelector(state => state.assessmentResults_List);
    const {assessmentResults, loading: loading_results} = results_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;

    //GET ASSESSMENT STATUS
    const statuses_ListState = useSelector(state => state.assessmentStatuses_List);
    const {assessmentStatuses, loading: loading_statuses} = statuses_ListState;

    //GET APPLICANTS
    const applicants_List_State = useSelector((state) => state.applicants_List);
    const {loading: loading_applicants, applicants} = applicants_List_State;
    
    
       
    //CURRENT DATE
    const d = new Date(), yyyy = d.getFullYear(), mm = d.getMonth(), dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getWorker(id));
        dispatch(getDivisions());
        dispatch(getApplicantsList());
        dispatch(getStaffList());
        dispatch(getAssessmentTypesList());
        dispatch(getAssessmentResultsList());
        dispatch(getAssessmentStatusesList());
       
    }, [])
    

    const [values, setValues] = useState({
            fname: '', 
            surname: loading_worker? '' : worker.Surname, 
            dob: loading_worker? '' : worker.DOB, 
            insurance: loading_worker? '' : worker.Insurance_No, 
            email: loading_worker? '' : worker.Email, 
            address1: loading_worker? '' : worker.Address1, 
            address2: loading_worker? '' : worker.Address2, 
            postcode: loading_worker? '' : worker.Postcode, 
            town: loading_worker? '' : worker.Postcode, 
            mobile: loading_worker? '' : worker.Mobile, 
            home: loading_worker? '' : worker.Home_Phone, 
            staff: '' , 
            division: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            
 
        }
    }


    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        showAlert('info','You can now edit this task',true)
        setIsEditing(!isEditing);
    }

    //USE ALERT
    const {alert, showAlert} = useAlert();
    
    const animatedComponents = makeAnimated();

    //const workers = ['Emily Bone', 'Shawn Drisic', 'Isaac Phily']
    const workers = [
        {value: 'Emily Bone', label: 'Emily Bone'},
        {value: 'John Cusa', label: 'John Cusa'},
        {value: 'Mah Jani', label: 'Mah Jani'},
    ]
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" >
            {/* <h3>I am receiving info: {info.Assessment_Subject}</h3> */}
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: purple[700]}} className=""  >
                <Stack direction="row" alignItems="center">
                    <CurrencyPoundIcon sx={{color: purple[50], mr: 2, fontSize: '1.5rem'}}/>
                    <Typography variant="h6" sx={{color: purple[50],fontWeight: 700}}>View Staff</Typography>
                </Stack>
                
            </DialogTitle>

        {alert.show? 
        <ComplexAlert alert={alert} removeAlert={showAlert}/> : ''
        }
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">
                    <Grid item lg={9}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[800], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Worker ID</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled type="text" value={`WRK - ${loading_worker? '' : worker.Worker_Id}`} className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">First Name</Form.Label>
                                    <Form.Control name="fname" onChange={handleChange}  disabled={isEditing? false : true} type="text" value={loading_worker? '' : worker.First_Name} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Surname</Form.Label>
                                    <Form.Control name="surname" disabled={isEditing? false : true} type="text" value={values.surname} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">DOB</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled type="text" value={values.dob} className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Insurance No.</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.insurance} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Email</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.email} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Mobile No.</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.mobile} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Home Phone No.</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.home} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Address 1</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.address1} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Address 2</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.address2} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Postcode</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.postcode} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Town/City</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.town} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                        
                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: purple[700], borderRadius: '5px', px: 2, py: '5px',}}>Worker Particulars</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Division</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             components={animatedComponents}
                                             placeholder="Select division ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>Construction</Typography>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Consultant</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             value={workers[0]}
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             isMulti
                                             components={animatedComponents}
                                             placeholder="Select staff ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Billy Baker</Typography>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Status</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             value={'In Progress'}
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             isMulti
                                             components={animatedComponents}
                                             placeholder="Select staff ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: green[500], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Active</Typography>
                                    }
                                </Form.Group>

                                
                            </Row>

                            <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px',}}>Payment Route</Typography>

                            <Row>
                               <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Payroll Setup</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             value={'Pending'}
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             isMulti
                                             components={animatedComponents}
                                             placeholder="Select type ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>Type</Typography>
                                    }
                                </Form.Group>
                               <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Payroll Doc Type</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             value={'Pending'}
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             isMulti
                                             components={animatedComponents}
                                             placeholder="Select type ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>Type</Typography>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Umbrella</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             value={workers[0]}
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             isMulti
                                             components={animatedComponents}
                                             placeholder="Select staff ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Billy Baker</Typography>
                                    }
                                </Form.Group>
                            
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">UTR No.</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled type="text" value={`WRK - ${info.Worker_Id}`} className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">CIS No.</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.fname} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                
                            </Row>

                           
                            <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: purple[700], borderRadius: '5px', px: 2, py: '5px',}}>Company</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Company</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled type="text" value={`WRK - ${info.Worker_Id}`} className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Company VAT</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.fname} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                
                            </Row>
                           
                        </Form>
                    </Grid>

                    {/* <Grid item lg={2} sx={{mr: 5}}>
                        <Stack sx={{mt: 3}} alignItems="center">
                            <Typography sx={{textAlign: 'center', mb: 3, color: 'gray'}}>Actions</Typography>
                            <Button variant="outlined" size="small" onClick={handleEditClick} endIcon={isEditing? <CloseIcon fontSize="small"/> : <EditIcon fontSize="small"/>} sx={{mb: 1,fontSize: '.7rem', color: isEditing? 'white' : '', border: '1px solid #80deea', background: isEditing? cyan[700] : cyan[50], width: '75%', boxShadow: 'none', px: 0}}>{isEditing? 'Cancel' : 'Edit'}</Button>
                            <Button variant="outlined" size="small" endIcon={<DeleteIcon fontSize="small" sx={{color: 'red[500]'}}/>} sx={{mb: 1, fontSize: '.7rem', color: '', border: '1px solid #80deea', background: red[50], width: '75%', boxShadow: 'none'}}>Delete</Button>

                        </Stack>
                    </Grid> */}
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            {/* <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: 'blue', '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Changes</Button> */}
        </DialogActions>
    </Dialog>
    )
}

export default ViewAccAndIncidentDialog
