import React, { useState } from 'react';
import Sidebar from './Components/Sidebar';
import {useNavigate} from 'react-router-dom';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import { Box, Grid, Paper, TextField, Button, FormControlLabel, Checkbox, FormControl, OutlinedInput, MenuItem } from '@mui/material';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import LoginScreen from './Screens/Login/LoginScreen';
import { styled } from '@mui/material/styles';
import { useFormControl } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import ComplexLogo from './assets/ComplexLogo.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MiniDrawer from './Components/Main/MiniDrawer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000'
    }
  }
})

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  function MyFormHelperText() {
    const { focused } = useFormControl() || {};
  
    const helperText = React.useMemo(() => {
      if (focused) {
        return 'This field is being focused';
      }
  
      return 'Helper text';
    }, [focused]);
  
    return <FormHelperText>{helperText}</FormHelperText>;
  }

  const currencies = [
    {
      value: 'Office Staff',
      label: 'Office Staff',
    },
    {
      value: 'New members',
      label: 'New members',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];
  

  
  
  const App = () => {
    // let navigate = useNavigate();
    
    const permitToEnter = ()=> {
      setLogin(false);
      //navigate('/dashboard')
    }
    const [currency, setCurrency] = React.useState('Office Staff');
      const handleChange = (event) => {
        setCurrency(event.target.value);
      };
  const [login, setLogin] = useState(true);

  return (

    <ThemeProvider theme={theme}>
      <BrowserRouter>

        {login? 
        // <LoginScreen login={login}/>
        <LoginScreen login={login} setLogin={setLogin}/>
        :
        <MiniDrawer/>
        }
      </BrowserRouter>
    </ThemeProvider>
  )
}
 
export default App
