import { SHIFTPREFERENCES_LIST_REQUEST, SHIFTPREFERENCES_LIST_SUCCESS, SHIFTPREFERENCES_LIST_FAIL} from "../Constants/shiftPreferenceConstants";

const shiftPreferencesListReducer = (state = {shiftPreferences: []}, action) => {

    switch (action.type)
    {
        case SHIFTPREFERENCES_LIST_REQUEST:
            return {loading: true};
 
        case SHIFTPREFERENCES_LIST_SUCCESS:
            console.log('Code is reaching the reducer and shiftPreferences data is:' + action.payload)
            return {
                loading: false,
                shiftPreferences: action.payload
            }
        case SHIFTPREFERENCES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 


export {shiftPreferencesListReducer}