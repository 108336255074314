export const TRANSACTIONTYPES_LIST_REQUEST = 'TRANSACTIONTYPES_LIST_REQUEST'
export const TRANSACTIONTYPES_LIST_SUCCESS = 'TRANSACTIONTYPES_LIST_SUCCESS'
export const TRANSACTIONTYPES_LIST_FAIL = 'TRANSACTIONTYPES_LIST_FAIL'


export const TRANSACTIONTYPE_CREATE_REQUEST = 'TRANSACTIONTYPE_CREATE_REQUEST'
export const TRANSACTIONTYPE_CREATE_SUCCESS = 'TRANSACTIONTYPE_CREATE_SUCCESS'
export const TRANSACTIONTYPE_CREATE_FAIL = 'TRANSACTIONTYPE_CREATE_FAIL'


export const TRANSACTIONTYPE_UPDATE_REQUEST = 'TRANSACTIONTYPE_UPDATE_REQUEST'
export const TRANSACTIONTYPE_UPDATE_SUCCESS = 'TRANSACTIONTYPE_UPDATE_SUCCESS'
export const TRANSACTIONTYPE_UPDATE_FAIL = 'TRANSACTIONTYPE_UPDATE_FAIL'

export const TRANSACTIONTYPE_DELETE_REQUEST = 'TRANSACTIONTYPE_DELETE_REQUEST'
export const TRANSACTIONTYPE_DELETE_SUCCESS = 'TRANSACTIONTYPE_DELETE_SUCCESS'
export const TRANSACTIONTYPE_DELETE_FAIL = 'TRANSACTIONTYPE_DELETE_FAIL'

export const VIEW_TRANSACTIONTYPE_REQUEST = 'VIEW_TRANSACTIONTYPE_REQUEST'
export const VIEW_TRANSACTIONTYPE_SUCCESS = 'VIEW_TRANSACTIONTYPE_SUCCESS'
export const VIEW_TRANSACTIONTYPE_FAIL = 'VIEW_TRANSACTIONTYPE_FAIL'