import { ASSESSMENTRESULT_LIST_REQUEST, ASSESSMENTRESULT_LIST_SUCCESS, ASSESSMENTRESULT_LIST_FAIL} from "../Constants/assessmentResultsConstants";

const assessmentResultsListReducer = (state = {assessmentResults: []}, action) => {
 
    switch (action.type)
    {
        case ASSESSMENTRESULT_LIST_REQUEST:
            return {loading: true};
 
        case ASSESSMENTRESULT_LIST_SUCCESS:
            console.log('Code is reaching the reducer and assessmentResults data is:' + (action.payload)[0].Profile_Id)
            return {
                loading: false,
                assessmentResults: action.payload
            }
        case ASSESSMENTRESULT_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 


export {assessmentResultsListReducer}