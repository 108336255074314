import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox} from '@mui/material';
import {  blue, } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import api from '../../../../../config/apiConfig';
import { getClientsList } from '../../../../../Redux/Actions/ClientsActions';
import { submitContact, getContactsList } from '../../../../../Redux/Actions/ContactActions';
import { getVisitsList } from '../../../../../Redux/Actions/VisitActions';
import { submitProspect } from '../../../../../Redux/Actions/ProspectActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SummaryBox from '../../../../Utilities/SummaryBox';
import SideCrumbs from '../../../../Utilities/SideCrumbs';


const AddProspectDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();  

    //GET CONTACTS
    const contacts_ListState = useSelector(state => state.contacts_List);
    const {contacts, loading} = contacts_ListState;

    //GET VISITS
    const visits_ListState = useSelector(state => state.visits_List);
    const {visits, loading: loading_visits} = visits_ListState;

    //GET CLIENTS
    const clients_ListState = useSelector(state => state.clients_List);
    const {clients, loading: loading_clients} = clients_ListState;

    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getClientsList());
        dispatch(getVisitsList());
        dispatch(getContactsList());
        
       
    }, [])
    

    const [values, setValues] = useState({
            
            name: '',
            companyno: '' , 
            vatno: '' , 
            credit_rating: '' , 
            credit_limit: '' , 
            main_location: '' , 
            branches: '' , 
            workers: '' , 
            contacts: '',
            visits: '',
            clients: '',
        });

    const [submitValues, setSubmitValues] = useState({
            
            name: '',
            companyno: '' , 
            vatno: '' , 
            credit_rating: '' , 
            credit_limit: '' , 
            main_location: '' , 
            branches: '' , 
            workers: '' , 
            contacts: '',
            visits: '',
            clients: '',
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();


    const handleSave = () => {
        console.log('in save function')
        dispatch(submitProspect(values, submitValues));
        handleSubmit('success','Successfully created prospect');

    }

    const animatedComponents = makeAnimated();


    const [inputValue, setInputValue] = useState('');
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)


    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
 
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[700]}}  >
                
                <ContactPageIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add Prospect </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
        
    
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    <SideCrumbs/>
                   
                             
                           
                    {/* <p>isPrimary: {submitValues.isPrimary}</p>
                    <p>fname: {submitValues.firstname}</p>
                    <p>mobile: {submitValues.mobile}</p>
                    <p>contatyp: {submitValues.contacttypes}</p>
                    <p>clients: {submitValues.clients}</p>
                    <p>department: {submitValues.departments}</p>
                    <p>influence: {submitValues.influences}</p>
                    <p>jrole: {submitValues.jobroles}</p> */}
                       
                        <Grid item lg={10}>
                            <Form className="my-3">
                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: 'whitesmoke', borderRadius: '5px', px: 2, py: '5px'}}>Basic Info</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2 ">
                                    <Form.Label className="view-label">Prospect ID</Form.Label>
                                    <Form.Control disabled type="text" value="PRS-3" placeholder="E.g 123qst1" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Name</Form.Label>
                                    <Form.Control type="text" name="name" onChange={handleChange} value={values.name} placeholder="Name" className="outline-none border-none" />
                                </Form.Group>
                            </Row>

                            <br/>
                            
                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: blue[50], borderRadius: '5px', px: 2, py: '5px'}}>Company Info</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Company No</Form.Label>
                                    <Form.Control type="text" name="companyno" onChange={handleChange} value={values.companyno}  placeholder="Company No" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">VAT No</Form.Label>
                                    <Form.Control type="text" name="vatno" onChange={handleChange} value={values.vatno}  placeholder="VAT No" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Credit Rating</Form.Label>
                                    <Form.Control type="text" name="credit_rating" onChange={handleChange} value={values.credit_rating}  placeholder="Credit Rating" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Credit Limit</Form.Label>
                                    <Form.Control type="text" name="credit_limit" onChange={handleChange} value={values.credit_limit}  placeholder="Credit Limit" className="outline-none border-none" />
                                </Form.Group>
                                
                            </Row>
                            <br/>
                            
                            <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>What do they need?</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Main Location</Form.Label>
                                    <Form.Control type="text" name="main_location" onChange={handleChange} value={values.main_location}  placeholder="Main location" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">No of Branches</Form.Label>
                                    <Form.Control type="number" name="branches" onChange={handleChange} value={values.branches}  placeholder="No of branches" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Workers Used</Form.Label>
                                    <Form.Control type="number" name="workers" onChange={handleChange} value={values.workers}  placeholder="No of workers" className="outline-none border-none" />
                                </Form.Group>
                               
                                
                            </Row>

                            <br/>

                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: 'whitesmoke', borderRadius: '5px', px: 2, py: '5px'}}>Basic Info</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Contacts</Form.Label>
                                    <AsyncSelect
                                        value={values.contacts}
                                        name="contacts"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'contacts')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for contact ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Visits</Form.Label>
                                    <AsyncSelect
                                        value={values.visits}
                                        name="visits"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'visits')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for visit ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Client</Form.Label>
                                        <AsyncSelect
                                            value={values.clients}
                                            name="clients"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'clients')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for client ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>
                                
                               
                                
                            </Row>
                        </Form>
                        
                 

                    
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[600], '&:hover': {color: blue[900]}}} onClick={handleSave}>Save Prospect</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddProspectDialog
