export  const SET_ACTIVE = 'SET_ACTIVE';
export  const SET_ACTIVE_ERROR = 'SET_ACTIVE_ERROR';

export  const SET_L1_ACTIVE = 'SET_L1_ACTIVE';
export  const SET_L1_ACTIVE_ERROR = 'SET_L1_ACTIVE_ERROR';

export  const SET_L2_ACTIVE = 'SET_L2_ACTIVE';
export  const SET_L2_ACTIVE_ERROR = 'SET_L2_ACTIVE_ERROR';

export  const SET_L3_ACTIVE = 'SET_L3_ACTIVE';
export  const SET_L3_ACTIVE_ERROR = 'SET_L3_ACTIVE_ERROR';