import React, {useState, useRef, useEffect} from 'react'
import PageHeader from '../../../Components/PageHeader';
import { Typography, Paper, Grid, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Button, Card, ToggleButtonGroup, ToggleButton, ClickAwayListener, MenuItem, MenuList, Popper, Grow, Divider, IconButton, TextField, Alert, List, ListItem, LinearProgress, Box, Tabs, Tab, Stack, ListItemText, ListItemAvatar, Avatar, ListItemIcon, Breadcrumbs } from '@mui/material';
import useForm from '../../../Hooks/useForm';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router'; 
import { useParams} from 'react-router-dom';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EventIcon from '@mui/icons-material/Event';
import ComplexAlert from '../../../Components/ComplexAlert';
import { blueGrey, green, blue, deepOrange, red, orange } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import { getApplicantsList } from '../../../Redux/Actions/ApplicantsActions';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { Form, Row, Col } from 'react-bootstrap';
import { yellow } from '@material-ui/core/colors';
import DetailHeader from '../../../Components/Applicants/DetailHeader';
import Select from 'react-select';
import makeAnimated from 'react-select/animated'
import { getAssessment } from '../../../Redux/Actions/AssessmentsActions';
import Crumbs from '../../../Components/Utilities/Crumbs';
import ViewTitle from '../../../Components/Utilities/ViewTitle';
import ViewStatus from '../../../Components/Utilities/ViewStatus';
import CRUD from '../../../Components/Utilities/CRUD';
import useCRUD from '../../../Hooks/useCRUD';
import FormHeading from '../../../Components/Utilities/FormHeading';
import AssessmentDialogs from '../../../Components/Dialogs/CRM/HR/AssessmentDialogs/AssessmentDialogs';
  
 

//Custom Button extended from MUI Button

const ReturnButton = styled(Button)(({theme})=> ({

     backgroundColor: 'red',
     '&:hover': { 
        backgroundColor: blueGrey[400],
     }
    })
)


const ViewAssessment = () => {
 
    //GET ID FROM ROUTE
    const {id} = useParams();

    //ACCESS DISPATCH FUNCTION
    const dispatch = useDispatch();
    

    //GET ASSESSMENT
    const assessment_records = useSelector(state => state.assessment_View);
    const {loading, assessment} = assessment_records;

     //GET APPLICANTS
     const applicants_List_State = useSelector((state) => state.applicants_List);
     const {loading: loading_applicants, applicants} = applicants_List_State;

//----------------------------------------------------------------------------------------------------------------------------------
    
    //ON COMPONENT DID MOUNT
    useEffect(()=> {
        dispatch(getAssessment(id));
        dispatch(getApplicantsList());
        
    }, [])
   
    //Other stuff
    const [open, setOpen] = useState();

    const anchorRef = React.useRef(null);

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
 
    //Redirect to provious page ---------------------

    const navigate = useNavigate()
  

    // handling form inputs

    const {values, handleChange} = useForm(id);

//----------------------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------------------   
    const {isEditing, handleCreate, handleEdit, handleDelete, setIsEditing, handlePrint, alert, showAlert, dialog, showDialog, handleSubmit, closeDialog } = useCRUD();

//----------------------------------------------------------------------------------------------------------------------------------

//DEALING WITH THE TABS
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


//----------------------------------------------------------------------------------------------------------------------------------
const animatedComponents = makeAnimated();

const workers = [
    {value: 'Emily Bone', label: 'Emily Bone'},
    {value: 'John Cusa', label: 'John Cusa'},
    {value: 'Mah Jani', label: 'Mah Jani'},
]
   
 

    return (
        <div> 
            <PageHeader page="Assessment Details" previous assessments task={values.name} />
            <Crumbs root="CRM" middle="assessments" end="Details"/>
            <ViewTitle first={loading? '' : assessment.Applicant} second={loading? '' : assessment.Date} third={loading? '' : assessment.Time} firstIcon={<EventIcon fontSize="small" sx={{color: blue[300]}}/>} secondIcon={<WatchLaterIcon fontSize="small" sx={{color: blue[300]}}/>} />
            
            <Grid container justifyContent="space-between" alignItems= "center"  sx={{mt: 2, mb: 2, px:0, borderRadius: '20px', color: 'gray', background: ''}}>
                <ViewStatus/>
                <CRUD isEditing={isEditing} handleCreate={handleCreate} handleEdit={handleEdit} handleDelete={handleDelete} setIsEditing={setIsEditing} showAlert ={showAlert } handlePrint/>
            </Grid>

           
            
            {alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/>: ''}
            {dialog.open? <AssessmentDialogs open={dialog.open} onClose={closeDialog} {...dialog}/>: ''}
            {loading ? <Box sx={{mt: 3,  color: 'orange', width: '100%' }}><LinearProgress sx={{ color: 'orange'}} /></Box> 
            : 
            
            <TabPanel value={tabValue} index={0}>
                <Grid container spacing={1} sx={{mt: 0, mb: 1, background: 'white',borderRadius: '5px', p: 3}}>

                    
                    <Grid item lg={9} sx={{mt: 0}}>
                        <FormHeading title="Basic Details" badge="Info" icon=""/>
                            <Paper sx={{mt: 2,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                
                                <Form className="">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridEmail" >
                                            <Form.Label className="view-label mb-3">Assessment ID</Form.Label>
                                            <Form.Control disabled type="email" value={`ASS-${id}`} placeholder="E.g 123qst1" className={`${isEditing? '' : 'border-0  '}`} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label className="view-label mb-3 ">Applicant Name</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        isMulti
                                                        components={animatedComponents}
                                                        placeholder="Select applicants ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : assessment.Applicant}</Typography>
                                            }
                                        </Form.Group>
                                        
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} controlId="formGridEmail" >
                                            <Form.Label className="view-label mb-3">Assessment Subject</Form.Label>
                                            <Form.Control autoFocus={isEditing? true : false} disabled type="email" value={loading? '' : assessment.Subject} placeholder="E.g John" className={`${isEditing? '' : 'border-0 '}`} />
                                        </Form.Group>
                                    </Row>
                                </Form>
                            </Paper>

                            <FormHeading title="Assignment Specifics" badge="" icon="circle"/>
                            <Paper sx={{mt: 0,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                           
                                <Form>
                                   
                                    <Row className="mt-3">
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="view-label mb-3">Division</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        isMulti
                                                        components={animatedComponents}
                                                        placeholder="Select division ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Construction</Typography>
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="view-label mb-3">Consultant</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        isMulti
                                                        components={animatedComponents}
                                                        placeholder="Select staff ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : assessment.Staff}</Typography>
                                            }
                                        </Form.Group>
                                       

                                    </Row>
                                    <Row className="mt-3">
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="view-label mb-3">Assessment Type</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        isMulti
                                                        components={animatedComponents}
                                                        placeholder="Select type ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: deepOrange[400], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{assessment.Type_Name}</Typography>
                                            }
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="view-label mb-3">Assessment Status</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        isMulti
                                                        components={animatedComponents}
                                                        placeholder="Select status ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{assessment.Status_Name}</Typography>
                                            }
                                        </Form.Group>
                                    </Row>
                                </Form>
                            </Paper>

                            <FormHeading title="Result" badge="" icon="circle"/>
                                <Paper sx={{mt: 0,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                    <Form>
                                    <Row className="mt-3">
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="view-label mb-3">Assessment Result</Form.Label>
                                            <br/>
                                            {
                                                isEditing? 
                                                    <Select 
                                                        value={workers[0]}
                                                        options={workers}
                                                        closeMenuOnSelect ={false}
                                                        components={animatedComponents}
                                                        placeholder="Select result ..."
                                                    />  :
                                            <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{assessment.Result_Name}</Typography>
                                            }
                                        </Form.Group>
                                    </Row>
                                    
                                </Form>
                            </Paper>

                           
                    </Grid>
                    
                     
                    <Grid item lg={3} sx={{ mt: 2, background: '', height: '600px', overflowY: 'hidden', overflow: 'scroll'}} className={`edit-summary ${isEditing? 'slide' : ''}`}>
                        <Stack direction="row" alignItems="center" justifyContent="center">
                            <Typography sx={{color: blue[600], fontSize: '', fontWeight: 700, textAlign: 'center', mt: 1, mr: 1}}>Edit Summary</Typography>
                            <IconButton size="small" sx={{background: 'white'}}>
                                <MoreHorizIcon fontSize="inherit"  />
                            </IconButton>
                        </Stack>
                            <Typography sx={{color: 'gray', fontSize: '.75rem', fontWeight: 500, textAlign: 'center', my: 2}}>No changes made yet.</Typography>
                            <Box sx={{pl: 3}}>
                            <Divider sx={{border: '.5px solid gray'}}/>
                            {/* <Stack direction="column" alignItems="flex-start" sx={{mt: 2, width: '100%'}}>
                                <Typography variant="body2" sx={{color: 'gray', fontWeight: 500,}}>Applicant ID: </Typography>
                                <Typography variant="body2" sx={{color: values.id? '' : blue[900], fontWeight: assessment.Assessments_Id? 800 : 500, ml: 0}}>{assessment.Assessments_Id? 'APP-' +assessment.Assessments_Id : 'null' }</Typography>
                            </Stack> */}
                            
                            </Box>
                        
                    </Grid>
                    <Grid item lg={3} sx={{mt: 5}}>
                        <Paper sx={{mt: 4,py: 2, background: 'rgb(242, 247, 252)', boxShadow: 'none', textAlign: 'center'}}>
                            
                            <Stack direction="row" alignItems="center" justifyContent="center"  sx={{my: 3}}>
                                <Typography variant="body2" sx={{fontSize: '0.7rem', fontWeight: 500, px: 1, py: 0,ml: 1,color: 'white', background: blue[700], borderRadius: '5px'}}>Navigation</Typography>
                            </Stack>
                            <Divider variant="middle" />
                            <br/>
                           
                            <Stack direction="column" alignItems="center">
                                <Button size="small" variant="contained" sx={{background: blueGrey[800], color: blue[50], fontSize: '.7rem', fontWeight: 700, boxShadow: 'none', mb: 1, width: '50%'}}>Details</Button>
                                <Button size="small" variant="contained" sx={{background: blueGrey[50], color: blueGrey[600], fontSize: '.7rem', fontWeight: 700, boxShadow: 'none', mb: 1, width: '50%'}}>Job Specifics</Button>
                                <Button size="small" variant="contained" sx={{background: blueGrey[50], color: blueGrey[600], fontSize: '.7rem', fontWeight: 700, boxShadow: 'none', mb: 1, width: '50%'}}>Applicant Specifics</Button>

                            </Stack>
                        </Paper>

                        
                    </Grid>
                    
                    
                </Grid>
            </TabPanel>
        }

            <TabPanel value={tabValue} index={1}>
            
    
                <Grid container spacing={1} sx={{mt: 3, mb: 0, background: ''}}>
                    
                    <Grid item lg={12} sx={{mb: 0, pb: 0}}>
                        
                        <DetailHeader values = {values} headerFor="applicant-details"/>

                    </Grid>
                    
                    <Grid item lg={12} sx={{mt: 0}}>
                                <Paper sx={{mt: 1,px: 4, py: 2, background: 'rgba(225, 225, 226, .2)', boxShadow: 'none'}}>
                                    <Stack direction="row" alignItems="center" sx={{mb: 3}}>
                                        <Typography variant="h5" sx={{fontWeight: 900}}>Documents</Typography>
                                        <Typography variant="" sx={{fontSize: '1rem', fontWeight: 700, px: 1, py: 0,ml: 1,color: blueGrey[900], background: 'moccasin', borderRadius: '5px'}}>3</Typography>

                                    </Stack>
                                    <List>
                                        <ListItem sx={{p:2}}>
                                            <AttachmentIcon sx={{color: blueGrey[500]}}/>
                                            <Typography variant="body1" sx={{color: blueGrey[900], ml: 2}}>Resume</Typography>
                                        </ListItem>
                                        <Divider/>
                                        <ListItem sx={{p:2}}>
                                            <AttachmentIcon sx={{color: blueGrey[500]}}/>
                                            <Typography variant="body1" sx={{color: blueGrey[900], ml: 2}}>Supporting Document 1</Typography>
                                        </ListItem>
                                        <Divider/>
                                        <ListItem sx={{p:2}}>
                                            <AttachmentIcon sx={{color: blueGrey[500]}}/> 
                                            <Typography variant="body1" sx={{color: blueGrey[900], ml: 2}}>Supporting Document 2</Typography>
                                        </ListItem>
                                        <Divider/>
                                        
                                    </List>
                                    
                                </Paper>
                        </Grid>
                        <Grid item lg={12}>
                            <Button variant="contained" sx={{width: '100%',mt: 2, background: blueGrey[700]}}>Send Email To Applicant</Button>

                        </Grid>

                </Grid>
            
                <Divider/>

            
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
        
  
            <Grid container spacing={1} alignItems="" justifyContent="space-between" sx={{mt: 3, mb: 0, background: ''}}>
                
                <Grid item lg={12} sx={{mb: 0, pb: 0}}>
                    
                    <DetailHeader values = {values} headerFor="applicant-details"/>

                </Grid>
                
                <Grid item lg={6} sx={{my: 1}}>
                            <Paper sx={{mt: 0,p: 4, background: 'white'}}> 
                                <Stack direction="row" alignItems="center" sx={{mb: 3}}>
                                    <Typography variant="h6" sx={{fontWeight: 600}}>Assessment Status:</Typography>
                                    <Typography variant="" sx={{fontSize: '0.8rem', fontWeight: 700, px: 1, py: 0,ml: 1,color: blueGrey[50], background: green[400], borderRadius: '5px'}}>Assessed</Typography>
                                </Stack>

                                    <Table sx={{ minWidth: '100%' }}>
                                        <TableHead sx={{my: 0, py: 0, background: blueGrey[50]}}>
                                            <TableRow>
                                                <TableCell sx={{ py: 0, m: 0 }}>Department</TableCell>
                                                <TableCell>Role</TableCell>
                                                <TableCell>Result</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell><Typography variant="P" sx={{ px: 1, py: 0, background: blueGrey[50], borderRadius: '5px'}}>I.T</Typography></TableCell>
                                                <TableCell><Typography variant="P" sx={{color: blueGrey[900], px: 1, background: blueGrey[50], borderRadius: '5px'}}>Senior Frontend Developer</Typography></TableCell>
                                                <TableCell><Typography variant="P" sx={{color: blueGrey[900], px: 1, background: blue[200], borderRadius: '5px'}}>Hired!</Typography></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
         
                            </Paper>
                    </Grid>
                    <Grid item lg={6} sx={{my: 1}}>
                        <Paper sx={{p: 4,background: ''}}>
                                <Stack direction="row" alignItems="center" sx={{mb: 3}}>
                                    <Typography variant="h6" sx={{fontWeight: 600}}>Other Linked Profiles:</Typography>
                                    <Typography variant="" sx={{fontSize: '1rem', fontWeight: 700, px: 1, py: 0,ml: 1,color: blueGrey[50], background: blueGrey[400], borderRadius: '5px'}}>1</Typography>
                                </Stack>
                                <Table sx={{ minWidth: '100%' }}>
                                    <TableHead sx={{my: 0, py: 0, background: blueGrey[50]}}>
                                        <TableRow>
                                            <TableCell sx={{ py: 0, m: 0 }}>Assessment Id</TableCell>
                                            <TableCell>Department</TableCell>
                                            <TableCell>Job</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><Typography variant="P" sx={{ px: 1, py: 0, background: blueGrey[50], borderRadius: '5px'}}>ASS-1</Typography></TableCell>
                                            <TableCell><Typography variant="P" sx={{color: blueGrey[900], px: 1, background: blueGrey[50], borderRadius: '5px'}}>I.T</Typography></TableCell>
                                            <TableCell><Typography variant="P" sx={{color: blueGrey[900], px: 1, background: yellow[400], borderRadius: '5px'}}>Head of Frontend</Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                        </Paper>
                    </Grid>
                    <Grid item lg={12}>
                        <Button variant="contained" sx={{width: '100%',mt: 2, background: blueGrey[700]}}>Send Email To Applicant</Button>

                    </Grid>


                

            </Grid>
           
            <Divider/>

        
        </TabPanel>
           
        </div>
    )
}

export default ViewAssessment
 
