export const RATECARDS_LIST_REQUEST = 'RATECARDS_LIST_REQUEST'
export const RATECARDS_LIST_SUCCESS = 'RATECARDS_LIST_SUCCESS'
export const RATECARDS_LIST_FAIL = 'RATECARDS_LIST_FAIL'


export const RATECARD_CREATE_REQUEST = 'RATECARD_CREATE_REQUEST'
export const RATECARD_CREATE_SUCCESS = 'RATECARD_CREATE_SUCCESS'
export const RATECARD_CREATE_FAIL = 'RATECARD_CREATE_FAIL'


export const RATECARD_UPDATE_REQUEST = 'RATECARD_UPDATE_REQUEST'
export const RATECARD_UPDATE_SUCCESS = 'RATECARD_UPDATE_SUCCESS'
export const RATECARD_UPDATE_FAIL = 'RATECARD_UPDATE_FAIL'

export const RATECARD_DELETE_REQUEST = 'RATECARD_DELETE_REQUEST'
export const RATECARD_DELETE_SUCCESS = 'RATECARD_DELETE_SUCCESS'
export const RATECARD_DELETE_FAIL = 'RATECARD_DELETE_FAIL'

export const VIEW_RATECARD_REQUEST = 'VIEW_RATECARD_REQUEST'
export const VIEW_RATECARD_SUCCESS = 'VIEW_RATECARD_SUCCESS'
export const VIEW_RATECARD_FAIL = 'VIEW_RATECARD_FAIL'