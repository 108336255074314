import React, {useState} from 'react';

const useFilter = (applicants_list) => {

    const [records, setRecords] = useState(applicants_list)
    //const tableheadings = Object.keys(records[0]);
    
    
    //Pagination | Filtering | Sorting

    //variables
    const pageOptions = [7, 14, 21];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7)

    //functions
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    }
    const handleRowsPerPageChnage = (event) => {
        const {value} = event.target;
        setRowsPerPage(parseInt(value, 10));
        setPage(0);
    }
    const stableSort = (array, comparator)=> {

        const stabilizedThis = array.map((el, index)=>[el, index]);
        stabilizedThis.sort((a,b) => {
            const order = comparator(a[0], b[0]);
            if(order!==0) return order;
            return a[1] - b[1];
        });
 
        return stabilizedThis.map((el) => el[0])

    }
     const descendingComparator = (a,b,orderBy) => {

        if(b[orderBy] < a[orderBy])
        {
            return -1;
        }
        if(b[orderBy] > a[orderBy])
        {
            return 1;
        }
        
        return 0;
    }
    const getComparator = (order, orderBy) => {
        return order === 'desc' ? (a,b) => descendingComparator(a,b,orderBy) : (a,b) => -descendingComparator(a,b,orderBy)
    } 
    const recordsAfterPaginationAndSorting = () => {

        //const prompted = records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        const prompted = stableSort(filterRecords(), getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        return prompted;
    }
    //handling the sorting of the tablw
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();

    const handleSortRequest = (cellHeading) => {
        
        const isAsc = orderBy === cellHeading && order === 'asc';
        setOrder(isAsc? 'desc' : 'asc')
        setOrderBy(cellHeading)
    }
    //handle search and filter functionality
    const [searchTerm, setSearchTerm] = useState('');

    const filterRecords = () => {
       if(searchTerm === '')
       {
           return records
       }
       else
       {
           return records.filter(x=> x['First_Name'].toLowerCase().includes(searchTerm));
       }       
    }
    const handleSearch = (e) => {
        const {value} = e.target;
        setSearchTerm(value);
        filterRecords();
    }



    return {rowsPerPage,  handlePageChange, handleRowsPerPageChnage,  order, page, pageOptions,  orderBy,  handleSearch, filterRecords, recordsAfterPaginationAndSorting, handleSortRequest}
}

export default useFilter
