import React, {useState} from 'react';
import AddProfitLossDialog from './AddProfitLossDialog';
import ViewProfitLossDialog from './ViewProfitLossDialog';
import DeleteProfitLossDialog from './DeleteProfitLossDialog';



const ProfitLossDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <> 
        {
            type === "create"?

            <AddProfitLossDialog open={open} onClose={onClose} handleSubmit={handleSubmit}  fullWidth />
            
            : type === "view"?

            <ViewProfitLossDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />

            : type === "delete"?

            <DeleteProfitLossDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />
            // <ViewProfitLossDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default ProfitLossDialogs
