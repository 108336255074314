import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo, purple, cyan } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import HolidayDialogs from '../../../Dialogs/CRM/System/HolidayDialogs/HolidayDialogs';

const HolidayTbl = ({holidays}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    const getStatusColor = (jr) => {
        return jr === 'Requested' ? lightGreen[500] : jr === 'Approved'?  pink[500] : jr === 'In Progress'? orange[500] : jr === 'Complete'? lightBlue[500] : jr === 'Filled'? cyan[500]  : jr === 'Complete'? lightBlue[500] : blueGrey[500]
    }

    const switchToViewHoliday = (id) => {
        navigate('/holiday/'+ id)
    } 

    const holidayQuickView = (id) => {

        showDialog(true, 'view', id)
    }


 
    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'HolidayName', headerName: 'Name', width: 150,  renderCell: (params) => <Typography onClick={()=> {holidayQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.HolidayName}`}</Typography>},
        { field: 'HolidayGroup', headerName: 'Group'},
        { field: 'Worker', headerName: 'Worker'},
        { field: 'Staff', headerName: 'Staff'},
        { field: 'DaysTaken', headerName: 'Days Taken'},
        { field: 'DaysLeft', headerName: 'Days Left'},
        { field: 'StartDate', headerName: 'Start Date'},
        { field: 'EndDate', headerName: 'End Date'},
        { field: 'ApprovedBy', headerName: 'ApprovedBy'},
        { field: 'Status', headerName: 'Status', minWidth: 180 , type: 'singleSelect', valueOptions: (['Requested', 'Approved', 'In Progress', 'Complete', 'Filled', 'Complete']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewHoliday(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];

    return (
        
        <>
            { dialog.open  ?  <HolidayDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={holidays}
                        loading={!holidays.length}
                    />
                </div>
            </div>
        </>
    )
}

export default HolidayTbl
