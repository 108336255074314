import React from 'react'
import { Form, Row, Col } from 'react-bootstrap';
import { Divider, Typography, Grid, Stack, Button, Card, CardHeader, CardContent, Box } from '@mui/material';
import { teal, blue } from '@mui/material/colors';
import useForm from '../../Hooks/useForm';
import tasks from '../../Data/Tasks/Tasks';
import { blueGrey } from '@material-ui/core/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ViewTasksDialogContent = () => {

    
    const {handleChange, values} = useForm(tasks)
    const handleSubmit = () => {
       
    }

    return (
        <>
        
        <Grid container>
            <Grid item lg={8} sx={{my: 1}}>
                <Typography variant="h5" sx={{fontWeight: 600}}>Deliver Bags</Typography>
                <Form className="mt-2">
                    <Typography variant="body2" sx={{display: 'inline',color: blueGrey[900],background:'', borderRadius: '5px', px: 1}}>Status:</Typography>
                    <Button endIcon={<KeyboardArrowDownIcon/>} variant="contained" size="large" sx={{color: 'white',fontSize: '.8rem', background: blue[500], boxShadow: 'none', mr: 1, px: '5px', py: 0}}>NEW</Button>
                    <Row className="mt-3">
                        <Col lg={3}>
                            <Form.Group controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="text-dark" style={{fontWeight: 800}}>Task ID</Form.Label>
                                <Form.Control disabled type="email" value="TSK-1" placeholder="E.g 123qst1" className="outline-0 border-0" />
                            </Form.Group>
                        </Col>

                        <Col lg={7}>
                            <Form.Group controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="text-dark" style={{fontWeight: 800}}>Task Name</Form.Label>
                                <Form.Control type="email" name="Task Name" disabled value={values['Task Name']} onChange={handleChange} placeholder="E.g Deliver Banners to IBM" className="outline-0 border-0" />
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col lg={10}>
                            <Form.Group controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="text-dark" style={{fontWeight: 800}}>Description</Form.Label>
                                <Form.Control type="email" disabled name="Task Description" value="No description recorded yet" placeholder="A short description will do just fine ..." className="outline-0 border-0"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <br/>
                <Typography variant="body1" sx={{fontWeight: 600, mb: 1}}>Activity</Typography>
                <Stack direction="row">
                    <Typography variant="body2" sx={{mr: 2}}>Show:</Typography>
                    <Button variant="contained" size="small" sx={{color: blueGrey[900],fontSize: '.6rem', background: blueGrey[50], boxShadow: 'none', mr: 1}}>All</Button>
                    <Button variant="contained" size="small" sx={{color: blueGrey[900],fontSize: '.6rem', background: blueGrey[50], boxShadow: 'none', mr: 1}}>Comments</Button>
                    <Button variant="contained" size="small" sx={{color: blueGrey[900],fontSize: '.6rem', background: blueGrey[50], boxShadow: 'none', mr: 1}}>History</Button>
                </Stack>

                <Form className="">
                    <Row>
                        <Col lg={10}>
                            <Typography variant="body1" sx={{fontWeight: 600, mt: 2}}>Comments</Typography>
                            <Form.Group controlId="formGridEmail" className="rounded p-2">
                                {/* <Form.Label className="text-dark">Comments</Form.Label> */}
                                <Form.Control type="email" as="textarea" disabled name="Task Description" value={values['Task Description']} onChange={handleChange} placeholder="A short description will do just fine ..." className="outline-0 border-0"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Grid>
            {/* <Divider orientation="vertical" flexItem/> */}
            <Grid item lg={4} sx={{background: '', width: '100%' , p: 0, m: 0, mt: 5}}>
                <Card sx={{boxShadow: 'none', border: '1px solid #cfd8dc', px: 0, width: '100%'}}>
                    <CardContent sx={{p:0, height: '100%'}}>

                        <Box sx={{boxShadow: 'none', background: blueGrey[50] ,borderBottom: '1px solid #cfd8dc', m: 0, p: 2}}>
                            <Typography variant="body1" sx={{fontWeight: 700}}>Details</Typography>
                        </Box>
                        <Box sx={{boxShadow: 'none', mx: 0, p: 2}}>
                            <Form className="">
                                <Form.Group as={Row} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label column className="text-dark sm-3" style={{fontSize: '.8rem',fontWeight: 900}}>Assignee</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control plaintext name="Task Description" value=""  placeholder="Nothing entered yet" className="outline-0 border-0"/> 
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label column className="text-dark sm-3" style={{fontSize: '.8rem',fontWeight: 900}}>Label</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control plaintext name="Task Description" value=""  placeholder="Nothing entered yet" className="outline-0 border-0"/> 
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label column className="text-dark sm-3" style={{fontSize: '.8rem',fontWeight: 900}}>Reporter</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control plaintext name="Task Description" value=""  placeholder="Nothing entered yet" className="outline-0 border-0"/> 
                                    </Col>
                                </Form.Group>
                           
                            </Form>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        </>
    )
}

export default ViewTasksDialogContent
