import { VISITS_LIST_REQUEST, VISITS_LIST_SUCCESS, VISITS_LIST_FAIL, VIEW_VISIT_FAIL, VIEW_VISIT_REQUEST, VIEW_VISIT_SUCCESS, VISIT_CREATE_REQUEST, VISIT_CREATE_SUCCESS, VISIT_CREATE_FAIL, VISIT_UPDATE_FAIL, VISIT_UPDATE_REQUEST, VISIT_UPDATE_SUCCESS } from '../Constants/visitConstants';
import api from '../../config/apiConfig';


const getVisitsList = () => async(dispatch) => {
    
  
    try {
        dispatch({type: VISITS_LIST_REQUEST});
        const {data} = await api.get('/visits');
        //console.log('Visits data is:' + data[0].Name)
        dispatch({type: VISITS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: VISITS_LIST_FAIL, payload: error})
    }
} 

const submitVisit = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: VISIT_CREATE_REQUEST});
        
        const {data} = await api.post(`/visits/`, postValues);
        console.log('Visit data is:' + data)
        dispatch({type: VISIT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VISIT_CREATE_FAIL, payload: error})
    }
} 

const getVisit = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_VISIT_REQUEST});
        
        const {data} = await api.get(`/visits/ ${id}`);
        console.log('Visit data is:' + data)
        dispatch({type: VIEW_VISIT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_VISIT_FAIL, payload: error})
    }
}

const editVisit = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: VISIT_UPDATE_REQUEST});
        
        const {data} = await api.put(`/visits/`, values);
        console.log('Visit data is:' + data)
        dispatch({type: VISIT_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: VISIT_UPDATE_FAIL, payload: error})
    }
}



export {getVisitsList, submitVisit, getVisit, editVisit }