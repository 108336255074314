
import api from '../../../../../config/apiConfig';


const addToWorkersShifts = (workerID, status, shiftID) => { 

    const initialTS_state = 1;

    checkIfTSExists(workerID)
    .then((response) => {

        addShiftToWorkerShifts(workerID, shiftID)
        console.log(response)
    })
    .catch((response) => {
        
            createTimesheetUsingWorkerIdAndStatus(workerID, initialTS_state)
            .then(()=> addShiftToWorkerShifts())
            console.log(response)
    }
    )
    console.log('YOU CALLED ME WITH THIS ARG:' + workerID)
    
}

const createTimesheetUsingWorkerIdAndStatus = async (workerID, status) => {

    const {data} = await api.get('/workersshifts', {workerID, status})
}

const checkIfTSExists = async (workerID) => {

    const {data} = await api.get('/timesheets');
    const timesheet = data.find(x => x.TimesheetWorker === workerID)
    if(timesheet)
    {
        return 'TIMESHEET EXISTS' 
    }
    else
    {
        return "DOESN'T EXIST"
    }
    //console.log('RETRIEVED THE SHEETS: ' + data)
}

const addShiftToWorkerShifts = async (workerID, shiftID) => {

    await api.post('/workersshifts/', {workerID, shiftID})

}

const getShiftsByWorkerId = async (TimesheetSheetID) => {

    const workerId = await getWorkerId(TimesheetSheetID)
    console.log('WWWWWWWWOR: ' + workerId)


    try {
        const {data} = await api.get(`/workersshifts/ ${workerId}`)
        console.log('items are: ' + data[0].WorkerName)
        return data
        
    } catch (error) {

        return error.message
        
    }
}

const getWorkerId = async (TimesheetSheetID) => {

    const {data} = await api.get(`/timesheets/ ${TimesheetSheetID}`)
    const workerId = data.TimesheetWorker;
    console.log('WORKERRRRRRR ID:' + workerId)
    return workerId
}

export { addToWorkersShifts, getShiftsByWorkerId}