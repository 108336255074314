import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, yellow, indigo, purple } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import BalanceSheetDialogs from '../../../Dialogs/Finance/Reconciliation/BalanceSheetDialogs/BalanceSheetDialogs';

const BalanceSheetsTbl = ({balanceSheets}) => { 

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    const  getTypeColor = (status) => {
        return status === 'Assign Work' ? purple[500] : status === 'Complete Invoices'?  lightGreen[500] : status === 'Complete Payroll'? lightBlue[500] :  status === 'Compliance'? pink[500] : status === 'Contact Client'? yellow[800]  : status === 'Contact Client'? indigo[400]  : status === 'New'? orange[500] : blueGrey[500]
    }
    const  getStatusColor = (status) => {
        return status === 'New Task' ? pink[500] : status === 'In Progress'?  yellow[800] : status === 'Part Complete'? lightBlue[500] : status === 'Complete'? lightBlue[500] : status === 'Overdue'? blue[800] : status === 'Escalate'? purple[800] :  blueGrey[500]
    } 

    const switchToViewTask = (id) => {
        navigate('/task/'+ id)
    } 

    const taskQuickView = (id) => {

        showDialog(true, 'view', id)
    }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number', minWidth: 100 },
        { field: 'BSName', headerName: 'BS Name', minWidth: 150 , renderCell: (params) => <Typography onClick={()=> {taskQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.BSName}`}</Typography>},
        { field: 'PeriodStart', headerName: 'Period Start', minWidth: 150},
        { field: 'PeriodEnd', headerName: 'Period End', minWidth: 150 ,},
        { field: 'FixedAssets', headerName: 'FixedAssets', minWidth: 120},
        { field: 'IntangibleAssets', headerName: 'IntangibleAssets', minWidth: 120},
        { field: 'OtherNonCurrentAssets', headerName: 'OtherNonCurrentAssets', minWidth: 150},
        { field: 'CashAtBank', headerName: 'CashAtBank', minWidth: 120},
        { field: 'TotalAssets', headerName: 'TotalAssets', minWidth: 120},
        { field: 'CurrentLiabilities', headerName: 'CurrentLiabilities', minWidth: 120},
        { field: 'Dividends', headerName: 'Dividends', minWidth: 120},
        { field: 'ShareCapital', headerName: 'ShareCapital', minWidth: 120},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewTask(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];


    return (
        
        <>
            { dialog.open  ?  <BalanceSheetDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={balanceSheets}
                        loading={!balanceSheets.length}
                    />
                </div>
            </div>
        </>
    )
}

export default BalanceSheetsTbl
