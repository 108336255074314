import { AVAILABILITY_LIST_REQUEST, AVAILABILITY_LIST_SUCCESS, AVAILABILITY_LIST_FAIL, VIEW_AVAILABILITY_FAIL, VIEW_AVAILABILITY_REQUEST, VIEW_AVAILABILITY_SUCCESS, AVAILABILITY_CREATE_FAIL, AVAILABILITY_UPDATE_REQUEST, AVAILABILITY_UPDATE_FAIL, AVAILABILITY_DELETE_REQUEST, AVAILABILITY_DELETE_FAIL, AVAILABILITY_CREATE_REQUEST, AVAILABILITY_CREATE_SUCCESS, AVAILABILITY_UPDATE_SUCCESS, AVAILABILITY_DELETE_SUCCESS } from "../Constants/availabilityConstants";

const initialState = {
    availabilities: [],
    availability: {},
    loading: '',
}

const availabilitiesListReducer = (state = {availabilities: []}, action) => {
 
    switch (action.type)
    {
        case AVAILABILITY_LIST_REQUEST:
            return {loading: true};
 
        case AVAILABILITY_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and availabilityes data is:' + action.payload)
            return {
                loading: false,
                availabilities: action.payload
            }
        case AVAILABILITY_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const availabilityCreateReducer = (state = {availabilities: []}, action) => {

    switch (action.type) {

        case AVAILABILITY_CREATE_REQUEST:
            return {
                loading: true
            }

        case AVAILABILITY_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                availabilities: [...state.availabilities, {Applicant_Id: 2, Name: value}]
            };

        case AVAILABILITY_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const availabilityViewReducer = (state= {availability: {}}, action) => {
    switch(action.type)
    {
        case VIEW_AVAILABILITY_REQUEST:
            return {loading: true};
        case VIEW_AVAILABILITY_SUCCESS:
            console.log('HEY')
            return {
                    loading: false,
                    availability: action.payload
                }
        case VIEW_AVAILABILITY_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const availabilityUpdateReducer = (state = {availability: {}}, action) => {

    switch (action.type) {

        case AVAILABILITY_UPDATE_REQUEST:
            return {
                
            }
        case AVAILABILITY_UPDATE_SUCCESS:

            return {

            }
        case AVAILABILITY_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const availabilityDeleteReducer = (state = {availability: {}}, action) => {

    switch (action.type) {
        case AVAILABILITY_DELETE_REQUEST:
            return {
                
            }
        case AVAILABILITY_DELETE_SUCCESS:
            return {

            }
        case AVAILABILITY_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {availabilitiesListReducer, availabilityCreateReducer, availabilityViewReducer, availabilityUpdateReducer, availabilityDeleteReducer }