export const HOLIDAYS_LIST_REQUEST = 'HOLIDAYS_LIST_REQUEST'
export const HOLIDAYS_LIST_SUCCESS = 'HOLIDAYS_LIST_SUCCESS'
export const HOLIDAYS_LIST_FAIL = 'HOLIDAYS_LIST_FAIL'


export const HOLIDAY_CREATE_REQUEST = 'HOLIDAY_CREATE_REQUEST'
export const HOLIDAY_CREATE_SUCCESS = 'HOLIDAY_CREATE_SUCCESS'
export const HOLIDAY_CREATE_FAIL = 'HOLIDAY_CREATE_FAIL'


export const HOLIDAY_UPDATE_REQUEST = 'HOLIDAY_UPDATE_REQUEST'
export const HOLIDAY_UPDATE_SUCCESS = 'HOLIDAY_UPDATE_SUCCESS'
export const HOLIDAY_UPDATE_FAIL = 'HOLIDAY_UPDATE_FAIL'

export const HOLIDAY_DELETE_REQUEST = 'HOLIDAY_DELETE_REQUEST'
export const HOLIDAY_DELETE_SUCCESS = 'HOLIDAY_DELETE_SUCCESS'
export const HOLIDAY_DELETE_FAIL = 'HOLIDAY_DELETE_FAIL'

export const VIEW_HOLIDAY_REQUEST = 'VIEW_HOLIDAY_REQUEST'
export const VIEW_HOLIDAY_SUCCESS = 'VIEW_HOLIDAY_SUCCESS'
export const VIEW_HOLIDAY_FAIL = 'VIEW_HOLIDAY_FAIL'