import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo, purple, cyan } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import CreditCheckDialogs from '../../../Dialogs/CRM/Management/CreditCheckDialogs/CreditCheckDialogs';

const CreditChecksTbl = ({creditchecks}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    
    const getStatusColor = (jr) => {
        return jr === 'New' ? lightGreen[500] : jr === 'Do Not Supply'?  pink[500] : jr === 'Cleared'? orange[500] : jr === 'New Check Pending'? lightBlue[500] : blueGrey[500]
    }
    

    const switchToViewCreditCheck = (id) => {
        navigate('/creditCheck/'+ id)
    } 

    const creditCheckQuickView = (id) => {

        showDialog(true, 'view', id)
    }


 
    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Client', headerName: 'Client', width: 150, renderCell: (params) => <Typography onClick={()=> {creditCheckQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Client}`}</Typography>},
        { field: 'CreditRating', headerName: 'Credit Rating'},
        { field: 'CreditLimit', headerName: 'Credit Limit'},
        { field: 'TeamFactorsLimit', headerName: 'Team Factors Limit'},
        { field: 'LastCheckedDate', headerName: 'Last Checked Date'},
        { field: 'NextCheckDue', headerName: 'Next Check Due'},
        { field: 'Status', headerName: 'Status', minWidth: 180 , type: 'singleSelect', valueOptions: (['New', 'Do Not Supply', 'Cleared', 'New Check Pending']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewCreditCheck(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];

    return (
        
        <>
            { dialog.open  ?  <CreditCheckDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={creditchecks}
                        loading={!creditchecks.length}
                    />
                </div>
            </div>
        </>
    )
}

export default CreditChecksTbl
