import { PAYERPAYEES_LIST_REQUEST, PAYERPAYEES_LIST_SUCCESS, PAYERPAYEES_LIST_FAIL, VIEW_PAYERPAYEE_FAIL, VIEW_PAYERPAYEE_REQUEST, VIEW_PAYERPAYEE_SUCCESS, PAYERPAYEE_CREATE_REQUEST, PAYERPAYEE_CREATE_SUCCESS, PAYERPAYEE_CREATE_FAIL, PAYERPAYEE_UPDATE_FAIL, PAYERPAYEE_UPDATE_REQUEST, PAYERPAYEE_UPDATE_SUCCESS } from '../Constants/payerPayeeConstants';
import api from '../../config/apiConfig';


const getPayerPayeesList = () => async(dispatch) => {
    
   
    try {
        dispatch({type: PAYERPAYEES_LIST_REQUEST});
        const {data} = await api.get('/payerpayees');
        console.log('PayerPayees data is:' + data[0].Name)
        dispatch({type: PAYERPAYEES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: PAYERPAYEES_LIST_FAIL, payload: error})
    }
} 

const submitPayerPayee = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: PAYERPAYEE_CREATE_REQUEST});
        
        const {data} = await api.post(`/payerpayees/`, postValues);
        console.log('PayerPayee data is:' + data)
        dispatch({type: PAYERPAYEE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: PAYERPAYEE_CREATE_FAIL, payload: error})
    }
} 

const getPayerPayee = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_PAYERPAYEE_REQUEST});
        
        const {data} = await api.get(`/payerpayees/ ${id}`);
        console.log('PayerPayee data is:' + data)
        dispatch({type: VIEW_PAYERPAYEE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_PAYERPAYEE_FAIL, payload: error})
    }
}

const editPayerPayee = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: PAYERPAYEE_UPDATE_REQUEST});
        
        const {data} = await api.put(`/payerpayees/`, values);
        console.log('PayerPayee data is:' + data)
        dispatch({type: PAYERPAYEE_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: PAYERPAYEE_UPDATE_FAIL, payload: error})
    }
}



export {getPayerPayeesList, submitPayerPayee, getPayerPayee, editPayerPayee }