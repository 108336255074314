export const WORKINGWEEKS_LIST_REQUEST = 'WORKINGWEEKS_LIST_REQUEST'
export const WORKINGWEEKS_LIST_SUCCESS = 'WORKINGWEEKS_LIST_SUCCESS'
export const WORKINGWEEKS_LIST_FAIL = 'WORKINGWEEKS_LIST_FAIL'


export const WORKINGWEEK_CREATE_REQUEST = 'WORKINGWEEK_CREATE_REQUEST'
export const WORKINGWEEK_CREATE_SUCCESS = 'WORKINGWEEK_CREATE_SUCCESS'
export const WORKINGWEEK_CREATE_FAIL = 'WORKINGWEEK_CREATE_FAIL'


export const WORKINGWEEK_UPDATE_REQUEST = 'WORKINGWEEK_UPDATE_REQUEST'
export const WORKINGWEEK_UPDATE_SUCCESS = 'WORKINGWEEK_UPDATE_SUCCESS'
export const WORKINGWEEK_UPDATE_FAIL = 'WORKINGWEEK_UPDATE_FAIL'

export const WORKINGWEEK_DELETE_REQUEST = 'WORKINGWEEK_DELETE_REQUEST'
export const WORKINGWEEK_DELETE_SUCCESS = 'WORKINGWEEK_DELETE_SUCCESS'
export const WORKINGWEEK_DELETE_FAIL = 'WORKINGWEEK_DELETE_FAIL'

export const VIEW_WORKINGWEEK_REQUEST = 'VIEW_WORKINGWEEK_REQUEST'
export const VIEW_WORKINGWEEK_SUCCESS = 'VIEW_WORKINGWEEK_SUCCESS'
export const VIEW_WORKINGWEEK_FAIL = 'VIEW_WORKINGWEEK_FAIL'