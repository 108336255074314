import React, {useState} from 'react';
import AddCheckInDialog from './AddCheckInDialog';
import ViewCheckInDialog from './ViewCheckInDialog';
import DeleteCheckInDialog from './DeleteCheckInDialog';



const CheckInDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        { 
            type === "create"?

            <AddCheckInDialog open={open} onClose={onClose} handleSubmit={handleSubmit} type={type} fullWidth />
            
            : type === "view"?

            <ViewCheckInDialog open={open} onClose={onClose} handleSubmit={handleSubmit} type={type} info={info} fullWidth />

            : type === "delete"?

            <DeleteCheckInDialog open={open} onClose={onClose} handleSubmit={handleSubmit} type={type} info={info} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default CheckInDialogs
