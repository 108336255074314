import { BALANCESHEETTYPES_LIST_REQUEST, BALANCESHEETTYPES_LIST_SUCCESS, BALANCESHEETTYPES_LIST_FAIL, VIEW_BALANCESHEETTYPE_FAIL, VIEW_BALANCESHEETTYPE_REQUEST, VIEW_BALANCESHEETTYPE_SUCCESS, BALANCESHEETTYPE_CREATE_FAIL, BALANCESHEETTYPE_UPDATE_REQUEST, BALANCESHEETTYPE_UPDATE_FAIL, BALANCESHEETTYPE_DELETE_REQUEST, BALANCESHEETTYPE_DELETE_FAIL, BALANCESHEETTYPE_CREATE_REQUEST, BALANCESHEETTYPE_CREATE_SUCCESS, BALANCESHEETTYPE_UPDATE_SUCCESS, BALANCESHEETTYPE_DELETE_SUCCESS } from "../Constants/balanceSheetTypeConstants";

const balanceSheetTypesListReducer = (state = {balanceSheetTypes: []}, action) => {

    switch (action.type)
    {
        case BALANCESHEETTYPES_LIST_REQUEST:
            return {loading: true};
 
        case BALANCESHEETTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and balanceSheetTypes data is:' + action.payload)
            return {
                loading: false,
                balanceSheetTypes: action.payload
            }
        case BALANCESHEETTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const balanceSheetTypeCreateReducer = (state = {balanceSheetTypes: []}, action) => {

    switch (action.type) {

        case BALANCESHEETTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case BALANCESHEETTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                balanceSheetTypes: [...state.balanceSheetTypes, {Applicant_Id: 2, Name: value}]
            };

        case BALANCESHEETTYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const balanceSheetTypeViewReducer = (state= {balanceSheetType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_BALANCESHEETTYPE_REQUEST:
            return {loading: true};
        case VIEW_BALANCESHEETTYPE_SUCCESS:
            return {
                    loading: false,
                    balanceSheetType: action.payload
                }
        case VIEW_BALANCESHEETTYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const balanceSheetTypeUpdateReducer = (state = {balanceSheetTypes: {}}, action) => {

    switch (action.type) {

        case BALANCESHEETTYPE_UPDATE_REQUEST:
            return {
                
            }
        case BALANCESHEETTYPE_UPDATE_SUCCESS:

            return {

            }
        case BALANCESHEETTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const balanceSheetTypeDeleteReducer = (state = {balanceSheetTypes: {}}, action) => {

    switch (action.type) {
        case BALANCESHEETTYPE_DELETE_REQUEST:
            return {
                
            }
        case BALANCESHEETTYPE_DELETE_SUCCESS:
            return {

            }
        case BALANCESHEETTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {balanceSheetTypesListReducer, balanceSheetTypeCreateReducer, balanceSheetTypeViewReducer, balanceSheetTypeUpdateReducer, balanceSheetTypeDeleteReducer }