import React from 'react';
import AddClientSiteDialog from './AddClientSiteDialog';
import ViewClientSiteDialog from './ViewClientSiteDialog';
import DeleteClientSiteDialog from './DeleteClientSiteDialog';



const ClientSiteDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        { 
            type === "create"?

            <AddClientSiteDialog open={open} onClose={onClose}  handleSubmit={handleSubmit} fullWidth />
            
            : type === "view"?

            <ViewClientSiteDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />

            : type === "delete"?

            <DeleteClientSiteDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default ClientSiteDialogs
