import { INDUSTRIES_LIST_REQUEST, INDUSTRIES_LIST_SUCCESS, INDUSTRIES_LIST_FAIL, VIEW_INDUSTRY_FAIL, VIEW_INDUSTRY_REQUEST, VIEW_INDUSTRY_SUCCESS, INDUSTRY_CREATE_FAIL, INDUSTRY_UPDATE_REQUEST, INDUSTRY_UPDATE_FAIL, INDUSTRY_DELETE_REQUEST, INDUSTRY_DELETE_FAIL, INDUSTRY_CREATE_REQUEST, INDUSTRY_CREATE_SUCCESS, INDUSTRY_UPDATE_SUCCESS, INDUSTRY_DELETE_SUCCESS } from "../Constants/industryConstants";

const initialState = {
    industries: [],
    industry: {},
    loading: '',
}

const industriesListReducer = (state = {industries: []}, action) => {
 
    switch (action.type)
    {
        case INDUSTRIES_LIST_REQUEST:
            return {loading: true};
 
        case INDUSTRIES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and industryes data is:' + action.payload)
            return {
                loading: false,
                industries: action.payload
            }
        case INDUSTRIES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const industryCreateReducer = (state = {industries: []}, action) => {

    switch (action.type) {

        case INDUSTRY_CREATE_REQUEST:
            return {
                loading: true
            }

        case INDUSTRY_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                industries: [...state.industries, {Applicant_Id: 2, Name: value}]
            };

        case INDUSTRY_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const industryViewReducer = (state= {industry: {}}, action) => {
    switch(action.type)
    {
        case VIEW_INDUSTRY_REQUEST:
            return {loading: true};
        case VIEW_INDUSTRY_SUCCESS:
            console.log('HEY')
            return {
                    loading: false,
                    industry: action.payload
                }
        case VIEW_INDUSTRY_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const industryUpdateReducer = (state = {industry: {}}, action) => {

    switch (action.type) {

        case INDUSTRY_UPDATE_REQUEST:
            return {
                
            }
        case INDUSTRY_UPDATE_SUCCESS:

            return {

            }
        case INDUSTRY_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const industryDeleteReducer = (state = {industry: {}}, action) => {

    switch (action.type) {
        case INDUSTRY_DELETE_REQUEST:
            return {
                
            }
        case INDUSTRY_DELETE_SUCCESS:
            return {

            }
        case INDUSTRY_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {industriesListReducer, industryCreateReducer, industryViewReducer, industryUpdateReducer, industryDeleteReducer }