import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo, purple, cyan } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import RequestDialogs from '../../../Dialogs/CRM/Operations/RequestDialogs/RequestDialogs';

const RequestsTbl = ({requests}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    const getTypeColor = (jr) => {
        return jr === 'Construction' ? lightGreen[500] : jr === 'Ad Hok'?  pink[500] : jr === 'Temp to Perm'? orange[500] : jr === 'Ongoing'? lightBlue[500] : blueGrey[500]
    }
    const getStatusColor = (jr) => {
        return jr === 'New' ? lightGreen[500] : jr === 'Approved'?  pink[500] : jr === 'Working On'? orange[500] : jr === 'Unable to fill'? lightBlue[500] : jr === 'Filled'? cyan[500]  : jr === 'Complete'? lightBlue[500] : blueGrey[500]
    }
    const getDivisionColor = (jr) => {
        return jr === 'Construction' ? lightGreen[500] : jr === 'Driving'?  pink[500] : jr === 'Aviation'? orange[500] : jr === 'Industrial'? lightBlue[500] : blueGrey[500]
    }

    const switchToViewRequest = (id) => {
        navigate('/request/'+ id)
    } 

    const requestQuickView = (id) => {

        showDialog(true, 'view', id)
    }


 
    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Name', headerName: 'Name', width: 150, renderCell: (params) => <Typography onClick={()=> {requestQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Name}`}</Typography>},
        { field: 'Type', headerName: 'Type', minWidth: 180 , type: 'singleSelect', valueOptions: (['Ad Hok', 'Temp to Perm', 'Ongoing']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getTypeColor(params.row.Type), borderRadius: '30px', px: '10px'}}>{params.row.Type}</Typography>
        )},
        { field: 'Division', headerName: 'Division', minWidth: 180 , type: 'singleSelect', valueOptions: (['Driving', 'Construction', 'Aviation', 'Industrial', 'Human Resources']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getDivisionColor(params.row.Division), borderRadius: '30px', px: '10px'}}>{params.row.Division}</Typography>
        )},
        { field: 'Client', headerName: 'Client'},
        { field: 'Position', headerName: 'Position'},
        { field: 'NoOfWorkersNeeded', headerName: 'Workers Needed'},
        { field: 'StartDate', headerName: 'Start Date'},
        { field: 'EndDate', headerName: 'End Date'},
        { field: 'Notes', headerName: 'Notes'},
        { field: 'Status', headerName: 'Status', minWidth: 180 , type: 'singleSelect', valueOptions: (['New', 'Approved', 'Working On', 'Unable to fill', 'Filled', 'Complete']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewRequest(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];

    return (
        
        <>
            { dialog.open  ?  <RequestDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={requests}
                        loading={!requests.length}
                    />
                </div>
            </div>
        </>
    )
}

export default RequestsTbl
