export const CONTACTS_LIST_REQUEST = 'CONTACTS_LIST_REQUEST'
export const CONTACTS_LIST_SUCCESS = 'CONTACTS_LIST_SUCCESS'
export const CONTACTS_LIST_FAIL = 'CONTACTS_LIST_FAIL'


export const CONTACT_CREATE_REQUEST = 'CONTACT_CREATE_REQUEST'
export const CONTACT_CREATE_SUCCESS = 'CONTACT_CREATE_SUCCESS'
export const CONTACT_CREATE_FAIL = 'CONTACT_CREATE_FAIL'


export const CONTACT_UPDATE_REQUEST = 'CONTACT_UPDATE_REQUEST'
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS'
export const CONTACT_UPDATE_FAIL = 'CONTACT_UPDATE_FAIL'

export const CONTACT_DELETE_REQUEST = 'CONTACT_DELETE_REQUEST'
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS'
export const CONTACT_DELETE_FAIL = 'CONTACT_DELETE_FAIL'

export const VIEW_CONTACT_REQUEST = 'VIEW_CONTACT_REQUEST'
export const VIEW_CONTACT_SUCCESS = 'VIEW_CONTACT_SUCCESS'
export const VIEW_CONTACT_FAIL = 'VIEW_CONTACT_FAIL'