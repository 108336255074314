import { BALANCESHEETS_LIST_REQUEST, BALANCESHEETS_LIST_SUCCESS, BALANCESHEETS_LIST_FAIL, VIEW_BALANCESHEET_FAIL, VIEW_BALANCESHEET_REQUEST, VIEW_BALANCESHEET_SUCCESS, BALANCESHEET_CREATE_FAIL, BALANCESHEET_UPDATE_REQUEST, BALANCESHEET_UPDATE_FAIL, BALANCESHEET_DELETE_REQUEST, BALANCESHEET_DELETE_FAIL, BALANCESHEET_CREATE_REQUEST, BALANCESHEET_CREATE_SUCCESS, BALANCESHEET_UPDATE_SUCCESS, BALANCESHEET_DELETE_SUCCESS } from "../Constants/balanceSheetConstants";

const balanceSheetsListReducer = (state = {balanceSheets: []}, action) => {

    switch (action.type)
    {
        case BALANCESHEETS_LIST_REQUEST:
            return {loading: true};
 
        case BALANCESHEETS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and balanceSheets data is:' + action.payload)
            return {
                loading: false,
                balanceSheets: action.payload
            }
        case BALANCESHEETS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const balanceSheetCreateReducer = (state = {balanceSheets: []}, action) => {

    switch (action.type) {

        case BALANCESHEET_CREATE_REQUEST:
            return {
                loading: true
            }

        case BALANCESHEET_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                balanceSheets: [...state.balanceSheets, {Applicant_Id: 2, Name: value}]
            };

        case BALANCESHEET_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const balanceSheetViewReducer = (state= {balanceSheet: {}}, action) => {
    switch(action.type)
    {
        case VIEW_BALANCESHEET_REQUEST:
            return {loading: true};
        case VIEW_BALANCESHEET_SUCCESS:
            return {
                    loading: false,
                    balanceSheet: action.payload
                }
        case VIEW_BALANCESHEET_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const balanceSheetUpdateReducer = (state = {balanceSheets: {}}, action) => {

    switch (action.type) {

        case BALANCESHEET_UPDATE_REQUEST:
            return {
                
            }
        case BALANCESHEET_UPDATE_SUCCESS:

            return {

            }
        case BALANCESHEET_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const balanceSheetDeleteReducer = (state = {balanceSheets: {}}, action) => {

    switch (action.type) {
        case BALANCESHEET_DELETE_REQUEST:
            return {
                
            }
        case BALANCESHEET_DELETE_SUCCESS:
            return {

            }
        case BALANCESHEET_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {balanceSheetsListReducer, balanceSheetCreateReducer, balanceSheetViewReducer, balanceSheetUpdateReducer, balanceSheetDeleteReducer }