import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo, purple, cyan } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import BookingDialogs from '../../../Dialogs/CRM/Operations/BookingDialogs/BookingDialogs';

const BookingsTbl = ({bookings}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    const getJRColor = (jr) => {
        return jr === 'Class 1' ? blue[500] : jr === 'Class 2'?  pink[500] : jr === 'Construction'? orange[500] : jr === 'FLT'? lightGreen[500] : blueGrey[500]
    }
    const getStatusColor = (jr) => {
        return jr === 'New' ? lightGreen[500] : jr === 'Approved'?  pink[500] : jr === 'Working On'? orange[500] : jr === 'Unable to fill'? lightBlue[500] : jr === 'Filled'? cyan[500]  : jr === 'Complete'? lightBlue[500] : blueGrey[500]
    }
    const getRecurringColor = (rc) => {
        return rc === 'Ongoing' ? lightGreen[500] : rc === 'Paused'?  pink[500] : rc === 'Stop at end date'? orange[500] : rc === 'Complete'? lightBlue[500] : blueGrey[500]
    }

    const switchToViewBooking = (id) => {
        navigate('/booking/'+ id)
    } 

    const bookingQuickView = (id) => {

        showDialog(true, 'view', id)
    }


 
    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Name', headerName: 'Name', width: 150, renderCell: (params) => <Typography onClick={()=> {bookingQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Name}`}</Typography>},
        { field: 'RequirementDetails', headerName: 'Requirement Details', width: 150},
        { field: 'StartDate', headerName: 'StartDate'},
        { field: 'EndDate', headerName: 'EndDate'},
        { field: 'Recurring', headerName: 'Recurring',type: 'singleSelect',  valueOptions: (['Ongoing', 'Paused', 'Stop at end date', 'Complete']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getRecurringColor(params.row.Recurring), borderRadius: '30px', px: '10px'}}>{params.row.Recurring}</Typography>
        )},
        { field: 'Client', headerName: 'Client'},
        { field: 'NoOfWorkersNeeded', headerName: 'Workers Needed', width: 150},
        { field: 'JRole', headerName: 'Positions Required',type: 'singleSelect',  valueOptions: (['Class 1', 'Driving', 'Industrial', 'Aviation']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getJRColor(params.row.JRole), borderRadius: '30px', px: '10px'}}>{params.row.JRole}</Typography>
        )},
        { field: 'Worker', headerName: 'Worker'},
        { field: 'Consultant', headerName: 'Consultant'},
        { field: 'Request', headerName: 'Request'},
        { field: 'Opportunity', headerName: 'Opportunity', width: 150},
        { field: 'Status', headerName: 'Status', minWidth: 150 , type: 'singleSelect', valueOptions: (['New', 'Approved', 'Working On', 'Unable to fill', 'Filled', 'Complete']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewBooking(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];

    return (
        
        <>
            { dialog.open  ?  <BookingDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={bookings}
                        loading={!bookings.length}
                    />
                </div>
            </div>
        </>
    )
}

export default BookingsTbl
