export const CONTACTTYPES_LIST_REQUEST = 'CONTACTTYPES_LIST_REQUEST'
export const CONTACTTYPES_LIST_SUCCESS = 'CONTACTTYPES_LIST_SUCCESS'
export const CONTACTTYPES_LIST_FAIL = 'CONTACTTYPES_LIST_FAIL'


export const CONTACTTYPE_CREATE_REQUEST = 'CONTACTTYPE_CREATE_REQUEST'
export const CONTACTTYPE_CREATE_SUCCESS = 'CONTACTTYPE_CREATE_SUCCESS'
export const CONTACTTYPE_CREATE_FAIL = 'CONTACTTYPE_CREATE_FAIL'


export const CONTACTTYPE_UPDATE_REQUEST = 'CONTACTTYPE_UPDATE_REQUEST'
export const CONTACTTYPE_UPDATE_SUCCESS = 'CONTACTTYPE_UPDATE_SUCCESS'
export const CONTACTTYPE_UPDATE_FAIL = 'CONTACTTYPE_UPDATE_FAIL'

export const CONTACTTYPE_DELETE_REQUEST = 'CONTACTTYPE_DELETE_REQUEST'
export const CONTACTTYPE_DELETE_SUCCESS = 'CONTACTTYPE_DELETE_SUCCESS'
export const CONTACTTYPE_DELETE_FAIL = 'CONTACTTYPE_DELETE_FAIL'

export const VIEW_CONTACTTYPE_REQUEST = 'VIEW_CONTACTTYPE_REQUEST'
export const VIEW_CONTACTTYPE_SUCCESS = 'VIEW_CONTACTTYPE_SUCCESS'
export const VIEW_CONTACTTYPE_FAIL = 'VIEW_CONTACTTYPE_FAIL'