import { PROFITLOSS_LIST_REQUEST, PROFITLOSS_LIST_SUCCESS, PROFITLOSS_LIST_FAIL, VIEW_PROFITLOSS_FAIL, VIEW_PROFITLOSS_REQUEST, VIEW_PROFITLOSS_SUCCESS, PROFITLOSS_CREATE_FAIL, PROFITLOSS_UPDATE_REQUEST, PROFITLOSS_UPDATE_FAIL, PROFITLOSS_DELETE_REQUEST, PROFITLOSS_DELETE_FAIL, PROFITLOSS_CREATE_REQUEST, PROFITLOSS_CREATE_SUCCESS, PROFITLOSS_UPDATE_SUCCESS, PROFITLOSS_DELETE_SUCCESS } from "../Constants/profitLossConstants";

const profitLossListReducer = (state = {profitLoss: []}, action) => {

    switch (action.type)
    {
        case PROFITLOSS_LIST_REQUEST:
            return {loading: true};
 
        case PROFITLOSS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and profitLoss data is:' + action.payload)
            return {
                loading: false,
                profitLoss: action.payload
            }
        case PROFITLOSS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const profitLossCreateReducer = (state = {profitLoss: []}, action) => {

    switch (action.type) {

        case PROFITLOSS_CREATE_REQUEST:
            return {
                loading: true
            }

        case PROFITLOSS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                profitLoss: [...state.profitLoss, {Applicant_Id: 2, Name: value}]
            };

        case PROFITLOSS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const profitLossViewReducer = (state= {profitLoss: {}}, action) => {
    switch(action.type)
    {
        case VIEW_PROFITLOSS_REQUEST:
            return {loading: true};
        case VIEW_PROFITLOSS_SUCCESS:
            return {
                    loading: false,
                    profitLoss: action.payload
                }
        case VIEW_PROFITLOSS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const profitLossUpdateReducer = (state = {profitLoss: {}}, action) => {

    switch (action.type) {

        case PROFITLOSS_UPDATE_REQUEST:
            return {
                
            }
        case PROFITLOSS_UPDATE_SUCCESS:

            return {

            }
        case PROFITLOSS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const profitLossDeleteReducer = (state = {profitLoss: {}}, action) => {

    switch (action.type) {
        case PROFITLOSS_DELETE_REQUEST:
            return {
                
            }
        case PROFITLOSS_DELETE_SUCCESS:
            return {

            }
        case PROFITLOSS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {profitLossListReducer, profitLossCreateReducer, profitLossViewReducer, profitLossUpdateReducer, profitLossDeleteReducer }