export const ASSETTYPES_LIST_REQUEST = 'ASSETTYPES_LIST_REQUEST'
export const ASSETTYPES_LIST_SUCCESS = 'ASSETTYPES_LIST_SUCCESS'
export const ASSETTYPES_LIST_FAIL = 'ASSETTYPES_LIST_FAIL'


export const ASSETTYPE_CREATE_REQUEST = 'ASSETTYPE_CREATE_REQUEST'
export const ASSETTYPE_CREATE_SUCCESS = 'ASSETTYPE_CREATE_SUCCESS'
export const ASSETTYPE_CREATE_FAIL = 'ASSETTYPE_CREATE_FAIL'


export const ASSETTYPE_UPDATE_REQUEST = 'ASSETTYPE_UPDATE_REQUEST'
export const ASSETTYPE_UPDATE_SUCCESS = 'ASSETTYPE_UPDATE_SUCCESS'
export const ASSETTYPE_UPDATE_FAIL = 'ASSETTYPE_UPDATE_FAIL'

export const ASSETTYPE_DELETE_REQUEST = 'ASSETTYPE_DELETE_REQUEST'
export const ASSETTYPE_DELETE_SUCCESS = 'ASSETTYPE_DELETE_SUCCESS'
export const ASSETTYPE_DELETE_FAIL = 'ASSETTYPE_DELETE_FAIL'

export const VIEW_ASSETTYPE_REQUEST = 'VIEW_ASSETTYPE_REQUEST'
export const VIEW_ASSETTYPE_SUCCESS = 'VIEW_ASSETTYPE_SUCCESS'
export const VIEW_ASSETTYPE_FAIL = 'VIEW_ASSETTYPE_FAIL'