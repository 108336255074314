import { AVAILABILITYSTATUSES_LIST_REQUEST, AVAILABILITYSTATUSES_LIST_SUCCESS, AVAILABILITYSTATUSES_LIST_FAIL} from '../Constants/availabilityStatusConstants';
import api from '../../config/apiConfig';


const getAvailabilityStatusesList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: AVAILABILITYSTATUSES_LIST_REQUEST});
        const {data} = await api.get('/availabilitystatuses');
        // console.log('AvailabilityStatus data is:' + data[0]['AvailabilityStatus_Name'])
        dispatch({type: AVAILABILITYSTATUSES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: AVAILABILITYSTATUSES_LIST_FAIL, payload: error})
    }
} 


export {getAvailabilityStatusesList}