import React, {useState, useEffect, useRef} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box} from '@mui/material';
import { blueGrey, blue, pink, red } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getWorkers } from '../../../../../Redux/Actions/WorkerActions';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import api from '../../../../../config/apiConfig';
import DropFileInput from '../../../../DropFileInput';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import { getDocumentTypesList } from '../../../../../Redux/Actions/DocumentTypeActions';
import { submitDocument } from '../../../../../Redux/Actions/DocumentActions';


const AddDocumentDialog = ({open, onClose, type, info, handleSubmit}) => {


    //ACCESS DISPATCH
    const dispatch = useDispatch(); 
    //GET WORKERS
    const {workers, loading} = useSelector(state => state.workers_List);
    const {documentTypes, loading_types} = useSelector(state => state.documentTypes_List);
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getWorkers());
        dispatch(getDocumentTypesList());
       
    }, [])
    

    const [values, setValues] = useState({
            
            workers: '',
            documents: [],
            documenttypes: '',
        });
        
    const [submitValues, setSubmitValues] = useState({
            
            workers: '',
            documenttypes: '', 
        });

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
          
        }
        else if(name == 'type' || name == 'status' || name == 'result')
        {

            setValues({
                ...values,
                [name]: value
            });
           
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
 
        }
    }

    const handleSave = () => {
        console.log('in save function')
        dispatch(submitDocument({...submitValues, documents: values.documents}));
        //handleSubmit('Successfully added document(s)');

    }

    const animatedComponents = makeAnimated();


    const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {

        
        const keeper = [];

        const tableName = name == 'workers' ? workers : name == 'documenttypes' ? documentTypes :  '';

        const id_field = name === 'documenttypes' ? 'Id' : name === 'workers'? 'id' : name === 'clients'? 'Client_Id': name === 'shiftstatuses'? 'Id' : name === 'jobroles'? 'Role_Id': name === 'ratecards'? 'Id' : name === 'contacts'? 'Id' : '';
        const name_field = name === 'documenttypes' ? 'Name' : name === 'workers'? 'FullName' : name === 'clients'? 'Name': name === 'shiftstatuses'? 'Name' : name === 'jobroles'? 'Name' : name === 'ratecards'? 'Name' : name === 'contacts'? 'First_Name' :'';
            
        tableName.forEach(x => {
            if(name === 'workers' || name === 'contacts')
            {
                keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
            }
            else{
                keeper.push({value: x[id_field], label: x[name_field]})

            }
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['divisions', 'staff', 'workers', 'clients', 'shiftstatuses', 'jobroles', 'ratecards', 'contacts', 'documenttypes'];
        
        const id_field = name === 'documenttypes' ? 'Id' : name === 'workers'? 'id' : name === 'clients'? 'Client_Id': name === 'shiftstatuses'? 'Id' : name === 'jobroles'? 'Role_Id': name === 'ratecards'? 'Id' : name === 'contacts'? 'Id' : '';
        const name_field = name === 'documenttypes' ? 'Name' : name === 'workers'? 'FullName' : name === 'clients'? 'Name': name === 'shiftstatuses'? 'Name' : name === 'jobroles'? 'Name' : name === 'ratecards'? 'Name' : name === 'contacts'? 'First_Name' :'';
        
        names.forEach(x => x === name)
        {
            
            data.forEach(x => {

                  keeper.push({value: x[id_field], label: x[name_field]})
            
                })
               
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action, essentials) => {
        const name = action.name;
        if(name === 'documenttypes'){
            const {file, index} = essentials;
            console.log('VALUE IS, oooo: ' + value)
            handleType(value.label, file, index, action.name)
        }

        setValues({
            ...values,
            [name]: value
        })
        setSubmitValues({
            ...submitValues,
            [name]: value.value
        })

    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
   
    const onFileChange = (files) => {
        console.log(files)
        setValues({...values, documents: files})
    }
    
    const handleType = (value, file, index, name) => {
        console.log('EXPIRY VALUE at the top :::::' + values.divisions[index].expiry)
        console.log('KO:' + file.id)
        console.log('DATE VALUE IS:' + value)

        const updated_array = values.documents.map(x => {
            
            console.log('X.EXPIRY:' +Object.getOwnPropertyNames(x))
            //console.log('X.EXPIRY:' + x.expiry)
            if(file.id === x.id)
            {   return {
                        name: x.name,
                        size: x.size,
                        expiry: values.divisions[index].expiry,
                        [name]: value,
                    }
            }
            else{

                return x
            }
        })
        console.log('pp: ' + updated_array[index].expiry)
        setValues({
            ...values,
            documents: [...updated_array]
        })
        console.log('EXPIRY VALUE at the bottom :::::' + updated_array[index].expiry)
       
    }

    const [date, setDate] = useState()

    //-----------------------------------------  ---------------------

    const handleDateChange = (value, index, file) => {
        console.log('ok' +file);
        //change value ---------------------------------

        const day = value.getDate();
        const month = value.getMonth();
        const year = value.getFullYear();
        const merged_date = `${day}/${month}/${year}`;

        //-----------------------------------------------

        let updated_array = values.documents.map(x => {
            
            if(file.id === x.id)
            {  
                return {
                    name: x.name,
                    size: x.size,
                    type: x.type,
                    expiry: value,
                }
            }
            else{
                return x
            }
        })
        setDate(value);
        setValues({
            ...values,
            date: value,
            divisions: [...updated_array]
        })
        console.log('EXPIRY VALUE in date method:::::' + updated_array[index].expiry)
        //VS
        //console.log('EXPIRY VALUE :::::' + values.divisions[index].expiry)
    }

    const block = useRef(null)
    const handleHover = () => {
        block.current.style.display = 'flex';
    }
    const handleExitHover = () => {
        block.current.style.display = 'none';
    }
    
   
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >
            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[700]}}  >
                
                <CheckCircleIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add Document </Typography>
            </DialogTitle>
        <Divider/>
        {loading? <LinearProgress sx={{ color: 'green'}} /> : '' }
        
        <DialogContent sx={{background: 'white'}}>
        
     {/* <h1>{values.divisions? values.divisions[0].expiry : ''}</h1> */}
    
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                         <Grid item lg={4} sx={{zIndex: 10, mt: 4}}>
                            
                            <DropFileInput onFileChange={(files) => onFileChange(files)}/>

                        </Grid>
                        
                        <Grid item lg={7}>
                        <Typography variant="body2" sx={{display: 'block', fontFamily: 'Poppins' ,color: blue[700],background: blue[50], borderRadius: '5px', px: 2, py: '5px'}}>Documents</Typography>
                        <br/>

                            {
                                values.documents != ''? 
                                <> 
                                <Typography variant="body2" sx={{display: 'block', fontFamily: 'Poppins' ,color: blueGrey[500], borderRadius: '5px', px: 2}}>Your documents are ready to be uploaded</Typography>
                                {values.documents.map((file, index) => (
                                    <Box onMouseEnter={handleHover} onMouseLeave={handleExitHover} sx={{background: 'rgba(227, 242, 253,.25)', pt: 1, pb: 1, px: 2,my: 2, borderRadius: '10px' }}>
                                        <div key={index} className="drop-file-preview__item">
                                            <PictureAsPdfRoundedIcon sx={{mr: 2, fontSize: 30, color: pink[500], p: 0}}/>
                                            <div className="drop-file-preview__item__info w-100 ml-5">
                                                <span>{file.name}</span>
                                                <span>{file.size}B</span>
                                            </div>
                                            <span className="drop-file-preview__item__delete" >
                                                <IconButton className="dfp-delete" size="small" sx={{background: 'white', boxShadow: '.5px .5px 13px whitesmoke'}}>
                                                    <CheckCircleIcon fontSize="inherit" sx={{color: blueGrey[700]}}/>
                                                </IconButton>
                                            </span>
                                        </div>
                                        <Stack ref={block} direction="row" spacing={2} sx={{display: 'none', mt: 2}}>
                                        {/* <Form.Control name="expiry" onChange={(e)=> handleType(e, file, index)} size="sm" type="text" value={values.documents[index].expiry} placeholder="Expiration Date" className="outline-none border-none" />  */}
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <ReactPicker
                                                    name="expiry"
                                                    label="Expiry Date"
                                                    value={values.date}
                                                    onChange={(e) => handleDateChange(e, index, file)}
                                                    renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '50%'}} {...params} />}
                                                />
                                            </LocalizationProvider>
                                            <AsyncSelect
                                                value={values.documenttypes}
                                                name="documenttypes"
                                                cacheOptions
                                                loadOptions={(value, action) => loadOptions(value, 'documenttypes')}
                                                defaultOptions
                                                onChange= {(value, action) => handleOptionChange(value, action, {file, index} )}
                                                components={animatedComponents}
                                                placeholder="Search for type ..."
                                                className="w-50 dt"
                                                onInputChange={handleInputChange}
                                            />

                                        </Stack>
                                    </Box>
                                ))}
                                </>
                                : <Typography variant="body2" sx={{display: 'block',color: red[500],background: red[50], borderRadius: '5px', px: 2, py: 3, textAlign: 'center'}}>No document details. Upload document first</Typography>
                            }
                            <Form className="my-0">
                            <br/>
                            <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Worker</Typography>
                           
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    {/* <Form.Label className="view-label">Worker</Form.Label> */}
                                    <AsyncSelect
                                        value={values.workers}
                                        name="workers"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'workers')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for worker ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                
                                
                            </Row>
                            </Form>
                        
                      
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[800], '&:hover': {color: blue[900]}}} onClick={handleSave}>Save Document(s)</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddDocumentDialog
