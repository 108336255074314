import { VIEW_PROFILE_REQUEST, VIEW_PROFILE_SUCCESS, VIEW_PROFILE_FAIL, PROFILE_CREATE_REQUEST, PROFILE_CREATE_SUCCESS, PROFILE_CREATE_FAIL, PROFILE_UPDATE_SUCCESS, PROFILE_DELETE_SUCCESS, PROFILE_DELETE_FAIL, PROFILE_UPDATE_REQUEST, PROFILE_UPDATE_FAIL, PROFILE_DELETE_REQUEST, PROFILES_LIST_REQUEST, PROFILES_LIST_SUCCESS, PROFILES_LIST_FAIL } from "../Constants/profileConstants";

const profilesListReducer = (state = {profiles: []}, action) => {

    switch (action.type) {

        case PROFILES_LIST_REQUEST:
            return {
                loading: true,
            }

        case PROFILES_LIST_SUCCESS: 
            console.log('In profile reducer, have received: ' + action.payload)
            return {
                loading: false,
                profiles: action.payload
            };

        case PROFILES_LIST_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const profileCreateReducer = (state = {profiles: []}, action) => {

    switch (action.type) {

        case PROFILE_CREATE_REQUEST:
            return {
                loading: true
            }

        case PROFILE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                profiles: [...state.profiles, {Profile_Id: 2, Name: value}]
            };

        case PROFILE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const profileViewReducer = (state = {profile: {}}, action) => {

    switch (action.type) {

        case VIEW_PROFILE_REQUEST:
            return {
                loading: true,
            }

        case VIEW_PROFILE_SUCCESS: 
            console.log('In reducer, have received: ' + action.payload.Id)
            return {
                loading: false,
                profile: action.payload
            };

        case VIEW_PROFILE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const profileUpdateReducer = (state = {profiles: {}}, action) => {

    switch (action.type) {

        case PROFILE_UPDATE_REQUEST:
            return {
                
            }
        case PROFILE_UPDATE_SUCCESS:

            return {

            }
        case PROFILE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const profileDeleteReducer = (state = {profiles: {}}, action) => {

    switch (action.type) {
        case PROFILE_DELETE_REQUEST:
            return {
                
            }
        case PROFILE_DELETE_SUCCESS:
            return {

            }
        case PROFILE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}




export {profilesListReducer, profileViewReducer, profileCreateReducer, profileUpdateReducer, profileDeleteReducer}