import { SET_ACTIVE, SET_ACTIVE_ERROR, SET_L1_ACTIVE, SET_L1_ACTIVE_ERROR, SET_L2_ACTIVE, SET_L2_ACTIVE_ERROR, SET_L3_ACTIVE, SET_L3_ACTIVE_ERROR } from "../Constants/sitemapConstants";

const activeLinkReducer = (state = { active_link_object: {}}, action) => {

    switch(action.type)
    {
        case SET_ACTIVE:
            return {
                active_link: action.payload
            }
        case SET_ACTIVE_ERROR:
            return {
                error: action.payload
            }
        default:
            return state
    }
 
}

const activeLinkReducerFirstLevel = (state = { active_link_object: {}}, action) => {

    switch(action.type)
    {
        case SET_L1_ACTIVE:
            return {
                active_link: action.payload
            }
        case SET_L1_ACTIVE_ERROR:
            return {
                error: action.payload
            }
        default:
            return state
    }
 
}

const activeLinkReducerSecondLevel = (state = { active_link_object: {}}, action) => {

    switch(action.type)
    {
        case SET_L2_ACTIVE:
            return {
                active_link: action.payload
            }
        case SET_L2_ACTIVE_ERROR:
            return {
                error: action.payload
            }
        default:
            return state
    }
 
}

const activeLinkReducerThirdLevel = (state = { active_link_object: {}}, action) => {

    switch(action.type)
    {
        case SET_L3_ACTIVE:
            return {
                active_link: action.payload
            }
        case SET_L3_ACTIVE_ERROR:
            return {
                error: action.payload
            }
        default:
            return state
    }
 
}

export {activeLinkReducer, activeLinkReducerFirstLevel, activeLinkReducerSecondLevel, activeLinkReducerThirdLevel}