import { INCIDENTTYPES_LIST_REQUEST, INCIDENTTYPES_LIST_SUCCESS, INCIDENTTYPES_LIST_FAIL, VIEW_INCIDENTTYPE_FAIL, VIEW_INCIDENTTYPE_REQUEST, VIEW_INCIDENTTYPE_SUCCESS, INCIDENTTYPE_CREATE_FAIL, INCIDENTTYPE_UPDATE_REQUEST, INCIDENTTYPE_UPDATE_FAIL, INCIDENTTYPE_DELETE_REQUEST, INCIDENTTYPE_DELETE_FAIL, INCIDENTTYPE_CREATE_REQUEST, INCIDENTTYPE_CREATE_SUCCESS, INCIDENTTYPE_UPDATE_SUCCESS, INCIDENTTYPE_DELETE_SUCCESS } from "../Constants/incidentTypeConstants";

const incidentTypesListReducer = (state = {incidentTypes: []}, action) => {

    switch (action.type)
    {
        case INCIDENTTYPES_LIST_REQUEST:
            return {loading: true};
 
        case INCIDENTTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and incidentTypes data is:' + action.payload)
            return {
                loading: false,
                incidentTypes: action.payload
            }
        case INCIDENTTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const incidentTypeCreateReducer = (state = {incidentTypes: []}, action) => {

    switch (action.type) {

        case INCIDENTTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case INCIDENTTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                incidentTypes: [...state.incidentTypes, {Applicant_Id: 2, Name: value}]
            };

        case INCIDENTTYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const incidentTypeViewReducer = (state= {incidentType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_INCIDENTTYPE_REQUEST:
            return {loading: true};
        case VIEW_INCIDENTTYPE_SUCCESS:
            return {
                    loading: false,
                    incidentType: action.payload
                }
        case VIEW_INCIDENTTYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const incidentTypeUpdateReducer = (state = {incidentTypes: {}}, action) => {

    switch (action.type) {

        case INCIDENTTYPE_UPDATE_REQUEST:
            return {
                
            }
        case INCIDENTTYPE_UPDATE_SUCCESS:

            return {

            }
        case INCIDENTTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const incidentTypeDeleteReducer = (state = {incidentTypes: {}}, action) => {

    switch (action.type) {
        case INCIDENTTYPE_DELETE_REQUEST:
            return {
                
            }
        case INCIDENTTYPE_DELETE_SUCCESS:
            return {

            }
        case INCIDENTTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {incidentTypesListReducer, incidentTypeCreateReducer, incidentTypeViewReducer, incidentTypeUpdateReducer, incidentTypeDeleteReducer }