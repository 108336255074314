import React from 'react';
import AddCreditCheckDialog from './AddCreditCheckDialog';
import ViewCreditCheckDialog from './ViewCreditCheckDialog';
import DeleteCreditCheckDialog from './DeleteCreditCheckDialog';



const CreditCheckDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        { 
            type === "create"?

            <AddCreditCheckDialog open={open} onClose={onClose}  handleSubmit={handleSubmit} fullWidth />
            
            : type === "view"?

            <ViewCreditCheckDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />

            : type === "delete"?

            <DeleteCreditCheckDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default CreditCheckDialogs
