export const TASKS_LIST_REQUEST = 'TASKS_LIST_REQUEST';
export const TASKS_LIST_SUCCESS = 'TASKS_LIST_SUCCESS';
export const TASKS_LIST_FAIL = 'TASKS_LIST_FAIL'; 

export const TASK_CREATE_REQUEST = 'TASK_CREATE_REQUEST'
export const TASK_CREATE_SUCCESS = 'TASK_CREATE_SUCCESS'
export const TASK_CREATE_FAIL = 'TASK_CREATE_FAIL'


export const TASK_UPDATE_REQUEST = 'TASK_UPDATE_REQUEST'
export const TASK_UPDATE_SUCCESS = 'TASK_UPDATE_SUCCESS'
export const TASK_UPDATE_FAIL = 'TASK_UPDATE_FAIL'

export const TASK_DELETE_REQUEST = 'TASK_DELETE_REQUEST'
export const TASK_DELETE_SUCCESS = 'TASK_DELETE_SUCCESS'
export const TASK_DELETE_FAIL = 'TASK_DELETE_FAIL'

export const VIEW_TASK_REQUEST = 'VIEW_TASK_REQUEST'
export const VIEW_TASK_SUCCESS = 'VIEW_TASK_SUCCESS'
export const VIEW_TASK_FAIL = 'VIEW_TASK_FAIL'