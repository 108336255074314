import { RATECARDS_LIST_REQUEST, RATECARDS_LIST_SUCCESS, RATECARDS_LIST_FAIL, VIEW_RATECARD_FAIL, VIEW_RATECARD_REQUEST, VIEW_RATECARD_SUCCESS, RATECARD_CREATE_REQUEST, RATECARD_CREATE_SUCCESS, RATECARD_CREATE_FAIL, RATECARD_UPDATE_FAIL, RATECARD_UPDATE_REQUEST, RATECARD_UPDATE_SUCCESS } from '../Constants/rateCardConstants';
import api from '../../config/apiConfig';


const getRateCardsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: RATECARDS_LIST_REQUEST});
        const {data} = await api.get('/ratecards');
        console.log('RateCards data is:' + data[0]['Name'])
        dispatch({type: RATECARDS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: RATECARDS_LIST_FAIL, payload: error})
    }
} 

const submitRateCard = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: RATECARD_CREATE_REQUEST});
        
        const {data} = await api.post(`/ratecards/`, values);
        console.log('RateCard data is:' + data)
        dispatch({type: RATECARD_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: RATECARD_CREATE_FAIL, payload: error})
    }
} 

const getRateCard = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_RATECARD_REQUEST});
        
        const {data} = await api.get(`/ratecards/ ${id}`);
        console.log('RateCard data is:' + data)
        dispatch({type: VIEW_RATECARD_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_RATECARD_FAIL, payload: error})
    }
}

const editRateCard = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: RATECARD_UPDATE_REQUEST});
        
        const {data} = await api.put(`/ratecards/`, values);
        console.log('RateCard data is:' + data)
        dispatch({type: RATECARD_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: RATECARD_UPDATE_FAIL, payload: error})
    }
}



export {getRateCardsList, submitRateCard, getRateCard, editRateCard }