export const EXPENSETYPES_LIST_REQUEST = 'EXPENSETYPES_LIST_REQUEST'
export const EXPENSETYPES_LIST_SUCCESS = 'EXPENSETYPES_LIST_SUCCESS'
export const EXPENSETYPES_LIST_FAIL = 'EXPENSETYPES_LIST_FAIL'


export const EXPENSETYPE_CREATE_REQUEST = 'EXPENSETYPE_CREATE_REQUEST'
export const EXPENSETYPE_CREATE_SUCCESS = 'EXPENSETYPE_CREATE_SUCCESS'
export const EXPENSETYPE_CREATE_FAIL = 'EXPENSETYPE_CREATE_FAIL'


export const EXPENSETYPE_UPDATE_REQUEST = 'EXPENSETYPE_UPDATE_REQUEST'
export const EXPENSETYPE_UPDATE_SUCCESS = 'EXPENSETYPE_UPDATE_SUCCESS'
export const EXPENSETYPE_UPDATE_FAIL = 'EXPENSETYPE_UPDATE_FAIL'

export const EXPENSETYPE_DELETE_REQUEST = 'EXPENSETYPE_DELETE_REQUEST'
export const EXPENSETYPE_DELETE_SUCCESS = 'EXPENSETYPE_DELETE_SUCCESS'
export const EXPENSETYPE_DELETE_FAIL = 'EXPENSETYPE_DELETE_FAIL'

export const VIEW_EXPENSETYPE_REQUEST = 'VIEW_EXPENSETYPE_REQUEST'
export const VIEW_EXPENSETYPE_SUCCESS = 'VIEW_EXPENSETYPE_SUCCESS'
export const VIEW_EXPENSETYPE_FAIL = 'VIEW_EXPENSETYPE_FAIL'