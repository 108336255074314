export const INCOMETYPES_LIST_REQUEST = 'INCOMETYPES_LIST_REQUEST'
export const INCOMETYPES_LIST_SUCCESS = 'INCOMETYPES_LIST_SUCCESS'
export const INCOMETYPES_LIST_FAIL = 'INCOMETYPES_LIST_FAIL'


export const INCOMETYPE_CREATE_REQUEST = 'INCOMETYPE_CREATE_REQUEST'
export const INCOMETYPE_CREATE_SUCCESS = 'INCOMETYPE_CREATE_SUCCESS'
export const INCOMETYPE_CREATE_FAIL = 'INCOMETYPE_CREATE_FAIL'


export const INCOMETYPE_UPDATE_REQUEST = 'INCOMETYPE_UPDATE_REQUEST'
export const INCOMETYPE_UPDATE_SUCCESS = 'INCOMETYPE_UPDATE_SUCCESS'
export const INCOMETYPE_UPDATE_FAIL = 'INCOMETYPE_UPDATE_FAIL'

export const INCOMETYPE_DELETE_REQUEST = 'INCOMETYPE_DELETE_REQUEST'
export const INCOMETYPE_DELETE_SUCCESS = 'INCOMETYPE_DELETE_SUCCESS'
export const INCOMETYPE_DELETE_FAIL = 'INCOMETYPE_DELETE_FAIL'

export const VIEW_INCOMETYPE_REQUEST = 'VIEW_INCOMETYPE_REQUEST'
export const VIEW_INCOMETYPE_SUCCESS = 'VIEW_INCOMETYPE_SUCCESS'
export const VIEW_INCOMETYPE_FAIL = 'VIEW_INCOMETYPE_FAIL'