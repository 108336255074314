import { CREDITCHECKS_LIST_REQUEST, CREDITCHECKS_LIST_SUCCESS, CREDITCHECKS_LIST_FAIL, VIEW_CREDITCHECK_FAIL, VIEW_CREDITCHECK_REQUEST, VIEW_CREDITCHECK_SUCCESS, CREDITCHECK_CREATE_FAIL, CREDITCHECK_UPDATE_REQUEST, CREDITCHECK_UPDATE_FAIL, CREDITCHECK_DELETE_REQUEST, CREDITCHECK_DELETE_FAIL, CREDITCHECK_CREATE_REQUEST, CREDITCHECK_CREATE_SUCCESS, CREDITCHECK_UPDATE_SUCCESS, CREDITCHECK_DELETE_SUCCESS } from "../Constants/creditCheckConstants";

const creditChecksListReducer = (state = {creditChecks: []}, action) => {

    switch (action.type)
    {
        case CREDITCHECKS_LIST_REQUEST:
            return {loading: true};
 
        case CREDITCHECKS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and creditChecks data is:' + action.payload)
            return {
                loading: false,
                creditChecks: action.payload
            }
        case CREDITCHECKS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const creditCheckCreateReducer = (state = {creditChecks: []}, action) => {

    switch (action.type) {

        case CREDITCHECK_CREATE_REQUEST:
            return {
                loading: true
            }

        case CREDITCHECK_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                creditChecks: [...state.creditChecks, {Applicant_Id: 2, Name: value}]
            };

        case CREDITCHECK_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const creditCheckViewReducer = (state= {creditCheck: {}}, action) => {
    switch(action.type)
    {
        case VIEW_CREDITCHECK_REQUEST:
            return {loading: true};
        case VIEW_CREDITCHECK_SUCCESS:
            return {
                    loading: false,
                    creditCheck: action.payload
                }
        case VIEW_CREDITCHECK_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const creditCheckUpdateReducer = (state = {creditChecks: {}}, action) => {

    switch (action.type) {

        case CREDITCHECK_UPDATE_REQUEST:
            return {
                
            }
        case CREDITCHECK_UPDATE_SUCCESS:

            return {

            }
        case CREDITCHECK_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const creditCheckDeleteReducer = (state = {creditChecks: {}}, action) => {

    switch (action.type) {
        case CREDITCHECK_DELETE_REQUEST:
            return {
                
            }
        case CREDITCHECK_DELETE_SUCCESS:
            return {

            }
        case CREDITCHECK_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {creditChecksListReducer, creditCheckCreateReducer, creditCheckViewReducer, creditCheckUpdateReducer, creditCheckDeleteReducer }