import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo, purple, cyan } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import AccAndIncidentDialogs from '../../../Dialogs/CRM/Operations/AccsAndIncidents/AccAndIncidentDialogs';

const IncidentsTbl = ({incidents}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    const getTypeColor = (jr) => {
        return jr === 'Construction' ? lightGreen[500] : jr === 'Ad Hok'?  pink[500] : jr === 'Temp to Perm'? orange[500] : jr === 'Ongoing'? lightBlue[500] : blueGrey[500]
    }
    const getStatusColor = (jr) => {
        return jr === 'New' ? lightGreen[500] : jr === 'Approved'?  pink[500] : jr === 'Working On'? orange[500] : jr === 'Unable to fill'? lightBlue[500] : jr === 'Filled'? cyan[500]  : jr === 'Complete'? lightBlue[500] : blueGrey[500]
    }
    const getDivisionColor = (jr) => {
        return jr === 'Construction' ? lightGreen[500] : jr === 'Driving'?  pink[500] : jr === 'Aviation'? orange[500] : jr === 'Industrial'? lightBlue[500] : blueGrey[500]
    }

    const switchToViewIncident = (id) => {
        navigate('/incident/'+ id)
    } 

    const incidentQuickView = (id) => {

        showDialog(true, 'view', id)
    }


 
    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Type', headerName: 'Type', minWidth: 180 , type: 'singleSelect', valueOptions: (['Accident-Complex', 'Accident-Client', 'Accident-Nova', 'Accident-Prime', 'Gross Misconduct', 'Failed Drugs Test']), renderCell: (params)=> (
            <Typography onClick={()=> {incidentQuickView(params.row.id)}} sx={{color: 'white', fontSize: '.7rem', backgroundColor: getTypeColor(params.row.Type), borderRadius: '30px', px: '10px', cursor: 'pointer'}}>{params.row.Type}</Typography>
        )},
        { field: 'Details', headerName: 'Details', width: 200},
        { field: 'Date', headerName: 'Date'},
        { field: 'Client', headerName: 'Client'},
        { field: 'Worker', headerName: 'Worker'},
        { field: 'Consultant', headerName: 'Consultant'},
        { field: 'Contact', headerName: 'Handling (External)'},
        { field: 'MgtSignOff', headerName: 'MgtSignOff'},
        { field: 'Signatory', headerName: 'Signatory'},
        { field: 'Status', headerName: 'Status', minWidth: 180 , type: 'singleSelect', valueOptions: (['New', 'Approved', 'Working On', 'Unable to fill', 'Filled', 'Complete']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewIncident(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];

    return (
        
        <>
            { dialog.open  ?  <AccAndIncidentDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={incidents}
                        loading={!incidents.length}
                    />
                </div>
            </div>
        </>
    )
}

export default IncidentsTbl
