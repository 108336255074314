import { WOMS_LIST_REQUEST, WOMS_LIST_SUCCESS, WOMS_LIST_FAIL, VIEW_WOM_FAIL, VIEW_WOM_REQUEST, VIEW_WOM_SUCCESS, WOM_CREATE_REQUEST, WOM_CREATE_SUCCESS, WOM_CREATE_FAIL, WOM_UPDATE_FAIL, WOM_UPDATE_REQUEST, WOM_UPDATE_SUCCESS } from '../Constants/womConstants';
import api from '../../config/apiConfig';


const getWOMsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: WOMS_LIST_REQUEST});
        const {data} = await api.get('/woms');
        // console.log('WOMs data is:' + data[0]['WOM_Name'])
        dispatch({type: WOMS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: WOMS_LIST_FAIL, payload: error})
    }
}  

const submitWOM = (stateValues, submitValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: WOM_CREATE_REQUEST});
        
        const {data} = await api.post(`/woms/`, submitValues);
        console.log('WOM data is:' + data)
        dispatch({type: WOM_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: WOM_CREATE_FAIL, payload: error})
    }
} 

const getWOM = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_WOM_REQUEST});
        
        const {data} = await api.get(`/woms/ ${id}`);
        console.log('WOM data is:' + data)
        dispatch({type: VIEW_WOM_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_WOM_FAIL, payload: error})
    }
}

const editWOM = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: WOM_UPDATE_REQUEST});
        
        const {data} = await api.put(`/woms/`, values);
        console.log('WOM data is:' + data)
        dispatch({type: WOM_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: WOM_UPDATE_FAIL, payload: error})
    }
}



export {getWOMsList, submitWOM, getWOM, editWOM }