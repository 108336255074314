import React, {useState, useEffect} from 'react';
import { Link as BreadcrumbLink, CircularProgress } from '@mui/material';
import { Paper,IconButton, InputLabel, Input, Button, Grid, Divider, Alert, LinearProgress, Stack, Typography, List, ListItemText, Breadcrumbs } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../Hooks/useDialog';
import { blueGrey, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicantsList } from '../../../Redux/Actions/ApplicantsActions';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import { blue, yellow, orange, green } from '@material-ui/core/colors';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import Crumbs from '../../../Components/Utilities/Crumbs';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import ApplicantsTbl from '../../../Components/Table/CRM/HR/ApplicantsTbl';
import ApplicantDialogs from '../../../Components/Dialogs/CRM/HR/ApplicantDialogs/ApplicantDialogs';


const Applicants = () => { 

    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET APPLICANTS
    const {loading, applicants} = useSelector((state) => state.applicants_List);

    const [list, setList] = useState([])

//----------------------------------------------------------------------------------------------------------------------------------
    
    useEffect(()=> {
        dispatch(getApplicantsList());
    }, [])

    useEffect(() => {
        if(applicants)
        {
            setList([...applicants])
        }
    }, [applicants])


   
//----------------------------------------------------------------------------------------------------------------------------------
    
    //TABLE DIALOG FUNCTIONALITY
    
    const handleAddClick = () => {
        console.log('THIS IS WORKING')
        showDialog(true, 'create', '');
    }

    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)
    
    //----------------------------------------------------------------------------------------------------------------------------------


    
    return (
        <>
       
        <ScreenHeader title="Applicants" subtitle="Manage all applicants from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>
        
        {dialog.open? <ApplicantDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : ''}

            <Grid container justifyContent="space-between" sx={{mb: 2}}>
                <Crumbs root="CRM" middle="Recruitment" end="Applicants" main/>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <AddButtonLabel item="Applicant"/>
                    <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                        <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                    </IconButton>
                </Stack>
            </Grid>

            <Divider sx={{color: lightBlue[800]}}/>
            {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
            { loading ?  <HorizontalLoading/> : '' }
            <HorizontalSummaryBox primary="Applicants Brief" secondary="Overall Applicants count" label1="All Applicants" label2="Second Label" label3="Third Label" count={[2,11,6]}/>
           

            <AboveTableHeader title="Applicants List"/>
            <Grid container>
                <ApplicantsTbl applicants={list}/>
            </Grid>



    
    </>
    )
}

export default Applicants
