import React from 'react'

const ViewTest = () => {
    return (
        <div>
            ViewTest Page
            
        </div>
    )
}

export default ViewTest
