import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, CircularProgress} from '@mui/material';
import { blueGrey, orange, blue, purple, red } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicantsList } from '../../../../../Redux/Actions/ApplicantsActions';
import { getAssessmentTypesList } from '../../../../../Redux/Actions/AssessmentTypesActions';
import { getAssessmentResultsList } from '../../../../../Redux/Actions/AssessmentResultsActions';
import { getAssessmentStatusesList } from '../../../../../Redux/Actions/AssessmentStatusesActions';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { Link as BreadcrumbLink } from '@mui/material';
import { submitAssessment, getAssessment } from '../../../../../Redux/Actions/AssessmentsActions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ComplexAlert from '../../../../ComplexAlert';
import useAlert from '../../../../../Hooks/useAlert';
import { getAvailability } from '../../../../../Redux/Actions/AvailabilityActions';
import EditInDialog from '../../../../Utilities/EditInDialog';
import DialogNavigation from '../../../../Utilities/DialogNavigation';



const ViewAvailabilityDialog = ({open, onClose, type, info, handleSubmit}) => {

    const id = info;


    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET ASSESSMENTS
    const availability_Record = useSelector(state => state.availability_View);
    const {availability, loading} = availability_Record;

    
       
    //CURRENT DATE
    const d = new Date(), yyyy = d.getFullYear(), mm = d.getMonth(), dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getAvailability(id));
        dispatch(getApplicantsList()); 
        dispatch(getStaffList());
        dispatch(getAssessmentTypesList());
        dispatch(getAssessmentResultsList());
        dispatch(getAssessmentStatusesList());
       
    }, [])
    

    const [values, setValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            
            //const the_name = getName(value);
            //setValues({ applicant: the_name })
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            
 
        }
    }
  

    const handleSave = () => {
        
        dispatch(submitAssessment(submitValues))
        handleSubmit();

    }

    const {alert, showAlert} = useAlert();
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        showAlert('info','You can now edit this assessment',true)
        setIsEditing(!isEditing);
    }
    
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >
            {/* <h3>I am receiving info: {info.Assessment_Subject}</h3> */}
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: blue[200], background: blue[800]}}  >
                <Stack direction="row">
                    <AssessmentIcon sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/>
                    <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}>{'Viewing Availability'}</Typography>
                </Stack>
                <Typography variant="body2" sx={{color: "white",fontWeight: 500, background: blue[700], borderRadius: '5px', px: 2, py: '5px',  boxShadow: '.5px .5px 5px blue'}}>{loading? <CircularProgress size={15}  color="primary"/> : availability.Status}</Typography>
            </DialogTitle>
    
        {alert.show?  <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        <Divider/>
        {loading? <LinearProgress sx={{ color: 'green'}} /> : '' }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">
                    <Grid item lg={12} justifyContent="space-between">
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Stack direction="column">
                                <Typography sx={{display: 'inline',fontSize: '.9rem', fontFamily: 'Poppins', fontWeight: '800', color: blueGrey[300] }}>Title</Typography>
                                <Typography sx={{fontSize: '1.4rem', fontFamily: 'Poppins-Medium', fontWeight: '800'}}>{loading? '' : `${availability.Worker} - ${availability.WeekCommencing}`}</Typography>
                            </Stack>
                            <EditInDialog isEditing={isEditing} handleEditClick={handleEditClick}/>
                        </Grid>
                    </Grid>

                <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                    <DialogNavigation/>
                </Grid>

                
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>
                            <Row >
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Availability ID</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled={isEditing? false : true} type="text" value={loading? '' : `AVL - ${availability.Id} `} className={isEditing? "" :"border-0 autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Availability Date</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.assessment_date} placeholder="E.g 123qst1" className={isEditing? "" :"border-0 autofocus"} />
                                </Form.Group>
                            </Row>
                            <Row >
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Worker</Form.Label>
                                    <Form.Control name="fname" onChange={handleChange}  disabled={isEditing? false : true} type="text" value={loading? '' : `${availability.Worker} `} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>

                            <Divider sx={{my: 2, color: blue[200]}}/>

                            <Row >
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Monday</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${availability.Monday}` === 'Available' ? <DoneIcon fontSize="small" sx={{color: blue[600]}}/> : `${availability.Monday}`}</Typography>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Tuesday</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${availability.Tuesday}` === 'Available' ? <DoneIcon fontSize="small" sx={{color: blue[600]}}/> : `${availability.Tuesday}`}</Typography>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Wednesday</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${availability.Wednesday}` === 'Available' ? <DoneIcon fontSize="small" sx={{color: blue[600]}}/> : `${availability.Wednesday}`}</Typography>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Thursday</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${availability.Thursday}` === 'Available' ? <DoneIcon fontSize="small" sx={{color: blue[600]}}/> : `${availability.Thursday}`}</Typography>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Friday</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${availability.Friday}` === 'Available' ? <DoneIcon fontSize="small" sx={{color: blue[600]}}/> : `${availability.Friday}`}</Typography>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Saturday</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${availability.Saturday}` === 'Available' ? <DoneIcon fontSize="small" sx={{color: blue[600]}}/> : `${availability.Saturday}`}</Typography>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Sunday</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${availability.Sunday}` === 'Available' ? <DoneIcon fontSize="small" sx={{color: blue[600]}}/> : `${availability.Sunday}`}</Typography>
                                </Form.Group>
                               
                            </Row>
                            <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[800], borderRadius: '5px', px: 2, py: '5px'}}>Availability Status</Typography>
                            <Row >
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Availability Status</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: orange[500], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${availability.Status} `}</Typography>
                                </Form.Group>
                               
                                
                            </Row>
                        </Form>
                    </Grid>

                    
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            {/* <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: 'blue', '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Changes</Button> */}
        </DialogActions>
    </Dialog>
    )
}

export default ViewAvailabilityDialog
