import { LINKEDUMBRELLAS_LIST_REQUEST, LINKEDUMBRELLAS_LIST_SUCCESS, LINKEDUMBRELLAS_LIST_FAIL} from "../Constants/linkedUmbrellaConstants";

const linkedUmbrellasListReducer = (state = {linkedUmbrellas: []}, action) => {

    switch (action.type)
    {
        case LINKEDUMBRELLAS_LIST_REQUEST:
            return {loading: true};
 
        case LINKEDUMBRELLAS_LIST_SUCCESS:
            console.log('Code is reaching the reducer and linkedUmbrellas data is:' + action.payload)
            return {
                loading: false,
                linkedUmbrellas: action.payload
            }
        case LINKEDUMBRELLAS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 


export {linkedUmbrellasListReducer}