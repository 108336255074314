export const SHIFTPREFERENCES_LIST_REQUEST = 'SHIFTPREFERENCES_LIST_REQUEST'
export const SHIFTPREFERENCES_LIST_SUCCESS = 'SHIFTPREFERENCES_LIST_SUCCESS'
export const SHIFTPREFERENCES_LIST_FAIL = 'SHIFTPREFERENCES_LIST_FAIL'


export const SHIFTPREFERENCE_CREATE_REQUEST = 'SHIFTPREFERENCE_CREATE_REQUEST'
export const SHIFTPREFERENCE_CREATE_SUCCESS = 'SHIFTPREFERENCE_CREATE_SUCCESS'
export const SHIFTPREFERENCE_CREATE_FAIL = 'SHIFTPREFERENCE_CREATE_FAIL'


export const SHIFTPREFERENCE_UPDATE_REQUEST = 'SHIFTPREFERENCE_UPDATE_REQUEST'
export const SHIFTPREFERENCE_UPDATE_SUCCESS = 'SHIFTPREFERENCE_UPDATE_SUCCESS'
export const SHIFTPREFERENCE_UPDATE_FAIL = 'SHIFTPREFERENCE_UPDATE_FAIL'

export const SHIFTPREFERENCE_DELETE_REQUEST = 'SHIFTPREFERENCE_DELETE_REQUEST'
export const SHIFTPREFERENCE_DELETE_SUCCESS = 'SHIFTPREFERENCE_DELETE_SUCCESS'
export const SHIFTPREFERENCE_DELETE_FAIL = 'SHIFTPREFERENCE_DELETE_FAIL'

export const VIEW_SHIFTPREFERENCE_REQUEST = 'VIEW_SHIFTPREFERENCE_REQUEST'
export const VIEW_SHIFTPREFERENCE_SUCCESS = 'VIEW_SHIFTPREFERENCE_SUCCESS'
export const VIEW_SHIFTPREFERENCE_FAIL = 'VIEW_SHIFTPREFERENCE_FAIL'