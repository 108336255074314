import { EXPENSETYPES_LIST_REQUEST, EXPENSETYPES_LIST_SUCCESS, EXPENSETYPES_LIST_FAIL, VIEW_EXPENSETYPE_FAIL, VIEW_EXPENSETYPE_REQUEST, VIEW_EXPENSETYPE_SUCCESS, EXPENSETYPE_CREATE_FAIL, EXPENSETYPE_UPDATE_REQUEST, EXPENSETYPE_UPDATE_FAIL, EXPENSETYPE_DELETE_REQUEST, EXPENSETYPE_DELETE_FAIL, EXPENSETYPE_CREATE_REQUEST, EXPENSETYPE_CREATE_SUCCESS, EXPENSETYPE_UPDATE_SUCCESS, EXPENSETYPE_DELETE_SUCCESS } from "../Constants/expenseTypeConstants";

const expenseTypesListReducer = (state = {expenseTypes: []}, action) => {

    switch (action.type)
    {
        case EXPENSETYPES_LIST_REQUEST:
            return {loading: true};
 
        case EXPENSETYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and expenseTypes data is:' + action.payload)
            return {
                loading: false,
                expenseTypes: action.payload
            }
        case EXPENSETYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const expenseTypeCreateReducer = (state = {expenseTypes: []}, action) => {

    switch (action.type) {

        case EXPENSETYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case EXPENSETYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                expenseTypes: [...state.expenseTypes, {Applicant_Id: 2, Name: value}]
            };

        case EXPENSETYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const expenseTypeViewReducer = (state= {expenseType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_EXPENSETYPE_REQUEST:
            return {loading: true};
        case VIEW_EXPENSETYPE_SUCCESS:
            return {
                    loading: false,
                    expenseType: action.payload
                }
        case VIEW_EXPENSETYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const expenseTypeUpdateReducer = (state = {expenseTypes: {}}, action) => {

    switch (action.type) {

        case EXPENSETYPE_UPDATE_REQUEST:
            return {
                
            }
        case EXPENSETYPE_UPDATE_SUCCESS:

            return {

            }
        case EXPENSETYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const expenseTypeDeleteReducer = (state = {expenseTypes: {}}, action) => {

    switch (action.type) {
        case EXPENSETYPE_DELETE_REQUEST:
            return {
                
            }
        case EXPENSETYPE_DELETE_SUCCESS:
            return {

            }
        case EXPENSETYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {expenseTypesListReducer, expenseTypeCreateReducer, expenseTypeViewReducer, expenseTypeUpdateReducer, expenseTypeDeleteReducer }