import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo, purple } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import TimesheetDialogs from '../../../Dialogs/CRM/Staff/TimesheetDialogs/TimesheetDialogs';

const TimesheetsTbl = ({timesheets}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    const  getTypeColor = (status) => {
        return status === 'Assign Work' ? purple[500] : status === 'Complete Invoices'?  lightGreen[500] : status === 'Complete Payroll'? lightBlue[500] :  status === 'Compliance'? pink[500] : status === 'Contact Client'? yellow[800]  : status === 'Contact Client'? indigo[400]  : status === 'New'? orange[500] : blueGrey[500]
    }
    const  getStatusColor = (status) => {
        return status === 'New Timesheet' ? pink[500] : status === 'In Progress'?  yellow[800] : status === 'Part Complete'? lightBlue[500] : status === 'Complete'? lightBlue[500] : status === 'Overdue'? blue[800] : status === 'Escalate'? purple[800] :  blueGrey[500]
    }

    const switchToViewTimesheet = (id) => {
        navigate('/timesheet/'+ id)
    } 

    const timesheetQuickView = (id) => {

        showDialog(true, 'view', id)
    }




    const columns = [
        { field: 'id', headerName: 'Id', type: 'number', minWidth: 100 },
        { field: 'Worker Id', headerName: 'Worker Id', minWidth: 100 , renderCell: (params) => <Typography onClick={()=> {timesheetQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`WRK-${params.row.TimesheetWorker}`}</Typography>},
        { field: 'Worker', headerName: 'Worker', minWidth: 150 , renderCell: (params) => <Typography onClick={()=> {timesheetQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Worker}`}</Typography>},
        { field: 'Starting', headerName: 'Starting', minWidth: 100 , renderCell: (params) => <Typography onClick={()=> {timesheetQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{params.row.Starting? `${params.row.Starting}` : 'undefined'}</Typography>},
        { field: 'Ending', headerName: 'Ending', minWidth: 100 , renderCell: (params) => <Typography onClick={()=> {timesheetQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{params.row.Ending? `${params.row.Ending}` : 'undefined'}</Typography>},
       
       
        { field: 'Status', headerName: 'Timesheet Status', minWidth: 150 , type: 'singleSelect', valueOptions: (['New Timesheet', 'Part Complete', 'Complete', 'In Progress', 'Overdue', 'Escalate']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
            )},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewTimesheet(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];

    return (
        
        <>
            { dialog.open  ?  <TimesheetDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={timesheets}
                        loading={!timesheets.length}
                    />
                </div>
            </div>
        </>
    )
}

export default TimesheetsTbl
