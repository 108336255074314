import { MONTHS_LIST_REQUEST, MONTHS_LIST_SUCCESS, MONTHS_LIST_FAIL, VIEW_MONTH_FAIL, VIEW_MONTH_REQUEST, VIEW_MONTH_SUCCESS, MONTH_CREATE_FAIL, MONTH_UPDATE_REQUEST, MONTH_UPDATE_FAIL, MONTH_DELETE_REQUEST, MONTH_DELETE_FAIL, MONTH_CREATE_REQUEST, MONTH_CREATE_SUCCESS, MONTH_UPDATE_SUCCESS, MONTH_DELETE_SUCCESS } from "../Constants/monthConstants";

const monthsListReducer = (state = {months: []}, action) => {

    switch (action.type)
    {
        case MONTHS_LIST_REQUEST:
            return {loading: true};
 
        case MONTHS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and months data is:' + action.payload)
            return {
                loading: false,
                months: action.payload
            }
        case MONTHS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const monthCreateReducer = (state = {months: []}, action) => {

    switch (action.type) {

        case MONTH_CREATE_REQUEST:
            return {
                loading: true
            }

        case MONTH_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                months: [...state.months, {Applicant_Id: 2, Name: value}]
            };

        case MONTH_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const monthViewReducer = (state= {month: {}}, action) => {
    switch(action.type)
    {
        case VIEW_MONTH_REQUEST:
            return {loading: true};
        case VIEW_MONTH_SUCCESS:
            return {
                    loading: false,
                    month: action.payload
                }
        case VIEW_MONTH_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const monthUpdateReducer = (state = {months: {}}, action) => {

    switch (action.type) {

        case MONTH_UPDATE_REQUEST:
            return {
                
            }
        case MONTH_UPDATE_SUCCESS:

            return {

            }
        case MONTH_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const monthDeleteReducer = (state = {months: {}}, action) => {

    switch (action.type) {
        case MONTH_DELETE_REQUEST:
            return {
                
            }
        case MONTH_DELETE_SUCCESS:
            return {

            }
        case MONTH_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {monthsListReducer, monthCreateReducer, monthViewReducer, monthUpdateReducer, monthDeleteReducer }