import { INCOMETYPES_LIST_REQUEST, INCOMETYPES_LIST_SUCCESS, INCOMETYPES_LIST_FAIL, VIEW_INCOMETYPE_FAIL, VIEW_INCOMETYPE_REQUEST, VIEW_INCOMETYPE_SUCCESS, INCOMETYPE_CREATE_FAIL, INCOMETYPE_UPDATE_REQUEST, INCOMETYPE_UPDATE_FAIL, INCOMETYPE_DELETE_REQUEST, INCOMETYPE_DELETE_FAIL, INCOMETYPE_CREATE_REQUEST, INCOMETYPE_CREATE_SUCCESS, INCOMETYPE_UPDATE_SUCCESS, INCOMETYPE_DELETE_SUCCESS } from "../Constants/incomeTypeConstants";

const incomeTypesListReducer = (state = {incomeTypes: []}, action) => {

    switch (action.type)
    {
        case INCOMETYPES_LIST_REQUEST:
            return {loading: true};
 
        case INCOMETYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and incomeTypes data is:' + action.payload)
            return {
                loading: false,
                incomeTypes: action.payload
            }
        case INCOMETYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const incomeTypeCreateReducer = (state = {incomeTypes: []}, action) => {

    switch (action.type) {

        case INCOMETYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case INCOMETYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                incomeTypes: [...state.incomeTypes, {Applicant_Id: 2, Name: value}]
            };

        case INCOMETYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const incomeTypeViewReducer = (state= {incomeType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_INCOMETYPE_REQUEST:
            return {loading: true};
        case VIEW_INCOMETYPE_SUCCESS:
            return {
                    loading: false,
                    incomeType: action.payload
                }
        case VIEW_INCOMETYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const incomeTypeUpdateReducer = (state = {incomeTypes: {}}, action) => {

    switch (action.type) {

        case INCOMETYPE_UPDATE_REQUEST:
            return {
                
            }
        case INCOMETYPE_UPDATE_SUCCESS:

            return {

            }
        case INCOMETYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const incomeTypeDeleteReducer = (state = {incomeTypes: {}}, action) => {

    switch (action.type) {
        case INCOMETYPE_DELETE_REQUEST:
            return {
                
            }
        case INCOMETYPE_DELETE_SUCCESS:
            return {

            }
        case INCOMETYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {incomeTypesListReducer, incomeTypeCreateReducer, incomeTypeViewReducer, incomeTypeUpdateReducer, incomeTypeDeleteReducer }