import React from 'react'
import { Box, Grid, List, ListItemText, Stack } from '@mui/material';
import { blue } from '@mui/material/colors';
import SummaryItemComponent from '../Tasks/SummaryItemComponent';

const HorizontalSummaryBox = ({primary, secondary, label1, label2, label3, count}) => {
    return (
        <>
           <Box justifyContent="space-between" alignItems="center" sx={{pl: 2, background: blue[50] , borderRadius: '5px', boxShadow: '1px 1px 1px #cfd8dc', mb: 2, width: '100%'}}>
            <Grid container justifyContent="space-evenly" alignItems="center" >
                <Grid item lg={3} sx={{pl: 0}}>
                    <List>
                        <ListItemText primary={primary} secondary={secondary}/>
                    </List>
                </Grid>
                <Grid item lg={9} sx={{pl: 0, bgcolor: ''}}>
                    <Stack direction="row" justifyContent="space-between" >
                        <SummaryItemComponent name={label1} count={count[0]} color={blue[700]} background={blue[50]}/>
                        <SummaryItemComponent name={label2} count={count[1]} color={blue[700]} background={blue[50]}/>
                        <SummaryItemComponent name={label3} count={count[2]} color={blue[700]} background={blue[50]}/>
                    </Stack>
                </Grid>
            </Grid>
        </Box> 
        </>
    )
}

export default HorizontalSummaryBox
