import { LIABILITYTYPES_LIST_REQUEST, LIABILITYTYPES_LIST_SUCCESS, LIABILITYTYPES_LIST_FAIL, VIEW_LIABILITYTYPE_FAIL, VIEW_LIABILITYTYPE_REQUEST, VIEW_LIABILITYTYPE_SUCCESS, LIABILITYTYPE_CREATE_FAIL, LIABILITYTYPE_UPDATE_REQUEST, LIABILITYTYPE_UPDATE_FAIL, LIABILITYTYPE_DELETE_REQUEST, LIABILITYTYPE_DELETE_FAIL, LIABILITYTYPE_CREATE_REQUEST, LIABILITYTYPE_CREATE_SUCCESS, LIABILITYTYPE_UPDATE_SUCCESS, LIABILITYTYPE_DELETE_SUCCESS } from "../Constants/liabilityTypeConstants";

const liabilityTypesListReducer = (state = {liabilityTypes: []}, action) => {

    switch (action.type)
    {
        case LIABILITYTYPES_LIST_REQUEST:
            return {loading: true};
 
        case LIABILITYTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and liabilityTypes data is:' + action.payload)
            return {
                loading: false,
                liabilityTypes: action.payload
            }
        case LIABILITYTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const liabilityTypeCreateReducer = (state = {liabilityTypes: []}, action) => {

    switch (action.type) {

        case LIABILITYTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case LIABILITYTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                liabilityTypes: [...state.liabilityTypes, {Applicant_Id: 2, Name: value}]
            };

        case LIABILITYTYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const liabilityTypeViewReducer = (state= {liabilityType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_LIABILITYTYPE_REQUEST:
            return {loading: true};
        case VIEW_LIABILITYTYPE_SUCCESS:
            return {
                    loading: false,
                    liabilityType: action.payload
                }
        case VIEW_LIABILITYTYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const liabilityTypeUpdateReducer = (state = {liabilityTypes: {}}, action) => {

    switch (action.type) {

        case LIABILITYTYPE_UPDATE_REQUEST:
            return {
                
            }
        case LIABILITYTYPE_UPDATE_SUCCESS:

            return {

            }
        case LIABILITYTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const liabilityTypeDeleteReducer = (state = {liabilityTypes: {}}, action) => {

    switch (action.type) {
        case LIABILITYTYPE_DELETE_REQUEST:
            return {
                
            }
        case LIABILITYTYPE_DELETE_SUCCESS:
            return {

            }
        case LIABILITYTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {liabilityTypesListReducer, liabilityTypeCreateReducer, liabilityTypeViewReducer, liabilityTypeUpdateReducer, liabilityTypeDeleteReducer }