import React from 'react'
import { Grid, Stack, Typography } from '@mui/material';
import { blue, blueGrey } from '@mui/material/colors';
import TimelapseIcon from '@mui/icons-material/Timelapse';

const FormBrief = ({name, icon, iconBrief, hours, minutes, date}) => {
    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" sx={{py: 1, pr: 2, background: 'rgba(227, 242, 253, .2)'}}>
                <Grid item>
                    <Grid container flexDirection="column" alignItems="center" >
                        <Stack direction="row" justifyContent="flex-end" alignItems="center">
                            {icon === 'time'? <TimelapseIcon sx={{ fontSize: 40,color: blue[600], mx: 2}}/> : <TimelapseIcon sx={{ fontSize: 40,color: blue[800], mx: 2}}/>}
                            <Typography variant="h3" sx={{fontSize: 25 ,fontWeight: 800, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blue[600]}}>{iconBrief}</Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ml: 4}}>
                            <Typography variant="h3" sx={{fontSize: 13 ,fontWeight: 800, fontFamily: 'Poppins-Regular', ml: 4, color:  blueGrey[400]}}>for</Typography>
                            <Typography variant="h3" sx={{fontSize: 15 ,fontWeight: 800, fontFamily: 'Poppins-Regular',pl: 1, color:  blueGrey[700]}}>{name}</Typography>

                        </Stack>
                    </Grid>
                </Grid>
                <Grid item>
                    {iconBrief === 'Timesheet'? 
                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
                        <Typography variant="h3" sx={{fontSize: 16 ,fontWeight: 800, fontFamily: 'Poppins-Bold', py: '', px: 0, color:  blue[600], mr: 1}}>Total Hours</Typography>
                    </Stack> : ''
                    }
                    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
                        <Typography variant="h3" sx={{fontSize: 40 ,fontWeight: 800, fontFamily: 'Poppins-Medium', py: '', px: 0, color:  blueGrey[800], mr: 1}}>{hours}h</Typography>
                        <Typography variant="h3" sx={{fontSize: 40 ,fontWeight: 800, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blueGrey[600]}}>{minutes}m</Typography>
                    </Stack>
                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Light', py: '', px: 0, color:  blue[300]}}>{date}</Typography>

                </Grid>
            </Grid>
        </>
    )
}

export default FormBrief
