export const PAYTYPES_LIST_REQUEST = 'PAYTYPES_LIST_REQUEST'
export const PAYTYPES_LIST_SUCCESS = 'PAYTYPES_LIST_SUCCESS'
export const PAYTYPES_LIST_FAIL = 'PAYTYPES_LIST_FAIL'


export const PAYTYPE_CREATE_REQUEST = 'PAYTYPE_CREATE_REQUEST'
export const PAYTYPE_CREATE_SUCCESS = 'PAYTYPE_CREATE_SUCCESS'
export const PAYTYPE_CREATE_FAIL = 'PAYTYPE_CREATE_FAIL'


export const PAYTYPE_UPDATE_REQUEST = 'PAYTYPE_UPDATE_REQUEST'
export const PAYTYPE_UPDATE_SUCCESS = 'PAYTYPE_UPDATE_SUCCESS'
export const PAYTYPE_UPDATE_FAIL = 'PAYTYPE_UPDATE_FAIL'

export const PAYTYPE_DELETE_REQUEST = 'PAYTYPE_DELETE_REQUEST'
export const PAYTYPE_DELETE_SUCCESS = 'PAYTYPE_DELETE_SUCCESS'
export const PAYTYPE_DELETE_FAIL = 'PAYTYPE_DELETE_FAIL'

export const VIEW_PAYTYPE_REQUEST = 'VIEW_PAYTYPE_REQUEST'
export const VIEW_PAYTYPE_SUCCESS = 'VIEW_PAYTYPE_SUCCESS'
export const VIEW_PAYTYPE_FAIL = 'VIEW_PAYTYPE_FAIL'