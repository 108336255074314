import React, {useState, useEffect} from 'react';
import { Link as BreadcrumbLink, CircularProgress } from '@mui/material';
import { Grid, Divider} from '@mui/material';
import useDialog from '../../../Hooks/useDialog';
import { lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux'; 
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import Crumbs from '../../../Components/Utilities/Crumbs';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import { getTimesheetsList } from '../../../Redux/Actions/TimesheetActions';
import ShiftDialogs from '../../../Components/Dialogs/CRM/Staff/ShiftDialogs/ShiftDialogs';
import TimesheetsTbl from '../../../Components/Table/CRM/Staff/TimesheetsTbl';
 
const Timesheets = () => { 

    //FETCH DATA FROM SERVER
    
    const dispatch = useDispatch();
    const {loading, timesheets }  = useSelector(state => state.timesheets_List) //receiving state object
    const [list, setList] = useState([])
    
    useEffect(()=> {
        dispatch(getTimesheetsList());
    }, [])

    useEffect(() => {
        if(timesheets)
        {
            setList([...timesheets])
        }
     }, [timesheets])
     
    //TABLE DIALOG FUNCTIONALITY
    
        
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)
    
    //----------------------------------------------------------------------------------------------------------------------------------
     
    return (
        <>
            <ScreenHeader title="Timesheets" subtitle="Manage timesheets from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>

            { dialog.open ? <ShiftDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            { alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }

            <Crumbs root="CRM" middle="Staff" end="Timesheets" main/>

            <Divider sx={{color: lightBlue[800]}}/>
            
            {loading? <HorizontalLoading/> : ''}
        
                
            <HorizontalSummaryBox primary="Timesheets Brief" secondary="Overall Timesheet count" label1="All Timesheets" label2="Second Label" label3="Third Label" count={[2,11,6]}/>
            
                
            <AboveTableHeader title="Timesheets List"/>
            <Grid container>
                <TimesheetsTbl timesheets={list}/>
            </Grid>


     
    </>
    )
}

export default Timesheets
