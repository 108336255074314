export const BOOKINGSTATUSES_LIST_REQUEST = 'BOOKINGSTATUSES_LIST_REQUEST';
export const BOOKINGSTATUSES_LIST_SUCCESS = 'BOOKINGSTATUSES_LIST_SUCCESS';
export const BOOKINGSTATUSES_LIST_FAIL = 'BOOKINGSTATUSES_LIST_FAIL'; 

export const BOOKINGSTATUS_CREATE_REQUEST = 'BOOKINGSTATUS_CREATE_REQUEST'
export const BOOKINGSTATUS_CREATE_SUCCESS = 'BOOKINGSTATUS_CREATE_SUCCESS'
export const BOOKINGSTATUS_CREATE_FAIL = 'BOOKINGSTATUS_CREATE_FAIL'


export const BOOKINGSTATUS_UPDATE_REQUEST = 'BOOKINGSTATUS_UPDATE_REQUEST'
export const BOOKINGSTATUS_UPDATE_SUCCESS = 'BOOKINGSTATUS_UPDATE_SUCCESS'
export const BOOKINGSTATUS_UPDATE_FAIL = 'BOOKINGSTATUS_UPDATE_FAIL'

export const BOOKINGSTATUS_DELETE_REQUEST = 'BOOKINGSTATUS_DELETE_REQUEST'
export const BOOKINGSTATUS_DELETE_SUCCESS = 'BOOKINGSTATUS_DELETE_SUCCESS'
export const BOOKINGSTATUS_DELETE_FAIL = 'BOOKINGSTATUS_DELETE_FAIL'

export const VIEW_BOOKINGSTATUS_REQUEST = 'VIEW_BOOKINGSTATUS_REQUEST'
export const VIEW_BOOKINGSTATUS_SUCCESS = 'VIEW_BOOKINGSTATUS_SUCCESS'
export const VIEW_BOOKINGSTATUS_FAIL = 'BOOKINGSTATUS_FAIL'