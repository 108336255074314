import { RATESTATUSES_LIST_REQUEST, RATESTATUSES_LIST_SUCCESS, RATESTATUSES_LIST_FAIL, VIEW_RATESTATUS_FAIL, VIEW_RATESTATUS_REQUEST, VIEW_RATESTATUS_SUCCESS, RATESTATUS_CREATE_REQUEST, RATESTATUS_CREATE_SUCCESS, RATESTATUS_CREATE_FAIL, RATESTATUS_UPDATE_FAIL, RATESTATUS_UPDATE_REQUEST, RATESTATUS_UPDATE_SUCCESS } from '../Constants/rateStatusConstants';
import api from '../../config/apiConfig';


const getRateStatusesList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: RATESTATUSES_LIST_REQUEST});
        const {data} = await api.get('/ratestatuses');
        // console.log('RateStatus data is:' + data[0]['RateStatus_Name'])
        dispatch({type: RATESTATUSES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: RATESTATUSES_LIST_FAIL, payload: error})
    }
} 

const submitRateStatus = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: RATESTATUS_CREATE_REQUEST});
        
        const {data} = await api.post(`/ratestatuses/`, values);
        console.log('RateStatus data is:' + data)
        dispatch({type: RATESTATUS_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: RATESTATUS_CREATE_FAIL, payload: error})
    }
} 

const getRateStatus = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_RATESTATUS_REQUEST});
        
        const {data} = await api.get(`/ratestatuses/ ${id}`);
        console.log('RateStatus data is:' + data)
        dispatch({type: VIEW_RATESTATUS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_RATESTATUS_FAIL, payload: error})
    }
}

const editRateStatus = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: RATESTATUS_UPDATE_REQUEST});
        
        const {data} = await api.put(`/ratestatuses/`, values);
        console.log('RateStatus data is:' + data)
        dispatch({type: RATESTATUS_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: RATESTATUS_UPDATE_FAIL, payload: error})
    }
}



export {getRateStatusesList, submitRateStatus, getRateStatus, editRateStatus }