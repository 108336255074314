import { ACCSANDINCIDENTS_LIST_REQUEST, ACCSANDINCIDENTS_LIST_SUCCESS, ACCSANDINCIDENTS_LIST_FAIL, VIEW_ACCSANDINCIDENT_FAIL, VIEW_ACCSANDINCIDENT_REQUEST, VIEW_ACCSANDINCIDENT_SUCCESS, ACCSANDINCIDENT_CREATE_FAIL, ACCSANDINCIDENT_UPDATE_REQUEST, ACCSANDINCIDENT_UPDATE_FAIL, ACCSANDINCIDENT_DELETE_REQUEST, ACCSANDINCIDENT_DELETE_FAIL, ACCSANDINCIDENT_CREATE_REQUEST, ACCSANDINCIDENT_CREATE_SUCCESS, ACCSANDINCIDENT_UPDATE_SUCCESS, ACCSANDINCIDENT_DELETE_SUCCESS } from "../Constants/accsAndIncidentConstants";

const accsAndIncidentsListReducer = (state = {accsAndIncidents: []}, action) => {

    switch (action.type)
    {
        case ACCSANDINCIDENTS_LIST_REQUEST:
            return {loading: true};
 
        case ACCSANDINCIDENTS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and accsAndIncidents data is:' + action.payload)
            return {
                loading: false,
                accsAndIncidents: action.payload
            }
        case ACCSANDINCIDENTS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const accsAndIncidentCreateReducer = (state = {accsAndIncidents: []}, action) => {

    switch (action.type) {

        case ACCSANDINCIDENT_CREATE_REQUEST:
            return {
                loading: true
            }

        case ACCSANDINCIDENT_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                accsAndIncidents: [...state.accsAndIncidents, {Applicant_Id: 2, Name: value}]
            };

        case ACCSANDINCIDENT_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const accsAndIncidentViewReducer = (state= {accsAndIncident: {}}, action) => {
    switch(action.type)
    {
        case VIEW_ACCSANDINCIDENT_REQUEST:
            return {loading: true};
        case VIEW_ACCSANDINCIDENT_SUCCESS:
            return {
                    loading: false,
                    accsAndIncident: action.payload
                }
        case VIEW_ACCSANDINCIDENT_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const accsAndIncidentUpdateReducer = (state = {accsAndIncidents: {}}, action) => {

    switch (action.type) {

        case ACCSANDINCIDENT_UPDATE_REQUEST:
            return {
                
            }
        case ACCSANDINCIDENT_UPDATE_SUCCESS:

            return {

            }
        case ACCSANDINCIDENT_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const accsAndIncidentDeleteReducer = (state = {accsAndIncidents: {}}, action) => {

    switch (action.type) {
        case ACCSANDINCIDENT_DELETE_REQUEST:
            return {
                
            }
        case ACCSANDINCIDENT_DELETE_SUCCESS:
            return {

            }
        case ACCSANDINCIDENT_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {accsAndIncidentsListReducer, accsAndIncidentCreateReducer, accsAndIncidentViewReducer, accsAndIncidentUpdateReducer, accsAndIncidentDeleteReducer }