import React, { useEffect, useState } from 'react';
import DataTable from '../../DataTable';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import ClientDialogs from '../../../Dialogs/CRM/Sales/ClientDialogs/ClientDialogs';

const ClientsTbl = ({clients}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const getIndustryColor = (jr) => {
        return jr === 'Haulage' ? blue[500] : jr === 'Food'?  pink[500] : jr === 'Courier'? orange[500] : blueGrey[500]
    }
    const getStatusColor = (jr) => {
        return jr === 'New' ? blue[500] : jr === 'Credit Check Pass'?  pink[500] : jr === 'Credit Check Fail'? orange[500] : jr === 'Black List'? lightGreen[500] : blueGrey[500]
    }

    const switchToViewClient = (id) => {
        navigate('/client/'+ id)
    } 

    const clientQuickView = (id) => {

        showDialog(true, 'view', id)
      }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Name', headerName: 'Name', width: 150, renderCell: (params) => <Typography onClick={()=> {clientQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Name}`}</Typography>},
        { field: 'CompanyNumber', headerName: 'CompanyNumber'},
        { field: 'VATNumber', headerName: 'VATNumber'},
        { field: 'Add1', headerName: 'Add1', width: 150},
        { field: 'Add2', headerName: 'Add2', width: 150},
        { field: 'Postcode', headerName: 'Postcode'},
        { field: 'TownCity', headerName: 'TownCity'},
        { field: 'SetupType', headerName: 'SetupType'},
        { field: 'Industry', headerName: 'Industry', minWidth: 150 , type: 'singleSelect', valueOptions: (['Haulage', 'Food', 'Courier']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getIndustryColor(params.row.Industry), borderRadius: '30px', px: '10px'}}>{params.row.Industry}</Typography>
        )},
        { field: 'Status', headerName: 'Status', minWidth: 150 , type: 'singleSelect', valueOptions: (['New', 'Credit Check Pass', 'Credit Check Fail', 'Black List', 'Live Account']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
    
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewClient(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ];



    return (
        <>
        { dialog.open  ?  <ClientDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }

            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={clients}
                        loading={!clients.length}
                    />
                </div>
            </div>
        </>
       
    )
}

export default ClientsTbl
