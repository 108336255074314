import React, {useState} from 'react';
import AddBalanceSheetDialog from './AddBalanceSheetDialog';
import ViewBalanceSheetDialog from './ViewBalanceSheetDialog';
import DeleteBalanceSheetDialog from './DeleteBalanceSheetDialog';



const BalanceSheetDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <> 
        {
            type === "create"?

            <AddBalanceSheetDialog open={open} onClose={onClose} handleSubmit={handleSubmit}  fullWidth />
            
            : type === "view"?

            <ViewBalanceSheetDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />

            : type === "delete"?

            <DeleteBalanceSheetDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />
            // <ViewBalanceSheetDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default BalanceSheetDialogs
