export const APPLICANTPROFILE_LIST_REQUEST = 'APPLICANTPROFILE_LIST_REQUEST'
export const APPLICANTPROFILE_LIST_SUCCESS = 'APPLICANTPROFILE_LIST_SUCCESS'
export const APPLICANTPROFILE_LIST_FAIL = 'APPLICANTPROFILE_LIST_FAIL'



export const APPLICANTPROFILE_CREATE_REQUEST = 'APPLICANTPROFILE_CREATE_REQUEST'
export const APPLICANTPROFILE_CREATE_SUCCESS = 'APPLICANTPROFILE_CREATE_SUCCESS'
export const APPLICANTPROFILE_CREATE_FAIL = 'APPLICANTPROFILE_CREATE_FAIL'


export const VIEW_APPLICANTPROFILE_REQUEST = 'VIEW_APPLICANTPROFILE_REQUEST'
export const VIEW_APPLICANTPROFILE_SUCCESS = 'VIEW_APPLICANTPROFILE_SUCCESS'
export const VIEW_APPLICANTPROFILE_FAIL = 'VIEW_APPLICANTPROFILE_FAIL'


export const APPLICANTPROFILE_UPDATE_REQUEST = 'APPLICANTPROFILE_UPDATE_REQUEST'
export const APPLICANTPROFILE_UPDATE_SUCCESS = 'APPLICANTPROFILE_UPDATE_SUCCESS'
export const APPLICANTPROFILE_UPDATE_FAIL = 'APPLICANTPROFILE_UPDATE_FAIL'


export const APPLICANTPROFILE_DELETE_REQUEST = 'APPLICANTPROFILE_DELETE_REQUEST'
export const APPLICANTPROFILE_DELETE_SUCCESS = 'APPLICANTPROFILE_DELETE_SUCCESS'
export const APPLICANTPROFILE_DELETE_FAIL = 'APPLICANTPROFILE_DELETE_FAIL'



