import { DEPARTMENTS_LIST_REQUEST, DEPARTMENTS_LIST_SUCCESS, DEPARTMENTS_LIST_FAIL, VIEW_DEPARTMENT_FAIL, VIEW_DEPARTMENT_REQUEST, VIEW_DEPARTMENT_SUCCESS, DEPARTMENT_CREATE_REQUEST, DEPARTMENT_CREATE_SUCCESS, DEPARTMENT_CREATE_FAIL, DEPARTMENT_UPDATE_FAIL, DEPARTMENT_UPDATE_REQUEST, DEPARTMENT_UPDATE_SUCCESS } from '../Constants/departmentConstants';
import api from '../../config/apiConfig';


const getDepartmentsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: DEPARTMENTS_LIST_REQUEST});
        const {data} = await api.get('/departments');
        // console.log('Departments data is:' + data[0]['Department_Name'])
        dispatch({type: DEPARTMENTS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: DEPARTMENTS_LIST_FAIL, payload: error})
    }
} 

const submitDepartment = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: DEPARTMENT_CREATE_REQUEST});
        
        const {data} = await api.post(`/departments/`, values);
        console.log('Department data is:' + data)
        dispatch({type: DEPARTMENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: DEPARTMENT_CREATE_FAIL, payload: error})
    }
} 

const getDepartment = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_DEPARTMENT_REQUEST});
        
        const {data} = await api.get(`/departments/ ${id}`);
        console.log('Department data is:' + data)
        dispatch({type: VIEW_DEPARTMENT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_DEPARTMENT_FAIL, payload: error})
    }
}

const editDepartment = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: DEPARTMENT_UPDATE_REQUEST});
        
        const {data} = await api.put(`/departments/`, values);
        console.log('Department data is:' + data)
        dispatch({type: DEPARTMENT_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: DEPARTMENT_UPDATE_FAIL, payload: error})
    }
}



export {getDepartmentsList, submitDepartment, getDepartment, editDepartment }