import React from 'react';
import {Route, Routes, Link} from 'react-router-dom';
import DashboardScreen from '../Screens/CRM/Dashboard/DashboardScreen';
import FinanceScreen from '../Screens/Finance/FinanceScreen';
import SettingsScreen from '../Screens/Settings/SettingsScreen';
// import AssessmentsCalendar from '../Screens/Calendars/AssessmentsCalendar';
import Applicants from '../Screens/CRM/Recruitment/Applicants';
import Assessments from '../Screens/CRM/Recruitment/Assessments';
// import DriversCalendar from '../Screens/Calendars/DriversCalendar'; 
// import ConstructionCalendar from '../Screens/Calendars/ConstructionCalendar';
// import IndustrialCalendar from '../Screens/Calendars/IndustrialCalendar';
// import EmployeeDocuments from '../Screens/Documents/EmployeeDocuments';
import Sketch from '../Screens/Sketch/Sketch';
import ViewAssessment from '../Screens/CRM/Recruitment/ViewAssessment';
import Staff from '../Screens/CRM/Staff/Staff';
import Workers from '../Screens/CRM/Staff/Workers';
import Shifts from '../Screens/CRM/Staff/Shifts';
import Registration from '../Screens/CRM/Recruitment/Registration';
import Availability from '../Screens/CRM/Staff/Availability';
import ViewAvailability from '../Screens/CRM/Staff//ViewAvailability';
import CheckIns from '../Screens/CRM/Staff/CheckIns';
import ViewApplicant from '../Screens/CRM/Recruitment/ViewApplicant';
import ViewTask from '../Screens/CRM/Tasks/ViewTask';
import Timesheets from '../Screens/CRM/Staff/Timesheets';
import Payments from '../Screens/CRM/Staff/Payments';
import Clients from '../Screens/CRM/Sales/Clients';
import Contacts from '../Screens/CRM/Sales/Contacts';
import Prospects from '../Screens/CRM/Sales/Prospects';
import Documents from '../Screens/CRM/Staff/Documents';
import ViewTimesheet from '../Screens/CRM/Staff/ViewTimesheet';
import ChecksAndLimits from '../Screens/CRM/Sales/ChecksAndLimits';
import Requests from '../Screens/CRM/Operations/Requests';
import AccidentsAndIncidents from '../Screens/CRM/Operations/AccidentsAndIncidents';
import Opportunities from '../Screens/CRM/Operations/Opportunities';
import Bookings from '../Screens/CRM/Operations/Bookings';
import Queries from '../Screens/CRM/Operations/Queries';
import BudgetTracker from '../Screens/CRM/Operations/BudgetTracker';
import Tasks from '../Screens/CRM/Tasks/Tasks';
import Divisions from '../Screens/CRM/Operations/Divisions';
import Profiles from '../Screens/CRM/Staff/Profiles';
import AdminScreen from '../Screens/CRM/Admin/AdminScreen';
import RateCards from '../Screens/CRM/Management/RateCards';
import ViewShift from '../Screens/CRM/Staff/ViewShift';
import ViewWorker from '../Screens/CRM/Staff/ViewWorker';
import ViewClient from '../Screens/CRM/Sales/ViewClient';
import ViewContact from '../Screens/CRM/Sales/ViewContact';
import ViewProfile from '../Screens/CRM/Staff/ViewProfile';
import ViewPayment from '../Screens/CRM/Staff/ViewPayment';
import ViewStaff from '../Screens/CRM/Staff/ViewStaff';
import ViewCheckIn from '../Screens/CRM/Staff/ViewCheckIn';
import ViewProspect from '../Screens/CRM/Sales/ViewProspect';
import ViewDivision from '../Screens/CRM/Operations/ViewDivision';
import ViewCsAndLs from '../Screens/CRM/Sales/ViewCsAndLs';
import TestingKanban from '../Screens/CRM/Tasks/TestingKanban';
import OutstandingTasks from '../Screens/CRM/Tasks/OutstandingTasks';
import Visits from '../Screens/CRM/Management/Visits';
import ClientSites from '../Screens/CRM/Management/ClientSites';
import CreditChecks from '../Screens/CRM/Management/CreditChecks';
import Test from '../Screens/Other/Test';
import ViewTest from '../Screens/Other/ViewTest';
import WorkingWeek from '../Screens/CRM/System/WorkingWeek';
import Holidays from '../Screens/CRM/System/Holidays';
import WOM from '../Screens/CRM/System/WOM';
import Attendance from '../Screens/CRM/System/Attendance';
import Invoices from '../Screens/Finance/Invoices/Invoices';
import ViewInvoice from '../Screens/CRM/Staff/ViewInvoice';
import ChartOfAccounts from '../Screens/Finance/Reconciliation/ChartOfAccounts';
import Reconciles from '../Screens/Finance/Reconciliation/Reconciles';
import Transactions from '../Screens/Finance/Reconciliation/Transactions';
import VATReturns from '../Screens/Finance/Reconciliation/VATReturns';
import ProfitAndLoss from '../Screens/Finance/Reconciliation/Profit&Loss';
import BalanceSheets from '../Screens/Finance/Reconciliation/BalanceSheets';
import Customers from '../Screens/Finance/Reconciliation/Customers';
import Suppliers from '../Screens/Finance/Reconciliation/Suppliers';
import UploadFiles from '../Screens/Finance/Reconciliation/UploadFiles';
import PayerPayees from '../Screens/Finance/Reconciliation/PayerPayees';
import Users from '../Screens/CRM/Admin/Users';
import Roles from '../Screens/CRM/Admin/Roles';

const AppRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<DashboardScreen/>}/>
                {/* <Route path="/recruitment" element={<RecruitmentScreen/>}/> */}
                <Route path="/finance" element={<FinanceScreen/>}/>
                <Route path="/settings" element={<SettingsScreen/>}/>
                {/* Admin*/}
                <Route path="/admin" element={<AdminScreen/>}/>
                <Route path="/users" element={<Users/>}/>
                <Route path="/roles" element={<Roles/>}/>
                <Route path="/sketch" element={<Sketch/>}/>
                {/* Documents */}
                {/* <Route path="/employees" element={<EmployeeDocuments/>}/> */}
                {/* Tasks */}
                {/* <Route path="/tasks" element={<TasksScreen/>}/> */}
                <Route path="/tasks" element={<Tasks/>}/>
                <Route path="/outstandingtasks" element={<OutstandingTasks/>}/>
                <Route path="/task/:id" element={<ViewTask/>}/>
                <Route path="/testingkanban" element={<TestingKanban/>}/>
                {/* Recruitments */}
                <Route path="/applicants" element={<Applicants/>}/>
                <Route path="/applicant/:id" element={<ViewApplicant/>}/>
                <Route path="/assessments" element={<Assessments/>}/>
                <Route path="/assessment/:id" element={<ViewAssessment/>}/>
                <Route path="/registration" element={<Registration/>}/>
                {/* Finance */}
                <Route path="/chartofaccounts" element={<ChartOfAccounts/>}/>
                <Route path="/uploadfiles" element={<UploadFiles/>}/>
                <Route path="/reconciles" element={<Reconciles/>}/>
                <Route path="/transactions" element={<Transactions/>}/>
                <Route path="/vatreturns" element={<VATReturns/>}/>
                <Route path="/profitandloss" element={<ProfitAndLoss/>}/>
                <Route path="/balancesheets" element={<BalanceSheets/>}/>
                <Route path="/customers" element={<Customers/>}/>
                <Route path="/suppliers" element={<Suppliers/>}/>
                <Route path="/payerpayees" element={<PayerPayees/>}/>
                {/* Staff */}
                <Route path="/staff" element={<Staff/>}/>
                <Route path="/staff/:id" element={<ViewStaff/>}/>
                <Route path="/workers" element={<Workers/>}/>
                <Route path="/worker/:id" element={<ViewWorker/>}/>
                <Route path="/shifts" element={<Shifts/>}/>
                <Route path="/shift/:id" element={<ViewShift/>}/>
                <Route path="/availability" element={<Availability/>}/>
                <Route path="/availability/:id" element={<ViewAvailability/>}/>
                <Route path="/checkins" element={<CheckIns/>}/>
                <Route path="/checkin/:id" element={<ViewCheckIn/>}/>
                <Route path="/timesheets" element={<Timesheets/>}/>
                <Route path="/timesheet/:id" element={<ViewTimesheet/>}/>
                {/* Sales */}
                <Route path="/clients" element={<Clients/>}/>
                <Route path="/client/:id" element={<ViewClient/>}/>
                <Route path="/contacts" element={<Contacts/>}/>
                <Route path="/contact/:id" element={<ViewContact/>}/>
                <Route path="/prospects" element={<Prospects/>}/>
                <Route path="/prospect/:id" element={<ViewProspect/>}/>
                <Route path="/checksandlimits" element={<ChecksAndLimits/>}/>
                <Route path="/checksandlimits/:id" element={<ViewCsAndLs/>}/>
                {/* Staff */}
                <Route path="/documents" element={<Documents/>}/>
                <Route path="/payments" element={<Payments/>}/>
                <Route path="/payment/:id" element={<ViewPayment/>}/>
                <Route path="/invoices" element={<Invoices/>}/>
                <Route path="/invoice/:id" element={<ViewInvoice/>}/>
                <Route path="/profiles" element={<Profiles/>}/>
                <Route path="/profile/:id" element={<ViewProfile/>}/>
                {/* Operations */}
                <Route path="/requests" element={<Requests/>}/>
                <Route path="/accidentsandincidents" element={<AccidentsAndIncidents/>}/>
                <Route path="/opportunities" element={<Opportunities/>}/>
                <Route path="/bookings" element={<Bookings/>}/>
                <Route path="/queries" element={<Queries/>}/>
                <Route path="/budgettracker" element={<BudgetTracker/>}/>
                {/* Management */}
                <Route path="/ratecards" element={<RateCards/>}/>
                <Route path="/lastservicevisits" element={<Visits/>}/>
                <Route path="/clientsites" element={<ClientSites/>}/>
                <Route path="/creditchecks" element={<CreditChecks/>}/>
                {/* System */}
                <Route path="/workingweeks" element={<WorkingWeek/>}/>
                <Route path="/workerofthemonth" element={<WOM/>}/>
                <Route path="/holidays" element={<Holidays/>}/>
                <Route path="/timeandattendance" element={<Attendance/>}/>
          
                {/* Divisions */}
                <Route path="/divisions" element={<Divisions/>}/>
                <Route path="/division/:id" element={<ViewDivision/>}/>

                {/* Sketch */}
                <Route path="/test" element={<Test/>}/>
                <Route path="/test/:id" element={<ViewTest/>}/>

          </Routes>
        </>
    )
}

export default AppRoutes
