import { APPLICANTS_LIST_REQUEST, APPLICANTS_LIST_SUCCESS, APPLICANTS_LIST_FAIL, VIEW_APPLICANT_FAIL, VIEW_APPLICANT_REQUEST, VIEW_APPLICANT_SUCCESS, APPLICANT_CREATE_FAIL, APPLICANT_UPDATE_REQUEST, APPLICANT_UPDATE_FAIL, APPLICANT_DELETE_REQUEST, APPLICANT_DELETE_FAIL, APPLICANT_CREATE_REQUEST, APPLICANT_CREATE_SUCCESS, APPLICANT_UPDATE_SUCCESS, APPLICANT_DELETE_SUCCESS } from "../Constants/applicantConstants";


const initialState = {
    applicants: [],
    applicant: {},
    loading: '',
}
const applicantsListReducer = (state = initialState, action) => {

    switch (action.type)
    {
        case APPLICANTS_LIST_REQUEST:
            return {loading: true};
 
        case APPLICANTS_LIST_SUCCESS:
            return {
                loading: false,
                applicants: [...action.payload]
            }
        case APPLICANTS_LIST_FAIL:
            return {
                error: action.payload
            }

        case APPLICANT_CREATE_SUCCESS: 

        let randomNumber = Math.floor(Math.random() * (Math.random()*43 + 12))
        const {first_name,surname, email, application_date, mobile, home_phone, divisions, jobroles, rating, applicantprospects, applicantstatuses} = action.payload;
        console.log('CREATING APP WITH: ' + action.payload.home_phone)
        const newApplicant = {id: randomNumber, First_Name: first_name, Surname: surname, Home_Phone: home_phone, Applicant: `${first_name} ${surname}`, Email: email, Application_Date: application_date, Mobile: mobile, Division: divisions, Job_Role: jobroles, Rating: rating, Prospect: applicantprospects, Status: applicantstatuses};
        
            return {
                ...state,
                loading: false,
                applicants: [...state.applicants, newApplicant]
            };

        case APPLICANT_CREATE_FAIL:
            return {
                error: action.payload
            }
            
        case VIEW_APPLICANT_REQUEST:
        return {loading: true};

        case VIEW_APPLICANT_SUCCESS:
            return {
                    loading: false,
                    applicant: action.payload
                }
        case VIEW_APPLICANT_FAIL:
            return {
                error: action.payload
            }

        //UPDATE APPLICANT
        case APPLICANT_UPDATE_REQUEST:
        return {
            
        }
        case APPLICANT_UPDATE_SUCCESS:

            return {

            }
        case APPLICANT_UPDATE_FAIL:
            return {

            }
        default:
            return state
    }

    
}
       
const applicantCreateReducer = (state = initialState, action) => {

    
}

const applicantViewReducer = (state= {applicant: {}}, action) => {
    switch(action.type)
    {
        case VIEW_APPLICANT_REQUEST:
            return {loading: true};
        case VIEW_APPLICANT_SUCCESS:
            return {
                    loading: false,
                    applicant: action.payload
                }
        case VIEW_APPLICANT_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const applicantUpdateReducer = (state = {applicants: {}}, action) => {

    switch (action.type) {

        case APPLICANT_UPDATE_REQUEST:
            return {
                
            }
        case APPLICANT_UPDATE_SUCCESS:

            return {

            }
        case APPLICANT_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const applicantDeleteReducer = (state = {applicants: {}}, action) => {

    switch (action.type) {
        case APPLICANT_DELETE_REQUEST:
            return {
                
            }
        case APPLICANT_DELETE_SUCCESS:
            return {

            }
        case APPLICANT_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {applicantsListReducer, applicantCreateReducer, applicantViewReducer, applicantUpdateReducer, applicantDeleteReducer }