import React, { useState, useEffect } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { ListItemText, ListItemButton, ListItemIcon, List, Collapse, } from '@mui/material';
import DropdownMenu from './DropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setL1Link_ToActive, setL2Link_ToActive, setL3Link_ToActive } from '../../Redux/Actions/SitemapActions';
import { blueGrey, cyan, teal, blue } from '@mui/material/colors';
import { useNavigate } from 'react-router';


const NavItem = ({item, drawerStatus}) => {
    
    //initialising
    const navigate = useNavigate(); 

    const [open, setOpen] = useState(false);
    const [dropdown, setDropdown] = useState([]);
    const [active, setActive] = useState();
     
 
    const dispatch = useDispatch();
    const firstLevelNavActiveState = useSelector(state => state.firstLevelNavActiveState);
    const {active_link} = firstLevelNavActiveState;

 
    const handleClick = (item) => {

        dispatch(setL2Link_ToActive(''));
        dispatch(setL3Link_ToActive(''));
        
        if(drawerStatus == true)
        {
            setActive(item.id)
            if(item.subMenu)
            {
                
                setOpen(!open);
                setDropdown([...item.subMenu]);
            }
            if(item.title == 'Dashboard')
            {
                
                dispatch(setL1Link_ToActive(item.id));
                navigate(item.path)
            }
        }
        else
        {
            console.log('the drawer is closed')
            setOpen(false);
            dispatch(setL1Link_ToActive(item.id));
            
        }
        
    }

    useEffect(()=> {
//        dispatch(setL1Link_ToActive(1))
    }, [])


    return (
        <>
        <List>
            <ListItemButton
                component="div"
                autoFocus={true}
                onClick = {()=> handleClick(item)}
                selected = {item.id === active_link}
                className={item.id === active_link? 'L1-active' : 'L1'}
                // disableTypography
                //blue color changed was blue[700]
                sx={{ ml: drawerStatus? '' : '', width: drawerStatus? 230 : 210}}
            >
                <ListItemIcon sx={{color: item.id === active_link? 'white' : blueGrey[500], background: '', maxWidth: "", m: 0}}>{item.icon}</ListItemIcon>
                {/* <ListItemIcon sx={{color: item.id === active_link? blueGrey[700] : ''}}>{item.icon}</ListItemIcon> */}
                <ListItemText sx={{mr: 0, pr: 0, background: ''}}  primary={item.title} className={item.id === active_link? 'L1-text-active' : 'L1-text'} primaryTypographyProps={{pr: 0, m: 0, background: 'pink',fontFamily: 'Poppins' ,fontWeight: item.id === active_link? 700 : '', color: item.id === active_link? 'white' : blueGrey[700]}}/>
                {
                    item.subMenu && open?
                     <ExpandMore fontSize="small" sx={{ml: 2, color: item.id === active_link? 'white' : blueGrey[600]}}/>
                    : item.subMenu?
                     <ExpandLess fontSize="small" sx={{ml: 2, color: item.id === active_link? 'white' : blueGrey[600]}}/>
                    : null
                }
                {/* {
                    item.title === "Dashboard"?
                    <Stack flexDirection="row" alignItems="center" sx={{ color: 'white', mr: 2}}>
                        <CircleIcon fontSize="small" sx={{ fontSize: '.4rem', color: cyan[600]}}/>
                        <Typography variant="body2" sx={{pl: 1, color: cyan[300] }}>New</Typography>
                    </Stack>
                    : ''
                } */}
                
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {
                    dropdown && open?
                        <DropdownMenu dropdown={dropdown} drawerStatus={drawerStatus}/>
                        : ''
                }

            </Collapse> 
        </List>
        </>
    )
}

export default NavItem
