export const VISITTYPES_LIST_REQUEST = 'VISITTYPES_LIST_REQUEST'
export const VISITTYPES_LIST_SUCCESS = 'VISITTYPES_LIST_SUCCESS'
export const VISITTYPES_LIST_FAIL = 'VISITTYPES_LIST_FAIL'


export const VISITTYPE_CREATE_REQUEST = 'VISITTYPE_CREATE_REQUEST'
export const VISITTYPE_CREATE_SUCCESS = 'VISITTYPE_CREATE_SUCCESS'
export const VISITTYPE_CREATE_FAIL = 'VISITTYPE_CREATE_FAIL'


export const VISITTYPE_UPDATE_REQUEST = 'VISITTYPE_UPDATE_REQUEST'
export const VISITTYPE_UPDATE_SUCCESS = 'VISITTYPE_UPDATE_SUCCESS'
export const VISITTYPE_UPDATE_FAIL = 'VISITTYPE_UPDATE_FAIL'

export const VISITTYPE_DELETE_REQUEST = 'VISITTYPE_DELETE_REQUEST'
export const VISITTYPE_DELETE_SUCCESS = 'VISITTYPE_DELETE_SUCCESS'
export const VISITTYPE_DELETE_FAIL = 'VISITTYPE_DELETE_FAIL'

export const VIEW_VISITTYPE_REQUEST = 'VIEW_VISITTYPE_REQUEST'
export const VIEW_VISITTYPE_SUCCESS = 'VIEW_VISITTYPE_SUCCESS'
export const VIEW_VISITTYPE_FAIL = 'VIEW_VISITTYPE_FAIL'