import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Divider, IconButton, ToggleButtonGroup, ToggleButton, Stack, Typography, CircularProgress } from '@mui/material';
import { blueGrey, orange, blue, green, lightBlue } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { getTasksList, getTasks } from '../../../Redux/Actions/TasksActions';
import useDialog from '../../../Hooks/useDialog';
import KanbanView from '../../../Components/Tasks/KanbanView';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import Crumbs from '../../../Components/Utilities/Crumbs';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import TaskDialogs from '../../../Components/Dialogs/CRM/Task/TaskDialogs/TaskDialogs';
import TasksTbl from '../../../Components/Table/CRM/Tasks/TasksTbl';

//KANBAN COLUMNS
const columns= [
    {
        id:1,
        title: 'Not started'
    },
    {
        id:2,
        title: 'In Progress'
    },
    {
        id:3,
        title: 'Completed'
    } 
]
 
const Tasks = () => {

    const dispatch = useDispatch();
    const {loading, tasks, error } = useSelector(state => state.tasks_List) //receiving state object
    const [list, setList] = useState([])

    useEffect(() => {

        dispatch(getTasks())
    
    }, [])

    useEffect(() => {
        if(tasks)
        {
            setList([...tasks])
        }
    }, [tasks])


   
    const [view, setView] = useState('table');

//----------------------------------------------------------------------------------------------------------------------------------
    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert(); 
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)

    const handleAddClick = () => {
        console.log('THIS IS WORKING')
        showDialog(true, 'create', '');
    }
    
    const [openDrawer, setOpenDrawer] = useState(true);


    return (
        <>
             <ScreenHeader title="Tasks" subtitle="Manage all tasks from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>
                {dialog.open? <TaskDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : ''  }{/* {     alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : ''} */}
            
            
            <Grid container justifyContent="space-between" sx={{mb: 2}}>
                <Crumbs root="CRM" middle="Tasks" end="Current Tasks" main/>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <AddButtonLabel item="Task"/>
                    <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                        <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                    </IconButton>
                </Stack>
            </Grid>
                
                <Divider sx={{color: lightBlue[800]}}/>
                {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
                { loading ?  <HorizontalLoading/> : '' }

                <HorizontalSummaryBox primary="Tasks Brief" secondary="Overall Tasks count" label1="All Tasks" label2="Second Label" label3="Third Label" count={[4,11,6]}/>
                <Grid container justifyContent="flex-end">
                    <ToggleButtonGroup value ={view} size="small" color="primary" >
                        <ToggleButton value="table" onClick={()=> setView('table')} sx={{background: view == 'table'? '' : '' }}>Table <TableRowsIcon fontSize="small" sx={{ pl: 1, color: blueGrey[700]}}/> </ToggleButton>
                        <ToggleButton value="kanban" onClick={()=> setView('kanban')} sx={{background: view == 'kanban'? '' : '' }}>Kanban <ViewColumnIcon fontSize="small" sx={{ pl: 1, color: blueGrey[700]}}/></ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

               
                <Grid container spacing={2} sx={{mt: 0}}>
               
                    {
                        view === 'table'?
                        
                    <>
                    <Typography variant="h6" sx={{fontWeight: 900, px: 0, py: 2, fontFamily: 'Poppins-Light', color: blueGrey[500], ml: 1}}>Tasks List</Typography>
                     <TasksTbl tasks={list}/> 
                    {/* <TasksList tasks = {tasks} taskButtonValue={taskButtonValue} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/> */}
                    </>
                    : 
                    <KanbanView columns={columns}/>
                       
                    }
                   
                </Grid>
            

        </>
    )
}

export default Tasks
