export const PAYERPAYEETYPES_LIST_REQUEST = 'PAYERPAYEETYPES_LIST_REQUEST'
export const PAYERPAYEETYPES_LIST_SUCCESS = 'PAYERPAYEETYPES_LIST_SUCCESS'
export const PAYERPAYEETYPES_LIST_FAIL = 'PAYERPAYEETYPES_LIST_FAIL'


export const PAYERPAYEETYPE_CREATE_REQUEST = 'PAYERPAYEETYPE_CREATE_REQUEST'
export const PAYERPAYEETYPE_CREATE_SUCCESS = 'PAYERPAYEETYPE_CREATE_SUCCESS'
export const PAYERPAYEETYPE_CREATE_FAIL = 'PAYERPAYEETYPE_CREATE_FAIL'


export const PAYERPAYEETYPE_UPDATE_REQUEST = 'PAYERPAYEETYPE_UPDATE_REQUEST'
export const PAYERPAYEETYPE_UPDATE_SUCCESS = 'PAYERPAYEETYPE_UPDATE_SUCCESS'
export const PAYERPAYEETYPE_UPDATE_FAIL = 'PAYERPAYEETYPE_UPDATE_FAIL'

export const PAYERPAYEETYPE_DELETE_REQUEST = 'PAYERPAYEETYPE_DELETE_REQUEST'
export const PAYERPAYEETYPE_DELETE_SUCCESS = 'PAYERPAYEETYPE_DELETE_SUCCESS'
export const PAYERPAYEETYPE_DELETE_FAIL = 'PAYERPAYEETYPE_DELETE_FAIL'

export const VIEW_PAYERPAYEETYPE_REQUEST = 'VIEW_PAYERPAYEETYPE_REQUEST'
export const VIEW_PAYERPAYEETYPE_SUCCESS = 'VIEW_PAYERPAYEETYPE_SUCCESS'
export const VIEW_PAYERPAYEETYPE_FAIL = 'VIEW_PAYERPAYEETYPE_FAIL'