import api from '../config/apiConfig';
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';

 
const useSelectInput = (name, values, setValues, submitValues, setSubmitValues) => {

    //GET DIVISIONS
    const {divisions, loading} = useSelector(state => state.divisions_List);

    //GET OFFICE STAFF AS CONSULTANT
    const {staff, loading: loading_staff} = useSelector(state => state.staff_List);

    //GET WORKERS
    const {workers, loading: loading_workers} = useSelector(state => state.workers_List);

    //GET TASKS
    const {tasks, loading: loading_tasks} = useSelector(state => state.tasks_List);
 
    //GET TASK TYPES
    const {taskTypes, loading: loading_types} = useSelector(state => state.taskTypes_List);

    //GET TASK STATUS
    const {taskStatuses, loading: loading_statuses}  = useSelector(state => state.taskStatuses_List);

    //GET CLIENTS
    const {clients, loading: loading_clients} = useSelector(state => state.clients_List);

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
  


    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['divisions', 'staff', 'workers', 'clients', 'taskstatuses', 'tasktypes', 'jobroles', 'checkinstatuses','workershiftstatuses','paytypes','rates', 'ratecards'];
        
        const id_field = name === 'divisions' ? 'id': name === 'staff' ? 'Staff_Id' : name === 'workers'? 'id' : name === 'clients'? 'Client_Id': name === 'taskstatuses'? 'Status_Id' : name === 'tasktypes'? 'Type_Id' : name === 'jobroles'? 'Role_Id' : name === 'requesttypes'? 'Id' :  name === 'requeststatuses'? 'Id' : name === 'incidenttypes'? 'Id' 
        : name === 'shifts'? 'Shift_Id' : name === 'checkinstatuses'? 'Id' : name === 'workershiftstatuses'? 'Id' : name === 'paytypes'? 'Id' : name === 'rates'? 'Id' :'Id';

        const name_field = name === 'divisions' ? 'Name': name === 'staff' ? 'FullName' : name === 'workers'? 'FullName' : name === 'clients'? 'Name': name === 'taskstatuses'? 'Status_Name' : name === 'tasktypes'? 'Type_Name' : name === 'jobroles'? 'Name' : name === 'requesttypes'? 'Name' : name === 'requeststatuses'? 'Name' : name === 'incidenttypes'? 'Name' 
        : name === 'shifts'? 'First_Name' :  name === 'checkinstatuses'? 'Name' : name === 'workershiftstatuses'? 'Name' : name === 'paytypes'? 'Name' : name === 'rates'? 'Name' : name === 'ratecards'? 'Name' : name === 'payerpayees'? 'FullName': name === 'timesheets'? 'Worker' :'Name';
        

        names.forEach(x => x === name)
        { 
            data.forEach(x => {
                if(name === 'shifts')
                {
                    keeper.push({value: x[id_field], label: `${x.Worker} - ${x.Date} - ${x.Client}`})
                }
                else{
                    keeper.push({value: x[id_field], label: x[name_field]})

                }
                })   
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;

        console.log('VALUE : ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
        }
        else if(name == 'type' || name == 'status' || name == 'result')
        {

            setValues({
                ...values,
                [name]: value
            });
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
 
        }
       }
    

    const handleOptionChange = (value, action) => {
        
        const name = action.name;
        console.log('VALUE:' + value.value)

        setValues({
            ...values,
            [name]: value
        })

        setSubmitValues({
            ...submitValues,
            [name]: value.value
        })
        
    }

    const filterOptions = async (inputValue, name) => {

        console.log('NAME IN FILTER IS:' + taskTypes )
        
        const keeper = [];

        const tableName = name == 'divisions' ? divisions : name == 'clients' ? clients : name == 'staff' ? staff : name == 'workers' ? workers : name == 'taskstatuses' ? taskStatuses : name == 'tasktypes' ? taskTypes :'';

        const id_field = name === 'divisions' ? 'id': name === 'staff' ? 'Staff_Id' : name === 'workers'? 'Worker_Id' : name === 'clients'? 'Client_Id': name === 'taskstatuses'? 'Status_Id' : name === 'tasktypes'? 'Type_Id' : '';
        const name_field = name === 'divisions' ? 'Name': name === 'staff' ? 'FullName' : name === 'workers'? 'FullName' : name === 'clients'? 'Name': name === 'taskstatuses'? 'Status_Name' : name === 'tasktypes'? 'Type_Name' : '';
            
        tableName.forEach(x => {
           
            keeper.push({value: x[id_field], label: x[name_field]})

            
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    return {loadOptions, handleInputChange,handleChange, values, submitValues, handleOptionChange}
}

export default useSelectInput
