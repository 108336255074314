import { VISITS_LIST_REQUEST, VISITS_LIST_SUCCESS, VISITS_LIST_FAIL, VIEW_VISIT_FAIL, VIEW_VISIT_REQUEST, VIEW_VISIT_SUCCESS, VISIT_CREATE_FAIL, VISIT_UPDATE_REQUEST, VISIT_UPDATE_FAIL, VISIT_DELETE_REQUEST, VISIT_DELETE_FAIL, VISIT_CREATE_REQUEST, VISIT_CREATE_SUCCESS, VISIT_UPDATE_SUCCESS, VISIT_DELETE_SUCCESS } from "../Constants/visitConstants";

const visitsListReducer = (state = {visits: []}, action) => {

    switch (action.type)
    {
        case VISITS_LIST_REQUEST:
            return {loading: true};
 
        case VISITS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and visits data is:' + action.payload)
            return {
                loading: false,
                visits: action.payload
            }
        case VISITS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const visitCreateReducer = (state = {visits: []}, action) => {

    switch (action.type) {

        case VISIT_CREATE_REQUEST:
            return {
                loading: true
            }

        case VISIT_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                visits: [...state.visits, {Applicant_Id: 2, Name: value}]
            };

        case VISIT_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const visitViewReducer = (state= {visit: {}}, action) => {
    switch(action.type)
    {
        case VIEW_VISIT_REQUEST:
            return {loading: true};
        case VIEW_VISIT_SUCCESS:
            return {
                    loading: false,
                    visit: action.payload
                }
        case VIEW_VISIT_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const visitUpdateReducer = (state = {visits: {}}, action) => {

    switch (action.type) {

        case VISIT_UPDATE_REQUEST:
            return {
                
            }
        case VISIT_UPDATE_SUCCESS:

            return {

            }
        case VISIT_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const visitDeleteReducer = (state = {visits: {}}, action) => {

    switch (action.type) {
        case VISIT_DELETE_REQUEST:
            return {
                
            }
        case VISIT_DELETE_SUCCESS:
            return {

            }
        case VISIT_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {visitsListReducer, visitCreateReducer, visitViewReducer, visitUpdateReducer, visitDeleteReducer }