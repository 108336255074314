import { PAYROLLDOCTYPES_LIST_REQUEST, PAYROLLDOCTYPES_LIST_FAIL, PAYROLLDOCTYPES_LIST_SUCCESS} from '../Constants/payrollDocTypeConstants';
import api from '../../config/apiConfig';


const getPayrollDocTypesList = () => async(dispatch) => {
    //const data = assessmenttypes;
 
    try {
        dispatch({type: PAYROLLDOCTYPES_LIST_REQUEST});
        const {data} = await api.get('/payrolldoctypes');
        //console.log('PayrollDocTypes data is:' + data[0]['PayrollDocTypes_Name'])
        dispatch({type: PAYROLLDOCTYPES_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: PAYROLLDOCTYPES_LIST_FAIL, payload: error})
    }
} 

export {getPayrollDocTypesList }