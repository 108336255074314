import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, yellow, indigo, purple } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import TaskDialogs from '../../../Dialogs/CRM/Task/TaskDialogs/TaskDialogs';

const TasksTbl = ({tasks}) => { 

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    const  getTypeColor = (status) => {
        return status === 'Assign Work' ? purple[500] : status === 'Complete Invoices'?  lightGreen[500] : status === 'Complete Payroll'? lightBlue[500] :  status === 'Compliance'? pink[500] : status === 'Contact Client'? yellow[800]  : status === 'Contact Client'? indigo[400]  : status === 'New'? orange[500] : blueGrey[500]
    }
    const  getStatusColor = (status) => {
        return status === 'New Task' ? pink[500] : status === 'In Progress'?  yellow[800] : status === 'Part Complete'? lightBlue[500] : status === 'Complete'? lightBlue[500] : status === 'Overdue'? blue[800] : status === 'Escalate'? purple[800] :  blueGrey[500]
    } 

    const switchToViewTask = (id) => {
        navigate('/task/'+ id)
    } 

    const taskQuickView = (id) => {

        showDialog(true, 'view', id)
    }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number', minWidth: 100 },
        { field: 'Task_Name', headerName: 'Task Name', minWidth: 250 , renderCell: (params) => <Typography onClick={()=> {taskQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Type} - ${params.row.Date} - ${params.row.Worker}`}</Typography>},
        { field: 'Description', headerName: 'Description', minWidth: 200 ,},
        { field: 'Type', headerName: 'Type', minWidth: 180 , type: 'singleSelect', valueOptions: (['Assign Work', 'Compliance', 'Contact Client', 'Contact Panel', 'Complete Invoices', 'New', 'Nest Payments']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getTypeColor(params.row.Type), borderRadius: '30px', px: '10px'}}>{params.row.Type}</Typography>
            )},
            
        { field: 'Worker', headerName: 'Worker', minWidth: 130},
        { field: 'Status', headerName: 'Status', minWidth: 180 , type: 'singleSelect', valueOptions: (['New Task', 'Part Complete', 'Complete', 'In Progress', 'Overdue', 'Escalate']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
            )},
        { field: 'Client', headerName: 'Client', type: 'singleSelect', valueOptions: (['Edmonton', 'Chiswick'])},
        { field: 'Staff', headerName: 'Staff'},
        { field: 'Date', headerName: 'Date'},
        { field: 'Deadline', headerName: 'Deadline'},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewTask(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];


    return (
        
        <>
            { dialog.open  ?  <TaskDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={tasks}
                        loading={!tasks.length}
                    />
                </div>
            </div>
        </>
    )
}

export default TasksTbl
