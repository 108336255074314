export const COAS_LIST_REQUEST = 'COAS_LIST_REQUEST'
export const COAS_LIST_SUCCESS = 'COAS_LIST_SUCCESS'
export const COAS_LIST_FAIL = 'COAS_LIST_FAIL'


export const COA_CREATE_REQUEST = 'COA_CREATE_REQUEST'
export const COA_CREATE_SUCCESS = 'COA_CREATE_SUCCESS'
export const COA_CREATE_FAIL = 'COA_CREATE_FAIL'


export const COA_UPDATE_REQUEST = 'COA_UPDATE_REQUEST'
export const COA_UPDATE_SUCCESS = 'COA_UPDATE_SUCCESS'
export const COA_UPDATE_FAIL = 'COA_UPDATE_FAIL'

export const COA_DELETE_REQUEST = 'COA_DELETE_REQUEST'
export const COA_DELETE_SUCCESS = 'COA_DELETE_SUCCESS'
export const COA_DELETE_FAIL = 'COA_DELETE_FAIL'

export const VIEW_COA_REQUEST = 'VIEW_COA_REQUEST'
export const VIEW_COA_SUCCESS = 'VIEW_COA_SUCCESS'
export const VIEW_COA_FAIL = 'VIEW_COA_FAIL'