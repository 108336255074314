import React, { useState } from 'react';
import applicants_list from '../Data/Applicants';

const useForm = (table_data) => {

    //console.log('The object is: ' + applicants_list.find(item => item.id == id));
    //const applicant = applicants_list.find(item => item.id == id)

    const obj = table_data[0];
    const names_array = Object.keys(obj);

    const objectOfFocus = {};

    names_array.forEach(element => {
        if(element == 'Task Description')
        {

            objectOfFocus[element] = ''
        }
        else{

            objectOfFocus[element] = ''
        }
    });
    

    console.log('My tasks now: '+ Object.keys(objectOfFocus));
    
    const [values, setValues] = useState({

       ...objectOfFocus

    })
 
    const handleChange = (event) => {
        const {name, value} = event.target;
        console.log(`In handle change and ${name} value is : ` + value);
        setValues({
            [name]: value,
        })
    }

    //handling Alerts
    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false,
        })

    // const handleClickEdit = () => {
        
    //     showAlert('info', 'You are now editing', true)
    //     setIsEditing(true)
    // }

    const showAlert = (type, message, show) => {
        setAlert({type, message, show})
    }


    return {values, handleChange, alert, showAlert}
}

export default useForm

 // id: applicant.id,
        // name: applicant['Applicant Name'],
        // fname: applicant['First Name'],
        // lname: applicant.Surname,
        // gender: applicant.gender,
        // email: applicant.email,
        // mobile: applicant.mobile,
        // address: applicant.address,
        // join_date: applicant,