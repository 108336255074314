import { SHIFTS_LIST_REQUEST, SHIFTS_LIST_SUCCESS, SHIFTS_LIST_FAIL, VIEW_SHIFT_FAIL, VIEW_SHIFT_REQUEST, VIEW_SHIFT_SUCCESS, SHIFT_CREATE_REQUEST, SHIFT_CREATE_SUCCESS, SHIFT_CREATE_FAIL, SHIFT_UPDATE_FAIL, SHIFT_UPDATE_REQUEST, SHIFT_UPDATE_SUCCESS } from '../Constants/shiftConstants';
import api from '../../config/apiConfig';


const getShiftsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: SHIFTS_LIST_REQUEST});
        const {data} = await api.get('/shifts');
        // console.log('Shift data is:' + data[0]['Shift_Name'])
        dispatch({type: SHIFTS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: SHIFTS_LIST_FAIL, payload: error})
    }
} 

const submitShift = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: SHIFT_CREATE_REQUEST});
        
        const {data} = await api.post(`/shifts/`, values);
        console.log('Shift data is:' + data)
        dispatch({type: SHIFT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: SHIFT_CREATE_FAIL, payload: error})
    }
} 

const getShift = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_SHIFT_REQUEST});
        
        const {data} = await api.get(`/shifts/ ${id}`);
        console.log('Shift data is:' + data.Staff_FName)
        dispatch({type: VIEW_SHIFT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_SHIFT_FAIL, payload: error})
    }
}

const editShift = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: SHIFT_UPDATE_REQUEST});
        
        const {data} = await api.put(`/shifts/`, values);
        console.log('Shift data is:' + data)
        dispatch({type: SHIFT_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: SHIFT_UPDATE_FAIL, payload: error})
    }
}



export {getShiftsList, submitShift, getShift, editShift }