import React from 'react';
import { Grid, Divider, Typography, Box, Stack} from '@mui/material';
import { blue, lightBlue} from '@mui/material/colors';

const TimesheetSummary = () => {
    return (
        <>
            <Box sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>
                <Typography >Worker Details</Typography>
                <Divider sx={{position: 'relative',color: blue[800], my: 2, zIndex: 5}}/>
                <Stack direction="row">
                    <Typography sx={{color: blue[300], fontSize: 30, fontFamily: 'Poppins-Bold',mt: 1, textDecoration: 'none'}}>James</Typography>
                    <Typography sx={{color: blue[600], fontSize: 30, fontFamily: 'Poppins-Bold',mt: 1, ml: 1, textDecoration: 'none'}}>Kin</Typography>

                </Stack>

                <Grid container sx={{mt: 2, background: lightBlue[200], borderRadius: '5px', p: 3}}>
                    <Grid item lg={4} >
                        <Typography sx={{color: blue[400], fontSize: 20 ,fontFamily: 'Poppins-Bold',mt: 1, textDecoration: 'none'}}>Worker Details</Typography>
                        <Divider sx={{position: 'relative',color: blue[800], my: 1, zIndex: 5}}/>
                        <Grid container>
                            <Grid item lg={6}>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>Division</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>Job Role</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>Payroll Setup</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>UTR</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>CIS</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>Company</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>Company No.</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>VAT No.</Typography>
                            </Grid>
                            <Grid item lg={6}>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                    
                    <Grid item lg={5} >
                        <Typography sx={{color: blue[400], fontSize: 20 ,fontFamily: 'Poppins-Bold',mt: 1, textDecoration: 'none'}}>Timesheet Details</Typography>
                        <Divider sx={{position: 'relative',color: blue[800], my: 1, zIndex: 5}}/>
                        <Grid container>
                            <Grid item lg={6}>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>Division</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>Job Role</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>Payroll Setup</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>UTR</Typography>
                            </Grid>
                            <Grid item lg={6}>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item lg={3} >
                        <Typography sx={{color: blue[700], fontSize: 20 ,fontFamily: 'Poppins-Bold',mt: 1, textDecoration: 'none'}}>Payment Summary</Typography>
                        <Divider sx={{position: 'relative',color: blue[800], my: 1, zIndex: 5}}/>
                        <Grid container>
                            <Grid item lg={6}>
                                <Typography sx={{color: blue[700], fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>Division</Typography>
                                <Typography sx={{color: blue[700], fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>Job Role</Typography>
                                <Typography sx={{color: blue[700], fontFamily: 'Poppins',mt: 1, textDecoration: 'none'}}>Payroll Setup</Typography>
                            </Grid>
                            <Grid item lg={6}>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                                <Typography sx={{color: 'white', fontFamily: 'Poppins-Bold',mt: 1}}>James Kin</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{position: 'relative',color: blue[800], my: 3, zIndex: 5}}/>

                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default TimesheetSummary
