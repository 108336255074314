export const BALANCESHEETTYPES_LIST_REQUEST = 'BALANCESHEETTYPES_LIST_REQUEST'
export const BALANCESHEETTYPES_LIST_SUCCESS = 'BALANCESHEETTYPES_LIST_SUCCESS'
export const BALANCESHEETTYPES_LIST_FAIL = 'BALANCESHEETTYPES_LIST_FAIL'


export const BALANCESHEETTYPE_CREATE_REQUEST = 'BALANCESHEETTYPE_CREATE_REQUEST'
export const BALANCESHEETTYPE_CREATE_SUCCESS = 'BALANCESHEETTYPE_CREATE_SUCCESS'
export const BALANCESHEETTYPE_CREATE_FAIL = 'BALANCESHEETTYPE_CREATE_FAIL'


export const BALANCESHEETTYPE_UPDATE_REQUEST = 'BALANCESHEETTYPE_UPDATE_REQUEST'
export const BALANCESHEETTYPE_UPDATE_SUCCESS = 'BALANCESHEETTYPE_UPDATE_SUCCESS'
export const BALANCESHEETTYPE_UPDATE_FAIL = 'BALANCESHEETTYPE_UPDATE_FAIL'

export const BALANCESHEETTYPE_DELETE_REQUEST = 'BALANCESHEETTYPE_DELETE_REQUEST'
export const BALANCESHEETTYPE_DELETE_SUCCESS = 'BALANCESHEETTYPE_DELETE_SUCCESS'
export const BALANCESHEETTYPE_DELETE_FAIL = 'BALANCESHEETTYPE_DELETE_FAIL'

export const VIEW_BALANCESHEETTYPE_REQUEST = 'VIEW_BALANCESHEETTYPE_REQUEST'
export const VIEW_BALANCESHEETTYPE_SUCCESS = 'VIEW_BALANCESHEETTYPE_SUCCESS'
export const VIEW_BALANCESHEETTYPE_FAIL = 'VIEW_BALANCESHEETTYPE_FAIL'