export const INCIDENTTYPES_LIST_REQUEST = 'INCIDENTTYPES_LIST_REQUEST';
export const INCIDENTTYPES_LIST_SUCCESS = 'INCIDENTTYPES_LIST_SUCCESS';
export const INCIDENTTYPES_LIST_FAIL = 'INCIDENTTYPES_LIST_FAIL'; 

export const INCIDENTTYPE_CREATE_REQUEST = 'INCIDENTTYPE_CREATE_REQUEST'
export const INCIDENTTYPE_CREATE_SUCCESS = 'INCIDENTTYPE_CREATE_SUCCESS'
export const INCIDENTTYPE_CREATE_FAIL = 'INCIDENTTYPE_CREATE_FAIL'


export const INCIDENTTYPE_UPDATE_REQUEST = 'INCIDENTTYPE_UPDATE_REQUEST'
export const INCIDENTTYPE_UPDATE_SUCCESS = 'INCIDENTTYPE_UPDATE_SUCCESS'
export const INCIDENTTYPE_UPDATE_FAIL = 'INCIDENTTYPE_UPDATE_FAIL'

export const INCIDENTTYPE_DELETE_REQUEST = 'INCIDENTTYPE_DELETE_REQUEST'
export const INCIDENTTYPE_DELETE_SUCCESS = 'INCIDENTTYPE_DELETE_SUCCESS'
export const INCIDENTTYPE_DELETE_FAIL = 'INCIDENTTYPE_DELETE_FAIL'

export const VIEW_INCIDENTTYPE_REQUEST = 'VIEW_INCIDENTTYPE_REQUEST'
export const VIEW_INCIDENTTYPE_SUCCESS = 'VIEW_INCIDENTTYPE_SUCCESS'
export const VIEW_INCIDENTTYPE_FAIL = 'VIEW_INCIDENTTYPE_FAIL'