import { TRANSACTIONS_LIST_REQUEST, TRANSACTIONS_LIST_SUCCESS, TRANSACTIONS_LIST_FAIL, VIEW_TRANSACTION_FAIL, VIEW_TRANSACTION_REQUEST, VIEW_TRANSACTION_SUCCESS, TRANSACTION_CREATE_REQUEST, TRANSACTION_CREATE_SUCCESS, TRANSACTION_CREATE_FAIL, TRANSACTION_UPDATE_FAIL, TRANSACTION_UPDATE_REQUEST, TRANSACTION_UPDATE_SUCCESS } from '../Constants/transactionConstants';
import api from '../../config/apiConfig';


const getTransactionsList = () => async(dispatch) => {
    
  
    try {
        dispatch({type: TRANSACTIONS_LIST_REQUEST});
        const {data} = await api.get('/transactions');
        console.log('Transactions data is:' + data[0].Name)
        dispatch({type: TRANSACTIONS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: TRANSACTIONS_LIST_FAIL, payload: error})
    }
} 

const submitTransaction = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: TRANSACTION_CREATE_REQUEST});
        
        const {data} = await api.post(`/transactions/`, postValues);
        console.log('Transaction data is:' + data)
        dispatch({type: TRANSACTION_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: TRANSACTION_CREATE_FAIL, payload: error})
    }
} 

const getTransaction = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_TRANSACTION_REQUEST});
        
        const {data} = await api.get(`/transactions/ ${id}`);
        console.log('Transaction data is:' + data)
        dispatch({type: VIEW_TRANSACTION_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_TRANSACTION_FAIL, payload: error})
    }
}

const editTransaction = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: TRANSACTION_UPDATE_REQUEST});
        
        const {data} = await api.put(`/transactions/`, values);
        console.log('Transaction data is:' + data)
        dispatch({type: TRANSACTION_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: TRANSACTION_UPDATE_FAIL, payload: error})
    }
}



export {getTransactionsList, submitTransaction, getTransaction, editTransaction }