import { TASKSTATUSES_LIST_REQUEST, TASKSTATUSES_LIST_SUCCESS, TASKSTATUSES_LIST_FAIL, VIEW_TASKSTATUS_FAIL, VIEW_TASKSTATUS_REQUEST, VIEW_TASKSTATUS_SUCCESS, TASKSTATUS_CREATE_FAIL, TASKSTATUS_UPDATE_REQUEST, TASKSTATUS_UPDATE_FAIL, TASKSTATUS_DELETE_REQUEST, TASKSTATUS_DELETE_FAIL, TASKSTATUS_CREATE_REQUEST, TASKSTATUS_CREATE_SUCCESS, TASKSTATUS_UPDATE_SUCCESS, TASKSTATUS_DELETE_SUCCESS } from "../Constants/taskStatusConstants";

const taskStatusesListReducer = (state = {taskStatuses: []}, action) => {

    switch (action.type)
    {
        case TASKSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case TASKSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and taskStatuss data is:' + action.payload)
            return {
                loading: false,
                taskStatuses: action.payload
            }
        case TASKSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const taskStatusCreateReducer = (state = {taskStatuses: []}, action) => {

    switch (action.type) {

        case TASKSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case TASKSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                taskStatuses: [...state.taskStatuses, {Applicant_Id: 2, Name: value}]
            };

        case TASKSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const taskStatusViewReducer = (state= {taskStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_TASKSTATUS_REQUEST:
            return {loading: true};
        case VIEW_TASKSTATUS_SUCCESS:
            return {
                    loading: false,
                    taskStatus: action.payload
                }
        case VIEW_TASKSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const taskStatusUpdateReducer = (state = {taskStatuss: {}}, action) => {

    switch (action.type) {

        case TASKSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case TASKSTATUS_UPDATE_SUCCESS:

            return {

            }
        case TASKSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const taskStatusDeleteReducer = (state = {taskStatuss: {}}, action) => {

    switch (action.type) {
        case TASKSTATUS_DELETE_REQUEST:
            return {
                
            }
        case TASKSTATUS_DELETE_SUCCESS:
            return {

            }
        case TASKSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {taskStatusesListReducer, taskStatusCreateReducer, taskStatusViewReducer, taskStatusUpdateReducer, taskStatusDeleteReducer }