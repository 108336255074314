export const CHECKINS_LIST_REQUEST = 'CHECKINS_LIST_REQUEST'
export const CHECKINS_LIST_SUCCESS = 'CHECKINS_LIST_SUCCESS'
export const CHECKINS_LIST_FAIL = 'CHECKINS_LIST_FAIL'


export const CHECKIN_CREATE_REQUEST = 'CHECKIN_CREATE_REQUEST'
export const CHECKIN_CREATE_SUCCESS = 'CHECKIN_CREATE_SUCCESS'
export const CHECKIN_CREATE_FAIL = 'CHECKIN_CREATE_FAIL'


export const CHECKIN_UPDATE_REQUEST = 'CHECKIN_UPDATE_REQUEST'
export const CHECKIN_UPDATE_SUCCESS = 'CHECKIN_UPDATE_SUCCESS'
export const CHECKIN_UPDATE_FAIL = 'CHECKIN_UPDATE_FAIL'

export const CHECKIN_DELETE_REQUEST = 'CHECKIN_DELETE_REQUEST'
export const CHECKIN_DELETE_SUCCESS = 'CHECKIN_DELETE_SUCCESS'
export const CHECKIN_DELETE_FAIL = 'CHECKIN_DELETE_FAIL'

export const VIEW_CHECKIN_REQUEST = 'VIEW_CHECKIN_REQUEST'
export const VIEW_CHECKIN_SUCCESS = 'VIEW_CHECKIN_SUCCESS'
export const VIEW_CHECKIN_FAIL = 'VIEW_CHECKIN_FAIL'