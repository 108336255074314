import React from 'react';
import AddVisitDialog from './AddVisitDialog';
import ViewVisitDialog from './ViewVisitDialog';
import DeleteVisitDialog from './DeleteVisitDialog';



const VisitDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        { 
            type === "create"?

            <AddVisitDialog open={open} onClose={onClose}  handleSubmit={handleSubmit} fullWidth />
            
            : type === "view"?

            <ViewVisitDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />

            : type === "delete"?

            <DeleteVisitDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default VisitDialogs
