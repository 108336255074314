export const INDUSTRIES_LIST_REQUEST = 'INDUSTRIES_LIST_REQUEST'
export const INDUSTRIES_LIST_SUCCESS = 'INDUSTRIES_LIST_SUCCESS'
export const INDUSTRIES_LIST_FAIL = 'INDUSTRIES_LIST_FAIL'


export const INDUSTRY_CREATE_REQUEST = 'INDUSTRY_CREATE_REQUEST'
export const INDUSTRY_CREATE_SUCCESS = 'INDUSTRY_CREATE_SUCCESS'
export const INDUSTRY_CREATE_FAIL = 'INDUSTRY_CREATE_FAIL'


export const INDUSTRY_UPDATE_REQUEST = 'INDUSTRY_UPDATE_REQUEST'
export const INDUSTRY_UPDATE_SUCCESS = 'INDUSTRY_UPDATE_SUCCESS'
export const INDUSTRY_UPDATE_FAIL = 'INDUSTRY_UPDATE_FAIL'

export const INDUSTRY_DELETE_REQUEST = 'INDUSTRY_DELETE_REQUEST'
export const INDUSTRY_DELETE_SUCCESS = 'INDUSTRY_DELETE_SUCCESS'
export const INDUSTRY_DELETE_FAIL = 'INDUSTRY_DELETE_FAIL'

export const VIEW_INDUSTRY_REQUEST = 'VIEW_INDUSTRY_REQUEST'
export const VIEW_INDUSTRY_SUCCESS = 'VIEW_INDUSTRY_SUCCESS'
export const VIEW_INDUSTRY_FAIL = 'VIEW_INDUSTRY_FAIL'