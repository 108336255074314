import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as GrIcons from 'react-icons/gr';
import * as BsIcons from 'react-icons/bs';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import MoneyOffOutlinedIcon from '@mui/icons-material/MoneyOffOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
  
  

export const SidebarData = [
    {
        id: 1,
        title: 'Dashboard',
        path: '/',
        icon: <DashboardOutlinedIcon  />,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: 'Dashboard',
        
    },
    {
        id: 2,
        title: 'Complex CRM',
        path: '',
        icon: <CalculateOutlinedIcon />,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: '',
        subMenu: [
            {
                id: 1,
                title: 'Calendars',
                path: '/calendar',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                secondSubMenu: [
                    {
                        title: 'Assessments Calendar',
                        path: '/assessmentscalendar',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Drivers Calendar',
                        path: '/driverscalendar',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Construction Calendar',
                        path: '/constructioncalendar',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Industrial Calendar',
                        path: '/industrialcalendar',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                title: 'Tasks',
                path: '/tasks',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                secondSubMenu: [
                    {
                        title: 'Current',
                        path: '/currenttasks',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Outstanding',
                        path: '/outstandingtasks',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                title: 'HR',
                path: '/recruitment',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                secondSubMenu: [
                    {
                        title: 'Applicants',
                        path: '/applicants',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Assessments',
                        path: '/assessments',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Registration',
                        path: '/registration',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                title: 'Staff',
                path: '/staff',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                secondSubMenu: [
                    {
                        title: 'Workers',
                        path: '/workers',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Availability',
                        path: '/availability',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Shifts',
                        path: '/shifts',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Check Ins',
                        path: '/checkins',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Timesheets',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'My Documents',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'My Payments',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'My Profile',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Office Staff',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                title: 'Sales',
                path: '/sales',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                secondSubMenu: [
                    {
                        title: 'New client form',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Contacts',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Clients',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Checks & Limits',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Prospects',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                title: 'Operations',
                path: '/operations',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                secondSubMenu: [
                    {
                        title: 'Requests',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Accidents & Incidents',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Opportunities',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Bookings',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Client Data',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Queries',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Budget Tracker',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                title: 'Management',
                path: '/management',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                secondSubMenu: [
                    {
                        title: 'Rate Cards',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Client Sites',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Last Service Visit',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Reports',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                title: 'System',
                path: '/systems',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                secondSubMenu: [
                    {
                        title: 'Working Week',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Worker of the month',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Holidays',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Time & Attendance Log',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                ]
            },
]
    },
    {
        id: 3,
        title: 'Recruitment',
        path: '',
        icon: <PeopleOutlinedIcon />,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: 'Recruitment',
        subMenu: [
            {
                title: 'Advert Templates',
                path: '/advert_templates',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                //secondSubMenu: []
            },
            {
                title: 'Applicants',
                path: '/applicants',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                //secondSubMenu: []
                
            },
            {
                title: 'Place Job',
                path: '/place_job',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                //secondSubMenu: []
            },
            {
                title: 'Jobs',
                path: '/systems',
                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                //secondSubMenu: []
            },
]
    },
    {
        id: 4,
        title: 'Documents',
        path: '/documents',
        icon: <SummarizeOutlinedIcon/>,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: 'Documents',
        subMenu: [
                    {
                        title: 'Employees',
                        path: '/employees',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                        iconClosed: <BsIcons.BsChevronDown/>,
                        iconUp: <BsIcons.BsChevronUp/>,
                        secondSubMenu: [
                            {
                                title: 'Send new document',
                                path: '/send_document',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Add new document',
                                path: '/add_document',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Search signed document',
                                path: '/search_document',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                                ]
                    },
                    {
                        title: 'Workers',
                        path: '/workers',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                        iconClosed: <BsIcons.BsChevronDown/>,
                        iconUp: <BsIcons.BsChevronUp/>,
                        secondSubMenu: [
                            {
                                title: 'Send new document',
                                path: '/send_document',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Add new document',
                                path: '/add_document',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Search signed document',
                                path: '/search_document',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'View pending document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Document Library',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                                ]
                    },
                    {
                        title: 'Clients',
                        path: '/clients',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                        iconClosed: <BsIcons.BsChevronDown/>,
                        iconUp: <BsIcons.BsChevronUp/>,
                        secondSubMenu: [
                            {
                                title: 'Send new document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Add new document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Search signed document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'View pending document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Document Library',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                        ]
                    },
                    {
                        title: 'Other',
                        path: '/other',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                        iconClosed: <BsIcons.BsChevronDown/>,
                        iconUp: <BsIcons.BsChevronUp/>,
                        secondSubMenu: [
                            {
                                title: 'Send new document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Add new document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Search signed document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'View pending document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Document Library',
                                path: '/systems',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                        ]
                    },
        ]
    },
    {
        id: 5,
        title: 'Finance',
        path: '',
        icon: <MoneyOffOutlinedIcon />,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: 'Finance',
        subMenu: [
                    {
                        title: 'Reconciliation',
                        path: '/reconciliation',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                        secondSubMenu: [
                            {
                                title: 'Chart of accounts',
                                path: '/chartofaccounts',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Upload File',
                                path: '/fileupload',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Reconcile',
                                path: '/reconcile',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Transactions',
                                path: '/transactions',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'VAT Returns',
                                path: '/vat',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Profit & Loss',
                                path: '/profitandloss',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Balance Sheet',
                                path: '/balancesheet',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Customers',
                                path: '/customers',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Suppliers',
                                path: '/suppliers',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                        ]
                    },
                    {
                        title: 'Invoices',
                        path: '/invoices',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                        secondSubMenu: [
                            {
                                title: 'Invoice Table',
                                path: '/invoicetable',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Send Invoice',
                                path: '/sendinvoice',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                        ]
                    },
                    {
                        title: 'Checks',
                        path: '/checks',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                        secondSubMenu: [
                            {
                                title: 'Cgrayit Checks',
                                path: '/cgrayitchecks',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'LTD Checks',
                                path: '/ltdchecks',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'VAT Check',
                                path: '/vatchecks',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'UTR Check',
                                path: '/utrcheck',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Drivers Licence Check',
                                path: '/drlicencecheck',
                                icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                            },
                        ]
                    },
        ]
    },
    {
        id: 6,
        title: 'Settings',
        path: '/settings',
        icon: <SettingsOutlinedIcon />,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: 'Settings',
        subMenu: [
                    {
                        title: 'Account',
                        path: '/account',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Users',
                        path: '/users',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
                    {
                        title: 'Email',
                        path: '/email',
                        icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    },
        ]
    },
    {
        id: 7,
        title: 'Admin',
        path: '/admin',
        icon: <AdminPanelSettingsOutlinedIcon/>,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: '',
    },
]

export default SidebarData 
