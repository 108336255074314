import { INCIDENTSTATUSES_LIST_REQUEST, INCIDENTSTATUSES_LIST_SUCCESS, INCIDENTSTATUSES_LIST_FAIL, VIEW_INCIDENTSTATUS_FAIL, VIEW_INCIDENTSTATUS_REQUEST, VIEW_INCIDENTSTATUS_SUCCESS, INCIDENTSTATUS_CREATE_FAIL, INCIDENTSTATUS_UPDATE_REQUEST, INCIDENTSTATUS_UPDATE_FAIL, INCIDENTSTATUS_DELETE_REQUEST, INCIDENTSTATUS_DELETE_FAIL, INCIDENTSTATUS_CREATE_REQUEST, INCIDENTSTATUS_CREATE_SUCCESS, INCIDENTSTATUS_UPDATE_SUCCESS, INCIDENTSTATUS_DELETE_SUCCESS } from "../Constants/incidentStatusConstants";

const incidentStatusesListReducer = (state = {incidentStatuses: []}, action) => {

    switch (action.type)
    {
        case INCIDENTSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case INCIDENTSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and incidentStatuss data is:' + action.payload)
            return {
                loading: false,
                incidentStatuses: action.payload
            }
        case INCIDENTSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const incidentStatusCreateReducer = (state = {incidentStatuses: []}, action) => {

    switch (action.type) {

        case INCIDENTSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case INCIDENTSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                incidentStatuses: [...state.incidentStatuses, {Applicant_Id: 2, Name: value}]
            };

        case INCIDENTSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const incidentStatusViewReducer = (state= {incidentStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_INCIDENTSTATUS_REQUEST:
            return {loading: true};
        case VIEW_INCIDENTSTATUS_SUCCESS:
            return {
                    loading: false,
                    incidentStatus: action.payload
                }
        case VIEW_INCIDENTSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const incidentStatusUpdateReducer = (state = {incidentStatuss: {}}, action) => {

    switch (action.type) {

        case INCIDENTSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case INCIDENTSTATUS_UPDATE_SUCCESS:

            return {

            }
        case INCIDENTSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const incidentStatusDeleteReducer = (state = {incidentStatuss: {}}, action) => {

    switch (action.type) {
        case INCIDENTSTATUS_DELETE_REQUEST:
            return {
                
            }
        case INCIDENTSTATUS_DELETE_SUCCESS:
            return {

            }
        case INCIDENTSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {incidentStatusesListReducer, incidentStatusCreateReducer, incidentStatusViewReducer, incidentStatusUpdateReducer, incidentStatusDeleteReducer }