import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, Breadcrumbs, TextField} from '@mui/material';
import { blueGrey, pink, lightBlue, lightGreen } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useDispatch, useSelector } from 'react-redux';
import {  submitHoliday } from '../../../../../Redux/Actions/HolidayActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { Link as BreadcrumbLink } from '@mui/material';
import { getDepartmentsList } from '../../../../../Redux/Actions/DepartmentActions';
import { getBranchesList } from '../../../../../Redux/Actions/BranchActions';
import { getRateCardsList } from '../../../../../Redux/Actions/RateCardActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SummaryBox from '../../../../Utilities/SummaryBox';
import SideCrumbs from '../../../../Utilities/SideCrumbs';
import useDate from '../../../../../Hooks/useDate';


const AddHolidayDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET DEPARTMENTS
    const {departments, loading} = useSelector(state => state.departments_List);


    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getJobRolesList());
        dispatch(getDepartmentsList());
        dispatch(getBranchesList());
        dispatch(getRateCardsList());
       
    }, [])
    

    const [values, setValues] = useState({
            name: '',
            group: '',
            worker: '',
            staff: '',
            start: '',
            end: '',
            days_taken: '',
            days_left: '',
            approved_by: '',
            status: '',
        });

    const [submitValues, setSubmitValues] = useState({
            name: '',
            group: '',
            worker: '',
            staff: '',
            start: '',
            end: '',
            days_taken: '',
            days_left: '', 
            approved_by: '',
            status: '',
        });   
        
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitHoliday(values, submitValues))
        handleSubmit('success',"Successfully saved request information");
        
    }
    
    const animatedComponents = makeAnimated();
    
    const [inputValue, setInputValue] = useState('');
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
    
    const [date, setDate] = useState({
        start: '',
        end: '',
    });


    const handleDateChange = (newValue, name) => {
        
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate({
            ...date,
            [name]: newValue
        });
        setValues({
            ...values,
            [name]: `${day}/${month+1}/${year}`
        })
        setSubmitValues({
            ...submitValues,
            [name]: `${day}/${month+1}/${year}`
        })
    }


    
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }


    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: lightBlue[700]}}  >
                
                <EngineeringIcon fontSize="large" sx={{color: lightBlue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: lightBlue[200],fontWeight: 700}}> Add Holiday </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    {/* <p>name {submitValues.name}</p>
                    <p>group {submitValues.group}</p>
                    <p>worker {submitValues.worker}</p>
                    <p>staff {submitValues.staff}</p>
                    <p>startdate{submitValues.start}</p>
                    <p>endate {submitValues.end}</p>
                    <p>days_taken{submitValues.days_taken}</p>
                    <p>days_left {submitValues.days_left}</p>
                    <p>approved {submitValues.approved_by}</p>
                    <p>status {submitValues.status}</p> */}
                    
                    <SideCrumbs/>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Holiday ID</Form.Label>
                                <Form.Control disabled type="email" value="HOL-1" placeholder="E.g 123qst1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Name</Form.Label>
                                <Form.Control type="text" name="name" value={values.name} onChange={handleChange} placeholder="Enter name" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Holiday Group</Form.Label>
                                    <AsyncSelect
                                        value={values.group}
                                        name="group"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'holidaygroups')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for group ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                        <br/>
                        <Typography id="specifics" variant="body2" sx={{display: 'inline',color: 'white',background: lightBlue[500], borderRadius: '5px', px: 2, py: '5px'}}>Some Specifics</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Worker</Form.Label>
                                    <AsyncSelect
                                        value={values.worker}
                                        name="worker"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'workers')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for worker ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Staff</Form.Label>
                                    <AsyncSelect
                                        value={values.staff}
                                        name="staff"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'staff')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for staff ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                        
                        <br/>
                        <Typography id="staff" variant="body2" sx={{display: 'inline',color: 'white',background: pink[400], borderRadius: '5px', px: 2, py: '5px'}}>Specifics</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="start"
                                            label="Worker's Start Date"
                                            value={date.start}
                                            onChange={(e) => handleDateChange(e, "start")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="end"
                                            label="Worker's End Date"
                                            value={date.end}
                                            onChange={(e) => handleDateChange(e, "end")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography id="staff" variant="body2" sx={{display: 'inline',color: 'white',background: lightGreen[500], borderRadius: '5px', px: 2, py: '5px'}}>Workers</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Days Taken</Form.Label>
                                <Form.Control name="days_taken" type="number" value={values.days_taken} onChange={handleChange} placeholder="15" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Days Left</Form.Label>
                                <Form.Control name="days_left" type="number" value={values.days_left} onChange={handleChange} placeholder="15" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Approved by</Form.Label>
                                   <AsyncSelect
                                        value={values.approved_by}
                                        name="approved_by"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'staff')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for staff ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>

                        <br/>
                        <Typography id="staff" variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Consultant</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Holiday Status</Form.Label>
                                   <AsyncSelect
                                        value={values.status}
                                        name="status"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'holidaystatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>

                        </Form>
                    </Grid>

                    
                </Grid>

            </DialogContent> 

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: lightBlue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: lightBlue[800], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Holiday</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddHolidayDialog
