import { VISITTYPES_LIST_REQUEST, VISITTYPES_LIST_SUCCESS, VISITTYPES_LIST_FAIL, VIEW_VISITTYPE_FAIL, VIEW_VISITTYPE_REQUEST, VIEW_VISITTYPE_SUCCESS, VISITTYPE_CREATE_FAIL, VISITTYPE_UPDATE_REQUEST, VISITTYPE_UPDATE_FAIL, VISITTYPE_DELETE_REQUEST, VISITTYPE_DELETE_FAIL, VISITTYPE_CREATE_REQUEST, VISITTYPE_CREATE_SUCCESS, VISITTYPE_UPDATE_SUCCESS, VISITTYPE_DELETE_SUCCESS } from "../Constants/visitTypeConstants";

const visitTypesListReducer = (state = {visitTypes: []}, action) => {

    switch (action.type)
    {
        case VISITTYPES_LIST_REQUEST:
            return {loading: true};
 
        case VISITTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and visitTypes data is:' + action.payload)
            return {
                loading: false,
                visitTypes: action.payload
            }
        case VISITTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const visitTypeCreateReducer = (state = {visitTypes: []}, action) => {

    switch (action.type) {

        case VISITTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case VISITTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                visitTypes: [...state.visitTypes, {Applicant_Id: 2, Name: value}]
            };

        case VISITTYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const visitTypeViewReducer = (state= {visitType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_VISITTYPE_REQUEST:
            return {loading: true};
        case VIEW_VISITTYPE_SUCCESS:
            return {
                    loading: false,
                    visitType: action.payload
                }
        case VIEW_VISITTYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const visitTypeUpdateReducer = (state = {visitTypes: {}}, action) => {

    switch (action.type) {

        case VISITTYPE_UPDATE_REQUEST:
            return {
                
            }
        case VISITTYPE_UPDATE_SUCCESS:

            return {

            }
        case VISITTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const visitTypeDeleteReducer = (state = {visitTypes: {}}, action) => {

    switch (action.type) {
        case VISITTYPE_DELETE_REQUEST:
            return {
                
            }
        case VISITTYPE_DELETE_SUCCESS:
            return {

            }
        case VISITTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {visitTypesListReducer, visitTypeCreateReducer, visitTypeViewReducer, visitTypeUpdateReducer, visitTypeDeleteReducer }