import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, LinearProgress, FormControlLabel, Checkbox, Box} from '@mui/material';
import { blueGrey, blue, green, lightGreen, pink, lightBlue } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import AlarmOnRoundedIcon from '@mui/icons-material/AlarmOnRounded';
import api from '../../../../../config/apiConfig';
import { getShiftsList, getShift } from '../../../../../Redux/Actions/ShiftActions';
import { submitCheckIn } from '../../../../../Redux/Actions/CheckInActions';
import {addToWorkersShifts} from './addToWorkersShifts';
import ComplexAlert from '../../../../ComplexAlert';
import useAlert from '../../../../../Hooks/useAlert';


const AddCheckInDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();  
    const {alert, showAlert}= useAlert();

    //GET SHIFTS
    const {shifts, loading} = useSelector(state => state.shifts_List);
    //GET RATES 
    const {rates, loading: loading_rates} = useSelector(state => state.rates_List);
    //GET SPECIFIC SHIFT
    const {shift, loading: loading_shift} = useSelector(state => state.shift_View);
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getShiftsList());
       
    }, [])
    

    const [values, setValues] = useState({
            
            nextDayFinish: '',
            shifts: '',
            checkinstatuses: '',
            workershiftstatuses: '',
            rates: '',
            paytypes: '',
            ratecards: '',
            timein: `${new Date().getHours()}:${new Date().getMinutes()}`,
            timeout: '',
        });

    const [submitValues, setSubmitValues] = useState({
            
            nextDayFinish: '',
            shifts: '',
            checkinstatuses: '',
            workershiftstatuses: '',
            rates: '',
            paytypes: '',
            ratecards: '',
            timein: `${new Date().getHours()}:${new Date().getMinutes()}`,
            timeout: '',
        });


    const handleChange = (e) => {

        const {name, value, checked, type} = e.target;

        setValues({
            ...values,
            [name]: value
        });
        setSubmitValues({
            ...submitValues,
            [name]: value
        })
        
     
    }

    const handleSave = () => {
        console.log('in save function')
        if(wantedShift.Division != null)
        {
            dispatch(submitCheckIn({...submitValues}));
            handleSubmit('success','Successfully added CheckIn');
            addToWorkersShifts(shift.WorkerId, shift.Shift_Status, shift.shifts)
        }
        else{ 
            showAlert('error', 'All fields should be filled', true)
        }
        setWantedShift({})

    }

    const animatedComponents = makeAnimated();


    const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {

        
        const keeper = [];

        const tableName = name == 'shifts' ? shifts : '';

        const id_field = name === 'shifts'? 'Shift_Id' : name === 'checkinstatuses'? 'Id' : name === 'workershiftstatuses'? 'Id' : name === 'paytypes'? 'Id' : name === 'rates'? 'Id' :  name === 'ratecards'? 'Id' :'';
        const name_field = name === 'shifts'? 'First_Name' :  name === 'checkinstatuses'? 'Name' : name === 'workershiftstatuses'? 'Name' : name === 'paytypes'? 'Name' : name === 'rates'? 'Name' : name === 'ratecards'? 'Name' :'';
            
        tableName.forEach(x => {
            
            if(name === 'shifts')
                {
                    keeper.push({value: x[id_field], label: `${x.Worker} - ${x.Date} - ${x.Client}`})
                }
            else{
                keeper.push({value: x[id_field], label: x[name_field]})
            } 
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['shifts', 'checkinstatuses','workershiftstatuses','paytypes','rates', 'ratecards'];
        
        const id_field = name === 'shifts'? 'Shift_Id' : name === 'checkinstatuses'? 'Id' : name === 'workershiftstatuses'? 'Id' : name === 'paytypes'? 'Id' : name === 'rates'? 'Id' :  name === 'ratecards'? 'Id' :'';
        const name_field = name === 'shifts'? 'First_Name' :  name === 'checkinstatuses'? 'Name' : name === 'workershiftstatuses'? 'Name' : name === 'paytypes'? 'Name' : name === 'rates'? 'Name' : name === 'ratecards'? 'Name' :'';
        
        names.forEach(x => x === name)
        { 
            data.forEach(x => {
                if(name === 'shifts')
                {
                    keeper.push({value: x[id_field], label: `${x.Worker} - ${x.Date} - ${x.Client}`})
                }
                else{
                    keeper.push({value: x[id_field], label: x[name_field]})

                }
                })   
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action) => {
        
        const name = action.name;

        setValues({
            ...values,
            [name]: value
        })

        setSubmitValues({
            ...submitValues,
            [name]: value.value
        })
        dispatch(getShift(value.value))

        
    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }

    const [wantedShift, setWantedShift] = useState({})
    useEffect(() => {
       if(shift)
       {
           setWantedShift({...shift})
       }
    }, [shift])
 
    return (
         
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[800]}}  >
                
                <AlarmOnRoundedIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add Check In </Typography>
            </DialogTitle>
     
            <Divider/>
            {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
            { loading? <LinearProgress sx={{ color: 'green'}} /> : ''}
        
            <DialogContent sx={{background: 'white'}}>
        
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>  
                       
                        <Grid item lg={11}>
                            <Form className="my-3">
                            <Row>
                            <Typography variant="body2" sx={{display: 'inline', fontFamily: 'Poppins' ,color: blue[50],background: lightBlue[800], borderRadius: '5px', px: 2, py: '5px', mb: 1}}>Select Shift</Typography>
                               
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <AsyncSelect
                                        value={values.shifts}
                                        name="shifts"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'shifts')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for shift ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                
                            </Row>
                            

                            <br/>
                            {
                                !submitValues.shifts ? 
                                <Typography variant="body2" sx={{display: 'block',color: blueGrey[800],background: blueGrey[50], borderRadius: '5px', px: 2, py: 3, textAlign: 'center'}}>No Shift details. Choose shift first</Typography>
                                : 
                                <Row>
                                    {loading_shift? 
                                    <Typography variant="body2" sx={{display: 'inline',color: green[800],background: green[50], borderRadius: '5px', px: 2, py: '5px'}}>Loading</Typography>

                                    : 
                                    <Grid container justifyContent="space-between" >
                                        <Grid item lg={5} sx={{height: '450px', overflow: 'hidden', overflowY: 'scroll',}}>
                                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: blue[50], borderRadius: '5px', px: 2, py: '5px'}}>Selected Shift - Details</Typography>
                                            <Box sx={{mt: 2, background: 'lightBlue[50]'}}>
                                                <Form.Label className="view-label">Shift ID</Form.Label>
                                                <Typography className="outline-none border-none" sx={{color: blueGrey[600], fontFamily: 'Poppins', fontSize: 15}}>{`SHT-${wantedShift.Shift_Id}`}</Typography>
                                                <Divider sx={{width: '75%', my: 2, color: blue[500]}}/>
                                                <Form.Label className="view-label">Name</Form.Label>
                                                <Typography className="outline-none border-none" sx={{color: blueGrey[600], fontFamily: 'Poppins', fontSize: 15}}>{`${wantedShift.First_Name} ${wantedShift.Surname} - ${wantedShift.Date} - ${wantedShift.Client}`}</Typography>
                                                <br/>
                                                <Form.Label className="view-label d-block">Shift Status</Form.Label>
                                                <Typography className="outline-none border-none" sx={{display: 'inline-block',color: 'white', fontFamily: 'Poppins', fontSize: 12, background: lightGreen[500], borderRadius: 5, px: 2, mb: 1, boxShadow: '1px 1px 5px #b0bec5'}}>{`${wantedShift.Shift_Status}`}</Typography>
                                                <br/>
                                                <Form.Label className="view-label">Date</Form.Label>
                                                <Typography className="outline-none border-none" sx={{color: blueGrey[600], fontFamily: 'Poppins', fontSize: 15}}>{wantedShift.Date}</Typography>
                                                <br/>
                                                <Form.Label className="view-label">Start Time</Form.Label>
                                                <Typography className="outline-none border-none" sx={{color: blueGrey[600], fontFamily: 'Poppins', fontSize: 15}}>{wantedShift.Start_Time}</Typography>
                                                <br/>
                                                <Form.Label className="view-label">End Time</Form.Label>
                                                <Typography className="outline-none border-none" sx={{color: blueGrey[600], fontFamily: 'Poppins', fontSize: 15}}>{wantedShift.End_Time}</Typography>
                                                <br/>
                                                <Form.Label className="view-label">Division</Form.Label>
                                                <Typography className="outline-none border-none" sx={{color: blueGrey[600], fontFamily: 'Poppins', fontSize: 15}}>{wantedShift.Division}</Typography>
                                                <br/>
                                                <Form.Label className="view-label d-block">Job Role</Form.Label>
                                                <Typography className="outline-none border-none" sx={{display: 'inline-block',color: 'white', fontFamily: 'Poppins', fontSize: 12, background: pink[500], borderRadius: 5, px: 2, mb: 1, boxShadow: '1px 1px 5px #b0bec5'}}>{wantedShift.Job_Role}</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item lg={6} sx={{background: blue[50], p: 3, borderRadius: '5px', height: '450px', overflow: 'hidden', overflowY: 'scroll',}}>
                                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: lightBlue[100], borderRadius: '5px', px: 2, py: '5px'}}>Your Check In - Details</Typography>
                                            <br/>
                                            <Row>
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                    <Form.Label className="view-label mt-4">Time In</Form.Label>
                                                    <Typography className="outline-none border-none" sx={{color: blue[900], fontFamily: 'Poppins', fontSize: 15}}>{values.timein}</Typography>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                    <Form.Label className="view-label w-100 mt-4">Next Day Finish?</Form.Label>
                                                    <FormControlLabel control={<Checkbox size="small" name="nextDayFinish" value="Yes" checked={values.nextDayFinish === 'Yes'? true : null} onChange={handleChange} />} label="Yes" />
                                                    <FormControlLabel control={<Checkbox size="small" name="nextDayFinish" value="No" checked={values.nextDayFinish === 'No'? true : null} onChange={handleChange} />} label="No" />
                                                </Form.Group>
                                            </Row>
                                            <Form className="mb-1">
                                                <Row>
                                                    
                                                    <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                        <Form.Label className="view-label">Worker Shift Status</Form.Label>
                                                        <AsyncSelect
                                                            value={values.workershiftstatuses} name="workershiftstatuses" cacheOptions loadOptions={(value, action) => loadOptions(value, 'workershiftstatuses')} defaultOptions onChange= {(value, action) => handleOptionChange(value, action)}
                                                            components={animatedComponents} placeholder="Search for status ..." onInputChange={handleInputChange}
                                                        />
                                                    </Form.Group>
                                                   
                                                </Row>
                                                <Row>
                                               
                                                </Row>
                                                <Row>
                                                    <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                        <Form.Label className="view-label">Rates</Form.Label>
                                                        <AsyncSelect
                                                            value={values.rates} name="rates" cacheOptions loadOptions={(value, action) => loadOptions(value, 'rates')} defaultOptions onChange={(value, action) => handleOptionChange(value, action)}
                                                            components={animatedComponents} placeholder="Search for rate ..." onInputChange={handleInputChange}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                        <Form.Label className="view-label">Pay Type</Form.Label>
                                                        <AsyncSelect
                                                            value={values.paytypes} name="paytypes" cacheOptions loadOptions={(value, action) => loadOptions(value, 'paytypes')} defaultOptions onChange= {(value, action) => handleOptionChange(value, action)}
                                                            components={animatedComponents} placeholder="Search for types ..." onInputChange={handleInputChange}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                        <Form.Label className="view-label">Rate Card</Form.Label>
                                                        <AsyncSelect
                                                            value={values.ratecards} name="ratecards" cacheOptions loadOptions={(value, action) => loadOptions(value, 'ratecards')} defaultOptions onChange= {(value, action) => handleOptionChange(value, action)}
                                                            components={animatedComponents} placeholder="Search for card ..." onInputChange={handleInputChange}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                        <Form.Label className="view-label">Check-In Status</Form.Label>
                                                        <AsyncSelect
                                                            value={values.checkinstatuses} name="checkinstatuses" cacheOptions loadOptions={(value, action) => loadOptions(value, 'checkinstatuses')} defaultOptions onChange= {(value, action) => handleOptionChange(value, action)}
                                                            components={animatedComponents} placeholder="Search for status ..." onInputChange={handleInputChange}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                            </Form>
                                        </Grid>
                                    </Grid>
                                    }
                                </Row>
                            }
                            
                           
                        </Form>
                        
                 

                    
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[700], '&:hover': {color: blue[900]}}} onClick={handleSave}>Check-In</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddCheckInDialog
