import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress} from '@mui/material';
import { blueGrey, cyan, blue, purple, deepPurple, red } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskIcon from '@mui/icons-material/Task';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicantsList } from '../../../../../Redux/Actions/ApplicantsActions';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getAssessmentTypesList } from '../../../../../Redux/Actions/AssessmentTypesActions';
import { getAssessmentResultsList } from '../../../../../Redux/Actions/AssessmentResultsActions';
import { getAssessmentStatusesList } from '../../../../../Redux/Actions/AssessmentStatusesActions';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { submitAssessment } from '../../../../../Redux/Actions/AssessmentsActions';



const DeleteWorkingWeekDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET ASSESSMENTS
    const assessments_ListState = useSelector(state => state.assessments_List);
    const {assessments, loading: loading_assessments} = assessments_ListState;

    //GET DIVISIONS
    const divisions_ListState = useSelector(state => state.divisions_List);
    const {divisions, loading} = divisions_ListState;

    //GET ASSESSMENT TYPE
    const types_ListState = useSelector(state => state.assessmentTypes_List);
    const {assessmentTypes, loading: loading_types} = types_ListState;

    //GET ASSESSMENT RESULT
    const results_ListState = useSelector(state => state.assessmentResults_List);
    const {assessmentResults, loading: loading_results} = results_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;

    //GET ASSESSMENT STATUS
    const statuses_ListState = useSelector(state => state.assessmentStatuses_List);
    const {assessmentStatuses, loading: loading_statuses} = statuses_ListState;

    //GET APPLICANTS
    const applicants_List_State = useSelector((state) => state.applicants_List);
    const {loading: loading_applicants, applicants} = applicants_List_State;
    
       
    //CURRENT DATE
    const d = new Date();
    const yyyy = d.getFullYear()
    const mm = d.getMonth()
    const dd = d.getDay()
    
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getApplicantsList());
        dispatch(getStaffList());
        dispatch(getAssessmentTypesList());
        dispatch(getAssessmentResultsList());
        dispatch(getAssessmentStatusesList());
       
    }, [])
    

    const [values, setValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
            getName(name, value);
            //const the_name = getName(value);
            //setValues({ applicant: the_name })
        }
        else if(name == 'type' || name == 'status' || name == 'result')
        {

            setValues({
                ...values,
                [name]: value
            });
            checkForId(name, value); 
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
 
        }
    }
    const checkForId =  (name, value) => {

        const criteria = ['type', 'status', 'result'];
        criteria.forEach(item => {
            if(item == name)
            {
                const list = name === 'type'? assessmentTypes : name === 'status' ? assessmentStatuses : assessmentResults;
                const name_field = name === 'type'? 'Type_Name' : name === 'status' ? 'Status_Name' : 'Result_Name';
                const id_field = name === 'type'? 'Type_Id' : name === 'status' ? 'Status_Id' : 'Result_Id';

                const object = list.find(type => type[name_field] == value);
                const id = object[id_field];
                console.log(`ID of${value} is: ${id}`)
                
                setSubmitValues({
                    applicant: values.applicant,
                    subject: values.subject,
                    level: values.level,
                    staff: values.staff,
                    ...submitValues,
                    [name]: id
                })
            }
        } )
        // if(criteria === 'type')
        // {
        //     const object = await types_ListState.find(type => type.Type_Name = value);
        //     const id = object.Id;

        //     setValues({
        //         ...values,
        //         [name]: id
        //     })
        // }
        // else ''
        
    }

    const getName = async (name, value) => {

        console.log('Reaching getName function and recieved:' + value)
        if(name === 'applicant')
        {
            const wanted = await applicants.find(x => x.Applicant_Id == value);
            console.log('Wanted object is: ' + wanted)
            setApplicantName(`${wanted.First_Name} ${wanted.Surname}`)
        }
        else
        {
        
            const wanted = await staff.find(x => x.Staff_Id == value);
            console.log('Wanted object is: ' + wanted)
            setStaffName(`${wanted.First_Name} ${wanted.Surname}`)

        }
    }
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitAssessment(submitValues))
        handleSubmit();

    }

    
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" >

         
            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white'}}  >
                
                <DeleteIcon fontSize="large" sx={{color: red[500], mr: 2, fontSize: '1.5rem'}}/> 
                
                    <Typography variant="h6" sx={{color: blueGrey[900],fontWeight: 700}}> Delete Task </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    
                    <Grid item lg={12} sx={{position: 'sticky', zIndex: 10}}>
                                <Typography variant="body1" sx={{color: blueGrey[900],fontWeight: 900, mb: 1, textAlign: 'center'}}>Task Summary</Typography>
                            <Grid container flexDirection="row" justifyContent="space-between" sx={{px: 3, pt: '4px', background: '#f2f7fc', boxShadow: 'none', overflowY: 'scroll', height: '120px'}}>
                                {/* <Divider sx={{border: '.5px solid gray'}}/> */}
                                <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Task ID: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`TSK - ${!loading_assessments? assessments.length + 1 : ''}`} </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Task Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.assessment_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                    <Typography variant="body2" sx={{color: values.subject? '' : blue[500], fontWeight: values.subject? 800 : 500, ml: 5}}>{values.subject? values.subject : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                    <Typography variant="body2" sx={{color: values.applicant? '' : blue[500], fontWeight: values.applicant? 800 : 500, ml: 5}}>{values.applicant? values.applicant : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Name </Typography>
                                    <Typography variant="body2" sx={{color: applicantName? '' : blue[500], fontWeight: applicantName? 800 : 500, ml: 5}}>{applicantName? applicantName : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff Name </Typography>
                                    <Typography variant="body2" sx={{color: staffName? '' : blue[500], fontWeight: staffName? 800 : 500, ml: 5}}>{staffName? staffName : 'null' }</Typography>
                                </Stack>
                                
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Task Type: </Typography>
                                    <Typography variant="body2" sx={{color: values.type? '' : blue[500], fontWeight: values.type? 800 : 500, ml: 5}}>{values.type? values.type : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Level: </Typography>
                                    <Typography variant="body2" sx={{color: values.level? '' : blue[500], fontWeight: values.level? 800 : 500, ml: 5}}>{values.level? values.level : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Task Result: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.result? 'white' : blue[500], fontSize: '.75rem', textAlign: 'left', px: values.result? '4px' : '', borderRadius: '3px' ,fontWeight: values.result? 500 : 500, background: values.result? blue[500] : ''}}>{values.result? values.result : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Task Status: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.status? 'white' : blue[500], fontSize: values.status? '.75rem' :'', textAlign: 'left', px: values.status? '4px' : '', borderRadius: '3px'  ,fontWeight: values.status? 500 : 500, background: values.status? blue[500] : ''}}>{values.status? values.status : 'null' }</Typography>
                                </Stack>
                                
                            
                            </Grid>
                        </Grid>
                    <Grid item lg={12}>
                        <Form className="mb-2">
                            <br/>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Delete Notes</Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        as="textarea" 
                                        placeholder="Write here ..." 
                                        className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                        </Form>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: red[600], borderRadius: '5px', px: 1}}>Confirm Deletion</Typography>
                        <Typography variant="h6" sx={{fontWeight: 800, mt: 1}}>Are you sure you want to delete the task below?</Typography>
                    </Grid>

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: red[600], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Delete Task</Button>
        </DialogActions>
    </Dialog>
    )
}

export default DeleteWorkingWeekDialog
