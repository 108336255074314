export const RATES_LIST_REQUEST = 'RATES_LIST_REQUEST'
export const RATES_LIST_SUCCESS = 'RATES_LIST_SUCCESS'
export const RATES_LIST_FAIL = 'RATES_LIST_FAIL'


export const RATE_CREATE_REQUEST = 'RATE_CREATE_REQUEST'
export const RATE_CREATE_SUCCESS = 'RATE_CREATE_SUCCESS'
export const RATE_CREATE_FAIL = 'RATE_CREATE_FAIL'


export const RATE_UPDATE_REQUEST = 'RATE_UPDATE_REQUEST'
export const RATE_UPDATE_SUCCESS = 'RATE_UPDATE_SUCCESS'
export const RATE_UPDATE_FAIL = 'RATE_UPDATE_FAIL'

export const RATE_DELETE_REQUEST = 'RATE_DELETE_REQUEST'
export const RATE_DELETE_SUCCESS = 'RATE_DELETE_SUCCESS'
export const RATE_DELETE_FAIL = 'RATE_DELETE_FAIL'

export const VIEW_RATE_REQUEST = 'VIEW_RATE_REQUEST'
export const VIEW_RATE_SUCCESS = 'VIEW_RATE_SUCCESS'
export const VIEW_RATE_FAIL = 'VIEW_RATE_FAIL'