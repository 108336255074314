export const WORKERSHIFTSTATUSES_LIST_REQUEST = 'WORKERSHIFTSTATUSES_LIST_REQUEST'
export const WORKERSHIFTSTATUSES_LIST_SUCCESS = 'WORKERSHIFTSTATUSES_LIST_SUCCESS'
export const WORKERSHIFTSTATUSES_LIST_FAIL = 'WORKERSHIFTSTATUSES_LIST_FAIL'


export const WORKERSHIFTSTATUS_CREATE_REQUEST = 'WORKERSHIFTSTATUS_CREATE_REQUEST'
export const WORKERSHIFTSTATUS_CREATE_SUCCESS = 'WORKERSHIFTSTATUS_CREATE_SUCCESS'
export const WORKERSHIFTSTATUS_CREATE_FAIL = 'WORKERSHIFTSTATUS_CREATE_FAIL'


export const WORKERSHIFTSTATUS_UPDATE_REQUEST = 'WORKERSHIFTSTATUS_UPDATE_REQUEST'
export const WORKERSHIFTSTATUS_UPDATE_SUCCESS = 'WORKERSHIFTSTATUS_UPDATE_SUCCESS'
export const WORKERSHIFTSTATUS_UPDATE_FAIL = 'WORKERSHIFTSTATUS_UPDATE_FAIL'

export const WORKERSHIFTSTATUS_DELETE_REQUEST = 'WORKERSHIFTSTATUS_DELETE_REQUEST'
export const WORKERSHIFTSTATUS_DELETE_SUCCESS = 'WORKERSHIFTSTATUS_DELETE_SUCCESS'
export const WORKERSHIFTSTATUS_DELETE_FAIL = 'WORKERSHIFTSTATUS_DELETE_FAIL'

export const VIEW_WORKERSHIFTSTATUS_REQUEST = 'VIEW_WORKERSHIFTSTATUS_REQUEST'
export const VIEW_WORKERSHIFTSTATUS_SUCCESS = 'VIEW_WORKERSHIFTSTATUS_SUCCESS'
export const VIEW_WORKERSHIFTSTATUS_FAIL = 'VIEW_WORKERSHIFTSTATUS_FAIL'