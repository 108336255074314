import React, {useState, useRef, useEffect} from 'react'
import PageHeader from '../../../Components/PageHeader';
import { Typography, Paper, Grid, Box, Tabs, Tab, Stack } from '@mui/material';
import {matchPath, useParams} from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import ComplexAlert from '../../../Components/ComplexAlert';
import { blueGrey, green, blue, deepOrange, red, orange, pink} from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select'; 
import makeAnimated from 'react-select/animated'
import { getWorker, getWorkers } from '../../../Redux/Actions/WorkerActions';
import ViewTitleComponent from '../../../Components/Utilities/ViewTitleComponent';
import FormHeading from '../../../Components/Utilities/FormHeading';
import Crumbs from '../../../Components/Utilities/Crumbs';
import ViewStatus from '../../../Components/Utilities/ViewStatus';
import CRUD from '../../../Components/Utilities/CRUD';
import useCRUD from '../../../Hooks/useCRUD';
import AsyncSelect from 'react-select/async';
import { getDivisions } from '../../../Redux/Actions/DivisionActions';
import { getJobRolesList } from '../../../Redux/Actions/JobRolesActions';
import { getShiftPreferencesList } from '../../../Redux/Actions/ShiftPreferenceActions';
import { getPayrollSetupsList } from '../../../Redux/Actions/PayrollSetupActions';
import { getPayrollDocTypesList } from '../../../Redux/Actions/PayrollDocTypeActions';
import { getWorkerStatusesList } from '../../../Redux/Actions/WorkerStatusActions';
import api from '../../../config/apiConfig';

const ViewWorker = () => {
 
    const {id} = useParams();
    const dispatch = useDispatch()
    
    const {isEditing, handleCreate, handleEdit, handleDelete, setIsEditing, handlePrint, alert, showAlert, dialog, showDialog, handleSubmit, closeDialog } = useCRUD();

    //GET WORKER
    const worker_records = useSelector(state => state.worker_View);
    const {loading, worker} = worker_records;

    //GET WORKERS
    const workers_ListState = useSelector(state => state.workers_List);
    const {workers, loading: loading_workers} = workers_ListState;

    //GET SHIFT PREFS
    const preferences_ListState = useSelector(state => state.shiftPreferences_List);
    const {shiftPreferences, loading: loading_preferences} = preferences_ListState;
 
    //GET DIVISIONS
    const divisions_ListState = useSelector(state => state.divisions_List);
    const {divisions, loading: loading_divisions} = divisions_ListState;

    //GET JOB ROLES
    const roles_List_State = useSelector((state) => state.jobRoles_List);
    const {loading: loading_roles, jobRoles} = roles_List_State;

    //GET PAYROLL SETUP 
    const payrollSetup_ListState = useSelector(state => state.payrollSetups_List);
    const {payrollSetups, loading: loading_setups} = payrollSetup_ListState;

    //GET PAYROLL DOC TYPE
    const docTypes_ListState = useSelector(state => state.payrollDocTypes_List);
    const {payrollDocTypes, loading: loading_docTypes} = docTypes_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;

    //GET WORKER STATUS
    const statuses_ListState = useSelector(state => state.workerStatuses_List);
    const {workerStatuses, loading: loading_statuses} = statuses_ListState;

    //GET LINKED UMBRELLAS
    const umbrellas_List_State = useSelector((state) => state.linkedUmbrellas_List);
    const {loading: loading_umbrellas, linkedUmbrellas} = umbrellas_List_State;

      //ON COMPONENT DID MOUNT
      useEffect(()=> {
        dispatch(getWorker(id));
        dispatch(getWorkers());
        dispatch(getDivisions());
        dispatch(getJobRolesList());
        dispatch(getShiftPreferencesList());
        dispatch(getPayrollSetupsList());
        dispatch(getPayrollDocTypesList());
        dispatch(getWorkerStatusesList());
    
        
    }, [])

    const [detail, setDetail] = useState();

    const [values, setValues] = useState({
        fname: loading? '' : worker.First_Name, 
        surname: loading? '' : worker.Surname, 
        dob: loading? '' : worker.DOB, 
        insurance: loading? '' : worker.Insurance_No, 
        email: loading? '' : worker.Email, 
        address1: loading? '' : worker.Address1, 
        address2: loading? '' : worker.Address2, 
        postcode: loading? '' : worker.Postcode, 
        town: loading? '' : worker.Postcode, 
        mobile: loading? '' : worker.Mobile, 
        home: loading? '' : worker.Home_Phone, 
        staff: '' , 
        division: loading? '' : worker.Division, 
    });

    

const [submitValues, setSubmitValues] = useState({
        assessment_date: '', 
        subject: '',
        applicant: '' , 
        staff: '' , 
        division: '', 
        type: '', 
        level: '', 
        result: '', 
        status: '', 
    });

    const handleChangeR = (e) => {
        const {name, value} = e.target;

        setDetail(value);
    };


const handleChange = (e) => {
    
    const {name, value} = e.target;
    console.log('Clicked on field with name: ' + name)
   
        setValues({
            ...values,
            [name]: value
        });
        
}

const [inputValue, setInputValue] = useState('');
    

const filterOptions = async (inputValue, name) => {

    
    const keeper = [];

    const tableName = name == 'divisions' ? divisions : name == 'jobroles' ? jobRoles : name == 'staff' ? staff : name == 'shiftpreferences' ? shiftPreferences : name == 'payrollsetups' ? payrollSetups : name == 'payrolldoctypes' ? payrollDocTypes : name == 'workerstatuses' ? workerStatuses : name == 'linkedumbrellas' ? linkedUmbrellas :'' ;

    const id_field = name === 'divisions' ? 'Division_Id': name === 'staff' ? 'Staff_Id' : name === 'jobroles'? 'Role_Id' : name === 'shiftpreferences'? 'Preference_Id': name === 'payrollsetups'? 'Setup_Id' : name === 'payrolldoctypes'? 'Type_Id' : name === 'workerstatuses'? 'Status_Id' : name === 'linkedumbrellas'? 'Umbrella_Id': '';
    const name_field = name === 'divisions' ? 'Name': name === 'staff' ? 'First_Name' : name === 'jobroles'? 'Name' : name === 'shiftpreferences'? 'Name': name === 'payrollsetups'? 'Setup_Name': name === 'payrolldoctypes'? 'Type_Name' : name === 'workerstatuses'? 'Status_Name' : name === 'linkedumbrellas'? 'Umbrella_Name':  '';
        
    tableName.forEach(x => {
        keeper.push({value: x[id_field], label: x[name_field]})
        })
    

    const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
    console.log('FILTER LEFT:' + filtered)
    
    return filtered;
    
}

const loadOptions =  async (inputValue, name) => {
    
    const {data} = await api.get(`/${name}`);
    const keeper = [];
    const names = ['divisions', 'staff', 'jobroles', 'shiftpreferences', 'payrollsetups', 'payrolldoctypes', 'workerstatuses', 'linkedumbrellas'];
    
    const id_field = name === 'divisions' ? 'Division_Id': name === 'staff' ? 'Staff_Id' : name === 'jobroles'? 'Role_Id' : name === 'shiftpreferences'? 'Preference_Id': name === 'payrollsetups'? 'Setup_Id' : name === 'payrolldoctypes'? 'Type_Id' : name === 'workerstatuses'? 'Status_Id' : name === 'linkedumbrellas'? 'Umbrella_Id': '';
    const name_field = name === 'divisions' ? 'Name': name === 'staff' ? 'FullName' : name === 'jobroles'? 'Name' : name === 'shiftpreferences'? 'Name': name === 'payrollsetups'? 'Setup_Name': name === 'payrolldoctypes'? 'Type_Name' : name === 'workerstatuses'? 'Status_Name' : name === 'linkedumbrellas'? 'Umbrella_Name':  '';
    
    names.forEach(x => x === name)
    {
        
        data.forEach(x => {
            keeper.push({value: x[id_field], label: x[name_field]})
           })
    }
    
    if(inputValue === '')
    {
        return keeper;
    }
    else{

        return filterOptions(inputValue, name)
    }
};
const handleOptionChange = (value, action) => {
    
    const name = action.name;

    setValues({
        ...values,
        [name]: value
    })

    setSubmitValues({
        ...submitValues,
        [name]: value.value
    })
    
}
const handleInputChange = (newValue) => {

    const inputValue = newValue.replace(/\W/g, '');
    setInputValue(inputValue);
    console.log('Input value is:' + inputValue)
    return inputValue
}


    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 0 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
    
    const [tabValue, setTabValue] = React.useState(0);
    
      const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };


      const animatedComponents = makeAnimated();

     

    return (
        <>
            <PageHeader page="Worker Details" previous workers />
            <Crumbs root="CRM" middle="workers" end="Details"/>

            <Grid container justifyContent="space-between" alignItems= "center"  sx={{mt: 2, mb: 2, px:0, borderRadius: '20px', color: 'gray', background: ''}}>
                <ViewStatus/>
                <CRUD isEditing={isEditing} handleCreate={handleCreate} handleEdit={handleEdit} handleDelete={handleDelete} setIsEditing={setIsEditing} showAlert ={showAlert } handlePrint/>
            </Grid>
              

            <Grid container  alignItems="" justifyContent="space-between" sx={{mt: 3, mb: 0, background: ''}}>
                
                <Grid item lg={12} sx={{mb: 0, px: 0, background: 'white', borderRadius: '10px 10px 0 0'}}>
                    
                    <ViewTitleComponent title={loading? '' : `${worker.First_Name} ${worker.Surname}`} picture/>
                               
                    
                    <Grid container sx={{background: '', px: 2, mt: 2, borderRadius: '5px 5px 0 0', background: '',}}>
                        <Grid item lg={12} sx={{mt: 1}}>
                            <Grid container>
                                <FormHeading title="Hot Points" badge="Info" icon=""/>
                                
                                <Paper sx={{mt: 3,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid #e3f2fd',width: '100%'}}> 
                                    <Grid container justifyContent="space-around">
                                        <Stack sx={{mt: 0}}>
                                            <Typography sx={{fontSize: 14 ,fontWeight: 500, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blue[500]}}>Division</Typography>
                                            <Typography sx={{fontSize: 14 ,fontWeight: 600, fontFamily: 'Poppins-Medium', py: '', px: 0, color:  blueGrey[900], mt: 1}}>{!loading? worker.Division: ''}</Typography>
                                        </Stack>
                                        <Stack sx={{mt: 0}}>
                                            <Typography sx={{fontSize: 14 ,fontWeight: 500, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blue[500]}}>Job Roles</Typography>
                                            <Typography sx={{fontSize: 14 ,fontWeight: 600, fontFamily: 'Poppins-Medium', py: '', px: 0, color:  blueGrey[900], mt: 1}}>{!loading? worker.JobRole : ''}</Typography>
                                        </Stack>
                                        <Stack sx={{mt: 0}}>
                                            <Typography sx={{fontSize: 14 ,fontWeight: 500, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blue[500]}}>Company</Typography>
                                            <Typography sx={{fontSize: 14 ,fontWeight: 600, fontFamily: 'Poppins-Medium', py: '', px: 0, color:  blueGrey[900], mt: 1}}>{!loading? worker.Company_Name : ''}</Typography>
                                        </Stack>
                                        <Stack sx={{mt: 0}}>
                                            <Typography sx={{fontSize: 14 ,fontWeight: 500, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blue[500]}}>Current Tasks</Typography>
                                            <Typography sx={{fontSize: 14 ,fontWeight: 600, fontFamily: 'Poppins-Medium', py: '', px: 0, color:  blueGrey[900], mt: 1}}><CircleIcon sx={{color: 'orange', fontSize: 10, mr: 1}} fontSize="small"/>{!loading && worker.Task? worker.Task : '' }</Typography>
                                        </Stack>
                                        <Stack sx={{mt: 0}}>
                                            <Typography sx={{fontSize: 14 ,fontWeight: 500, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blue[500]}}>Status</Typography>
                                            <Typography sx={{fontSize: 14 ,fontWeight: 600, fontFamily: 'Poppins-Medium', py: '', px: 0, color:  green[600], mt: 1}}>{!loading? worker.Status_Name: ''}</Typography>
                                        </Stack>
                                        
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-start" alignItems="center" sx={{background: blue[50], px: 2, mt: 3, borderRadius: '0'}}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" sx={{p: 0}}>
                            <Tab label="Information"/>
                            <Tab label={`Current Tasks`}  />
                            <Tab label="Payslips"  />
                            <Tab label="Shifts"  />
                            <Tab label="Other"  />
                        </Tabs>
                    </Grid>
                    
                    
                    <Grid container sx={{mt: 2, px: 2}}>
                            <Grid item lg={12} sx={{mt: 1}}>
                               
                                <FormHeading title="Basic Details" badge="Info" icon=""/>
                                <Paper sx={{mt: 2,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                    
                                    <Form className="">
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridEmail" >
                                                <Form.Label className="view-label mb-3">Worker ID</Form.Label>
                                                <Form.Control disabled type="text" value={`WRK-${id}`} placeholder="E.g 123qst1" className={`${isEditing? '' : 'border-0  '}`} />
                                            </Form.Group>
                                            <h6>First name: {values.fname}</h6>
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label">First Name</Form.Label>
                                                <Form.Control 
                                                    name="fname" 
                                                    onChange={handleChange}  
                                                    //disabled={isEditing? false : true} 
                                                    type="text" 
                                                    value={loading? '' : worker.First_Name}
                                                    className={isEditing? "" :"border-0  autofocus"} 
                                                />
                                                
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label">Surname</Form.Label>
                                                <Form.Control 
                                                    name="surname" 
                                                    disabled={isEditing? false : true} 
                                                    onChange={handleChange}
                                                    type="text" 
                                                    value={loading? '' : worker.Surname} 
                                                    placeholder="E.g 123qst1" 
                                                    className={isEditing? "" :"border-0  autofocus"} />
                                            </Form.Group>
                                            
                                        </Row>
                                        <Row className="mb-3">

                                            <Form.Group as={Col} controlId="formGridEmail" >
                                                <Form.Label className="view-label mb-3">Email</Form.Label>
                                                <Form.Control autoFocus={isEditing? true : false} disabled type="email" value={loading? '' : worker.Email} placeholder="E.g John" className={`${isEditing? '' : 'border-0 '}`} />
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Paper>

                                <FormHeading title="Other Details" badge="" icon="circle"/>
                                <Paper sx={{mt: 0,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                            
                                    <Form>
                                    
                                        <Row className="mt-3">
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label mb-3">Division</Form.Label>
                                                <br/>
                                                {
                                                    isEditing? 
                                                    <AsyncSelect
                                                        value={values.divisions}
                                                        name="divisions"
                                                        cacheOptions
                                                        loadOptions={(value, action) => loadOptions(value, 'divisions')}
                                                        defaultOptions
                                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                                        components={animatedComponents}
                                                        placeholder="Search for division ..."
                                                        onInputChange={handleInputChange}
                                                    />    :
                                                <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Construction</Typography>
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label mb-3">Consultant</Form.Label>
                                                <br/>
                                                {
                                                    isEditing? 
                                                    <AsyncSelect
                                                        value={values.staff}
                                                        name="staff"
                                                        cacheOptions
                                                        loadOptions={(value, action) => loadOptions(value, 'staff')}
                                                        defaultOptions
                                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                                        components={animatedComponents}
                                                        placeholder="Search for consultant ..."
                                                        onInputChange={handleInputChange}
                                                    />   :
                                                <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: blue[500], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `${worker.Consultant}`}</Typography>
                                                }
                                            </Form.Group>
                                        

                                        </Row>
                                        <Row className="mt-3">
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label mb-3">Worker Type</Form.Label>
                                                <br/>
                                                {
                                                    isEditing? 
                                                    <AsyncSelect
                                                        value={values.staff}
                                                        name="staff"
                                                        cacheOptions
                                                        loadOptions={(value, action) => loadOptions(value, 'staff')}
                                                        defaultOptions
                                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                                        components={animatedComponents}
                                                        placeholder="Search for consultant ..."
                                                        onInputChange={handleInputChange}
                                                    />   :
                                                <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: deepOrange[400], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : worker.Type_Name}</Typography>
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label mb-3">Worker Status</Form.Label>
                                                <br/>
                                                {
                                                    isEditing? 
                                                    <AsyncSelect
                                                        value={values.workerstatuses}
                                                        name="workerstatuses"
                                                        cacheOptions
                                                        loadOptions={(value, action) => loadOptions(value, 'workerstatuses')}
                                                        defaultOptions
                                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                                        components={animatedComponents}
                                                        placeholder="Search for status ..."
                                                        onInputChange={handleInputChange}
                                                    />  :
                                                <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : worker.Status_Name}</Typography>
                                                }
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Paper>

                                <FormHeading title="More Details" badge="" icon="circle"/>
                                <Paper sx={{mt: 2,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                        <Form>
                                        <Row className="mt-3">
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label mb-3">Worker Result</Form.Label>
                                                <br/>
                                                {
                                                    isEditing? 
                                                        <Select 
                                                            value={workers[0]}
                                                            options={workers}
                                                            closeMenuOnSelect ={false}
                                                            components={animatedComponents}
                                                            placeholder="Select result ..."
                                                        />  :
                                                <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : worker.Result_Name}</Typography>
                                                }
                                            </Form.Group>
                                        </Row>
                                        
                                    </Form>
                                </Paper>
                            </Grid>
                        </Grid>
                    
                    <TabPanel value={tabValue} index={1}>
                        <Grid container sx={{mt: 2, px: 2}}>
                            <Grid item lg={12} sx={{mt: 1}}>
                                <FormHeading title="Current Tasks" badge="Info" icon=""/>
                                <Paper sx={{mt: 2,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                    <Form>
                                        <Row className="mt-3">
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label mb-3">Tasks</Form.Label>
                                                <br/>
                                                <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: orange[600], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `TSK -${worker.Task}`}</Typography>
                                            </Form.Group>
                                           
                                        </Row>
                                    </Form>
                                </Paper>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <Grid container sx={{mt: 1, px: 2}}>
                            <Grid item lg={12} sx={{mt: 0}}>
                                <FormHeading title="Pay Slips" badge="" icon="circle"/>
                                <Paper sx={{mt: 2,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                    <Form>
                                        <Row className="mt-0">
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label mb-3">Payments</Form.Label>
                                                <br/>
                                               
                                                <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: pink[400], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : worker.Type_Name}</Typography>
                                            </Form.Group>
                                           
                                        </Row>
                                    </Form>
                                </Paper>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <Grid container sx={{mt: 1, px: 2}}>
                            <Grid item lg={12} sx={{mt: 0}}>
                                <FormHeading title="Shifts" badge="" icon="circle"/>
                                <Paper sx={{mt: 2,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                    <Form>
                                        <Row className="mt-0">
                                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                                <Form.Label className="view-label mb-3">Shifts</Form.Label>
                                                <br/>
                                                <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: blueGrey[800], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `SHT - ${worker.Shift}`}</Typography>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Paper>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid>

               

            </Grid>

            <Grid container spacing={1} sx={{mt: 3, mb: 1, background: ''}}>

        <Grid item lg={12} sx={{mb: 0, pb: 0}}>


        </Grid>

        {/* <Grid item lg={9} sx={{mt: 1}}>
                <Paper sx={{mt: 0,p: 4, background: 'white', boxShadow: 'none'}}> 
                    <Stack direction="row" alignItems="center" sx={{mb: 3}}>
                        <Typography variant="h5" sx={{fontWeight: 900}}>Details</Typography>
                        <Typography variant="" sx={{fontSize: '1rem', fontWeight: 700, px: 1, py: 0,ml: 1,color: 'white', background: blue[200], borderRadius: '5px'}}>Info</Typography>

                    </Stack>
                    <Form className="">
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="border border-1 border-light rounded p-1">
                                <Form.Label className="">Applicant ID</Form.Label>
                                <Form.Control disabled type="email" value={values.id} placeholder="E.g 123qst1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="border border-1 border-light rounded p-1">
                                <Form.Label>Applicant Name</Form.Label>
                                <Form.Control  value={values.name} disabled={isEditing? false : true} type="email" placeholder="Enter applicant's name" />
                            </Form.Group>
                            
                        </Row>
                        <Row className="mt-1">
                        
                            <Form.Group as={Col} controlId="formGridEmail" className="border border-1 border-light rounded p-1">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" value={values.fname} placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="border border-2 border-light rounded p-1">
                                <Form.Label>Surname</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" value={values.lname} placeholder="Enter email" />
                            </Form.Group>
                        </Row>

                        <Row className="mt-1">
                        
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Home Phone</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                        </Row>
                    </Form>
                </Paper>

                <Paper sx={{mt: 1,p: 4, background: 'white', boxShadow: 'none'}}> 
                <Typography variant="h5" sx={{fontWeight: 900, mb: 3}}>Job Specifics</Typography>
                    <Form>
                    
                        <Row className="mt-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Division</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Job</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>

                        </Row>
                        <Row className="mt-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Applicant Rating</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Applicant Prospect</Form.Label>
                                <Form.Control disabled={isEditing? false : true} type="email" placeholder="Enter email" />
                            </Form.Group>
                        </Row>
                    </Form>
                </Paper>

                <Paper sx={{mt: 1,p: 4, background: 'white', boxShadow: 'none'}}> 
                <Typography variant="h5" sx={{fontWeight: 900, mb: 3}}>Applicant Specifics</Typography>
                    <Form>
                    
                        <Row className="mt-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Applicant Status</Form.Label>
                                <Form.Control disabled type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Worker</Form.Label>
                                <Form.Control disabled type="email" placeholder="Enter email" />
                            </Form.Group>

                        </Row>
                    
                    </Form>
                </Paper>

        </Grid>
        <Grid item lg={3} sx={{mt: 1}}>
            <Paper sx={{mt: 0,py: 2, background: 'white', boxShadow: 'none', textAlign: 'center'}}>
                <Typography variant="h6" sx={{fontWeight: 700, py: 1, px: 0,color: blueGrey[700], background: 'blueGrey[200]'}}>Applicant History</Typography>
                <Divider/>
                <br/>
                <Typography variant="p" sx={{fontSize: '.8rem',my: 3, fontWeight: 700, py: 1, px: 1,color: blueGrey[50], background: blueGrey[500], borderRadius: '5px'}}>No Previous History</Typography>

            </Paper>

            <Paper sx={{mt: 2,py: 1, background: 'white', boxShadow: 'none', textAlign: 'center'}}>
                <Typography variant="h6" sx={{fontWeight: 700, py: 1, px: 0,color: blueGrey[900], background: 'blueGrey[200]'}}> Other I.T Vacancies</Typography>
                <Divider/>
                <br/>
                <List sx={{ textAlign: 'left', p: 2}}>
                    <ListItem>
                        <ListItemIcon>
                                <InfoIcon/>
                        </ListItemIcon>
                        <ListItemText primary="React Developer" sx={{m: 0, p: 0}}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                                <InfoIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Javascript Developer" sx={{m: 0, p: 0}}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                                <InfoIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Senior React Frontend Developer" sx={{m: 0, p: 0}}/>
                    </ListItem>
                </List>

            </Paper>
        </Grid>
 */}

        </Grid>
        </>
    )
}

export default ViewWorker

{/* <Grid item sx={{pr: 4, borderRight: '1px solid #eceff1'}}>
<Stack sx={{ml: 2}}>
    <Typography variant="h1" sx={{fontSize: '1.7rem',fontWeight: 800, py: '', px: 0,color: 'blueGrey[800]', background: 'blueGrey[200]'}}>{values ? values.name : 'Employee Name'}</Typography>
        <Divider orientation="vertical" variant="middle" />
    <Stack direction="row" justifyContent="space-between" sx={{mt: '7px'}}>
            <Box sx={{px:0, py: 0, display: 'flex', justifyContent: "space-around", alignItems: "center", background: '', borderRadius: '10px'}}>
                <Typography variant="" sx={{fontSize: '', fontWeight: 500, py: 0, pl: 0,color: blueGrey[700], background: 'blueGrey[200]'}}>{headerFor == 'worker-details' ? 'Department:' : 'Tag:'}</Typography>
                <Typography variant="" sx={{fontSize: '.7rem', fontWeight: 500, px: 1, py: 0,ml: 1,color: blueGrey[50], background: 'dodgerblue', borderRadius: '10px'}}>{headerFor == 'worker-details' ? 'Employee Department' : 'Immediate Hire'}</Typography>
            </Box>
    </Stack>

    
</Stack>
</Grid> */}