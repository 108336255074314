import { BOOKINGS_LIST_REQUEST, BOOKINGS_LIST_SUCCESS, BOOKINGS_LIST_FAIL, VIEW_BOOKING_FAIL, VIEW_BOOKING_REQUEST, VIEW_BOOKING_SUCCESS, BOOKING_CREATE_REQUEST, BOOKING_CREATE_SUCCESS, BOOKING_CREATE_FAIL, BOOKING_UPDATE_FAIL, BOOKING_UPDATE_REQUEST, BOOKING_UPDATE_SUCCESS } from '../Constants/bookingConstants';
import api from '../../config/apiConfig';


const getBookingsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: BOOKINGS_LIST_REQUEST});
        const {data} = await api.get('/bookings');
        // console.log('Bookings data is:' + data[0]['Booking_Name'])
        dispatch({type: BOOKINGS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: BOOKINGS_LIST_FAIL, payload: error})
    }
} 

const submitBooking = (stateValues, submitValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: BOOKING_CREATE_REQUEST});
        
        const {data} = await api.post(`/bookings/`, submitValues);
        console.log('Booking data is:' + data)
        dispatch({type: BOOKING_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: BOOKING_CREATE_FAIL, payload: error})
    }
} 

const getBooking = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_BOOKING_REQUEST});
        
        const {data} = await api.get(`/bookings/ ${id}`);
        console.log('Booking data is:' + data)
        dispatch({type: VIEW_BOOKING_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_BOOKING_FAIL, payload: error})
    }
}

const editBooking = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: BOOKING_UPDATE_REQUEST});
        
        const {data} = await api.put(`/bookings/`, values);
        console.log('Booking data is:' + data)
        dispatch({type: BOOKING_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: BOOKING_UPDATE_FAIL, payload: error})
    }
}



export {getBookingsList, submitBooking, getBooking, editBooking }