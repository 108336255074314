import React, {useState} from 'react';
import AddShiftDialog from './AddShiftDialog';
import ViewShiftDialog from './ViewShiftDialog';
import DeleteShiftDialog from './DeleteShiftDialog';



const ShiftDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        { 
            type === "create"?

            <AddShiftDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />
            
            : type === "view"?

            <ViewShiftDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />

            : type === "delete"?

            <DeleteShiftDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default ShiftDialogs
