import { WORKERS_LIST_REQUEST, WORKERS_LIST_SUCCESS, WORKERS_LIST_FAIL, VIEW_WORKER_REQUEST, WORKER_CREATE_FAIL, WORKER_CREATE_SUCCESS, VIEW_WORKER_SUCCESS, VIEW_WORKER_FAIL, WORKER_UPDATE_REQUEST, WORKER_UPDATE_SUCCESS, WORKER_UPDATE_FAIL, WORKER_DELETE_REQUEST, WORKER_DELETE_SUCCESS, WORKER_DELETE_FAIL, WORKER_CREATE_REQUEST } from "../Constants/workerConstants";

const workersListReducer = (state = {workers: []}, action) => {

    switch(action.type)
    {
        case WORKERS_LIST_REQUEST: 
            return {loading: true}

        case WORKERS_LIST_SUCCESS: 
        //console.log('in reducer returning workers:' + action.payload)
        const new_state = action.payload;
            return {...state,
                    loading: false, 
                    workers: action.payload
                }

        case WORKERS_LIST_FAIL: 
            return {error: action.payload}
        
        default:
            return state

    }

}

const workerCreateReducer = (state = {workers: []}, action) => {

    switch (action.type) {

        case WORKER_CREATE_REQUEST:
            return {
                save_loading: true
            }

        case WORKER_CREATE_SUCCESS: 
        //console.log('Previous workers state from reducer: ' + state.workers);
        const value = action.payload;
            return {
                workers: [...state.workers, {Division_Id: 5, Name: value}]
            };

        case WORKER_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const workerViewReducer = (state = {worker: {}}, action) => {

    switch (action.type) {

        case VIEW_WORKER_REQUEST:
            return {
                loading: true,
            }

        case VIEW_WORKER_SUCCESS: 
            console.log('In reducer, have received: ' + action.payload)
            return {
                loading: false,
                worker: action.payload
            };

        case VIEW_WORKER_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const workerUpdateReducer = (state = {workers: {}}, action) => {

    switch (action.type) {

        case WORKER_UPDATE_REQUEST:
            return {
                
            }
        case WORKER_UPDATE_SUCCESS:

            return {

            }
        case WORKER_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const workerDeleteReducer = (state = {workers: {}}, action) => {

    switch (action.type) {
        case WORKER_DELETE_REQUEST:
            return {
                
            }
        case WORKER_DELETE_SUCCESS:
            return {

            }
        case WORKER_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}




export {workersListReducer, workerCreateReducer, workerViewReducer, workerUpdateReducer, workerDeleteReducer}