import { SITESTATUSES_LIST_REQUEST, SITESTATUSES_LIST_SUCCESS, SITESTATUSES_LIST_FAIL, VIEW_SITESTATUS_FAIL, VIEW_SITESTATUS_REQUEST, VIEW_SITESTATUS_SUCCESS, SITESTATUS_CREATE_FAIL, SITESTATUS_UPDATE_REQUEST, SITESTATUS_UPDATE_FAIL, SITESTATUS_DELETE_REQUEST, SITESTATUS_DELETE_FAIL, SITESTATUS_CREATE_REQUEST, SITESTATUS_CREATE_SUCCESS, SITESTATUS_UPDATE_SUCCESS, SITESTATUS_DELETE_SUCCESS } from "../Constants/siteStatusConstants";

const siteStatusesListReducer = (state = {siteStatuses: []}, action) => {

    switch (action.type)
    {
        case SITESTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case SITESTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and siteStatuss data is:' + action.payload)
            return {
                loading: false,
                siteStatuses: action.payload
            }
        case SITESTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const siteStatusCreateReducer = (state = {siteStatuses: []}, action) => {

    switch (action.type) {

        case SITESTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case SITESTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                siteStatuses: [...state.siteStatuses, {Applicant_Id: 2, Name: value}]
            };

        case SITESTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const siteStatusViewReducer = (state= {siteStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_SITESTATUS_REQUEST:
            return {loading: true};
        case VIEW_SITESTATUS_SUCCESS:
            return {
                    loading: false,
                    siteStatus: action.payload
                }
        case VIEW_SITESTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const siteStatusUpdateReducer = (state = {siteStatuss: {}}, action) => {

    switch (action.type) {

        case SITESTATUS_UPDATE_REQUEST:
            return {
                
            }
        case SITESTATUS_UPDATE_SUCCESS:

            return {

            }
        case SITESTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const siteStatusDeleteReducer = (state = {siteStatuss: {}}, action) => {

    switch (action.type) {
        case SITESTATUS_DELETE_REQUEST:
            return {
                
            }
        case SITESTATUS_DELETE_SUCCESS:
            return {

            }
        case SITESTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {siteStatusesListReducer, siteStatusCreateReducer, siteStatusViewReducer, siteStatusUpdateReducer, siteStatusDeleteReducer }