import { ASSESSMENTS_LIST_REQUEST, ASSESSMENTS_LIST_SUCCESS, ASSESSMENTS_LIST_FAIL, ASSESSMENT_CREATE_SUCCESS, ASSESSMENT_CREATE_FAIL, ASSESSMENT_UPDATE_REQUEST, ASSESSMENT_UPDATE_SUCCESS, ASSESSMENT_UPDATE_FAIL, ASSESSMENT_DELETE_REQUEST, ASSESSMENT_DELETE_SUCCESS, ASSESSMENT_DELETE_FAIL, ASSESSMENT_CREATE_REQUEST, VIEW_ASSESSMENT_REQUEST, VIEW_ASSESSMENT_SUCCESS, VIEW_ASSESSMENT_FAIL } from "../Constants/assessmentConstants";

const assessmentsListReducer = (state = {assessments: []}, action) => {

    switch(action.type)
    {
        case ASSESSMENTS_LIST_REQUEST: 
            return {
                loading: true
            } 
        case ASSESSMENTS_LIST_SUCCESS: 
        console.log('Reached the success route in reducer for assessments fetch with:' + (action.payload).length)
            return {
                    loading: false, 
                    assessments: action.payload
                }
        case ASSESSMENTS_LIST_FAIL: 
            return {error: action.payload}
        
        default:
            return state

    }

}

const assessmentCreateReducer = (state = {assessments: []}, action) => {

    switch (action.type) {

        case ASSESSMENT_CREATE_REQUEST:
            return {
                loading: true
            }

        case ASSESSMENT_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                assessments: [...state.assessments, {Assessment_Id: 2, Name: value}]
            };

        case ASSESSMENT_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const assessmentViewReducer = (state = {assessment: {}}, action) => {

    switch (action.type) {

        case VIEW_ASSESSMENT_REQUEST:
            return {
                loading: true,
            }

        case VIEW_ASSESSMENT_SUCCESS: 
            console.log('In reducer, have received: ' + action.payload)
            return {
                loading: false,
                assessment: action.payload
            };

        case VIEW_ASSESSMENT_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const assessmentUpdateReducer = (state = {assessments: {}}, action) => {

    switch (action.type) {

        case ASSESSMENT_UPDATE_REQUEST:
            return {
                
            }
        case ASSESSMENT_UPDATE_SUCCESS:

            return {

            }
        case ASSESSMENT_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const assessmentDeleteReducer = (state = {assessments: {}}, action) => {

    switch (action.type) {
        case ASSESSMENT_DELETE_REQUEST:
            return {
                
            }
        case ASSESSMENT_DELETE_SUCCESS:
            return {

            }
        case ASSESSMENT_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}




export {assessmentsListReducer, assessmentCreateReducer, assessmentViewReducer, assessmentUpdateReducer, assessmentDeleteReducer}