import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress} from '@mui/material';
import { blueGrey, orange, blue, purple, red } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';



const EditApplicantDialog = ({open, onClose, type, info, handleSubmit}) => {



    
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" >


            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white'}} className="dialog-bg"  >
                <Stack direction="row">
                    <AssessmentIcon sx={{color: blue[50], mr: 2, fontSize: '1.5rem'}}/>
                    <Typography variant="h6" sx={{color: blueGrey[50],fontWeight: 700}}>EDIT</Typography>
                </Stack>
                <Typography variant="body2" sx={{color: "white",fontWeight: 500, background: blue[500], borderRadius: 3, px: 1}}>Client-Passed</Typography>
            </DialogTitle>
    
        <Divider/>
        
        

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} ><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: 'blue', '&:hover': {color: blueGrey[900]}}}>Save Changes</Button>
        </DialogActions>
    </Dialog>
    )

    }
export default EditApplicantDialog
