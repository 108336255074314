export const PAYMENTSTATUSES_LIST_REQUEST = 'PAYMENTSTATUSES_LIST_REQUEST'
export const PAYMENTSTATUSES_LIST_SUCCESS = 'PAYMENTSTATUSES_LIST_SUCCESS'
export const PAYMENTSTATUSES_LIST_FAIL = 'PAYMENTSTATUSES_LIST_FAIL'


export const PAYMENTSTATUS_CREATE_REQUEST = 'PAYMENTSTATUS_CREATE_REQUEST'
export const PAYMENTSTATUS_CREATE_SUCCESS = 'PAYMENTSTATUS_CREATE_SUCCESS'
export const PAYMENTSTATUS_CREATE_FAIL = 'PAYMENTSTATUS_CREATE_FAIL'


export const PAYMENTSTATUS_UPDATE_REQUEST = 'PAYMENTSTATUS_UPDATE_REQUEST'
export const PAYMENTSTATUS_UPDATE_SUCCESS = 'PAYMENTSTATUS_UPDATE_SUCCESS'
export const PAYMENTSTATUS_UPDATE_FAIL = 'PAYMENTSTATUS_UPDATE_FAIL'

export const PAYMENTSTATUS_DELETE_REQUEST = 'PAYMENTSTATUS_DELETE_REQUEST'
export const PAYMENTSTATUS_DELETE_SUCCESS = 'PAYMENTSTATUS_DELETE_SUCCESS'
export const PAYMENTSTATUS_DELETE_FAIL = 'PAYMENTSTATUS_DELETE_FAIL'

export const VIEW_PAYMENTSTATUS_REQUEST = 'VIEW_PAYMENTSTATUS_REQUEST'
export const VIEW_PAYMENTSTATUS_SUCCESS = 'VIEW_PAYMENTSTATUS_SUCCESS'
export const VIEW_PAYMENTSTATUS_FAIL = 'VIEW_PAYMENTSTATUS_FAIL'