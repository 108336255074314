import React, { useState } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, Breadcrumbs} from '@mui/material';
import { blueGrey, pink, lightBlue } from '@mui/material/colors';

const SummaryBox = ({values}) => {
    const [summary, setSummary] = useState(false)

    const handleSummary = () => {
        setSummary(!summary);
    }

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    return (
        <>
            <Grid item lg={12} sx={{position: 'sticky', zIndex: 10}}>
                        <Grid item lg={12} sx={{m: 0, p: 0, background: ''}}>
                            <Stack direction="row" alignItems="center" sx={{ background: 'lightBlue[50]', mb: 0}}>
                                <Typography variant="body1" sx={{color: lightBlue[800],fontWeight: 900, textAlign: 'center'}}>Form Summary</Typography>
                                <Button variant="text" size="small" sx={{fontSize: 12, color: lightBlue[900], textAlign: 'center'}} onClick={handleSummary}>{summary? 'Close' : 'Open'}</Button>
                            </Stack>
                        </Grid>
                                
                        <Grid container flexDirection="row" justifyContent="space-between" className={`summary ${summary? 'open' : ''}`} sx={{px: 3, pt: '2px', background: '#f2f7fc', border: '2px solid whitesmoke', borderRadius: 3, boxShadow: 'none', overflowY: 'scroll'}}>
                                {/* <Divider sx={{border: '.5px solid gray'}}/> */}
                                {/* <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff ID: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`OFS - ${!loading_staff? staff.length + 1 : ''}`} </Typography>
                                </Stack> */}
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.assessment_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                    <Typography variant="body2" sx={{color: values.subject? '' : lightBlue[500], fontWeight: values.subject? 800 : 500, ml: 5}}>{values.subject? values.subject : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                    <Typography variant="body2" sx={{color: values.applicant? '' : lightBlue[500], fontWeight: values.applicant? 800 : 500, ml: 5}}>{values.applicant? values.applicant : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Name </Typography>
                                    <Typography variant="body2" sx={{color: applicantName? '' : lightBlue[500], fontWeight: applicantName? 800 : 500, ml: 5}}>{applicantName? applicantName : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Consultant Name </Typography>
                                    <Typography variant="body2" sx={{color: staffName? '' : lightBlue[500], fontWeight: staffName? 800 : 500, ml: 5}}>{staffName? staffName : 'null' }</Typography>
                                </Stack>
                                
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Worker Type: </Typography>
                                    <Typography variant="body2" sx={{color: values.type? '' : lightBlue[500], fontWeight: values.type? 800 : 500, ml: 5}}>{values.type? values.type : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Level: </Typography>
                                    <Typography variant="body2" sx={{color: values.level? '' : lightBlue[500], fontWeight: values.level? 800 : 500, ml: 5}}>{values.level? values.level : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Worker Result: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.result? 'white' : lightBlue[500], fontSize: '.75rem', textAlign: 'left', px: values.result? '4px' : '', borderRadius: '3px' ,fontWeight: values.result? 500 : 500, background: values.result? lightBlue[500] : ''}}>{values.result? values.result : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Worker Status: </Typography>
                                </Stack>
                                {/* <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.status? 'white' : lightBlue[500], fontSize: values.status? '.75rem' :'', textAlign: 'left', px: values.status? '4px' : '', borderRadius: '3px'  ,fontWeight: values.status? 500 : 500, background: values.status? lightBlue[500] : ''}}>{values.status? values.status : 'null' }</Typography>
                                </Stack>
                                 */}
                            
                            </Grid>
                    </Grid>
        </>
    )
}

export default SummaryBox
