export const WORKINGWEEKSTATUSES_LIST_REQUEST = 'WORKINGWEEKSTATUSES_LIST_REQUEST';
export const WORKINGWEEKSTATUSES_LIST_SUCCESS = 'WORKINGWEEKSTATUSES_LIST_SUCCESS';
export const WORKINGWEEKSTATUSES_LIST_FAIL = 'WORKINGWEEKSTATUSES_LIST_FAIL'; 

export const WORKINGWEEKSTATUS_CREATE_REQUEST = 'WORKINGWEEKSTATUS_CREATE_REQUEST'
export const WORKINGWEEKSTATUS_CREATE_SUCCESS = 'WORKINGWEEKSTATUS_CREATE_SUCCESS'
export const WORKINGWEEKSTATUS_CREATE_FAIL = 'WORKINGWEEKSTATUS_CREATE_FAIL'


export const WORKINGWEEKSTATUS_UPDATE_REQUEST = 'WORKINGWEEKSTATUS_UPDATE_REQUEST'
export const WORKINGWEEKSTATUS_UPDATE_SUCCESS = 'WORKINGWEEKSTATUS_UPDATE_SUCCESS'
export const WORKINGWEEKSTATUS_UPDATE_FAIL = 'WORKINGWEEKSTATUS_UPDATE_FAIL'

export const WORKINGWEEKSTATUS_DELETE_REQUEST = 'WORKINGWEEKSTATUS_DELETE_REQUEST'
export const WORKINGWEEKSTATUS_DELETE_SUCCESS = 'WORKINGWEEKSTATUS_DELETE_SUCCESS'
export const WORKINGWEEKSTATUS_DELETE_FAIL = 'WORKINGWEEKSTATUS_DELETE_FAIL'

export const VIEW_WORKINGWEEKSTATUS_REQUEST = 'VIEW_WORKINGWEEKSTATUS_REQUEST'
export const VIEW_WORKINGWEEKSTATUS_SUCCESS = 'VIEW_WORKINGWEEKSTATUS_SUCCESS'
export const VIEW_WORKINGWEEKSTATUS_FAIL = 'VIEW_WORKINGWEEKSTATUS_FAIL'