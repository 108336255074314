import { PAYMENTS_LIST_REQUEST, PAYMENTS_LIST_SUCCESS, PAYMENTS_LIST_FAIL, VIEW_PAYMENT_FAIL, VIEW_PAYMENT_REQUEST, VIEW_PAYMENT_SUCCESS, PAYMENT_CREATE_REQUEST, PAYMENT_CREATE_SUCCESS, PAYMENT_CREATE_FAIL, PAYMENT_UPDATE_FAIL, PAYMENT_UPDATE_REQUEST, PAYMENT_UPDATE_SUCCESS } from '../Constants/paymentConstants';
import api from '../../config/apiConfig';


const getPaymentsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: PAYMENTS_LIST_REQUEST});
        const {data} = await api.get('/payments');
        console.log('Payments data is:' + data)
        dispatch({type: PAYMENTS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: PAYMENTS_LIST_FAIL, payload: error})
    } 
} 

const submitPayment = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: PAYMENT_CREATE_REQUEST});
        
        const {data} = await api.post(`/payments/`, values);
        console.log('Payment data is:' + data)
        dispatch({type: PAYMENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: PAYMENT_CREATE_FAIL, payload: error})
    }
} 

const getPayment = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_PAYMENT_REQUEST});
        
        const {data} = await api.get(`/payments/ ${id}`);
        console.log('Payment data is:' + data)
        dispatch({type: VIEW_PAYMENT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_PAYMENT_FAIL, payload: error})
    }
}

const editPayment = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: PAYMENT_UPDATE_REQUEST});
        
        const {data} = await api.put(`/payments/`, values);
        console.log('Payment data is:' + data)
        dispatch({type: PAYMENT_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: PAYMENT_UPDATE_FAIL, payload: error})
    }
}



export {getPaymentsList, submitPayment, getPayment, editPayment }