import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField} from '@mui/material';
import { blueGrey, blue, purple, deepPurple, red } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import TaskIcon from '@mui/icons-material/Task';
import { useDispatch, useSelector } from 'react-redux';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getClientsList } from '../../../../../Redux/Actions/ClientsActions';
import { getTaskStatusesList } from '../../../../../Redux/Actions/TaskStatusActions';
import { getWorkers } from '../../../../../Redux/Actions/WorkerActions';
import { getTaskTypesList } from '../../../../../Redux/Actions/TaskTypeActions';
import { submitTask } from '../../../../../Redux/Actions/TasksActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SideCrumbs from '../../../../Utilities/SideCrumbs';
import ComplexAlert from '../../../../ComplexAlert';
import useAlert from '../../../../../Hooks/useAlert';
import SummaryBox from '../../../../Utilities/SummaryBox';
import { submitBalanceSheet } from '../../../../../Redux/Actions/BalanceSheetActions';



const AddBalanceSheetDialog = ({open, onClose, type, info, handleSubmit}) => {

    //ACCESS DISPATCH
    const dispatch = useDispatch();
    const {divisions, loading} = useSelector(state => state.divisions_List);
    //GET TASKS
    const {alert, showAlert}= useAlert();

   
    useEffect(() => {
    }, [])
    
    

    const [values, setValues] = useState({
            name: '', 
            period_start: '', 
            period_end: '', 
            fixedAssets: '', 
            intangibleAssets: '', 
            otherNonCurrentAssets: '' , 
            cashAtBank: '' , 
            totalAssets: '' , 
            currentLiabilities: '', 
            dividends: '', 
            totalCurrentLiabilities: '', 
            totalAssetsMinusCurrentLiabilities: '', 
            otherLongTermLiabilities: '', 
            totalLiabilities: '', 
            netAssets: '', 
            shareCapital: '', 
            shareholdersFunds: '', 
            capitalEmployed: '', 
            netWorthOrEquity: '', 
            workingCapital: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            name: '', 
            period_start: '', 
            period_end: '', 
            fixedAssets: '', 
            intangibleAssets: '', 
            otherNonCurrentAssets: '' , 
            cashAtBank: '' , 
            totalAssets: '' , 
            currentLiabilities: '', 
            dividends: '', 
            totalCurrentLiabilities: '', 
            totalAssetsMinusCurrentLiabilities: '', 
            otherLongTermLiabilities: '', 
            totalLiabilities: '', 
            netAssets: '', 
            shareCapital: '', 
            shareholdersFunds: '', 
            capitalEmployed: '', 
            netWorthOrEquity: '', 
            workingCapital: '', 
        });
    
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitBalanceSheet(values, submitValues))
        handleSubmit('success',"Successfully saved Balance Sheet information");

    }

    const handleDateChange = (newValue, name) => {
        
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate({
            ...date,
            [name]: newValue
        });
        setValues({
            ...values,
            [name]: `${day}/${month+1}/${year}`
        })
        setSubmitValues({
            ...submitValues,
            [name]: `${day}/${month+1}/${year}`
        })
    }
    
    
    const [inputValue, setInputValue] = useState('');
    
    const handleInputChange = (newValue) => {
        
        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
 
    const [date, setDate] = useState({});

   


    const animatedComponents = makeAnimated();


    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between',alignItems: 'center',fontSize: '1.3rem', background: blue[800], fontWeight: 900, color: 'white'}}  > 
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    <TaskIcon fontSize="large" sx={{color: blue[500], mr: 2, fontSize: '1.5rem'}}/> 
                    <Typography variant="h6" sx={{color: blue[500],fontWeight: 700, fontFamily: 'Poppins'}}> Add Balance Sheet </Typography>
                </Stack>
            </DialogTitle>

        <Divider sx={{color: blue[500]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    {/* <p>name {submitValues.name}</p>
                    <p>group {submitValues.group}</p>
                    <p>worker {submitValues.worker}</p>
                    <p>staff {submitValues.staff}</p>
                    <p>startdate{submitValues.start}</p>
                    <p>endate {submitValues.end}</p>
                    <p>days_taken{submitValues.days_taken}</p>
                    <p>days_left {submitValues.days_left}</p>
                    <p>approved {submitValues.approved_by}</p>
                    <p>status {submitValues.status}</p> */}
                    <SideCrumbs/>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Balance Sheet Name</Form.Label>
                                <Form.Control type="text" name="name" value={values.name} onChange={handleChange} className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>More Information</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="period_start"
                                            label="Period Start"
                                            value={date.period_start}
                                            onChange={(e) => handleDateChange(e, "period_start")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="period_end"
                                            label="Period End"
                                            value={date.period_end}
                                            onChange={(e) => handleDateChange(e, "period_end")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>

                            
                        </Row>
                    
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Fixed Assets</Form.Label>
                                <Form.Control type="text" name="fixedAssets" value={values.fixedAssets} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Intangible Assets</Form.Label>
                                <Form.Control type="text" name="intangibleAssets" value={values.intangibleAssets} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Other Non Current Assets</Form.Label>
                                <Form.Control type="text" name="otherNonCurrentAssets" value={values.otherNonCurrentAssets} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Cash At Bank</Form.Label>
                                <Form.Control type="text" name="cashAtBank" value={values.cashAtBank} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Total Assets</Form.Label>
                                <Form.Control type="text" name="totalAssets" value={values.totalAssets} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Current Liabilities</Form.Label>
                                <Form.Control type="text" name="currentLiabilities" value={values.currentLiabilities} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Dividends</Form.Label>
                                <Form.Control type="text" name="dividends" value={values.dividends} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Total Current Liabilities</Form.Label>
                                <Form.Control type="text" name="totalCurrentLiabilities" value={values.totalCurrentLiabilities} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>More Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Total Assets minus Current Liabilities</Form.Label>
                                <Form.Control type="text" name="totalAssetsMinusCurrentLiabilities" value={values.totalAssetsMinusCurrentLiabilities} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Other Long Term Liabilities</Form.Label>
                                <Form.Control type="text" name="otherLongTermLiabilities" value={values.otherLongTermLiabilities} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Net Assets</Form.Label>
                                <Form.Control type="text" name="netAssets" value={values.netAssets} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Share Capital</Form.Label>
                                <Form.Control type="text" name="shareCapital" value={values.shareCapital} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: deepPurple[500], borderRadius: '5px', px: 2, py: '5px'}}>More</Typography>
                        
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Shareholders Funds</Form.Label>
                                <Form.Control type="text" name="shareholdersFunds" value={values.shareholdersFunds} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Capital Employed</Form.Label>
                                <Form.Control type="text" name="capitalEmployed" value={values.capitalEmployed} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Net Worth/Equity</Form.Label>
                                <Form.Control type="text" name="netWorthOrEquity" value={values.netWorthOrEquity} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Working Capital</Form.Label>
                                <Form.Control type="text" name="workingCapital" value={values.workingCapital} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                          
                        </Form>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[700], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Balance Sheet</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddBalanceSheetDialog
