import { REQUESTTYPES_LIST_REQUEST, REQUESTTYPES_LIST_SUCCESS, REQUESTTYPES_LIST_FAIL, VIEW_REQUESTTYPE_FAIL, VIEW_REQUESTTYPE_REQUEST, VIEW_REQUESTTYPE_SUCCESS, REQUESTTYPE_CREATE_FAIL, REQUESTTYPE_UPDATE_REQUEST, REQUESTTYPE_UPDATE_FAIL, REQUESTTYPE_DELETE_REQUEST, REQUESTTYPE_DELETE_FAIL, REQUESTTYPE_CREATE_REQUEST, REQUESTTYPE_CREATE_SUCCESS, REQUESTTYPE_UPDATE_SUCCESS, REQUESTTYPE_DELETE_SUCCESS } from "../Constants/requestTypeConstants";

const requestTypesListReducer = (state = {requestTypes: []}, action) => {

    switch (action.type)
    {
        case REQUESTTYPES_LIST_REQUEST:
            return {loading: true};
 
        case REQUESTTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and requestTypes data is:' + action.payload)
            return {
                loading: false,
                requestTypes: action.payload
            }
        case REQUESTTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const requestTypeCreateReducer = (state = {requestTypes: []}, action) => {

    switch (action.type) {

        case REQUESTTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case REQUESTTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                requestTypes: [...state.requestTypes, {Applicant_Id: 2, Name: value}]
            };

        case REQUESTTYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const requestTypeViewReducer = (state= {requestType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_REQUESTTYPE_REQUEST:
            return {loading: true};
        case VIEW_REQUESTTYPE_SUCCESS:
            return {
                    loading: false,
                    requestType: action.payload
                }
        case VIEW_REQUESTTYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const requestTypeUpdateReducer = (state = {requestTypes: {}}, action) => {

    switch (action.type) {

        case REQUESTTYPE_UPDATE_REQUEST:
            return {
                
            }
        case REQUESTTYPE_UPDATE_SUCCESS:

            return {

            }
        case REQUESTTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const requestTypeDeleteReducer = (state = {requestTypes: {}}, action) => {

    switch (action.type) {
        case REQUESTTYPE_DELETE_REQUEST:
            return {
                
            }
        case REQUESTTYPE_DELETE_SUCCESS:
            return {

            }
        case REQUESTTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {requestTypesListReducer, requestTypeCreateReducer, requestTypeViewReducer, requestTypeUpdateReducer, requestTypeDeleteReducer }