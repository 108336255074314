import React, {useState, useRef, useEffect} from 'react'
import { Typography, Paper, Grid, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Button, Card, ToggleButtonGroup, ToggleButton, ClickAwayListener, MenuItem, MenuList, Popper, Grow, Divider, IconButton, TextField, Alert, List, ListItem, LinearProgress, Box, Tabs, Tab, Stack, ListItemText, ListItemAvatar, Avatar, ListItemIcon, Breadcrumbs } from '@mui/material';
import { blueGrey, green, blue, deepOrange, red, deepPurple, lightGreen, orange } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated'
import Crumbs from '../../../Components/Utilities/Crumbs';
import ViewStatus from '../../../Components/Utilities/ViewStatus'; 
import CRUD from '../../../Components/Utilities/CRUD';
import useCRUD from '../../../Hooks/useCRUD';
import ViewTitleComponent from '../../../Components/Utilities/ViewTitleComponent';
import { getProfile } from '../../../Redux/Actions/ProfileActions';
import PageHeader from '../../../Components/PageHeader';
import ME from '../../../assets/ME.jpg';
import FormHeading from '../../../Components/Utilities/FormHeading';
import { useParams } from 'react-router';

const ViewProfile = () => {
  
    const {id} = useParams();
    console.log('id in view profile is:' + id)
    const dispatch = useDispatch()
    const {isEditing, handleCreate, handleEdit, handleDelete, setIsEditing, handlePrint, alert, showAlert, dialog, showDialog, handleSubmit, closeDialog } = useCRUD();

    //GET PROFILE
    const profile_records = useSelector(state => state.profile_View);
    const {loading, profile} = profile_records;

      //ON COMPONENT DID MOUNT
      useEffect(()=> {

        dispatch(getProfile(id));
        
    }, [])

    
      const animatedComponents = makeAnimated();

        const workers = [
            {value: 'Emily Bone', label: 'Emily Bone'},
            {value: 'John Cusa', label: 'John Cusa'},
            {value: 'Mah Jani', label: 'Mah Jani'},
        ]

    return (
        <>
            <PageHeader page="Profile Details" previous profiles />
            <Grid container justifyContent="space-between">
                <Crumbs root="CRM" middle="profiles" end="Details"/>
             </Grid>

             <Grid container justifyContent="space-between" alignItems= "center"  sx={{mt: 2, mb: 2, px:0, borderRadius: '20px', color: 'gray', background: ''}}>
                <ViewStatus/>
                <CRUD isEditing={isEditing} handleCreate={handleCreate} handleEdit={handleEdit} handleDelete={handleDelete} setIsEditing={setIsEditing} showAlert ={showAlert } handlePrint/>
            </Grid>

            <Grid container  alignItems="" justifyContent="space-between" sx={{mt: 3, mb: 0, background: 'white'}}>
                <ViewTitleComponent title={loading? '' : `${profile.Type == 'Worker'? 'Worker' : 'Applicant'}`} profile/>

                <Grid item lg={12} sx={{mt: 3, px: 3,  background: 'white', borderRadius: '0'}}>
                    <Grid container justifyContent="space-between">
                        <Grid item sx={{background: ''}} >
                            <Avatar sx={{width: 140, height: 140, background: blueGrey[500], border: '7px solid whitesmoke'}} variant="circular" src={ME}/>
                        </Grid>
                        
                        <Grid item lg={3}>
                            <Stack alignItems="flex-start">
                                <FormHeading title="Name" badge="Info" icon=""/>
                                <Typography sx={{fontSize: 20 ,fontFamily: 'Poppins-Medium', color: blueGrey[800]}}>{loading? '' : profile.Person}</Typography>
                            </Stack>
                            <Stack alignItems="flex-start" sx={{mt: 2}}>
                                <FormHeading title="Area" badge="Division" icon=""/>
                                <Typography sx={{fontSize: 16 ,fontFamily: 'Poppins-Medium', color: orange[500]}}>Construction</Typography>
                            </Stack>
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{color: 'black'}}/>
                        <Grid item md={3}>
                            <Grid container flexDirection="column">
                                <Stack alignItems="flex-start" sx={{mt: 2}}>
                                    <FormHeading title="" badge="Status" icon=""/>
                                    <Typography sx={{fontSize: 16 ,fontFamily: 'Poppins-Medium', color: lightGreen[500]}}>Active</Typography>
                                </Stack>
                                <Stack alignItems="flex-start" sx={{mt: 2}}>
                                    <FormHeading title="" badge="Supervisor" icon=""/>
                                    <Typography sx={{fontSize: 16 ,fontFamily: 'Poppins-Medium', color: blueGrey[500]}}>Jenny Keller</Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                    </Grid>
                <Divider orientation="horizontal" flexItem sx={{color: blue[500], mt: 4}}/>
                </Grid>



               
                <Grid item lg={12} sx={{mb: 0, px: 3, background: 'white', borderRadius: '0'}}>
                    <Grid container sx={{mt: 2}}>


                        <Grid item lg={12} sx={{mt: 1}}>
                                <FormHeading title="Other details" badge="Info" icon=""/>
                                <Paper sx={{mt: 0,px: 4, py: 2, background: 'white', boxShadow: 'none', border: '1px solid whitesmoke'}}> 
                                    <Form className="">
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="formGridEmail" >
                                                <Form.Label className="view-label mb-3">Profile ID</Form.Label>
                                                <Form.Control disabled type="email" value={`PRF-${id}`} placeholder="E.g 123qst1" className={`${isEditing? '' : 'border-0  '}`} />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridEmail">
                                                <Form.Label className="view-label mb-3 ">Profile Name</Form.Label>
                                                <br/>
                                                {
                                                    isEditing? 
                                                        <Select 
                                                            value={workers[0]}
                                                            options={workers}
                                                            closeMenuOnSelect ={false}
                                                            isMulti
                                                            components={animatedComponents}
                                                            placeholder="Select applicants ..."
                                                        />  :
                                                <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : `test`}</Typography>
                                                }
                                            </Form.Group>
                                            
                                        </Row>
                                        <Row className="mb-3">

                                            <Form.Group as={Col} controlId="formGridEmail" >
                                                <Form.Label className="view-label mb-3">Email</Form.Label>
                                                <Form.Control autoFocus={isEditing? true : false} disabled type="email" value={loading? '' : 'holding'} placeholder="E.g John" className={`${isEditing? '' : 'border-0 '}`} />
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                </Paper>
                        </Grid>
                    </Grid>
                </Grid>

               

            </Grid>
        </>
    )
}

export default ViewProfile

{/* <Grid item sx={{pr: 4, borderRight: '1px solid #eceff1'}}>
<Stack sx={{ml: 2}}>
    <Typography variant="h1" sx={{fontSize: '1.7rem',fontWeight: 800, py: '', px: 0,color: 'blueGrey[800]', background: 'blueGrey[200]'}}>{values ? values.name : 'Employee Name'}</Typography>
        <Divider orientation="vertical" variant="middle" />
    <Stack direction="row" justifyContent="space-between" sx={{mt: '7px'}}>
            <Box sx={{px:0, py: 0, display: 'flex', justifyContent: "space-around", alignItems: "center", background: '', borderRadius: '10px'}}>
                <Typography variant="" sx={{fontSize: '', fontWeight: 500, py: 0, pl: 0,color: blueGrey[700], background: 'blueGrey[200]'}}>{headerFor == 'worker-details' ? 'Department:' : 'Tag:'}</Typography>
                <Typography variant="" sx={{fontSize: '.7rem', fontWeight: 500, px: 1, py: 0,ml: 1,color: blueGrey[50], background: 'dodgerblue', borderRadius: '10px'}}>{headerFor == 'worker-details' ? 'Employee Department' : 'Immediate Hire'}</Typography>
            </Box>
    </Stack>

    
</Stack>
</Grid> */}