import React, { useState } from 'react';
import { ListItemText, ListItemButton, ListItemIcon, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import DropdownItem from './DropdownItem';


const DropdownMenu = ({dropdown, drawerStatus}) => {

   
    
    return (
        <>
            <List sx={{background: 'rgba(227, 242, 253, .0)', px: 2}}>
            {/* <List sx={{background: 'rgba(240, 243, 245, .9)'}}> */}
                {
                    dropdown.map((item, index) => 

                    <DropdownItem key={index} item={item} drawerStatus={drawerStatus}/>
                    )
                 }
               
            </List>
        </>
    )
}

export default DropdownMenu
