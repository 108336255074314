import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, Breadcrumbs} from '@mui/material';
import { blueGrey, cyan, blue, purple, deepPurple, teal, orange, deepOrange, yellow, pink } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import api from '../../../../../config/apiConfig';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { getShiftPreferencesList } from '../../../../../Redux/Actions/ShiftPreferenceActions';
import { getPayrollSetupsList } from '../../../../../Redux/Actions/PayrollSetupActions';
import { getPayrollDocTypesList } from '../../../../../Redux/Actions/PayrollDocTypeActions';
import { getWorkerStatusesList } from '../../../../../Redux/Actions/WorkerStatusActions';
import { getWorkers, submitWorker } from '../../../../../Redux/Actions/WorkerActions';
import { Link as BreadcrumbLink } from '@mui/material';


const AddWorkerDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET SHIFT PREFS
    const preferences_ListState = useSelector(state => state.shiftPreferences_List);
    const {shiftPreferences, loading: loading_preferences} = preferences_ListState;

    //GET DIVISIONS
    const divisions_ListState = useSelector(state => state.divisions_List);
    const {divisions, loading} = divisions_ListState;

    //GET JOB ROLES
    const roles_List_State = useSelector((state) => state.jobRoles_List);
    const {loading: loading_roles, jobRoles} = roles_List_State;

    //GET PAYROLL SETUP 
    const payrollSetup_ListState = useSelector(state => state.payrollSetups_List);
    const {payrollSetups, loading: loading_setups} = payrollSetup_ListState;

    //GET PAYROLL DOC TYPE
    const docTypes_ListState = useSelector(state => state.payrollDocTypes_List);
    const {payrollDocTypes, loading: loading_docTypes} = docTypes_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;

    //GET WORKERs
    const workers_ListState = useSelector(state => state.workers_List);
    const {workers, loading: loading_workers} = workers_ListState;

    //GET WORKER STATUS
    const statuses_ListState = useSelector(state => state.workerStatuses_List);
    const {workerStatuses, loading: loading_statuses} = statuses_ListState;

    //GET LINKED UMBRELLAS
    const umbrellas_List_State = useSelector((state) => state.linkedUmbrellas_List);
    const {loading: loading_umbrellas, linkedUmbrellas} = umbrellas_List_State;
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getWorkers()); 
        dispatch(getDivisions());
        dispatch(getJobRolesList());
        dispatch(getShiftPreferencesList());
        dispatch(getPayrollSetupsList());
        dispatch(getPayrollDocTypesList());
        dispatch(getWorkerStatusesList());
       
    }, [])
    

    const [values, setValues] = useState({
            gender: '',
            firstname: '',
            surname: '',
            nationalno: '',
            dob: '',
            mobile: '',
            hphone: '',
            email: '', 
            address1: '',
            address2: '' , 
            postcode: '' , 
            town: '', 
            divisions: '', 
            jobroles: '', 
            utr: '',
            cis: '',
            company: '',
            companyno: '',
            vat: '',
            staff: '',
            shiftpreferences: '', 
            payrollsetups: '', 
            payrolldoctypes: '', 
            workerstatuses: '', 
            linkedumbrellas: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            gender: '',
            firstname: '',
            surname: '',
            nationalno: '',
            dob: '',
            mobile: '',
            hphone: '',
            email: '', 
            address1: '',
            address2: '' , 
            postcode: '' , 
            town: '', 
            divisions: '', 
            jobroles: [], 
            utr: '',
            cis: '',
            company: '',
            companyno: '',
            vat: '',
            staff: '',
            shiftpreferences: '', 
            payrollsetups: '', 
            payrolldoctypes: '', 
            workerstatuses: '', 
            linkedumbrellas: '',  
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {

        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
         
        }
        else if(name == 'type' || name == 'status' || name == 'result')
        {

            setValues({
                ...values,
                [name]: value
            });
           
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
 
        } 
    }
   
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitWorker(submitValues))
        handleSubmit('success',"Successfully saved worker's information");

    }

    const animatedComponents = makeAnimated();

    const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {

        
        const keeper = [];

        const tableName = name == 'divisions' ? divisions : name == 'jobroles' ? jobRoles : name == 'staff' ? staff : name == 'shiftpreferences' ? shiftPreferences : name == 'payrollsetups' ? payrollSetups : name == 'payrolldoctypes' ? payrollDocTypes : name == 'workerstatuses' ? workerStatuses : name == 'linkedumbrellas' ? linkedUmbrellas :'' ;

        const id_field = name === 'divisions' ? 'Division_Id': name === 'staff' ? 'Staff_Id' : name === 'jobroles'? 'Role_Id' : name === 'shiftpreferences'? 'Preference_Id': name === 'payrollsetups'? 'Setup_Id' : name === 'payrolldoctypes'? 'Type_Id' : name === 'workerstatuses'? 'Status_Id' : name === 'linkedumbrellas'? 'Umbrella_Id': '';
        const name_field = name === 'divisions' ? 'Name': name === 'staff' ? 'First_Name' : name === 'jobroles'? 'Name' : name === 'shiftpreferences'? 'Name': name === 'payrollsetups'? 'Setup_Name': name === 'payrolldoctypes'? 'Type_Name' : name === 'workerstatuses'? 'Status_Name' : name === 'linkedumbrellas'? 'Umbrella_Name':  '';
            
        tableName.forEach(x => {
            keeper.push({value: x[id_field], label: x[name_field]})
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['divisions', 'staff', 'jobroles', 'shiftpreferences', 'payrollsetups', 'payrolldoctypes', 'workerstatuses', 'linkedumbrellas'];
        
        const id_field = name === 'divisions' ? 'Division_Id': name === 'staff' ? 'Staff_Id' : name === 'jobroles'? 'Role_Id' : name === 'shiftpreferences'? 'Preference_Id': name === 'payrollsetups'? 'Setup_Id' : name === 'payrolldoctypes'? 'Type_Id' : name === 'workerstatuses'? 'Status_Id' : name === 'linkedumbrellas'? 'Umbrella_Id': '';
        const name_field = name === 'divisions' ? 'Name': name === 'staff' ? 'First_Name' : name === 'jobroles'? 'Name' : name === 'shiftpreferences'? 'Name': name === 'payrollsetups'? 'Setup_Name': name === 'payrolldoctypes'? 'Type_Name' : name === 'workerstatuses'? 'Status_Name' : name === 'linkedumbrellas'? 'Umbrella_Name':  '';
        
        names.forEach(x => x === name)
        {
            
            data.forEach(x => {
                keeper.push({value: x[id_field], label: x[name_field]})
               })
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action) => {
        
        const name = action.name;

        if(name === 'jobroles')
        {
            const container = [];

            value.forEach(x => {
                
                container.push(x.value)
            })
            console.log('valueeeeeeeeeeeeeee:' + container)

            setValues({
                ...values,
                jobroles: [...value]
            })
            setSubmitValues({
                ...submitValues,
                jobroles: container
            })
        }
        else{

            setValues({
                ...values,
                [name]: value
            })
    
            setSubmitValues({
                ...submitValues,
                [name]: value.value
            })
        }
    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }

    const [summary, setSummary] = useState(false)

    const handleSummary = () => {
        setSummary(!summary);
    }

    //const workers = ['Emily Bone', 'Shawn Drisic', 'Isaac Phily']
    const genders = [
        {value: 'Male', label: 'Male'},
        {value: 'Female', label: 'Female'},
        
    ]
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[700]}}  >
                
                <EngineeringIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add new Worker </Typography>
            </DialogTitle>
     
        <Divider/>
        {/* <p>fname {submitValues.firstname}</p>
        <p>sname {submitValues.surname}</p>
        <p>pref {submitValues.shiftpreferences}</p>
        <p>div {submitValues.divisions}</p>
        <p>roles{submitValues.jobroles}</p>
        <p>status {submitValues.workerstatuses}</p>
        <p> staff{submitValues.staff}</p>
        <p>doc {submitValues.payrolldoctypes}</p>
        <p>setup {submitValues.payrollsetups}</p>
        <p>umb {submitValues.linkedumbrellas}</p> */}
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <Grid item lg={12} sx={{position: 'sticky', zIndex: 10}}>
                        <Grid item lg={12} sx={{m: 0, p: 0, background: ''}}>
                            <Stack direction="row" alignItems="center" sx={{ background: 'blue[50]', mb: 0}}>
                                <Typography variant="body1" sx={{color: blue[800],fontWeight: 900, textAlign: 'center'}}>Form Summary</Typography>
                                <Button variant="text" size="small" sx={{fontSize: 12, color: blue[900], textAlign: 'center'}} onClick={handleSummary}>{summary? 'Close' : 'Open'}</Button>
                            </Stack>
                        </Grid>
                                
                        <Grid container flexDirection="row" justifyContent="space-between" className={`summary ${summary? 'open' : ''}`} sx={{px: 3, pt: '2px', background: '#f2f7fc', border: '2px solid whitesmoke', borderRadius: 3, boxShadow: 'none', overflowY: 'scroll'}}>
                                {/* <Divider sx={{border: '.5px solid gray'}}/> */}
                                <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Worker ID: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`WRK - ${!loading_workers? workers.length + 1 : ''}`} </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Worker Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.assessment_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                    <Typography variant="body2" sx={{color: values.subject? '' : blue[500], fontWeight: values.subject? 800 : 500, ml: 5}}>{values.subject? values.subject : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                    <Typography variant="body2" sx={{color: values.applicant? '' : blue[500], fontWeight: values.applicant? 800 : 500, ml: 5}}>{values.applicant? values.applicant : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Name </Typography>
                                    <Typography variant="body2" sx={{color: applicantName? '' : blue[500], fontWeight: applicantName? 800 : 500, ml: 5}}>{applicantName? applicantName : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff Name </Typography>
                                    <Typography variant="body2" sx={{color: staffName? '' : blue[500], fontWeight: staffName? 800 : 500, ml: 5}}>{staffName? staffName : 'null' }</Typography>
                                </Stack>
                                
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Worker Type: </Typography>
                                    <Typography variant="body2" sx={{color: values.type? '' : blue[500], fontWeight: values.type? 800 : 500, ml: 5}}>{values.type? values.type : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Level: </Typography>
                                    <Typography variant="body2" sx={{color: values.level? '' : blue[500], fontWeight: values.level? 800 : 500, ml: 5}}>{values.level? values.level : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Worker Result: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.result? 'white' : blue[500], fontSize: '.75rem', textAlign: 'left', px: values.result? '4px' : '', borderRadius: '3px' ,fontWeight: values.result? 500 : 500, background: values.result? blue[500] : ''}}>{values.result? values.result : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Worker Status: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.status? 'white' : blue[500], fontSize: values.status? '.75rem' :'', textAlign: 'left', px: values.status? '4px' : '', borderRadius: '3px'  ,fontWeight: values.status? 500 : 500, background: values.status? blue[500] : ''}}>{values.status? values.status : 'null' }</Typography>
                                </Stack>
                                
                            
                            </Grid>
                        </Grid>
                    
                    <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                        <Stack sx={{mb: 3, background: '', height: '300px', borderRadius: '5px', width: '80%', pl: 3, pt: 3}}>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300]}} fontSize="small" href="#">Basic Info</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#contact">Contact</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#specifics">Job Specifics</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#payroll">Payroll</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#client">Company Info</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[400], mt: 4}} fontSize="small" href="#status">Worker Status</BreadcrumbLink>
                        </Stack>
                    </Grid>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Worker ID</Form.Label>
                                <Form.Control disabled type="email" value="WRK-3" placeholder="E.g 123qst1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Gender</Form.Label>
                                    <Select 
                                        options={genders}
                                        components={animatedComponents}
                                        placeholder="Select gender ..."
                                    />
                            </Form.Group>
                        </Row>
                    
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">First Name</Form.Label>
                                <Form.Control type="email" name="firstname" value={values.firstname} onChange={handleChange} placeholder="Enter first name" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Surname</Form.Label>
                                <Form.Control type="email" name="surname" value={values.surname} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <Row>
                            
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">National Insurance #</Form.Label>
                                <Form.Control type="email" name="nationalno" value={values.nationalno} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">D.O.B</Form.Label>
                                <Form.Control type="email" name="dob" value={values.dob} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography id="contact" variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Contact Information</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Mobile</Form.Label>
                                <Form.Control type="email" name="mobile" value={values.mobile} onChange={handleChange} placeholder="Enter first name" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Home Phone</Form.Label>
                                <Form.Control type="email" name="hphone" value={values.hphone} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Email</Form.Label>
                                <Form.Control type="email" name="email" value={values.email} onChange={handleChange} placeholder="Enter first name" className="outline-none border-none" />
                            </Form.Group>
                        
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Address Line 1</Form.Label>
                                <Form.Control type="email" name="address1" value={values.address1} onChange={handleChange} placeholder="Enter first name" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Address Line 2</Form.Label>
                                <Form.Control type="email" name="address2" value={values.address2} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                        
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Postcode</Form.Label>
                                <Form.Control type="email" name="postcode" value={values.postcode} onChange={handleChange} placeholder="Enter first name" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Town/City</Form.Label>
                                <Form.Control type="email" name="town" value={values.town} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                        
                        </Row>


                        <br/>
                        <Typography id="specifics" variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Job Specifics</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Division</Form.Label>
                                    <AsyncSelect
                                        value={values.divisions}
                                        name="divisions"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'divisions')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for division ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Job Role</Form.Label>
                                        <AsyncSelect
                                            value={values.jobroles}
                                            name="jobroles"
                                            cacheOptions
                                            isMulti
                                            loadOptions={(value, action) => loadOptions(value, 'jobroles')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for roles ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>
                        </Row>
                        <Row>
                           
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Shift Preference</Form.Label>
                                    <AsyncSelect
                                        value={values.shiftpreferences}
                                        name="shiftpreferences"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'shiftpreferences')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for preference ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography id="payroll" variant="body2" sx={{display: 'inline',color: 'white',background: pink[500], borderRadius: '5px', px: 2, py: '5px'}}>Payroll</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Payroll Setup</Form.Label>
                                    <AsyncSelect
                                        value={values.payrollsetups}
                                        name="payrollsetups"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'payrollsetups')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for setup ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="view-label">Payroll Doc Type</Form.Label>
                                    <AsyncSelect
                                        value={values.payrolldoctypes}
                                        name="payrolldoctypes"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'payrolldoctypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for doc type ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                           
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">UTR#</Form.Label>
                                <Form.Control type="email" name="utr" value={values.utr} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">CIS#</Form.Label>
                                <Form.Control type="email" name="cis" value={values.cis} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                        <br/>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Linked Umbrella</Form.Label>
                                    <AsyncSelect
                                        value={values.linkedumbrellas}
                                        name="linkedumbrellas"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'linkedumbrellas')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for umbrella ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography id="company" variant="body2" sx={{display: 'inline',color: 'white',background: blue[700], borderRadius: '5px', px: 2, py: '5px'}}>Company Information</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Company Name</Form.Label>
                                <Form.Control type="email" name="company" value={values.company} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Company Number</Form.Label>
                                <Form.Control type="email" name="companyno" value={values.companyno} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">VAT Number</Form.Label>
                                <Form.Control type="email" name="vat" value={values.vat} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography id="staff" variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Office Staff</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Related Consultant</Form.Label>
                                   <AsyncSelect
                                        value={values.staff}
                                        name="staff"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'staff')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for staff ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>

                        <br/>
                        <Typography id="status" variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Worker Status</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Worker Status</Form.Label>
                                    <AsyncSelect
                                        value={values.workerstatuses}
                                        name="workerstatuses"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'workerstatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>

                       
                        
                        
                        </Form>
                    </Grid>

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[800], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Worker</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddWorkerDialog
