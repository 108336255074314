import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { margin } from '@mui/system';
import SidebarData from './SidebarData';
import SubMenu from './SubMenu';

const Nav = styled.div`
      background: whitesmoke;
      height: 80px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      
      `;

const NavIcon = styled(Link)`
      color: black;
      margin-left: 2rem;
      font-size: 2rem; 
      display: flex;
      justify-content: flex-start;
      align-items: center;

      `;

const SidebarNav = styled.div`
      background: aliceblue;
      width: 250px;
      height: 100vh;
      font-size: 2rem;
      display: flex;
      justify-content: center;
      position: fixed;
      top: 0;
      left: ${({sidebar}) => (sidebar? '0' : '-100%')};
      transition: 350ms;
      z-index: 10;
      box-shadow: 0 10px 15px grey;


      `;


const SidebarWrap = styled.div`
      width: 100%;

      `;

      
      
 


// const SideNav = styled.div`
//       background: orange;
      
//       `;


      
const Sidebar = () => {

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
        setSidebar(!sidebar);
    }
    return (
        <>
        <Nav>
            <NavIcon to="#">
                <FaIcons.FaBars onClick={showSidebar}/>
            </NavIcon>
        </Nav>

        <SidebarNav sidebar={sidebar}>
            <SidebarWrap>
                <NavIcon to="#">
                    <AiIcons.AiOutlineClose onClick={showSidebar}/>
                </NavIcon>
                {SidebarData.map((item, index) => <SubMenu item={item} key={index}/>)}
            </SidebarWrap>
        </SidebarNav>
        
        </>
    )
}

export default Sidebar
