import { CHECKINS_LIST_REQUEST, CHECKINS_LIST_SUCCESS, CHECKINS_LIST_FAIL, VIEW_CHECKIN_FAIL, VIEW_CHECKIN_REQUEST, VIEW_CHECKIN_SUCCESS, CHECKIN_CREATE_FAIL, CHECKIN_UPDATE_REQUEST, CHECKIN_UPDATE_FAIL, CHECKIN_DELETE_REQUEST, CHECKIN_DELETE_FAIL, CHECKIN_CREATE_REQUEST, CHECKIN_CREATE_SUCCESS, CHECKIN_UPDATE_SUCCESS, CHECKIN_DELETE_SUCCESS } from "../Constants/checkInConstants";

const checkInsListReducer = (state = {checkIns: []}, action) => {

    switch (action.type)
    {
        case CHECKINS_LIST_REQUEST:
            return {loading: true};
 
        case CHECKINS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and checkIns data is:' + action.payload)
            return {
                loading: false,
                checkIns: action.payload
            }
        case CHECKINS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const checkInCreateReducer = (state = {checkIns: []}, action) => {

    switch (action.type) {

        case CHECKIN_CREATE_REQUEST:
            return {
                loading: true
            }

        case CHECKIN_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                checkIns: [...state.checkIns, {Applicant_Id: 2, Name: value}]
            };

        case CHECKIN_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const checkInViewReducer = (state= {checkIn: {}}, action) => {
    switch(action.type)
    {
        case VIEW_CHECKIN_REQUEST:
            return {loading: true};
        case VIEW_CHECKIN_SUCCESS:
            return {
                    loading: false,
                    checkIn: action.payload
                }
        case VIEW_CHECKIN_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const checkInUpdateReducer = (state = {checkIns: {}}, action) => {

    switch (action.type) {

        case CHECKIN_UPDATE_REQUEST:
            return {
                
            }
        case CHECKIN_UPDATE_SUCCESS:

            return {

            }
        case CHECKIN_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const checkInDeleteReducer = (state = {checkIns: {}}, action) => {

    switch (action.type) {
        case CHECKIN_DELETE_REQUEST:
            return {
                
            }
        case CHECKIN_DELETE_SUCCESS:
            return {

            }
        case CHECKIN_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {checkInsListReducer, checkInCreateReducer, checkInViewReducer, checkInUpdateReducer, checkInDeleteReducer }