export const WOMSTATUSES_LIST_REQUEST = 'WOMSTATUSES_LIST_REQUEST';
export const WOMSTATUSES_LIST_SUCCESS = 'WOMSTATUSES_LIST_SUCCESS';
export const WOMSTATUSES_LIST_FAIL = 'WOMSTATUSES_LIST_FAIL'; 

export const WOMSTATUS_CREATE_REQUEST = 'WOMSTATUS_CREATE_REQUEST'
export const WOMSTATUS_CREATE_SUCCESS = 'WOMSTATUS_CREATE_SUCCESS'
export const WOMSTATUS_CREATE_FAIL = 'WOMSTATUS_CREATE_FAIL'


export const WOMSTATUS_UPDATE_REQUEST = 'WOMSTATUS_UPDATE_REQUEST'
export const WOMSTATUS_UPDATE_SUCCESS = 'WOMSTATUS_UPDATE_SUCCESS'
export const WOMSTATUS_UPDATE_FAIL = 'WOMSTATUS_UPDATE_FAIL'

export const WOMSTATUS_DELETE_REQUEST = 'WOMSTATUS_DELETE_REQUEST'
export const WOMSTATUS_DELETE_SUCCESS = 'WOMSTATUS_DELETE_SUCCESS'
export const WOMSTATUS_DELETE_FAIL = 'WOMSTATUS_DELETE_FAIL'

export const VIEW_WOMSTATUS_REQUEST = 'VIEW_WOMSTATUS_REQUEST'
export const VIEW_WOMSTATUS_SUCCESS = 'VIEW_WOMSTATUS_SUCCESS'
export const VIEW_WOMSTATUS_FAIL = 'WOMSTATUS_FAIL'