import { TRANSACTIONTYPES_LIST_REQUEST, TRANSACTIONTYPES_LIST_SUCCESS, TRANSACTIONTYPES_LIST_FAIL, VIEW_TRANSACTIONTYPE_FAIL, VIEW_TRANSACTIONTYPE_REQUEST, VIEW_TRANSACTIONTYPE_SUCCESS, TRANSACTIONTYPE_CREATE_FAIL, TRANSACTIONTYPE_UPDATE_REQUEST, TRANSACTIONTYPE_UPDATE_FAIL, TRANSACTIONTYPE_DELETE_REQUEST, TRANSACTIONTYPE_DELETE_FAIL, TRANSACTIONTYPE_CREATE_REQUEST, TRANSACTIONTYPE_CREATE_SUCCESS, TRANSACTIONTYPE_UPDATE_SUCCESS, TRANSACTIONTYPE_DELETE_SUCCESS } from "../Constants/transactionTypeConstants";

const transactionTypesListReducer = (state = {transactionTypes: []}, action) => {

    switch (action.type)
    {
        case TRANSACTIONTYPES_LIST_REQUEST:
            return {loading: true};
 
        case TRANSACTIONTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and transactionTypes data is:' + action.payload)
            return {
                loading: false,
                transactionTypes: action.payload
            }
        case TRANSACTIONTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const transactionTypeCreateReducer = (state = {transactionTypes: []}, action) => {

    switch (action.type) {

        case TRANSACTIONTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case TRANSACTIONTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                transactionTypes: [...state.transactionTypes, {Applicant_Id: 2, Name: value}]
            };

        case TRANSACTIONTYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const transactionTypeViewReducer = (state= {transactionType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_TRANSACTIONTYPE_REQUEST:
            return {loading: true};
        case VIEW_TRANSACTIONTYPE_SUCCESS:
            return {
                    loading: false,
                    transactionType: action.payload
                }
        case VIEW_TRANSACTIONTYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const transactionTypeUpdateReducer = (state = {transactionTypes: {}}, action) => {

    switch (action.type) {

        case TRANSACTIONTYPE_UPDATE_REQUEST:
            return {
                
            }
        case TRANSACTIONTYPE_UPDATE_SUCCESS:

            return {

            }
        case TRANSACTIONTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const transactionTypeDeleteReducer = (state = {transactionTypes: {}}, action) => {

    switch (action.type) {
        case TRANSACTIONTYPE_DELETE_REQUEST:
            return {
                
            }
        case TRANSACTIONTYPE_DELETE_SUCCESS:
            return {

            }
        case TRANSACTIONTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {transactionTypesListReducer, transactionTypeCreateReducer, transactionTypeViewReducer, transactionTypeUpdateReducer, transactionTypeDeleteReducer }