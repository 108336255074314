import React, {useState} from 'react';
import AddTaskDialog from './AddTaskDialog';
import ViewTaskDialog from './ViewTaskDialog';
import DeleteTaskDialog from './DeleteTaskDialog';



const TaskDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <> 
        {
            type === "create"?

            <AddTaskDialog open={open} onClose={onClose} handleSubmit={handleSubmit}  fullWidth />
            
            : type === "view"?

            <ViewTaskDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />

            : type === "delete"?

            <DeleteTaskDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default TaskDialogs
