import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox} from '@mui/material';
import { blue } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import api from '../../../../../config/apiConfig';
import { getRateStatusesList } from '../../../../../Redux/Actions/RateStatusActions';
import { submitRateCard } from '../../../../../Redux/Actions/RateCardActions';


const AddRateCardDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();  

    //GET CONTACTTYPES
    const rateStatuses_ListState = useSelector(state => state.rateStatuses_List);
    const {rateStatuses, loading} = rateStatuses_ListState;


    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getRateStatusesList());
        
       
    }, [])
    

    const [values, setValues] = useState({
            
        Name: '',
        Break: '',
        ratestatuses: '',
        StandardChargeRate_LTDUTR: '',
        StandardPayRate_PAYE: '',
        StandardPayRate_PAYE2: '',
        NightChargeRate: '',
        NightPayRate_LTDUTR: '',
        NightPayRate_PAYE: '',
        NightPayRate_PAYE2: '',
        SaturdayChargeRate: '',
        SaturdayPayRate_LTDUTR: '',
        SaturdayPayRate_PAYE: '',
        SaturdayPayRate_PAYE2: '',
        SundayChargeRate: '',
        SundayPayRate_LTDUTR: '',
        SundayPayRate_PAYE: '',
        SundayPayRate_PAYE2: '',
        OvertimeChargeRate: '',
        OvertimePayRate_LTDUTR: '',
        OvertimePayRate_PAYE: '',
        OvertimePayRate_PAYE2: '',
        BHChargeRate: '',
        BHPayRate_LTDUTR: '',
        BHPayRate_PAYE: '',
        BHPayRate_PAYE2: '',
        SaturdayNightChargeRate: '',
        SaturdayNightPayRate_LTDUTR: '',
        SaturdayNightPayRate_PAYE: '',
        SaturdayNightPayRate_PAYE2: ''
        });

    const [submitValues, setSubmitValues] = useState({
            
        Name: '',
        Break: '',
        ratestatuses: '',
        StandardChargeRate_LTDUTR: '',
        StandardPayRate_PAYE: '',
        StandardPayRate_PAYE2: '',
        NightChargeRate: '',
        NightPayRate_LTDUTR: '',
        NightPayRate_PAYE: '',
        NightPayRate_PAYE2: '',
        SaturdayChargeRate: '',
        SaturdayPayRate_LTDUTR: '',
        SaturdayPayRate_PAYE: '',
        SaturdayPayRate_PAYE2: '',
        SundayChargeRate: '',
        SundayPayRate_LTDUTR: '',
        SundayPayRate_PAYE: '',
        SundayPayRate_PAYE2: '',
        OvertimeChargeRate: '',
        OvertimePayRate_LTDUTR: '',
        OvertimePayRate_PAYE: '',
        OvertimePayRate_PAYE2: '',
        BHChargeRate: '',
        BHPayRate_LTDUTR: '',
        BHPayRate_PAYE: '',
        BHPayRate_PAYE2: '',
        SaturdayNightChargeRate: '',
        SaturdayNightPayRate_LTDUTR: '',
        SaturdayNightPayRate_PAYE: '',
        SaturdayNightPayRate_PAYE2: ''
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {

        const {name, value, checked, type} = e.target;

        setValues({
            ...values,
            [name]: value
        });
        setSubmitValues({
            ...submitValues,
            [name]: value
        })
        
     
    }

    const handleSave = () => {
        console.log('in save function')
        dispatch(submitRateCard({...submitValues}));
        handleSubmit('success','Successfully created rate card');

    }

    const animatedComponents = makeAnimated();


    const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {

        
        const keeper = [];

        const tableName = name == 'ratestatuses' ? rateStatuses : '';

        const id_field = name === 'ratestatuses'? 'Id' : '';
        const name_field = name === 'ratestatuses'? 'Name' :'';
            
        tableName.forEach(x => {
            
            keeper.push({value: x[id_field], label: x[name_field]})

            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['ratestatuses'];
        
        const id_field = name === 'ratestatuses'? 'Id' : '';
        const name_field = name === 'ratestatuses'? 'Name' :'';
        
        names.forEach(x => x === name)
        {
            
            data.forEach(x => {
               
                keeper.push({value: x[id_field], label: x[name_field]})
               
                })
               
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action) => {
        
        const name = action.name;

        if(name === 'jobroles')
        {
            const container = [];
            value.forEach(x => { container.push(x.value) })
            setValues({
                ...values,
                jobroles: [...value]
            })
            setSubmitValues({
                ...submitValues,
                jobroles: container
            })
        }
        else{
            setValues({
                ...values,
                [name]: value
            })
            setSubmitValues({
                ...submitValues,
                [name]: value.value
            })

        }
    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
 
    
    const [date, setDate] = useState(`${1}/${1}/${2022}`);
    

    
    const [summary, setSummary] = useState(false)

    const handleSummary = () => {
        setSummary(!summary);
    }
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[700]}}  >
                
                <CreditCardIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add Rate Card </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
        
    
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <Grid item lg={12} sx={{position: 'sticky', zIndex: 10}}>
                        <Grid item lg={12} sx={{m: 0, p: 0, background: ''}}>
                            <Stack direction="row" alignItems="center" sx={{ background: 'blue[50]', mb: 0}}>
                                <Typography variant="body1" sx={{color: blue[800],fontWeight: 900, textAlign: 'center'}}>Form Summary</Typography>
                                <Button variant="text" size="small" sx={{fontSize: 12, color: blue[900], textAlign: 'center'}} onClick={handleSummary}>{summary? 'Close' : 'Open'}</Button>
                            </Stack>
                        </Grid>
                                
                        <Grid container flexDirection="row" justifyContent="space-between" className={`summary ${summary? 'open' : ''}`} sx={{px: 3, pt: '2px', background: '#f2f7fc', border: '2px solid whitesmoke', borderRadius: 3, boxShadow: 'none', overflowY: 'scroll'}}>
                                {/* <Divider sx={{border: '.5px solid gray'}}/> */}
                                <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability ID: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`AVL - ${!loading? 2 + 1 : ''}`} </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.assessment_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                    <Typography variant="body2" sx={{color: values.subject? '' : blue[500], fontWeight: values.subject? 800 : 500, ml: 5}}>{values.subject? values.subject : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                    <Typography variant="body2" sx={{color: values.applicant? '' : blue[500], fontWeight: values.applicant? 800 : 500, ml: 5}}>{values.applicant? values.applicant : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Name </Typography>
                                    <Typography variant="body2" sx={{color: applicantName? '' : blue[500], fontWeight: applicantName? 800 : 500, ml: 5}}>{applicantName? applicantName : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff Name </Typography>
                                    <Typography variant="body2" sx={{color: staffName? '' : blue[500], fontWeight: staffName? 800 : 500, ml: 5}}>{staffName? staffName : 'null' }</Typography>
                                </Stack>
                                
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Type: </Typography>
                                    <Typography variant="body2" sx={{color: values.type? '' : blue[500], fontWeight: values.type? 800 : 500, ml: 5}}>{values.type? values.type : 'null' }</Typography>
                                </Stack>
                                
                                
                            
                            </Grid>
                        </Grid>
                   
                             
                           
                    {/* <p>isPrimary: {submitValues.isPrimary}</p>
                    <p>fname: {submitValues.firstname}</p>
                    <p>mobile: {submitValues.mobile}</p>
                    <p>contatyp: {submitValues.contacttypes}</p>
                    <p>clients: {submitValues.clients}</p>
                    <p>department: {submitValues.departments}</p>
                    <p>influence: {submitValues.influences}</p>
                    <p>jrole: {submitValues.jobroles}</p> */}
                       
                        <Grid item lg={11}>
                            <Form className="my-3">
                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: 'whitesmoke', borderRadius: '5px', px: 2, py: '5px'}}>Basic Info</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2 ">
                                    <Form.Label className="view-label">Rate Card ID</Form.Label>
                                    <Form.Control disabled type="text" value="RCD-3" placeholder="E.g 123qst1" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Name</Form.Label>
                                    <Form.Control type="text" name="Name" onChange={handleChange} value={values.Name} placeholder="Enter name ..." className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Break</Form.Label>
                                    <Form.Control type="number" name="Break" onChange={handleChange} value={values.Break} placeholder="Break duration ..." className="outline-none border-none" />
                                </Form.Group>
                               
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Status</Form.Label>
                                    <AsyncSelect
                                        value={values.ratestatuses}
                                        name="ratestatuses"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'ratestatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                
                            </Row>

                            <br/>
                            
                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: blue[50], borderRadius: '5px', px: 2, py: '5px'}}>Standard</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Standard Charge Rate</Form.Label>
                                    <Form.Control type="number" name="mobile" onChange={handleChange} value={values.mobile}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Standard Pay Rate LTD/UTR</Form.Label>
                                    <Form.Control type="number" name="StandardChargeRate_LTDUTR" onChange={handleChange} value={values.StandardChargeRate_LTDUTR}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Standard Pay Rate PAYE</Form.Label>
                                    <Form.Control type="number" name="StandardPayRate_PAYE" onChange={handleChange} value={values.StandardPayRate_PAYE}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Standard Pay Rate PAYE 2</Form.Label>
                                    <Form.Control type="number" name="StandardPayRate_PAYE2" onChange={handleChange} value={values.StandardPayRate_PAYE2}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                
                            </Row>

                            <br/>

                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: 'whitesmoke', borderRadius: '5px', px: 2, py: '5px'}}>Night</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Night Charge Rate</Form.Label>
                                    <Form.Control type="number" name="NightChargeRate" onChange={handleChange} value={values.NightChargeRate}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Night Pay Rate LTD/UTR</Form.Label>
                                    <Form.Control type="number" name="NightPayRate_LTDUTR" onChange={handleChange} value={values.NightPayRate_LTDUTR}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Night Pay Rate PAYE</Form.Label>
                                    <Form.Control type="number" name="NightPayRate_PAYE" onChange={handleChange} value={values.NightPayRate_PAYE}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Night Pay Rate PAYE2</Form.Label>
                                    <Form.Control type="number" name="NightPayRate_PAYE2" onChange={handleChange} value={values.NightPayRate_PAYE2}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                            </Row>

                            <br/>
                            
                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: blue[50], borderRadius: '5px', px: 2, py: '5px'}}>Saturday</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Saturday Charge Rate</Form.Label>
                                    <Form.Control type="number" name="SaturdayChargeRate" onChange={handleChange} value={values.SaturdayChargeRate}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Saturday Pay Rate LTD/UTR</Form.Label>
                                    <Form.Control type="number" name="SaturdayPayRate_LTDUTR" onChange={handleChange} value={values.SaturdayPayRate_LTDUTR}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Saturday Pay Rate PAYE</Form.Label>
                                    <Form.Control type="number" name="SaturdayPayRate_PAYE" onChange={handleChange} value={values.SaturdayPayRate_PAYE}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Saturday Pay Rate PAYE2</Form.Label>
                                    <Form.Control type="number" name="SaturdayPayRate_PAYE2" onChange={handleChange} value={values.SaturdayPayRate_PAYE2}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                
                            </Row>

                            <br/>

                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: 'whitesmoke', borderRadius: '5px', px: 2, py: '5px'}}>Sunday</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Sunday Charge Rate</Form.Label>
                                    <Form.Control type="number" name="SundayChargeRate" onChange={handleChange} value={values.SundayChargeRate}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Sunday Pay Rate LTD/UTR</Form.Label>
                                    <Form.Control type="number" name="SundayPayRate_LTDUTR" onChange={handleChange} value={values.SundayPayRate_LTDUTR}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Sunday Pay Rate PAYE</Form.Label>
                                    <Form.Control type="number" name="SundayPayRate_PAYE" onChange={handleChange} value={values.SundayPayRate_PAYE}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Sunday Pay Rate PAYE2</Form.Label>
                                    <Form.Control type="number" name="SundayPayRate_PAYE2" onChange={handleChange} value={values.SundayPayRate_PAYE2}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                            </Row>

                            <br/>
                            
                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: blue[50], borderRadius: '5px', px: 2, py: '5px'}}>Overtime</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Overtime Charge Rate</Form.Label>
                                    <Form.Control type="number" name="OvertimeChargeRate" onChange={handleChange} value={values.OvertimeChargeRate}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Overtime Pay Rate LTD/UTR</Form.Label>
                                    <Form.Control type="number" name="OvertimePayRate_LTDUTR" onChange={handleChange} value={values.OvertimePayRate_LTDUTR}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Overtime Pay Rate PAYE</Form.Label>
                                    <Form.Control type="number" name="OvertimePayRate_PAYE" onChange={handleChange} value={values.OvertimePayRate_PAYE}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Overtime Pay Rate PAYE2</Form.Label>
                                    <Form.Control type="number" name="OvertimePayRate_PAYE2" onChange={handleChange} value={values.OvertimePayRate_PAYE2}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                
                            </Row>

                            <br/>

                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: 'whitesmoke', borderRadius: '5px', px: 2, py: '5px'}}>BHC</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">BH Charge Rate</Form.Label>
                                    <Form.Control type="number" name="BHChargeRate" onChange={handleChange} value={values.BHChargeRate}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">BH Pay Rate LTD/UTR</Form.Label>
                                    <Form.Control type="number" name="BHPayRate_LTDUTR" onChange={handleChange} value={values.BHPayRate_LTDUTR}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">BH Pay Rate PAYE</Form.Label>
                                    <Form.Control type="number" name="BHPayRate_PAYE" onChange={handleChange} value={values.BHPayRate_PAYE}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">BH Pay Rate PAYE2</Form.Label>
                                    <Form.Control type="number" name="BHPayRate_PAYE2" onChange={handleChange} value={values.BHPayRate_PAYE2}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                            
                            </Row>

                            <br/>
                            
                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: blue[50], borderRadius: '5px', px: 2, py: '5px'}}>Saturday Night</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Saturday Charge Rate</Form.Label>
                                    <Form.Control type="number" name="SaturdayNightChargeRate" onChange={handleChange} value={values.SaturdayNightChargeRate}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Saturday Pay Rate LTD/UTR</Form.Label>
                                    <Form.Control type="number" name="SaturdayNightPayRate_LTDUTR" onChange={handleChange} value={values.SaturdayNightPayRate_LTDUTR}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Saturday Pay Rate PAYE</Form.Label>
                                    <Form.Control type="number" name="SaturdayNightPayRate_PAYE" onChange={handleChange} value={values.SaturdayNightPayRate_PAYE}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Saturday Pay Rate PAYE2</Form.Label>
                                    <Form.Control type="number" name="SaturdayNightPayRate_PAYE2" onChange={handleChange} value={values.SaturdayNightPayRate_PAYE2}  placeholder="Rate here" className="outline-none border-none" />
                                </Form.Group>
                                
                            </Row>

                        </Form>
                        
                 

                    
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[600], '&:hover': {color: blue[900]}}} onClick={handleSave}>Save Card</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddRateCardDialog
