import React from 'react';
import AddTimesheetDialog from './AddTimesheetDialog';
import ViewTimesheetDialog from './ViewTimesheetDialog';
import DeleteTimesheetDialog from './DeleteTimesheetDialog';



const TimesheetDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        { 
            type === "create"?

            <AddTimesheetDialog open={open} onClose={onClose}  handleSubmit={handleSubmit} fullWidth />
            
            : type === "view"?

            <ViewTimesheetDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />

            : type === "delete"?

            <DeleteTimesheetDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default TimesheetDialogs
