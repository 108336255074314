import { CHECKINS_LIST_REQUEST, CHECKINS_LIST_SUCCESS, CHECKINS_LIST_FAIL, VIEW_CHECKIN_FAIL, VIEW_CHECKIN_REQUEST, VIEW_CHECKIN_SUCCESS, CHECKIN_CREATE_REQUEST, CHECKIN_CREATE_SUCCESS, CHECKIN_CREATE_FAIL, CHECKIN_UPDATE_FAIL, CHECKIN_UPDATE_REQUEST, CHECKIN_UPDATE_SUCCESS } from '../Constants/checkInConstants';
import api from '../../config/apiConfig';


const getCheckInsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: CHECKINS_LIST_REQUEST});
        const {data} = await api.get('/checkins');
        // console.log('CheckIns data is:' + data[0]['CheckIn_Name'])
        dispatch({type: CHECKINS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: CHECKINS_LIST_FAIL, payload: error})
    }
} 

const submitCheckIn = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: CHECKIN_CREATE_REQUEST});
        
        const {data} = await api.post(`/checkins/`, values);
        console.log('CheckIn data is:' + data)
        dispatch({type: CHECKIN_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: CHECKIN_CREATE_FAIL, payload: error})
    }
} 

const getCheckIn = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_CHECKIN_REQUEST});
        
        const {data} = await api.get(`/checkins/ ${id}`);
        console.log('CheckIn data is:' + data)
        dispatch({type: VIEW_CHECKIN_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_CHECKIN_FAIL, payload: error})
    }
}

const editCheckIn = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: CHECKIN_UPDATE_REQUEST});
        
        const {data} = await api.put(`/checkins/`, values);
        console.log('CheckIn data is:' + data)
        dispatch({type: CHECKIN_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: CHECKIN_UPDATE_FAIL, payload: error})
    }
}



export {getCheckInsList, submitCheckIn, getCheckIn, editCheckIn }