import { OPPORTUNITYSTATUSES_LIST_REQUEST, OPPORTUNITYSTATUSES_LIST_SUCCESS, OPPORTUNITYSTATUSES_LIST_FAIL, VIEW_OPPORTUNITYSTATUS_FAIL, VIEW_OPPORTUNITYSTATUS_REQUEST, VIEW_OPPORTUNITYSTATUS_SUCCESS, OPPORTUNITYSTATUS_CREATE_FAIL, OPPORTUNITYSTATUS_UPDATE_REQUEST, OPPORTUNITYSTATUS_UPDATE_FAIL, OPPORTUNITYSTATUS_DELETE_REQUEST, OPPORTUNITYSTATUS_DELETE_FAIL, OPPORTUNITYSTATUS_CREATE_REQUEST, OPPORTUNITYSTATUS_CREATE_SUCCESS, OPPORTUNITYSTATUS_UPDATE_SUCCESS, OPPORTUNITYSTATUS_DELETE_SUCCESS } from "../Constants/opportunityStatusConstants";

const opportunityStatusesListReducer = (state = {opportunityStatuses: []}, action) => {

    switch (action.type)
    {
        case OPPORTUNITYSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case OPPORTUNITYSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and opportunityStatuss data is:' + action.payload)
            return {
                loading: false,
                opportunityStatuses: action.payload
            }
        case OPPORTUNITYSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const opportunityStatusCreateReducer = (state = {opportunityStatuses: []}, action) => {

    switch (action.type) {

        case OPPORTUNITYSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case OPPORTUNITYSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                opportunityStatuses: [...state.opportunityStatuses, {Applicant_Id: 2, Name: value}]
            };

        case OPPORTUNITYSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const opportunityStatusViewReducer = (state= {opportunityStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_OPPORTUNITYSTATUS_REQUEST:
            return {loading: true};
        case VIEW_OPPORTUNITYSTATUS_SUCCESS:
            return {
                    loading: false,
                    opportunityStatus: action.payload
                }
        case VIEW_OPPORTUNITYSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const opportunityStatusUpdateReducer = (state = {opportunityStatuss: {}}, action) => {

    switch (action.type) {

        case OPPORTUNITYSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case OPPORTUNITYSTATUS_UPDATE_SUCCESS:

            return {

            }
        case OPPORTUNITYSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const opportunityStatusDeleteReducer = (state = {opportunityStatuss: {}}, action) => {

    switch (action.type) {
        case OPPORTUNITYSTATUS_DELETE_REQUEST:
            return {
                
            }
        case OPPORTUNITYSTATUS_DELETE_SUCCESS:
            return {

            }
        case OPPORTUNITYSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {opportunityStatusesListReducer, opportunityStatusCreateReducer, opportunityStatusViewReducer, opportunityStatusUpdateReducer, opportunityStatusDeleteReducer }