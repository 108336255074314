import React, {useState, useEffect, useRef} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, TextField, List, ListItem, ListItemAvatar, Avatar, ListItemText} from '@mui/material';
import { blueGrey, orange, lightGreen, red, blue, deepOrange, pink, lightBlue } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import AsyncSelect from 'react-select/async';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicantsList } from '../../../../../Redux/Actions/ApplicantsActions';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getAssessmentTypesList } from '../../../../../Redux/Actions/AssessmentTypesActions';
import { getAssessmentResultsList } from '../../../../../Redux/Actions/AssessmentResultsActions';
import { getAssessmentStatusesList } from '../../../../../Redux/Actions/AssessmentStatusesActions';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import useAlert from '../../../../../Hooks/useAlert';
import ComplexAlert from '../../../../ComplexAlert';
import makeAnimated from 'react-select/animated'
import EditInDialog from '../../../../Utilities/EditInDialog';
import { getTask, deleteTask, editTask } from '../../../../../Redux/Actions/TasksActions';
import DialogNavigation from '../../../../Utilities/DialogNavigation';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import ME from '../../../../../assets/ME.jpg';
  


const ViewProfitLossDialog = ({open, onClose, type, info, handleSubmit}) => {


    console.log('PLEASE DISPLAY INFO FOR ME:' + info.name)

    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET task
    const {task, loading} = useSelector(state => state.task_View);
    //GET DIVISIONS
    const {divisions, loading: loading_divisions} = useSelector(state => state.divisions_List);
    //GET ASSESSMENT TYPE
    const {assessmentTypes, loading: loading_types} = useSelector(state => state.assessmentTypes_List);
    //GET ASSESSMENT RESULT
    const {assessmentResults, loading: loading_results} = useSelector(state => state.assessmentResults_List);
    //GET OFFICE STAFF AS CONSULTANT
    const {staff, loading: loading_staff} = useSelector(state => state.staff_List);
    //GET ASSESSMENT STATUS
    const {assessmentStatuses, loading: loading_statuses} = useSelector(state => state.assessmentStatuses_List);
    //GET APPLICANTS
    const {loading: loading_applicants, applicants} = useSelector((state) => state.applicants_List);
    
       
    //CURRENT DATE
    const d = new Date(), yyyy = d.getFullYear(), mm = d.getMonth(), dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getTask(info))
        dispatch(getDivisions());
        dispatch(getApplicantsList());
        dispatch(getStaffList());
        dispatch(getAssessmentTypesList());
        dispatch(getAssessmentResultsList());
        dispatch(getAssessmentStatusesList());
       
    }, []);

    const [item, setItem] = useState({})

    useEffect(() => {
        //console.log('I have run')
        if(task)
        {
            setItem({...task})
        }
    }, [task])
    

    const [values, setValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const handleEditChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setItem({
                ...item,
                [name]: value
            }); 
        }
        else
        {
            setItem({
                ...item,
                [name]: value
            });
 
        }
    }


    

    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    let submitAnchor = useRef(null)

    const handleEditClick = () => {
        setIsEditing(true);
        showAlert('info','You can now edit this task', true)
        
    }

    const handleCancel = () => {
        setEdited([])
        setIsDeleting(false);
        if(isEditing)
        {
            setIsEditing(false);
            dispatch(getTask(item.Id))
        }
    }

    const handleDeleteClick = () => {
        setIsDeleting(true);
        showAlert('error','You are about to delete a task',true)
        dispatch(deleteTask(item.Id))
    }

    const handleActionClick = () => {
        
        const type =  submitAnchor.current.textContent;
        if(type === 'Save Changes'){
            submitChanges()
        } else if(type === 'Delete Task'){
            dispatch(deleteTask(item.Id))
            handleSubmit('success', `Successfully deleted the task with ID: ${item.Id}`)
        }
    }

    const submitChanges = () => {
        console.log('Ready to submit')
        //we want to submit the values in the state 'item'
        dispatch(editTask(item.id, item))
    }

    //USE ALERT
    const {alert, showAlert} = useAlert();
    
    const animatedComponents = makeAnimated();


    const [date, setDate] = useState({
        date: '',
        deadline: '',
    });

    const handleDateChange = (newValue, name) => {
        
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate({
            ...date,
            [name]: newValue
        });
        setItem({
            ...item,
            [name]: `${month+1}/${day}/${year}`
        })
        setSubmitValues({
            ...submitValues,
            [name]: `${day}/${month+1}/${year}`
        })
    }
    const [edited, setEdited] = useState([])

    const getColor = (name) => {
        console.log('have reached colour function')
        const hasEdited = edited.find(x=> x === name? x : '')
        if(hasEdited)
        {
            return lightGreen[500]
        }
        else{

            return blueGrey[300]
        }
    }
    const handleSelectEditChange = (value, action) => {

        const name = action.name;

        console.log('VALUE:' + value.label)
        setItem({
            ...item,
            [name]: value.label
        })
        setValues({
            ...values,
            [name]: value
        })
        setEdited([
            ...edited,
            name
        ])
    }
    const [inputValue, setInputValue] = useState('');
    
    const handleInputChange = (newValue) => {
        
        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >
            {/* <h3>I am receiving info: {info.Assessment_Subject}</h3> */}
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: isEditing? lightBlue[500]: isDeleting? pink[500] : blue[800]}} className=""  >
                <Stack direction="row" alignItems="center">
                    <AssessmentIcon sx={{color: blue[300], mr: 2, fontSize: '1.5rem'}}/>
                    <Typography variant="h6" sx={{color: isEditing? 'white' : blue[300],fontWeight: 700}}>{isEditing? 'Editing Task' : isDeleting? 'Deleting Task' : 'Viewing Task'}</Typography>
                </Stack>
                <Typography variant="body2" sx={{color: "white",fontWeight: 500, background: 'blue[700]', borderRadius: 3, px: 2, py: '5px'}}>{item.Status}</Typography>
            </DialogTitle>

        {alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : ''}
        <Divider/> 
        {loading? <LinearProgress sx={{ color: 'green'}} /> : ''}
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">
                <Grid item lg={12} sx={{mr: 5}}>
                    <Grid container justifyContent="space-between">
                        <Stack direction="row" alignItems="center">
                            <Typography sx={{fontSize: '1.4rem', fontFamily: 'Poppins-Medium', fontWeight: '800', color: blue[500]}}>{`${item.Task_Name} | ${item.Date} | ${item.Worker} `}</Typography>
                            <Typography sx={{fontSize: 13, fontFamily: 'Poppins-Medium', color: 'white', background: orange[400], borderRadius: '3px', px: 1, ml: 2}}>{`${item.Deadline}`}</Typography>
                        </Stack>
                        <EditInDialog isEditing={isEditing} isDeleting={isDeleting} handleCancel={handleCancel} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick}/>
                    </Grid>
                    <Divider sx={{color: blue[400], mt: 2}}/>
                </Grid>
                <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                    <DialogNavigation/>
                </Grid>
                <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[800], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Task ID</Form.Label>
                                    <Form.Control name="id" autoFocus="true" disabled type="text" value={`TSK - ${item.Id}`} className={isEditing? "item-id" :"border-0 item-id  autofocus"} />
                                </Form.Group>
 
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Task Name</Form.Label>
                                    <Form.Control onChange={handleEditChange} name="Name" disabled={isEditing? false : true} type="text" value={item.Name} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="date"
                                            label="Task Date"
                                            value={item.Date}
                                            onChange={(e) => handleDateChange(e, "Date")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background: 'rgb(242, 247, 252)', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="deadline"
                                            label="Task Deadline"
                                            value={item.Deadline}
                                            onChange={(e) => handleDateChange(e, "Deadline")}
                                            renderInput={(params) => <TextField size="small" disabled variant="outlined" sx={{background: 'rgb(242, 247, 252)', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Form.Group>
                            </Row>
                        
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="mb-0 p-0 view-label">Task Description</Form.Label>
                                    <Form.Control 
                                        onChange={handleEditChange}
                                        name="Description" 
                                        disabled={isEditing? false : true} 
                                        type="email" 
                                        value={item.Description} 
                                        className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                        
                        
                        
                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: blueGrey[200], fontSize: 17, fontFamily: 'Poppins', background: 'blue[700]', borderRadius: '5px', py: '5px',}}>Task Particulars</Typography>
                        <Divider sx={{color: blue[400], mt: 1, mb: 1}}/>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Task Type</Form.Label>
                                    {
                                    isEditing? 
                                        <>
                                        <Typography component="span" sx={{ml: 1, fontSize: 13, fontFamily: 'Poppins' , color: 'white', background: getColor('Type'), borderRadius: '5px', px: '5px', py: '2px'}} ><CircleIcon fontSize="small" color="action" sx={{fontSize: '7px', color: lightGreen[300]}}/> {item.Type}</Typography>
                                        <br/>
                                            <AsyncSelect
                                                value={values.Type}
                                                name="Type"
                                                cacheOptions
                                                loadOptions={(value, action) => loadOptions(value, 'tasktypes')}
                                                defaultOptions
                                                onChange= {(value, action) => handleSelectEditChange(value, action)}
                                                components={animatedComponents}
                                                placeholder="Search for types ..."
                                                onInputChange={handleInputChange}
                                            /> </>  :
                                            <>
                                            <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: blue[400], color: 'white', borderRadius: 10, px: '7px', py: '5px'}}>{item.Type}</Typography>
                                    </>
                                    }
                                </Form.Group>
                                
                            </Row>

                            
                           
                            
                            <Typography variant="body2" sx={{display: 'inline',mt: 2, color: blueGrey[200], fontSize: 17, fontFamily: 'Poppins', background: 'blue[700]', borderRadius: '5px', py: '5px',}}>Notes</Typography>
                            <Divider sx={{color: blue[500], mt: 1, mb: 1}}/>
                             <Row>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{color: orange[300]}} src={ME} sx={{backgroundSize: 'cover', backgroundPosition: 'top'}}>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Noter giver's name" secondary="08/03/22 17:00" sx={{fontFamily: 'Poppins'}} primaryTypographyProps={{color: blue[600], fontFamily: 'Poppins'}}/>
                                    </ListItem>
                                </List>
                                
                                
                            </Row>
                        </Form>
                    </Grid>

                    
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            {!isEditing && !isDeleting? null : <Button size="small" ref={submitAnchor} sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: isEditing? lightBlue[500]: isDeleting? pink[500] : blue[700], '&:hover': {color: blueGrey[900]}}} onClick={handleActionClick}>{isEditing? 'Save Changes' : isDeleting? 'Delete Task' : null}</Button>}
        </DialogActions>
    </Dialog>
    )
}

export default ViewProfitLossDialog
