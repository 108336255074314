import { WORKINGWEEKS_LIST_REQUEST, WORKINGWEEKS_LIST_SUCCESS, WORKINGWEEKS_LIST_FAIL, VIEW_WORKINGWEEK_FAIL, VIEW_WORKINGWEEK_REQUEST, VIEW_WORKINGWEEK_SUCCESS, WORKINGWEEK_CREATE_REQUEST, WORKINGWEEK_CREATE_SUCCESS, WORKINGWEEK_CREATE_FAIL, WORKINGWEEK_UPDATE_FAIL, WORKINGWEEK_UPDATE_REQUEST, WORKINGWEEK_UPDATE_SUCCESS } from '../Constants/workingWeekConstants';
import api from '../../config/apiConfig';


const getWorkingWeeksList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: WORKINGWEEKS_LIST_REQUEST});
        const {data} = await api.get('/workingweeks');
        // console.log('WorkingWeeks data is:' + data[0]['WorkingWeek_Name'])
        dispatch({type: WORKINGWEEKS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: WORKINGWEEKS_LIST_FAIL, payload: error})
    }
} 

const submitWorkingWeek = (statevValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: WORKINGWEEK_CREATE_REQUEST});
        
        const {data} = await api.post(`/workingweeks/`, postValues);
        console.log('WorkingWeek data is:' + data)
        dispatch({type: WORKINGWEEK_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: WORKINGWEEK_CREATE_FAIL, payload: error})
    }
} 

const getWorkingWeek = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_WORKINGWEEK_REQUEST});
        
        const {data} = await api.get(`/workingweeks/ ${id}`);
        console.log('WorkingWeek data is:' + data)
        dispatch({type: VIEW_WORKINGWEEK_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_WORKINGWEEK_FAIL, payload: error})
    }
}

const editWorkingWeek = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: WORKINGWEEK_UPDATE_REQUEST});
        
        const {data} = await api.put(`/workingweeks/`, values);
        console.log('WorkingWeek data is:' + data)
        dispatch({type: WORKINGWEEK_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: WORKINGWEEK_UPDATE_FAIL, payload: error})
    }
}



export {getWorkingWeeksList, submitWorkingWeek, getWorkingWeek, editWorkingWeek }