export const OPPORTUNITYTYPES_LIST_REQUEST = 'OPPORTUNITYTYPES_LIST_REQUEST';
export const OPPORTUNITYTYPES_LIST_SUCCESS = 'OPPORTUNITYTYPES_LIST_SUCCESS';
export const OPPORTUNITYTYPES_LIST_FAIL = 'OPPORTUNITYTYPES_LIST_FAIL'; 

export const OPPORTUNITYTYPE_CREATE_REQUEST = 'OPPORTUNITYTYPE_CREATE_REQUEST'
export const OPPORTUNITYTYPE_CREATE_SUCCESS = 'OPPORTUNITYTYPE_CREATE_SUCCESS'
export const OPPORTUNITYTYPE_CREATE_FAIL = 'OPPORTUNITYTYPE_CREATE_FAIL'


export const OPPORTUNITYTYPE_UPDATE_REQUEST = 'OPPORTUNITYTYPE_UPDATE_REQUEST'
export const OPPORTUNITYTYPE_UPDATE_SUCCESS = 'OPPORTUNITYTYPE_UPDATE_SUCCESS'
export const OPPORTUNITYTYPE_UPDATE_FAIL = 'OPPORTUNITYTYPE_UPDATE_FAIL'

export const OPPORTUNITYTYPE_DELETE_REQUEST = 'OPPORTUNITYTYPE_DELETE_REQUEST'
export const OPPORTUNITYTYPE_DELETE_SUCCESS = 'OPPORTUNITYTYPE_DELETE_SUCCESS'
export const OPPORTUNITYTYPE_DELETE_FAIL = 'OPPORTUNITYTYPE_DELETE_FAIL'

export const VIEW_OPPORTUNITYTYPE_REQUEST = 'VIEW_OPPORTUNITYTYPE_REQUEST'
export const VIEW_OPPORTUNITYTYPE_SUCCESS = 'VIEW_OPPORTUNITYTYPE_SUCCESS'
export const VIEW_OPPORTUNITYTYPE_FAIL = 'VIEW_OPPORTUNITYTYPE_FAIL'