import React, {useState, useRef, useEffect} from 'react'
import { Typography, Grid, Stack, Divider, } from '@mui/material';
import {useParams} from 'react-router-dom';
import { blueGrey, blue } from '@mui/material/colors'; 
import { useSelector, useDispatch } from 'react-redux';
import PageHeader from '../../../Components/PageHeader';
import Crumbs from '../../../Components/Utilities/Crumbs';
import ViewTitleComponent from '../../../Components/Utilities/ViewTitleComponent';
import FormBrief from '../../../Components/Utilities/FormBrief';
import FormHeading from '../../../Components/Utilities/FormHeading';
import TimesheetListItem from '../../../Components/Utilities/TimesheetListItem';
import TimesheetListHeadings from '../../../Components/Utilities/TimesheetListHeadings';
import ViewStatus from '../../../Components/Utilities/ViewStatus';
import { getTimesheet } from '../../../Redux/Actions/TimesheetActions';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
//OPERATION FUNCTIONS
import { getShiftsByWorkerId } from '../../../Components/Dialogs/CRM/Staff/CheckInDialogs/addToWorkersShifts';

const ViewTimesheet = () => { 
 
    const {id} = useParams();
    const dispatch = useDispatch() 

    //GET TIMESHEET
    const timesheet_records = useSelector(state => state.timesheet_View);
    const {loading, timesheet} = timesheet_records;

    //SHIFTS
    const [shifts, setShifts] = useState([])
    const [count, setCount] = useState(0)

    useEffect(()=> { 


        dispatch(getTimesheet(id))
        getShiftsByWorkerId(id)
        .then((data) => setShifts([...data]))
        
        //.then(calculateFigures())
        //.catch(console.log('THERE WAS AN ERROR. REMI'))
    
    }, [])

    const calculateFigures = () => {
        
    }

    

    return (
        <>
            {/* <h1>Shifts are: {shifts? shifts[0].WS_Worker : 'loading'}</h1> */}
            <PageHeader page="Timesheet Details" previous timesheets />
            <Crumbs root="CRM" middle="timesheets" end="Details"/>

            <Grid container justifyContent="space-between" alignItems= "center"  sx={{mt: 2, mb: 2, px:0, borderRadius: '20px', color: 'gray', background: ''}}>
                <ViewStatus/>
                {loading? '' : timesheet.TimesheetWorker}
            </Grid>

            <Grid container  alignItems="" justifyContent="space-between" sx={{mt: 3, mb: 0, background: ''}}>
                 
                <Grid item lg={12} sx={{mb: 0, pb: 0, background: 'white', borderRadius: '10px 10px 0 0', boxShadow: ".5px .5px 10px rgba(227, 242, 253, .9)"}}>
                    
                    {/* <ViewTitleComponent title={shifts = !null? shifts[0].WorkerName : 'Undefined'}/> */}
                    <ViewTitleComponent title={loading? '': timesheet.Worker}/>
                    {/* to note: <FormBrief name={shifts == !null? shifts[0].WorkerName : 'Undefined'} icon="time" iconBrief="Timesheet" hours={110} minutes={30} date="12/02/2022 - 12/03/2022"/> */}
                    <FormBrief name={loading? '': timesheet.Worker} icon="time" iconBrief="Timesheet" hours={110} minutes={30} date="12/02/2022 - 12/03/2022"/>
                    <Divider sx={{color: blue[400], pt: '2px'}}/>
                    <Grid container sx={{background: '', p: 2, mt: 2, borderRadius: '5px 5px 0 0'}}>

                        <Grid item lg={12} sx={{mt: 1}}>
                            <FormHeading title="Completed Jobs" badge="Info" icon=""/>
                            <TimesheetListHeadings/>
                            {/* List Items */}
                            {
                                shifts?
                                shifts.map((shift, index) => <TimesheetListItem no={index + 1} shift={`${shift.WorkerName} | ${shift.Date} | ${shift.Client}`} division={shift.Division} role={shift.JobRole} status={shift.Status} sum other/>)
                                : 
                                <HorizontalLoading/>
                            }
                            
                        </Grid>

                        <Grid item lg={12} >
                            <Divider sx={{my: 5, color: blue[100]}}/>
                            <Grid container flexDirection="row" alignItems="center" justifyContent="flex-end">
                                <Stack direction="column" sx={{mr: 3}}>
                                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blue[500]}}>Total breaks deducted </Typography>
                                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blue[500]}}>Total Paid Hours </Typography>
                                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blue[500]}}>Total Pay </Typography>
                                </Stack>
                                <Stack direction="column">
                                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blueGrey[700], ml: 1}}> 2hrs</Typography>
                                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blueGrey[700], ml: 1}}> 21hrs</Typography>
                                    <Typography sx={{fontSize: 15 ,fontWeight: 600, fontFamily: 'Poppins-Regular', py: '', px: 0, color:  blueGrey[700], ml: 1}}> ${'2112.00'}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
              
                </Grid>

               

            </Grid>
        </>
    )
}

export default ViewTimesheet

{/* <Grid item sx={{pr: 4, borderRight: '1px solid #eceff1'}}>
<Stack sx={{ml: 2}}>
    <Typography variant="h1" sx={{fontSize: '1.7rem',fontWeight: 800, py: '', px: 0,color: 'blueGrey[800]', background: 'blueGrey[200]'}}>{values ? values.name : 'Employee Name'}</Typography>
        <Divider orientation="vertical" variant="middle" />
    <Stack direction="row" justifyContent="space-between" sx={{mt: '7px'}}>
            <Box sx={{px:0, py: 0, display: 'flex', justifyContent: "space-around", alignItems: "center", background: '', borderRadius: '10px'}}>
                <Typography variant="" sx={{fontSize: '', fontWeight: 500, py: 0, pl: 0,color: blueGrey[700], background: 'blueGrey[200]'}}>{headerFor == 'worker-details' ? 'Department:' : 'Tag:'}</Typography>
                <Typography variant="" sx={{fontSize: '.7rem', fontWeight: 500, px: 1, py: 0,ml: 1,color: blueGrey[50], background: 'dodgerblue', borderRadius: '10px'}}>{headerFor == 'worker-details' ? 'Employee Department' : 'Immediate Hire'}</Typography>
            </Box>
    </Stack>

    
</Stack>
</Grid> */}