import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox} from '@mui/material';
import { blueGrey, cyan, purple, blue, teal, orange, deepOrange, green, pink } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import api from '../../../../../config/apiConfig';
import { getClientsList } from '../../../../../Redux/Actions/ClientsActions';
import { getDepartmentsList } from '../../../../../Redux/Actions/DepartmentActions';
import { getContactTypesList } from '../../../../../Redux/Actions/ContactTypeActions';
import { getInfluencesList } from '../../../../../Redux/Actions/InfluenceActions';
import { submitContact } from '../../../../../Redux/Actions/ContactActions';


const AddContactDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();  

    //GET CONTACTTYPES
    const contacts_ListState = useSelector(state => state.contacts_List);
    const {contacts, loading} = contacts_ListState;

    //GET CONTACTTYPES
    const contactTypes_ListState = useSelector(state => state.contactTypes_List);
    const {contactTypes, loading: loading_contactTypes} = contactTypes_ListState;

    //GET DEPARTMENTS
    const departments_ListState = useSelector(state => state.departments_List);
    const {departments, loading: loading_departments} = departments_ListState;

    //GET INFLUENCES
    const influences_ListState = useSelector(state => state.influences_List);
    const {influences, loading: loading_influences} = influences_ListState;

    //GET CLIENTS
    const clients_ListState = useSelector(state => state.clients_List);
    const {clients, loading: loading_clients} = clients_ListState;

    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getClientsList());
        dispatch(getDepartmentsList());
        dispatch(getContactTypesList());
        dispatch(getInfluencesList());
        
       
    }, [])
    

    const [values, setValues] = useState({
            
            isPrimary: '',
            firstname: '' , 
            surname: '' , 
            contacttypes: '' , 
            email: '' , 
            mobile: '' , 
            office: '' , 
            clients: '' , 
            departments: '',
            jobroles: '',
            influences: '',
        });

    const [submitValues, setSubmitValues] = useState({
            
            isPrimary: '',
            firstname: '' , 
            surname: '' , 
            contacttypes: '' , 
            email: '' , 
            mobile: '' , 
            office: '' , 
            clients: '' , 
            departments: '',
            jobroles: '',
            influences: '',
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {

        const {name, value, checked, type} = e.target;

        setValues({
            ...values,
            [name]: value
        });
        setSubmitValues({
            ...submitValues,
            [name]: value
        })
        
     
    }

    const handleSave = () => {
        console.log('in save function')
        dispatch(submitContact({...submitValues}));
        handleSubmit('success','Successfully saved contact infomation');

    }

    const animatedComponents = makeAnimated();


    const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {

        
        const keeper = [];

        const tableName = name == 'influences' ? influences : name == 'clients' ? clients : name == 'departments' ? departments : name == 'contacttypes' ? contactTypes : '';

        const id_field = name === 'contacttypes'? 'Type_Id' : name === 'clients'? 'Client_Id': name === 'departments'? 'Department_Id' : name === 'jobroles'? 'Role_Id': name === 'influences'? 'Id' : name === 'contacts'? 'Id' : '';
        const name_field = name === 'contacttypes'? 'Type_Name' : name === 'clients'? 'Name': name === 'departments'? 'Department_Name' : name === 'jobroles'? 'Name' : name === 'influences'? 'Name' : name === 'contacts'? 'First_Name' :'';
            
        tableName.forEach(x => {
            if(name === 'contacts')
            {
                keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
            }
            else{
                keeper.push({value: x[id_field], label: x[name_field]})

            }
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['contacttypes', 'clients', 'departments', 'jobroles', 'influences', 'contacts'];
        
        const id_field = name === 'contacttypes'? 'Type_Id' : name === 'clients'? 'Client_Id': name === 'departments'? 'Department_Id' : name === 'jobroles'? 'Role_Id': name === 'influences'? 'Id' : name === 'contacts'? 'Id' : '';
        const name_field = name === 'contacttypes'? 'Type_Name' : name === 'clients'? 'Name': name === 'departments'? 'Department_Name' : name === 'jobroles'? 'Name' : name === 'influences'? 'Name' : name === 'contacts'? 'First_Name' :'';
        
        names.forEach(x => x === name)
        {
            
            data.forEach(x => {
                if(name === 'contacts' )
                {
                    keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
                }
                else{

                    keeper.push({value: x[id_field], label: x[name_field]})
                }
                })
               
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action) => {
        
        const name = action.name;

        if(name === 'jobroles')
        {
            const container = [];
            value.forEach(x => { container.push(x.value) })
            setValues({
                ...values,
                jobroles: [...value]
            })
            setSubmitValues({
                ...submitValues,
                jobroles: container
            })
        }
        else{
            setValues({
                ...values,
                [name]: value
            })
            setSubmitValues({
                ...submitValues,
                [name]: value.value
            })

        }
    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
 
    
    const [date, setDate] = useState(`${1}/${1}/${2022}`);
    

    
    const [summary, setSummary] = useState(false)

    const handleSummary = () => {
        setSummary(!summary);
    }
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[700]}}  >
                
                <ContactPageIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add Contact </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
        
    
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <Grid item lg={12} sx={{position: 'sticky', zIndex: 10}}>
                        <Grid item lg={12} sx={{m: 0, p: 0, background: ''}}>
                            <Stack direction="row" alignItems="center" sx={{ background: 'blue[50]', mb: 0}}>
                                <Typography variant="body1" sx={{color: blue[800],fontWeight: 900, textAlign: 'center'}}>Form Summary</Typography>
                                <Button variant="text" size="small" sx={{fontSize: 12, color: blue[900], textAlign: 'center'}} onClick={handleSummary}>{summary? 'Close' : 'Open'}</Button>
                            </Stack>
                        </Grid>
                                
                        <Grid container flexDirection="row" justifyContent="space-between" className={`summary ${summary? 'open' : ''}`} sx={{px: 3, pt: '2px', background: '#f2f7fc', border: '2px solid whitesmoke', borderRadius: 3, boxShadow: 'none', overflowY: 'scroll'}}>
                                {/* <Divider sx={{border: '.5px solid gray'}}/> */}
                                <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability ID: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`AVL - ${!loading? 2 + 1 : ''}`} </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.assessment_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                    <Typography variant="body2" sx={{color: values.subject? '' : blue[500], fontWeight: values.subject? 800 : 500, ml: 5}}>{values.subject? values.subject : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                    <Typography variant="body2" sx={{color: values.applicant? '' : blue[500], fontWeight: values.applicant? 800 : 500, ml: 5}}>{values.applicant? values.applicant : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Name </Typography>
                                    <Typography variant="body2" sx={{color: applicantName? '' : blue[500], fontWeight: applicantName? 800 : 500, ml: 5}}>{applicantName? applicantName : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff Name </Typography>
                                    <Typography variant="body2" sx={{color: staffName? '' : blue[500], fontWeight: staffName? 800 : 500, ml: 5}}>{staffName? staffName : 'null' }</Typography>
                                </Stack>
                                
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Type: </Typography>
                                    <Typography variant="body2" sx={{color: values.type? '' : blue[500], fontWeight: values.type? 800 : 500, ml: 5}}>{values.type? values.type : 'null' }</Typography>
                                </Stack>
                                
                                
                            
                            </Grid>
                        </Grid>   
                           
                    {/* <p>isPrimary: {submitValues.isPrimary}</p>
                    <p>fname: {submitValues.firstname}</p>
                    <p>mobile: {submitValues.mobile}</p>
                    <p>contatyp: {submitValues.contacttypes}</p>
                    <p>clients: {submitValues.clients}</p>
                    <p>department: {submitValues.departments}</p>
                    <p>influence: {submitValues.influences}</p>
                    <p>jrole: {submitValues.jobroles}</p> */}
                       
                        <Grid item lg={11}>
                            <Form className="my-3">
                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: 'whitesmoke', borderRadius: '5px', px: 2, py: '5px'}}>Basic Info</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2 ">
                                    <Form.Label className="view-label">Contact ID</Form.Label>
                                    <Form.Control disabled type="text" value="CNT-3" placeholder="E.g 123qst1" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">First Name</Form.Label>
                                    <Form.Control type="text" name="firstname" onChange={handleChange} value={values.firstname} placeholder="Enter first name ..." className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Surname</Form.Label>
                                    <Form.Control type="text" name="surname" onChange={handleChange} value={values.surname} placeholder="Enter surname ..." className="outline-none border-none" />
                                </Form.Group>
                               
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Contact Type</Form.Label>
                                    <AsyncSelect
                                        value={values.contacttypes}
                                        name="contacttypes"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'contacttypes')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for type ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label w-100">isPrimary?</Form.Label>
                                    <FormControlLabel control={<Checkbox name="isPrimary" value="Yes" checked={values.isPrimary === 'Yes'? true : null} onChange={handleChange} />} label="Yes" />
                                    <FormControlLabel control={<Checkbox name="isPrimary" value="No" checked={values.isPrimary === 'No'? true : null} onChange={handleChange} />} label="No" />
                                </Form.Group>
                            </Row>

                            <br/>
                            
                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: blue[50], borderRadius: '5px', px: 2, py: '5px'}}>Contact Details</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Mobile</Form.Label>
                                    <Form.Control type="text" name="mobile" onChange={handleChange} value={values.mobile}  placeholder="Enter mobile ..." className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Office Phone</Form.Label>
                                    <Form.Control type="text" name="office" onChange={handleChange} value={values.office}  placeholder="Enter home phone ..." className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Email</Form.Label>
                                    <Form.Control type="text" name="email" onChange={handleChange} value={values.email}  placeholder="Enter email ..." className="outline-none border-none" />
                                </Form.Group>
                                
                            </Row>

                            <br/>

                            <Row>
                            <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: 'whitesmoke', borderRadius: '5px', px: 2, py: '5px'}}>Other Details</Typography>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Related Client</Form.Label>
                                    <AsyncSelect
                                        value={values.clients}
                                        name="clients"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'clients')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for client ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Department</Form.Label>
                                    <AsyncSelect
                                        value={values.departments}
                                        name="departments"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'departments')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for department ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Job Role</Form.Label>
                                        <AsyncSelect
                                            value={values.jobroles}
                                            name="jobroles"
                                            cacheOptions
                                            isMulti
                                            loadOptions={(value, action) => loadOptions(value, 'jobroles')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for roles ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Influence</Form.Label>
                                    <AsyncSelect
                                        value={values.influences}
                                        name="influences"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'influences')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for influence ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                               
                                
                            </Row>
                        </Form>
                        
                 

                    
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[600], '&:hover': {color: blue[900]}}} onClick={handleSave}>Save Contact</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddContactDialog
