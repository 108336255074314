export const REQUESTTYPES_LIST_REQUEST = 'REQUESTTYPES_LIST_REQUEST';
export const REQUESTTYPES_LIST_SUCCESS = 'REQUESTTYPES_LIST_SUCCESS';
export const REQUESTTYPES_LIST_FAIL = 'REQUESTTYPES_LIST_FAIL'; 

export const REQUESTTYPE_CREATE_REQUEST = 'REQUESTTYPE_CREATE_REQUEST'
export const REQUESTTYPE_CREATE_SUCCESS = 'REQUESTTYPE_CREATE_SUCCESS'
export const REQUESTTYPE_CREATE_FAIL = 'REQUESTTYPE_CREATE_FAIL'


export const REQUESTTYPE_UPDATE_REQUEST = 'REQUESTTYPE_UPDATE_REQUEST'
export const REQUESTTYPE_UPDATE_SUCCESS = 'REQUESTTYPE_UPDATE_SUCCESS'
export const REQUESTTYPE_UPDATE_FAIL = 'REQUESTTYPE_UPDATE_FAIL'

export const REQUESTTYPE_DELETE_REQUEST = 'REQUESTTYPE_DELETE_REQUEST'
export const REQUESTTYPE_DELETE_SUCCESS = 'REQUESTTYPE_DELETE_SUCCESS'
export const REQUESTTYPE_DELETE_FAIL = 'REQUESTTYPE_DELETE_FAIL'

export const VIEW_REQUESTTYPE_REQUEST = 'VIEW_REQUESTTYPE_REQUEST'
export const VIEW_REQUESTTYPE_SUCCESS = 'VIEW_REQUESTTYPE_SUCCESS'
export const VIEW_REQUESTTYPE_FAIL = 'VIEW_REQUESTTYPE_FAIL'