import * as React from 'react'
import KanbanAPI from '../../../../api/KanbanAPI';
import Dropzone from './Dropzone';
import { Card, CardHeader, CardActions, Button, Avatar, Divider, Typography, IconButton, Stack, Popper, Paper, ClickAwayListener, Grow, MenuList, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { blueGrey, blue } from '@mui/material/colors';
import useDialog from '../../../../Hooks/useDialog';
import ComplexDialog from '../../../../Components/ComplexDialog';

const Item = ({id, content}) => {

    const [value, setValue] = React.useState(content)

    const handleBlur = (e) => {
        const {textContent} = e.target;
        console.log('Blur: Newly entered text is:' + textContent.trim());
        //const new_content = textContent.trim();
        // console.log('Blur: Old text is:' + value.trim())
        if(textContent === value)
        {
            return;
        }
        KanbanAPI.updateItem(id, {content: textContent})
        //setValue(value)
    }
 
    const handleDelete = () => {
        console.log('Delete is working')
        //const check = confirm('Are you sure you want to delete this item?')
  
        KanbanAPI.deleteItem(id)
    }

    const handleDragStart = (e) => {
        const {dataTransfer} = e;
        dataTransfer.setData("text/plain", id)
    }

    const handleDrop = (e) => {
        e.preventDefault()
    }

    const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {

      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const {closeDialog, dialog, handleSubmit, showDialog} = useDialog();

  const handleViewTask = () => {

        showDialog(true, 'view-task', '');
  }

    return ( 
        <>
        
            {/* <div data-id={id} draggable="true" onDragStart={handleDragStart} onDrop={handleDrop} className="newkanban-item">
                <div contentEditable onDoubleClick={handleDelete} onBlur={handleBlur} className="newkanban-item-input">
                    {value}
                </div>
                <Dropzone/>
            </div> */}

                {
                dialog.open? <ComplexDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>: ''
                }
            
            <Card data-id={id} draggable="true" onDragStart={handleDragStart} onDrop={handleDrop} className="newkanban-item" sx={{mb: 2, border: 'none'}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" >
                    <CardHeader
                        // avatar={<CircleIcon sx={{color: blue[100], fontSize: '.5rem'}}/>}
                        title={value}
                        contentEditable
                        onDoubleClick={handleDelete} onBlur={handleBlur}
                        className="newkanban-item-input"
                    />
                    <Button 
                        id="composition-button"
                        size="small" 
                        sx={{background: '', mr: 2}}
                        ref={anchorRef}
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <MoreHorizIcon fontSize="small" sx={{background: ''}}/>
                    </Button>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="top-start"
                            transition
                            disablePortal
                            
                           
                            
                        >
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                            >
                                <Paper >
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList 
                                            autoFocusItem={open}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            onKeyDown={handleListKeyDown}
                                            >
                                            <MenuItem disabled>Actions</MenuItem>
                                            <MenuItem>Label</MenuItem>
                                            <MenuItem>Edit</MenuItem>
                                            <MenuItem>Delete</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>

                            </Grow>
                        )
                        }
                        </Popper>

                </Stack>

                <CardActions sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Avatar variant="rounded" sx={{bgcolor: blueGrey[900], width: 20, height: 20, fontSize: '.7rem', ml: 1}}>N</Avatar>
                    <Button size="small" variant="contained" onClick={handleViewTask} sx={{color: blueGrey[800], background: blue[50], border: '', mr: 1, boxShadow: 'none', cursor: 'pointer'}}><Typography variant="body2" sx={{fontSize: '.7rem'}}>View Task</Typography></Button>    
                </CardActions>
                <Dropzone/>
            </Card>
        </>
    )
}

export default Item
