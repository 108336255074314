import React, { useState, useEffect } from 'react'
import { Grid, ToggleButton, ToggleButtonGroup , Stack, Box, Typography, } from '@mui/material';
import { blue, blueGrey } from '@mui/material/colors';
import DisplayCard from './DisplayCard';
import SearchTableComponent from '../Table/SearchTableComponent';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { getAssessmentsList } from '../../Redux/Actions/AssessmentsActions';
import { useSelector, useDispatch } from 'react-redux';

const AssessmentsList = ({assessments}) => {

    
    return (
        <>
            <Grid item lg={12} sx={{mt: 3}}>

                <Box component="div" sx={{background: 'white', px: 1, py: 1, width: '30%', mb: 4, borderRadius: 2, boxShadow: '1px 1px 5px #cfd8dc'}}>
                    <SearchTableComponent other forTable={"Search Assessments"}/>
                </Box>

            </Grid>
            <Grid item lg={12} sx={{p: 2, mt: 1, background: blue[50], borderRadius: 1}}>
                    <Typography sx={{color: blue[400], ml: 2}}>Showing All</Typography>
                <Grid container flexDirection="row" justifyContent="flex-start" sx={{p: 2, mt: 1, background: blue[50], borderRadius: 1}}>
                    <br/>
                    {
                        assessments.map(item => 
                        <DisplayCard assessment={item}/>

                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default AssessmentsList
