export const ACCSANDINCIDENTS_LIST_REQUEST = 'ACCSANDINCIDENTS_LIST_REQUEST';
export const ACCSANDINCIDENTS_LIST_SUCCESS = 'ACCSANDINCIDENTS_LIST_SUCCESS';
export const ACCSANDINCIDENTS_LIST_FAIL = 'ACCSANDINCIDENTS_LIST_FAIL'; 

export const ACCSANDINCIDENT_CREATE_REQUEST = 'ACCSANDINCIDENT_CREATE_REQUEST'
export const ACCSANDINCIDENT_CREATE_SUCCESS = 'ACCSANDINCIDENT_CREATE_SUCCESS'
export const ACCSANDINCIDENT_CREATE_FAIL = 'ACCSANDINCIDENT_CREATE_FAIL'


export const ACCSANDINCIDENT_UPDATE_REQUEST = 'ACCSANDINCIDENT_UPDATE_REQUEST'
export const ACCSANDINCIDENT_UPDATE_SUCCESS = 'ACCSANDINCIDENT_UPDATE_SUCCESS'
export const ACCSANDINCIDENT_UPDATE_FAIL = 'ACCSANDINCIDENT_UPDATE_FAIL'

export const ACCSANDINCIDENT_DELETE_REQUEST = 'ACCSANDINCIDENT_DELETE_REQUEST'
export const ACCSANDINCIDENT_DELETE_SUCCESS = 'ACCSANDINCIDENT_DELETE_SUCCESS'
export const ACCSANDINCIDENT_DELETE_FAIL = 'ACCSANDINCIDENT_DELETE_FAIL'

export const VIEW_ACCSANDINCIDENT_REQUEST = 'VIEW_ACCSANDINCIDENT_REQUEST'
export const VIEW_ACCSANDINCIDENT_SUCCESS = 'VIEW_ACCSANDINCIDENT_SUCCESS'
export const VIEW_ACCSANDINCIDENT_FAIL = 'VIEW_ACCSANDINCIDENT_FAIL'