import React, { useState } from 'react';
import PageHeader from '../../../Components/PageHeader';
import Column from './NewKanbanComponents/Column';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Card, Paper, Button, Grid, CardHeader, CardActions, Avatar, Divider, IconButton, Box, AppBar, Toolbar, ToggleButtonGroup, ToggleButton, ClickAwayListener, MenuItem, MenuList, Popper, Grow, ButtonGroup } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const TestingKanban = () => {

    // console.log(KanbanAPI.updateItem(7, { 
    //     columnId: 2,
    //     position: 0
    // }));
    const anchorRef = React.useRef(null);

    const [open, setOpen] = useState();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
      };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
      };

      const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpen(false);
        } else if (event.key === 'Escape') {
          setOpen(false);
        }
      }

      //handling the selected menu item -------------------------------------------------
      const [view, setView] = useState({
          kanban: true,
          table: false,
      });
      const [show, setShow] = useState({
        not_started: true, 
        completed: true,
        inprogress: true,
      });

      const [filterLabel, setFilterLabel] = useState('Showing All Tasks');

      //function
      const handleSelection = (e) => {

          const {id} = e.target;
          console.log('Handle selection is working and id is: ' + id)
          if(id == 'not-started')
          {
              console.log('If is working')
              setFilterLabel('Showing Not Started tasks');
              setShow({
                not_started: true,
                inprogress: false,
                completed: false,
              })
          }
          if(id == 'in-progress')
          {
            setFilterLabel('Showing Tasks In Progress');
            setShow({
                not_started: false,
                inprogress: true,
                completed: false,
              })
          }
          if(id == 'completed')
          {
            setFilterLabel('Showing Completed Tasks');
            setShow({
                not_started: false,
                inprogress: false,
                completed: true,
              })
          }
          if(id == 'all')
          {
            setFilterLabel('Showing All Tasks');
            setShow({
                not_started: true,
                inprogress: true,
                completed: true,
              })
            }
    
          
      }

    const columns= [
            {
                id:1,
                title: 'Not started'
            },
            {
                id:2,
                title: 'In Progress'
            },
            {
                id:3,
                title: 'Completed'
            } 
        ]
    
    return (
        <>
        <PageHeader page="Current Tasks"/>
            <br/>
            <Divider/> 
            <br/>
            <AppBar position="static" sx={{background: blueGrey[100], boxShadow: 'none', borderRadius: '8px;'}}>
                <Toolbar sx={{background: '', display: 'flex', justifyContent: 'space-between'}}>
                    <>
                    <div>
                    <Button
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle} 
                        sx={{color: 'black', background: 'rgba(245, 245, 245, .7)'}}
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                    Filter View
                    </Button>
                    
                    <Box component="span" sx={{ml: 2, color: 'teal', background: ''}}>{filterLabel}</Box>
                    </div>

                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom"
                        transition
                        disablePortal
                        >
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                            >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem id="all" onClick={handleSelection}>Show All</MenuItem>
                                    <MenuItem id="not-started" onClick={handleSelection}>Show Not Started</MenuItem>
                                    <MenuItem id="in-progress" onClick={handleSelection}>Show In Progress</MenuItem>
                                    <MenuItem id="completed" onClick={handleSelection}>Show Completed</MenuItem>
                                </MenuList>
                                </ClickAwayListener>
                            </Paper>
                            </Grow>
                        )}
                        </Popper>
                    </>

                    {/* Buttons to change the view from Kanban to Table List */}

                    <ButtonGroup variant="contained" >
                        <Button sx={{background: 'orange' }}>Kanban View</Button>
                        <Button  sx={{ background: '#455a64' }}>Table List</Button>
                    </ButtonGroup>
                </Toolbar>
            </AppBar>
            <br/>
        <div className="newkanban">

            {columns.map(column => <Column key={column.id} {...column} />)}
            
        </div>
        </>
    )
}

export default TestingKanban
