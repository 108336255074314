import React, {useState} from 'react';
import AddVATReturnDialog from './AddVATReturnDialog';
import ViewVATReturnDialog from './ViewVATReturnDialog';
import DeleteVATReturnDialog from './DeleteVATReturnDialog';



const VATReturnDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <> 
        {
            type === "create"?

            <AddVATReturnDialog open={open} onClose={onClose} handleSubmit={handleSubmit}  fullWidth />
            
            : type === "view"?

            <ViewVATReturnDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />

            : type === "delete"?

            <DeleteVATReturnDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />
            // <ViewVATReturnDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default VATReturnDialogs
