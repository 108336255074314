import React, {useState, useEffect} from 'react';
import { Link as BreadcrumbLink, CircularProgress } from '@mui/material';
import { IconButton, Grid, Divider, Stack} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../Hooks/useDialog';
import { blueGrey, blue, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import Crumbs from '../../../Components/Utilities/Crumbs';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import { getTransactionsList } from '../../../Redux/Actions/TransactionActions';
import TransactionDialogs from '../../../Components/Dialogs/Finance/Reconciliation/TransactionsDialogs/TransactionDialogs';
import TransactionsTbl from '../../../Components/Table/Finance/Reconciliation/TransactionsTbl';
 
const Transactions = () => { 

    //FETCH DATA FROM SERVER
    
    const dispatch = useDispatch();
    const {transactions, loading ,error } = useSelector(state => state.transactions_List) //receiving state object
    const [list, setList] = useState([])
    
    useEffect(()=> {
        dispatch(getTransactionsList());
    }, [])
     
    useEffect(() => {
        if(transactions)
        {
            setList([...transactions])
        }
        
    }, [transactions])
    //TABLE DIALOG FUNCTIONALITY
    
        const handleAddClick = () => {
            console.log('THIS IS WORKING')
            showDialog(true, 'create', '');
        }
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)
    
    //----------------------------------------------------------------------------------------------------------------------------------
     
    return (
        <div>
        
         <ScreenHeader title="Transactions" subtitle="Manage all transactions from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>
        { dialog.open ?  <TransactionDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
        <Grid container justifyContent="space-between" sx={{mb: 2}}>
            <Crumbs root="Finance" middle="Reconciliation" end="Transactions" main/>
            <Stack direction="row" justifyContent="center" alignItems="center">
                <AddButtonLabel item="Transaction"/>
                <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                    <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                </IconButton>
            </Stack>
        </Grid>
        
        <Divider sx={{color: lightBlue[800]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        { loading ?  <HorizontalLoading/> : '' }
        <HorizontalSummaryBox primary="Transactions Brief" secondary="Overall Transactions count" label1="All Transactions" label2="Second Label" label3="Third Label" count={[2,11,6]}/>
        
        <AboveTableHeader title="Transactions List"/>
        <Grid container>
            <TransactionsTbl transactions={list}/>
        </Grid>


     
    </div>
    )
}

export default Transactions
