export const REQUESTS_LIST_REQUEST = 'REQUESTS_LIST_REQUEST'
export const REQUESTS_LIST_SUCCESS = 'REQUESTS_LIST_SUCCESS'
export const REQUESTS_LIST_FAIL = 'REQUESTS_LIST_FAIL'


export const REQUEST_CREATE_REQUEST = 'REQUEST_CREATE_REQUEST'
export const REQUEST_CREATE_SUCCESS = 'REQUEST_CREATE_SUCCESS'
export const REQUEST_CREATE_FAIL = 'REQUEST_CREATE_FAIL'


export const REQUEST_UPDATE_REQUEST = 'REQUEST_UPDATE_REQUEST'
export const REQUEST_UPDATE_SUCCESS = 'REQUEST_UPDATE_SUCCESS'
export const REQUEST_UPDATE_FAIL = 'REQUEST_UPDATE_FAIL'

export const REQUEST_DELETE_REQUEST = 'REQUEST_DELETE_REQUEST'
export const REQUEST_DELETE_SUCCESS = 'REQUEST_DELETE_SUCCESS'
export const REQUEST_DELETE_FAIL = 'REQUEST_DELETE_FAIL'

export const VIEW_REQUEST_REQUEST = 'VIEW_REQUEST_REQUEST'
export const VIEW_REQUEST_SUCCESS = 'VIEW_REQUEST_SUCCESS'
export const VIEW_REQUEST_FAIL = 'VIEW_REQUEST_FAIL'