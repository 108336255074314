import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
        display: flex;
        color: black;
        
        `;

const SidebarLabel = styled.span`
        
        
        `;
const SubMenu = ({item}) => {
    return (
        <>
           <SidebarLink to={item.path}>
               <p>{item.icon}</p>
               <SidebarLabel>{item.title}</SidebarLabel>

           </SidebarLink> 
        </>
    )
}

export default SubMenu
