import { VATSTATUSES_LIST_REQUEST, VATSTATUSES_LIST_SUCCESS, VATSTATUSES_LIST_FAIL, VIEW_VATSTATUS_FAIL, VIEW_VATSTATUS_REQUEST, VIEW_VATSTATUS_SUCCESS, VATSTATUS_CREATE_FAIL, VATSTATUS_UPDATE_REQUEST, VATSTATUS_UPDATE_FAIL, VATSTATUS_DELETE_REQUEST, VATSTATUS_DELETE_FAIL, VATSTATUS_CREATE_REQUEST, VATSTATUS_CREATE_SUCCESS, VATSTATUS_UPDATE_SUCCESS, VATSTATUS_DELETE_SUCCESS } from "../Constants/vatStatusConstants";

const vatStatusesListReducer = (state = {vatStatuses: []}, action) => {

    switch (action.type)
    {
        case VATSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case VATSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and vatStatuss data is:' + action.payload)
            return {
                loading: false,
                vatStatuses: action.payload
            }
        case VATSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const vatStatusCreateReducer = (state = {vatStatuses: []}, action) => {

    switch (action.type) {

        case VATSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case VATSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                vatStatuses: [...state.vatStatuses, {Applicant_Id: 2, Name: value}]
            };

        case VATSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const vatStatusViewReducer = (state= {vatStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_VATSTATUS_REQUEST:
            return {loading: true};
        case VIEW_VATSTATUS_SUCCESS:
            return {
                    loading: false,
                    vatStatus: action.payload
                }
        case VIEW_VATSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const vatStatusUpdateReducer = (state = {vatStatuss: {}}, action) => {

    switch (action.type) {

        case VATSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case VATSTATUS_UPDATE_SUCCESS:

            return {

            }
        case VATSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const vatStatusDeleteReducer = (state = {vatStatuss: {}}, action) => {

    switch (action.type) {
        case VATSTATUS_DELETE_REQUEST:
            return {
                
            }
        case VATSTATUS_DELETE_SUCCESS:
            return {

            }
        case VATSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {vatStatusesListReducer, vatStatusCreateReducer, vatStatusViewReducer, vatStatusUpdateReducer, vatStatusDeleteReducer }