import React from 'react'
import { Grid, Box, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const AboveTableHeader = ({title}) => {
    return (
        <>
           <Typography variant="h6" sx={{fontWeight: 900, px: 0, py: 2, fontFamily: 'Poppins-Light', color: blueGrey[500], ml: 1}}>{title}</Typography>
        </>
    )
}

export default AboveTableHeader
