import React from 'react'
import { ListItem, Stack, ListItemText, IconButton } from '@mui/material';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { orange, blue, cyan } from '@mui/material/colors';

const SummaryItemComponent = ({name, count, icon, forTasks, color, background}) => {
    return (
        <>
            <ListItem disableGutters sx={{background: 'white', borderRadius: 2, px: 1, py: 0, mb: forTasks? 1 : 0, mr: 2}}> 
                <Stack sx={{width: '100%',background: ''}} direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="column">
                        <ListItemText primary={name} secondary={count} primaryTypographyProps={{fontWeight: 600, color: color}}/>
                    </Stack>
                    <IconButton fontSize="large" sx={{p: 1, color: color, background: background , borderRadius: 1}}>
                        {name.includes("All", 0)? <FormatListNumberedIcon/> : name.includes("Pend", 0)? <TimelapseIcon/> : <CheckCircleOutlineIcon/> }
                    </IconButton>
                    
                </Stack>
            </ListItem>
        </>
    )
}

export default SummaryItemComponent
