import { JOBROLE_LIST_REQUEST, JOBROLE_LIST_FAIL, JOBROLE_LIST_SUCCESS} from '../Constants/jobRoleConstants';
import api from '../../config/apiConfig';


const getJobRolesList = () => async(dispatch) => {
    //const data = assessmenttypes;
 
    try {
        dispatch({type: JOBROLE_LIST_REQUEST});
        const {data} = await api.get('/jobroles');
        //console.log('JobRoles data is:' + data[0]['JobRoles_Name'])
        dispatch({type: JOBROLE_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: JOBROLE_LIST_FAIL, payload: error})
    }
} 

export {getJobRolesList }