import React from 'react'
import { Grid, Box, Typography } from '@mui/material';
import { blueGrey, blue } from '@mui/material/colors';

const ScreenHeader = ({title, subtitle, count}) => {
    return (
        <>
            <Grid container sx={{mb: 3}}>
                <Grid item lg={12}>
                    <Box>
                        <h2 style={{color: blueGrey[600],fontFamily: 'Poppins-Medium' , fontWeight: 900, marginBottom: 0}} >{title}<Typography variant="" component="span" sx={{color: blue[700],fontSize: '1.8rem'}}> ({count})</Typography></h2>
                        <Typography variant="body1" sx={{display: 'inline-block', borderRadius: '5px',py: 0, px: '7px' , color: 'white',fontWeight: 500, background: blue[500], mt: 1, boxShadow: '1px 1px 8px #90a4ae'}}>{subtitle}</Typography>
                    </Box>

                </Grid>
            </Grid>
        </>
    )
}

export default ScreenHeader
