import applicants from '../../Data/Applicants';
import { APPLICANTS_LIST_REQUEST, APPLICANTS_LIST_SUCCESS, APPLICANTS_LIST_FAIL, VIEW_APPLICANT_FAIL, VIEW_APPLICANT_REQUEST, VIEW_APPLICANT_SUCCESS, APPLICANT_CREATE_REQUEST, APPLICANT_CREATE_SUCCESS, APPLICANT_CREATE_FAIL, APPLICANT_UPDATE_FAIL, APPLICANT_UPDATE_REQUEST, APPLICANT_UPDATE_SUCCESS } from '../Constants/applicantConstants';
import api from '../../config/apiConfig';


const getApplicantsList = () => async(dispatch) => {
    const data = applicants;
 
    try {
        dispatch({type: APPLICANTS_LIST_REQUEST});
        const {data} = await api.get('/applicants');
        // console.log('Applicants data is:' + data[0]['Applicant_Name'])
        dispatch({type: APPLICANTS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: APPLICANTS_LIST_FAIL, payload: error})
    }
}  
 
const submitApplicant = (submitValues, displayValues) => async (dispatch) => {
    console.log('HAVE RECIEVED THE VALUES IN THE ACTION, THEY ARE: ' + submitValues.applicantprospects)
     
    try {
        dispatch({type: APPLICANT_CREATE_REQUEST});
        
        const {data} = await api.post(`/applicants/`, submitValues);
        console.log('Applicant data is:' + data)
        dispatch({type: APPLICANT_CREATE_SUCCESS, payload: displayValues})
    } catch (error) {
        dispatch({type: APPLICANT_CREATE_FAIL, payload: error})
    }
} 

const getApplicant = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_APPLICANT_REQUEST});
        console.log('IDDDDDDDDDD' + id)
        const {data} = await api.get(`/applicants/ ${id}`);
        console.log('Applicant data is:' + data)
        dispatch({type: VIEW_APPLICANT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_APPLICANT_FAIL, payload: error})
    }
}

const editApplicant = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: APPLICANT_UPDATE_REQUEST});
        
        const {data} = await api.put(`/applicants/`, values);
        console.log('Applicant data is:' + data)
        dispatch({type: APPLICANT_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: APPLICANT_UPDATE_FAIL, payload: error})
    }
}



export {getApplicantsList, submitApplicant, getApplicant, editApplicant }