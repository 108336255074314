export const BRANCHES_LIST_REQUEST = 'BRANCHES_LIST_REQUEST'
export const BRANCHES_LIST_SUCCESS = 'BRANCHES_LIST_SUCCESS'
export const BRANCHES_LIST_FAIL = 'BRANCHES_LIST_FAIL'


export const BRANCH_CREATE_REQUEST = 'BRANCH_CREATE_REQUEST'
export const BRANCH_CREATE_SUCCESS = 'BRANCH_CREATE_SUCCESS'
export const BRANCH_CREATE_FAIL = 'BRANCH_CREATE_FAIL'


export const BRANCH_UPDATE_REQUEST = 'BRANCH_UPDATE_REQUEST'
export const BRANCH_UPDATE_SUCCESS = 'BRANCH_UPDATE_SUCCESS'
export const BRANCH_UPDATE_FAIL = 'BRANCH_UPDATE_FAIL'

export const BRANCH_DELETE_REQUEST = 'BRANCH_DELETE_REQUEST'
export const BRANCH_DELETE_SUCCESS = 'BRANCH_DELETE_SUCCESS'
export const BRANCH_DELETE_FAIL = 'BRANCH_DELETE_FAIL'

export const VIEW_BRANCH_REQUEST = 'VIEW_BRANCH_REQUEST'
export const VIEW_BRANCH_SUCCESS = 'VIEW_BRANCH_SUCCESS'
export const VIEW_BRANCH_FAIL = 'VIEW_BRANCH_FAIL'