export const PROSPECTS_LIST_REQUEST = 'PROSPECTS_LIST_REQUEST'
export const PROSPECTS_LIST_SUCCESS = 'PROSPECTS_LIST_SUCCESS'
export const PROSPECTS_LIST_FAIL = 'PROSPECTS_LIST_FAIL'


export const PROSPECT_CREATE_REQUEST = 'PROSPECT_CREATE_REQUEST'
export const PROSPECT_CREATE_SUCCESS = 'PROSPECT_CREATE_SUCCESS'
export const PROSPECT_CREATE_FAIL = 'PROSPECT_CREATE_FAIL'


export const PROSPECT_UPDATE_REQUEST = 'PROSPECT_UPDATE_REQUEST'
export const PROSPECT_UPDATE_SUCCESS = 'PROSPECT_UPDATE_SUCCESS'
export const PROSPECT_UPDATE_FAIL = 'PROSPECT_UPDATE_FAIL'

export const PROSPECT_DELETE_REQUEST = 'PROSPECT_DELETE_REQUEST'
export const PROSPECT_DELETE_SUCCESS = 'PROSPECT_DELETE_SUCCESS'
export const PROSPECT_DELETE_FAIL = 'PROSPECT_DELETE_FAIL'

export const VIEW_PROSPECT_REQUEST = 'VIEW_PROSPECT_REQUEST'
export const VIEW_PROSPECT_SUCCESS = 'VIEW_PROSPECT_SUCCESS'
export const VIEW_PROSPECT_FAIL = 'VIEW_PROSPECT_FAIL'