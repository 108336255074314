import React from 'react';
import { Link as BreadcrumbLink, Grid, Stack } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const SideCrumbs = () => {
    return (
        <>
            <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                <Stack sx={{mb: 3, background: '', height: '300px', borderRadius: '5px', width: '80%', pl: 3, pt: 3}}>
                    <BreadcrumbLink underline="none" sx={{color: blueGrey[300]}} fontSize="small" href="#basic">Basic Info</BreadcrumbLink>
                    <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#nature">Job Nature</BreadcrumbLink>
                    <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#staff">Staff of Shift</BreadcrumbLink>
                    <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#client">Client Information</BreadcrumbLink>
                    <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#notes">Notes</BreadcrumbLink>
                </Stack>
            </Grid>
        </>
    )
}

export default SideCrumbs
