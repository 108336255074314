import KanbanData from "../Data/KanbanData";

export default class KanbanAPI {

    static getItems(columnId){
        const column = read().find(column => column.id == columnId)

        if(!column){
            return [];
        }
        return column.items;
    } 

    static insertItem(columnId, content){
        const data = read();
        const column = data.find(column => column.id == columnId);
        const item={
            id: Math.floor(Math.random() * 1000000),
            content
        };
        if(!column)
        {
            throw new Error('Column does not exist')
        }
        column.items.push(item);
        save(data);

        return item;
    }

    static updateItem(itemId, newProps){
        const data = read();
        const [item, currentColumn] = (()=> {
            for(const column of data){
                const item = column.items.find(column_item=> column_item.id == itemId);

                if(item)
                {
                    return [item, column];
                }
            }

            })();

                if(!item){
                    throw new Error('Item not found Moreme');
                }

                item.content = newProps.content === undefined? item.content : newProps.content;

                //Update column and position

                if(newProps.columnId !== undefined && newProps.position !== undefined)
                {
                    const targetColumn = data.find(column => column.id == newProps.columnId)
               
                    if(!targetColumn){
                        throw new Error("Target column not found Moreme")
                    }
                    currentColumn.items.splice(currentColumn.items.indexOf(item), 1);
                    //Move the item to it's new column and position
                    targetColumn.items.splice(newProps.position, 0, item);
                    console.log('Target column'+targetColumn);
                }

                // //Delete the item from it's current column

                // save(data);
            

        //console.log(item, currentColumn);

    }

    static deleteItem(itemId){
        const data = read();

        for(const column of data){
            const item = column.items.find(item=> item.id == itemId)
           
            if(item){
                column.splice(column.items.indexOf(item), 1);
            }

        }
        save(data);
    }
}

function read(){
    const json = localStorage.getItem("kbdata"); 

    if(!json)
    {
        return [
            {
                id: 1,
             items: [
                        {
                            id: 4,
                            content: 'Deliver bags',
                        },
                        {
                            id: 7,
                            content: 'Design fliers',
                        },
             ],
            },
            {
                id: 2,
             items: [
                        {
                            id: 5,
                            content: 'Print A2 Calendars',
                        },
             ],
            },
            {
                id: 3,
             items: [],
            },
    ,];
    }

    return JSON.parse(json);
}

function save(data) {
    localStorage.setItem("kbdata", JSON.stringify(data))
}
