import { ASSESSMENTS_LIST_REQUEST, ASSESSMENTS_LIST_FAIL, ASSESSMENT_CREATE_REQUEST, ASSESSMENT_CREATE_SUCCESS, ASSESSMENT_CREATE_FAIL, VIEW_ASSESSMENT_REQUEST, VIEW_ASSESSMENT_SUCCESS, ASSESSMENT_UPDATE_REQUEST, ASSESSMENT_UPDATE_SUCCESS, ASSESSMENT_UPDATE_FAIL, ASSESSMENTS_LIST_SUCCESS, VIEW_ASSESSMENT_FAIL } from '../Constants/assessmentConstants';
import api from '../../config/apiConfig';


const getAssessmentsList = () => async(dispatch) => {
    //const data = assessments;
 
    try {
        dispatch({type: ASSESSMENTS_LIST_REQUEST});
        const {data} = await api.get('/assessments');
        //console.log('Assessments data is:' + data[0]['Assessments_Name'])
        dispatch({type: ASSESSMENTS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: ASSESSMENTS_LIST_FAIL, payload: error})
    }
} 

const submitAssessment = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: ASSESSMENT_CREATE_REQUEST});
        
        const {data} = await api.post(`/assessments/`, values);
        console.log('Assessment data is:' + data)
        dispatch({type: ASSESSMENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: ASSESSMENT_CREATE_FAIL, payload: error})
    }
} 

const getAssessment = (id) => async (dispatch) => {
    
      
    try {
        dispatch({type: VIEW_ASSESSMENT_REQUEST});
        
        const {data} = await api.get(`/assessments/ ${id}`);
        console.log('Assessment data is:' + id)
        dispatch({type: VIEW_ASSESSMENT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_ASSESSMENT_FAIL, payload: error})
    }
}

const editAssessment = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: ASSESSMENT_UPDATE_REQUEST});
        
        const {data} = await api.put(`/assessments/`, values);
        console.log('Assessment data is:' + data)
        dispatch({type: ASSESSMENT_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: ASSESSMENT_UPDATE_FAIL, payload: error})
    }
}



export {getAssessmentsList, submitAssessment, getAssessment, editAssessment }