import React, { useState, useEffect } from 'react'
import { Typography, Card, CardHeader, CardContent, Button } from '@mui/material';

const Test = () => {

    const [standing, setStanding] = useState('Am a component, YES!');

    useEffect(() => {
        console.log('Moreme, i have run')
    }, [standing])

    const handleClick = (arg) => {
        setStanding((prevState) => {
            return prevState + arg
        })
    }

    const handlePreviousClick = (arg) => {
        setStanding((prevState) => {
            return prevState 
        })
    }


    return (
        <div>
            <Typography sx={{fontFamily: 'Poppins-Light', fontSize: 50}}>Test Page</Typography>
            <Card sx={{width: '300px', background: 'skyblue'}}>
              
                <CardContent>
                    <Typography sx={{fontFamily: 'Poppins-Light', fontSize: 20, color: 'white'}}>{standing}</Typography>
                    <Button onClick={()=> handleClick('again')}>Change state</Button>
                </CardContent>
            </Card>
        </div>
    )
}

export default Test


// import React, {useState, useEffect} from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import PageHeader from '../../../Components/PageHeader';
// import { Typography, Paper, Grid, Button, IconButton, Stack, List, ListItemText, Divider, Chip, Link, ListItemAvatar, ListItem, Breadcrumbs } from '@mui/material';
// import { Box} from '@mui/system'; 
// import useToPDF from '../../../Hooks/useToPDF';
// import { blueGrey, blue, orange, green, red, cyan, lightBlue, lightGreen, pink } from '@mui/material/colors';
// import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
// import { useParams, Navigate, useNavigate } from 'react-router';
// import { Form, Row, Col } from 'react-bootstrap';
// import { Avatar } from '@material-ui/core';
// import DateRangeIcon from '@mui/icons-material/DateRange';
// import CircleIcon from '@mui/icons-material/Circle';
// import ComplexAlert from '../../../Components/ComplexAlert';
// import Select from 'react-select'; 
// import makeAnimated from 'react-select/animated'
// import { getTask, editTask } from '../../../Redux/Actions/TasksActions';
// import FormHeader from '../../../Components/Utilities/FormHeader';
// import FormHeading from '../../../Components/Utilities/FormHeading';
// import FormNavigation from '../../../Components/Utilities/FormNavigation';
// import Crumbs from '../../../Components/Utilities/Crumbs';
// import ViewTitle from '../../../Components/Utilities/ViewTitle';
// import CRUD from '../../../Components/Utilities/CRUD';
// import useCRUD from '../../../Hooks/useCRUD';
// import AsyncSelect from 'react-select/async';
// import ViewStatus, { convertSelectedStatusToStatusId, convertSelectItemsToIds } from '../../../Components/Utilities/ViewStatus';
// import useSelectInput from '../../../Hooks/useSelectInput';
// import { getTaskStatusesList } from '../../../Redux/Actions/TaskStatusActions';
// import TaskDialogs from '../../../Components/Dialogs/CRM/Task/TaskDialogs/TaskDialogs';

// const ViewTask = ({index}) => {

//     //targeted task
//     const {id} = useParams();
//     const dispatch = useDispatch();
//     const {isEditing, handleCreate, handleEdit, handleDelete, setIsEditing, alert, showAlert, dialog, showDialog, handleSubmit, closeDialog } = useCRUD();
//     const {loading, task, error }  = useSelector(state => state.task_View) 
//     const {taskStatuses, loading: loading_statuses}  = useSelector(state => state.taskStatuses_List);
//     const [item, setItem] = useState({})
//     const [values, setValues] = useState({
//         name: '', 
//         date: '', 
//         deadline: '', 
//         description: '', 
//         staff: '', 
//         divisions: '' , 
//         tasktypes: '' , 
//         clients: '' , 
//         workers: '', 
//         notes: '', 
//         taskstatuses: '', 
//     });

//     const [submitValues, setSubmitValues] = useState({
//             name: '',
//             date: '', 
//             deadline: '', 
//             description: '', 
//             staff: '', 
//             divisions: '' , 
//             tasktypes: '' , 
//             clients: '' , 
//             workers: '', 
//             notes: '', 
//             taskstatuses: '' 
//         });

//     useEffect(() => {   
//         console.log('FIRST USE EFFECT HAS RUN')
//         dispatch(getTask(id))
//         dispatch(getTaskStatusesList())
//     }, [])

//     useEffect(() => {
//         console.log('SECOND USE EFFECT HAS RUN')
//        if(task)
//        {
//            setItem({...task})
//        }
//     }, [task])

//     const handleEditChange = (e) => {
        
//             const {name, value} = e.target;
//             setItem({
//                 ...item,
//                 [name]: value
//             })
//     }
//     const [edited, setEdited] = useState([])

//     const getColor = (name) => {
//         console.log('have reached colour function')
//         const hasEdited = edited.find(x=> x === name? x : '')
//         if(hasEdited)
//         {
//             return lightGreen[500]
//         }
//         else{

//             return blueGrey[300]
//         }
//     }
//     const handleSelectEditChange = (value, action) => {

//         const name = action.name;

//         console.log('VALUE:' + value.label)
//         setItem({
//             ...item,
//             [name]: value.label
//         })
//         setValues({
//             ...values,
//             [name]: value
//         })
//         setEdited([
//             ...edited,
//             name
//         ])
//     }

//     const handleCancel = () => {
//         setIsEditing(false);
//         setEdited([])
//         dispatch(getTask(id))
//     }
//     const handleSaveChanges = async (e, newStatus) => {
//         e.preventDefault();
//         if(newStatus)
//         {

//             dispatch(editTask(item.Id, {...item, Status: newStatus}))
//         }
//         else{
//             //go through submit values to check if any of the properties are not empty

//             const {items} = await convertSelectItemsToIds([{name: 'clients', value: item.Client, criteria: 'Name'}, {name: 'taskstatuses', value: item.Status, criteria: 'Status_Name'}])
//             const ClientId = items.map(x => {
//                 if(x.name === 'clients')
//                 {
//                     return x.properties.Name
//                 }
//                 return null
//             })
//             console.log('HOOOORAY, I GOT CLIENT ID: ' + ClientId)
//             //dispatch(editTask(item.Id, item))
//             // const {Status_Id} = await convertSelectedStatusToStatusId(item.Status);
//             // dispatch(editTask(item.Id, {...item, Status: Status_Id}))
//             setIsEditing(false)

//         }
//         showAlert('success', 'Changes successfully made', true)
//     }

    
//     const [inputValue, setInputValue] = useState('');
    
//     const handleInputChange = (newValue) => {
        
//         const inputValue = newValue.replace(/\W/g, '');
//         setInputValue(inputValue);
//         console.log('Input value is:' + inputValue)
//         return inputValue
//     }
//     const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
  
//     const animatedComponents = makeAnimated();

    
 
//     return ( 
//         <div> 
//             <div>
                
//                 <PageHeader page="Task Details" previous tasks />
//                 <Crumbs root="CRM" middle="tasks" end="Details"/>
//                 <ViewTitle first={`${item.Type} - ${item.Date !== 'undefined'? item.Date : 'Not set'} - ${item.Worker}`}/>
//                 {/* <h4>hb: {item.Client}</h4>
//                 <h4>desc: {item.Description}</h4> */}
            
//             {

//             }
//                 <Grid container justifyContent="space-between" alignItems= "center"  sx={{mt: 2, mb: 2, px:0, borderRadius: '20px', color: 'gray', background: ''}}>
//                     <ViewStatus status={item.Status} task={taskStatuses} values={item} setValues={setItem} handleSaveChanges={handleSaveChanges}/>
//                     <CRUD isEditing={isEditing} handleCreate={handleCreate} handleCancel={handleCancel} handleEdit={handleEdit} handleSaveChanges={handleSaveChanges} handleDelete={handleDelete} setIsEditing={setIsEditing} showAlert ={showAlert } handlePrint/>
//                 </Grid>

//                 <Divider sx={{color: blue[500]}}/>
//             </div>

//             { alert.show?  <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }

//             { dialog.open?  <TaskDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }

//             <Grid container flexDirection="column" justifyContent="space-between" sx={{mt: 3, mb: 1, background: 'white', borderRadius: '5px',p: 3}}>
      
//                 <Grid item lg={12} sx={{background: 'blue[50]', borderRadius: '5px', p: 0, height: '', maxHeight: '', overflowY: 'scrollY', width: '100%'}}>
//                     <Grid container justifyContent="flex-start" flexDirection="row">

//                         <Grid item lg={6}>
//                             <Grid container justifyContent="center" alignItems="flex-start" flexDirection="column" sx={{background: ''}}>
//                                 <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{mb: 1}}>
//                                     <Typography variant="body1" sx={{fontWeight: 900, mr: 2}}>Date and Time</Typography>
//                                     <AccessTimeFilledIcon sx={{color: blue[800], fontSize: 17}}/>
//                                 </Stack>
//                                 <Grid container justifyContent="space-evenly">
//                                     <Paper sx={{mb: 1,py: 1, borderLeft: '', width: '45%' ,background: 'rgb(242, 247, 252)', boxShadow: 'none', textAlign: 'center', boxShadow: '1px 1px 7px rgb(225, 226, 227)'}}>
//                                         <Grid container justifyContent="space-between" alignItems="center" sx={{my: 1}}>
//                                             <Box component="div" sx={{display: 'flex', alignItems: 'center', px: 1,}}>
//                                                 <DateRangeIcon fontSize="small"/>
//                                                 <Typography variant="body1" sx={{color: blueGrey[700],ml: 1, fontSize: 13, fontWeight: 700}}> Date</Typography>
//                                             </Box>
//                                             <Typography variant="body2" sx={{px: 1,color: blueGrey[900], fontWeight: 400, borderRadius: '5px', mr: 1}}>08/03/22 17:00</Typography>
//                                         </Grid>
//                                     </Paper>
//                                     <Paper sx={{mb: 1,py: 1, borderLeft: '', width: '45%', background: 'rgb(242, 247, 252)', boxShadow: 'none', textAlign: 'center', boxShadow: '1px 1px 7px rgb(225, 226, 227)'}}>
//                                     <Grid container justifyContent="space-between" alignItems="center" sx={{my: 1}}>
//                                         <Box component="div" sx={{display: 'flex', alignItems: 'center', px: 1,}}>
//                                             <DateRangeIcon fontSize="small" sx={{color: red[500]}}/>
//                                             <Typography variant="body1" sx={{color: blueGrey[700],ml: 1,fontSize: 13, fontWeight: 700}}> Deadline</Typography>
//                                         </Box>
//                                         <Typography variant="body2" sx={{px: 1,color: blueGrey[900], fontWeight: 400, borderRadius: '5px', mr: 1}}>08/03/22 17:00</Typography>
//                                     </Grid>
//                                 </Paper>
//                                 </Grid>
//                             </Grid>
//                         </Grid>
//                         <Grid item lg={6}>
//                             <Grid container justifyContent="center" alignItems="flex-start" flexDirection="column" sx={{background: ''}}>
//                                 <Stack direction="row" alignItems="center" justifyContent="center" sx={{mb: 1}}>
//                                     <Typography variant="body1" sx={{fontWeight: 900, mr: 2}}>Quickies</Typography>
//                                     <CircleIcon sx={{color: blueGrey[300], fontSize: 17}}/>
//                                 </Stack>
//                                 <Grid container justifyContent="space-evenly">
//                                     <Paper sx={{mb: 1,py: 1, borderLeft: '', width: '45%', background: 'rgb(242, 247, 252)', boxShadow: 'none', textAlign: 'center', boxShadow: '1px 1px 7px rgb(225, 226, 227)'}}>
//                                         <Grid container justifyContent="space-between" alignItems="center" sx={{my: 1}}>
//                                             <Box component="div" sx={{display: 'flex', alignItems: 'center', px: 1,}}>
//                                                 <Typography variant="body1" sx={{color: blueGrey[700],ml: 1, fontSize: 13, fontFamily: 'Poppins', fontWeight: 700}}> Division</Typography>
//                                             </Box>
//                                             <Typography variant="body2" sx={{px: 1,color: orange[50], fontWeight: 400, borderRadius: '5px', mr: 1, background: cyan[400], border: '1px solid white'}}>Construction</Typography>
//                                         </Grid>
//                                     </Paper>
//                                     <Paper sx={{mb: 1,py: 1, borderLeft: '', width: '45%', background: 'rgb(242, 247, 252)', boxShadow: 'none', textAlign: 'center', boxShadow: '1px 1px 7px rgb(225, 226, 227)'}}>
//                                     <Grid container justifyContent="space-between" alignItems="center" sx={{my: 1}}>
//                                         <Box component="div" sx={{display: 'flex', alignItems: 'center', px: 1,}}>
//                                             <Typography variant="body1" sx={{color: blueGrey[700],ml: 1,fontSize: 13, fontWeight: 700}}>Task Type</Typography>
//                                         </Box>
//                                         <Typography variant="body2" sx={{px: 1,color: orange[50], fontWeight: 400, borderRadius: '5px', mr: 1, background: orange[400], border: '1px solid white'}}>{item.Type}</Typography>
//                                     </Grid>
//                                 </Paper>
//                                 </Grid>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Grid>

//                 <Divider sx={{color: lightBlue[500], my: 2}}/>

//                 <Grid item lg={12}>
//                     {/* Form */}
//                     <FormHeader>
//                         <FormHeading title="Basic Details" badge="Info" icon=""/>
//                         <FormNavigation/>
//                     </FormHeader>

//                     <Paper  sx={{py: 2, px: 2, boxShadow: 'none', background: 'rgba(227, 242, 253, .23)', border: '1px solid rgba(225, 225, 226, .3)', borderRadius: '5px'}}>
//                         <Form className="mt-2 px-1">
//                             <Row className="mb-2">
//                                 <Form.Group as={Col} controlId="formGridEmail" className="border-0 rounded p-1">
//                                     <Form.Label className="view-label">Task ID</Form.Label>
//                                     <Form.Control disabled type="email" value={`TSK-${item.Id}`} placeholder="E.g 123qst1" className="border-0" />
//                                 </Form.Group>
//                                 <Form.Group as={Col} controlId="formGridEmail" className="border-0 rounded p-1">
//                                     <Form.Label className="view-label">Name</Form.Label>
//                                     <Form.Control name="Task_Name" autoFocus={isEditing? true : false} disabled value={`${item.Type} - ${item.Date !== 'undefined'? item.Date : 'Not set'} - ${item.Worker}`} type="email" onChange={handleEditChange} placeholder="Enter applicant's name" className={"border-0  autofocus"}/>
//                                 </Form.Group>
//                             </Row>
//                             <Row className="mb-2">
//                                 <Form.Group as={Col} controlId="formGridEmail" className="border-0 rounded p-1">
//                                     <Form.Label className="view-label">Description</Form.Label>
//                                     <Form.Control name="Description" disabled={isEditing? false : true} value={item.Description} onChange={handleEditChange} type="email" placeholder="Enter applicant's name" className={isEditing? "" :"border-0  autofocus"}/>
//                                 </Form.Group>
                                
//                             </Row>
//                             <Row className="mb-2">
//                                 <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
//                                     <Form.Label className="view-label mb-3">Client</Form.Label>
//                                     {
//                                     isEditing? 
//                                         <>
//                                         <Typography component="span" sx={{ml: 1, fontSize: 13, fontFamily: 'Poppins' , color: 'white', background: getColor('Client'), borderRadius: '5px', px: '5px', py: '2px'}} ><CircleIcon fontSize="small" color="action" sx={{fontSize: '7px', color: lightGreen[300]}}/> {item.Client}</Typography>
//                                         <br/>
//                                             <AsyncSelect
//                                                 value={values.Client}
//                                                 name="Client"
//                                                 cacheOptions
//                                                 loadOptions={(value, action) => loadOptions(value, 'clients')}
//                                                 defaultOptions
//                                                 onChange= {(value, action) => handleSelectEditChange(value, action)}
//                                                 components={animatedComponents}
//                                                 placeholder="Search for client ..."
//                                                 onInputChange={handleInputChange}
//                                             /> </>  :
//                                             <>
//                                             <br/>
//                                     <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: blueGrey[300], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{item.Client}</Typography>
//                                     </>
//                                     }
//                                 </Form.Group>
                                
//                             </Row>
                            
//                         </Form>   
//                     </Paper>
                    
//                     <FormHeading title="Related Staff" badge="" icon="assignment"/>
                    
//                     <Paper  sx={{p: 3, my: 1, boxShadow: 'none', background: 'rgba(227, 242, 253, .23)', border: '1px solid rgba(225, 225, 226, .3)'}}>
//                     <Row>
//                         <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
//                             <Form.Label className="view-label mb-3">Task Assignee: </Form.Label>
//                             {
//                             isEditing? 
//                             <>
//                                     <Typography component="span" sx={{ml: 1, fontSize: 13, fontFamily: 'Poppins' , color: 'white', background: getColor('Staff'), borderRadius: '5px', px: '5px', py: '2px'}} ><CircleIcon fontSize="small" color="action" sx={{fontSize: '7px', color: lightGreen[300]}}/> {item.Staff}</Typography>
//                                     <br/>
//                                     <AsyncSelect
//                                         value={values.Staff}
//                                         name="Staff"
//                                         cacheOptions
//                                         loadOptions={(value, action) => loadOptions(value, 'staff')}
//                                         defaultOptions
//                                         onChange= {(value, action) => handleSelectEditChange(value, action)}
//                                         components={animatedComponents}
//                                         placeholder="Search for staff ..."
//                                         onInputChange={handleInputChange}
//                                         /> 
//                                         </> : 
//                                     <>
//                                     <br/>
//                                     <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{item.Staff}</Typography>
//                                     </>
//                             }
//                         </Form.Group>
//                         <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
//                             <Form.Label className="view-label mb-3">Workers Involved</Form.Label>
//                             {
//                                 isEditing? 
//                                     <>
//                                     <Typography component="span" sx={{ml: 1, fontSize: 13, fontFamily: 'Poppins' , color: 'white', background: getColor('Worker'), borderRadius: '5px', px: '5px', py: '2px'}} ><CircleIcon fontSize="small" color="action" sx={{fontSize: '7px', color: lightGreen[300]}}/> {item.Worker}</Typography>
//                                     <br/>
//                                     <AsyncSelect
//                                         value={values.Worker}
//                                         name="Worker"
//                                         cacheOptions
//                                         loadOptions={(value, action) => loadOptions(value, 'workers')}
//                                         defaultOptions
//                                         onChange= {(value, action) => handleSelectEditChange(value, action)}
//                                         components={animatedComponents}
//                                         placeholder="Search for worker ..."
//                                         onInputChange={handleInputChange}
//                                     /> 
//                                     </> :
//                                     <>
//                                     <br/>
//                                     <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px', mr: 2}}>{item.Worker}</Typography>
//                                     </>
//                         }
//                         </Form.Group>
                        
//                     </Row>
                        
//                     </Paper>
//                     <FormHeading title="Task Specifics" badge="" icon="circle"/>

//                     <Paper  sx={{p: 3, my: 1, boxShadow: 'none', background: 'rgba(227, 242, 253, .23)', border: '1px solid rgba(225, 225, 226, .3)'}}>
//                         <Row>
//                             <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
//                                 <Form.Label className="view-label mb-3">Task Type</Form.Label>
//                                 {
//                                 isEditing? 
//                                     <>
//                                     <Typography component="span" sx={{ml: 1, fontSize: 13, fontFamily: 'Poppins' , color: 'white', background: getColor('Type'), borderRadius: '5px', px: '5px', py: '2px'}} ><CircleIcon fontSize="small" color="action" sx={{fontSize: '7px', color: lightGreen[300]}}/> {item.Type}</Typography>
//                                     <br/>
//                                         <AsyncSelect
//                                             value={values.Type}
//                                             name="Type"
//                                             cacheOptions
//                                             loadOptions={(value, action) => loadOptions(value, 'tasktypes')}
//                                             defaultOptions
//                                             onChange= {(value, action) => handleSelectEditChange(value, action)}
//                                             components={animatedComponents}
//                                             placeholder="Search for types ..."
//                                             onInputChange={handleInputChange}
//                                         /> </>  :
//                                         <>
//                                         <br/>
//                                 <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{item.Type}</Typography>
//                                 </>
//                                 }
//                             </Form.Group>
//                             <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
//                                 <Form.Label className="view-label mb-3">Task Status</Form.Label>
//                                 {
//                                 isEditing? 
//                                     <>
//                                     <Typography component="span" sx={{ml: 1, fontSize: 13, fontFamily: 'Poppins' , color: 'white', background: getColor('Status'), borderRadius: '5px', px: '5px', py: '2px'}} ><CircleIcon fontSize="small" color="action" sx={{fontSize: '7px', color: lightGreen[300]}}/> {item.Status}</Typography>
//                                     <br/>
//                                         <AsyncSelect
//                                             value={values.Status}
//                                             name="Status"
//                                             cacheOptions
//                                             loadOptions={(value, action) => loadOptions(value, 'taskstatuses')}
//                                             defaultOptions
//                                             onChange= {(value, action) => handleSelectEditChange(value, action)}
//                                             components={animatedComponents}
//                                             placeholder="Search for status ..."
//                                             onInputChange={handleInputChange}
//                                         /> </> :
//                                         <>
//                                         <br/>
//                                         <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'dodgerblue', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{item.Status}</Typography>
//                                 </>
//                                 }
//                             </Form.Group>
                            
//                         </Row>
                        
//                     </Paper>
                    
//                     <FormHeading title="Task Notes" badge="" icon="note"/>

//                     <Paper  sx={{p: 3, my: 1, boxShadow: 'none', background: 'rgba(227, 242, 253, .23)', border: '1px solid rgba(225, 225, 226, .3)'}}>
//                         <Grid container>
                            
//                             <List>
//                                 <ListItem>
//                                     <ListItemAvatar>
//                                         <Avatar sx={{color: orange[300]}}>
//                                             M
//                                         </Avatar>
//                                     </ListItemAvatar>
//                                     <ListItemText primary="Noter giver's name" secondary="08/03/22 17:00" sx={{fontFamily: 'Poppins'}} primaryTypographyProps={{color: blue[600]}}/>
//                                 </ListItem>
//                             </List>
//                         </Grid>
//                     </Paper>
//                 </Grid>

//             </Grid>      
//         </div>
//     )
// }

// export default ViewTask
 