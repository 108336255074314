import {useState} from 'react';
import useAlert from './useAlert';
import useDialog from './useDialog';

const useCRUD = (task) => {

    const [isEditing, setIsEditing] = useState(false);
    const {dialog, closeDialog,showDialog, handleSubmit} = useDialog();
    const {alert, showAlert } = useAlert()
   

    const handleEdit = () => {

        setIsEditing(true)
        showAlert('info', 'You can now edit some details', true);
    };

    const handleCreate = () => {
        showDialog(true, 'create', '')
    }
    
    const handleDelete = () => {
        showDialog(true, 'delete', task)
    }

    const handleSave = () => {
        setIsEditing(false)
        showAlert('success', 'Your information has been successfully saved', true)
    }


    const handlePrint = () => {
        console.log('Hi, Mo. Functionality pending here')
    }
    return {isEditing, handleCreate, handleEdit, handleDelete, setIsEditing, handleSave, handlePrint, alert, showAlert, dialog, showDialog, closeDialog, handleSubmit }
}

export default useCRUD
