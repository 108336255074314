import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha, useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { blueGrey } from '@material-ui/core/colors';
import { FormControl, InputLabel, Input, InputAdornment, IconButton, Grid} from '@mui/material';
import { Form, Col, Row, InputGroup } from 'react-bootstrap';

const SearchTableComponent = ({handleSearch, other, forTable}) => {

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        background: 'pink',
        // backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
      }));
      
      const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }));
      
      const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: blueGrey[900],
        background: 'rgb(246, 247, 248)',
        '& .MuiInputBase-input': {
          padding: theme.spacing(1, 1, 1, 0),
          // vertical padding + font size from searchIcon
          paddingLeft: `calc(1em + ${theme.spacing(4)})`,
          transition: theme.transitions.create('width'),
          width: '100%',
          [theme.breakpoints.up('md')]: {
            width: '20ch',
          },
        },
      }));

    return (
        <>
        {
            other?
        <Grid container justifyContent="space-between" sx={{mt: 0, mb: 0}}>
            <Grid item lg={12}>
                <Form className="mb-0">
                    <Row>
                        <Col lg={12} >
                            <InputGroup className="shadow-0" >
                                <InputGroup.Text className="border-0 py-2 shadow"><SearchIcon/></InputGroup.Text>
                                <Form.Control placeholder={forTable} onChange={handleSearch} className="border-0 py-2" />
                                {/* <Form.Control placeholder={`${forTable == 'applicants'? "Search applicants" : forTable == 'tasks'? "Search tasks" : "Search assessment"}`} onChange={handleSearch} className="border-0 py-2" /> */}
                            </InputGroup>
                        </Col>
                    </Row>
                </Form>
            </Grid>
        </Grid>

        :
        <FormControl  variant="contained">
            <InputLabel sx={{ml: 2, color: ''}} htmlFor="standard-adornment-password">Search applicants </InputLabel>
            <Input
                
                id="standard-adornment-password"
                sx={{ml: 2, background: 'rgba(237, 240, 241, .1)', borderRadius: '2px', outline: 'none', border: 'none'}}
                onChange={handleSearch}
                //placeholder="Type to search list"
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton> 
                        <SearchIcon/>
                    </IconButton>
                </InputAdornment>
                }
                />
        </FormControl>
        }
        </>
    )
}

export default SearchTableComponent

{/* <Search>
    <SearchIconWrapper>
      <SearchIcon sx={{position: 'relative', zIndex: '100', color: blueGrey[900]}} />
    </SearchIconWrapper>
    <StyledInputBase
      placeholder="Search…"
      inputProps={{ 'aria-label': 'search', color: '' }}
      onChange={handleSearch}
    />
  </Search> */}