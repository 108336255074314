import React, {useState, useEffect} from 'react';
import { Link as BreadcrumbLink, CircularProgress } from '@mui/material';
import { Paper,IconButton, Grid, Divider, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../Hooks/useDialog';
import { blueGrey, purple, blue, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import Crumbs from '../../../Components/Utilities/Crumbs';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import { getStaffList } from '../../../Redux/Actions/StaffActions';
import RegistrationsTbl from '../../../Components/Table/CRM/HR/RegistrationsTbl';
import StaffDialogs from '../../../Components/Dialogs/CRM/Staff/StaffDialogs/StaffDialogs';
 
const Registration = () => { 

    const dispatch = useDispatch();
    const {loading, staff } = useSelector(state => state.staff_List) 
    const [list, setList] = useState([])
    
    useEffect(()=> {
        dispatch(getStaffList());
    }, [])

    useEffect(() => {
        if(staff)
        {
            setList([...staff]);
        }
    }, [staff])
    
    //TABLE DIALOG FUNCTIONALITY
    
        const handleAddClick = () => {
            console.log('THIS IS WORKING')
            showDialog(true, 'create', '');
        }

    //----------------------------------------------------------------------------------------------------------------------------------
    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)
    
    //----------------------------------------------------------------------------------------------------------------------------------
     
    return (
        <>
            
        <ScreenHeader title="Registration" subtitle="Manage registration information from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>
        { dialog.open ?  <StaffDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : ''}

        <Grid container justifyContent="space-between" sx={{mb: 2}}>
            <Crumbs root="CRM" middle="Recruitment" end="Registrations" main/>
            <Stack direction="row" justifyContent="center" alignItems="center">
                <AddButtonLabel item="Registration"/>
                <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                    <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                </IconButton>
            </Stack>
        </Grid>


        <Divider sx={{color: lightBlue[800]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        { loading ?  <HorizontalLoading/> : '' }


        <HorizontalSummaryBox primary="Registrations Brief" secondary="Overall Registrations count" label1="All Registrations" label2="Second Label" label3="Third Label" count={[2,11,6]}/>
        

        <AboveTableHeader title="Registrations List"/>
           
            
            <Grid container>
                <RegistrationsTbl payments={list}/>
            </Grid>
        


     
    </>
    )
}

export default Registration
