import { HOLIDAYGROUPS_LIST_REQUEST, HOLIDAYGROUPS_LIST_SUCCESS, HOLIDAYGROUPS_LIST_FAIL, VIEW_HOLIDAYGROUP_FAIL, VIEW_HOLIDAYGROUP_REQUEST, VIEW_HOLIDAYGROUP_SUCCESS, HOLIDAYGROUP_CREATE_FAIL, HOLIDAYGROUP_UPDATE_REQUEST, HOLIDAYGROUP_UPDATE_FAIL, HOLIDAYGROUP_DELETE_REQUEST, HOLIDAYGROUP_DELETE_FAIL, HOLIDAYGROUP_CREATE_REQUEST, HOLIDAYGROUP_CREATE_SUCCESS, HOLIDAYGROUP_UPDATE_SUCCESS, HOLIDAYGROUP_DELETE_SUCCESS } from "../Constants/holidayGroupConstants";

const holidayGroupsListReducer = (state = {holidayGroups: []}, action) => {

    switch (action.type)
    {
        case HOLIDAYGROUPS_LIST_REQUEST:
            return {loading: true};
 
        case HOLIDAYGROUPS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and holidayGroups data is:' + action.payload)
            return {
                loading: false,
                holidayGroups: action.payload
            }
        case HOLIDAYGROUPS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const holidayGroupCreateReducer = (state = {holidayGroups: []}, action) => {

    switch (action.type) {

        case HOLIDAYGROUP_CREATE_REQUEST:
            return {
                loading: true
            }

        case HOLIDAYGROUP_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                holidayGroups: [...state.holidayGroups, {Applicant_Id: 2, Name: value}]
            };

        case HOLIDAYGROUP_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const holidayGroupViewReducer = (state= {holidayGroup: {}}, action) => {
    switch(action.type)
    {
        case VIEW_HOLIDAYGROUP_REQUEST:
            return {loading: true};
        case VIEW_HOLIDAYGROUP_SUCCESS:
            return {
                    loading: false,
                    holidayGroup: action.payload
                }
        case VIEW_HOLIDAYGROUP_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const holidayGroupUpdateReducer = (state = {holidayGroups: {}}, action) => {

    switch (action.type) {

        case HOLIDAYGROUP_UPDATE_REQUEST:
            return {
                
            }
        case HOLIDAYGROUP_UPDATE_SUCCESS:

            return {

            }
        case HOLIDAYGROUP_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const holidayGroupDeleteReducer = (state = {holidayGroups: {}}, action) => {

    switch (action.type) {
        case HOLIDAYGROUP_DELETE_REQUEST:
            return {
                
            }
        case HOLIDAYGROUP_DELETE_SUCCESS:
            return {

            }
        case HOLIDAYGROUP_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {holidayGroupsListReducer, holidayGroupCreateReducer, holidayGroupViewReducer, holidayGroupUpdateReducer, holidayGroupDeleteReducer }