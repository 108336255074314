import React from 'react';
import AddClientDialog from './AddClientDialog';
import ViewClientDialog from './ViewClientDialog';
import DeleteClientDialog from './DeleteClientDialog';



const ClientDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        {
            type === "create"?

            <AddClientDialog open={open} onClose={onClose}  handleSubmit={handleSubmit} fullWidth />
            
            : type === "view"?

            <ViewClientDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />

            : type === "delete"?

            <DeleteClientDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default ClientDialogs
