import React, {useState} from 'react';
import { alpha, styled } from '@mui/material/styles';
import {Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Box, TextField, Button, MenuItem, Grid, Divider, FormLabel, RadioGroup, FormControlLabel, Radio, InputBase, InputLabel, Autocomplete, Input, FormHelperText, Typography} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { blueGrey, orange } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import TaskIcon from '@mui/icons-material/Task';
import TasksDialogContent from './Tasks/TasksDialogContent';
import ViewTasksDialogContent from './Tasks/ViewTasksDialogContent';
import AddApplicantDialog from './Dialogs/CRM/HR/ApplicantDialogs/AddApplicantDialog';



const ComplexDialog = ({open, onClose, type, info, handleSubmit}) => {



    console.log('Received info: ' + info)

    //Date picker
    
    const [value, setValue] = React.useState(null);

    const [fields, setFields] = useState({
        fullname: type === 'edit_applicant'? info.name : '',
        email: type === 'edit_applicant'? info.email : '',
        mobile: type === 'edit_applicant'? info.mobile : '',
        city: type === 'edit_applicant'? info.city : '',
        gender: type === 'edit_applicant'? info.gender : '',
        department: type === 'edit_applicant'? info.department : '',
        date_added: type === 'edit_applicant'? info.date_added : '',
        
    })
    
    return (
        <> 
        {
            type === "add-new-applicant"?

            <AddApplicantDialog/>
            :
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={type == "view-task" ? "lg" : "md"} >
                {
                    type == "view-task"? '': 
                    <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: blueGrey[900] ,background: type === 'edit_applicant'? blueGrey[50] : type === 'delete_applicant'? blueGrey[50] : type === 'add-new-task'? blueGrey[50] : blueGrey[50]}}>
                        {type === 'edit-applicant'?<PersonIcon fontSize="large" sx={{color: 'dodgerblue', mr: 3}}/> : type === 'delete-applicant'? <PersonRemoveIcon fontSize="large" sx={{color: 'red', mr: 3}}/> : type === "add-new-applicant" ? <PersonAddIcon fontSize="large" sx={{color: 'green', mr: 3}}/> : type === "add-new-task" ? <TaskIcon fontSize="large" sx={{color: blueGrey[900], mr: 3}}/>  : ''}
                        {type === 'edit-applicant'? 'Edit applicant info' : type === 'delete-applicant'? 'Delete applicant info' : type === "add-new-applicant" ? 'Add new applicant' : type === "add-new-task" ? 'Add New Task' : type === "add-new-worker" ? 'Add New Worker' : 'Heading not defined'}
                    </DialogTitle>
                }
                <Divider/>
               
                {/* {'department is:' + fields.department} */}
                
                {
                type === "add-new-applicant" ?
                <DialogContent sx={{background: 'white'}}>
                        
                                <Form className="">
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Applicant ID</Form.Label>
                                            <Form.Control disabled type="text" value={1} placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">First Name</Form.Label>
                                            <Form.Control type="text" value={''} placeholder="E.g Tim" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Surname</Form.Label>
                                            <Form.Control type="text" value={''} placeholder="E.g Kelly" className="outline-none border-none" />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Email</Form.Label>
                                            <Form.Control type="email" value={''} placeholder="timkelly@email.com" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Mobile</Form.Label>
                                            <Form.Control type="text" value={''} placeholder="E.g +263 787 582 628" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Home Phone</Form.Label>
                                            <Form.Control type="email" value={''} placeholder="E.g +263 787 582 628" className="outline-none border-none" />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Division</Form.Label>
                                            <Form.Select defaultValue="Choose Division">
                                                <option>I.T</option>
                                                <option>Marketing</option>
                                                <option>Sales</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Job</Form.Label>
                                            <Form.Select defaultValue="Choose Job">
                                                <option>I.T</option>
                                                <option>Marketing</option>
                                                <option>Sales</option>
                                            </Form.Select>
                                        </Form.Group>
                                        
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Applicant ID</Form.Label>
                                            <Form.Control disabled type="text" value={1} placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Applicant Prospect</Form.Label>
                                            <br/>
                                            <Form.Check inline type="checkbox" name="same" label="No" className="text-dark"/>
                                            <Form.Check inline type="checkbox" name="same" label="Yes" className="text-dark"/>
                                        </Form.Group>
                                        
                                    </Row>
                                    <Row>
                                    <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Applicant Status</Form.Label>
                                            <br/>
                                            <Form.Check inline type="checkbox" name="same" label="No" className="text-dark"/>
                                            <Form.Check inline type="checkbox" name="same" label="Yes" className="text-dark"/>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Assessment</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>        
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Applicant Documents</Form.Label>
                                            <Form.Control type="file" className="outline-none border-none" />
                                        </Form.Group>
                                        
                                    </Row>
                                </Form>

                    </DialogContent>
                
                :
                type === "edit-applicant" ?
                <DialogContent sx={{background: 'white'}}>

                                <Form className="">
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Applicant ID</Form.Label>
                                            <Form.Control disabled type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">First Name</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Surname</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Email</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Mobile</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Home Phone</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Division</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Job</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                        
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Applicant Rating</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Applicant Prospect</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                        
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Applicant Status</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Assessment</Form.Label>
                                            <Form.Control type="email" value="Tim Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>        
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Applicant Documents</Form.Label>
                                            <Form.Control type="file" className="outline-none border-none" />
                                        </Form.Group>
                                        
                                    </Row>
                                </Form>

                    </DialogContent>
                :
                type === "add-new-task" ?
                <DialogContent sx={{background: 'white'}}>

                    <TasksDialogContent/>

                </DialogContent>
                :
                type === "view-task" ?
                <DialogContent sx={{background: 'white'}}>

                    <ViewTasksDialogContent/>

                </DialogContent>
                :
                type === "add-new-worker" ?
                <DialogContent sx={{background: 'white'}}>

                    <TasksDialogContent/>

                </DialogContent>
                :
 
                <DialogContent sx={{background: 'white'}}>
                    <Form className="">
                        <Typography variant="h6" sx={{color: blueGrey[900]}}>Are you sure you want to delete this applicant with the below information?</Typography>
                        <br/>
                        <Divider/>

                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Applicant ID</Form.Label>
                                            <Form.Control disabled type="email" value="1" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Job Applied For</Form.Label>
                                            <Form.Control disabled type="email" value="1" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">First Name</Form.Label>
                                            <Form.Control disabled type="email" value="Tim" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                            <Form.Label className="text-dark">Surname</Form.Label>
                                            <Form.Control disabled type="email" value="Kelly" placeholder="E.g 123qst1" className="outline-none border-none" />
                                        </Form.Group>
                                    </Row>
                                  
                                </Form>
                    
                </DialogContent>
                
                }
                <DialogActions sx={{my:2, mr: 3}}>
                    <Button sx={{color: 'white', fontWeight: 400, background: blueGrey[700],'&:hover': {background: blueGrey[300]}}} onClick={onClose}><CloseIcon/></Button>
                    <Button sx={{color: 'white', fontWeight: 400, background: type === 'delete-applicant'? 'rgba(255, 0, 0,.9)': blueGrey[900], '&:hover': {background: blueGrey[500]}}}  onClick={handleSubmit}>{type === 'edit-applicant'? 'Save Changes' : type === 'delete-applicant'? 'Delete Info' : type === 'add-new-task'? 'Save new task' : type === 'view-task'? 'Task' : 'Save Applicant Details'}</Button>
                </DialogActions>
        </Dialog>
        }
    </>
    )

}

export default ComplexDialog
