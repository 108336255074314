import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, Breadcrumbs} from '@mui/material';
import { blueGrey, blue, pink } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useDispatch, useSelector } from 'react-redux';
import {  submitStaff } from '../../../../../Redux/Actions/StaffActions';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import api from '../../../../../config/apiConfig';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { Link as BreadcrumbLink } from '@mui/material';
import { getDepartmentsList } from '../../../../../Redux/Actions/DepartmentActions';
import { getBranchesList } from '../../../../../Redux/Actions/BranchActions';
import { getRateCardsList } from '../../../../../Redux/Actions/RateCardActions';


const AddStaffDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET BRANCHES
    const branches_ListState = useSelector(state => state.branches_List);
    const {branches, loading: loading_branches} = branches_ListState;

    //GET DEPARTMENTS
    const departments_ListState = useSelector(state => state.departments_List);
    const {departments, loading} = departments_ListState;

    //GET DEPARTMENTS
    const rateCards_ListState = useSelector(state => state.rateCards_List);
    const {rateCards, loading: loading_cards} = rateCards_ListState;

    //GET JOB ROLES
    const roles_List_State = useSelector((state) => state.jobRoles_List);
    const {loading: loading_roles, jobRoles} = roles_List_State;


    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;


    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getJobRolesList());
        dispatch(getDepartmentsList());
        dispatch(getBranchesList());
        dispatch(getRateCardsList());
       
    }, [])
    

    const [values, setValues] = useState({
            gender: '',
            firstname: '',
            surname: '',
            nationalno: '',
            dob: '',
            mobile: '',
            officeline: '',
            hphone: '',
            email: '', 
            address1: '',
            address2: '' , 
            postcode: '' , 
            town: '', 
            divisions: '', 
            jobroles: '', 
            staff: '',
            ratecards: ''
        
        });

    const [submitValues, setSubmitValues] = useState({
            gender: '',
            firstname: '',
            surname: '',
            nationalno: '',
            dob: '',
            mobile: '',
            officeline: '',
            hphone: '',
            email: '', 
            address1: '',
            address2: '' , 
            postcode: '' , 
            town: '', 
            divisions: '', 
            jobroles: [], 
            staff: '',
            ratecards: ''
            
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {

        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
         
        }
        else if(name == 'type' || name == 'status' || name == 'result')
        {

            setValues({
                ...values,
                [name]: value
            });
           
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
 
        } 
    }
   
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitStaff(submitValues))
        handleSubmit('success',"Successfully saved office staff's information");

    }

    const animatedComponents = makeAnimated();

    const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {

        
        const keeper = [];

        const tableName = name == 'departments' ? departments : name == 'jobroles' ? jobRoles : name == 'staff' ? staff : name == 'ratecards' ? rateCards :'' ;

        const id_field = name === 'departments' ? 'Department_Id': name === 'staff' ? 'Staff_Id' : name === 'jobroles'? 'Role_Id' : name === 'branches'? 'Branch_Id': name === 'ratecards'? 'Id' : '';
        const name_field = name === 'departments' ? 'Department_Name': name === 'staff' ? 'FullName' : name === 'jobroles'? 'Name' : name === 'branches'? 'Branch_Name':  name === 'ratecards'? 'Name' :  '';
            
        tableName.forEach(x => {
            keeper.push({value: x[id_field], label: x[name_field]})
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['departments', 'staff', 'jobroles', 'branches', 'ratecards'];
        
        const id_field = name === 'departments' ? 'Department_Id': name === 'staff' ? 'Staff_Id' : name === 'jobroles'? 'Role_Id' : name === 'branches'? 'Branch_Id': name === 'ratecards'? 'Id' : '';
        const name_field = name === 'departments' ? 'Department_Name': name === 'staff' ? 'FullName' : name === 'jobroles'? 'Name' : name === 'branches'? 'Branch_Name':  name === 'ratecards'? 'Name' :  '';
        
        names.forEach(x => x === name)
        {
            
            data.forEach(x => {
                keeper.push({value: x[id_field], label: x[name_field]})
               })
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action) => {
        
        const name = action.name;

        if(name === 'jobroles')
        {
            const container = [];

            value.forEach(x => {
                
                container.push(x.value)
            })
            console.log('valueeeeeeeeeeeeeee:' + container)

            setValues({
                ...values,
                jobroles: [...value]
            })
            setSubmitValues({
                ...submitValues,
                jobroles: container
            })
        }
        else{

            setValues({
                ...values,
                [name]: value
            })
    
            setSubmitValues({
                ...submitValues,
                [name]: value.value
            })
        }
    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }

    const [summary, setSummary] = useState(false)

    const handleSummary = () => {
        setSummary(!summary);
    }

    //const workers = ['Emily Bone', 'Shawn Drisic', 'Isaac Phily']
    const genders = [
        {value: 'Male', label: 'Male'},
        {value: 'Female', label: 'Female'},
        
    ]
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[800]}}  >
                
                <EngineeringIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add Office Staff </Typography>
            </DialogTitle>
     
        <Divider/>
        {/* <p>fname {submitValues.firstname}</p>
        <p>sname {submitValues.surname}</p>
        <p>pref {submitValues.shiftpreferences}</p>
        <p>div {submitValues.divisions}</p>
        <p>roles{submitValues.jobroles}</p>
        <p>status {submitValues.workerstatuses}</p>
        <p> staff{submitValues.staff}</p>
        <p>doc {submitValues.payrolldoctypes}</p>
        <p>setup {submitValues.payrollsetups}</p>
        <p>umb {submitValues.linkedumbrellas}</p> */}
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <Grid item lg={12} sx={{position: 'sticky', zIndex: 10}}>
                        <Grid item lg={12} sx={{m: 0, p: 0, background: ''}}>
                            <Stack direction="row" alignItems="center" sx={{ background: 'blue[50]', mb: 0}}>
                                <Typography variant="body1" sx={{color: blue[800],fontWeight: 900, textAlign: 'center'}}>Form Summary</Typography>
                                <Button variant="text" size="small" sx={{fontSize: 12, color: blue[900], textAlign: 'center'}} onClick={handleSummary}>{summary? 'Close' : 'Open'}</Button>
                            </Stack>
                        </Grid>
                                
                        <Grid container flexDirection="row" justifyContent="space-between" className={`summary ${summary? 'open' : ''}`} sx={{px: 3, pt: '2px', background: '#f2f7fc', border: '2px solid whitesmoke', borderRadius: 3, boxShadow: 'none', overflowY: 'scroll'}}>
                                {/* <Divider sx={{border: '.5px solid gray'}}/> */}
                                <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff ID: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`OFS - ${!loading_staff? staff.length + 1 : ''}`} </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.assessment_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                    <Typography variant="body2" sx={{color: values.subject? '' : blue[500], fontWeight: values.subject? 800 : 500, ml: 5}}>{values.subject? values.subject : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                    <Typography variant="body2" sx={{color: values.applicant? '' : blue[500], fontWeight: values.applicant? 800 : 500, ml: 5}}>{values.applicant? values.applicant : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Name </Typography>
                                    <Typography variant="body2" sx={{color: applicantName? '' : blue[500], fontWeight: applicantName? 800 : 500, ml: 5}}>{applicantName? applicantName : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Consultant Name </Typography>
                                    <Typography variant="body2" sx={{color: staffName? '' : blue[500], fontWeight: staffName? 800 : 500, ml: 5}}>{staffName? staffName : 'null' }</Typography>
                                </Stack>
                                
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Worker Type: </Typography>
                                    <Typography variant="body2" sx={{color: values.type? '' : blue[500], fontWeight: values.type? 800 : 500, ml: 5}}>{values.type? values.type : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Level: </Typography>
                                    <Typography variant="body2" sx={{color: values.level? '' : blue[500], fontWeight: values.level? 800 : 500, ml: 5}}>{values.level? values.level : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Worker Result: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.result? 'white' : blue[500], fontSize: '.75rem', textAlign: 'left', px: values.result? '4px' : '', borderRadius: '3px' ,fontWeight: values.result? 500 : 500, background: values.result? blue[500] : ''}}>{values.result? values.result : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Worker Status: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.status? 'white' : blue[500], fontSize: values.status? '.75rem' :'', textAlign: 'left', px: values.status? '4px' : '', borderRadius: '3px'  ,fontWeight: values.status? 500 : 500, background: values.status? blue[500] : ''}}>{values.status? values.status : 'null' }</Typography>
                                </Stack>
                                
                            
                            </Grid>
                        </Grid>
                    
                    <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                        <Stack sx={{mb: 3, background: '', height: '300px', borderRadius: '5px', width: '80%', pl: 3, pt: 3}}>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300]}} fontSize="small" href="#">Basic Info</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#contact">Contact</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#specifics">Job Specifics</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#payroll">Payroll</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#client">Company Info</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[400], mt: 4}} fontSize="small" href="#status">Worker Status</BreadcrumbLink>
                        </Stack>
                    </Grid>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Staff ID</Form.Label>
                                <Form.Control disabled type="email" value="OFS-3" placeholder="E.g 123qst1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Gender</Form.Label>
                                    <Select 
                                        options={genders}
                                        components={animatedComponents}
                                        placeholder="Select gender ..."
                                    />
                            </Form.Group>
                        </Row>
                    
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">First Name</Form.Label>
                                <Form.Control type="email" name="firstname" value={values.firstname} onChange={handleChange} placeholder="Enter first name" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Surname</Form.Label>
                                <Form.Control type="email" name="surname" value={values.surname} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <Row>
                            
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">National Insurance #</Form.Label>
                                <Form.Control type="email" name="nationalno" value={values.nationalno} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">D.O.B</Form.Label>
                                <Form.Control type="email" name="dob" value={values.dob} onChange={handleChange} placeholder="Enter surname" className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography id="contact" variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Contact Information</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Mobile</Form.Label>
                                <Form.Control type="email" name="mobile" value={values.mobile} onChange={handleChange} placeholder="Enter first name" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Office Line</Form.Label>
                                <Form.Control type="text" name="officeline" value={values.officeline} onChange={handleChange} placeholder="Enter office number" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Home Phone</Form.Label>
                                <Form.Control type="email" name="hphone" value={values.hphone} onChange={handleChange} placeholder="Enter home number" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Email</Form.Label>
                                <Form.Control type="email" name="email" value={values.email} onChange={handleChange} placeholder="Enter email" className="outline-none border-none" />
                            </Form.Group>
                        
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Address Line 1</Form.Label>
                                <Form.Control type="email" name="address1" value={values.address1} onChange={handleChange} placeholder="Enter address line 1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Address Line 2</Form.Label>
                                <Form.Control type="email" name="address2" value={values.address2} onChange={handleChange} placeholder="Enter address line 2" className="outline-none border-none" />
                            </Form.Group>
                        
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Postcode</Form.Label>
                                <Form.Control type="email" name="postcode" value={values.postcode} onChange={handleChange} placeholder="Enter postcode" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Town/City</Form.Label>
                                <Form.Control type="email" name="town" value={values.town} onChange={handleChange} placeholder="Enter town/city" className="outline-none border-none" />
                            </Form.Group>
                        
                        </Row>


                        <br/>
                        <Typography id="specifics" variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Some Specifics</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Department</Form.Label>
                                    <AsyncSelect
                                        value={values.departments}
                                        name="departments"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'departments')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for department ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Job Role</Form.Label>
                                        <AsyncSelect
                                            value={values.jobroles}
                                            name="jobroles"
                                            cacheOptions
                                            isMulti
                                            loadOptions={(value, action) => loadOptions(value, 'jobroles')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for roles ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>
                        </Row>
                        <Row>
                           
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Branch</Form.Label>
                                    <AsyncSelect
                                        value={values.branches}
                                        name="branches"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'branches')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for branch ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography id="payroll" variant="body2" sx={{display: 'inline',color: 'white',background: pink[500], borderRadius: '5px', px: 2, py: '5px'}}>Payroll</Typography>
                        <Row>
                            
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="view-label">Rate Card</Form.Label>
                                    <AsyncSelect
                                        value={values.ratecards}
                                        name="ratecards"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'ratecards')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for card name ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                           
                        </Row>
                        
                        <br/>
                        <Typography id="staff" variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Consultant</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Related Consultant</Form.Label>
                                   <AsyncSelect
                                        value={values.staff}
                                        name="staff"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'staff')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for staff ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>

                        </Form>
                    </Grid>

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[800], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Office Staff</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddStaffDialog
