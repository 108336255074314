import React from 'react'
import { Stack} from '@mui/material';
import { blue } from '@mui/material/colors';

const FormHeader = (props) => {
    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{mb: 2}}>
                {props.children}
            </Stack>
        </>
    )
}

export default FormHeader
