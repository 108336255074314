import { DOCUMENTTYPES_LIST_REQUEST, DOCUMENTTYPES_LIST_SUCCESS, DOCUMENTTYPES_LIST_FAIL, VIEW_DOCUMENTTYPE_FAIL, VIEW_DOCUMENTTYPE_REQUEST, VIEW_DOCUMENTTYPE_SUCCESS, DOCUMENTTYPE_CREATE_FAIL, DOCUMENTTYPE_UPDATE_REQUEST, DOCUMENTTYPE_UPDATE_FAIL, DOCUMENTTYPE_DELETE_REQUEST, DOCUMENTTYPE_DELETE_FAIL, DOCUMENTTYPE_CREATE_REQUEST, DOCUMENTTYPE_CREATE_SUCCESS, DOCUMENTTYPE_UPDATE_SUCCESS, DOCUMENTTYPE_DELETE_SUCCESS } from "../Constants/documentTypeConstants";

const documentTypesListReducer = (state = {documentTypes: []}, action) => {

    switch (action.type)
    {
        case DOCUMENTTYPES_LIST_REQUEST:
            return {loading: true};
 
        case DOCUMENTTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and documentTypes data is:' + action.payload)
            return {
                loading: false,
                documentTypes: action.payload
            }
        case DOCUMENTTYPES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const documentTypeCreateReducer = (state = {documentTypes: []}, action) => {

    switch (action.type) {

        case DOCUMENTTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case DOCUMENTTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                documentTypes: [...state.documentTypes, {Applicant_Id: 2, Name: value}]
            };

        case DOCUMENTTYPE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const documentTypeViewReducer = (state= {documentType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_DOCUMENTTYPE_REQUEST:
            return {loading: true};
        case VIEW_DOCUMENTTYPE_SUCCESS:
            return {
                    loading: false,
                    documentType: action.payload
                }
        case VIEW_DOCUMENTTYPE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const documentTypeUpdateReducer = (state = {documentTypes: {}}, action) => {

    switch (action.type) {

        case DOCUMENTTYPE_UPDATE_REQUEST:
            return {
                
            }
        case DOCUMENTTYPE_UPDATE_SUCCESS:

            return {

            }
        case DOCUMENTTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const documentTypeDeleteReducer = (state = {documentTypes: {}}, action) => {

    switch (action.type) {
        case DOCUMENTTYPE_DELETE_REQUEST:
            return {
                
            }
        case DOCUMENTTYPE_DELETE_SUCCESS:
            return {

            }
        case DOCUMENTTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {documentTypesListReducer, documentTypeCreateReducer, documentTypeViewReducer, documentTypeUpdateReducer, documentTypeDeleteReducer }