import React from 'react';
import AddHolidayDialog from './AddHolidayDialog';
import ViewHolidayDialog from './ViewHolidayDialog';
import DeleteHolidayDialog from './DeleteHolidayDialog';



const HolidayDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        { 
            type === "create"?

            <AddHolidayDialog open={open} onClose={onClose}  handleSubmit={handleSubmit} fullWidth />
            
            : type === "view"?

            <ViewHolidayDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />

            : type === "delete"?

            <DeleteHolidayDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default HolidayDialogs
