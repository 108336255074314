export const BOOKINGS_LIST_REQUEST = 'BOOKINGS_LIST_REQUEST'
export const BOOKINGS_LIST_SUCCESS = 'BOOKINGS_LIST_SUCCESS'
export const BOOKINGS_LIST_FAIL = 'BOOKINGS_LIST_FAIL'


export const BOOKING_CREATE_REQUEST = 'BOOKING_CREATE_REQUEST'
export const BOOKING_CREATE_SUCCESS = 'BOOKING_CREATE_SUCCESS'
export const BOOKING_CREATE_FAIL = 'BOOKING_CREATE_FAIL'


export const BOOKING_UPDATE_REQUEST = 'BOOKING_UPDATE_REQUEST'
export const BOOKING_UPDATE_SUCCESS = 'BOOKING_UPDATE_SUCCESS'
export const BOOKING_UPDATE_FAIL = 'BOOKING_UPDATE_FAIL'

export const BOOKING_DELETE_REQUEST = 'BOOKING_DELETE_REQUEST'
export const BOOKING_DELETE_SUCCESS = 'BOOKING_DELETE_SUCCESS'
export const BOOKING_DELETE_FAIL = 'BOOKING_DELETE_FAIL'

export const VIEW_BOOKING_REQUEST = 'VIEW_BOOKING_REQUEST'
export const VIEW_BOOKING_SUCCESS = 'VIEW_BOOKING_SUCCESS'
export const VIEW_BOOKING_FAIL = 'VIEW_BOOKING_FAIL'