import { CLIENTS_LIST_REQUEST, CLIENTS_LIST_SUCCESS, CLIENTS_LIST_FAIL, VIEW_CLIENT_FAIL, VIEW_CLIENT_REQUEST, VIEW_CLIENT_SUCCESS, CLIENT_CREATE_FAIL, CLIENT_UPDATE_REQUEST, CLIENT_UPDATE_FAIL, CLIENT_DELETE_REQUEST, CLIENT_DELETE_FAIL, CLIENT_CREATE_REQUEST, CLIENT_CREATE_SUCCESS, CLIENT_UPDATE_SUCCESS, CLIENT_DELETE_SUCCESS } from "../Constants/clientConstants";

const clientsListReducer = (state = {clients: []}, action) => {

    switch (action.type)
    {
        case CLIENTS_LIST_REQUEST:
            return {loading: true};
 
        case CLIENTS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and clients data is:' + action.payload)
            return {
                loading: false,
                clients: action.payload
            }
        case CLIENTS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const clientCreateReducer = (state = {clients: []}, action) => {

    switch (action.type) {

        case CLIENT_CREATE_REQUEST:
            return {
                loading: true
            }

        case CLIENT_CREATE_SUCCESS: 
        console.log('Previous divisions state from reducer: ' + state.clients);
        
        const newClient = action.payload;
        const updatedList = [...state.clients, newClient];
            return {
                loading: false,
                clients: updatedList,
            };

        case CLIENT_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const clientViewReducer = (state= {client: {}}, action) => {
    switch(action.type)
    {
        case VIEW_CLIENT_REQUEST:
            return {loading: true};
        case VIEW_CLIENT_SUCCESS:
            return {
                    loading: false,
                    client: action.payload
                }
        case VIEW_CLIENT_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const clientUpdateReducer = (state = {clients: {}}, action) => {

    switch (action.type) {

        case CLIENT_UPDATE_REQUEST:
            return {
                
            }
        case CLIENT_UPDATE_SUCCESS:

            return {

            }
        case CLIENT_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const clientDeleteReducer = (state = {clients: {}}, action) => {

    switch (action.type) {
        case CLIENT_DELETE_REQUEST:
            return {
                
            }
        case CLIENT_DELETE_SUCCESS:
            return {

            }
        case CLIENT_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {clientsListReducer, clientCreateReducer, clientViewReducer, clientUpdateReducer, clientDeleteReducer }