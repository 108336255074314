export const TASKTYPES_LIST_REQUEST = 'TASKTYPES_LIST_REQUEST'
export const TASKTYPES_LIST_SUCCESS = 'TASKTYPES_LIST_SUCCESS'
export const TASKTYPES_LIST_FAIL = 'TASKTYPES_LIST_FAIL'

export const VIEW_TASKTYPE_REQUEST = 'VIEW_TASKTYPE_REQUEST'
export const VIEW_TASKTYPE_SUCCESS = 'VIEW_TASKTYPE_SUCCESS'
export const VIEW_TASKTYPE_FAIL = 'VIEW_TASKTYPE_FAIL'

export const TASKTYPE_CREATE_REQUEST = 'TASKTYPE_CREATE_REQUEST'
export const TASKTYPE_CREATE_SUCCESS = 'TASKTYPE_CREATE_SUCCESS'
export const TASKTYPE_CREATE_FAIL = 'TASKTYPE_CREATE_FAIL'

export const TASKTYPE_UPDATE_REQUEST = 'TASKTYPE_UPDATE_REQUEST'
export const TASKTYPE_UPDATE_SUCCESS = 'TASKTYPE_UPDATE_SUCCESS'
export const TASKTYPE_UPDATE_FAIL = 'TASKTYPE_UPDATE_FAIL'

export const TASKTYPE_DELETE_REQUEST = 'TASKTYPE_DELETE_REQUEST'
export const TASKTYPE_DELETE_SUCCESS = 'TASKTYPE_DELETE_SUCCESS'
export const TASKTYPE_DELETE_FAIL = 'TASKTYPE_DELETE_FAIL'