import React, {useState} from 'react';
import AddAssessmentDialog from './AddAssessmentDialog';
import ViewAssessmentDialog from './ViewAssessmentDialog';
import DeleteAssessmentDialog from './DeleteAssessmentDialog';
import EditAssessmentDialog from './EditAssessmentDialog';



const AssessmentDialogs = ({open, onClose, type, info, handleSubmit}) => {

  
    return (
        <> 
        {
            type === "create"?

            <AddAssessmentDialog open={open} onClose={onClose} handleSubmit={handleSubmit}  fullWidth />
            
            : type === "view"?

            <ViewAssessmentDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />

            : type === "edit"?

            <EditAssessmentDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />

            : type === "delete"?

            <DeleteAssessmentDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />
            // <ViewAssessmentDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default AssessmentDialogs
