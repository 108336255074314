import { RATECARDS_LIST_REQUEST, RATECARDS_LIST_SUCCESS, RATECARDS_LIST_FAIL, VIEW_RATECARD_FAIL, VIEW_RATECARD_REQUEST, VIEW_RATECARD_SUCCESS, RATECARD_CREATE_FAIL, RATECARD_UPDATE_REQUEST, RATECARD_UPDATE_FAIL, RATECARD_DELETE_REQUEST, RATECARD_DELETE_FAIL, RATECARD_CREATE_REQUEST, RATECARD_CREATE_SUCCESS, RATECARD_UPDATE_SUCCESS, RATECARD_DELETE_SUCCESS } from "../Constants/rateCardConstants";

const rateCardsListReducer = (state = {rateCards: []}, action) => {

    switch (action.type)
    {
        case RATECARDS_LIST_REQUEST:
            return {loading: true};
 
        case RATECARDS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and rateCards data is:' + action.payload)
            return {
                loading: false,
                rateCards: action.payload
            }
        case RATECARDS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const rateCardCreateReducer = (state = {rateCards: []}, action) => {

    switch (action.type) {

        case RATECARD_CREATE_REQUEST:
            return {
                loading: true
            }

        case RATECARD_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                rateCards: [...state.rateCards, {Applicant_Id: 2, Name: value}]
            };

        case RATECARD_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const rateCardViewReducer = (state= {rateCard: {}}, action) => {
    switch(action.type)
    {
        case VIEW_RATECARD_REQUEST:
            return {loading: true};
        case VIEW_RATECARD_SUCCESS:
            return {
                    loading: false,
                    rateCard: action.payload
                }
        case VIEW_RATECARD_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const rateCardUpdateReducer = (state = {rateCards: {}}, action) => {

    switch (action.type) {

        case RATECARD_UPDATE_REQUEST:
            return {
                
            }
        case RATECARD_UPDATE_SUCCESS:

            return {

            }
        case RATECARD_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const rateCardDeleteReducer = (state = {rateCards: {}}, action) => {

    switch (action.type) {
        case RATECARD_DELETE_REQUEST:
            return {
                
            }
        case RATECARD_DELETE_SUCCESS:
            return {

            }
        case RATECARD_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {rateCardsListReducer, rateCardCreateReducer, rateCardViewReducer, rateCardUpdateReducer, rateCardDeleteReducer }