import React, {useState} from 'react';
import AddTransactionDialog from './AddTransactionDialog';
import ViewTransactionDialog from './ViewTransactionDialog';
import DeleteTransactionDialog from './DeleteTransactionDialog';



const TransactionDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <> 
        {
            type === "create"?

            <AddTransactionDialog open={open} onClose={onClose} handleSubmit={handleSubmit}  fullWidth />
            
            : type === "view"?

            <ViewTransactionDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />

            : type === "delete"?

            <DeleteTransactionDialog open={open} onClose={onClose} handleSubmit={handleSubmit} info={info} fullWidth />
            // <ViewTransactionDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default TransactionDialogs
