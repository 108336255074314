import { SETUPTYPES_LIST_REQUEST, SETUPTYPES_LIST_SUCCESS, SETUPTYPES_LIST_FAIL, VIEW_SETUPTYPE_FAIL, VIEW_SETUPTYPE_REQUEST, VIEW_SETUPTYPE_SUCCESS, SETUPTYPE_CREATE_FAIL, SETUPTYPE_UPDATE_REQUEST, SETUPTYPE_UPDATE_FAIL, SETUPTYPE_DELETE_REQUEST, SETUPTYPE_DELETE_FAIL, SETUPTYPE_CREATE_REQUEST, SETUPTYPE_CREATE_SUCCESS, SETUPTYPE_UPDATE_SUCCESS, SETUPTYPE_DELETE_SUCCESS } from "../Constants/setupTypeConstants";

const setupTypesListReducer = (state = {setupTypes: []}, action) => {

    switch (action.type)
    {
        case SETUPTYPES_LIST_REQUEST:
            return {loading: true};
 
        case SETUPTYPES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and setupTypes data is:' + action.setupload)
            return {
                loading: false,
                setupTypes: action.setupload
            }
        case SETUPTYPES_LIST_FAIL:
            return {
                error: action.setupload
                
            }
        default:
            return state
    }
}
 
const setupTypeCreateReducer = (state = {setupTypes: []}, action) => {

    switch (action.type) {

        case SETUPTYPE_CREATE_REQUEST:
            return {
                loading: true
            }

        case SETUPTYPE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.setupload;
            return {
                loading: false,
                setupTypes: [...state.setupTypes, {Applicant_Id: 2, Name: value}]
            };

        case SETUPTYPE_CREATE_FAIL:
            return {
                error: action.setupload
            }
        default:
            return state;
    }
}

const setupTypeViewReducer = (state= {setupType: {}}, action) => {
    switch(action.type)
    {
        case VIEW_SETUPTYPE_REQUEST:
            return {loading: true};
        case VIEW_SETUPTYPE_SUCCESS:
            return {
                    loading: false,
                    setupType: action.setupload
                }
        case VIEW_SETUPTYPE_FAIL:
            return {
                error: action.setupload
            }
        default:
            return state
    }
}

const setupTypeUpdateReducer = (state = {setupTypes: {}}, action) => {

    switch (action.type) {

        case SETUPTYPE_UPDATE_REQUEST:
            return {
                
            }
        case SETUPTYPE_UPDATE_SUCCESS:

            return {

            }
        case SETUPTYPE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const setupTypeDeleteReducer = (state = {setupTypes: {}}, action) => {

    switch (action.type) {
        case SETUPTYPE_DELETE_REQUEST:
            return {
                
            }
        case SETUPTYPE_DELETE_SUCCESS:
            return {

            }
        case SETUPTYPE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {setupTypesListReducer, setupTypeCreateReducer, setupTypeViewReducer, setupTypeUpdateReducer, setupTypeDeleteReducer }