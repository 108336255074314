import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress} from '@mui/material';
import { blueGrey, orange, blue, purple, red } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicantsList } from '../../../../../Redux/Actions/ApplicantsActions';
import { submitAssessment } from '../../../../../Redux/Actions/AssessmentsActions';



const DeleteApplicantDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    

    //GET APPLICANTS
    const applicants_List_State = useSelector((state) => state.applicants_List);
    const {loading, applicants} = applicants_List_State;
    
       
    //CURRENT DATE
    const d = new Date(), yyyy = d.getFullYear(), mm = d.getMonth(), dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
       
        dispatch(getApplicantsList());
       
       
    }, [])
    

    const [values, setValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

   

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    
   


    const handleDelete = () => {
        
        dispatch(submitAssessment())
        handleSubmit();

    }

    
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" >

       

            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white'}} className="dialog-bg"  >
                <Stack direction="row">
                    <DeleteIcon sx={{color: blue[50], mr: 2, fontSize: '1.5rem'}}/>
                    <Typography variant="h6" sx={{color: blueGrey[50],fontWeight: 700}}>DELETE Applicant</Typography>
                </Stack>
               
            </DialogTitle>
    
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
            
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item lg={12}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 1}}>Basic Information</Typography>
                            <Row>
                                
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark">Applicant Id</Form.Label>
                                    <Form.Control name="assessment_date" disabled type="text" value={values.assessment_date} placeholder="E.g 123qst1" className="border-0 bold" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="text-dark mb-0 p-0 view-label">Applicant Name</Form.Label>
                                    <Form.Control  name="subject" type="email" disabled value={'Applicant Name'} placeholder="Your description here" className="border-0 bold" />
                                </Form.Group>
                            </Row>
                           <br/>
                       
                        </Form>
                    <Typography variant="body1" sx={{color: 'white' , width: '60%',background: 'red', borderRadius: 3, px: 2,fontSize: '1rem', fontWeight: 600}}> Are you sure you want to delete the applicant stated above?</Typography>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: 'blue', '&:hover': {color: blueGrey[900]}}} onClick={handleDelete}>Delete Applicant</Button>
        </DialogActions>
    </Dialog>
    )
}

export default DeleteApplicantDialog
