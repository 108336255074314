export const RATESTATUSES_LIST_REQUEST = 'RATESTATUSES_LIST_REQUEST'
export const RATESTATUSES_LIST_SUCCESS = 'RATESTATUSES_LIST_SUCCESS'
export const RATESTATUSES_LIST_FAIL = 'RATESTATUSES_LIST_FAIL'


export const RATESTATUS_CREATE_REQUEST = 'RATESTATUS_CREATE_REQUEST'
export const RATESTATUS_CREATE_SUCCESS = 'RATESTATUS_CREATE_SUCCESS'
export const RATESTATUS_CREATE_FAIL = 'RATESTATUS_CREATE_FAIL'


export const RATESTATUS_UPDATE_REQUEST = 'RATESTATUS_UPDATE_REQUEST'
export const RATESTATUS_UPDATE_SUCCESS = 'RATESTATUS_UPDATE_SUCCESS'
export const RATESTATUS_UPDATE_FAIL = 'RATESTATUS_UPDATE_FAIL'

export const RATESTATUS_DELETE_REQUEST = 'RATESTATUS_DELETE_REQUEST'
export const RATESTATUS_DELETE_SUCCESS = 'RATESTATUS_DELETE_SUCCESS'
export const RATESTATUS_DELETE_FAIL = 'RATESTATUS_DELETE_FAIL'

export const VIEW_RATESTATUS_REQUEST = 'VIEW_RATESTATUS_REQUEST'
export const VIEW_RATESTATUS_SUCCESS = 'VIEW_RATESTATUS_SUCCESS'
export const VIEW_RATESTATUS_FAIL = 'VIEW_RATESTATUS_FAIL'