export const TRANSACTIONS_LIST_REQUEST = 'TRANSACTIONS_LIST_REQUEST'
export const TRANSACTIONS_LIST_SUCCESS = 'TRANSACTIONS_LIST_SUCCESS'
export const TRANSACTIONS_LIST_FAIL = 'TRANSACTIONS_LIST_FAIL'


export const TRANSACTION_CREATE_REQUEST = 'TRANSACTION_CREATE_REQUEST'
export const TRANSACTION_CREATE_SUCCESS = 'TRANSACTION_CREATE_SUCCESS'
export const TRANSACTION_CREATE_FAIL = 'TRANSACTION_CREATE_FAIL'


export const TRANSACTION_UPDATE_REQUEST = 'TRANSACTION_UPDATE_REQUEST'
export const TRANSACTION_UPDATE_SUCCESS = 'TRANSACTION_UPDATE_SUCCESS'
export const TRANSACTION_UPDATE_FAIL = 'TRANSACTION_UPDATE_FAIL'

export const TRANSACTION_DELETE_REQUEST = 'TRANSACTION_DELETE_REQUEST'
export const TRANSACTION_DELETE_SUCCESS = 'TRANSACTION_DELETE_SUCCESS'
export const TRANSACTION_DELETE_FAIL = 'TRANSACTION_DELETE_FAIL'

export const VIEW_TRANSACTION_REQUEST = 'VIEW_TRANSACTION_REQUEST'
export const VIEW_TRANSACTION_SUCCESS = 'VIEW_TRANSACTION_SUCCESS'
export const VIEW_TRANSACTION_FAIL = 'VIEW_TRANSACTION_FAIL'