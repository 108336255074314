import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as GrIcons from 'react-icons/gr';
import * as BsIcons from 'react-icons/bs';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TopicIcon from '@mui/icons-material/Topic';
import PaidIcon from '@mui/icons-material/Paid';
import CircleIcon from '@mui/icons-material/Circle';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AppsIcon from '@mui/icons-material/Apps';
import TodayIcon from '@mui/icons-material/Today';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import TaskIcon from '@mui/icons-material/Task'; 
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
  
   

export const NavData = [
    {
        id: 1,
        title: 'Dashboard',
        path: '/',
        icon: <DashboardIcon fontSize="small"/>,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: 'Dashboard',
        // subMenu: [],
        
    },
    {
        id: 2,
        title: 'CRM',
        path: '/crm',
        icon: <AppsIcon fontSize="small"/>,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: '',
        subMenu: [
            {
                id: 22,
                title: 'Calendars',
                path: '/calendar',
                icon: <TodayIcon fontSize="small" sx={{color: ''}}/>,
                secondSubMenu: [
                    {
                        id: 221,
                        title: 'Assessments Calendar',
                        path: '/assessmentscalendar',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 222,
                        title: 'Drivers Calendar',
                        path: '/driverscalendar',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 223,
                        title: 'Construction Calendar',
                        path: '/constructioncalendar',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 224,
                        title: 'Industrial Calendar',
                        path: '/industrialcalendar',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                id: 23,
                title: 'Tasks',
                path: '/tasks',
                icon: <TaskIcon fontSize="small" sx={{color: ''}}/>,
                secondSubMenu: [
                    {
                        id: 231,
                        title: 'Current',
                        path: '/tasks',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    // {
                    //     id: 232,
                    //     title: 'Outstanding',
                    //     path: '/outstandingtasks',
                    //     icon: <GrIcons.GrSystem  sx={{color: 'gray'}}/>,
                    // },
                ]
            },
            {
                id: 24,
                title: 'HR',
                path: '/recruitment',
                icon: <PersonAddAlt1Icon fontSize="small" sx={{color: ''}}/>,
                secondSubMenu: [
                    {
                        id: 241,
                        title: 'Applicants',
                        path: '/applicants',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 242,
                        title: 'Assessments',
                        path: '/assessments',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 243,
                        title: 'Registration',
                        path: '/registration',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                id: 25,
                title: 'Staff',
                path: '/staff',
                icon: <PeopleIcon fontSize="small" sx={{color: ''}}/>,
                secondSubMenu: [
                    {
                        id: 251,
                        title: 'Workers',
                        path: '/workers',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 252,
                        title: 'Availability',
                        path: '/availability',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 253,
                        title: 'Shifts',
                        path: '/shifts',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 254,
                        title: 'Check Ins',
                        path: '/checkins',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 255,
                        title: 'Timesheets',
                        path: '/timesheets',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 256,
                        title: 'Documents',
                        path: '/documents',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 257,
                        title: 'Payments',
                        path: '/payments',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 257,
                        title: 'Invoices',
                        path: '/invoices',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 258,
                        title: 'Profile',
                        path: '/profiles',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 259,
                        title: 'Office Staff',
                        path: '/staff',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                id: 26,
                title: 'Sales',
                path: '/sales',
                icon: <ReceiptIcon  fontSize="small" sx={{color: ''}}/>,
                secondSubMenu: [
                    {
                        id: 261,
                        title: 'New client form',
                        path: '/clients',
                        icon: <GrIcons.GrSystem  fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 262,
                        title: 'Contacts',
                        path: '/contacts',
                        icon: <GrIcons.GrSystem  fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 263,
                        title: 'Clients',
                        path: '/clients',
                        icon: <GrIcons.GrSystem  fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 264,
                        title: 'Checks & Limits',
                        path: '/checksandlimits',
                        icon: <GrIcons.GrSystem  fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 265,
                        title: 'Prospects',
                        path: '/prospects',
                        icon: <GrIcons.GrSystem  fontSize="small" sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                id: 27,
                title: 'Operations',
                path: '/operations',
                icon: <DeviceHubIcon fontSize="small" sx={{color: ''}}/>,
                secondSubMenu: [
                    {
                        id: 271,
                        title: 'Divisions',
                        path: '/divisions',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 272,
                        title: 'Requests',
                        path: '/requests',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 273,
                        title: 'Accidents & Incidents',
                        path: '/accidentsandincidents',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 274,
                        title: 'Opportunities',
                        path: '/opportunities',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 275,
                        title: 'Bookings',
                        path: '/bookings',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 276,
                        title: 'Client Data',
                        path: '/clients',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 277,
                        title: 'Queries',
                        path: '/queries',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 278,
                        title: 'Budget Tracker',
                        path: '/budgettracker',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                id: 28,
                title: 'Management',
                path: '/management',
                icon: <ManageAccountsIcon fontSize="small" sx={{color: ''}}/>,
                secondSubMenu: [
                    {
                        id: 281,
                        title: 'Rate Cards',
                        path: '/ratecards',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 282,
                        title: 'Client Sites',
                        path: '/clientsites',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 283,
                        title: 'Credit Checks',
                        path: '/creditchecks',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 284,
                        title: 'Last Service Visit',
                        path: '/lastservicevisits',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 285,
                        title: 'Reports',
                        path: '/reports',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                ]
            },
            {
                id: 29,
                title: 'System',
                path: '/systems',
                icon: <AllInclusiveIcon fontSize="small" sx={{color: ''}}/>,
                secondSubMenu: [
                    {
                        id: 291,
                        title: 'Working Week',
                        path: '/workingweeks',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 292,
                        title: 'Worker of the month',
                        path: '/workerofthemonth',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 293,
                        title: 'Holidays',
                        path: '/holidays',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                    {
                        id: 294,
                        title: 'Time & Attendance',
                        path: '/timeandattendance',
                        icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                    },
                ]
            },
]
    },
    {
        id: 3,
        title: 'Recruitment',
        path: '/recruitment',
        icon: <PeopleAltIcon fontSize="small" />,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: 'Recruitment',
        subMenu: [
            {
                id: 33,
                title: 'Ad Templates',
                path: '/advert_templates',
                icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                //secondSubMenu: []
            },
            {
                id: 34,
                title: 'Applicants',
                path: '/applicants',
                icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                //secondSubMenu: []
                
            },
            {
                id: 35,
                title: 'Place Job',
                path: '/place_job',
                icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                //secondSubMenu: []
            },
            {
                id: 36,
                title: 'Jobs',
                path: '/systems',
                icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                //secondSubMenu: []
            },
]
    },
    {
        id: 4,
        title: 'Documents',
        path: '/documents',
        icon: <TopicIcon fontSize="small"/>,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: 'Documents',
        subMenu: [
                    {
                        id: 44,
                        title: 'Employees',
                        path: '/employees',
                        icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                        iconClosed: <BsIcons.BsChevronDown/>,
                        iconUp: <BsIcons.BsChevronUp/>,
                        secondSubMenu: [
                            {
                                title: 'Send document',
                                path: '/send_document',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Add document',
                                path: '/add_document',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Search document',
                                path: '/search_document',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                                ]
                    },
                    {
                        id: 45,
                        title: 'Workers',
                        path: '/workers',
                        icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                        iconClosed: <BsIcons.BsChevronDown/>,
                        iconUp: <BsIcons.BsChevronUp/>,
                        secondSubMenu: [
                            {
                                title: 'Send document',
                                path: '/send_document',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Add document',
                                path: '/add_document',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Search document',
                                path: '/search_document',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'View document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Document Library',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                                ]
                    },
                    {
                        id: 46,
                        title: 'Clients',
                        path: '/clients',
                        icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                        iconClosed: <BsIcons.BsChevronDown/>,
                        iconUp: <BsIcons.BsChevronUp/>,
                        secondSubMenu: [
                            {
                                title: 'Send new document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Add new document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Search signed document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'View pending document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Document Library',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                        ]
                    },
                    {
                        id: 47,
                        title: 'Other',
                        path: '/other',
                        icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                        iconClosed: <BsIcons.BsChevronDown/>,
                        iconUp: <BsIcons.BsChevronUp/>,
                        secondSubMenu: [
                            {
                                title: 'Send new document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Add new document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Search signed document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'View pending document',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Document Library',
                                path: '/systems',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                        ]
                    },
        ]
    },
    {
        id: 5,
        title: 'Finance',
        path: '/finance',
        icon: <PaidIcon fontSize="small" />,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: 'Finance',
        subMenu: [
                    {
                        id: 51,
                        title: 'Reconciliation',
                        path: '/reconciliation',
                        icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                        secondSubMenu: [
                            {
                                id: 511, 
                                title: 'Chart of accounts',
                                path: '/chartofaccounts',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                id: 512, 
                                title: 'Upload File',
                                path: '/uploadfiles',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                id: 513, 
                                title: 'Reconcile',
                                path: '/reconciles',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                id: 514, 
                                title: 'Transactions',
                                path: '/transactions',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                id: 515, 
                                title: 'VAT Returns',
                                path: '/vatreturns',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                id: 516, 
                                title: 'Profit & Loss',
                                path: '/profitandloss',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                id: 517, 
                                title: 'Balance Sheet',
                                path: '/balancesheets',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                id: 518, 
                                title: 'Customers',
                                path: '/customers',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                id: 519, 
                                title: 'Suppliers',
                                path: '/suppliers',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                id: 520, 
                                title: 'Payer/Payee',
                                path: '/payerpayees',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                        ]
                    },
                    {
                        id: 52,
                        title: 'Invoices',
                        path: '/invoices',
                        icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                        secondSubMenu: [
                            {
                                id: 521,
                                title: 'Invoices',
                                path: '/invoices',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                id: 522,
                                title: 'Send Invoice',
                                path: '/sendinvoice',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                        ]
                    },
                    {
                        id: 53,
                        title: 'Checks',
                        path: '/checks',
                        icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                        secondSubMenu: [
                            {
                                title: 'Cgrayit Checks',
                                path: '/cgrayitchecks',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'LTD Checks',
                                path: '/ltdchecks',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'VAT Check',
                                path: '/vatchecks',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'UTR Check',
                                path: '/utrcheck',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                            {
                                title: 'Drivers Licence Check',
                                path: '/drlicencecheck',
                                icon: <GrIcons.GrSystem fontSize="small" sx={{color: 'gray'}}/>,
                            },
                        ]
                    },
        ]
    },
    {
        id: 6,
        title: 'Settings',
        path: '/settings',
        icon: <SettingsIcon fontSize="small" />,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: 'Settings',
        subMenu: [
                    {
                        id: 61,
                        title: 'Account',
                        path: '/account',
                        icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                    },
                    {
                        id: 62,
                        title: 'Users',
                        path: '/users',
                        icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                    },
                    {
                        id: 63,
                        title: 'Email',
                        path: '/email',
                        icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
                    },
        ]
    },
    { 
        id: 7,
        title: 'Admin',
        path: '/admin',
        icon: <AdminPanelSettingsIcon fontSize="small"/>,
        iconClosed: <BsIcons.BsChevronDown/>,
        iconUp: <BsIcons.BsChevronUp/>,
        category: 'Admin',
        subMenu: [
            {
                id: 71,
                title: 'Users',
                path: '/users',
                icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
            },
            {
                id: 72,
                title: 'Roles',
                path: '/roles',
                icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
            },
            {
                id: 73,
                title: 'Auth Tokens',
                path: '/authtokens',
                icon: <CircleIcon fontSize="small" sx={{color: '#263238', fontSize: 11}}/>,
            },
]
    },
]

export default NavData 
