import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import { blueGrey, cyan, blue, deepPurple, teal, orange, deepOrange, green } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done'; 
import { submitAssessment } from '../../../../../Redux/Actions/AssessmentsActions';
import { Link as BreadcrumbLink } from '@mui/material';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getWorkers } from '../../../../../Redux/Actions/WorkerActions';
import { getAvailabilityStatusesList } from '../../../../../Redux/Actions/AvailabilityStatusActions';
import { submitAvailability } from '../../../../../Redux/Actions/AvailabilityActions';
import api from '../../../../../config/apiConfig';
import { submitProfile } from '../../../../../Redux/Actions/ProfileActions';


const AddProfileDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch(); 

    //GET WORKERS
    const applicants_ListState = useSelector(state => state.applicants_List);
    const {applicants, loading} = applicants_ListState;

    //GET WORKERS
    const workers_ListState = useSelector(state => state.workers_List);
    const {workers, loading: loading_workers} = workers_ListState;
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getAvailabilityStatusesList());
        dispatch(getWorkers());
       
    }, [])
    

    const [values, setValues] = useState({
            
            type: '',
            owner: '' , 
            applicants: '' , 
            workers: '' , 
        });

    const [submitValues, setSubmitValues] = useState({
            
            type: '',
            owner: '' , 
            applicants: '' , 
            workers: '' , 
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicants' || name == 'workers' )
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
          
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
 
        }
    }

    const handleSave = () => {
        console.log('in save function')
        if(values.type === 'Applicant')
        {
            dispatch(submitProfile({type: submitValues.type, owner: submitValues.applicants }));
        }
        else
        {
            dispatch(submitProfile({type: submitValues.type, owner: submitValues.workers }));

        }
        handleSubmit('success','Successfully created profile');

    }

    const animatedComponents = makeAnimated();


    const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {

        
        const keeper = [];

        const tableName = name == 'workers' ? workers : name == 'applicants' ? applicants : '';

        const id_field = name === 'applicants' ? 'Id' : name === 'workers'? 'Worker_Id' : '';
        const name_field = name === 'applicants' ? 'First_Name' : name === 'workers'? 'First_Name' : '';
            
        tableName.forEach(x => {
            if(name === 'workers' || name === 'applicants')
            {
                keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
            }
            else{
                keeper.push({value: x[id_field], label: x[name_field]})

            }
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['applicants', 'workers'];
        
        const id_field = name === 'applicants' ? 'Id' : name === 'workers'? 'Worker_Id' : '';
        const name_field = name === 'applicants' ? 'First_Name' : name === 'workers'? 'First_Name' : '';
        
        names.forEach(x => x === name)
        {
            
            data.forEach(x => {
                if(name === 'workers' || name === 'applicants' )
                {
                    keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
                }
                else{

                    keeper.push({value: x[id_field], label: x[name_field]})
                }
                })
               
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action) => {
        
        const name = action.name;

        
        setValues({
            ...values,
            [name]: value
        })
        setSubmitValues({
            ...submitValues,
            [name]: value.value
        })

    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
 
    
    const [date, setDate] = useState(`${1}/${1}/${2022}`);
    const [displayDates, setDisplayDates] = useState({
        monday: ''
    })

    const handleDateChange = (newValue) => {
        const dayOfWeek = newValue.getDay();
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate(`${day}/${month+1}/${year}`);
        console.log('DATE SELECTED, DAY OF WEEK IS:' + dayOfWeek)

        if(dayOfWeek > 2)
        {
            setDisplayDates({
                monday: `${day}/${month+1}/${year}`,
                tuesday: `${day}/${month+1}/${year}`,
                wednesday: `${day}/${month+1}/${year}`,
                thursday: `${day}/${month+1}/${year}`,
                friday: `${day}/${month+1}/${year}`,
                saturday: `${day}/${month+1}/${year}`,
                sunday: `${day}/${month+1}/${year}`,
            })
        }
        //setDate()
        //new Date().get
    }
    
    const [summary, setSummary] = useState(false)

    const handleSummary = () => {
        setSummary(!summary);
    }
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[700]}}  >
                
                <CheckCircleIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add Profile </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
        
    
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <Grid item lg={12} sx={{position: 'sticky', zIndex: 10}}>
                        <Grid item lg={12} sx={{m: 0, p: 0, background: ''}}>
                            <Stack direction="row" alignItems="center" sx={{ background: 'blue[50]', mb: 0}}>
                                <Typography variant="body1" sx={{color: blue[800],fontWeight: 900, textAlign: 'center'}}>Form Summary</Typography>
                                <Button variant="text" size="small" sx={{fontSize: 12, color: blue[900], textAlign: 'center'}} onClick={handleSummary}>{summary? 'Close' : 'Open'}</Button>
                            </Stack>
                        </Grid>
                                 
                        <Grid container flexDirection="row" justifyContent="space-between" className={`summary ${summary? 'open' : ''}`} sx={{px: 3, pt: '2px', background: '#f2f7fc', border: '2px solid whitesmoke', borderRadius: 3, boxShadow: 'none', overflowY: 'scroll'}}>
                                {/* <Divider sx={{border: '.5px solid gray'}}/> */}
                                <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability ID: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`AVL - ${!loading? 2 + 1 : ''}`} </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.assessment_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                    <Typography variant="body2" sx={{color: values.subject? '' : blue[500], fontWeight: values.subject? 800 : 500, ml: 5}}>{values.subject? values.subject : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                    <Typography variant="body2" sx={{color: values.applicant? '' : blue[500], fontWeight: values.applicant? 800 : 500, ml: 5}}>{values.applicant? values.applicant : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Name </Typography>
                                    <Typography variant="body2" sx={{color: applicantName? '' : blue[500], fontWeight: applicantName? 800 : 500, ml: 5}}>{applicantName? applicantName : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff Name </Typography>
                                    <Typography variant="body2" sx={{color: staffName? '' : blue[500], fontWeight: staffName? 800 : 500, ml: 5}}>{staffName? staffName : 'null' }</Typography>
                                </Stack>
                                
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Type: </Typography>
                                    <Typography variant="body2" sx={{color: values.type? '' : blue[500], fontWeight: values.type? 800 : 500, ml: 5}}>{values.type? values.type : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Level: </Typography>
                                    <Typography variant="body2" sx={{color: values.level? '' : blue[500], fontWeight: values.level? 800 : 500, ml: 5}}>{values.level? values.level : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Result: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.result? 'white' : blue[500], fontSize: '.75rem', textAlign: 'left', px: values.result? '4px' : '', borderRadius: '3px' ,fontWeight: values.result? 500 : 500, background: values.result? blue[500] : ''}}>{values.result? values.result : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Status: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.status? 'white' : blue[500], fontSize: values.status? '.75rem' :'', textAlign: 'left', px: values.status? '4px' : '', borderRadius: '3px'  ,fontWeight: values.status? 500 : 500, background: values.status? blue[500] : ''}}>{values.status? values.status : 'null' }</Typography>
                                </Stack>
                                
                            
                            </Grid>
                        </Grid>
                        <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                            <Stack sx={{mb: 3, background: '', height: '300px', borderRadius: '5px', width: '80%', pl: 3, pt: 3}}>
                                <BreadcrumbLink underline="none" sx={{color: blueGrey[300]}} fontSize="small" href="#basic">Basic Info</BreadcrumbLink>
                                <BreadcrumbLink underline="none" sx={{color: blueGrey[300] , mt: 4}} fontSize="small" href="#basic">Date & Time</BreadcrumbLink>
                                <BreadcrumbLink underline="none" sx={{color: blueGrey[300] , mt: 4}} fontSize="small" href="#basic">Applicant</BreadcrumbLink>
                            </Stack>

                            {/* <p>TYPE - VALUE: {values.type}</p>
                            <p>TYPE - SUBMIT: {submitValues.type}</p>
                            <p>APP: {submitValues.applicants}</p>
                            <p>WOR: {submitValues.workers}</p> */}
                        </Grid>
                        <Grid item lg={10}>
                            <Form className="my-3">
                            
                            <Row>
                                <Typography variant="body2" sx={{display: 'inline',color: blue[800],background: blue[50], borderRadius: '5px', px: 2, py: '5px'}}>ID Automatically Generated</Typography>
                            </Row>

                            <br/>
                            <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Info</Typography>
                            <Row>
                                <Form.Group as={Row} controlId="formGridEmail" className="rounded mt-4">
                                    <Form.Label  className="view-label" >Profile Type</Form.Label>
                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="Applicant"  value={values.type} name="type" onChange={handleChange}>
                                        <FormControlLabel value="Applicant" control={<Radio size="small"  />} label="Applicant" sx={{color: blue[700]}} />
                                        <FormControlLabel value="Worker" control={<Radio size="small" />} label="Worker" sx={{color: blue[700]}}/>
                                    </RadioGroup>
                                </Form.Group>
                            </Row>

                            <Row>
                                {values.type === 'Applicant'? 
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Related Applicant</Form.Label>
                                    <AsyncSelect
                                        value={values.applicants}
                                        name="applicants"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'applicants')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for applicant ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                : values.type === 'Worker'? 
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Related Worker</Form.Label>
                                    <AsyncSelect
                                        value={values.workers}
                                        name="workers"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'workers')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for worker ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                : 
                                
                                <Typography variant="body2" sx={{display: 'inline',color: blueGrey[700],background: blueGrey[50], borderRadius: '5px', px: 2, py: '5px', mt: 2}}>Please choose type of profile</Typography>
                            
                                }
                                
                                
                            </Row>
                        </Form>
                     
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[600], '&:hover': {color: deepPurple[900]}}} onClick={handleSave}>Save Profile</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddProfileDialog
