import { PAYERPAYEES_LIST_REQUEST, PAYERPAYEES_LIST_SUCCESS, PAYERPAYEES_LIST_FAIL, VIEW_PAYERPAYEE_FAIL, VIEW_PAYERPAYEE_REQUEST, VIEW_PAYERPAYEE_SUCCESS, PAYERPAYEE_CREATE_FAIL, PAYERPAYEE_UPDATE_REQUEST, PAYERPAYEE_UPDATE_FAIL, PAYERPAYEE_DELETE_REQUEST, PAYERPAYEE_DELETE_FAIL, PAYERPAYEE_CREATE_REQUEST, PAYERPAYEE_CREATE_SUCCESS, PAYERPAYEE_UPDATE_SUCCESS, PAYERPAYEE_DELETE_SUCCESS } from "../Constants/payerPayeeConstants";

const payerPayeesListReducer = (state = {payerPayees: []}, action) => {

    switch (action.type)
    {
        case PAYERPAYEES_LIST_REQUEST:
            return {loading: true};
 
        case PAYERPAYEES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and payerPayees data is:' + action.payload)
            return {
                loading: false,
                payerPayees: action.payload
            }
        case PAYERPAYEES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const payerPayeeCreateReducer = (state = {payerPayees: []}, action) => {

    switch (action.type) {

        case PAYERPAYEE_CREATE_REQUEST:
            return {
                loading: true
            }

        case PAYERPAYEE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                payerPayees: [...state.payerPayees, {Applicant_Id: 2, Name: value}]
            };

        case PAYERPAYEE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const payerPayeeViewReducer = (state= {payerPayee: {}}, action) => {
    switch(action.type)
    {
        case VIEW_PAYERPAYEE_REQUEST:
            return {loading: true};
        case VIEW_PAYERPAYEE_SUCCESS:
            return {
                    loading: false,
                    payerPayee: action.payload
                }
        case VIEW_PAYERPAYEE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const payerPayeeUpdateReducer = (state = {payerPayees: {}}, action) => {

    switch (action.type) {

        case PAYERPAYEE_UPDATE_REQUEST:
            return {
                
            }
        case PAYERPAYEE_UPDATE_SUCCESS:

            return {

            }
        case PAYERPAYEE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const payerPayeeDeleteReducer = (state = {payerPayees: {}}, action) => {

    switch (action.type) {
        case PAYERPAYEE_DELETE_REQUEST:
            return {
                
            }
        case PAYERPAYEE_DELETE_SUCCESS:
            return {

            }
        case PAYERPAYEE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {payerPayeesListReducer, payerPayeeCreateReducer, payerPayeeViewReducer, payerPayeeUpdateReducer, payerPayeeDeleteReducer }