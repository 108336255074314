import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Box, Grid, Paper, TextField, Button, FormControlLabel, Checkbox, FormControl, OutlinedInput, MenuItem } from '@mui/material';
import ComplexLogo from '../../assets/ComplexLogo.png';
import AccountCircle from '@mui/icons-material/AccountCircle';

const SignUp = () => {

    const currencies = [
        {
          value: 'Office Staff',
          label: 'Office Staff',
        },
        {
          value: 'New members',
          label: 'New members',
        },
        {
          value: 'Other',
          label: 'Other',
        },
      ];

    let navigate = useNavigate();
    
    const permitToEnter = ()=> {
  
        navigate('/dashboard');
    }

    const [currency, setCurrency] = React.useState('Office Staff');

    const handleChange = (event) => {
        setCurrency(event.target.value);
      };

    
    return (
        <>
            <Grid item lg={8} sx={{background: 'rgba(253, 251, 251, 1)', borderRadius: '10px', padding: '20px 30px', boxShadow: '2px 2px 25px black'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}} className="reg-img">
                    <img src={ComplexLogo} />
                </Box>
                <Box component="form" sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                    <FormControl sx={{ width: '25ch', pr: '10px', pt: '30px' }}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Select User Type"
                            value={currency}
                            onChange={handleChange}
                            size="small"
                            
                            >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>

                    <FormControl sx={{ width: '25ch' }}>
                        <Box component="span" className="helper-text" sx={{ pb: '5px', textAlign: 'left' }}>Registration Code</Box>
                        <OutlinedInput label="Select User Type"  placeholder="Please enter text" size="small" />
                    </FormControl>
                </Box>
                <Box component="form" sx={{mt: '20px',display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                     <FormControl sx={{ width: '25ch', paddingRight: '10px' }}>
                        <Box component="span" className="helper-text" sx={{ pb: '5px', textAlign: 'left' }}>First name</Box>
                        <OutlinedInput label="Select User Type" placeholder="Please enter text" size="small"/>
                    </FormControl>
                     <FormControl sx={{ width: '25ch' }}>
                        <Box component="span" className="helper-text" sx={{ pb: '5px', textAlign: 'left' }}>Surname</Box>
                        <OutlinedInput label="Select User Type" placeholder="Please enter text" size="small"/>
                    </FormControl>
                </Box>
                <Box component="form" sx={{mt: '20px', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                     <FormControl sx={{ width: '25ch', paddingRight: '10px' }}>
                        <Box component="span" className="helper-text" sx={{ pb: '5px', textAlign: 'left' }}>Email</Box>
                        <OutlinedInput label="Select User Type" placeholder="Please enter text" size="small"/>
                    </FormControl>
                     <FormControl sx={{ width: '25ch' }}>
                        <Box component="span" className="helper-text" sx={{ pb: '5px', textAlign: 'left' }}>Mobile</Box>
                        <OutlinedInput label="Select User Type" placeholder="Please enter text" size="small"/>
                    </FormControl>
                </Box>
                <FormControl sx={{ mt: '20px', width: '100%' }}>
                    <Box component="span" className="helper-text" sx={{ pb: '5px', textAlign: 'left' }}>Mobile</Box>
                    <OutlinedInput label="Select User Type" placeholder="Please enter text" size="small"/>
                </FormControl>
                
                <Button variant="contained" sx={{mt: '30px', background: 'red', width: '100%'}}>REGISTER</Button>
               
            </Grid>
        </>
    )
}

export default SignUp
