import React from 'react';
import {DataGrid, GridToolbarContainer,GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarDensitySelector, GridToolbar} from '@mui/x-data-grid';
import { blue, blueGrey, lightBlue } from '@mui/material/colors';
import { Divider } from '@mui/material';
import { borderRadius } from '@mui/system';

function CustomToolbar() {
    return (
       <>
            <GridToolbarContainer sx={{py: 2, background: 'rgba(225, 245, 254, .7)'}}>
                <GridToolbarColumnsButton sx={{color: blueGrey[600], background: 'blue[50]', ml: 1, px: 1, fontFamily: 'Poppins', border: '.9px solid #90caf9', boxShadow: '.2px .2px 3px #90caf9'}}/>
                <GridToolbarFilterButton sx={{color: blueGrey[600], background: 'blue[50]', ml: 1, px: 1, fontFamily: 'Poppins', border: '.9px solid #90caf9', boxShadow: '.2px .2px 3px #90caf9'}}/>
                <GridToolbarDensitySelector sx={{color: blueGrey[600], background: 'blue[50]', ml: 1, px: 1, fontFamily: 'Poppins', border: '.9px solid #90caf9', boxShadow: '.2px .2px 3px #90caf9'}}/>
                <GridToolbarExport sx={{color: blueGrey[600], background: 'blue[50]', ml: 1, px: 1, fontFamily: 'Poppins', border: '.9px solid #90caf9', boxShadow: '.2px .2px 3px #90caf9'}}/>
            </GridToolbarContainer>
            <Divider sx={{color: blue[900], borderWidth: 0}}/>
        </>
    );
  }


const DataTable = ({rows, columns, loading}) => {

    const [selectionModel, setSelectionModel] = React.useState([]);

    return (
        <>
             <DataGrid
                columns={columns}
                rows={rows}
                loading={loading}
                checkboxSelection
                disableSelectionOnClick
                //isCellEditable={false}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                  }}
                selectionModel={selectionModel}
                components={{
                    Toolbar: CustomToolbar,
                        }}
                    
                
            />
        </>
    )
}

export default DataTable
