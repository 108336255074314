export const CHECKINSTATUSES_LIST_REQUEST = 'CHECKINSTATUSES_LIST_REQUEST'
export const CHECKINSTATUSES_LIST_SUCCESS = 'CHECKINSTATUSES_LIST_SUCCESS'
export const CHECKINSTATUSES_LIST_FAIL = 'CHECKINSTATUSES_LIST_FAIL'


export const CHECKINSTATUS_CREATE_REQUEST = 'CHECKINSTATUS_CREATE_REQUEST'
export const CHECKINSTATUS_CREATE_SUCCESS = 'CHECKINSTATUS_CREATE_SUCCESS'
export const CHECKINSTATUS_CREATE_FAIL = 'CHECKINSTATUS_CREATE_FAIL'


export const CHECKINSTATUS_UPDATE_REQUEST = 'CHECKINSTATUS_UPDATE_REQUEST'
export const CHECKINSTATUS_UPDATE_SUCCESS = 'CHECKINSTATUS_UPDATE_SUCCESS'
export const CHECKINSTATUS_UPDATE_FAIL = 'CHECKINSTATUS_UPDATE_FAIL'

export const CHECKINSTATUS_DELETE_REQUEST = 'CHECKINSTATUS_DELETE_REQUEST'
export const CHECKINSTATUS_DELETE_SUCCESS = 'CHECKINSTATUS_DELETE_SUCCESS'
export const CHECKINSTATUS_DELETE_FAIL = 'CHECKINSTATUS_DELETE_FAIL'

export const VIEW_CHECKINSTATUS_REQUEST = 'VIEW_CHECKINSTATUS_REQUEST'
export const VIEW_CHECKINSTATUS_SUCCESS = 'VIEW_CHECKINSTATUS_SUCCESS'
export const VIEW_CHECKINSTATUS_FAIL = 'VIEW_CHECKINSTATUS_FAIL'