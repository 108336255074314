export const INFLUENCES_LIST_REQUEST = 'INFLUENCES_LIST_REQUEST'
export const INFLUENCES_LIST_SUCCESS = 'INFLUENCES_LIST_SUCCESS'
export const INFLUENCES_LIST_FAIL = 'INFLUENCES_LIST_FAIL'


export const INFLUENCE_CREATE_REQUEST = 'INFLUENCE_CREATE_REQUEST'
export const INFLUENCE_CREATE_SUCCESS = 'INFLUENCE_CREATE_SUCCESS'
export const INFLUENCE_CREATE_FAIL = 'INFLUENCE_CREATE_FAIL'


export const INFLUENCE_UPDATE_REQUEST = 'INFLUENCE_UPDATE_REQUEST'
export const INFLUENCE_UPDATE_SUCCESS = 'INFLUENCE_UPDATE_SUCCESS'
export const INFLUENCE_UPDATE_FAIL = 'INFLUENCE_UPDATE_FAIL'

export const INFLUENCE_DELETE_REQUEST = 'INFLUENCE_DELETE_REQUEST'
export const INFLUENCE_DELETE_SUCCESS = 'INFLUENCE_DELETE_SUCCESS'
export const INFLUENCE_DELETE_FAIL = 'INFLUENCE_DELETE_FAIL'

export const VIEW_INFLUENCE_REQUEST = 'VIEW_INFLUENCE_REQUEST'
export const VIEW_INFLUENCE_SUCCESS = 'VIEW_INFLUENCE_SUCCESS'
export const VIEW_INFLUENCE_FAIL = 'VIEW_INFLUENCE_FAIL'