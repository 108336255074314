import React from 'react';
import AddDivisionDialog from './AddDivisionDialog';
import ViewDivisionDialog from './ViewDivisionDialog';
import DeleteDivisionDialog from './DeleteDivisionDialog';



const DivisionDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        {
            type === "create"? 

            <AddDivisionDialog open={open} onClose={onClose}  handleSubmit={handleSubmit} fullWidth />
            
            : type === "view"?

            <ViewDivisionDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />

            : type === "delete"?

            <DeleteDivisionDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default DivisionDialogs
