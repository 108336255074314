import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, Grid, DialogActions, Button, Typography, Stack, Divider } from '@mui/material';
import { deepPurple, blueGrey } from '@mui/material/colors';
import AlarmOnRoundedIcon from '@mui/icons-material/AlarmOnRounded';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import { useSelector, useDispatch } from 'react-redux';

const ClockIn = ({onClose, shift}) => {

    const dispatch = useDispatch();

    const handleClockIn = () => {

        const object = {
            shift_Id: 1,
            timeIn: '13:00',
            timeOut: '13:00',
            status: 1,
        }
        console.log('ck')
        //dispatch(submitCheckIn(object));
    }
    

    return (
        <>
            <DialogContent sx={{background: 'white'}}>
            
                <Grid 
                    container 
                    alignItems="flex-start" 
                    justifyContent="center" 
                    sx={{background: blueGrey[900], height: 200, width: '100%', borderRadius: 2}}
                    className="shift-box"
                >   
                
                {shift? 
                    <Grid item lg={12} sx={{height: '100%'}}>
                        <Grid container justifyContent="space-around" alignItems="center" sx={{background: '',height: '100%'}}>
                            <Stack justifyContent="center" alignItems="center" sx={{ background: '', ml: 5}}>
                                <WorkOutlineRoundedIcon sx={{fontSize: 80, color: deepPurple[900], fontWeight: 300, background: 'white', borderRadius: '100%', p: 2, mb: 2}}/>
                                <Typography sx={{fontSize: 15, fontWeight: 600 ,color: 'whitesmoke', mb: 2, background: deepPurple[900], px: 2, borderRadius: 3}}>{shift.Shift_Status}</Typography>
                            </Stack>
                            <Stack justifyContent="center" alignItems="center">
                                <Typography sx={{fontSize: 25, fontWeight: 600 ,color: 'white', mb: 2, fontFamily: 'Poppins'}}>{`${shift.First_Name} ${shift.Surname} | ${shift.Date} | ${shift.Client}`}</Typography>
                                <Stack flexDirection="row" justifyContent="space-between" sx={{width: '100%', mb: 3}}>
                                    <Stack flexDirection="row" alignItems="center">
                                        <Typography sx={{fontSize: 15, fontWeight: 500 ,color: 'white', fontFamily: 'Poppins'}}>Date: </Typography>
                                        <Typography sx={{fontSize: 17, fontWeight: 600 ,color: 'white', fontFamily: 'Poppins', background: '', ml: 1}}> 02/02/2022 </Typography>
                                    </Stack>
                                    <Stack flexDirection="row" alignItems="center">
                                        <Typography sx={{fontSize: 15, fontWeight: 500 ,color: 'white', fontFamily: 'Poppins'}}>Division: </Typography>
                                        <Typography sx={{fontSize: 17, fontWeight: 600 ,color: 'white', fontFamily: 'Poppins', background: '', ml: 1}}> Driving </Typography>
                                    </Stack>
                                    <Stack flexDirection="row" alignItems="center">
                                        <Typography sx={{fontSize: 15, fontWeight: 500 ,color: 'white', fontFamily: 'Poppins'}}>Job Role: </Typography>
                                        <Typography sx={{fontSize: 17, fontWeight: 600 ,color: 'white', fontFamily: 'Poppins', background: '', ml: 1}}> Class 2 </Typography>
                                    </Stack>
                                   
                                </Stack>
                           
                                <Stack flexDirection="row" justifyContent="space-around" sx={{width: '100%'}}>
                                    <Stack flexDirection="row" alignItems="center">
                                        <Typography sx={{fontSize: 15, fontWeight: 500 ,color: 'white', fontFamily: 'Poppins'}}>Start Time: </Typography>
                                        <Typography sx={{fontSize: 16, fontWeight: 500 ,color: 'white', fontFamily: 'Poppins', background: deepPurple[900], px: 1, borderRadius: 1, ml: 1}}> 17:00 </Typography>
                                    </Stack>
                                    <Stack flexDirection="row" alignItems="center">
                                        <Typography sx={{fontSize: 15, fontWeight: 500 ,color: 'white', fontFamily: 'Poppins'}}>End Time: </Typography>
                                        <Typography sx={{fontSize: 16, fontWeight: 500 ,color: 'white', fontFamily: 'Poppins', background: deepPurple[900], px: 1, borderRadius: 1, ml: 1}}> 19:00 </Typography>
                                    </Stack>
                                    <Stack flexDirection="row" alignItems="center">
                                        <Typography sx={{fontSize: 15, fontWeight: 500 ,color: 'white', fontFamily: 'Poppins'}}>Total Hours: </Typography>
                                        <Typography sx={{fontSize: 16, fontWeight: 500 ,color: 'white', fontFamily: 'Poppins', background: deepPurple[900], px: 1, borderRadius: 1, ml: 1}}>2 hours </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack justifyContent="center" alignItems="center">
                               
                                <Typography sx={{fontSize: 15, fontWeight: 500 ,color: 'white', fontFamily: 'Poppins'}}>Client </Typography>
                                <Typography sx={{fontSize: 15, fontWeight: 800 ,color: 'white', fontFamily: 'Poppins', mb: 3}}>Peterhouse </Typography>
                                <Typography sx={{fontSize: 15, fontWeight: 500 ,color: 'white', fontFamily: 'Poppins'}}>Staff </Typography>
                                <Typography sx={{fontSize: 15, fontWeight: 800 ,color: 'white', fontFamily: 'Poppins'}}>James Daley</Typography>
                                
                            </Stack>
                        </Grid>

                    </Grid>
                    : 'no data'
                }
                    
                </Grid>
            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button 
                onClick={onClose}
                size="small" sx={{color: 'deepPurple[700]', fontWeight: 400, background: 'none','&:hover': {color: 'black'}}}><CloseIcon/></Button>
            <Button 
                onClick={handleClockIn}
                size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: deepPurple[700], '&:hover': {color: blueGrey[900]}}} >Clock-In <AlarmOnRoundedIcon fontSize="small" sx={{ml: 1}}/></Button>
        </DialogActions>
        </>
    )
}

export default ClockIn
