import { AVAILABILITY_LIST_REQUEST, AVAILABILITY_LIST_SUCCESS, AVAILABILITY_LIST_FAIL, VIEW_AVAILABILITY_FAIL, VIEW_AVAILABILITY_REQUEST, VIEW_AVAILABILITY_SUCCESS, AVAILABILITY_CREATE_REQUEST, AVAILABILITY_CREATE_SUCCESS, AVAILABILITY_CREATE_FAIL, AVAILABILITY_UPDATE_FAIL, AVAILABILITY_UPDATE_REQUEST, AVAILABILITY_UPDATE_SUCCESS } from '../Constants/availabilityConstants';
import api from '../../config/apiConfig';


const getAvailabilityList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: AVAILABILITY_LIST_REQUEST});
        const {data} = await api.get('/availabilities');
        // console.log('Availability data is:' + data[0]['Availability_Name'])
        dispatch({type: AVAILABILITY_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: AVAILABILITY_LIST_FAIL, payload: error})
    }
} 

const submitAvailability = (values) => async (dispatch) => {
    
     
    try {
        dispatch({type: AVAILABILITY_CREATE_REQUEST});
        
        const {data} = await api.post(`/availabilities/`, values);
        console.log('Availability data is:' + values)
        dispatch({type: AVAILABILITY_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: AVAILABILITY_CREATE_FAIL, payload: error})
    }
} 

const getAvailability = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_AVAILABILITY_REQUEST});
        
        const {data} = await api.get(`/availabilities/ ${id}`);
        console.log('Availability data is:' + data)
        dispatch({type: VIEW_AVAILABILITY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_AVAILABILITY_FAIL, payload: error})
    }
}

const editAvailability = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: AVAILABILITY_UPDATE_REQUEST});
        
        const {data} = await api.put(`/availabilities/`, values);
        console.log('Availability data is:' + data)
        dispatch({type: AVAILABILITY_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: AVAILABILITY_UPDATE_FAIL, payload: error})
    }
}



export {getAvailabilityList, submitAvailability, getAvailability, editAvailability }