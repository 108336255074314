import React, { useEffect, useState } from 'react';
import DataTable from '../../DataTable';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, cyan } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import ClientSiteDialogs from '../../../Dialogs/CRM/Management/ClientSiteDialogs/ClientSiteDialogs';

const SitesTbl = ({sites}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const switchToViewClientSite = (id) => {
        navigate('/clientsite/'+ id)
    } 
    const getStatusColor = (jr) => {
        return jr === 'Active' ? lightGreen[500] : jr === 'Inactive'?  pink[500] : blueGrey[500]
    }

    const rateCardQuickView = (id) => {

        showDialog(true, 'view', id)
      }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Location', headerName: 'Location', width: 200, renderCell: (params) => <Typography onClick={()=> {rateCardQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Location}`}</Typography>},
        { field: 'Client', headerName: 'Client', width: 150},
        { field: 'MainSiteContact', headerName: 'MainSiteContact', width: 150},
        { field: 'OtherSiteContact', headerName: 'OtherSiteContact', width: 150},
        { field: 'Add1', headerName: 'Address 1', width: 150},
        { field: 'Add2', headerName: 'Address 2', width: 150},
        { field: 'Postcode', headerName: 'Postcode'},
        { field: 'TownCity', headerName: 'TownCity'},
        { field: 'Status', headerName: 'Status', minWidth: 150 , type: 'singleSelect', valueOptions: (['Active', 'Inactive']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewClientSite(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ];



    return (
        <>
        { dialog.open  ?  <ClientSiteDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }

            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={sites}
                        loading={!sites.length}
                    />
                </div>
            </div>
        </>
       
    )
}

export default SitesTbl
