import { TRANSACTIONS_LIST_REQUEST, TRANSACTIONS_LIST_SUCCESS, TRANSACTIONS_LIST_FAIL, VIEW_TRANSACTION_FAIL, VIEW_TRANSACTION_REQUEST, VIEW_TRANSACTION_SUCCESS, TRANSACTION_CREATE_FAIL, TRANSACTION_UPDATE_REQUEST, TRANSACTION_UPDATE_FAIL, TRANSACTION_DELETE_REQUEST, TRANSACTION_DELETE_FAIL, TRANSACTION_CREATE_REQUEST, TRANSACTION_CREATE_SUCCESS, TRANSACTION_UPDATE_SUCCESS, TRANSACTION_DELETE_SUCCESS } from "../Constants/transactionConstants";

const transactionsListReducer = (state = {transactions: []}, action) => {

    switch (action.type)
    {
        case TRANSACTIONS_LIST_REQUEST:
            return {loading: true};
 
        case TRANSACTIONS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and transactions data is:' + action.payload)
            return {
                loading: false,
                transactions: action.payload
            }
        case TRANSACTIONS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const transactionCreateReducer = (state = {transactions: []}, action) => {

    switch (action.type) {

        case TRANSACTION_CREATE_REQUEST:
            return {
                loading: true
            }

        case TRANSACTION_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                transactions: [...state.transactions, {Applicant_Id: 2, Name: value}]
            };

        case TRANSACTION_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const transactionViewReducer = (state= {transaction: {}}, action) => {
    switch(action.type)
    {
        case VIEW_TRANSACTION_REQUEST:
            return {loading: true};
        case VIEW_TRANSACTION_SUCCESS:
            return {
                    loading: false,
                    transaction: action.payload
                }
        case VIEW_TRANSACTION_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const transactionUpdateReducer = (state = {transactions: {}}, action) => {

    switch (action.type) {

        case TRANSACTION_UPDATE_REQUEST:
            return {
                
            }
        case TRANSACTION_UPDATE_SUCCESS:

            return {

            }
        case TRANSACTION_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const transactionDeleteReducer = (state = {transactions: {}}, action) => {

    switch (action.type) {
        case TRANSACTION_DELETE_REQUEST:
            return {
                
            }
        case TRANSACTION_DELETE_SUCCESS:
            return {

            }
        case TRANSACTION_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {transactionsListReducer, transactionCreateReducer, transactionViewReducer, transactionUpdateReducer, transactionDeleteReducer }