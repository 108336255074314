import React from 'react';
import AddOpportunityDialog from './AddOpportunityDialog';
import ViewOpportunityDialog from './ViewOpportunityDialog';
import DeleteOpportunityDialog from './DeleteOpportunityDialog';



const OpportunityDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        { 
            type === "create"?

            <AddOpportunityDialog open={open} onClose={onClose}  handleSubmit={handleSubmit} fullWidth />
            
            : type === "view"?

            <ViewOpportunityDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />

            : type === "delete"?

            <DeleteOpportunityDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default OpportunityDialogs
