import { SITES_LIST_REQUEST, SITES_LIST_SUCCESS, SITES_LIST_FAIL, VIEW_SITE_FAIL, VIEW_SITE_REQUEST, VIEW_SITE_SUCCESS, SITE_CREATE_REQUEST, SITE_CREATE_SUCCESS, SITE_CREATE_FAIL, SITE_UPDATE_FAIL, SITE_UPDATE_REQUEST, SITE_UPDATE_SUCCESS } from '../Constants/siteConstants';
import api from '../../config/apiConfig';


const getSitesList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: SITES_LIST_REQUEST});
        const {data} = await api.get('/sites');
        console.log('Sites data is:' + data)
        dispatch({type: SITES_LIST_SUCCESS, payload: data})
        
         
    } catch (error) {
        dispatch({type: SITES_LIST_FAIL, payload: error})
    } 
} 

const submitSite = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: SITE_CREATE_REQUEST});
        
        const {data} = await api.post(`/sites/`, postValues);
        console.log('Site data is:' + data)
        dispatch({type: SITE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: SITE_CREATE_FAIL, payload: error})
    }
} 

const getSite = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_SITE_REQUEST});
        
        const {data} = await api.get(`/sites/ ${id}`);
        console.log('Site data is:' + data)
        dispatch({type: VIEW_SITE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_SITE_FAIL, payload: error})
    }
}

const editSite = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: SITE_UPDATE_REQUEST});
        
        const {data} = await api.put(`/sites/`, values);
        console.log('Site data is:' + data)
        dispatch({type: SITE_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: SITE_UPDATE_FAIL, payload: error})
    }
}



export {getSitesList, submitSite, getSite, editSite }