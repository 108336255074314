import React, { useEffect } from 'react'
import { Alert, Grid } from '@mui/material';
import { blue, green, blueGrey, pink, lightGreen, lightBlue } from '@mui/material/colors';
import { Box } from '@mui/system';

const ComplexAlert = ({alert: {type, message}, removeAlert}) => {


    useEffect(()=>{
        const timeout = setTimeout(()=> {
            removeAlert()
        }, 4000)

        return ()=> clearTimeout(timeout)
    }, [])


    return (
        <Grid container flexDirection="row" justifyContent="center" sx={{mb: 1}}>
            <Alert variant="outlined" severity={type} sx={{mt: 1,color: 'white', background: type === 'error'? pink[500]: type === 'success'? lightGreen[500] : lightBlue[300], width: '90%', fontWeight: 700, border: 'none'}} >{message}</Alert>
        </Grid>
    )
}

export default ComplexAlert
