export const VATRETURNFREQUENCY_LIST_REQUEST = 'VATRETURNFREQUENCY_LIST_REQUEST'
export const VATRETURNFREQUENCY_LIST_SUCCESS = 'VATRETURNFREQUENCY_LIST_SUCCESS'
export const VATRETURNFREQUENCY_LIST_FAIL = 'VATRETURNFREQUENCY_LIST_FAIL'


export const VATRETURNFREQUENCY_CREATE_REQUEST = 'VATRETURNFREQUENCY_CREATE_REQUEST'
export const VATRETURNFREQUENCY_CREATE_SUCCESS = 'VATRETURNFREQUENCY_CREATE_SUCCESS'
export const VATRETURNFREQUENCY_CREATE_FAIL = 'VATRETURNFREQUENCY_CREATE_FAIL'


export const VATRETURNFREQUENCY_UPDATE_REQUEST = 'VATRETURNFREQUENCY_UPDATE_REQUEST'
export const VATRETURNFREQUENCY_UPDATE_SUCCESS = 'VATRETURNFREQUENCY_UPDATE_SUCCESS'
export const VATRETURNFREQUENCY_UPDATE_FAIL = 'VATRETURNFREQUENCY_UPDATE_FAIL'

export const VATRETURNFREQUENCY_DELETE_REQUEST = 'VATRETURNFREQUENCY_DELETE_REQUEST'
export const VATRETURNFREQUENCY_DELETE_SUCCESS = 'VATRETURNFREQUENCY_DELETE_SUCCESS'
export const VATRETURNFREQUENCY_DELETE_FAIL = 'VATRETURNFREQUENCY_DELETE_FAIL'

export const VIEW_VATRETURNFREQUENCY_REQUEST = 'VIEW_VATRETURNFREQUENCY_REQUEST'
export const VIEW_VATRETURNFREQUENCY_SUCCESS = 'VIEW_VATRETURNFREQUENCY_SUCCESS'
export const VIEW_VATRETURNFREQUENCY_FAIL = 'VIEW_VATRETURNFREQUENCY_FAIL'