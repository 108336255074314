import React, { useEffect, useState } from 'react';
import DataTable from '../../DataTable';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import RateCardDialogs from '../../../Dialogs/CRM/Management/RateCardDialogs/RateCardDialogs';

const RateCardsTbl = ({ratecards}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const getJRColor = (jr) => {
        return jr === 'Class 1' ? blue[500] : jr === 'Class 2'?  pink[500] : jr === 'Construction'? orange[500] : jr === 'FLT'? lightGreen[500] : blueGrey[500]
    }
    const getStatusColor = (status) => {
        return status === 'New' ? blue[500] : status === 'Active'?  lightGreen[500] : status === 'Multi'? orange[500] : status === 'Leaver'? lightGreen[500] : blueGrey[500]
    }

    const switchToViewRateCard = (id) => {
        navigate('/ratecard/'+ id)
    } 

    const rateCardQuickView = (id) => {

        showDialog(true, 'view', id)
      }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Name', headerName: 'Name', width: 200, renderCell: (params) => <Typography onClick={()=> {rateCardQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Name}`}</Typography>},

        { field: 'StatusName', headerName: 'Status', type: 'singleSelect', valueOptions: (['New', 'Active', 'Multi', 'Leaver', 'Archived']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.StatusName), borderRadius: '30px', px: '10px'}}>{params.row.StatusName}</Typography>
        )},

        { field: 'Break', headerName: 'Break'},
        { field: 'StandardChargeRate_LTDUTR', headerName: 'StandardChargeRate_LTDUTR', width: 200,},
        { field: 'StandardPayRate_PAYE', headerName: 'StandardPayRate_PAYE', width: 200,},
        { field: 'StandardPayRate_PAYE2', headerName: 'StandardPayRate_PAYE2', width: 200,},
        { field: 'NightChargeRate', headerName: 'NightChargeRate', width: 200,},
        { field: 'NightPayRate_LTDUTR', headerName: 'NightPayRate_LTDUTR', width: 200,},
        { field: 'NightPayRate_PAYE', headerName: 'NightPayRate_PAYE', width: 200,},
        { field: 'NightPayRate_PAYE2', headerName: 'NightPayRate_PAYE2', width: 200,},
        { field: 'SaturdayChargeRate', headerName: 'SaturdayChargeRate', width: 200,},
        { field: 'SaturdayPayRate_LTDUTR', headerName: 'SaturdayPayRate_LTDUTR', width: 200,},
        { field: 'SaturdayPayRate_PAYE', headerName: 'SaturdayPayRate_PAYE', width: 200,},
        { field: 'SaturdayPayRate_PAYE2', headerName: 'SaturdayPayRate_PAYE2', width: 200,},
        { field: 'SundayChargeRate', headerName: 'SundayChargeRate', width: 200,},
        { field: 'SundayPayRate_LTDUTR', headerName: 'SundayPayRate_LTDUTR', width: 200,},
        { field: 'SundayPayRate_PAYE', headerName: 'SundayPayRate_PAYE', width: 200,},
        { field: 'SundayPayRate_PAYE2', headerName: 'SundayPayRate_PAYE2', width: 200,},
        { field: 'OvertimeChargeRate', headerName: 'OvertimeChargeRate', width: 200,},
        { field: 'OvertimePayRate_LTDUTR', headerName: 'OvertimePayRate_LTDUTR', width: 200,},
        { field: 'OvertimePayRate_PAYE', headerName: 'OvertimePayRate_PAYE', width: 200,},
        { field: 'OvertimePayRate_PAYE2', headerName: 'OvertimePayRate_PAYE2', width: 200,},
        { field: 'BHChargeRate', headerName: 'BHChargeRate', width: 200,},
        { field: 'BHPayRate_LTDUTR', headerName: 'BHPayRate_LTDUTR', width: 200,},
        { field: 'BHPayRate_PAYE', headerName: 'BHPayRate_PAYE', width: 200,},
        { field: 'BHPayRate_PAYE2', headerName: 'BHPayRate_PAYE2', width: 200,},
        { field: 'SaturdayNightChargeRate', headerName: 'SaturdayNightChargeRate', width: 200,},
        { field: 'SaturdayNightPayRate_LTDUTR', headerName: 'SaturdayNightPayRate_LTDUTR', width: 200,},
        { field: 'SaturdayNightPayRate_PAYE', headerName: 'SaturdayNightPayRate_PAYE', width: 200,},
        { field: 'SaturdayNightPayRate_PAYE2', headerName: 'SaturdayNightPayRate_PAYE2', width: 200,},
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewRateCard(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ];



    return (
        <>
        { dialog.open  ?  <RateCardDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }

            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={ratecards}
                        loading={!ratecards.length}
                    />
                </div>
            </div>
        </>
       
    )
}

export default RateCardsTbl
