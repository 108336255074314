import { ASSESSMENTTYPE_LIST_REQUEST, ASSESSMENTTYPE_LIST_SUCCESS, ASSESSMENTTYPE_LIST_FAIL} from "../Constants/assessmentTypesConstants";

const assessmentTypesListReducer = (state = {assessmentTypes: []}, action) => {
 
    switch (action.type)
    {
        case ASSESSMENTTYPE_LIST_REQUEST:
            return {loading: true};
 
        case ASSESSMENTTYPE_LIST_SUCCESS:
            console.log('Code is reaching the reducer and assessmentTypes data is:' + (action.payload)[0].Profile_Id)
            return {
                loading: false,
                assessmentTypes: action.payload
            }
        case ASSESSMENTTYPE_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}



export {assessmentTypesListReducer}