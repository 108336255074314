import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { applicantsListReducer, applicantViewReducer, applicantCreateReducer, applicantUpdateReducer, applicantDeleteReducer } from './Redux/Reducers/applicantsReducer';
import { activeLinkReducer, activeLinkReducerFirstLevel, activeLinkReducerSecondLevel, activeLinkReducerThirdLevel } from './Redux/Reducers/sitemapReducer';
import { assessmentsListReducer, assessmentCreateReducer, assessmentDeleteReducer, assessmentUpdateReducer, assessmentViewReducer } from './Redux/Reducers/assessmentsReducer';
import tasksReducer, { tasksListReducer, taskViewReducer, taskDeleteReducer } from './Redux/Reducers/tasksReducer';
import { divisionsListReducer, divisionCreateReducer, divisionViewReducer, divisionUpdateReducer, divisionDeleteReducer } from './Redux/Reducers/divisionsReducer';
import { profileViewReducer, profileCreateReducer, profileUpdateReducer, profileDeleteReducer, profilesListReducer } from './Redux/Reducers/profilesReducer';
import { applicantProfilesListReducer, applicantProfileViewReducer, applicantProfileCreateReducer, applicantProfileUpdateReducer, applicantProfileDeleteReducer } from './Redux/Reducers/applicantsProfilesReducer';
import { applicantProspectsListReducer } from './Redux/Reducers/applicantProspectsReducer';
import { applicantStatusesListReducer } from './Redux/Reducers/applicantStatusesReducer';
import { assessmentResultsListReducer } from './Redux/Reducers/assessmentResultsReducer';
import { assessmentTypesListReducer } from './Redux/Reducers/assessmentTypesReducer';
import { jobRolesListReducer } from './Redux/Reducers/jobRolesReducer';
import { assessmentStatusesListReducer } from './Redux/Reducers/assessmentStatusesReducer';
import { staffListReducer } from './Redux/Reducers/staffReducer';
import { workersListReducer, workerViewReducer, workerCreateReducer, workerUpdateReducer, workerDeleteReducer } from './Redux/Reducers/workersReducer';
import { taskStatusesListReducer } from './Redux/Reducers/taskStatusReducer';
import { clientsListReducer } from './Redux/Reducers/clientsReducer';
import { taskTypesListReducer } from './Redux/Reducers/taskTypesReducer';
import { shiftPreferencesListReducer } from './Redux/Reducers/shiftPreferencesReducer';
import { payrollSetupsListReducer } from './Redux/Reducers/payrollSetupReducer';
import { payrollDocTypesListReducer } from './Redux/Reducers/payrollDocTypesReducer';
import { workerStatusesListReducer } from './Redux/Reducers/workerStatusReducer';
import { linkedUmbrellasListReducer } from './Redux/Reducers/linkedUmbrellaReducer';
import { shiftStatusesListReducer } from './Redux/Reducers/shiftStatusReducer';
import { branchesListReducer } from './Redux/Reducers/branchReducer';
import { shiftsListReducer, shiftViewReducer } from './Redux/Reducers/shiftReducer';
import { rateCardsListReducer } from './Redux/Reducers/rateCardReducer';
import { contactsListReducer } from './Redux/Reducers/contactsReducer';
import { availabilityStatusesListReducer } from './Redux/Reducers/availabilityStatusesReducer';
import { availabilitiesListReducer, availabilityViewReducer } from './Redux/Reducers/availabiilityReducer';
import { timesheetsListReducer, timesheetViewReducer } from './Redux/Reducers/timesheetReducer';
import { documentsListReducer } from './Redux/Reducers/documentReducer';
import { paymentsListReducer } from './Redux/Reducers/paymentReducer';
import { checkInCreateReducer, checkInsListReducer, checkInViewReducer } from './Redux/Reducers/checkInReducer';
import { influencesListReducer } from './Redux/Reducers/influenceReducer';
import { departmentsListReducer } from './Redux/Reducers/departmentReducer';
import { contactTypesListReducer } from './Redux/Reducers/contactTypeReducer';
import { rateStatusesListReducer } from './Redux/Reducers/rateStatusReducer';
import { visitsListReducer } from './Redux/Reducers/visitReducer';
import { prospectsListReducer } from './Redux/Reducers/prospectReducer';
import { payTypesListReducer } from './Redux/Reducers/payTypesReducer';
import { ratesListReducer } from './Redux/Reducers/rateReducer';
import { workerShiftStatusesListReducer } from './Redux/Reducers/workerShiftStatusReducer';
import { checkinStatusesListReducer } from './Redux/Reducers/checkinStatusReducer';
import { paymentStatusesListReducer } from './Redux/Reducers/paymentStatusReducer';
import { documentTypesListReducer } from './Redux/Reducers/documentTypeReducer';
import { requestsListReducer } from './Redux/Reducers/requestReducer';
import { accsAndIncidentsListReducer } from './Redux/Reducers/accsAndIncidentReducer';
import { opportunitiesListReducer } from './Redux/Reducers/opportunityReducer';
import { creditChecksListReducer } from './Redux/Reducers/creditCheckReducer';
import { sitesListReducer } from './Redux/Reducers/siteReducer';
import { requestTypesListReducer } from './Redux/Reducers/requestTypeReducer';
import { requestStatusesListReducer } from './Redux/Reducers/requestStatusReducer';
import { incidentTypesListReducer } from './Redux/Reducers/incidentTypeReducer';
import { incidentStatusesListReducer } from './Redux/Reducers/incidentStatusReducer';
import { opportunityTypesListReducer } from './Redux/Reducers/opportunityTypeReducer';
import { opportunityStatusesListReducer } from './Redux/Reducers/opportunityStatusReducer';
import { creditCheckStatusesListReducer } from './Redux/Reducers/creditCheckStatusReducer';
import { setupTypesListReducer } from './Redux/Reducers/setupTypeReducer';
import { industriesListReducer } from './Redux/Reducers/industryReducer';
import { clientStatusesListReducer } from './Redux/Reducers/clientStatusReducer';
import { visitTypesListReducer } from './Redux/Reducers/visitTypeReducer';
import { holidaysListReducer } from './Redux/Reducers/holidayReducer';
import { holidayStatusesListReducer } from './Redux/Reducers/holidayStatusReducer';
import { holidayGroupsListReducer } from './Redux/Reducers/holidayGroupReducer';
import { invoicesListReducer } from './Redux/Reducers/invoiceReducer';
import { invoiceTypesListReducer } from './Redux/Reducers/invoiceTypeReducer';
import { invoiceStatusesListReducer } from './Redux/Reducers/invoiceStatusReducer';
import { womsListReducer } from './Redux/Reducers/womReducer';
import { womStatusesListReducer } from './Redux/Reducers/womStatusReducer';
import { paymentTermsListReducer } from './Redux/Reducers/paymentTermReducer';
import { monthsListReducer } from './Redux/Reducers/monthReducer';
import { siteStatusesListReducer } from './Redux/Reducers/siteStatusReducer';
import { bookingStatusesListReducer } from './Redux/Reducers/bookingStatusReducer';
import { recurringsListReducer } from './Redux/Reducers/recurringReducer';
import { bookingsListReducer } from './Redux/Reducers/bookingReducer';
import { workingWeekStatusesListReducer } from './Redux/Reducers/workingWeekStatusReducer';
import { workingWeeksListReducer } from './Redux/Reducers/workingWeekReducer';
import { incomeTypesListReducer } from './Redux/Reducers/incomeTypeReducer';
import { expenseTypesListReducer } from './Redux/Reducers/expenseTypeReducer';
import { assetTypesListReducer } from './Redux/Reducers/assetTypeReducer';
import { liabilityTypesListReducer } from './Redux/Reducers/liabilityTypeReducer';
import { balanceSheetTypesListReducer } from './Redux/Reducers/balanceSheetTypeReducer';
import { transactionTypesListReducer } from './Redux/Reducers/transactionTypeReducer';
import { vatStatusesListReducer } from './Redux/Reducers/vatStatusReducer';
import { payerPayeeTypesListReducer } from './Redux/Reducers/payerPayeeTypeReducer';
import { profitLossStatusesListReducer } from './Redux/Reducers/profitLossStatusReducer';
import { profitLossListReducer } from './Redux/Reducers/profitLossReducer';
import { coasListReducer } from './Redux/Reducers/coaReducer';
import { vatRatesListReducer } from './Redux/Reducers/vatRateReducer';
import { reconcilesListReducer } from './Redux/Reducers/reconcileReducer';
import { vatReturnFrequencyListReducer } from './Redux/Reducers/vatReturnFrequencyReducer';
import { vatReturnsListReducer } from './Redux/Reducers/vatReturnReducer';
import { payerPayeesListReducer } from './Redux/Reducers/payerPayeeReducer';
import { transactionsListReducer } from './Redux/Reducers/transactionReducer';
import { balanceSheetsListReducer } from './Redux/Reducers/balanceSheetReducer';

 

// const initialState = {
//     applicants: [],
//     divisions: [], 
//     divisions: [{Id: 1, Name: 'Nema Ioeer'}, {Id: 2, Name: 'Hiesmen Yua'}], 
//     tasks: [],
//     active_link: {}

// } 
const reducer = combineReducers({
    //ITEMS
    incomeTypes_List: incomeTypesListReducer, 
    expenseTypes_List: expenseTypesListReducer, 
    assetTypes_List: assetTypesListReducer, 
    liabilityTypes_List: liabilityTypesListReducer, 
    balanceSheetTypes_List: balanceSheetTypesListReducer, 
    transactionTypes_List: transactionTypesListReducer, 
    vatStatuses_List: vatStatusesListReducer, 
    payerPayeeTypes_List: payerPayeeTypesListReducer, 
    profitLossStatuses_List: profitLossStatusesListReducer, 
    profitLoss_List: profitLossListReducer, 
    coas_List: coasListReducer, 
    vatRates_List: vatRatesListReducer, 
    reconciles_List: reconcilesListReducer, 
    vatReturnFrequency_List: vatReturnFrequencyListReducer, 
    vatReturns_List: vatReturnsListReducer, 
    payerPayees_List: payerPayeesListReducer, 
    transactions_List: transactionsListReducer, 
    balanceSheets_List: balanceSheetsListReducer, 
    workingWeeks_List: workingWeeksListReducer, 
    workingWeekStatuses_List: workingWeekStatusesListReducer, 
    bookings_List: bookingsListReducer, 
    recurrings_List: recurringsListReducer, 
    bookingStatuses_List: bookingStatusesListReducer, 
    holidays_List: holidaysListReducer, 
    holidayStatuses_List: holidayStatusesListReducer, 
    holidayGroups_List: holidayGroupsListReducer, 
    invoices_List: invoicesListReducer, 
    invoiceTypes_List: invoiceTypesListReducer, 
    invoiceStatuses_List: invoiceStatusesListReducer, 
    woms_List: womsListReducer, 
    womStatuses_List: womStatusesListReducer, 
    paymentTerms_List: paymentTermsListReducer, 
    months_List: monthsListReducer, 
    siteStatuses_List: siteStatusesListReducer, 
    requests_List: requestsListReducer, 
    accsandIncidents_List: accsAndIncidentsListReducer, 
    creditChecks_List: creditChecksListReducer, 
    sites_List: sitesListReducer, 
    requestTypes_List: requestTypesListReducer, 
    requestStatuses_List: requestStatusesListReducer, 
    incidentTypes_List: incidentTypesListReducer, 
    incidentStatuses_List: incidentStatusesListReducer, 
    opportunities_List: opportunitiesListReducer, 
    opportunityTypes_List: opportunityTypesListReducer, 
    opportunityStatuses_List: opportunityStatusesListReducer, 
    creditCheckStatuses_List: creditCheckStatusesListReducer, 
    setupTypes_List: setupTypesListReducer, 
    industries_List: industriesListReducer, 
    clientStatuses_List: clientStatusesListReducer, 
    //DIVISIONS
    divisions_List: divisionsListReducer, 
    division_View: divisionViewReducer,
    divisions_Create: divisionCreateReducer,
    divisions_Update: divisionUpdateReducer,
    divisions_Delete: divisionDeleteReducer,
    //OTHER
    applicantProspects_List: applicantProspectsListReducer,
    applicantStatuses_List: applicantStatusesListReducer,
    assessmentStatuses_List: assessmentStatusesListReducer,
    assessmentResults_List: assessmentResultsListReducer,
    assessmentTypes_List: assessmentTypesListReducer,
    jobRoles_List: jobRolesListReducer,
    //INFLUENCES
    influences_List: influencesListReducer, 
    //DEPARTMENTS
    departments_List: departmentsListReducer, 
    //CONTACT TYPES
    contactTypes_List: contactTypesListReducer, 
    //STAFF
    staff_List: staffListReducer, 
    //VISITS
    visits_List: visitsListReducer, 
    visitTypes_List: visitTypesListReducer, 
    //PROSPECTS
    prospects_List: prospectsListReducer, 
    //CLIENTS
    clients_List: clientsListReducer, 
    //CHECK-IN STATUS
    checkinStatuses_List: checkinStatusesListReducer, 
    //CHECK-INS
    checkIns_List: checkInsListReducer, 
    checkIn_View: checkInViewReducer,
    checkIns_Create: checkInCreateReducer,

    //TASKS
    taskStatuses_List: taskStatusesListReducer, 
    //TASK TYPES
    taskTypes_List: taskTypesListReducer, 
    //RATE STATUSES
    rateStatuses_List: rateStatusesListReducer, 
    //SHIFT PREFERENCES
    shiftPreferences_List: shiftPreferencesListReducer, 
    //PAYROLL SETUPS
    payrollSetups_List: payrollSetupsListReducer, 
    //PAYROLL DOC TYPES
    payrollDocTypes_List: payrollDocTypesListReducer, 
    //WORKER STATUSES
    workerStatuses_List: workerStatusesListReducer, 
    //LINKED UMBRELLAS
    linkedUmbrellas_List: linkedUmbrellasListReducer, 
    //SHIFTS
    shifts_List: shiftsListReducer, 
    shift_View: shiftViewReducer, 
    //SHIFT STATUSES
    shiftStatuses_List: shiftStatusesListReducer, 
    //PAYMENT STATUSES
    paymentStatuses_List: paymentStatusesListReducer, 
    //BRANCHES
    branches_List: branchesListReducer, 
    //RATE CARDS
    rateCards_List: rateCardsListReducer, 
    //AVAILABILITY STATUSES
    availabilityStatuses_List: availabilityStatusesListReducer, 
    //AVAILABILITY
    availability_List: availabilitiesListReducer, 
    availability_View: availabilityViewReducer,
    //CONTACTS
    contacts_List: contactsListReducer, 
    //RATES
    rates_List: ratesListReducer, 
    //PAYMENT TYPES
    paymentTypes_List: payTypesListReducer, 
    //PAYMENTS
    payments_List: paymentsListReducer, 
    //DOCUMENTS
    documents_List: documentsListReducer, 
    //DOCUMENT TYPES
    documentTypes_List: documentTypesListReducer,
    //TIMESHEETS
    timesheets_List: timesheetsListReducer, 
    timesheet_View: timesheetViewReducer, 
    //ASSESSMENTS
    assessments_List: assessmentsListReducer,
    assessment_View: assessmentViewReducer,
    assessments_Create: assessmentCreateReducer,
    assessments_Update: assessmentUpdateReducer,
    assessments_Delete: assessmentDeleteReducer,
    //APPLICANTS
    applicants_List: applicantsListReducer,
    applicant_View: applicantViewReducer,
    //applicant_Create: applicantCreateReducer,
    applicant_Update: applicantUpdateReducer,
    applicant_Delete: applicantDeleteReducer,
    //WORKER SHIFT STATUSES
    workerShiftStatuses_List: workerShiftStatusesListReducer,
    //WORKERS
    workers_List: workersListReducer,
    worker_View: workerViewReducer,
    worker_Create: workerCreateReducer,
    worker_Update: workerUpdateReducer,
    worker_Delete: workerDeleteReducer,
    //PROFILES
    profiles_List: profilesListReducer,
    profile_View: profileViewReducer,
    profile_Create: profileCreateReducer,
    profile_Update: profileUpdateReducer,
    profile_Delete: profileDeleteReducer,
    //APPLICANT PROFILES
    applicantProfiles_List: applicantProfilesListReducer,
    applicantProfile_View: applicantProfileViewReducer,
    applicantProfile_Create: applicantProfileCreateReducer,
    applicantProfile_Update: applicantProfileUpdateReducer,
    applicantProfile_Delete: applicantProfileDeleteReducer,
    //TASKS
    tasks_List: tasksListReducer,
    task_View: taskViewReducer,
    //NAVIGATION LINKS
    active_link_state: activeLinkReducer,
    firstLevelNavActiveState: activeLinkReducerFirstLevel,
    secondLevelNavActiveState: activeLinkReducerSecondLevel,
    thirdLevelNavActiveState: activeLinkReducerThirdLevel,
})

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
// const store = createStore(reducer, initialState, compose(applyMiddleware(thunk)));

export default store;