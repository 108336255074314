import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, Breadcrumbs, TextField} from '@mui/material';
import { blueGrey, pink, lightBlue, lightGreen } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useDispatch, useSelector } from 'react-redux';
import {  submitBooking } from '../../../../../Redux/Actions/BookingActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { Link as BreadcrumbLink } from '@mui/material';
import { getDepartmentsList } from '../../../../../Redux/Actions/DepartmentActions';
import { getBranchesList } from '../../../../../Redux/Actions/BranchActions';
import { getRateCardsList } from '../../../../../Redux/Actions/RateCardActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SummaryBox from '../../../../Utilities/SummaryBox';
import SideCrumbs from '../../../../Utilities/SideCrumbs';
import useDate from '../../../../../Hooks/useDate';
import { getWOMsList, submitWOM } from '../../../../../Redux/Actions/WOMActions';


const AddWOMDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET DEPARTMENTS
    const {woms, loading} = useSelector(state => state.woms_List);


    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getWOMsList());
       
    }, [])
    

    const [values, setValues] = useState({
            workers: '',
            months: '',
            reward: '',
            womstatuses: '',
        });

    const [submitValues, setSubmitValues] = useState({
            workers: '',
            months: '',
            reward: '',
            womstatuses: '',
        });   
        
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitWOM(values, submitValues))
        handleSubmit('success',"Successfully saved WOM information");
        
    }
    
    const animatedComponents = makeAnimated();
    
    const [inputValue, setInputValue] = useState('');
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
   
    
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }


    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: lightBlue[700]}}  >
                
                <EngineeringIcon fontSize="large" sx={{color: lightBlue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: lightBlue[200],fontWeight: 700}}> Add Worker Of The Month </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    
                    <SideCrumbs/>
                    <Grid item lg={10}>
                    {/* <p>workers {submitValues.workers}</p>
                    <p>month {submitValues.months}</p>
                    <p>reward {submitValues.reward}</p>
                    <p>womstatuses {submitValues.womstatuses}</p> */}
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">WOM ID</Form.Label>
                                <Form.Control disabled type="email" value="WOM-1" placeholder="E.g 123qst1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Worker of The Month</Form.Label>
                                    <AsyncSelect
                                        value={values.workers}
                                        name="workers"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'workers')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for worker ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                        <br/>
                        <Typography id="specifics" variant="body2" sx={{display: 'inline',color: 'white',background: lightBlue[500], borderRadius: '5px', px: 2, py: '5px'}}>Some Specifics</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Month</Form.Label>
                                    <AsyncSelect
                                        value={values.months}
                                        name="months"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'months')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for month ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Reward Value</Form.Label>
                                <Form.Control name="reward" type="reward" value={values.reward} onChange={handleChange} placeholder="1,200 Pounds" className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography id="staff" variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Consultant</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">WOM Status</Form.Label>
                                   <AsyncSelect
                                        value={values.womstatuses}
                                        name="womstatuses"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'womstatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        
                        </Row>

                        </Form>
                    </Grid>

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: lightBlue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: lightBlue[800], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save WOM</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddWOMDialog
