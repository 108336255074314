import { CREDITCHECKS_LIST_REQUEST, CREDITCHECKS_LIST_SUCCESS, CREDITCHECKS_LIST_FAIL, VIEW_CREDITCHECK_FAIL, VIEW_CREDITCHECK_REQUEST, VIEW_CREDITCHECK_SUCCESS, CREDITCHECK_CREATE_REQUEST, CREDITCHECK_CREATE_SUCCESS, CREDITCHECK_CREATE_FAIL, CREDITCHECK_UPDATE_FAIL, CREDITCHECK_UPDATE_REQUEST, CREDITCHECK_UPDATE_SUCCESS } from '../Constants/creditCheckConstants';
import api from '../../config/apiConfig';


const getCreditChecksList = () => async(dispatch) => {
    
  
    try {
        dispatch({type: CREDITCHECKS_LIST_REQUEST});
        const {data} = await api.get('/creditchecks');
        console.log('CreditChecks data is:' + data)
        dispatch({type: CREDITCHECKS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: CREDITCHECKS_LIST_FAIL, payload: error})
    } 
} 

const submitCreditCheck = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: CREDITCHECK_CREATE_REQUEST});
        
        const {data} = await api.post(`/creditchecks/`, postValues);
        console.log('CreditCheck data is:' + data)
        dispatch({type: CREDITCHECK_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: CREDITCHECK_CREATE_FAIL, payload: error})
    }
} 

const getCreditCheck = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_CREDITCHECK_REQUEST});
        
        const {data} = await api.get(`/creditchecks/ ${id}`);
        console.log('CreditCheck data is:' + data)
        dispatch({type: VIEW_CREDITCHECK_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_CREDITCHECK_FAIL, payload: error})
    }
}

const editCreditCheck = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: CREDITCHECK_UPDATE_REQUEST});
        
        const {data} = await api.put(`/creditchecks/`, values);
        console.log('CreditCheck data is:' + data)
        dispatch({type: CREDITCHECK_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: CREDITCHECK_UPDATE_FAIL, payload: error})
    }
}



export {getCreditChecksList, submitCreditCheck, getCreditCheck, editCreditCheck }