import React from 'react';
import { Stack, Typography } from '@mui/material';
import { blue, blueGrey, lightBlue } from '@mui/material/colors';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CircleIcon from '@mui/icons-material/Circle';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

const FormHeading = ({title, badge, icon}) => {
    return (
        <>
            <Stack direction="row" alignItems="center" >
                <Typography variant="body1" sx={{fontFamily: 'Poppins', fontWeight: 400, color: lightBlue[600]}}>{title}</Typography>
                { badge?
                <Typography variant="body2" sx={{fontSize: '0.7rem', fontWeight: 500, px: 1, py: 0,ml: 1,color: 'white', background: blueGrey[800], borderRadius: '3px'}}>{badge}</Typography>
                : icon == 'assignment'?
                <AssignmentIndIcon sx={{color: blueGrey[800], my: 2, ml: 1, fontSize: 17}}/>
                : icon == 'circle'?
                <CircleIcon fontSize="small" sx={{color: blueGrey[800], my: 2, ml: 1, fontSize: 13}}/>
                : icon == 'note'?
                <NoteAltIcon sx={{color: blueGrey[800], my: 2, ml: 1, fontSize: 17}}/>
                : ''
                }
            </Stack>
        </>
    )
}

export default FormHeading
