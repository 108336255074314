import React from 'react';
import { Breadcrumbs, Link as BreadcrumbLink } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { blueGrey, blue } from '@mui/material/colors';
import { useNavigate } from 'react-router';


const Crumbs = ({root, middle, end, main}) => {

    const navigate = useNavigate();
    
    return (
        <>
            <Breadcrumbs sx={{mt: 1, mb: 2}}>
                <BreadcrumbLink><HomeIcon fontSize="small" sx={{color: blueGrey[300]}}/></BreadcrumbLink>
                <BreadcrumbLink underline="none" sx={{color: blueGrey[300]}} fontSize="small" href="#">{root}</BreadcrumbLink>
                <BreadcrumbLink underline="none" sx={{color: blueGrey[300]}} fontSize="small" onClick={()=> main? '' : navigate(`/${middle}`)} >{middle}</BreadcrumbLink>
                <BreadcrumbLink underline="none" sx={{color: blue[400]}} fontSize="small">{end}</BreadcrumbLink>
            </Breadcrumbs>
        </>
    )
}

export default Crumbs
