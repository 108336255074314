import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo, purple, cyan } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import VisitDialogs from '../../../Dialogs/CRM/Management/VisitDialogs/VisitDialogs';

const VisitsTbl = ({visits}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    
    const getTypeColor = (type) => {
        return type === 'Consultant' ? lightGreen[500] : type === 'Manager'?  pink[500] : type === 'Director'? orange[500]: blueGrey[500]
    }
    

    const switchToViewVisit = (id) => {
        navigate('/visit/'+ id)
    } 

    const visitQuickView = (id) => {

        showDialog(true, 'view', id)
    }


 
    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Name', headerName: 'Name', width: 250, renderCell: (params) => <Typography onClick={()=> {visitQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Name}`}</Typography>},
        { field: 'Type', headerName: 'Type', minWidth: 180 , type: 'singleSelect', valueOptions: (['Consultant', 'Manager', 'Director']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getTypeColor(params.row.Type), borderRadius: '30px', px: '10px'}}>{params.row.Type}</Typography>
        )},
        { field: 'Date', headerName: 'Date'},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewVisit(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];

    return (
        
        <>
            { dialog.open  ?  <VisitDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={visits}
                        loading={!visits.length}
                    />
                </div>
            </div>
        </>
    )
}

export default VisitsTbl
