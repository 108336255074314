export const REQUESTSTATUSES_LIST_REQUEST = 'REQUESTSTATUSES_LIST_REQUEST';
export const REQUESTSTATUSES_LIST_SUCCESS = 'REQUESTSTATUSES_LIST_SUCCESS';
export const REQUESTSTATUSES_LIST_FAIL = 'REQUESTSTATUSES_LIST_FAIL'; 

export const REQUESTSTATUS_CREATE_REQUEST = 'REQUESTSTATUS_CREATE_REQUEST'
export const REQUESTSTATUS_CREATE_SUCCESS = 'REQUESTSTATUS_CREATE_SUCCESS'
export const REQUESTSTATUS_CREATE_FAIL = 'REQUESTSTATUS_CREATE_FAIL'


export const REQUESTSTATUS_UPDATE_REQUEST = 'REQUESTSTATUS_UPDATE_REQUEST'
export const REQUESTSTATUS_UPDATE_SUCCESS = 'REQUESTSTATUS_UPDATE_SUCCESS'
export const REQUESTSTATUS_UPDATE_FAIL = 'REQUESTSTATUS_UPDATE_FAIL'

export const REQUESTSTATUS_DELETE_REQUEST = 'REQUESTSTATUS_DELETE_REQUEST'
export const REQUESTSTATUS_DELETE_SUCCESS = 'REQUESTSTATUS_DELETE_SUCCESS'
export const REQUESTSTATUS_DELETE_FAIL = 'REQUESTSTATUS_DELETE_FAIL'

export const VIEW_REQUESTSTATUS_REQUEST = 'VIEW_REQUESTSTATUS_REQUEST'
export const VIEW_REQUESTSTATUS_SUCCESS = 'VIEW_REQUESTSTATUS_SUCCESS'
export const VIEW_REQUESTSTATUS_FAIL = 'VIEW_REQUESTSTATUS_FAIL'