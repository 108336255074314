import React, {useState, useEffect} from 'react';
import { Link as BreadcrumbLink, CircularProgress } from '@mui/material';
import { IconButton, Grid, Divider, Stack} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../Hooks/useDialog';
import { blueGrey, blue, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import { getWorkers } from '../../../Redux/Actions/WorkerActions';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import Crumbs from '../../../Components/Utilities/Crumbs';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import WorkersTbl from '../../../Components/Table/CRM/Staff/WorkersTbl';
import WorkerDialogs from '../../../Components/Dialogs/CRM/Staff/WorkerDialogs/WorkerDialogs';



 

const Workers = () => { 

    
    //FETCH DATA FROM SERVER
    
    const dispatch = useDispatch();
    const {workers, loading: loading_workers} = useSelector(state => state.workers_List);
    const [list, setList] = useState([])
    
    useEffect(()=> {
        dispatch(getWorkers());
    }, [])

    useEffect(() => {
       if(workers)
       {
           setList([...workers])
       }
    }, [workers])
     
    

    
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //TABLE DIALOG FUNCTIONALITY
    
        const handleAddClick = () => {
            console.log('THIS IS WORKING')
            showDialog(true, 'create', '');
        }

    //----------------------------------------------------------------------------------------------------------------------------------
        
    //TODO: CRUD FUNCTIONS
        
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)
    
    //----------------------------------------------------------------------------------------------------------------------------------
      
    return (
        <>
         
        <ScreenHeader title="Workers" subtitle="Manage workers information from here" count={loading_workers? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>
        
        <Grid container justifyContent="space-between" sx={{mb: 2}}>
            <Crumbs root="CRM" middle="Staff" end="Workers" main/>
            <Stack direction="row" justifyContent="center" alignItems="center">
                <AddButtonLabel item="Worker"/>
                <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                    <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                </IconButton>
            </Stack>
        </Grid>

        {dialog.open  ?  <WorkerDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>  : ''  }

        <Divider sx={{color: lightBlue[800]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        { loading_workers ?  <HorizontalLoading/> : '' }
        <HorizontalSummaryBox primary="Workers Brief" secondary="Overall Workers count" label1="All Workers" label2="Second Label" label3="Third Label" count={[2,11,6]}/>
        
           
        <AboveTableHeader title="Workers List"/>
        <Grid container>
            <WorkersTbl workers={list} />
        </Grid>
            


     
    </>
    )
}

export default Workers
