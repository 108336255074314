import React from 'react'
import { Grid, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';

const ViewTitle = ({first, second, third, firstIcon, secondIcon}) => {
    return (
        <>
            <Grid container sx={{mt: 1,display: 'flex',alignItems: 'center',background:  blue[700], padding: '5px 20px', borderRadius: '10px', borderLeft: '8px solid #263238', border: '2px solid whitesmoke', boxShadow: ''}}>
                <Grid item>
                    <Typography component="h5" sx={{fontSize: 15, fontFamily: 'Poppins',color: 'white',fontWeight: 900, lineHeight: '40px'}}>
                    {first}
                    {second? 
                    <>
                        <span style={{color: 'lightgray', marginLeft: '10px'}}> | </span>
                        {firstIcon}
                        {`  `}
                        {second}
                        <span style={{color: 'lightgray', marginLeft: '10px'}}> |  </span>
                        {secondIcon}
                        {third}
                    </>
                    : ''
                }
                </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default ViewTitle
