import React from 'react';
import AddAccAndIncidentDialog from './AddAccAndIncidentDialog';
import ViewAccAndIncidentDialog from './ViewAccAndIncidentDialog';
import DeleteAccAndIncidentDialog from './DeleteAccAndIncidentDialog';



const AccAndIncidentDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        { 
            type === "create"?

            <AddAccAndIncidentDialog open={open} onClose={onClose}  handleSubmit={handleSubmit} fullWidth />
            
            : type === "view"?

            <ViewAccAndIncidentDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />

            : type === "delete"?

            <DeleteAccAndIncidentDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default AccAndIncidentDialogs
