import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen, red, yellow, indigo, purple, cyan } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import DataTable from '../../DataTable';
import AccAndIncidentDialogs from '../../../Dialogs/CRM/Operations/AccsAndIncidents/AccAndIncidentDialogs';
import WorkingWeekDialogs from '../../../Dialogs/CRM/System/WorkingWeekDialogs/WorkingWeekDialogs';

const WorkingWeekTbl = ({weeks}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    
    const getStatusColor = (jr) => {
        return jr === 'Pending' ? lightGreen[500] : jr === 'Upcoming'?  pink[500] : jr === 'Current'? orange[500] : jr === 'Complete'? lightBlue[500] : blueGrey[500]
    }
    

    const switchToViewIncident = (id) => {
        navigate('/incident/'+ id)
    } 

    const workingWeekQuickView = (id) => {

        showDialog(true, 'view', id)
    }


 
    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Week', headerName: 'Week', width: 150,  renderCell: (params) => <Typography onClick={()=> {workingWeekQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.CommenceDate} - ${params.row.EndDate} `}</Typography>},
        { field: 'CommenceDate', headerName: 'Commence Date'},
        { field: 'EndDate', headerName: 'End Date'},
        { field: 'WeekNumber', headerName: 'Week Number', width: 150},
        { field: 'Status', headerName: 'Status', minWidth: 180 , type: 'singleSelect', valueOptions: (['Pending', 'Upcoming', 'Current', 'Complete']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewIncident(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
       
        
    ];

    return (
        
        <>
            { dialog.open  ?  <WorkingWeekDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={weeks}
                        loading={!weeks.length}
                    />
                </div>
            </div>
        </>
    )
}

export default WorkingWeekTbl
