import React, { useEffect, useState } from 'react';
import DataTable from '../../DataTable';
import { Button, Typography } from '@mui/material';
import { blue, lightBlue, pink, orange, blueGrey, lightGreen } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import useDialog from '../../../../Hooks/useDialog';
import ProfileDialogs from '../../../Dialogs/CRM/Staff/ProfileDialogs/ProfileDialogs';

const ProfilesTbl = ({profiles}) => {

    const navigate = useNavigate();
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog();

    const getJRColor = (jr) => {
        return jr === 'Class 1' ? blue[500] : jr === 'Class 2'?  pink[500] : jr === 'Construction'? orange[500] : jr === 'FLT'? lightGreen[500] : blueGrey[500]
    }
    const getStatusColor = (status) => {
        return status === 'New' ? blue[500] : status === 'Active'?  lightGreen[500] : status === 'Multi'? orange[500] : status === 'Leaver'? lightGreen[500] : blueGrey[500]
    }

    const switchToViewProfile = (id) => {
        navigate('/profile/'+ id)
    } 

    const profileQuickView = (id) => {

        showDialog(true, 'view', id)
      }


    const columns = [
        { field: 'id', headerName: 'Id', type: 'number'},
        { field: 'Person', headerName: 'Person', width: 250, renderCell: (params) => <Typography onClick={()=> {profileQuickView(params.row.id)}} sx={{color: lightBlue[700], cursor: 'pointer', fontSize: '.8rem',}}>{`${params.row.Person}`}</Typography>},
        { field: 'Type', headerName: 'Type'},
        { field: 'Status', headerName: 'Status', type: 'singleSelect', valueOptions: (['New', 'Active', 'Multi', 'Leaver', 'Archived']), renderCell: (params)=> (
            <Typography sx={{color: 'white', fontSize: '.7rem', backgroundColor: getStatusColor(params.row.Status), borderRadius: '30px', px: '10px'}}>{params.row.Status}</Typography>
        )},
        
        { field: 'View', headerName: 'View', renderCell: (params) => <Button size="small" onClick={()=> {switchToViewProfile(params.row.id)}} sx={{color: 'white', fontSize: '.5rem', backgroundColor: blue[700], '&:hover': {color: blue[500], background: 'white'}}}>Full View</Button>}
        
    ];



    return (
        <>
        { dialog.open  ?  <ProfileDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }

            <div style={{ display: 'flex', height: '400px', width: '100%', background: 'white' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable
                        columns={columns}
                        rows={profiles}
                        loading={!profiles.length}
                    />
                </div>
            </div>
        </>
       
    )
}

export default ProfilesTbl
