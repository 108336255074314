import { DOCUMENTS_LIST_REQUEST, DOCUMENTS_LIST_SUCCESS, DOCUMENTS_LIST_FAIL, VIEW_DOCUMENT_FAIL, VIEW_DOCUMENT_REQUEST, VIEW_DOCUMENT_SUCCESS, DOCUMENT_CREATE_FAIL, DOCUMENT_UPDATE_REQUEST, DOCUMENT_UPDATE_FAIL, DOCUMENT_DELETE_REQUEST, DOCUMENT_DELETE_FAIL, DOCUMENT_CREATE_REQUEST, DOCUMENT_CREATE_SUCCESS, DOCUMENT_UPDATE_SUCCESS, DOCUMENT_DELETE_SUCCESS } from "../Constants/documentConstants";

const documentsListReducer = (state = {documents: []}, action) => {

    switch (action.type)
    {
        case DOCUMENTS_LIST_REQUEST:
            return {loading: true};
 
        case DOCUMENTS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and documents data is:' + action.payload)
            return {
                loading: false,
                documents: action.payload
            }
        case DOCUMENTS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const documentCreateReducer = (state = {documents: []}, action) => {

    switch (action.type) {

        case DOCUMENT_CREATE_REQUEST:
            return {
                loading: true
            }

        case DOCUMENT_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                documents: [...state.documents, {Applicant_Id: 2, Name: value}]
            };

        case DOCUMENT_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const documentViewReducer = (state= {document: {}}, action) => {
    switch(action.type)
    {
        case VIEW_DOCUMENT_REQUEST:
            return {loading: true};
        case VIEW_DOCUMENT_SUCCESS:
            return {
                    loading: false,
                    document: action.payload
                }
        case VIEW_DOCUMENT_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const documentUpdateReducer = (state = {documents: {}}, action) => {

    switch (action.type) {

        case DOCUMENT_UPDATE_REQUEST:
            return {
                
            }
        case DOCUMENT_UPDATE_SUCCESS:

            return {

            }
        case DOCUMENT_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const documentDeleteReducer = (state = {documents: {}}, action) => {

    switch (action.type) {
        case DOCUMENT_DELETE_REQUEST:
            return {
                
            }
        case DOCUMENT_DELETE_SUCCESS:
            return {

            }
        case DOCUMENT_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {documentsListReducer, documentCreateReducer, documentViewReducer, documentUpdateReducer, documentDeleteReducer }