import React, {useState, useEffect} from 'react';
import { Link as BreadcrumbLink, CircularProgress } from '@mui/material';
import { IconButton, Grid, Divider, Stack} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../Hooks/useDialog';
import { blueGrey, purple, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import { blue } from '@material-ui/core/colors';
import { getShiftsList } from '../../../Redux/Actions/ShiftActions';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import Crumbs from '../../../Components/Utilities/Crumbs';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import ShiftDialogs from '../../../Components/Dialogs/CRM/Staff/ShiftDialogs/ShiftDialogs';
import ShiftsTbl from '../../../Components/Table/CRM/Staff/ShiftsTbl';
 
const Shifts = () => { 

    //FETCH DATA FROM SERVER
    
    const dispatch = useDispatch();
    const {loading, shifts } = useSelector(state => state.shifts_List) 
    const [list, setList] = useState([])
    
    useEffect(()=> {
        dispatch(getShiftsList());
    }, [])

    useEffect(() => {
        if(shifts)
        {
            setList([...shifts]);
        }
    }, [shifts])
     
    
    //---------------------------------------------------------------------------------

    
    
        const handleAddClick = () => {
            console.log('THIS IS WORKING')
            showDialog(true, 'create', '');
        }

    //----------------------------------------------------------------------------------------------------------------------------------
        
    //TODO: CRUD FUNCTIONS
        
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)
    
    //----------------------------------------------------------------------------------------------------------------------------------
     
    return (
        <div>
            
        <ScreenHeader title="Shifts" subtitle="Manage shifts from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>
     
        <Grid container justifyContent="space-between" sx={{mb: 2}}>
            <Crumbs root="CRM" middle="Staff" end="Shifts" main/>
            <Stack direction="row" justifyContent="center" alignItems="center">
                <AddButtonLabel item="Shift"/>
                <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                    <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                </IconButton>
            </Stack>
        </Grid>

        { dialog.open  ?  <ShiftDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>: '' }

        <Divider sx={{color: lightBlue[800]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        { loading?  <HorizontalLoading/> : '' }
        <HorizontalSummaryBox primary="Shifts Brief" secondary="Overall Shifts count" label1="All Shifts" label2="Second Label" label3="Third Label" count={[2,11,6]}/>
        
        
        <AboveTableHeader title="Shifts List"/>
        <Grid container>
            <ShiftsTbl shifts={list}/>
        </Grid>
        


     
    </div>
    )
}

export default Shifts
