import { JOBROLE_LIST_REQUEST, JOBROLE_LIST_SUCCESS, JOBROLE_LIST_FAIL} from "../Constants/jobRoleConstants";

const jobRolesListReducer = (state = {jobRoles: []}, action) => {
 
    switch (action.type)
    {
        case JOBROLE_LIST_REQUEST:
            return {loading: true};
 
        case JOBROLE_LIST_SUCCESS:
            console.log('Code is reaching the reducer and jobRoles data is:' + (action.payload)[0].Profile_Id)
            return {
                loading: false,
                jobRoles: action.payload
            }
        case JOBROLE_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 



export {jobRolesListReducer}