export const BALANCESHEETS_LIST_REQUEST = 'BALANCESHEETS_LIST_REQUEST'
export const BALANCESHEETS_LIST_SUCCESS = 'BALANCESHEETS_LIST_SUCCESS'
export const BALANCESHEETS_LIST_FAIL = 'BALANCESHEETS_LIST_FAIL'


export const BALANCESHEET_CREATE_REQUEST = 'BALANCESHEET_CREATE_REQUEST'
export const BALANCESHEET_CREATE_SUCCESS = 'BALANCESHEET_CREATE_SUCCESS'
export const BALANCESHEET_CREATE_FAIL = 'BALANCESHEET_CREATE_FAIL'


export const BALANCESHEET_UPDATE_REQUEST = 'BALANCESHEET_UPDATE_REQUEST'
export const BALANCESHEET_UPDATE_SUCCESS = 'BALANCESHEET_UPDATE_SUCCESS'
export const BALANCESHEET_UPDATE_FAIL = 'BALANCESHEET_UPDATE_FAIL'

export const BALANCESHEET_DELETE_REQUEST = 'BALANCESHEET_DELETE_REQUEST'
export const BALANCESHEET_DELETE_SUCCESS = 'BALANCESHEET_DELETE_SUCCESS'
export const BALANCESHEET_DELETE_FAIL = 'BALANCESHEET_DELETE_FAIL'

export const VIEW_BALANCESHEET_REQUEST = 'VIEW_BALANCESHEET_REQUEST'
export const VIEW_BALANCESHEET_SUCCESS = 'VIEW_BALANCESHEET_SUCCESS'
export const VIEW_BALANCESHEET_FAIL = 'VIEW_BALANCESHEET_FAIL'