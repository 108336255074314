import { CLIENTS_LIST_REQUEST, CLIENTS_LIST_SUCCESS, CLIENTS_LIST_FAIL, VIEW_CLIENT_FAIL, VIEW_CLIENT_REQUEST, VIEW_CLIENT_SUCCESS, CLIENT_CREATE_REQUEST, CLIENT_CREATE_SUCCESS, CLIENT_CREATE_FAIL, CLIENT_UPDATE_FAIL, CLIENT_UPDATE_REQUEST, CLIENT_UPDATE_SUCCESS } from '../Constants/clientConstants';
import api from '../../config/apiConfig';


const getClientsList = () => async(dispatch) => {
    
 
    try {
        dispatch({type: CLIENTS_LIST_REQUEST});
        const {data} = await api.get('/clients');
        //console.log('Clients data is:' + data[0].Name)
        dispatch({type: CLIENTS_LIST_SUCCESS, payload: data})
        
        
    } catch (error) {
        dispatch({type: CLIENTS_LIST_FAIL, payload: error})
    }
} 

const submitClient = (stateValues, postValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: CLIENT_CREATE_REQUEST});
        
        const {data} = await api.post(`/clients/`, postValues);
        console.log('Client data is:' + data)
        dispatch({type: CLIENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: CLIENT_CREATE_FAIL, payload: error})
    }
} 

const getClient = (id) => async (dispatch) => {
    
     
    try {
        dispatch({type: VIEW_CLIENT_REQUEST});
        
        const {data} = await api.get(`/clients/ ${id}`);
        console.log('Client data is:' + data)
        dispatch({type: VIEW_CLIENT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_CLIENT_FAIL, payload: error})
    }
}

const editClient = (values) => async (dispatch) => {
    
     
    try {

        dispatch({type: CLIENT_UPDATE_REQUEST});
        
        const {data} = await api.put(`/clients/`, values);
        console.log('Client data is:' + data)
        dispatch({type: CLIENT_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: CLIENT_UPDATE_FAIL, payload: error})
    }
}



export {getClientsList, submitClient, getClient, editClient }