export const DOCUMENTS_LIST_REQUEST = 'DOCUMENTS_LIST_REQUEST'
export const DOCUMENTS_LIST_SUCCESS = 'DOCUMENTS_LIST_SUCCESS'
export const DOCUMENTS_LIST_FAIL = 'DOCUMENTS_LIST_FAIL'


export const DOCUMENT_CREATE_REQUEST = 'DOCUMENT_CREATE_REQUEST'
export const DOCUMENT_CREATE_SUCCESS = 'DOCUMENT_CREATE_SUCCESS'
export const DOCUMENT_CREATE_FAIL = 'DOCUMENT_CREATE_FAIL'


export const DOCUMENT_UPDATE_REQUEST = 'DOCUMENT_UPDATE_REQUEST'
export const DOCUMENT_UPDATE_SUCCESS = 'DOCUMENT_UPDATE_SUCCESS'
export const DOCUMENT_UPDATE_FAIL = 'DOCUMENT_UPDATE_FAIL'

export const DOCUMENT_DELETE_REQUEST = 'DOCUMENT_DELETE_REQUEST'
export const DOCUMENT_DELETE_SUCCESS = 'DOCUMENT_DELETE_SUCCESS'
export const DOCUMENT_DELETE_FAIL = 'DOCUMENT_DELETE_FAIL'

export const VIEW_DOCUMENT_REQUEST = 'VIEW_DOCUMENT_REQUEST'
export const VIEW_DOCUMENT_SUCCESS = 'VIEW_DOCUMENT_SUCCESS'
export const VIEW_DOCUMENT_FAIL = 'VIEW_DOCUMENT_FAIL'