import { INVOICESTATUSES_LIST_REQUEST, INVOICESTATUSES_LIST_SUCCESS, INVOICESTATUSES_LIST_FAIL, VIEW_INVOICESTATUS_FAIL, VIEW_INVOICESTATUS_REQUEST, VIEW_INVOICESTATUS_SUCCESS, INVOICESTATUS_CREATE_FAIL, INVOICESTATUS_UPDATE_REQUEST, INVOICESTATUS_UPDATE_FAIL, INVOICESTATUS_DELETE_REQUEST, INVOICESTATUS_DELETE_FAIL, INVOICESTATUS_CREATE_REQUEST, INVOICESTATUS_CREATE_SUCCESS, INVOICESTATUS_UPDATE_SUCCESS, INVOICESTATUS_DELETE_SUCCESS } from "../Constants/invoiceStatusConstants";

const invoiceStatusesListReducer = (state = {invoiceStatuses: []}, action) => {

    switch (action.type)
    {
        case INVOICESTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case INVOICESTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and invoiceStatuss data is:' + action.payload)
            return {
                loading: false,
                invoiceStatuses: action.payload
            }
        case INVOICESTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const invoiceStatusCreateReducer = (state = {invoiceStatuses: []}, action) => {

    switch (action.type) {

        case INVOICESTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case INVOICESTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                invoiceStatuses: [...state.invoiceStatuses, {Applicant_Id: 2, Name: value}]
            };

        case INVOICESTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const invoiceStatusViewReducer = (state= {invoiceStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_INVOICESTATUS_REQUEST:
            return {loading: true};
        case VIEW_INVOICESTATUS_SUCCESS:
            return {
                    loading: false,
                    invoiceStatus: action.payload
                }
        case VIEW_INVOICESTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const invoiceStatusUpdateReducer = (state = {invoiceStatuss: {}}, action) => {

    switch (action.type) {

        case INVOICESTATUS_UPDATE_REQUEST:
            return {
                
            }
        case INVOICESTATUS_UPDATE_SUCCESS:

            return {

            }
        case INVOICESTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const invoiceStatusDeleteReducer = (state = {invoiceStatuss: {}}, action) => {

    switch (action.type) {
        case INVOICESTATUS_DELETE_REQUEST:
            return {
                
            }
        case INVOICESTATUS_DELETE_SUCCESS:
            return {

            }
        case INVOICESTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {invoiceStatusesListReducer, invoiceStatusCreateReducer, invoiceStatusViewReducer, invoiceStatusUpdateReducer, invoiceStatusDeleteReducer }