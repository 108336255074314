export const HOLIDAYGROUPS_LIST_REQUEST = 'HOLIDAYGROUPS_LIST_REQUEST'
export const HOLIDAYGROUPS_LIST_SUCCESS = 'HOLIDAYGROUPS_LIST_SUCCESS'
export const HOLIDAYGROUPS_LIST_FAIL = 'HOLIDAYGROUPS_LIST_FAIL'


export const HOLIDAYGROUP_CREATE_REQUEST = 'HOLIDAYGROUP_CREATE_REQUEST'
export const HOLIDAYGROUP_CREATE_SUCCESS = 'HOLIDAYGROUP_CREATE_SUCCESS'
export const HOLIDAYGROUP_CREATE_FAIL = 'HOLIDAYGROUP_CREATE_FAIL'


export const HOLIDAYGROUP_UPDATE_REQUEST = 'HOLIDAYGROUP_UPDATE_REQUEST'
export const HOLIDAYGROUP_UPDATE_SUCCESS = 'HOLIDAYGROUP_UPDATE_SUCCESS'
export const HOLIDAYGROUP_UPDATE_FAIL = 'HOLIDAYGROUP_UPDATE_FAIL'

export const HOLIDAYGROUP_DELETE_REQUEST = 'HOLIDAYGROUP_DELETE_REQUEST'
export const HOLIDAYGROUP_DELETE_SUCCESS = 'HOLIDAYGROUP_DELETE_SUCCESS'
export const HOLIDAYGROUP_DELETE_FAIL = 'HOLIDAYGROUP_DELETE_FAIL'

export const VIEW_HOLIDAYGROUP_REQUEST = 'VIEW_HOLIDAYGROUP_REQUEST'
export const VIEW_HOLIDAYGROUP_SUCCESS = 'VIEW_HOLIDAYGROUP_SUCCESS'
export const VIEW_HOLIDAYGROUP_FAIL = 'VIEW_HOLIDAYGROUP_FAIL'