import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { blueGrey, pink } from '@mui/material/colors';
import { IconButton } from '@mui/material';

const DropFileInput = (props) => {

    const wrapperRef = useRef(null);

    const handleDragEnter = () => {
        wrapperRef.current.classList.add('dragover')
    }
    const handleDragLeave = () => {
        wrapperRef.current.classList.remove('dragover')
    }
    const handleDrop = () => {
        wrapperRef.current.classList.remove('dragover')
    }

    const [fileList, setFileList] = useState([]);

    const handleFileDrop = (e) => {
        const newFile = e.target.files[0];
        newFile.id = Math.floor(Math.random() * 20);
        newFile.expiry = '';
        newFile.documenttypes = '';
        console.log('NEW FILE: ' + newFile)
        if(newFile)
        {
            const updatedList = [...fileList, newFile];
            setFileList(updatedList)
            props.onFileChange(updatedList);
        }
    }

    const handleDelete = (file) => {

        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        props.onFileChange(updatedList);
    }

    return (
        <>
            <div 
                ref={wrapperRef} 
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                className="drop-file-input"
            >

                <div className="drop-file-input__label">
                    <UploadFileRoundedIcon sx={{fontSize: 105, color: blueGrey[900]}}/>
                    <p>Drag and drop your files here</p>
                </div>
                <input 
                    onChange={handleFileDrop}
                    type="file" 
                    value=""
                />
            </div>

            {
                fileList.length > 0 ? (
                    <div className="drop-file-preview">
                        <p className="drop-file-preview__label">Ready to Upload</p>
                        {
                            fileList.map((file, index) => (
                                <div key={index} className="drop-file-preview__item">
                                    <PictureAsPdfRoundedIcon sx={{mr: 2, fontSize: 45, color: pink[400]}}/>
                                    <div className="drop-file-preview__item__info">
                                        <p>{file.name}</p>
                                        <p>{file.size}B</p>
                                    </div>
                                    <span className="drop-file-preview__item__delete" onClick={() => handleDelete(file)}>
                                        <IconButton className="dfp-delete" size="medium" sx={{background: 'white', boxShadow: '.5px .5px 13px whitesmoke'}}>
                                            <DeleteOutlineRoundedIcon sx={{color: blueGrey[700]}}/>
                                        </IconButton>
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                ) : null
            }
        </>
    )
}

DropFileInput.propTypes = {
    onFileChange: PropTypes.func,
}


export default DropFileInput
