import { INFLUENCES_LIST_REQUEST, INFLUENCES_LIST_SUCCESS, INFLUENCES_LIST_FAIL, VIEW_INFLUENCE_FAIL, VIEW_INFLUENCE_REQUEST, VIEW_INFLUENCE_SUCCESS, INFLUENCE_CREATE_FAIL, INFLUENCE_UPDATE_REQUEST, INFLUENCE_UPDATE_FAIL, INFLUENCE_DELETE_REQUEST, INFLUENCE_DELETE_FAIL, INFLUENCE_CREATE_REQUEST, INFLUENCE_CREATE_SUCCESS, INFLUENCE_UPDATE_SUCCESS, INFLUENCE_DELETE_SUCCESS } from "../Constants/influenceConstants";

const influencesListReducer = (state = {influences: []}, action) => {

    switch (action.type)
    {
        case INFLUENCES_LIST_REQUEST:
            return {loading: true};
 
        case INFLUENCES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and influences data is:' + action.payload)
            return {
                loading: false,
                influences: action.payload
            }
        case INFLUENCES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const influenceCreateReducer = (state = {influences: []}, action) => {

    switch (action.type) {

        case INFLUENCE_CREATE_REQUEST:
            return {
                loading: true
            }

        case INFLUENCE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                influences: [...state.influences, {Applicant_Id: 2, Name: value}]
            };

        case INFLUENCE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const influenceViewReducer = (state= {influence: {}}, action) => {
    switch(action.type)
    {
        case VIEW_INFLUENCE_REQUEST:
            return {loading: true};
        case VIEW_INFLUENCE_SUCCESS:
            return {
                    loading: false,
                    influence: action.payload
                }
        case VIEW_INFLUENCE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const influenceUpdateReducer = (state = {influences: {}}, action) => {

    switch (action.type) {

        case INFLUENCE_UPDATE_REQUEST:
            return {
                
            }
        case INFLUENCE_UPDATE_SUCCESS:

            return {

            }
        case INFLUENCE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const influenceDeleteReducer = (state = {influences: {}}, action) => {

    switch (action.type) {
        case INFLUENCE_DELETE_REQUEST:
            return {
                
            }
        case INFLUENCE_DELETE_SUCCESS:
            return {

            }
        case INFLUENCE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {influencesListReducer, influenceCreateReducer, influenceViewReducer, influenceUpdateReducer, influenceDeleteReducer }