import { SITES_LIST_REQUEST, SITES_LIST_SUCCESS, SITES_LIST_FAIL, VIEW_SITE_FAIL, VIEW_SITE_REQUEST, VIEW_SITE_SUCCESS, SITE_CREATE_FAIL, SITE_UPDATE_REQUEST, SITE_UPDATE_FAIL, SITE_DELETE_REQUEST, SITE_DELETE_FAIL, SITE_CREATE_REQUEST, SITE_CREATE_SUCCESS, SITE_UPDATE_SUCCESS, SITE_DELETE_SUCCESS } from "../Constants/siteConstants";

const sitesListReducer = (state = {sites: []}, action) => {

    switch (action.type)
    {
        case SITES_LIST_REQUEST:
            return {loading: true};
 
        case SITES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and sites data is:' + action.payload)
            return {
                loading: false,
                sites: action.payload
            }
        case SITES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const siteCreateReducer = (state = {sites: []}, action) => {

    switch (action.type) {

        case SITE_CREATE_REQUEST:
            return {
                loading: true
            }

        case SITE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                sites: [...state.sites, {Applicant_Id: 2, Name: value}]
            };

        case SITE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const siteViewReducer = (state= {site: {}}, action) => {
    switch(action.type)
    {
        case VIEW_SITE_REQUEST:
            return {loading: true};
        case VIEW_SITE_SUCCESS:
            return {
                    loading: false,
                    site: action.payload
                }
        case VIEW_SITE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const siteUpdateReducer = (state = {sites: {}}, action) => {

    switch (action.type) {

        case SITE_UPDATE_REQUEST:
            return {
                
            }
        case SITE_UPDATE_SUCCESS:

            return {

            }
        case SITE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const siteDeleteReducer = (state = {sites: {}}, action) => {

    switch (action.type) {
        case SITE_DELETE_REQUEST:
            return {
                
            }
        case SITE_DELETE_SUCCESS:
            return {

            }
        case SITE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {sitesListReducer, siteCreateReducer, siteViewReducer, siteUpdateReducer, siteDeleteReducer }