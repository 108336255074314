import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, duration} from '@mui/material';
import { blueGrey, cyan, purple, blue, teal, orange, deepOrange, green, yellow } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useDispatch, useSelector } from 'react-redux';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { submitAssessment } from '../../../../../Redux/Actions/AssessmentsActions';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated'
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { getClientsList } from '../../../../../Redux/Actions/ClientsActions';
import { getWorkers } from '../../../../../Redux/Actions/WorkerActions';
import WorkIcon from '@mui/icons-material/Work';
import EventIcon from '@mui/icons-material/Event';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import api from '../../../../../config/apiConfig';
import { getBranchesList } from '../../../../../Redux/Actions/BranchActions';
import { Link as BreadcrumbLink } from '@mui/material';
import { getRateCardsList } from '../../../../../Redux/Actions/RateCardActions';
import { getContactsList } from '../../../../../Redux/Actions/ContactActions';
import { submitShift } from '../../../../../Redux/Actions/ShiftActions';
 


const AddShiftDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch(); 

    //GET SHIFT STATUS
    const statuses_ListState = useSelector(state => state.shiftStatuses_List);
    const {shiftStatuses, loading: loading_statuses} = statuses_ListState;

    //GET DIVISIONS
    const divisions_ListState = useSelector(state => state.divisions_List);
    const {divisions, loading} = divisions_ListState;

    //GET CLIENTS
    const clients_ListState = useSelector(state => state.clients_List);
    const {clients, loading: loading_clients} = clients_ListState;

    //GET WORKERS
    const workers_ListState = useSelector(state => state.workers_List);
    const {workers, loading: loading_workers} = workers_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const branches_ListState = useSelector(state => state.branches_List);
    const {branches, loading: loading_branches} = branches_ListState;

    //GET JOB ROLES
    const roles_List_State = useSelector((state) => state.jobRoles_List);
    const {loading: loading_roles, jobRoles} = roles_List_State;

    //GET RATE CARDS
    const rateCards_List_State = useSelector((state) => state.rateCards_List);
    const {loading: loading_rates, rates} = rateCards_List_State;

    //GET CONTACTS
    const contacts_List_State = useSelector((state) => state.contacts_List);
    const {loading: loading_contacts, contacts} = contacts_List_State;
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getBranchesList());
        dispatch(getStaffList());
        dispatch(getJobRolesList());
        dispatch(getClientsList());
        dispatch(getWorkers());
        dispatch(getStaffList());
        dispatch(getRateCardsList());
        dispatch(getContactsList());
       
    }, [])
    

    const [values, setValues] = useState({
            //shift_date: current_date, 
            shiftstatuses: '',
            description: '',
            jobroles: '' , 
            staff: '' , 
            clients: '' , 
            divisions: '', 
            workers: '', 
            ratecards: '', 
            notes: '', 
            contacts: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            //shift_date: current_date, 
            shiftstatuses: '',
            description: '',
            jobroles: '' , 
            staff: '' , 
            clients: '',
            divisions: '', 
            workers: '', 
            ratecards: '', 
            notes: '', 
            contacts: '', 
        });

    
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'jobroles' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
        }
        else if(name == 'clients' || name == 'status' || name == 'result')
        {

            setValues({
                ...values,
                [name]: value
            });
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
 
        }
    }
    

    const handleSave = () => {
        console.log('in save function')
        const start_time = shiftTime.start_time;
        const end_time = shiftTime.end_time;
        dispatch(submitShift({...submitValues, date, start_time, end_time}))
        handleSubmit('success',`Shift successfully created with status ${values.shiftstatuses.label}`);

    }

    const animatedComponents = makeAnimated();


    const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {

        
        const keeper = [];

        const tableName = name == 'divisions' ? divisions : name == 'clients' ? clients : name == 'staff' ? staff : name == 'workers' ? workers : name == 'shiftstatuses' ? shiftStatuses : name == 'jobroles' ? jobRoles : name == 'ratecards'? rates : name == 'contacts'? contacts : '';

        const id_field = name === 'divisions' ? 'Division_Id': name === 'staff' ? 'Staff_Id' : name === 'workers'? 'Worker_Id' : name === 'clients'? 'Client_Id': name === 'shiftstatuses'? 'Id' : name === 'jobroles'? 'Role_Id': name === 'ratecards'? 'Id' : name === 'contacts'? 'Id' : '';
        const name_field = name === 'divisions' ? 'Name': name === 'staff' ? 'First_Name' : name === 'workers'? 'First_Name' : name === 'clients'? 'Name': name === 'shiftstatuses'? 'Name' : name === 'jobroles'? 'Name' : name === 'ratecards'? 'Name' : name === 'contacts'? 'First_Name' :'';
            
        tableName.forEach(x => {
            if(name === 'workers' || name === 'contacts')
            {
                keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
            }
            else{
                keeper.push({value: x[id_field], label: x[name_field]})

            }
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['divisions', 'staff', 'workers', 'clients', 'shiftstatuses', 'jobroles', 'ratecards', 'contacts'];
        
        const id_field = name === 'divisions' ? 'Division_Id': name === 'staff' ? 'Staff_Id' : name === 'workers'? 'Worker_Id' : name === 'clients'? 'Client_Id': name === 'shiftstatuses'? 'Id' : name === 'jobroles'? 'Role_Id': name === 'ratecards'? 'Id' : name === 'contacts'? 'Id' : '';
        const name_field = name === 'divisions' ? 'Name': name === 'staff' ? 'First_Name' : name === 'workers'? 'First_Name' : name === 'clients'? 'Name': name === 'shiftstatuses'? 'Name' : name === 'jobroles'? 'Name' : name === 'ratecards'? 'Name' : name === 'contacts'? 'First_Name' :'';
        
        names.forEach(x => x === name)
        {
            
            data.forEach(x => {
                if(name === 'workers' || name === 'contacts' )
                {
                    keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
                }
                else{

                    keeper.push({value: x[id_field], label: x[name_field]})
                }
                })
               
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action) => {
        
        const name = action.name;

        if(name === 'jobroles')
        {
            const container = [];
            value.forEach(x => { container.push(x.value) })
            setValues({
                ...values,
                jobroles: [...value]
            })
            setSubmitValues({
                ...submitValues,
                jobroles: container
            })
        }
        else{
            setValues({
                ...values,
                [name]: value
            })
            setSubmitValues({
                ...submitValues,
                [name]: value.value
            })

            //SET SHIFT NAME BASED ON INPUT
            // setShiftName(()=> {
            //     if(name === 'workers'){
            //     const worker_fname = values.workers.label;
                
            //     const date = values.shift_date;
            //     const worker_details = workers.find(x => x.First_Name === worker_fname);
            //     const worker_sname = worker_details.Surname;
            //     const client = values.clients.label;
            //     return `${worker_fname + '' +  worker_sname} - ${date} - ${client}`
            //     console.log( 'WORKER: ' + worker_details)
            // }
            //})



            //SET BRANCH NAME BASED ON STAFF SELECTED
            setBranch((prevState)=> {
                if(name === 'staff')
                {
                    const consultant = staff.find(x => x.First_Name === value.label);
                    const targetBranchId = consultant.Department_Id;
                    const branch = branches.find(x => x.Branch_Id === targetBranchId);

                    return branch.Branch_Name;
                    
                    
                }
            })
        }
    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }

    const [summary, setSummary] = useState(false)

    const handleSummary = () => {
        setSummary(!summary);
    }

    const [branch, setBranch] = useState('');
    const [date, setDate] = useState('');
    const [shiftTime, setShiftTime] = useState({
        start_time: '',
        end_time: '',
        duration: '2hrs'
    });
   
    

    const handleTimeChange = (e) => {

        const {name, value} = e.target;
        setShiftTime({
            ...shiftTime,
            [name] : value
        })
        //calculateDuration();
    }

    const calculateDuration = () => {
        if(shiftTime.start_time !== '' && shiftTime.end_time !== '')
        {
            //const start = shiftTime.start_time;
            const start = new Date("01/01/2022" + shiftTime.start_time).getTime()
            const end = new Date("01/01/2022" + shiftTime.end_time)
            //const end = shiftTime.end_time;
            const duration = start;
            setShiftTime({
                ...shiftTime,
                duration : '4hrs'
            })
            console.log('START:' + start)
        }
        //endtime - starttime/60
    }

    //const shiftName = <Typography >{values.shift_date}  {values.workers.label}  {values.clients.label} </Typography>   
    const shiftName = <Typography 
                        sx={{background: '', borderRadius: '3px', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', pl: 2, border: '1px solid #ede7f6'}}>
                        {date ? date : <span style={{color: 'grey', fontStyle: 'italic'}}>__</span> } 
                        <span style={{color: blue[500], px: 3}}> | </span> 
                        {values.workers.label? values.workers.label : <span style={{color: 'grey', fontStyle: 'italic'}}>__</span> }  
                        <span style={{color: blue[500]}}> | </span>  
                        {values.clients.label? values.clients.label : <span style={{color: 'grey', fontStyle: 'italic'}}>__</span>} 
                     </Typography>   
  
    const website = values.clients? 'peterhouse@website.com' : '';
    const shiftNumber = values.clients? 1 : '';
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[700]}}  >
                <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{background: ''}}>
                    <WorkIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                    <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add Shift </Typography>
                </Stack>
                {/* {shiftName} */}
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
        
    
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <Grid item lg={12} sx={{position: 'sticky', zIndex: 10}}>
                        <Grid item lg={12} sx={{m: 0, p: 0, background: ''}}>
                            <Stack direction="row" alignItems="center" sx={{ background: 'blue[50]', mb: 0}}>
                                <Typography variant="body1" sx={{color: blue[800],fontWeight: 900, textAlign: 'center'}}>Form Summary</Typography>
                                <Button variant="text" size="small" sx={{fontSize: 12, color: blue[800], textAlign: 'center'}} onClick={handleSummary}>{summary? 'Close' : 'Open'}</Button>
                            </Stack>
                        </Grid>
                                
                        <Grid container flexDirection="row" justifyContent="space-between" className={`summary ${summary? 'open' : ''}`} sx={{px: 3, pt: '2px', background: '#f2f7fc', border: '2px solid whitesmoke', borderRadius: 3, boxShadow: 'none', overflowY: 'scroll'}}>
                               
                                <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability ID: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{`AVL - ${!loading_statuses? shiftStatuses.length + 1 : ''}`} </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.shift_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Subject: </Typography>
                                    <Typography variant="body2" sx={{color: values.shiftstatuses? '' : blue[500], fontWeight: values.shiftstatuses? 800 : 500, ml: 5}}>{values.shiftstatuses? values.shiftstatuses.value : 'null' }</Typography>
                                </Stack>
                                {/* <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Id </Typography>
                                    <Typography variant="body2" sx={{color: values.jobroles? '' : blue[500], fontWeight: values.jobroles? 800 : 500, ml: 5}}>{values.jobroles? values.jobroles : 'null' }</Typography>
                                </Stack> */}
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Staff Name </Typography>
                                    <Typography variant="body2" sx={{color: staffName? '' : blue[500], fontWeight: staffName? 800 : 500, ml: 5}}>{staffName? staffName : 'null' }</Typography>
                                </Stack>
                                
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Type: </Typography>
                                    <Typography variant="body2" sx={{color: values.clients? '' : blue[500], fontWeight: values.clients? 800 : 500, ml: 5}}>{values.clients? values.clients.value : 'null' }</Typography>
                                </Stack>
                                {/* <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Level: </Typography>
                                    <Typography variant="body2" sx={{color: values.workers? '' : blue[500], fontWeight: values.workers? 800 : 500, ml: 5}}>{values.workers? values.workers : 'null' }</Typography>
                                </Stack> */}
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Result: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.result? 'white' : blue[500], fontSize: '.75rem', textAlign: 'left', px: values.result? '4px' : '', borderRadius: '3px' ,fontWeight: values.result? 500 : 500, background: values.result? blue[500] : ''}}>{values.result? values.result : 'null' }</Typography>
                                </Stack>
                                {/* <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Availability Status: </Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 1, width: '40%'}}>
                                    <Typography variant="body2" sx={{color: values.status? 'white' : blue[500], fontSize: values.status? '.75rem' :'', textAlign: 'left', px: values.status? '4px' : '', borderRadius: '3px'  ,fontWeight: values.status? 500 : 500, background: values.status? blue[500] : ''}}>{values.status? values.status : 'null' }</Typography>
                                </Stack> */}
                                
                            
                            </Grid>
                    </Grid>

                    <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                        <Stack sx={{mb: 3, background: '', height: '300px', borderRadius: '5px', width: '80%', pl: 3, pt: 3}}>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300]}} fontSize="small" href="#basic">Basic Info</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#nature">Job Nature</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#staff">Staff of Shift</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#client">Client Information</BreadcrumbLink>
                            <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#notes">Notes</BreadcrumbLink>
                        </Stack>

                            {/* <p>ID: </p>
                            <p>date: {date}</p>
                            <p>time: {shiftTime.start_time}</p>
                            <p>endtime: {shiftTime.end_time}</p>
                            <p>desc: {submitValues.description}</p>
                            <p>shiftstatuses: {submitValues.shiftstatuses}</p>
                            <p>staff: {submitValues.staff}</p>
                            <p>jobroles: {submitValues.jobroles}</p>
                            <p>clients: {submitValues.clients}</p>
                            <p>divisions: {submitValues.divisions}</p>
                            <p>workers: {submitValues.workers}</p>
                            <p>rate: {submitValues.ratecards}</p>
                            <p>notes: {submitValues.notes}</p>
                            <p>contact: {submitValues.contacts}</p> */}

                        
                    </Grid>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Shift ID</Form.Label>
                                <Form.Control disabled type="email" value="SHT-1" placeholder="E.g 123qst1" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Shift Name</Form.Label>
                                {shiftName}
                                {/* <Form.Control disabled type="email" name="Shift Name" value={shiftName} className="outline-none border-none" /> */}
                            </Form.Group>
                        </Row>
                    
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Shift Description</Form.Label>
                                <Form.Control type="email" name="description" value={values.description} onChange={handleChange} placeholder="A short description will do just fine ..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[700], borderRadius: '5px', px: 2, py: '5px'}}>Job Nature</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Choose Division</Form.Label>
                                    <AsyncSelect
                                        value={values.divisions}
                                        name="divisions"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'divisions')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for division ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Job Roles</Form.Label>
                                <AsyncSelect
                                    value={values.jobroles}
                                    name="jobroles"
                                    cacheOptions
                                    isMulti
                                    loadOptions={(value, action) => loadOptions(value, 'jobroles')}
                                    defaultOptions
                                    onChange= {(value, action) => handleOptionChange(value, action)}
                                    components={animatedComponents}
                                    placeholder="Search for roles ..."
                                    onInputChange={handleInputChange}
                                />
                            </Form.Group>
                        </Row>
                        
                        <EventIcon fontSize="medium" sx={{color: blueGrey[900]}}/>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Shift Date</Form.Label>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            label=""
                                            value={date}
                                            onChange={(newValue) => { setDate(`${newValue.getDay()} / ${newValue.getMonth()} / ${newValue.getFullYear()}`)}}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' blue[50]', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Shift Start Time</Form.Label>
                                <TextField
                                    id="time"
                                    label=""
                                    name="start_time"
                                    type="time"
                                    size="small"
                                    value={shiftTime.start_time}
                                    onChange={handleTimeChange}
                                    defaultValue="07:30"
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    inputProps={{
                                    step: 300, // 5 min
                                    }}
                                    sx={{ width: "100%", background: 'rgb(242, 247, 252)' }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Shift End Time</Form.Label>
                                <TextField
                                    id="time"
                                    label="-"
                                    name="end_time"
                                    type="time"
                                    size="small"
                                    value={shiftTime.end_time}
                                    onChange={handleTimeChange}
                                    defaultValue="07:30"
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    inputProps={{
                                    step: 300, // 5 min
                                    }}
                                    sx={{ width: "100%", background: 'rgb(242, 247, 252)' }}
                                />
                            </Form.Group>
                           
                        </Row>

                        <TimelapseIcon fontSize="medium" sx={{color: blueGrey[800]}}/>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Total Duration</Form.Label>
                                <Form.Control type="text" disabled value={shiftTime.duration} placeholder="Total Duration" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Break Deducted</Form.Label>
                                <Form.Control type="number" placeholder="Break Deducted" className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Shift Status</Form.Label>
                                <AsyncSelect
                                    value={values.shiftstatuses}
                                    name="shiftstatuses"
                                    cacheOptions
                                    loadOptions={(value, action) => loadOptions(value, 'shiftstatuses')}
                                    defaultOptions
                                    onChange= {(value, action) => handleOptionChange(value, action)}
                                    components={animatedComponents}
                                    placeholder="Search for statuses ..."
                                    onInputChange={handleInputChange}
                                />
                            </Form.Group>
                        </Row>


                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Staff On Shift</Typography>
                        <Row>
                            
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Consultant</Form.Label>
                                    <AsyncSelect
                                        value={values.staff}
                                        name="staff"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'staff')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for staff ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Branch</Form.Label>
                                <Form.Control type="email" value={branch} disabled placeholder="Branch Name" className="outline-none border-none" /> 
                            </Form.Group>
                        
                        </Row>
                        <br/>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Related Worker</Form.Label>
                                <AsyncSelect
                                    value={values.workers}
                                    name="workers"
                                    cacheOptions
                                    loadOptions={(value, action) => loadOptions(value, 'workers')}
                                    defaultOptions
                                    onChange= {(value, action) => handleOptionChange(value, action)}
                                    components={animatedComponents}
                                    placeholder="Search for worker ..."
                                    onInputChange={handleInputChange}
                                />
                            </Form.Group>
                        </Row>


                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Client Information</Typography>
                        <Row>
                        
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="view-label">Client Name</Form.Label>
                                <AsyncSelect
                                    value={values.clients}
                                    name="clients"
                                    cacheOptions
                                    loadOptions={(value, action) => loadOptions(value, 'clients')}
                                    defaultOptions
                                    onChange= {(value, action) => handleOptionChange(value, action)}
                                    components={animatedComponents}
                                    placeholder="Search for client ..."
                                    onInputChange={handleInputChange}
                                />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="view-label">Website</Form.Label>
                            <Form.Control type="email" disabled value={website} placeholder="Website" className="outline-none border-none" />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2 col-2">
                            <Form.Label className="view-label">Shift Number</Form.Label>
                            <Form.Control type="number" disabled value={shiftNumber}placeholder="Number" className="outline-none border-none" />
                        </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Booked In By</Typography>
                        <Row>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                            <Form.Label className="view-label">Contact</Form.Label>
                                <AsyncSelect
                                    value={values.contacts}
                                    name="contacts"
                                    cacheOptions
                                    loadOptions={(value, action) => loadOptions(value, 'contacts')}
                                    defaultOptions
                                    onChange= {(value, action) => handleOptionChange(value, action)}
                                    components={animatedComponents}
                                    placeholder="Search for contact ..."
                                    onInputChange={handleInputChange}
                                />
                        </Form.Group>
                        </Row>

                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[800], borderRadius: '5px', px: 2, py: '5px'}}>Pay</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Rate Card</Form.Label>
                                <AsyncSelect
                                    value={values.ratecards}
                                    name="ratecards"
                                    cacheOptions
                                    loadOptions={(value, action) => loadOptions(value, 'ratecards')}
                                    defaultOptions
                                    onChange= {(value, action) => handleOptionChange(value, action)}
                                    components={animatedComponents}
                                    placeholder="Search for card ..."
                                    onInputChange={handleInputChange}
                                />
                            </Form.Group>
                        </Row>
                        
                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: yellow[800], borderRadius: '5px', px: 2, py: '5px'}}> Notes</Typography>
                        

                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Shift Notes</Form.Label>
                                <Form.Control type="email" as="textarea" name="notes" value={values.notes} onChange={handleChange} placeholder="E.g Construction, etc." className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                          
                        </Form>
                    </Grid>
                  

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[800], '&:hover': {color: blue[900]}}} onClick={handleSave}>Save Shift</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddShiftDialog
