export const ASSESSMENTS_LIST_REQUEST = 'ASSESSMENTS_LIST_REQUEST'
export const ASSESSMENTS_LIST_SUCCESS = 'ASSESSMENTS_LIST_SUCCESS'
export const ASSESSMENTS_LIST_FAIL = 'ASSESSMENTS_LIST_FAIL'


export const ASSESSMENT_CREATE_REQUEST = 'PROFILE_CREATE_REQUEST'
export const ASSESSMENT_CREATE_SUCCESS = 'PROFILE_CREATE_SUCCESS'
export const ASSESSMENT_CREATE_FAIL = 'PROFILE_CREATE_FAIL'


export const VIEW_ASSESSMENT_REQUEST = 'VIEW_ASSESSMENT_REQUEST'
export const VIEW_ASSESSMENT_SUCCESS = 'VIEW_ASSESSMENT_SUCCESS'
export const VIEW_ASSESSMENT_FAIL = 'VIEW_ASSESSMENT_FAIL'


export const ASSESSMENT_UPDATE_REQUEST = 'ASSESSMENT_UPDATE_REQUEST'
export const ASSESSMENT_UPDATE_SUCCESS = 'ASSESSMENT_UPDATE_SUCCESS'
export const ASSESSMENT_UPDATE_FAIL = 'ASSESSMENT_UPDATE_FAIL'


export const ASSESSMENT_DELETE_REQUEST = 'ASSESSMENT_DELETE_REQUEST'
export const ASSESSMENT_DELETE_SUCCESS = 'ASSESSMENT_DELETE_SUCCESS'
export const ASSESSMENT_DELETE_FAIL = 'ASSESSMENT_DELETE_REQUEST'