import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Stack, LinearProgress, Box} from '@mui/material';
import { blueGrey, blue, deepPurple, lightBlue, pink, orange } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux';
import CircleIcon from '@mui/icons-material/Circle';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getWorkers, getWorker } from '../../../../../Redux/Actions/WorkerActions';
import api from '../../../../../config/apiConfig';
import { submitPayment } from '../../../../../Redux/Actions/PaymentActions';
import TimesheetSummary from '../../../../Utilities/TimesheetSummary';
import DoneIcon from '@mui/icons-material/Done';


const AddPaymentDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch(); 

    //GET ASSESSMENTS
    const {statuses, loading: loading_statuses} = useSelector(state => state.availabilityStatuses_List);
    const {availabilities, loading: loading_availabilities} = useSelector(state => state.availability_List);
    const {workers, loading} = useSelector(state => state.workers_List);
    const {worker, loading: loading_worker} = useSelector(state => state.worker_View);

    const [selectedTimesheet, setSelectedTimesheet] = useState({})
    
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getWorkers());
       
    }, [])
    

    const [values, setValues] = useState({
            
            workers: '',
            timesheet: '',
            status: '',
        });

    const [submitValues, setSubmitValues] = useState({
            
            workers: '',
            timesheet: '',
            status: '',
        });

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
          
        }
        else if(name == 'type' || name == 'status' || name == 'result')
        {

            setValues({
                ...values,
                [name]: value
            });
           
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
 
        }
    }

    const handleSave = () => {
        console.log('in save function')
        dispatch(submitPayment({...submitValues}));
        handleSubmit();

    }

    const animatedComponents = makeAnimated();


    const [inputValue, setInputValue] = useState('');
    

    const filterOptions = async (inputValue, name) => {

        
        const keeper = [];

        const tableName = name == 'workers' ? workers : name == 'availabilitystatuses' ? statuses : '';

        const id_field = name === 'availabilitystatuses' ? 'Status_Id' : name === 'workers'? 'Worker_Id' : name === 'clients'? 'Client_Id': name === 'shiftstatuses'? 'Id' : name === 'jobroles'? 'Role_Id': name === 'ratecards'? 'Id' : name === 'contacts'? 'Id' : '';
        const name_field = name === 'availabilitystatuses' ? 'Status_Name' : name === 'workers'? 'First_Name' : name === 'clients'? 'Name': name === 'shiftstatuses'? 'Name' : name === 'jobroles'? 'Name' : name === 'ratecards'? 'Name' : name === 'contacts'? 'First_Name' :'';
            
        tableName.forEach(x => {
            if(name === 'workers' || name === 'contacts')
            {
                keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
            }
            else{
                keeper.push({value: x[id_field], label: x[name_field]})

            }
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['divisions', 'staff', 'workers', 'clients', 'shiftstatuses', 'jobroles', 'ratecards', 'contacts', 'availabilitystatuses'];
        
        const id_field = name === 'paymentstatuses' ? 'Id' : name === 'workers'? 'id' : name === 'timesheets'? 'id': name === 'shiftstatuses'? 'Id' : name === 'jobroles'? 'Role_Id': name === 'ratecards'? 'Id' : name === 'contacts'? 'Id' : '';
        const name_field = name === 'paymentstatuses' ? 'Name' : name === 'workers'? 'FullName' : name === 'timesheets'? 'Worker': name === 'shiftstatuses'? 'Name' : name === 'jobroles'? 'Name' : name === 'ratecards'? 'Name' : name === 'contacts'? 'First_Name' :'';
        
        names.forEach(x => x === name)
        {
            
            data.forEach(x => {
                if(name === 'workers' || name === 'contacts' )
                {
                    keeper.push({value: x[id_field], label: `${x['First_Name']}   ${x['Surname']}`})
                }
                else{

                    keeper.push({value: x[id_field], label: x[name_field]})
                }
                })
               
        }
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };
    const handleOptionChange = (value, action) => {
        
        const name = action.name;

            setValues({
                ...values,
                [name]: value
            })
            setSubmitValues({
                ...submitValues,
                [name]: value.value
            })

            if(name === 'workers'){
                dispatch(getWorker(value.value))
            }

    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }

    const handleSelectTimesheet = () => {
        setSelectedTimesheet({div: 'oo'})
        setSubmitValues({...submitValues, timesheet: worker.TimesheetId})
    }
 

    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[700]}}  >
                
                <CheckCircleIcon fontSize="large" sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/> 
                
                <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add Payment </Typography>
            </DialogTitle>
     
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
        
    
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                        {/*
                        <p>thursday: {submitValues.thursday}</p>
                        <p>fri: {submitValues.friday}</p>
                        <p>sat: {submitValues.saturday}</p>
                        <p>sunday: {submitValues.sunday}</p>
                        <p>workers: {submitValues.workers}</p> */}
                        <Grid item lg={12}>
                            <Form className="my-3">
                            <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[700], borderRadius: '5px', px: 2, py: '5px'}}>Basic Info</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2 mt-2">
                                    <Form.Label className="view-label">This is Payment no:</Form.Label>
                                    <Form.Control disabled type="email" value="PYM-3" placeholder="E.g 123qst1" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Payment Name</Form.Label>
                                    <Form.Control type="email" value="Autogenerated" disabled placeholder="Enter name ..." className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Related Worker</Form.Label>
                                    <AsyncSelect
                                        value={values.workers}
                                        name="workers"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'workers')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for worker ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                {/* <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Timesheet</Form.Label>
                                    <AsyncSelect
                                        value={values.timesheets}
                                        name="timesheets"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'timesheets')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for timesheet ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group> */}
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Payment Status</Form.Label>
                                    <AsyncSelect
                                        value={values.paymentstatuses}
                                        name="paymentstatuses"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'paymentstatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                                
                            </Row>
                        </Form>

                        <Box>
                            <Stack direction="row" sx={{mb: 1}}>
                                <Typography sx={{color: blueGrey[800], fontFamily: 'Poppins-Light',mr: 1}}>Timesheets for </Typography>
                                <Typography sx={{color: blue[600], fontFamily: 'Poppins-Light'}}>{!submitValues.workers? '' : worker? worker.FullName : 'no'}</Typography>

                            </Stack>
                            <Divider sx={{color: lightBlue[800], mb: 1}}/>

                            {!submitValues.workers? 
                                <Typography variant="body2" sx={{display: 'block',color: blueGrey[800],background: blueGrey[50], borderRadius: '5px', px: 2, py: 2, textAlign: 'center'}}>No Timesheets to display. Select worker first</Typography>
                                :
                                worker?
                                <Stack direction="row" justifyContent="space-between" sx={{width: '100%', textAlign: 'left' ,background: 'aliceblue', py: 1, px: 2, borderRadius: '5px', boxShadow: '.5px 0 5px lightblue', cursor: 'pointer'}}>
                                    <div>
                                        <CircleIcon fontSize="small" sx={{color: worker.TimesheetId === null? pink[500] : blue[500], mr: 2}}/>
                                        <Typography sx={{display: 'inline',color: blue[500]}}>{worker.TimesheetId === null? 'Worker has no timesheet': `TMS-${worker.TimesheetId} | ${worker.FullName}`} </Typography>
                                    </div>
                                    <Button size="small" sx={{p: 0, color: blue[400] ,background: blue[50]}} onClick={handleSelectTimesheet}>Select</Button>
                                </Stack>
                                : 'No details to display'
                            }
                            {submitValues.timesheet? <Typography sx={{color: orange[500], fontFamily: 'Poppins-Light',mt: 3}}><DoneIcon/> You have selected a timesheet</Typography>
                            : ''
                        }
                        </Box>

                        {
                            selectedTimesheet.Divis != null ?
                                <TimesheetSummary/>
                        : 'No summary to display'
                        }
                        
                        
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[600], '&:hover': {color: deepPurple[900]}}} onClick={handleSave}>Save Payment</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddPaymentDialog
