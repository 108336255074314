import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
//import { ComponentToPrint } from './ComponentToPrint';

export default function useToPDF (){


  const componentRef = useRef(); 
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return {handlePrint, componentRef}
};