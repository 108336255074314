import { COAS_LIST_REQUEST, COAS_LIST_SUCCESS, COAS_LIST_FAIL, VIEW_COA_FAIL, VIEW_COA_REQUEST, VIEW_COA_SUCCESS, COA_CREATE_FAIL, COA_UPDATE_REQUEST, COA_UPDATE_FAIL, COA_DELETE_REQUEST, COA_DELETE_FAIL, COA_CREATE_REQUEST, COA_CREATE_SUCCESS, COA_UPDATE_SUCCESS, COA_DELETE_SUCCESS } from "../Constants/chartOfAccountConstants";

const coasListReducer = (state = {coas: []}, action) => {

    switch (action.type)
    {
        case COAS_LIST_REQUEST:
            return {loading: true};
 
        case COAS_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and coas data is:' + action.payload)
            return {
                loading: false,
                coas: action.payload
            }
        case COAS_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const coaCreateReducer = (state = {coas: []}, action) => {

    switch (action.type) {

        case COA_CREATE_REQUEST:
            return {
                loading: true
            }

        case COA_CREATE_SUCCESS: 
        console.log('Previous divisions state from reducer: ' + state.coas);
        
        const newClient = action.payload;
        const updatedList = [...state.coas, newClient];
            return {
                loading: false,
                coas: updatedList,
            };

        case COA_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const coaViewReducer = (state= {coa: {}}, action) => {
    switch(action.type)
    {
        case VIEW_COA_REQUEST:
            return {loading: true};
        case VIEW_COA_SUCCESS:
            return {
                    loading: false,
                    coa: action.payload
                }
        case VIEW_COA_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const coaUpdateReducer = (state = {coas: {}}, action) => {

    switch (action.type) {

        case COA_UPDATE_REQUEST:
            return {
                
            }
        case COA_UPDATE_SUCCESS:

            return {

            }
        case COA_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const coaDeleteReducer = (state = {coas: {}}, action) => {

    switch (action.type) {
        case COA_DELETE_REQUEST:
            return {
                
            }
        case COA_DELETE_SUCCESS:
            return {

            }
        case COA_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {coasListReducer, coaCreateReducer, coaViewReducer, coaUpdateReducer, coaDeleteReducer }