import React from 'react'
import { Button, IconButton, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import useCRUD from '../../Hooks/useCRUD';
import { blueGrey, blue } from '@mui/material/colors';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const CRUD = ({handleSaveChanges, isEditing, handleCreate, handleEdit,handleCancel, handleDelete, setIsEditing, handlePrint, showDialog }) => {

    return (
        <>
        <Grid item sx={{background: ''}}>
            <Grid container alignItems="center" >
                {
                    isEditing? 
                    <>
                        <IconButton
                            size="small" 
                            aria-label="cancel" 
                            onClick={handleCancel}
                            sx={{background: 'whitesmoke', mr: 2, boxShadow: '.5px .5px 2px #90a4ae'}}><CancelOutlinedIcon fontSize="medium" sx={{color: blue[600]}}/>
                        </IconButton>
                        
                        <Button onClick={handleSaveChanges} sx={{color: blue[700], fontSize: '.7rem',height: 1,fontWeight: 600, background: 'whitesmoke', mr: 3, boxShadow: '.5px .5px 2px #90a4ae'}} size="small">
                            Save
                        </Button>
                    </>
                    :
                    <IconButton 
                        size="small"
                        aria-label="edit" 
                        onClick={handleEdit}
                        sx={{background: 'whitesmoke', mr: 2, boxShadow: '.5px .5px 2px #90a4ae'}}><EditIcon fontSize="inherit" />
                    </IconButton> 
                }
                <IconButton
                    size="small" 
                    aria-label="delete" 
                    onClick={handleDelete}
                    sx={{background: 'whitesmoke', mr: 2, boxShadow: '.5px .5px 2px #90a4ae'}}><DeleteIcon fontSize="inherit" />
                </IconButton>

                <IconButton 
                    size="small"
                    aria-label="print" 
                    onClick={handlePrint}
                    sx={{background: 'whitesmoke', mr: 2, boxShadow: '.5px .5px 2px #90a4ae'}}><PrintIcon fontSize="inherit" />
                </IconButton>

                <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleCreate}>
                    <AddIcon sx={{color: blueGrey[50]}} />
                </IconButton>
            </Grid>
        </Grid>
        </>
    )
}

export default CRUD
