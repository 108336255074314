export const VATRATES_LIST_REQUEST = 'VATRATES_LIST_REQUEST';
export const VATRATES_LIST_SUCCESS = 'VATRATES_LIST_SUCCESS';
export const VATRATES_LIST_FAIL = 'VATRATES_LIST_FAIL'; 

export const VATRATE_CREATE_REQUEST = 'VATRATE_CREATE_REQUEST'
export const VATRATE_CREATE_SUCCESS = 'VATRATE_CREATE_SUCCESS'
export const VATRATE_CREATE_FAIL = 'VATRATE_CREATE_FAIL'


export const VATRATE_UPDATE_REQUEST = 'VATRATE_UPDATE_REQUEST'
export const VATRATE_UPDATE_SUCCESS = 'VATRATE_UPDATE_SUCCESS'
export const VATRATE_UPDATE_FAIL = 'VATRATE_UPDATE_FAIL'

export const VATRATE_DELETE_REQUEST = 'VATRATE_DELETE_REQUEST'
export const VATRATE_DELETE_SUCCESS = 'VATRATE_DELETE_SUCCESS'
export const VATRATE_DELETE_FAIL = 'VATRATE_DELETE_FAIL'

export const VIEW_VATRATE_REQUEST = 'VIEW_VATRATE_REQUEST'
export const VIEW_VATRATE_SUCCESS = 'VIEW_VATRATE_SUCCESS'
export const VIEW_VATRATE_FAIL = 'VATRATE_FAIL'