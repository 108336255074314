import { CLIENTSTATUSES_LIST_REQUEST, CLIENTSTATUSES_LIST_SUCCESS, CLIENTSTATUSES_LIST_FAIL, VIEW_CLIENTSTATUS_FAIL, VIEW_CLIENTSTATUS_REQUEST, VIEW_CLIENTSTATUS_SUCCESS, CLIENTSTATUS_CREATE_FAIL, CLIENTSTATUS_UPDATE_REQUEST, CLIENTSTATUS_UPDATE_FAIL, CLIENTSTATUS_DELETE_REQUEST, CLIENTSTATUS_DELETE_FAIL, CLIENTSTATUS_CREATE_REQUEST, CLIENTSTATUS_CREATE_SUCCESS, CLIENTSTATUS_UPDATE_SUCCESS, CLIENTSTATUS_DELETE_SUCCESS } from "../Constants/clientStatusConstants";

const clientStatusesListReducer = (state = {clientStatuses: []}, action) => {

    switch (action.type)
    {
        case CLIENTSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case CLIENTSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and clientStatuss data is:' + action.payload)
            return {
                loading: false,
                clientStatuses: action.payload
            }
        case CLIENTSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const clientStatusCreateReducer = (state = {clientStatuses: []}, action) => {

    switch (action.type) {

        case CLIENTSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case CLIENTSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                clientStatuses: [...state.clientStatuses, {Applicant_Id: 2, Name: value}]
            };

        case CLIENTSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const clientStatusViewReducer = (state= {clientStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_CLIENTSTATUS_REQUEST:
            return {loading: true};
        case VIEW_CLIENTSTATUS_SUCCESS:
            return {
                    loading: false,
                    clientStatus: action.payload
                }
        case VIEW_CLIENTSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const clientStatusUpdateReducer = (state = {clientStatuss: {}}, action) => {

    switch (action.type) {

        case CLIENTSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case CLIENTSTATUS_UPDATE_SUCCESS:

            return {

            }
        case CLIENTSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const clientStatusDeleteReducer = (state = {clientStatuss: {}}, action) => {

    switch (action.type) {
        case CLIENTSTATUS_DELETE_REQUEST:
            return {
                
            }
        case CLIENTSTATUS_DELETE_SUCCESS:
            return {

            }
        case CLIENTSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {clientStatusesListReducer, clientStatusCreateReducer, clientStatusViewReducer, clientStatusUpdateReducer, clientStatusDeleteReducer }