export const SHIFTS_LIST_REQUEST = 'SHIFTS_LIST_REQUEST'
export const SHIFTS_LIST_SUCCESS = 'SHIFTS_LIST_SUCCESS'
export const SHIFTS_LIST_FAIL = 'SHIFTS_LIST_FAIL'


export const SHIFT_CREATE_REQUEST = 'SHIFT_CREATE_REQUEST'
export const SHIFT_CREATE_SUCCESS = 'SHIFT_CREATE_SUCCESS'
export const SHIFT_CREATE_FAIL = 'SHIFT_CREATE_FAIL'


export const SHIFT_UPDATE_REQUEST = 'SHIFT_UPDATE_REQUEST'
export const SHIFT_UPDATE_SUCCESS = 'SHIFT_UPDATE_SUCCESS'
export const SHIFT_UPDATE_FAIL = 'SHIFT_UPDATE_FAIL'

export const SHIFT_DELETE_REQUEST = 'SHIFT_DELETE_REQUEST'
export const SHIFT_DELETE_SUCCESS = 'SHIFT_DELETE_SUCCESS'
export const SHIFT_DELETE_FAIL = 'SHIFT_DELETE_FAIL'

export const VIEW_SHIFT_REQUEST = 'VIEW_SHIFT_REQUEST'
export const VIEW_SHIFT_SUCCESS = 'VIEW_SHIFT_SUCCESS'
export const VIEW_SHIFT_FAIL = 'VIEW_SHIFT_FAIL'