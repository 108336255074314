import React, {useState, useEffect} from 'react';
import { Link as BreadcrumbLink, CircularProgress, Typography, Button } from '@mui/material';
import {IconButton, Grid, Divider, Stack} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../Hooks/useDialog';
import { blueGrey, blue, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import HorizontalSummaryBox from '../../../Components/Utilities/HorizontalSummaryBox';
import Crumbs from '../../../Components/Utilities/Crumbs';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import { getClientsList } from '../../../Redux/Actions/ClientsActions';
import ClientsTbl from '../../../Components/Table/CRM/Sales/ClientsTbl';
import ClientDialogs from '../../../Components/Dialogs/CRM/Sales/ClientDialogs/ClientDialogs';

 

const Roles = () => { 

    
    const dispatch = useDispatch();
    const {loading, clients, error } = useSelector(state => state.clients_List) //receiving state object
    const [list, setList] = useState([])
    
    useEffect(()=> {
        dispatch(getClientsList());
    }, [])
     
    useEffect(() => {
        if(clients)
        {
            setList([...clients])
        }
    }, [clients])

    
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //TABLE DIALOG FUNCTIONALITY
    
        const handleAddClick = () => {
            console.log('THIS IS WORKING')
            showDialog(true, 'create', '');
        }

    //----------------------------------------------------------------------------------------------------------------------------------
        
    //TODO: CRUD FUNCTIONS
        
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)
    
    //----------------------------------------------------------------------------------------------------------------------------------
     
    return (
        <div>
            
        <ScreenHeader title="Roles" subtitle="Manage all system users from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>
        { dialog.open ?  <ClientDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
        <Grid container justifyContent="space-between" sx={{mb: 2}}>
            <Crumbs root="CRM" middle="Admin" end="Roles" main/>
            <Stack direction="row" justifyContent="center" alignItems="center">
                <AddButtonLabel item="Role"/>
                <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                    <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                </IconButton>
            </Stack>
        </Grid>


        <Divider sx={{color: lightBlue[800]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        { loading ?  <HorizontalLoading/> : '' }

        <Grid container sx={{background: 'white', p: 2, mt: 1, border: '1px solid #bbdefb', borderRadius: 2}}>
            <Typography sx={{fontSize: 19, fontFamily: 'Poppins-Bold', color: blueGrey[900], mb: 1}}> Roles Available</Typography>
            <Typography sx={{fontSize: 15, fontFamily: 'Poppins', color: blueGrey[700]}}>A role provides provides access to predefined menus and features so that depending on the assigned role (Super Admin, Manager, Accountant) an
            an administrator can have access to what he needs.</Typography>

            <Grid container sx={{mt: 2}}>
                <Grid item sx={{border: '1px solid #bbdefb ', background: 'white', p: 2, borderRadius: 2}}>
                    <Typography sx={{fontSize: 15, fontFamily: 'Poppins-Medium', color: blueGrey[900], mb: 0}}>Admin</Typography>
                    <Button sx={{color: blue[400], fontSize: 12, py: 0, pl: 0, fontWeight: 700}}>View more</Button>
                </Grid>
            </Grid>
        </Grid>

        <AboveTableHeader title="Roles List"/>
            


     
    </div>
    )
}

export default Roles
