export const MONTHS_LIST_REQUEST = 'MONTHS_LIST_REQUEST'
export const MONTHS_LIST_SUCCESS = 'MONTHS_LIST_SUCCESS'
export const MONTHS_LIST_FAIL = 'MONTHS_LIST_FAIL'


export const MONTH_CREATE_REQUEST = 'MONTH_CREATE_REQUEST'
export const MONTH_CREATE_SUCCESS = 'MONTH_CREATE_SUCCESS'
export const MONTH_CREATE_FAIL = 'MONTH_CREATE_FAIL'


export const MONTH_UPDATE_REQUEST = 'MONTH_UPDATE_REQUEST'
export const MONTH_UPDATE_SUCCESS = 'MONTH_UPDATE_SUCCESS'
export const MONTH_UPDATE_FAIL = 'MONTH_UPDATE_FAIL'

export const MONTH_DELETE_REQUEST = 'MONTH_DELETE_REQUEST'
export const MONTH_DELETE_SUCCESS = 'MONTH_DELETE_SUCCESS'
export const MONTH_DELETE_FAIL = 'MONTH_DELETE_FAIL'

export const VIEW_MONTH_REQUEST = 'VIEW_MONTH_REQUEST'
export const VIEW_MONTH_SUCCESS = 'VIEW_MONTH_SUCCESS'
export const VIEW_MONTH_FAIL = 'VIEW_MONTH_FAIL'