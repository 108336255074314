import React from 'react'
import { Stack, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

const AddButtonLabel = ({item}) => {
    return (
        <>
            <Stack direction="row">
                <Typography sx={{color: blueGrey[400], fontFamily: 'Poppins'}}>Add </Typography>
                <Typography sx={{color: blueGrey[800], fontFamily: 'Poppins-Medium', ml: '5px'}}>{item}</Typography>
            </Stack>   
        </>
    )
}

export default AddButtonLabel
