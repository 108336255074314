import { SET_ACTIVE, SET_ACTIVE_ERROR, SET_L1_ACTIVE, SET_L1_ACTIVE_ERROR, SET_L2_ACTIVE, SET_L2_ACTIVE_ERROR, SET_L3_ACTIVE, SET_L3_ACTIVE_ERROR } from "../Constants/sitemapConstants";

const setLinkToActive = (index) => async (dispatch) =>{

    try {
        console.log('code here is working, index is:'+ index)
        dispatch({type: SET_ACTIVE, payload: index})
        
    } catch (error) {
        dispatch({type: SET_ACTIVE_ERROR, payload: error})
    }

}

const setL1Link_ToActive = (index) => async (dispatch) =>{

    try {
        console.log('code here is working, index is:'+ index)
        dispatch({type: SET_L1_ACTIVE, payload: index})
        
    } catch (error) {
        dispatch({type: SET_L1_ACTIVE_ERROR, payload: error})
    }

}

const setL2Link_ToActive = (index) => async (dispatch) =>{

    try {
        console.log('code here is working, index is:'+ index)
        dispatch({type: SET_L2_ACTIVE, payload: index})
        
    } catch (error) {
        dispatch({type: SET_L2_ACTIVE_ERROR, payload: error})
    }

}

const setL3Link_ToActive = (index) => async (dispatch) =>{

    try {
        console.log('code here is working, index is:'+ index)
        dispatch({type: SET_L3_ACTIVE, payload: index})
        
    } catch (error) {
        dispatch({type: SET_L3_ACTIVE_ERROR, payload: error})
    }

}

export {setLinkToActive, setL1Link_ToActive, setL2Link_ToActive, setL3Link_ToActive}