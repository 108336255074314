import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { Box, Grid, TextField, Button, FormControlLabel, Checkbox, Typography} from '@mui/material';
import ComplexLogo from '../../assets/ComplexLogo.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import api from '../../config/apiConfig';

const SignIn = ({login, setLogin}) => {

    let navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [validation, setValidation] = useState('');
    
 
    const handleSignIn = async ()=> {

        //console.log('CLICKED ON LOGIN')
        if(username === '') 
        {
            setValidation("Username is missing")

        } else if(password === ''){

            setValidation("Password is missing")
        }

        if(username === '' && password === ''){
            setValidation("Both username and password fields are empty")
        } 
        else if (username != '' && password !='') {

        const {data} = await api.post('/login', {username: username, password: password})
        if(data) {
            
            console.log('RESPONSE IS: ' + data.Username)
            setLogin(false)
        } else {
            setValidation("You are not registered")
            console.log('RESPONSE IS NOTHING')
        }
    }
       
    }

    return (
        <>
            <Grid item lg={8} sx={{borderRadius: '10px',backgroundColor: 'rgba(253, 251, 251,1)',display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: ' 20px 30px', boxShadow: '2px 2px 25px black'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <img src={ComplexLogo}/>
                </Box>
               
                <Box sx={{ display: 'flex', alignItems: 'flex-end'}}>
                    <AccountCircle sx={{ color: 'black', mr: 1, my: 0.5 }} />
                    <TextField 
                        name="usernameValidation" 
                        size="small" 
                        value={username} 
                        onChange={(e)=> setUsername(e.target.value)} 
                        label="Username" 
                        variant="standard"
                        required
                        sx={{ width: '100%'}} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: '20px'}}>
                    <AccountCircle sx={{ color: 'black', mr: 1, my: 0.5 }} />
                    <TextField 
                        name="passwordValidation" 
                        type="password" 
                        size="small" 
                        value={password} 
                        onChange={(e)=> setPassword(e.target.value)} 
                        label="Password" 
                        variant="standard" 
                        required
                        sx={{ width: '100%'}} />
                </Box>
                <Typography sx={{color: 'red', fontSize: '.8rem', my: 4}}>{validation? validation : ''}</Typography>
                {/* <Grid container sx={{my: '20px', display: 'flex', justifyContent: 'space-around', color: 'rgb(122, 122, 122)'}}>
                    <Box component="span">Forgot username?</Box>
                    <Box component="span">Forgot password?</Box>
                </Grid> */}
                <Grid container sx={{my: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'rgb(122, 122, 122)'}}>
                    <Button variant="contained" sx={{background: 'red', width: '100%'}} onClick={handleSignIn}>LOGIN</Button>
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Remember me" />
                    
                </Grid>
                
            </Grid>
        </>
    )
}

export default SignIn
