export const SETUPTYPES_LIST_REQUEST = 'SETUPTYPES_LIST_REQUEST';
export const SETUPTYPES_LIST_SUCCESS = 'SETUPTYPES_LIST_SUCCESS';
export const SETUPTYPES_LIST_FAIL = 'SETUPTYPES_LIST_FAIL'; 

export const SETUPTYPE_CREATE_REQUEST = 'SETUPTYPE_CREATE_REQUEST'
export const SETUPTYPE_CREATE_SUCCESS = 'SETUPTYPE_CREATE_SUCCESS'
export const SETUPTYPE_CREATE_FAIL = 'SETUPTYPE_CREATE_FAIL'


export const SETUPTYPE_UPDATE_REQUEST = 'SETUPTYPE_UPDATE_REQUEST'
export const SETUPTYPE_UPDATE_SUCCESS = 'SETUPTYPE_UPDATE_SUCCESS'
export const SETUPTYPE_UPDATE_FAIL = 'SETUPTYPE_UPDATE_FAIL'

export const SETUPTYPE_DELETE_REQUEST = 'SETUPTYPE_DELETE_REQUEST'
export const SETUPTYPE_DELETE_SUCCESS = 'SETUPTYPE_DELETE_SUCCESS'
export const SETUPTYPE_DELETE_FAIL = 'SETUPTYPE_DELETE_FAIL'

export const VIEW_SETUPTYPE_REQUEST = 'VIEW_SETUPTYPE_REQUEST'
export const VIEW_SETUPTYPE_SUCCESS = 'VIEW_SETUPTYPE_SUCCESS'
export const VIEW_SETUPTYPE_FAIL = 'VIEW_SETUPTYPE_FAIL'