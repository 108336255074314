import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton} from '@mui/material';
import { blueGrey, orange, blue, red, cyan, deepOrange, green } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useDispatch, useSelector } from 'react-redux';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getAssessmentTypesList } from '../../../../../Redux/Actions/AssessmentTypesActions';
import { getAssessmentResultsList } from '../../../../../Redux/Actions/AssessmentResultsActions';
import { getAssessmentStatusesList } from '../../../../../Redux/Actions/AssessmentStatusesActions';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import useAlert from '../../../../../Hooks/useAlert';
import ComplexAlert from '../../../../ComplexAlert';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { getApplicant } from '../../../../../Redux/Actions/ApplicantsActions';
import FormLabel from '../../../../Utilities/FormLabel';
import EditInDialog from '../../../../Utilities/EditInDialog';
import DialogNavigation from '../../../../Utilities/DialogNavigation';



const ViewApplicantDialog = ({open, onClose, type, info, handleSubmit}) => {

    console.log('INFOOO' + info)


    //ACCESS DISPATCH
    const dispatch = useDispatch();
    const id = info;


    //GET APPLICANT
    const applicant_records = useSelector(state => state.applicant_View);
    const {loading, applicant} = applicant_records;
 

    //GET ASSESSMENT TYPE
    const types_ListState = useSelector(state => state.assessmentTypes_List);
    const {assessmentTypes, loading: loading_types} = types_ListState;

    //GET ASSESSMENT RESULT
    const results_ListState = useSelector(state => state.assessmentResults_List);
    const {assessmentResults, loading: loading_results} = results_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;

    //GET ASSESSMENT STATUS
    const statuses_ListState = useSelector(state => state.assessmentStatuses_List);
    const {assessmentStatuses, loading: loading_statuses} = statuses_ListState;

    
    
       
    //CURRENT DATE
    const d = new Date(), yyyy = d.getFullYear(), mm = d.getMonth(), dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getApplicant(id));
        dispatch(getDivisions());
        dispatch(getStaffList());
        dispatch(getAssessmentTypesList());
        dispatch(getAssessmentResultsList());
        dispatch(getAssessmentStatusesList());
       
    }, [])
    

    const [values, setValues] = useState({
            fname: '', 
            surname: '',
            dob: '',
            insurance: '',
            email: '',
            address1: '',
            address2: '',
            postcode: '',
            town: '',
            mobile: '',
            home: '',
            staff: '' , 
            division: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            
 
        }
    }


    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        showAlert('info','You can now edit this task',true)
        setIsEditing(!isEditing);
    }

    //USE ALERT
    const {alert, showAlert} = useAlert();
    
    const animatedComponents = makeAnimated();

    //const workers = ['Emily Bone', 'Shawn Drisic', 'Isaac Phily']
    const workers = [
        {value: 'Emily Bone', label: 'Emily Bone'},
        {value: 'John Cusa', label: 'John Cusa'},
        {value: 'Mah Jani', label: 'Mah Jani'},
    ]
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[800]}} className=""  >
                <Stack direction="row" alignItems="center">
                    <AssessmentIcon sx={{color: blue[300], mr: 2, fontSize: '1.5rem'}}/>
                    <Typography variant="h6" sx={{color: blue[300],fontWeight: 700}}>View Applicant</Typography>
                </Stack>
                <Typography variant="body2" sx={{color: "white",fontWeight: 500, background: blue[700], borderRadius: '5px', px: 2, py: '5px',  boxShadow: '.5px .5px 5px blue'}}>{loading? '' : applicant.Status}</Typography>
            </DialogTitle>

 
        {alert.show?  <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        <Divider/>
        {loading? <LinearProgress sx={{ color: 'green'}} /> : ''}
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">
                <Grid item lg={12} sx={{mr: 5}}>
                    <Grid container justifyContent="space-between">
                        <Typography sx={{fontSize: '1.4rem', fontFamily: 'Poppins-Medium', fontWeight: '800'}}>{loading? '' : applicant.Applicant}</Typography>
                        <EditInDialog isEditing={isEditing} handleEditClick={handleEditClick}/>
                    </Grid>
                </Grid>
                <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                    <DialogNavigation/>
                    
                </Grid>
                    <Grid item lg={10}>
                        <Form className="">
                            
                            <FormLabel title="Basic Information" color="gray"/>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Applicant ID</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled type="text" value={`APL - ${loading? '' : applicant.Id}`} className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">First Name</Form.Label>
                                    <Form.Control name="fname" onChange={handleChange}  disabled={isEditing? false : true} type="text" value={loading? '' : applicant.First_Name} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Surname</Form.Label>
                                    <Form.Control name="surname" disabled={isEditing? false : true} type="text" value={loading? '' : applicant.Surname} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>

                            <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Email</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading? '' : applicant.Email} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Mobile No.</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading? '' : applicant.Mobile} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Home Phone No.</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={loading? '' : applicant.Home_Phone} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                            
                            <Divider sx={{my: 2, color: blue[200]}}/>
                            <FormLabel title="Applicant Particulars" color="blue"/>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Application Date</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : applicant.Application_Date}</Typography>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Division</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             components={animatedComponents}
                                             placeholder="Select division ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>Construction</Typography>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Status</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             value={'In Progress'}
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             isMulti
                                             components={animatedComponents}
                                             placeholder="Select staff ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: green[500], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : applicant.Status}</Typography>
                                    }
                                </Form.Group>

                                
                            </Row>
                            <Divider sx={{my: 2, color: blue[200]}}/>

                            <FormLabel title="Assessment" color="gray"/>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Assessment Status</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             value={'In Progress'}
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             isMulti
                                             components={animatedComponents}
                                             placeholder="Select staff ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : applicant.Status? applicant.Status :  'Status pending' }</Typography>
                                    }
                                </Form.Group>

                                
                            </Row>


                            
                    
                        </Form>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            {/* <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: 'blue', '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Changes</Button> */}
        </DialogActions>
    </Dialog>
    )
}

export default ViewApplicantDialog
