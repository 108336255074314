import { APPLICANTPROFILE_LIST_REQUEST, APPLICANTPROFILE_LIST_SUCCESS, APPLICANTPROFILE_LIST_FAIL, VIEW_APPLICANTPROFILE_FAIL, VIEW_APPLICANTPROFILE_REQUEST, VIEW_APPLICANTPROFILE_SUCCESS, APPLICANTPROFILE_CREATE_FAIL, APPLICANTPROFILE_UPDATE_REQUEST, APPLICANTPROFILE_UPDATE_FAIL, APPLICANTPROFILE_DELETE_REQUEST, APPLICANTPROFILE_DELETE_FAIL, APPLICANTPROFILE_CREATE_REQUEST, APPLICANTPROFILE_CREATE_SUCCESS, APPLICANTPROFILE_UPDATE_SUCCESS, APPLICANTPROFILE_DELETE_SUCCESS } from "../Constants/applicantProfileConstants";

const applicantProfilesListReducer = (state = {applicantProfiles: []}, action) => {
 
    switch (action.type)
    {
        case APPLICANTPROFILE_LIST_REQUEST:
            return {loading: true};
 
        case APPLICANTPROFILE_LIST_SUCCESS:
            console.log('Code is reaching the reducer and applicantProfiles data is:' + (action.payload)[0].Profile_Id)
            return {
                loading: false,
                applicantProfiles: action.payload
            }
        case APPLICANTPROFILE_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const applicantProfileCreateReducer = (state = {applicantProfiles: []}, action) => {

    switch (action.type) {

        case APPLICANTPROFILE_CREATE_REQUEST:
            return {
                loading: true
            }

        case APPLICANTPROFILE_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                applicantProfiles: [...state.applicantProfiles, {Applicant_Id: 2, Name: value}]
            };

        case APPLICANTPROFILE_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const applicantProfileViewReducer = (state= {applicantProfile: {}}, action) => {
    switch(action.type)
    {
        case VIEW_APPLICANTPROFILE_REQUEST:
            return {loading: true};
        case VIEW_APPLICANTPROFILE_SUCCESS:
            return {
                    loading: false,
                    applicant: action.payload
                }
        case VIEW_APPLICANTPROFILE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const applicantProfileUpdateReducer = (state = {applicantProfiles: {}}, action) => {

    switch (action.type) {

        case APPLICANTPROFILE_UPDATE_REQUEST:
            return {
                
            }
        case APPLICANTPROFILE_UPDATE_SUCCESS:

            return {

            }
        case APPLICANTPROFILE_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const applicantProfileDeleteReducer = (state = {applicantProfiles: {}}, action) => {

    switch (action.type) {
        case APPLICANTPROFILE_DELETE_REQUEST:
            return {
                
            }
        case APPLICANTPROFILE_DELETE_SUCCESS:
            return {

            }
        case APPLICANTPROFILE_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {applicantProfilesListReducer, applicantProfileCreateReducer, applicantProfileViewReducer, applicantProfileUpdateReducer, applicantProfileDeleteReducer }