export const TIMESHEETS_LIST_REQUEST = 'TIMESHEETS_LIST_REQUEST'
export const TIMESHEETS_LIST_SUCCESS = 'TIMESHEETS_LIST_SUCCESS'
export const TIMESHEETS_LIST_FAIL = 'TIMESHEETS_LIST_FAIL'


export const TIMESHEET_CREATE_REQUEST = 'TIMESHEET_CREATE_REQUEST'
export const TIMESHEET_CREATE_SUCCESS = 'TIMESHEET_CREATE_SUCCESS'
export const TIMESHEET_CREATE_FAIL = 'TIMESHEET_CREATE_FAIL'


export const TIMESHEET_UPDATE_REQUEST = 'TIMESHEET_UPDATE_REQUEST'
export const TIMESHEET_UPDATE_SUCCESS = 'TIMESHEET_UPDATE_SUCCESS'
export const TIMESHEET_UPDATE_FAIL = 'TIMESHEET_UPDATE_FAIL'

export const TIMESHEET_DELETE_REQUEST = 'TIMESHEET_DELETE_REQUEST'
export const TIMESHEET_DELETE_SUCCESS = 'TIMESHEET_DELETE_SUCCESS'
export const TIMESHEET_DELETE_FAIL = 'TIMESHEET_DELETE_FAIL'

export const VIEW_TIMESHEET_REQUEST = 'VIEW_TIMESHEET_REQUEST'
export const VIEW_TIMESHEET_SUCCESS = 'VIEW_TIMESHEET_SUCCESS'
export const VIEW_TIMESHEET_FAIL = 'VIEW_TIMESHEET_FAIL'