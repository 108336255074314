export const VATSTATUSES_LIST_REQUEST = 'VATSTATUSES_LIST_REQUEST';
export const VATSTATUSES_LIST_SUCCESS = 'VATSTATUSES_LIST_SUCCESS';
export const VATSTATUSES_LIST_FAIL = 'VATSTATUSES_LIST_FAIL'; 

export const VATSTATUS_CREATE_REQUEST = 'VATSTATUS_CREATE_REQUEST'
export const VATSTATUS_CREATE_SUCCESS = 'VATSTATUS_CREATE_SUCCESS'
export const VATSTATUS_CREATE_FAIL = 'VATSTATUS_CREATE_FAIL'


export const VATSTATUS_UPDATE_REQUEST = 'VATSTATUS_UPDATE_REQUEST'
export const VATSTATUS_UPDATE_SUCCESS = 'VATSTATUS_UPDATE_SUCCESS'
export const VATSTATUS_UPDATE_FAIL = 'VATSTATUS_UPDATE_FAIL'

export const VATSTATUS_DELETE_REQUEST = 'VATSTATUS_DELETE_REQUEST'
export const VATSTATUS_DELETE_SUCCESS = 'VATSTATUS_DELETE_SUCCESS'
export const VATSTATUS_DELETE_FAIL = 'VATSTATUS_DELETE_FAIL'

export const VIEW_VATSTATUS_REQUEST = 'VIEW_VATSTATUS_REQUEST'
export const VIEW_VATSTATUS_SUCCESS = 'VIEW_VATSTATUS_SUCCESS'
export const VIEW_VATSTATUS_FAIL = 'VATSTATUS_FAIL'