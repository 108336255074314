import React, {useState, useEffect} from 'react';
import {} from 'react-router-dom'
import {CircularProgress, Typography, Button } from '@mui/material';
import {IconButton, Grid, Divider, Stack} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useDialog from '../../../Hooks/useDialog';
import { blueGrey, blue, lightBlue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import ComplexAlert from '../../../Components/ComplexAlert';
import useAlert from '../../../Hooks/useAlert';
import ScreenHeader from '../../../Components/Header/ScreenHeader';
import AboveTableHeader from '../../../Components/Header/AboveTableHeader';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import Crumbs from '../../../Components/Utilities/Crumbs';
import AddButtonLabel from '../../../Components/Utilities/AddButtonLabel';
import { getClientsList } from '../../../Redux/Actions/ClientsActions';
import ClientDialogs from '../../../Components/Dialogs/CRM/Sales/ClientDialogs/ClientDialogs';
import UsersTbl from '../../../Components/Table/Admin/UsersTbl';

 

const Users = () => { 

    
    const dispatch = useDispatch();
    const {loading, clients, error } = useSelector(state => state.clients_List) //receiving state object
    const [list, setList] = useState([])
    
    useEffect(()=> {
        dispatch(getClientsList());
    }, [])
     
    useEffect(() => {
        if(clients)
        {
            setList([...clients])
        }
    }, [clients])

    
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //TABLE DIALOG FUNCTIONALITY
    
        const handleAddClick = () => {
            console.log('THIS IS WORKING')
            showDialog(true, 'create', '');
        }

    //----------------------------------------------------------------------------------------------------------------------------------
        
    //TODO: CRUD FUNCTIONS
        
    //----------------------------------------------------------------------------------------------------------------------------------
    
    //ALERT FUNCTIONALITY
    const {alert, showAlert} = useAlert();

    //DIALOG FUNCTIONALITY
    const {dialog, showDialog, handleSubmit, closeDialog} = useDialog(showAlert)
    
    //----------------------------------------------------------------------------------------------------------------------------------
     
    return (
        <div>
            
        <ScreenHeader title="Users" subtitle="Manage all system users from here" count={loading? <CircularProgress size={15}  color="primary" />  :`${list.length}`}/>
        { dialog.open ?  <ClientDialogs handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/> : '' }
        <Grid container justifyContent="space-between" sx={{mb: 2}}>
            <Crumbs root="CRM" middle="Admin" end="Users" main/>
            <Stack direction="row" justifyContent="center" alignItems="center">
                <AddButtonLabel item="User"/>
                <IconButton aria-label="delete" sx={{background: blueGrey[900], boxShadow: '2px 2px 9px gray', ml: 2}} onClick={handleAddClick}>
                    <AddIcon fontSize="middle" sx={{color: blue[200]}} /> 
                </IconButton>
            </Stack>
        </Grid>

        <Divider sx={{color: lightBlue[800]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        { loading ?  <HorizontalLoading/> : '' }




        <AboveTableHeader title="Users List"/>
            
        <Grid container>   
            <UsersTbl users={list}/>     
            
        </Grid>
     


     
    </div>
    )
}

export default Users
