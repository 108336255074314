import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress} from '@mui/material';
import { blueGrey, orange, blue, purple, red, cyan, deepOrange } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicantsList } from '../../../../../Redux/Actions/ApplicantsActions';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getAssessmentTypesList } from '../../../../../Redux/Actions/AssessmentTypesActions';
import { getAssessmentResultsList } from '../../../../../Redux/Actions/AssessmentResultsActions';
import { getAssessmentStatusesList } from '../../../../../Redux/Actions/AssessmentStatusesActions';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { submitAssessment } from '../../../../../Redux/Actions/AssessmentsActions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useAlert from '../../../../../Hooks/useAlert';
import ComplexAlert from '../../../../ComplexAlert';
import Select from 'react-select';
import makeAnimated from 'react-select/animated'



const ViewDocumentDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET ASSESSMENTS
    const assessments_ListState = useSelector(state => state.assessments_List);
    const {assessments, loading: loading_assessments} = assessments_ListState;

    //GET DIVISIONS
    const divisions_ListState = useSelector(state => state.divisions_List);
    const {divisions, loading} = divisions_ListState;

    //GET ASSESSMENT TYPE
    const types_ListState = useSelector(state => state.assessmentTypes_List);
    const {assessmentTypes, loading: loading_types} = types_ListState;

    //GET ASSESSMENT RESULT
    const results_ListState = useSelector(state => state.assessmentResults_List);
    const {assessmentResults, loading: loading_results} = results_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;

    //GET ASSESSMENT STATUS
    const statuses_ListState = useSelector(state => state.assessmentStatuses_List);
    const {assessmentStatuses, loading: loading_statuses} = statuses_ListState;

    //GET APPLICANTS
    const applicants_List_State = useSelector((state) => state.applicants_List);
    const {loading: loading_applicants, applicants} = applicants_List_State;
    
       
    //CURRENT DATE
    const d = new Date(), yyyy = d.getFullYear(), mm = d.getMonth(), dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getApplicantsList());
        dispatch(getStaffList());
        dispatch(getAssessmentTypesList());
        dispatch(getAssessmentResultsList());
        dispatch(getAssessmentStatusesList());
       
    }, [])
    

    const [values, setValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            getName(name, value);
            //const the_name = getName(value);
            //setValues({ applicant: the_name })
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            checkForId(name, value); 
 
        }
    }
    const checkForId =  (name, value) => {

        const criteria = ['type', 'status', 'result'];
        criteria.forEach(item => {
            if(item == name)
            {
                const list = name === 'type'? assessmentTypes : name === 'status' ? assessmentStatuses : assessmentResults;
                const name_field = name === 'type'? 'Type_Name' : name === 'status' ? 'Status_Name' : 'Result_Name';
                const id_field = name === 'type'? 'Type_Id' : name === 'status' ? 'Status_Id' : 'Result_Id';

                const object = list.find(type => type[name_field] == value);
                const id = object[id_field];
                console.log(`ID of${value} is: ${id}`)
                
                setSubmitValues({
                    applicant: values.applicant,
                    subject: values.subject,
                    level: values.level,
                    staff: values.staff,
                    ...submitValues,
                    [name]: id
                })
            }
        } )
        
    }

    const getName = async (name, value) => {

        console.log('Reaching getName function and recieved:' + value)
        if(name === 'applicant')
        {
            const wanted = await applicants.find(x => x.Applicant_Id == value);
            console.log('Wanted object is: ' + wanted)
            setApplicantName(`${wanted.First_Name} ${wanted.Surname}`)
        }
        else
        {
        
            const wanted = await staff.find(x => x.Staff_Id == value);
            console.log('Wanted object is: ' + wanted)
            setStaffName(`${wanted.First_Name} ${wanted.Surname}`)

        }
    }
    const handleSave = () => {
        
        dispatch(submitAssessment(submitValues))
        handleSubmit();

    }

    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        showAlert('info','You can now edit this task',true)
        setIsEditing(!isEditing);
    }

    //USE ALERT
    const {alert, showAlert} = useAlert();
    
    const animatedComponents = makeAnimated();

    //const workers = ['Emily Bone', 'Shawn Drisic', 'Isaac Phily']
    const workers = [
        {value: 'Emily Bone', label: 'Emily Bone'},
        {value: 'John Cusa', label: 'John Cusa'},
        {value: 'Mah Jani', label: 'Mah Jani'},
    ]
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" >
            {/* <h3>I am receiving info: {info.Assessment_Subject}</h3> */}
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: cyan[800]}} className=""  >
                <Stack direction="row" alignItems="center">
                    <AssessmentIcon sx={{color: blue[50], mr: 2, fontSize: '1.5rem'}}/>
                    <Typography variant="h6" sx={{color: 'white',fontWeight: 700}}>View Task</Typography>
                </Stack>
                <Typography variant="body2" sx={{color: "white",fontWeight: 500, background: cyan[700], borderRadius: 3, px: 1}}>in Progress</Typography>
            </DialogTitle>

        {alert.show? 
        <ComplexAlert alert={alert} removeAlert={showAlert}/> : ''
        }
        <Divider/>
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">
                    <Grid item lg={9}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[800], borderRadius: '5px', px: 1}}>Basic Information</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Task ID</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled type="text" value={`TSK - ${info.Assessments_Id}`} className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Task Date</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.assessment_date} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Task Deadline</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.assessment_date} placeholder="E.g 123qst1" className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                        
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="mb-0 p-0 view-label">Task Description</Form.Label>
                                    <Form.Control 
                                        onChange={handleChange} 
                                        name="subject" 
                                        disabled={isEditing? false : true} 
                                        type="email" 
                                        value={'Hard coded subject'} 
                                        className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                            </Row>
                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: purple[500], borderRadius: '5px', px: 1,}}>Task Particulars</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Task Type</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             value={'Pending'}
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             isMulti
                                             components={animatedComponents}
                                             placeholder="Select type ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>Type</Typography>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Task Division</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             components={animatedComponents}
                                             placeholder="Select division ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>Construction</Typography>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Task Status</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             value={'In Progress'}
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             isMulti
                                             components={animatedComponents}
                                             placeholder="Select staff ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>In Progress</Typography>
                                    }
                                </Form.Group>
                                
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                        <Form.Label className="view-label mb-3">Client</Form.Label>
                                        <br/>
                                        {
                                            isEditing? 
                                            <Select 
                                                value={'In Progress'}
                                                options={workers}
                                                closeMenuOnSelect ={false}
                                                isMulti
                                                components={animatedComponents}
                                                placeholder="Select division ..."
                                            />  :
                                        <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>BMC </Typography>
                                        }
                                </Form.Group>
                            </Row>

                            <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: deepOrange[500], borderRadius: '5px', px: 1}}>Individuals on task</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Task Assignee</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             value={workers[0]}
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             isMulti
                                             components={animatedComponents}
                                             placeholder="Select staff ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Billy Baker</Typography>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label mb-3">Workers Involved</Form.Label>
                                    <br/>
                                    {
                                        isEditing? 
                                         <Select 
                                             value={workers[0]}
                                             options={workers}
                                             closeMenuOnSelect ={false}
                                             isMulti
                                             components={animatedComponents}
                                             placeholder="Select workers ..."
                                         />  :
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(163, 163, 163)', color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>Shawn Michael</Typography>
                                    }
                                </Form.Group>
                                
                            </Row>
                           
                            
                           
                            <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: cyan[700], borderRadius: '5px', px: 1,}}>Task Notes</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="mb-0 p-0 view-label">Task Notes</Form.Label>
                                    <Form.Control 
                                        onChange={handleChange} 
                                        name="subject" 
                                        disabled={isEditing? false : true} 
                                        as="textarea" 
                                        value={'Hard coded notes'} 
                                        className={isEditing? "" :"border-0  autofocus"} />
                                </Form.Group>
                                
                                
                            </Row>
                        </Form>
                    </Grid>

                    <Grid item lg={2} sx={{mr: 5}}>
                        <Stack sx={{mt: 3}} alignItems="center">
                            <Typography sx={{textAlign: 'center', mb: 3, color: 'gray'}}>Actions</Typography>
                            <Button variant="outlined" size="small" onClick={handleEditClick} endIcon={isEditing? <CloseIcon fontSize="small"/> : <EditIcon fontSize="small"/>} sx={{mb: 1,fontSize: '.7rem', color: isEditing? 'white' : '', border: '1px solid #80deea', background: isEditing? cyan[700] : cyan[50], width: '75%', boxShadow: 'none', px: 0}}>{isEditing? 'Cancel' : 'Edit'}</Button>
                            <Button variant="outlined" size="small" endIcon={<DeleteIcon fontSize="small" sx={{color: 'red[500]'}}/>} sx={{mb: 1, fontSize: '.7rem', color: '', border: '1px solid #80deea', background: red[50], width: '75%', boxShadow: 'none'}}>Delete</Button>

                        </Stack>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            {/* <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: 'blue', '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Changes</Button> */}
        </DialogActions>
    </Dialog>
    )
}

export default ViewDocumentDialog
