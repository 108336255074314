import React from 'react';
import ViewContactDialog from './ViewContactDialog';
import DeleteContactDialog from './DeleteContactDialog';
import AddContactDialog from './AddContactDialog';



const ContactDialogs = ({open, onClose, type, info, handleSubmit}) => {

 
    return (
        <>
        {
            type === "create"?

            <AddContactDialog open={open} onClose={onClose}  handleSubmit={handleSubmit} fullWidth />
            
            : type === "view"?

            <ViewContactDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />

            : type === "delete"?

            <DeleteContactDialog open={open} onClose={onClose} info={info} handleSubmit={handleSubmit} fullWidth />
            // <ViewTaskDialog handleSubmit={handleSubmit} open={dialog.open} onClose={closeDialog} {...dialog}/>
            :
        ''}
        
        
    </>
    )

}

export default ContactDialogs
