export const INVOICESTATUSES_LIST_REQUEST = 'INVOICESTATUSES_LIST_REQUEST';
export const INVOICESTATUSES_LIST_SUCCESS = 'INVOICESTATUSES_LIST_SUCCESS';
export const INVOICESTATUSES_LIST_FAIL = 'INVOICESTATUSES_LIST_FAIL'; 

export const INVOICESTATUS_CREATE_REQUEST = 'INVOICESTATUS_CREATE_REQUEST'
export const INVOICESTATUS_CREATE_SUCCESS = 'INVOICESTATUS_CREATE_SUCCESS'
export const INVOICESTATUS_CREATE_FAIL = 'INVOICESTATUS_CREATE_FAIL'


export const INVOICESTATUS_UPDATE_REQUEST = 'INVOICESTATUS_UPDATE_REQUEST'
export const INVOICESTATUS_UPDATE_SUCCESS = 'INVOICESTATUS_UPDATE_SUCCESS'
export const INVOICESTATUS_UPDATE_FAIL = 'INVOICESTATUS_UPDATE_FAIL'

export const INVOICESTATUS_DELETE_REQUEST = 'INVOICESTATUS_DELETE_REQUEST'
export const INVOICESTATUS_DELETE_SUCCESS = 'INVOICESTATUS_DELETE_SUCCESS'
export const INVOICESTATUS_DELETE_FAIL = 'INVOICESTATUS_DELETE_FAIL'

export const VIEW_INVOICESTATUS_REQUEST = 'VIEW_INVOICESTATUS_REQUEST'
export const VIEW_INVOICESTATUS_SUCCESS = 'VIEW_INVOICESTATUS_SUCCESS'
export const VIEW_INVOICESTATUS_FAIL = 'INVOICESTATUS_FAIL'