import { SHIFTSTATUSES_LIST_REQUEST, SHIFTSTATUSES_LIST_SUCCESS, SHIFTSTATUSES_LIST_FAIL, VIEW_SHIFTSTATUS_FAIL, VIEW_SHIFTSTATUS_REQUEST, VIEW_SHIFTSTATUS_SUCCESS, SHIFTSTATUS_CREATE_FAIL, SHIFTSTATUS_UPDATE_REQUEST, SHIFTSTATUS_UPDATE_FAIL, SHIFTSTATUS_DELETE_REQUEST, SHIFTSTATUS_DELETE_FAIL, SHIFTSTATUS_CREATE_REQUEST, SHIFTSTATUS_CREATE_SUCCESS, SHIFTSTATUS_UPDATE_SUCCESS, SHIFTSTATUS_DELETE_SUCCESS } from "../Constants/shiftStatusConstants";

const shiftStatusesListReducer = (state = {shiftStatuses: []}, action) => {

    switch (action.type)
    {
        case SHIFTSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case SHIFTSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and shiftStatuss data is:' + action.payload)
            return {
                loading: false,
                shiftStatuses: action.payload
            }
        case SHIFTSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const shiftStatusCreateReducer = (state = {shiftStatuses: []}, action) => {

    switch (action.type) {

        case SHIFTSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case SHIFTSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                shiftStatuses: [...state.shiftStatuses, {Applicant_Id: 2, Name: value}]
            };

        case SHIFTSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const shiftStatusViewReducer = (state= {shiftStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_SHIFTSTATUS_REQUEST:
            return {loading: true};
        case VIEW_SHIFTSTATUS_SUCCESS:
            return {
                    loading: false,
                    shiftStatus: action.payload
                }
        case VIEW_SHIFTSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const shiftStatusUpdateReducer = (state = {shiftStatuss: {}}, action) => {

    switch (action.type) {

        case SHIFTSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case SHIFTSTATUS_UPDATE_SUCCESS:

            return {

            }
        case SHIFTSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const shiftStatusDeleteReducer = (state = {shiftStatuss: {}}, action) => {

    switch (action.type) {
        case SHIFTSTATUS_DELETE_REQUEST:
            return {
                
            }
        case SHIFTSTATUS_DELETE_SUCCESS:
            return {

            }
        case SHIFTSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {shiftStatusesListReducer, shiftStatusCreateReducer, shiftStatusViewReducer, shiftStatusUpdateReducer, shiftStatusDeleteReducer }