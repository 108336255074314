import { DIVISIONS_LIST_REQUEST, DIVISIONS_LIST_SUCCESS, DIVISIONS_LIST_FAIL, DIVISIONS_CREATE, DIVISIONS_CREATE_SUCCESS, DIVISIONS_CREATE_FAIL, DIVISIONS_CREATE_REQUEST, VIEW_DIVISION_REQUEST, VIEW_DIVISION_SUCCESS, VIEW_DIVISION_FAIL } from "../Constants/divisionConstants";
import api from '../../config/apiConfig';

const getDivisions = () => async (dispatch) => { 
    

    try {

        dispatch({type: DIVISIONS_LIST_REQUEST});
        const {data} = await api.get('/divisions');
        console.log('DIIIIIIVS:' + data);
        dispatch({type: DIVISIONS_LIST_SUCCESS, payload: data})
        
    } catch (error) {
        dispatch({type: DIVISIONS_LIST_FAIL, payload: error.msg})
    }
} 

const submitDivision = (value) => async (dispatch) => {
    try {
    
        console.log('IN ACTION, HEY HEY, VALUE IS: ' + value)
        dispatch({type: DIVISIONS_CREATE_REQUEST}) 
        const {data} = await api.post('/divisions/', {newDivision: value});
        //console.log('From backend i am getting: ' + div.data.insertId) 
        console.log('From backend i am getting: ' + Object.getOwnPropertyNames(data)) 
        dispatch({type: DIVISIONS_CREATE_SUCCESS, payload: value})
        
    } catch (error) {
        dispatch({type: DIVISIONS_CREATE_FAIL, payload: error.message })
    }
} 

const getDivision = (id) => async (dispatch) => {
    try {
    
        dispatch({type: VIEW_DIVISION_REQUEST})
        const {data} = await api.get(`/divisions/ ${id}`);
       
        console.log('Received object: ' + data)
        dispatch({type: VIEW_DIVISION_SUCCESS, payload: data})

    } catch (error) {
        dispatch({type: VIEW_DIVISION_FAIL, payload: error.message })
    }
}

const editDivision = (id, name) => async (dispatch) => {
    try {
    
        dispatch({type: VIEW_DIVISION_REQUEST})
        const {data} = await api.put('/divisions/', {id, name});
       
        console.log('Received object: ' + data)
        dispatch({type: VIEW_DIVISION_SUCCESS, payload: {Division_Id: id, Name: name}})

    } catch (error) {
        dispatch({type: VIEW_DIVISION_FAIL, payload: error.message })
    }
}

const deleteDivision = (id) => async (dispatch) => {
    try {
    
        dispatch({type: VIEW_DIVISION_REQUEST})
        const {data} = await api.delete(`/divisions/ ${id}`);
       
        console.log('Received object: ' + data)
        dispatch({type: VIEW_DIVISION_SUCCESS, payload: data})

    } catch (error) {
        dispatch({type: VIEW_DIVISION_FAIL, payload: error.message })
    }
}

export {getDivisions, submitDivision, getDivision, editDivision, deleteDivision}