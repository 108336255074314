import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress, IconButton, Chip, Menu, MenuItem, TextField, FormControlLabel, Checkbox} from '@mui/material';
import { blueGrey, blue, purple, deepPurple, red } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import TaskIcon from '@mui/icons-material/Task';
import { useDispatch, useSelector } from 'react-redux';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as ReactPicker } from '@mui/x-date-pickers/DatePicker';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getClientsList } from '../../../../../Redux/Actions/ClientsActions';
import { getTaskStatusesList } from '../../../../../Redux/Actions/TaskStatusActions';
import { getWorkers } from '../../../../../Redux/Actions/WorkerActions';
import { getTaskTypesList } from '../../../../../Redux/Actions/TaskTypeActions';
import useSelectInput from '../../../../../Hooks/useSelectInput';
import SideCrumbs from '../../../../Utilities/SideCrumbs';
import ComplexAlert from '../../../../ComplexAlert';
import useAlert from '../../../../../Hooks/useAlert';
import SummaryBox from '../../../../Utilities/SummaryBox';
import { submitVATReturn } from '../../../../../Redux/Actions/VATReturnActions';



const AddVATReturnDialog = ({open, onClose, type, info, handleSubmit}) => {

    //ACCESS DISPATCH
    const dispatch = useDispatch();
    //GET TASKS
    const {tasks, loading: loading_tasks} = useSelector(state => state.tasks_List);
    const {divisions, loading} = useSelector(state => state.divisions_List);
    const {alert, showAlert}= useAlert();

   
    
       
    //CURRENT DATE
    const d = new Date(),  yyyy = d.getFullYear(),  mm = d.getMonth(),  dd = d.getDay()
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getStaffList());
        dispatch(getClientsList());
        dispatch(getTaskStatusesList());
        dispatch(getTaskTypesList());
        dispatch(getWorkers());
       
    }, [])
    
    

    const [values, setValues] = useState({
            name: '', 
            frequency: '', 
            start_date: '', 
            end_date: '', 
            box1: '', 
            box2: '', 
            box3: '' , 
            box4: '' , 
            box5: '' , 
            box6: '', 
            box7: '', 
            box8: '', 
            status: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            name: '', 
            frequency: '', 
            start_date: '', 
            end_date: '', 
            box1: '', 
            box2: '', 
            box3: '' , 
            box4: '' , 
            box5: '' , 
            box6: '', 
            box7: '', 
            box8: '', 
            status: '', 
        });
    
    const handleSave = () => {
        console.log('in save function')
        dispatch(submitVATReturn(values, submitValues))
        handleSubmit('success',"Successfully saved VAT Return information");

    }
    const [date, setDate] = useState({});
    const handleDateChange = (newValue, name) => {
        
        const day = newValue.getDate();
        const month = newValue.getMonth();
        const year = newValue.getFullYear();
        setDate({
            ...date,
            [name]: newValue
        });
        setValues({
            ...values,
            [name]: `${day}/${month+1}/${year}`
        })
        setSubmitValues({
            ...submitValues,
            [name]: `${day}/${month+1}/${year}`
        })
    }
    
    
    const [inputValue, setInputValue] = useState('');
    
    const handleInputChange = (newValue) => {
        
        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }
    const {loadOptions, handleOptionChange, handleChange} = useSelectInput(inputValue, values, setValues, submitValues, setSubmitValues)
 
    

   


    const animatedComponents = makeAnimated();


    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >

            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between',alignItems: 'center',fontSize: '1.3rem', background: blue[800], fontWeight: 900, color: 'white'}}  > 
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    <TaskIcon fontSize="large" sx={{color: blue[500], mr: 2, fontSize: '1.5rem'}}/> 
                    <Typography variant="h6" sx={{color: blue[500],fontWeight: 700, fontFamily: 'Poppins'}}> Add VAT Return </Typography>
                </Stack>
            </DialogTitle>

        <Divider sx={{color: blue[500]}}/>
        {  alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        {
            loading? <LinearProgress sx={{ color: 'green'}} /> : ''
        }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="space-evenly" sx={{position: 'relative', top: 0}}>
                    <SummaryBox values={values}/>
                    {/* <p>name {submitValues.name}</p>
                    <p>group {submitValues.group}</p>
                    <p>worker {submitValues.worker}</p>
                    <p>staff {submitValues.staff}</p>
                    <p>startdate{submitValues.start}</p>
                    <p>endate {submitValues.end}</p>
                    <p>days_taken{submitValues.days_taken}</p>
                    <p>days_left {submitValues.days_left}</p>
                    <p>approved {submitValues.approved_by}</p>
                    <p>status {submitValues.status}</p> */}
                    <SideCrumbs/>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>

                       
                    
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">VAT Name</Form.Label>
                                <Form.Control type="text"  name="name" value={values.name} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Return Frequency</Form.Label>
                                    <AsyncSelect
                                        value={values.frequency}
                                        name="frequency"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'vatreturnfrequency')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for frequency ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                        <Row>
                        <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="start_date"
                                            label="Start Date"
                                            value={date.start_date}
                                            onChange={(e) => handleDateChange(e, "start_date")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <br/>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <ReactPicker
                                            name="end_date"
                                            label="End Date"
                                            value={date.end_date}
                                            onChange={(e) => handleDateChange(e, "end_date")}
                                            renderInput={(params) => <TextField size="small" variant="outlined" sx={{background:' none', color: "white", width: '100%'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                            </Form.Group>
                        </Row>

                        <Divider sx={{my: 2, color: blue[500]}}/>
                        
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Box 1</Form.Label>
                                <Form.Control type="text" name="box1" value={values.box1} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Box 2</Form.Label>
                                <Form.Control type="text" name="box2" value={values.box2} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Box 3</Form.Label>
                                <Form.Control type="text" name="box3" value={values.box3} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Box 4</Form.Label>
                                <Form.Control type="text" name="box4" value={values.box4} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Box 5</Form.Label>
                                <Form.Control type="text" name="box5" value={values.box5} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Box 6</Form.Label>
                                <Form.Control type="text" name="box6" value={values.box6} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Box 7</Form.Label>
                                <Form.Control type="text" name="box7" value={values.box7} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Box 8</Form.Label>
                                <Form.Control type="text" name="box8" value={values.box8} onChange={handleChange} placeholder="..." className="outline-none border-none" />
                            </Form.Group>
                        </Row>
                        <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: deepPurple[500], borderRadius: '5px', px: 2, py: '5px'}}>Items</Typography>
                        <Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                <Form.Label className="view-label">Status</Form.Label>
                                    <AsyncSelect
                                        value={values.status}
                                        name="status"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'vatstatuses')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for status ..."
                                        onInputChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Row>
                          
                        </Form>
                    </Grid>
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: blue[700], '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save VAT Return</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddVATReturnDialog
