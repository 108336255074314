import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDivision, deleteDivision, editDivision } from '../../../Redux/Actions/DivisionActions';
import { Typography, Box, LinearProgress , Button, Grid, Divider} from '@mui/material';
import { blue, lightBlue } from '@mui/material/colors';
import { useParams } from 'react-router';
import { Form, Col } from 'react-bootstrap';
import PageHeader from '../../../Components/PageHeader';
import Crumbs from '../../../Components/Utilities/Crumbs';
import ViewTitle from '../../../Components/Utilities/ViewTitle';
import HorizontalLoading from '../../../Components/Utilities/HorizontalLoading';
import CRUD from '../../../Components/Utilities/CRUD';
import useCRUD from '../../../Hooks/useCRUD';
import FormHeading from '../../../Components/Utilities/FormHeading';
import ComplexAlert from '../../../Components/ComplexAlert';

 
const ViewDivision = ({dept}) => {

    //GET ID FROM ROUTE PRAMAS
    const {id} = useParams();

    //INSTANTIATING SOME METHODS
    const dispatch = useDispatch();
    const {isEditing, handleCreate, handleEdit, handleDelete, setIsEditing, handlePrint, alert, showAlert, dialog, showDialog, handleSubmit, closeDialog } = useCRUD();

    const {loading, division} = useSelector(state => state.division_View)

    const [value, setValue] = useState('')

    useEffect(() => {
        dispatch(getDivision(id))
    }, [])
    
    useEffect(()=> {
    
        if(division)
        {
            setValue(division.Name)
        }
        
    }, [division])

    const handleChange = (e) => {
        console.log('I am changing something')
        const {value} = e.target;
        setValue(value)
    }

    const handleSaveChanges = (e) => {

        e.preventDefault()
        if(value)
        {
            dispatch(editDivision(division.Division_Id, value))
            setIsEditing(false)
            showAlert('info', 'Your changes have been saved successfully', true);

        }
        else{
            console.log('The field should not be empty')
        }

    } 
    
    return (
        <>
            <PageHeader page="Division Details" previous tasks />
            <Crumbs root="CRM" middle="divisions" end="Details"/>
            <ViewTitle first={value}/>
            { loading ?  <HorizontalLoading/> : '' }
            <Grid container justifyContent="flex-end" alignItems= "center"  sx={{mt: 2, mb: 2, px:0, borderRadius: '20px', color: 'gray', background: ''}}>
                <CRUD isEditing={isEditing} handleCreate={handleCreate} handleEdit={handleEdit} handleSaveChanges={handleSaveChanges} handleDelete={handleDelete} setIsEditing={setIsEditing} showAlert ={showAlert } handlePrint/>
            </Grid>
            <Divider sx={{color: lightBlue[800]}}/>
            {alert.show? <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
          
            <Grid container flexDirection="column" sx={{mt: 3, mb: 1, background: 'white', borderRadius: '5px',p: 3, border: '1px solid #e1f5fe'}}>
                <FormHeading title="Basic Details" badge="Info" icon=""/>
                <Form.Group as={Col} controlId="formGridEmail" className="border-0 rounded p-1">
                    <Form.Label className="view-label">Name</Form.Label>
                    <Form.Control onChange={handleChange}  autoFocus={isEditing? true : false} disabled={isEditing? false : true} value={value} className={isEditing? "" :"border-0  autofocus"}/>
                </Form.Group>
            </Grid>
            
          
        </>
    )
}

export default ViewDivision
