import { BOOKINGSTATUSES_LIST_REQUEST, BOOKINGSTATUSES_LIST_SUCCESS, BOOKINGSTATUSES_LIST_FAIL, VIEW_BOOKINGSTATUS_FAIL, VIEW_BOOKINGSTATUS_REQUEST, VIEW_BOOKINGSTATUS_SUCCESS, BOOKINGSTATUS_CREATE_FAIL, BOOKINGSTATUS_UPDATE_REQUEST, BOOKINGSTATUS_UPDATE_FAIL, BOOKINGSTATUS_DELETE_REQUEST, BOOKINGSTATUS_DELETE_FAIL, BOOKINGSTATUS_CREATE_REQUEST, BOOKINGSTATUS_CREATE_SUCCESS, BOOKINGSTATUS_UPDATE_SUCCESS, BOOKINGSTATUS_DELETE_SUCCESS } from "../Constants/bookingStatusConstants";

const bookingStatusesListReducer = (state = {bookingStatuses: []}, action) => {

    switch (action.type)
    {
        case BOOKINGSTATUSES_LIST_REQUEST:
            return {loading: true};
 
        case BOOKINGSTATUSES_LIST_SUCCESS:
            //console.log('Code is reaching the reducer and bookingStatuss data is:' + action.payload)
            return {
                loading: false,
                bookingStatuses: action.payload
            }
        case BOOKINGSTATUSES_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}
 
const bookingStatusCreateReducer = (state = {bookingStatuses: []}, action) => {

    switch (action.type) {

        case BOOKINGSTATUS_CREATE_REQUEST:
            return {
                loading: true
            }

        case BOOKINGSTATUS_CREATE_SUCCESS: 
        //console.log('Previous divisions state from reducer: ' + state.divisions);
        const value = action.payload;
            return {
                loading: false,
                bookingStatuses: [...state.bookingStatuses, {Applicant_Id: 2, Name: value}]
            };

        case BOOKINGSTATUS_CREATE_FAIL:
            return {
                error: action.payload
            }
        default:
            return state;
    }
}

const bookingStatusViewReducer = (state= {bookingStatus: {}}, action) => {
    switch(action.type)
    {
        case VIEW_BOOKINGSTATUS_REQUEST:
            return {loading: true};
        case VIEW_BOOKINGSTATUS_SUCCESS:
            return {
                    loading: false,
                    bookingStatus: action.payload
                }
        case VIEW_BOOKINGSTATUS_FAIL:
            return {
                error: action.payload
            }
        default:
            return state
    }
}

const bookingStatusUpdateReducer = (state = {bookingStatuss: {}}, action) => {

    switch (action.type) {

        case BOOKINGSTATUS_UPDATE_REQUEST:
            return {
                
            }
        case BOOKINGSTATUS_UPDATE_SUCCESS:

            return {

            }
        case BOOKINGSTATUS_UPDATE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}
const bookingStatusDeleteReducer = (state = {bookingStatuss: {}}, action) => {

    switch (action.type) {
        case BOOKINGSTATUS_DELETE_REQUEST:
            return {
                
            }
        case BOOKINGSTATUS_DELETE_SUCCESS:
            return {

            }
        case BOOKINGSTATUS_DELETE_FAIL:
            return {

            }
    
        default:
           return state;
    }
}


export {bookingStatusesListReducer, bookingStatusCreateReducer, bookingStatusViewReducer, bookingStatusUpdateReducer, bookingStatusDeleteReducer }