import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Paper, Stack, LinearProgress} from '@mui/material';
import { blueGrey, orange, blue, purple, red } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicantsList } from '../../../../../Redux/Actions/ApplicantsActions';
import { getAssessmentTypesList } from '../../../../../Redux/Actions/AssessmentTypesActions';
import { getAssessmentResultsList } from '../../../../../Redux/Actions/AssessmentResultsActions';
import { getAssessmentStatusesList } from '../../../../../Redux/Actions/AssessmentStatusesActions';
import { getStaffList } from '../../../../../Redux/Actions/StaffActions';
import { submitAssessment, getAssessment } from '../../../../../Redux/Actions/AssessmentsActions';
import ComplexAlert from '../../../../ComplexAlert';
import useAlert from '../../../../../Hooks/useAlert';
import DialogNavigation from '../../../../Utilities/DialogNavigation';
import EditInDialog from '../../../../Utilities/EditInDialog';


 
const ViewAssessmentDialog = ({open, onClose, type, info, handleSubmit}) => {

    const id = info;
    console.log('IDDD:' + id)

    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET ASSESSMENTS
    const assessment_Record = useSelector(state => state.assessment_View);
    const {assessment, loading} = assessment_Record;

   
    //GET ASSESSMENT TYPE
    const types_ListState = useSelector(state => state.assessmentTypes_List);
    const {assessmentTypes, loading: loading_types} = types_ListState;

    //GET ASSESSMENT RESULT
    const results_ListState = useSelector(state => state.assessmentResults_List);
    const {assessmentResults, loading: loading_results} = results_ListState;

    //GET OFFICE STAFF AS CONSULTANT
    const staff_ListState = useSelector(state => state.staff_List);
    const {staff, loading: loading_staff} = staff_ListState;

    //GET ASSESSMENT STATUS
    const statuses_ListState = useSelector(state => state.assessmentStatuses_List);
    const {assessmentStatuses, loading: loading_statuses} = statuses_ListState;

    //GET APPLICANTS
    const applicants_List_State = useSelector((state) => state.applicants_List);
    const {loading: loading_applicants, applicants} = applicants_List_State;
    
       
    //CURRENT DATE
    const d = new Date(), yyyy = d.getFullYear(), mm = d.getMonth(), dd = d.getDay()
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getAssessment(id));
        dispatch(getApplicantsList()); 
        dispatch(getStaffList());
        dispatch(getAssessmentTypesList());
        dispatch(getAssessmentResultsList());
        dispatch(getAssessmentStatusesList());
       
    }, [])
    

    const [values, setValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            assessment_date: current_date, 
            subject: '',
            applicant: '' , 
            staff: '' , 
            division: '', 
            type: '', 
            level: '', 
            result: '', 
            status: '', 
        });

    const [applicantName, setApplicantName] = useState();
    const [staffName, setStaffName] = useState();

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log('Clicked on field with name: ' + name)
        
        if(name == 'applicant' || name == 'staff' )
        {
            setValues({
                ...values,
                [name]: value
            });
            getName(name, value);
            //const the_name = getName(value);
            //setValues({ applicant: the_name })
        }
        else
        {
            setValues({
                ...values,
                [name]: value
            });
            checkForId(name, value); 
 
        }
    }
    const checkForId =  (name, value) => {

        const criteria = ['type', 'status', 'result'];
        criteria.forEach(item => {
            if(item == name)
            {
                const list = name === 'type'? assessmentTypes : name === 'status' ? assessmentStatuses : assessmentResults;
                const name_field = name === 'type'? 'Type_Name' : name === 'status' ? 'Status_Name' : 'Result_Name';
                const id_field = name === 'type'? 'Type_Id' : name === 'status' ? 'Status_Id' : 'Result_Id';

                const object = list.find(type => type[name_field] == value);
                const id = object[id_field];
                console.log(`ID of${value} is: ${id}`)
                
                setSubmitValues({
                    applicant: values.applicant,
                    subject: values.subject,
                    level: values.level,
                    staff: values.staff,
                    ...submitValues,
                    [name]: id
                })
            }
        } )
        
    }

    const getName = async (name, value) => {

        console.log('Reaching getName function and recieved:' + value)
        if(name === 'applicant')
        {
            const wanted = await applicants.find(x => x.Applicant_Id == value);
            console.log('Wanted object is: ' + wanted)
            setApplicantName(`${wanted.First_Name} ${wanted.Surname}`)
        }
        else
        {
        
            const wanted = await staff.find(x => x.Staff_Id == value);
            console.log('Wanted object is: ' + wanted)
            setStaffName(`${wanted.First_Name} ${wanted.Surname}`)

        }
    }
    const handleSave = () => {
        
        dispatch(submitAssessment(submitValues))
        handleSubmit();

    }

    const {alert, showAlert} = useAlert();
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        showAlert('info','You can now edit this assessment',true)
        setIsEditing(!isEditing);
    }
    
    
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" >
            {/* <h3>I am receiving info: {info.Assessment_Subject}</h3> */}
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontSize: '1.3rem', fontWeight: 900, color: 'white', background: blue[800]}}  >
                <Stack direction="row">
                    <AssessmentIcon sx={{color: blue[300], mr: 2, fontSize: '1.5rem'}}/>
                    <Typography variant="h6" sx={{color: blue[300],fontWeight: 700}}>{'Viewing assessment'}</Typography>
                </Stack>
                <Typography variant="body2" sx={{color: "white",fontWeight: 500, background: blue[700], borderRadius: '5px', px: 2, py: '5px',  boxShadow: '.5px .5px 5px blue'}}>{loading? '' : assessment.Status}</Typography>
            </DialogTitle>
    
        {alert.show?  <ComplexAlert alert={alert} removeAlert={showAlert}/> : '' }
        <Divider/>
        { loading? <LinearProgress sx={{ color: 'green'}} /> : '' }
        
        <DialogContent sx={{background: 'white'}}>
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">
                <Grid item lg={12} justifyContent="space-between">
                    <Grid container justifyContent="space-between">
                        <Stack direction="column">
                            <Typography sx={{display: 'inline',fontSize: '.9rem', fontFamily: 'Poppins', fontWeight: '800', color: blueGrey[300] }}>Title</Typography>
                            <Typography sx={{fontSize: '1.4rem', fontFamily: 'Poppins-Medium', fontWeight: '800'}}>{loading? '' : `${assessment.Applicant} | ${assessment.Date} | ${assessment.Time} `}</Typography>
                        </Stack>
                        <EditInDialog isEditing={isEditing} handleEditClick={handleEditClick}/>
                    </Grid>
                </Grid>
                <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                    <DialogNavigation/>
                </Grid>
                    <Grid item lg={10}>
                        <Form className="">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[500], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>
                            <Row >
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Assessment ID</Form.Label>
                                    <Form.Control onChange={handleChange} name="id" autoFocus="true" disabled={isEditing? false : true} type="text" value={`ASS - ${loading? '' : assessment.Id}`} className={isEditing? "" :"border-0 autofocus"} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Assessment Date</Form.Label>
                                    <Form.Control name="assessment_date" disabled={isEditing? false : true} type="text" value={values.assessment_date} placeholder="E.g 123qst1" className={isEditing? "" :"border-0 autofocus"} />
                                </Form.Group>
                            </Row>
                            <Row >
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Assessment Type</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : assessment.Type}</Typography>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Assessment Subject</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : assessment.Subject}</Typography>
                                </Form.Group>
                            </Row>
                            <Divider sx={{my: 2, color: blue[200]}}/>
                            <Row >
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Related Applicant</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: orange[500], color: 'white', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : assessment.Applicant}</Typography>
                                        
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Office Staff</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline',background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : assessment.Staff}</Typography>
                                        
                                </Form.Group>
                               
                            </Row>
                            
                            <Row>
                                 <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Applicant Level</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : assessment.Level}</Typography>
                                </Form.Group>
                            </Row>
                            
                            <Divider sx={{my: 2, color: blue[200]}}/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blueGrey[800], borderRadius: '5px', px: 2, py: '5px'}}>Applicant Decision</Typography>
                            <Row >
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Assessment Result</Form.Label>
                                    <br/>
                                    <Typography variant="body2" sx={{position: 'relative',display: 'inline', background: 'rgb(242, 247, 252)', color: 'black', borderRadius: 1, px: '7px', py: '5px'}}>{loading? '' : assessment.Result}</Typography>
                                </Form.Group>

                                
                            </Row>
                        </Form>
                    </Grid>

                    
                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            {/* <Button size="small" sx={{color: 'white', fontSize: '.7rem' ,fontWeight: 400, background: 'blue', '&:hover': {color: blueGrey[900]}}} onClick={handleSave}>Save Changes</Button> */}
        </DialogActions>
    </Dialog>
    )
}

export default ViewAssessmentDialog
