export const APPLICANTS_LIST_REQUEST = 'APPLICANTS_LIST_REQUEST'
export const APPLICANTS_LIST_SUCCESS = 'APPLICANTS_LIST_SUCCESS'
export const APPLICANTS_LIST_FAIL = 'APPLICANTS_LIST_FAIL'

export const VIEW_APPLICANT_REQUEST = 'VIEW_APPLICANT_REQUEST'
export const VIEW_APPLICANT_SUCCESS = 'VIEW_APPLICANT_SUCCESS'
export const VIEW_APPLICANT_FAIL = 'VIEW_APPLICANT_FAIL'

export const APPLICANT_CREATE_REQUEST = 'APPLICANT_CREATE_REQUEST'
export const APPLICANT_CREATE_SUCCESS = 'APPLICANT_CREATE_SUCCESS'
export const APPLICANT_CREATE_FAIL = 'APPLICANT_CREATE_FAIL'

export const APPLICANT_UPDATE_REQUEST = 'APPLICANT_UPDATE_REQUEST'
export const APPLICANT_UPDATE_SUCCESS = 'APPLICANT_UPDATE_SUCCESS'
export const APPLICANT_UPDATE_FAIL = 'APPLICANT_UPDATE_FAIL'

export const APPLICANT_DELETE_REQUEST = 'APPLICANT_DELETE_REQUEST'
export const APPLICANT_DELETE_SUCCESS = 'APPLICANT_DELETE_SUCCESS'
export const APPLICANT_DELETE_FAIL = 'APPLICANT_DELETE_FAIL'