import { APPLICANTPROSPECT_LIST_REQUEST, APPLICANTPROSPECT_LIST_SUCCESS, APPLICANTPROSPECT_LIST_FAIL} from "../Constants/applicantProspectConstants";

const applicantProspectsListReducer = (state = {applicantProspects: []}, action) => {
 
    switch (action.type)
    {
        case APPLICANTPROSPECT_LIST_REQUEST:
            return {loading: true};
 
        case APPLICANTPROSPECT_LIST_SUCCESS:
            console.log('Code is reaching the reducer and applicantProspects data is:' + (action.payload)[0].Profile_Id)
            return {
                loading: false,
                applicantProspects: action.payload
            }
        case APPLICANTPROSPECT_LIST_FAIL:
            return {
                error: action.payload
                
            }
        default:
            return state
    }
}



export {applicantProspectsListReducer}