import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Divider, Typography, Stack, Rating, CircularProgress} from '@mui/material';
import { blueGrey, blue, purple } from '@mui/material/colors';
import { Form, Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useDispatch, useSelector } from 'react-redux';
import { submitApplicant, getApplicantsList } from '../../../../../Redux/Actions/ApplicantsActions';
import { getDivisions } from '../../../../../Redux/Actions/DivisionActions';
import { getApplicantProspectsList } from '../../../../../Redux/Actions/ApplicantProspectsActions';
import { getApplicantStatusesList } from '../../../../../Redux/Actions/ApplicantStatusesActions';
import { getJobRolesList } from '../../../../../Redux/Actions/JobRolesActions';
import { Link as BreadcrumbLink } from '@mui/material';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import api from '../../../../../config/apiConfig';
 

const AddApplicantDialog = ({open, onClose, type, info, handleSubmit}) => {



    //ACCESS DISPATCH
    const dispatch = useDispatch();

    //GET DIVISIONS
    const divisions_ListState = useSelector(state => state.divisions_List);
    const {divisions} = divisions_ListState;

    //GET APPLICANTS
    const applicants_List_State = useSelector((state) => state.applicants_List);
    const {applicants, loading} = applicants_List_State;

    //GET JOB ROLES
    const roles_List_State = useSelector((state) => state.jobRoles_List);
    const {jobRoles} = roles_List_State;

    //GET APPLICANT STATUS
    const statuses_List_State = useSelector((state) => state.applicantStatuses_List);
    const {applicantStatuses: statuses} = statuses_List_State;

    //GET APPLICANT PROSPECT
    const prospects_List_State = useSelector((state) => state.applicantProspects_List);
    const {applicantProspects: prospects} = prospects_List_State;

    
       
    //CURRENT DATE
    const d = new Date(), yyyy = d.getFullYear(), mm = d.getMonth(), dd = d.getDay();
    const current_date = `${yyyy}-${mm}-${dd}`;
    
    //ON COMPONENT DID MOUNT
    useEffect(() => {
        
        dispatch(getDivisions());
        dispatch(getApplicantsList());
        dispatch(getApplicantProspectsList());
        dispatch(getApplicantStatusesList());
        dispatch(getJobRolesList());

    }, [])

    const [values, setValues] = useState({
            first_name: '',
            surname: '' , 
            email: '', 
            application_date: current_date, 
            mobile: '', 
            home_phone: '', 
            divisions: '', 
            jobroles: '', 
            rating: '', 
            applicantprospects: '', 
            applicantstatuses: '', 
        });

    const [submitValues, setSubmitValues] = useState({
            first_name: '',
            surname: '' , 
            email: '', 
            application_date: current_date, 
            mobile: '', 
            home_phone: '', 
            divisions: '', 
            jobroles: [], 
            rating: '', 
            applicantprospects: '', 
            applicantstatuses: '', 
    });

    const handleChange = (e) => {

        const {name, value} = e.target;

        if(name === 'status' || name === 'division' || name === 'role' || name === 'prospect')
        {
            setValues({
                ...values,
                [name]: value
            })
            
        }
        else {

            setValues({
                ...values,
                [name]: value
            })
            setSubmitValues({
                ...submitValues,
                [name]: value
            })
        }
    }

 

    const handleSave = () => {
       
        console.log('PROS: ' + submitValues.applicantprospects);
        console.log('STATUS: ' + submitValues.applicantstatuses);
        console.log('DIVI: ' + submitValues.divisions);
        console.log('DIVI: ' + submitValues.first_name);
        //submitValues.forEach(x => console.log('ITEM: ' + x));
        dispatch(submitApplicant(submitValues, 
                                {...values, 
                                  applicantprospects: values.applicantprospects.label,
                                  applicantstatuses: values.applicantstatuses.label,
                                  divisions: values.divisions.label,
                                  jobroles: values.jobroles.label,
                                }))
        handleSubmit('success',`Successfully added applicant ${submitValues.first_name} to the list.`);

        //CREATE A PROFILE
        //const profile = createProfile(applicants.length)
        //ispatch(submitApplicantProfile(profile))
        
    }

    const animatedComponents = makeAnimated();
    
    const handleSummary = () => {
        setSummary(!summary);
    }
    const [summary, setSummary] = useState(false)

    const [inputValue, setInputValue] = useState('');

   

    const filterOptions = async (inputValue, name) => {

        console.log('NAME IN FILTER IS:' + name)
        
        const keeper = [];

        const tableName = name === 'divisions' ? divisions : name === 'jobroles' ? jobRoles : name === 'applicantprospects' ? prospects : name === 'applicantstatuses' ? statuses :'';

        const id_field = name === 'divisions' ? 'id': name === 'jobroles' ? 'Role_Id' : name === 'applicantprospects'? 'Prospect_Id' : name === 'applicantstatuses'? 'Status_Id' : '';
        const name_field = name === 'divisions' ? 'Name': name === 'jobroles' ? 'Name' : name === 'applicantprospects'? 'Prospect_Name' : name === 'applicantstatuses'? 'Status_Name' : '';
            
        tableName.forEach(x => {
            keeper.push({value: x[id_field], label: x[name_field]})
            })
        

        const filtered = keeper.filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('FILTER LEFT:' + filtered)
        
        return filtered;
        
    }

    const loadOptions =  async (inputValue, name) => {
        
        console.log('NAME IS: ' + name)
        
        const {data} = await api.get(`/${name}`);
        const keeper = [];
        const names = ['divisions', 'jobroles', 'applicantprospects', 'applicantstatuses'];
        
        const id_field = name === 'divisions' ? 'id': name === 'jobroles' ? 'Role_Id' : name === 'applicantprospects'? 'Prospect_Id' : name === 'applicantstatuses'? 'Status_Id' : '';
        const name_field = name === 'divisions' ? 'Name': name === 'jobroles' ? 'Name' : name === 'applicantprospects'? 'Prospect_Name' : name === 'applicantstatuses'? 'Status_Name' : '';
        names.forEach(x => x === name)
            data.forEach(x => {
                keeper.push({value: x[id_field], label: x[name_field]})
               })
        
        if(inputValue === '')
        {
            return keeper;
        }
        else{

            return filterOptions(inputValue, name)
        }
    };


    const handleOptionChange = (value, action) => {
        
        const name = action.name;

        if(name === 'applicantprospects' || name === 'applicantstatuses' || name === 'divisions' || name === 'jobroles')
        {
            setValues({
                ...values,
                [name]: value
            })
    
            setSubmitValues({
                ...submitValues,
                [name]: value.value
            })
        }
        else{

            setValues({
                ...values,
                [name]: value.label
            })
    
            setSubmitValues({
                ...submitValues,
                [name]: value.value
            })
        }
    }
    const handleInputChange = (newValue) => {

        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
        console.log('Input value is:' + inputValue)
        return inputValue
    }

            
    return (
        
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" >
        
            <DialogTitle sx={{display: 'flex', justifyContent: 'flex-start',alignItems: 'center',fontSize: '1.3rem', background: blue[800], fontWeight: 900, color: 'white'}} className="">
                <PersonAddIcon sx={{color: blue[200], mr: 2, fontSize: '1.5rem'}}/>
                
                    <Typography variant="h6" sx={{color: blue[200],fontWeight: 700}}> Add new applicant </Typography>
            </DialogTitle>
    
        <Divider/> 
    
        
        <DialogContent sx={{background: 'white'}}>
            
                <Grid container spacing={0} alignItems="flex-start" justifyContent="space-evenly" sx={{background: ''}}>
                    
                <Grid item lg={12} sx={{m: 0, p: 0, background: ''}}>
                    <Stack direction="row" alignItems="center" sx={{ background: 'blue[50]', mb: 1}}>
                        <Typography variant="body1" sx={{color: blue[700],fontWeight: 900, textAlign: 'center'}}>Form Summary</Typography>
                        <Button variant="text" size="small" sx={{fontSize: 12, color: blue[700], textAlign: 'center'}} onClick={handleSummary}>{summary? 'Close' : 'Open'}</Button>
                    </Stack>
                </Grid>

                <Grid item lg={12} sx={{position: 'sticky', zIndex: 10}}>
                       
                    <Grid container flexDirection="row" justifyContent="space-between" className={`summary ${summary? 'open' : ''}`} sx={{px: 3, pt: '4px', background: '#f2f7fc', border: '2px solid whitesmoke', borderRadius: 3, boxShadow: 'none', overflowY: 'scroll'}}>
                            
                                <Stack direction="row" justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant ID: </Typography>
                                    <Typography variant="body2" sx={{color: values.id? '' : 'lightgray', fontWeight: values.id? 800 : 500, ml: 5}}>{values.id? values.id : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Applicant Date: </Typography>
                                    <Typography variant="body2" sx={{fontWeight: 800, ml: 5}}>{values.application_date}</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>First name: </Typography>
                                    <Typography variant="body2" sx={{color: values.first_name? '' : blue[500], fontWeight: values.first_name? 800 : 500, ml: 5}}>{values.first_name? values.first_name : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Surname: </Typography>
                                    <Typography variant="body2" sx={{color: values.surname? '' : blue[500], fontWeight: values.surname? 800 : 500, ml: 5}}>{values.surname? values.surname : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Email: </Typography>
                                    <Typography variant="body2" sx={{color: values.email? '' : blue[500], fontWeight: values.email? 800 : 500, ml: 5}}>{values.email? values.email : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Mobile: </Typography>
                                    <Typography variant="body2" sx={{color: values.mobile? '' : blue[500], fontWeight: values.mobile? 800 : 500, ml: 5}}>{values.mobile? values.mobile : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Home Phone: </Typography>
                                    <Typography variant="body2" sx={{color: values.home_phone? '' : blue[500], fontWeight: values.home_phone? 800 : 500, ml: 5}}>{values.home_phone? values.home_phone : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Division: </Typography>
                                    <Typography variant="body2" sx={{color: values.divisions? '' : blue[500], fontWeight: values.divisions? 800 : 500, ml: 5}}>{values.divisions? values.divisions.label : 'null' }</Typography>
                                </Stack>
                                {/* <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Job Role: </Typography>
                                    <Typography variant="body2" sx={{color: values.jobroles? '' : blue[500], fontWeight: values.jobroles? 800 : 500, ml: 5}}>{values.joberoles? values.jobroles.forEach(x => x.label) : 'null' }</Typography>
                                </Stack> */}
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Rating: </Typography>
                                    <Typography variant="body2" sx={{color: values.rating? '' : blue[500], fontWeight: values.rating? 800 : 500, ml: 5}}>{values.rating? values.rating.label : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                    <Typography variant="body2" sx={{fontWeight: 500,}}>Prospect: </Typography>
                                    <Typography variant="body2" sx={{color: values.applicantprospects? '' : blue[500], fontWeight: values.applicantprospects? 800 : 500, ml: 5}}>{values.applicantprospects? values.applicantprospects.label : 'null' }</Typography>
                                </Stack>
                                <Stack direction="row"  justifyContent="space-between" sx={{mt: 2, width: '40%'}}>
                                <Typography variant="body2" sx={{fontWeight: 500,}}>Status: </Typography>
                                <Typography variant="body2" sx={{color: values.applicantstatuses? '' : blue[500], fontWeight: values.applicantstatuses? 800 : 500, ml: 5}}>{values.applicantstatuses? values.applicantstatuses.label : 'null' }</Typography>
                            </Stack>
                        </Grid>
                           
                </Grid>
                <Grid item lg={2} sx={{position: 'relative', left: 0,}}>
                    <Stack sx={{mb: 0, background: '', height: '300px', borderRadius: '5px', width: '80%', pl: 3, pt: 3}}>
                        <BreadcrumbLink underline="none" sx={{color: blueGrey[300]}} fontSize="small" href="#basic">Basic Info</BreadcrumbLink>
                        <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#specifics">Applicant Specifics</BreadcrumbLink>
                        <BreadcrumbLink underline="none" sx={{color: blueGrey[300], mt: 4}} fontSize="small" href="#status">Applicant Status</BreadcrumbLink>
                    </Stack>
                    {/* <p>jr: {submitValues.jobroles}</p> */}
                    {/* {console.log('JR: ' + values.jobroles? values.jobroles.label : '')} */}
                    {/* <p>ID: </p>
                    <p>fNAME: {submitValues.first_name}</p>
                    <p>sName: {submitValues.surname}</p>
                    <p>email: {submitValues.email}</p>
                    <p>mobile: {submitValues.mobile}</p>
                    <p>home: {submitValues.home_phone}</p>
                    <p>division: {submitValues.divisions}</p>
                    <p>jobrole: {submitValues.jobroles}</p>
                    <p>rating: {submitValues.rating}</p>
                    <p>propect: {submitValues.applicantprospects}</p>
                    <p>status: {submitValues.applicantstatuses}</p> */}
                </Grid>
                <Grid item lg={10}>
                        <Form className="mt-4">
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[600], borderRadius: '5px', px: 2, py: '5px'}}>Basic Information</Typography>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">This is Applicant no:</Form.Label>
                                    {loading? <> <br/> <CircularProgress color="secondary" size={20} sx={{mt: 1}}/> </>:
                                    <Form.Control onChange={handleChange} name="id" disabled type="text" value={!loading? applicants.length + 1 : ''} className="outline-none border-none" />
                                    }
                                </Form.Group>
                                
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Application Date</Form.Label>
                                    <Form.Control name="application_date" disabled type="text" value={values.application_date} placeholder="E.g 123qst1" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">First Name</Form.Label>
                                    <Form.Control onChange={handleChange} name="first_name" type="text" value={values.first_name} placeholder="E.g Tim" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Surname</Form.Label>
                                    <Form.Control onChange={handleChange} name="surname" type="text" value={values.surname} placeholder="E.g Kelly" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Email</Form.Label>
                                    <Form.Control onChange={handleChange} name="email" type="email" value={values.email} placeholder="timkelly@email.com" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Mobile</Form.Label>
                                    <Form.Control onChange={handleChange} name="mobile" type="text" value={values.mobile} placeholder="E.g 787 582 628" className="outline-none border-none" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Home Phone</Form.Label>
                                    <Form.Control onChange={handleChange} name="home_phone" type="email" value={values.home_phone} placeholder="E.g +263 787 582 628" className="outline-none border-none" />
                                </Form.Group>
                            </Row>
                            <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: purple[500], borderRadius: '5px', px: 2, py: '5px'}}>Applicant Specifics</Typography>
                           
                           {/* <h4>Input value for job roles is:  {values.jobroles? values.jobroles.length : ''}</h4> */}
                           {/* <h4>Input <bold>standard</bold>  value for job roles is:  {submitValues.jobroles? submitValues.jobroles.length : ''}</h4>
                           <h4>{'Input submit value for job role is: ' + submitValues.jobroles }</h4> */}
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Division</Form.Label>
                                    <AsyncSelect
                                        value={values.divisions}
                                        name="divisions"
                                        cacheOptions
                                        loadOptions={(value, action) => loadOptions(value, 'divisions')}
                                        defaultOptions
                                        onChange= {(value, action) => handleOptionChange(value, action)}
                                        components={animatedComponents}
                                        placeholder="Search for division ..."
                                        onInputChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Job Role</Form.Label>
                                        <AsyncSelect
                                            value={values.jobroles}
                                            name="jobroles"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'jobroles')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for roles ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>
                                
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Applicant Rating</Form.Label>
                                    <br/>
                                    <Rating name="rating" defaultValue={2.5} value={values.rating} onChange={handleChange} precision={0.5} sx={{mt: 1}}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Applicant Prospect</Form.Label>
                                        <AsyncSelect
                                            value={values.applicantprospects}
                                            name="applicantprospects"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'applicantprospects')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for prospect ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>
                                </Row>
                                
                            <br/>
                        <Typography variant="body2" sx={{display: 'inline',color: 'white',background: blue[600], borderRadius: '5px', px: 2, py: '5px'}}>Applicant Status</Typography>
                            <Row> 
                                <Form.Group as={Col} controlId="formGridEmail" className="rounded p-2">
                                    <Form.Label className="view-label">Applicant Status</Form.Label>
                                    <AsyncSelect
                                            value={values.applicantstatuses}
                                            name="applicantstatuses"
                                            cacheOptions
                                            loadOptions={(value, action) => loadOptions(value, 'applicantstatuses')}
                                            defaultOptions
                                            onChange= {(value, action) => handleOptionChange(value, action)}
                                            components={animatedComponents}
                                            placeholder="Search for status ..."
                                            onInputChange={handleInputChange}
                                        />
                                </Form.Group>

                                
                            </Row>
                           
                        </Form>
                    </Grid>

                    
                </Grid>

            </DialogContent>

        <DialogActions sx={{my:2, mr: 3}}>
            <Button size="small" sx={{color: blue[700], fontWeight: 400, background: 'none','&:hover': {color: 'black'}}} onClick={onClose}><CloseIcon/></Button>
            <Button size="small" sx={{color: 'white', fontWeight: 400, background: blue[800], '&:hover': {background: blue[800]}}} className="" onClick={handleSave}>Save Applicant</Button>
        </DialogActions>
    </Dialog>
    )
}

export default AddApplicantDialog
