export const PAYMENTS_LIST_REQUEST = 'PAYMENTS_LIST_REQUEST'
export const PAYMENTS_LIST_SUCCESS = 'PAYMENTS_LIST_SUCCESS'
export const PAYMENTS_LIST_FAIL = 'PAYMENTS_LIST_FAIL'


export const PAYMENT_CREATE_REQUEST = 'PAYMENT_CREATE_REQUEST'
export const PAYMENT_CREATE_SUCCESS = 'PAYMENT_CREATE_SUCCESS'
export const PAYMENT_CREATE_FAIL = 'PAYMENT_CREATE_FAIL'


export const PAYMENT_UPDATE_REQUEST = 'PAYMENT_UPDATE_REQUEST'
export const PAYMENT_UPDATE_SUCCESS = 'PAYMENT_UPDATE_SUCCESS'
export const PAYMENT_UPDATE_FAIL = 'PAYMENT_UPDATE_FAIL'

export const PAYMENT_DELETE_REQUEST = 'PAYMENT_DELETE_REQUEST'
export const PAYMENT_DELETE_SUCCESS = 'PAYMENT_DELETE_SUCCESS'
export const PAYMENT_DELETE_FAIL = 'PAYMENT_DELETE_FAIL'

export const VIEW_PAYMENT_REQUEST = 'VIEW_PAYMENT_REQUEST'
export const VIEW_PAYMENT_SUCCESS = 'VIEW_PAYMENT_SUCCESS'
export const VIEW_PAYMENT_FAIL = 'VIEW_PAYMENT_FAIL'