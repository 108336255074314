import React from 'react'
import PageHeader from '../../Components/PageHeader';

const SettingsScreen = () => {
    return (
        <div>
            <PageHeader page="My Settings"/>
        </div>
    )
}

export default SettingsScreen
