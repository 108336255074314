import { TASKS_LIST_REQUEST, TASKS_LIST_SUCCESS, TASKS_LIST_FAIL, VIEW_TASK_FAIL, VIEW_TASK_REQUEST, VIEW_TASK_SUCCESS, TASK_CREATE_REQUEST, TASK_CREATE_SUCCESS, TASK_CREATE_FAIL, TASK_UPDATE_FAIL, TASK_UPDATE_REQUEST, TASK_UPDATE_SUCCESS, TASK_DELETE_REQUEST, TASK_DELETE_SUCCESS, TASK_DELETE_FAIL } from '../Constants/tasksConstants';
import api from '../../config/apiConfig';



const getTasks = () => async (dispatch)=> {

    //console.log('Get tasks is working' + tasks.length)
     
    try {
        dispatch({type: TASKS_LIST_REQUEST})
        const {data} = await api.get('/tasks');
        dispatch({type: TASKS_LIST_SUCCESS, payload: data })
        
    } catch (error) {
        dispatch({type: TASKS_LIST_FAIL, payload: error})
    }
} 


const submitTask = (submitValues, stateValues) => async (dispatch) => {
    
     
    try {
        dispatch({type: TASK_CREATE_REQUEST});
        
        const {data} = await api.post(`/tasks/`, submitValues);
        dispatch({type: TASK_CREATE_SUCCESS, payload: stateValues})
    } catch (error) {
        dispatch({type: TASK_CREATE_FAIL, payload: error})
    }
} 

const getTask = (id) => async (dispatch) => {
    
       
    try {
        dispatch({type: VIEW_TASK_REQUEST});
        
        const {data} = await api.get(`/tasks/ ${id}`);
        console.log('Task data is:' + data)
        dispatch({type: VIEW_TASK_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: VIEW_TASK_FAIL, payload: error})
    }
}

const editTask = (id, values) => async (dispatch) => {
    
     
    try {

        dispatch({type: TASK_UPDATE_REQUEST});
         
        const {data} = await api.put(`/tasks/`, {id, values});
        console.log('Task data in edit action function is:' + values.Status)
        dispatch({type: TASK_UPDATE_SUCCESS, payload: data})

    } catch (error) {

        dispatch({type: TASK_UPDATE_FAIL, payload: error})
    }
}

const deleteTask = (id) => async (dispatch) => {
     
    dispatch({type: TASK_DELETE_SUCCESS, payload: id})
    try { 
        dispatch({type: TASK_DELETE_REQUEST});
        const {data} = await api.delete(`/tasks/ ${id}`);
        console.log('Task data in edit action function is:' + id)
        dispatch({type: TASK_DELETE_SUCCESS, payload: id})

    } catch (error) {
        console.log('error part in deletetask function :' + error)
        dispatch({type: TASK_DELETE_FAIL, payload: error})
    }
}



export {getTasks, submitTask, getTask, editTask, deleteTask}