import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Typography, Button, Grid, IconButton, Divider, Stack} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useNavigate} from 'react-router-dom';
import { blueGrey, blue, cyan, deepPurple, green, purple, indigo } from '@mui/material/colors';

const PageHeader = ({page, previous, tasks, task, assess, applicants, shifts, checkins, workers, availability, timesheets ,assessments, profile}) => {

    const navigate = useNavigate(); 

     
    const goToPrevious = () => {
        if(assess)
        {navigate('/calendar')}
        if(applicants)
        {navigate('/applicants')}
        if(assessments)
        {navigate('/assessments')}
        if(shifts)
        {navigate('/shifts')}
        if(checkins)
        {navigate('/checkins')}
        if(workers)
        {navigate('/workers')}
        if(availability)
        {navigate('/availability')}
        if(timesheets)
        {navigate('/timesheets')}
        else{navigate('/tasks')}
    }

    return (
        <Grid container alignItems="center" sx={{
                            background:  blue[50] ,
                            padding: '0 10px', height: '35px', borderRadius: '5px', borderLeft: '4px solid #1565c0', boxShadow: '.5px .5px 3px #64b5f6'}}>

           
            <Grid item sx={{ mr: 2, opacity: '.7'}}>
                <Stack direction="row" alignItems="center">
                    <IconButton sx={{color: blue[200] }} onClick={goToPrevious} size="small" aria-label="delete">
                        <ArrowBackIosIcon fontSize="inherit"/>
                    </IconButton>
                    <Typography sx={{color: blueGrey[500], fontSize: 15}}>Back</Typography>
                </Stack>
            </Grid> 

            <Grid item >
                <Box>
                    <Typography sx={{fontSize: 18,fontFamily: 'Poppins-Medium', color: blue[800]}}>
                        {task? task : page}
                    </Typography>
                    {profile? 
                    <Typography sx={{ color: 'black',fontWeight: 100, lineHeight: '20px'}}>
                        Manage all your settings and information from here
                    </Typography>
                : ''
                // :<Typography sx={{ color: blueGrey[400],fontWeight: 100, lineHeight: '10px', pb: 1}}>
                //     Your summary of everything, all in one place ....
                // </Typography>
                }

                </Box>
            </Grid>


            {page == 'Dashboard'? 
            <Grid lg={3}>
                {/* <Button sx={{background: 'white' ,borderLeft: '3px solid red', color: 'gray'}} variant="contained" endIcon={<AddIcon />}>
                    Create new Dashboard
                </Button> */}

            </Grid>
            : ''
            }
        </Grid>
    )
}

export default PageHeader
