export const tasks = [
    {
     ID: 'TSK-1',
        'Task Name': 'Compliance',
        'Task Description': 'No description given',
        'Task Assignee': '',
        'Related Staff': '',
        'Related Division': '',
        'Task Type': '',
        'Task Date': '09/03/22 17:00',
        'Task Deadline': '',
        'Client Involved': '', 
        'Client Name': '', 
        'Workers Involved': '',
        'Worker Name': 'Gina Astefanoia',
        'Task Notes': '',
        'Task Status': 'new',
    },
    {
     ID: 'TSK-2',
        'Task Name': 'Complete Invoices',
        'Task Description': 'Pending description',
        'Task Assignee': '',
        'Related Staff': '',
        'Related Division': '',
        'Task Type': '', 
        'Task Date': '09/03/22 17:00',
        'Task Deadline': '',
        'Client Involved': '',
        'Client Name': '',
        'Workers Involved': 'Tariro Dera',
        'Worker Name': '',
        'Task Notes': '',
        'Task Status': 'in progress',
    },
    {
     ID: 'TSK-3',
        'Task Name': 'Purchase Printers',
        'Task Description': 'No description given',
        'Task Assignee': '',
        'Related Staff': '',
        'Related Division': '',
        'Task Type': '',
        'Task Date': '09/03/22 17:00',
        'Task Deadline': '',
        'Client Involved': '',
        'Client Name': '',
        'Workers Involved': 'Taurai Jiri',
        'Worker Name': '',
        'Task Notes': '',
        'Task Status': 'completed',
    },
    {
     ID: 'TSK-4',
        'Task Name': 'Complete Payroll',
        'Task Description': 'No description given',
        'Task Assignee': '',
        'Related Staff': '',
        'Related Division': '',
        'Task Type': '',
        'Task Date': '09/03/22 17:00',
        'Task Deadline': '',
        'Client Involved': '',
        'Client Name': '',
        'Workers Involved': 'Taurai Jiri',
        'Worker Name': '',
        'Task Notes': '',
        'Task Status': 'new',
    },
    {
     ID: 'TSK-5',
        'Task Name': 'Interview I.T Candidates',
        'Task Description': 'No description given',
        'Task Assignee': '',
        'Related Staff': '',
        'Related Division': '',
        'Task Type': '',
        'Task Date': '09/03/22 17:00',
        'Task Deadline': '',
        'Client Involved': '',
        'Client Name': '',
        'Workers Involved': 'Taurai Jiri',
        'Worker Name': '',
        'Task Notes': '',
        'Task Status': 'new',
    },
]

export default tasks;